
export class Address {
  id: number;
  customerId: number | null;
  phoneNo: string | null;
  email: string | null;
  fax: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  country: Country | null;
  addressType: eAddressType;

  constructor() {
    this.id = 0;
    this.customerId = null;
    this.phoneNo = null;
    this.email = null;
    this.fax = null;
    this.streetAddress = null;
    this.zipCode = '';
    this.city = null;
    this.state = null;
    this.country = null;
    this.addressType = eAddressType.Origin;
  }
}
export enum Country {
  Afghanistan = 10,
  Albania = 20,
  Algeria = 30,
  Andorra = 50, // Added Andorra
  Angola = 60,
  Antigua_and_Barbuda = 70,
  Argentina = 80,
  Armenia = 90,
  Australia = 100,
  Austria = 110,
  Azerbaijan = 120,
  Bahamas = 130,
  Bahrain = 140,
  Bangladesh = 150,
  Barbados = 160,
  Belarus = 170,
  Belgium = 180,
  Belize = 190,
  Benin = 200,
  Bhutan = 210,
  Bolivia = 220,
  Bosnia_and_Herzegovina = 230,
  Botswana = 240,
  Brazil = 250,
  Brunei = 260,
  Bulgaria = 270,
  Burkina_Faso = 280,
  Burundi = 290,
  Cabo_Verde = 300,
  Cambodia = 310,
  Cameroon = 320,
  Canada = 330,
  Central_African_Republic = 340,
  Chad = 350,
  Chile = 360,
  China = 370,
  Colombia = 380,
  Comoros = 390,
  Congo_Democratic_Republic_of_the = 400,
  Congo_Republic_of_the = 410,
  Costa_Rica = 420,
  Cote_dIvoire = 430,
  Croatia = 440,
  Cuba = 450,
  Cyprus = 460,
  Czech_Republic = 470,
  Denmark = 480,
  Djibouti = 490,
  Dominica = 500,
  Dominican_Republic = 510,
  East_Timor_Timor_Leste = 520,
  Ecuador = 530,
  Egypt = 540,
  El_Salvador = 550,
  Equatorial_Guinea = 560,
  Eritrea = 570,
  Estonia = 580,
  Ethiopia = 590,
  Fiji = 600,
  Finland = 610,
  France = 620,
  Gabon = 630,
  Gambia = 640,
  Georgia = 650,
  Germany = 660,
  Ghana = 670,
  Greece = 680,
  Grenada = 690,
  Guatemala = 700,
  Guinea = 710,
  Guinea_Bissau = 720,
  Guyana = 730,
  Haiti = 740,
  Honduras = 750,
  Hungary = 760,
  Iceland = 770,
  India = 780,
  Indonesia = 790,
  Iran = 800,
  Iraq = 810,
  Ireland = 820,
  Israel = 830,
  Italy = 840,
  Jamaica = 850,
  Japan = 860,
  Jordan = 870,
  Kazakhstan = 880,
  Kenya = 890,
  Kiribati = 900,
  Korea_North = 910,
  Korea_South = 920,
  Kosovo = 930,
  Kuwait = 940,
  Kyrgyzstan = 950,
  Laos = 960,
  Latvia = 970,
  Lebanon = 980,
  Lesotho = 990,
  Liberia = 1000,
  Libya = 1010,
  Liechtenstein = 1020,
  Lithuania = 1030,
  Luxembourg = 1040,
  Macedonia = 1050,
  Madagascar = 1060,
  Malawi = 1070,
  Malaysia = 1080,
  Maldives = 1090,
  Mali = 1100,
  Malta = 1110,
  Marshall_Islands = 1120,
  Mauritania = 1130,
  Mauritius = 1140,
  Mexico = 1150,
  Micronesia = 1160,
  Moldova = 1170,
  Monaco = 1180,
  Mongolia = 1190,
  Montenegro = 1200,
  Morocco = 1210,
  Mozambique = 1220,
  Myanmar = 1230,
  Namibia = 1240,
  Nauru = 1250,
  Nepal = 1260,
  Netherlands = 1270,
  New_Zealand = 1280,
  Nicaragua = 1290,
  Niger = 1300,
  Nigeria = 1310,
  Norway = 1320,
  Oman = 1330,
  Pakistan = 1340,
  Palau = 1350,
  Panama = 1360,
  Papua_New_Guinea = 1370,
  Paraguay = 1380,
  Peru = 1390,
  Philippines = 1400,
  Poland = 1410,
  Portugal = 1420,
  Qatar = 1430,
  Romania = 1440,
  Russia = 1450,
  Rwanda = 1460,
  Saint_Kitts_and_Nevis = 1470,
  Saint_Lucia = 1480,
  Saint_Vincent_and_the_Grenadines = 1490,
  Samoa = 1500,
  San_Marino = 1510,
  Sao_Tome_and_Principe = 1520,
  Saudi_Arabia = 1530,
  Senegal = 1540,
  Serbia = 1550,
  Seychelles = 1560,
  Sierra_Leone = 1570,
  Singapore = 1580,
  Slovakia = 1590,
  Slovenia = 1600,
  Solomon_Islands = 1610,
  Somalia = 1620,
  South_Africa = 1630,
  South_Sudan = 1640,
  Spain = 1650,
  Sri_Lanka = 1660,
  Sudan = 1670,
  Suriname = 1680,
  Swaziland = 1690,
  Sweden = 1700,
  Switzerland = 1710,
  Syria = 1720,
  Taiwan = 1730,
  Tajikistan = 1740,
  Tanzania = 1750,
  Thailand = 1760,
  Togo = 1770,
  Tonga = 1780,
  Trinidad_and_Tobago = 1790,
  Tunisia = 1800,
  Turkey = 1810,
  Turkmenistan = 1820,
  Tuvalu = 1830,
  Uganda = 1840,
  Ukraine = 1850,
  United_Arab_Emirates = 1860,
  United_Kingdom = 1870,
  United_State = 1880
}

export interface EnumOption {
  id: number;
  value: string;
}
export const CountryOptions: EnumOption[] = [
  { id: Country.Afghanistan, value: 'Afghanistan' },
  { id: Country.Albania, value: 'Albania' },
  { id: Country.Algeria, value: 'Algeria' },
  { id: Country.Andorra, value: 'Andorra' },
  { id: Country.Angola, value: 'Angola' },
  { id: Country.Antigua_and_Barbuda, value: 'Antigua and Barbuda' },
  { id: Country.Argentina, value: 'Argentina' },
  { id: Country.Armenia, value: 'Armenia' },
  { id: Country.Australia, value: 'Australia' },
  { id: Country.Austria, value: 'Austria' },
  { id: Country.Azerbaijan, value: 'Azerbaijan' },
  { id: Country.Bahamas, value: 'Bahamas' },
  { id: Country.Bahrain, value: 'Bahrain' },
  { id: Country.Bangladesh, value: 'Bangladesh' },
  { id: Country.Barbados, value: 'Barbados' },
  { id: Country.Belarus, value: 'Belarus' },
  { id: Country.Belgium, value: 'Belgium' },
  { id: Country.Belize, value: 'Belize' },
  { id: Country.Benin, value: 'Benin' },
  { id: Country.Bhutan, value: 'Bhutan' },
  { id: Country.Bolivia, value: 'Bolivia' },
  { id: Country.Bosnia_and_Herzegovina, value: 'Bosnia and Herzegovina' },
  { id: Country.Botswana, value: 'Botswana' },
  { id: Country.Brazil, value: 'Brazil' },
  { id: Country.Brunei, value: 'Brunei' },
  { id: Country.Bulgaria, value: 'Bulgaria' },
  { id: Country.Burkina_Faso, value: 'Burkina Faso' },
  { id: Country.Burundi, value: 'Burundi' },
  { id: Country.Cabo_Verde, value: 'Cabo Verde' },
  { id: Country.Cambodia, value: 'Cambodia' },
  { id: Country.Cameroon, value: 'Cameroon' },
  { id: Country.Canada, value: 'Canada' },
  { id: Country.Central_African_Republic, value: 'Central African Republic' },
  { id: Country.Chad, value: 'Chad' },
  { id: Country.Chile, value: 'Chile' },
  { id: Country.China, value: 'China' },
  { id: Country.Colombia, value: 'Colombia' },
  { id: Country.Comoros, value: 'Comoros' },
  { id: Country.Congo_Democratic_Republic_of_the, value: 'Congo Democratic Republic of the' },
  { id: Country.Congo_Republic_of_the, value: 'Congo Republic of the' },
  { id: Country.Costa_Rica, value: 'Costa Rica' },
  { id: Country.Cote_dIvoire, value: 'Cote d\'Ivoire' },
  { id: Country.Croatia, value: 'Croatia' },
  { id: Country.Cuba, value: 'Cuba' },
  { id: Country.Cyprus, value: 'Cyprus' },
  { id: Country.Czech_Republic, value: 'Czech Republic' },
  { id: Country.Denmark, value: 'Denmark' },
  { id: Country.Djibouti, value: 'Djibouti' },
  { id: Country.Dominica, value: 'Dominica' },
  { id: Country.Dominican_Republic, value: 'Dominican Republic' },
  { id: Country.East_Timor_Timor_Leste, value: 'East Timor (Timor-Leste)' },
  { id: Country.Ecuador, value: 'Ecuador' },
  { id: Country.Egypt, value: 'Egypt' },
  { id: Country.El_Salvador, value: 'El Salvador' },
  { id: Country.Equatorial_Guinea, value: 'Equatorial Guinea' },
  { id: Country.Eritrea, value: 'Eritrea' },
  { id: Country.Estonia, value: 'Estonia' },
  { id: Country.Ethiopia, value: 'Ethiopia' },
  { id: Country.Fiji, value: 'Fiji' },
  { id: Country.Finland, value: 'Finland' },
  { id: Country.France, value: 'France' },
  { id: Country.Gabon, value: 'Gabon' },
  { id: Country.Gambia, value: 'Gambia' },
  { id: Country.Georgia, value: 'Georgia' },
  { id: Country.Germany, value: 'Germany' },
  { id: Country.Ghana, value: 'Ghana' },
  { id: Country.Greece, value: 'Greece' },
  { id: Country.Grenada, value: 'Grenada' },
  { id: Country.Guatemala, value: 'Guatemala' },
  { id: Country.Guinea, value: 'Guinea' },
  { id: Country.Guinea_Bissau, value: 'Guinea-Bissau' },
  { id: Country.Guyana, value: 'Guyana' },
  { id: Country.Haiti, value: 'Haiti' },
  { id: Country.Honduras, value: 'Honduras' },
  { id: Country.Hungary, value: 'Hungary' },
  { id: Country.Iceland, value: 'Iceland' },
  { id: Country.India, value: 'India' },
  { id: Country.Indonesia, value: 'Indonesia' },
  { id: Country.Iran, value: 'Iran' },
  { id: Country.Iraq, value: 'Iraq' },
  { id: Country.Ireland, value: 'Ireland' },
  { id: Country.Israel, value: 'Israel' },
  { id: Country.Italy, value: 'Italy' },
  { id: Country.Jamaica, value: 'Jamaica' },
  { id: Country.Japan, value: 'Japan' },
  { id: Country.Jordan, value: 'Jordan' },
  { id: Country.Kazakhstan, value: 'Kazakhstan' },
  { id: Country.Kenya, value: 'Kenya' },
  { id: Country.Kiribati, value: 'Kiribati' },
  { id: Country.Korea_North, value: 'Korea, North' },
  { id: Country.Korea_South, value: 'Korea, South' },
  { id: Country.Kosovo, value: 'Kosovo' },
  { id: Country.Kuwait, value: 'Kuwait' },
  { id: Country.Kyrgyzstan, value: 'Kyrgyzstan' },
  { id: Country.Laos, value: 'Laos' },
  { id: Country.Latvia, value: 'Latvia' },
  { id: Country.Lebanon, value: 'Lebanon' },
  { id: Country.Lesotho, value: 'Lesotho' },
  { id: Country.Liberia, value: 'Liberia' },
  { id: Country.Libya, value: 'Libya' },
  { id: Country.Liechtenstein, value: 'Liechtenstein' },
  { id: Country.Lithuania, value: 'Lithuania' },
  { id: Country.Luxembourg, value: 'Luxembourg' },
  { id: Country.Macedonia, value: 'Macedonia' },
  { id: Country.Madagascar, value: 'Madagascar' },
  { id: Country.Malawi, value: 'Malawi' },
  { id: Country.Malaysia, value: 'Malaysia' },
  { id: Country.Maldives, value: 'Maldives' },
  { id: Country.Mali, value: 'Mali' },
  { id: Country.Malta, value: 'Malta' },
  { id: Country.Marshall_Islands, value: 'Marshall Islands' },
  { id: Country.Mauritania, value: 'Mauritania' },
  { id: Country.Mauritius, value: 'Mauritius' },
  { id: Country.Mexico, value: 'Mexico' },
  { id: Country.Micronesia, value: 'Micronesia' },
  { id: Country.Moldova, value: 'Moldova' },
  { id: Country.Monaco, value: 'Monaco' },
  { id: Country.Mongolia, value: 'Mongolia' },
  { id: Country.Montenegro, value: 'Montenegro' },
  { id: Country.Morocco, value: 'Morocco' },
  { id: Country.Mozambique, value: 'Mozambique' },
  { id: Country.Myanmar, value: 'Myanmar' },
  { id: Country.Namibia, value: 'Namibia' },
  { id: Country.Nauru, value: 'Nauru' },
  { id: Country.Nepal, value: 'Nepal' },
  { id: Country.Netherlands, value: 'Netherlands' },
  { id: Country.New_Zealand, value: 'New Zealand' },
  { id: Country.Nicaragua, value: 'Nicaragua' },
  { id: Country.Niger, value: 'Niger' },
  { id: Country.Nigeria, value: 'Nigeria' },
  { id: Country.Norway, value: 'Norway' },
  { id: Country.Oman, value: 'Oman' },
  { id: Country.Pakistan, value: 'Pakistan' },
  { id: Country.Palau, value: 'Palau' },
  { id: Country.Panama, value: 'Panama' },
  { id: Country.Papua_New_Guinea, value: 'Papua New Guinea' },
  { id: Country.Paraguay, value: 'Paraguay' },
  { id: Country.Peru, value: 'Peru' },
  { id: Country.Philippines, value: 'Philippines' },
  { id: Country.Poland, value: 'Poland' },
  { id: Country.Portugal, value: 'Portugal' },
  { id: Country.Qatar, value: 'Qatar' },
  { id: Country.Romania, value: 'Romania' },
  { id: Country.Russia, value: 'Russia' },
  { id: Country.Rwanda, value: 'Rwanda' },
  { id: Country.Saint_Kitts_and_Nevis, value: 'Saint Kitts and Nevis' },
  { id: Country.Saint_Lucia, value: 'Saint Lucia' },
  { id: Country.Saint_Vincent_and_the_Grenadines, value: 'Saint Vincent and the Grenadines' },
  { id: Country.Samoa, value: 'Samoa' },
  { id: Country.San_Marino, value: 'San Marino' },
  { id: Country.Sao_Tome_and_Principe, value: 'Sao Tome and Principe' },
  { id: Country.Saudi_Arabia, value: 'Saudi Arabia' },
  { id: Country.Senegal, value: 'Senegal' },
  { id: Country.Serbia, value: 'Serbia' },
  { id: Country.Seychelles, value: 'Seychelles' },
  { id: Country.Sierra_Leone, value: 'Sierra Leone' },
  { id: Country.Singapore, value: 'Singapore' },
  { id: Country.Slovakia, value: 'Slovakia' },
  { id: Country.Slovenia, value: 'Slovenia' },
  { id: Country.Solomon_Islands, value: 'Solomon Islands' },
  { id: Country.Somalia, value: 'Somalia' },
  { id: Country.South_Africa, value: 'South Africa' },
  { id: Country.South_Sudan, value: 'South Sudan' },
  { id: Country.Spain, value: 'Spain' },
  { id: Country.Sri_Lanka, value: 'Sri Lanka' },
  { id: Country.Sudan, value: 'Sudan' },
  { id: Country.Suriname, value: 'Suriname' },
  { id: Country.Swaziland, value: 'Swaziland' },
  { id: Country.Sweden, value: 'Sweden' },
  { id: Country.Switzerland, value: 'Switzerland' },
  { id: Country.Syria, value: 'Syria' },
  { id: Country.Taiwan, value: 'Taiwan' },
  { id: Country.Tajikistan, value: 'Tajikistan' },
  { id: Country.Tanzania, value: 'Tanzania' },
  { id: Country.Thailand, value: 'Thailand' },
  { id: Country.Togo, value: 'Togo' },
  { id: Country.Tonga, value: 'Tonga' },
  { id: Country.Trinidad_and_Tobago, value: 'Trinidad and Tobago' },
  { id: Country.Tunisia, value: 'Tunisia' },
  { id: Country.Turkey, value: 'Turkey' },
  { id: Country.Turkmenistan, value: 'Turkmenistan' },
  { id: Country.Tuvalu, value: 'Tuvalu' },
  { id: Country.Uganda, value: 'Uganda' },
  { id: Country.Ukraine, value: 'Ukraine' },
  { id: Country.United_Arab_Emirates, value: 'United Arab Emirates' },
  { id: Country.United_Kingdom, value: 'United Kingdom' },
  { id: Country.United_State, value: 'United States' }
];
export enum eAddressType {
  Origin = 0,
  Destination = 1
}

export const AddressTypeOptions: EnumOption[] = [
  { id: eAddressType.Origin, value: 'Origin' },
  { id: eAddressType.Origin, value: 'Destination' }
];
