export class UserAssignment {
  refId: number;
  userIds: number[];
  userId: number;
  assignmentType: eAssignmentType;
  assignedDate: Date | null | string;
  assignedTimeSlot: string | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.refId = 0;
    this.userIds = [];
    this.userId = 0;
    this.assignmentType = eAssignmentType.Lead;
    this.assignedDate = new Date();
    this.assignedTimeSlot = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
export enum eAssignmentType {
  Lead = 0,
  Move = 1,
  Survey = 2,
  Packing = 3,
  Delivery = 4,
}