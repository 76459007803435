import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';

@Component({
  selector: 'app-inventory-setting',
  templateUrl: './inventory-setting.component.html',
  styleUrls: ['./inventory-setting.component.scss']
})
export class InventorySettingComponent implements OnInit {

  defaultNavActiveId: number = 1;

  constructor (private breadcrumbService: BreadcrumbService,) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Inventory Settings', url: '/inventory-setting' },
      

    ]);

  }
  onChangeTab(_type: any) {

  }

}
