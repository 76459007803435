import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { MoveCategoryTypeOptions, MoveTypeOptions, TransportTypeOptions, eProcessStatus } from '../../models/lead-detail.model';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../../services/movd.service';
import { ContactService } from 'src/services/contact.service';
import { GeneralService } from 'src/services/general.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { packingListColumns } from '../../models/packaging-detail.model';
import { AuthService } from 'src/services/auth.service';
import { AppComponent } from 'src/app/app.component';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { PackagingBoardListDTO, PackingListResponse } from './packing-board.model';

@Component({
  selector: 'app-packing-list',
  templateUrl: './packing-list.component.html',
  styleUrls: ['./packing-list.component.scss']
})
export class PackingListComponent implements OnInit {
  columnMode: ColumnMode = ColumnMode.force;
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  columnToggleSubject = new Subject<void>();



  loadingIndicator = true;
  _displayedLeadsList: any[] = [];
  tableColumns: any[] = [];
  _allLeads = new PackagingBoardListDTO();
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  _selectedStatus: string = 'All';

  _allLeadsList: any[] = [];
  _companyId: number;
  defaultColumns: string[] = ['Packing ID', 'Move ID', 'Account', 'Customer', 'Origin']; //'Status',
  _selectedColumns: { [key: string]: boolean } = {};
  _allColumns: string[] = packingListColumns;
  _contacts: any = [];
  _companies: any = [];

  _userRole: string | null = null;

  _eProcessStatus = eProcessStatus;

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _crmContactReadPermission = false;
  _leadInfoReadPermission = false;

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _searchTerm = '';

  constructor(
    private _router: Router,
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _contactService: ContactService,
    public generalService: GeneralService,
    private _authService: AuthService,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService

  ) {
    this._userRole = localStorage.getItem('role');
    this._companyId = Number(localStorage.getItem('companyId'));

    this.columnToggleSubject.pipe(
      debounceTime(10) // Adjust this value as needed toggleColumnVisibility() will only be called once per 20 milliseconds 
    ).subscribe(() => this.toggleColumnVisibility());
  }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Packing List', url: '/packing-list' }
    ]);

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Packing List.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Packing List.Create');
    this._updatePermission = permissions.includes('Packing List.Update');
    this._deletePermission = permissions.includes('Packing List.Delete');
    this._crmContactReadPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Info.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Survey.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Quotation.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Messages.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Summary.Read') || this._userRole === 'CompanyAdmin';

    this._companyId = Number(localStorage.getItem('companyId'));
    //this._statusList = ["NEW LEADS", "IN PROGRESS", "WON LEADS", "LOST LEADS"];
    const storedSelectedColumns = localStorage.getItem('selectedColumnsForThisSection');
    if (storedSelectedColumns) {
      this._selectedColumns = JSON.parse(storedSelectedColumns);
    } else {
      for (const column of this.defaultColumns) {
        this._selectedColumns[column] = true;
      }
    }


    if (this._companyId !== 0) {
      // console.log("com", this._companyId);
      this.getPackagingBoardList();
    }

    // this._selectedColumns = {};
    for (const column of this.defaultColumns) {
      this._selectedColumns[column] = true;
    }
    this.defaultColumns.forEach((column) => {
      this._selectedColumns[column] = true;
    });
    this.tableColumns = this.defaultColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));
    this.getCustomers();
    // console.log('Table Coumn', this.tableColumns);
    // console.log('All Column', this._allColumns);
  }
  async showUnauthorizedDialog() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit the Packing Form', '');
    console.log("go to dashboard. no read permission");
  }
  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        if (response && response.length > 0) {
          this._contacts = response.filter((item: { contactType: number; }) => item.contactType === 0);
          this._companies = response.filter((item: { contactType: number; }) => item.contactType === 1);
          // console.log("_contacts", this._contacts);
          // console.log("_companies", this._companies);
        }
      },
      error: (error: any) => {
        console.error("error getting contacts", error);
      }
    });
  }

  getMoveTypeLabel(value: any) {
    return this._moveType.find(option => option.id === value)?.value;
  }

  getMoveCategoryLabel(value: any) {
    return this._moveCategory.find(option => option.id === value)?.value;
  }

  getTransportTypeLabel(value: any) {
    return this._transportType.find(option => option.id === value)?.value;
  }

  onClickStatus(label: string) {
    this._selectedStatus = label;
    switch (label) {
      case 'All':
        this._displayedLeadsList = this._allLeadsList;
        break;

      case 'New':
        let newList = this._allLeadsList.filter(packingDetail => (packingDetail.packingStatus === eProcessStatus.New));
        this._displayedLeadsList = newList;
        break;

      case 'InProgress':
        let inProgressList = this._allLeadsList.filter(packingDetail => (packingDetail.packingStatus === eProcessStatus.InProgress));
        this._displayedLeadsList = inProgressList;
        break;

      case 'Completed':
        let completedList = this._allLeadsList.filter(packingDetail => (packingDetail.packingStatus === eProcessStatus.Completed));
        this._displayedLeadsList = completedList;
        break;

      default:
        // Handle the case when the label is not recognized
        break;
    }

  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.getPackagingBoardList();
  }

  onInputChange(event: any) {
    // console.log("on input change event", event);
    // console.log("on input change event.data", event.data);

    if (event && event.data === null) {
      // console.log("condition checked", event && event.data === null);
      this.getPackagingBoardList();
    }
  }

  onSearch(): void {
    this.getPackagingBoardList();
  }

  getPackagingBoardList() {
    this._movdService.getPackagingBoardList(this._currentPage, this._pageSize, this._searchTerm).subscribe({
      next: (data: PackingListResponse) => {
        // console.log("getPackagingBoardList", data);
        if (data && data.packingList && data.packingList.length > 0) {
          var packingBoardDTO = new PackagingBoardListDTO();
          this._totalRecords = data.totalRecords;
          this._allLeadsList = data.packingList;
          this._displayedLeadsList = data.packingList;
          // console.log(" _displayedLeadsList", this._displayedLeadsList);
          // Update tableColumns to include new combined date columns
          this.tableColumns = Object.keys(packingBoardDTO)
            .filter((key) => key !== 'leadId'
              && key !== 'lastName'
              && key !== 'phone'
              && key !== 'email'
              && key !== 'pocId'
              && key !== 'accountId'
              && key !== 'accountName'
              && key !== 'thirdPartyId'
              && key !== 'thirdPartyPocId'
              && key !== 'accountManagerId'
              && key !== 'creationDate'
            )
            .map((key) => {
              let header: string;
              let prop: string = key;
              switch (key) {
                case 'packagingDetailId':
                  header = 'Packaging Id';
                  break;
                case 'leadDetailId':
                  header = 'Lead Id';
                  break;
                case 'moveDetailId':
                  header = 'Move ID';
                  break;
                case 'account':
                  header = 'Account';
                  break;
                case 'customer':
                  header = 'Customer';
                  break;
                case 'status':
                  header = 'Status';
                  break;
                case 'origin':
                  header = 'Origin';
                  break;
                case 'destination':
                  header = 'Destination';
                  break;
                case 'moveType':
                  header = 'Move';
                  break;
                case 'moveCategory':
                  header = 'Move Category';
                  break;
                case 'moveTransport':
                  header = 'Transport Type';
                  break;
                case 'portOfLoading':
                  header = 'POL';
                  break;
                case 'portOfDischarge':
                  header = 'POE';
                  break;
                case 'placeOfDelivery':
                  header = 'Place Of Delivery';
                  break;
                case 'pocName':
                  header = 'POC';
                  break;
                case 'thirdPartyName':
                  header = 'Third Party';
                  break;
                case 'thirdPartyPocName':
                  header = 'Third Party POC';
                  break;
                case 'packagingDate':
                  header = 'Pack Date';
                  break;
                case 'loadingDate':
                  header = 'Loading Date';
                  break;
                case 'storageDate':
                  header = 'Storage Date';
                  break;
                case 'warehouseStorage':
                  header = 'Warehouse Storage';
                  break;
                case 'deliveryDate':
                  header = 'Delivery Date';
                  break;
                case 'accountManager':
                  header = 'Account Manager';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }
              return { header, prop };
            });

          this.toggleColumnVisibility();
          // }
          // console.log('display data', data);
        }
      },
      error: (error) => {
        console.error("error getting leads for board", error);
      }
    });
  }


  openColumnModal(content: TemplateRef<any>) {
    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }

  toggleColumnVisibility() {
    // console.log('_selectedColumns:', this._selectedColumns);
    this.tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));
    // console.log('tableColumns:', this.tableColumns);
    localStorage.setItem('selectedColumns', JSON.stringify(this._selectedColumns));
    localStorage.setItem('selectedColumnsForThisSection', JSON.stringify(this._selectedColumns));
  }

  async navigateToPackagingDetail(data: PackagingBoardListDTO) {
    console.log("navigate to packing detail data", data);

    if (!this._readPermission) {
      this.showUnauthorizedDialog();
      return;
    }

    if (data.packingStatus === eProcessStatus.New) {
      let text = 'You are about to start the packing process.';
      let confirmButtonText = 'Yes, please start!';

      let confirmed = await this._appComponent.showConfirmationDialog(
        'Are you sure?',
        text,
        confirmButtonText
      );

      if (await confirmed === true) {
        this.savePackingStatus(data);
        const packagingDetailId = data.packagingDetailId
        const leadId = data.leadId
        const moveDetailId = data.moveDetailId
        const leadDetailId = data.leadDetailId
        const queryParams = { packagingDetailId, leadId, moveDetailId, leadDetailId };
        const navigationExtras: NavigationExtras = {
          queryParams,
          skipLocationChange: false
        };
        this.breadcrumbService.setBreadcrumbs([
          { text: 'Dashboard', url: '/dashboard' },
          { text: 'Packing List', url: '/packing-list' },
          { text: 'Packing Form', url: '/packing-form' },
        ]);
        this._router.navigate(['/packing-details'], navigationExtras);
      }
      else {
        return;
      }
    } else if (data.packingStatus === eProcessStatus.InProgress || data.packingStatus === eProcessStatus.Completed) {
      const packagingDetailId = data.packagingDetailId
      const leadId = data.leadId
      const moveDetailId = data.moveDetailId
      const leadDetailId = data.leadDetailId
      const queryParams = { packagingDetailId, leadId, moveDetailId, leadDetailId };
      const navigationExtras: NavigationExtras = {
        queryParams,
        skipLocationChange: false
      };
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Packing Form', url: '/packing-form' },
      ]);

      this._router.navigate(['/packing-details'], navigationExtras);
    }

  }

  returnPackingUrl(row: any) {
    const packagingDetailId = row.packagingDetailId;
    const leadId = row.leadId;
    const moveDetailId = row.moveDetailId;
    const leadDetailId = row.leadDetailId;
    return { packagingDetailId, leadId, moveDetailId, leadDetailId };
  }



  savePackingStatus(data: PackagingBoardListDTO) {
    this._movdService.savePackingAndDeliveryStatus(data.packagingDetailId || 0, eProcessStatus.InProgress, "Packing").subscribe({
      next: (response: any) => {
        // console.log("saved survey status resonse", response);
        // now navigate to packing form
      },
      error: (error) => {
        console.error("error saving survey status", error);
      }
    });
  }

  leadIdClick(data: any) {
    if (this._leadInfoReadPermission === false && this._userRole !== 'CompanyAdmin') {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the lead info form', '');
      return;
    }
    this._allLeads = data;
    const leadDetailId = this._allLeads.leadDetailId;
    const leadId = this._allLeads.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Packing List', url: '/packing-list' },
      { text: 'Lead Info', url: '/lead-detail' },
    ]);


    this._router.navigate(['/lead-detail'], navigationExtras);

  }

  returnLeadUrl(row: any) {
    const leadDetailId = row.leadDetailId;
    const leadId = row.leadId;
    return { leadDetailId, leadId };

  }

  moveInfoClick(data: any) {
    const moveDetailId = data.moveDetailId;
    const leadId = data.leadId;
    const leadDetailId = data.leadDetailId;
    // console.log("_moveDetail.id and _leadDetailId", moveDetailId, leadDetailId, leadId);
    const queryParams = { moveDetailId, leadDetailId, leadId };

    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Packing List', url: '/packing-list' },
      { text: 'Move Info', url: '' },
    ]);

    this._router.navigate(['/move-detail'], navigationExtras);

  }

  returnMoveUrl(row: any) {
    const moveDetailId = row.moveDetailId;
    const leadId = row.leadId;
    const leadDetailId = row.leadDetailId;
    return { moveDetailId, leadDetailId, leadId };
  }
  redirectToAccount(id: any) {
    if (this._crmContactReadPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the profile', '');
      return;
    }
    // const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Packing List', url: '/packing-list' },
      { text: 'Profile', url: '' },
    ]);
    this._router.navigate(['/profile'], navigationExtras);
  }
  getColumnWidth(header: string): number {
    if (header === 'Packing ID' || header === 'Lead ID' || header === 'Move ID') {
      return 85; // Set the desired width for these columns
    } else {
      return 155; // Default width for other columns
    }
  }
}
