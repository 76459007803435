<ng-template #noData>- - - -</ng-template>
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 *ngIf="assignmentType === 'leadDetail'"
      class="modal-title container d-flex align-items-center" id="exampleModalLabel">
      <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover"
        colors="primary:#110a5c,secondary:#1ac0a1" style="width: 40px; height: 40px">
      </lord-icon>
      &nbsp; Update Lead Info
    </h4>
    <h4 *ngIf="assignmentType === 'moveDetail'"
      class="modal-title container d-flex align-items-center" id="exampleModalLabel">
      <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover"
        colors="primary:#110a5c,secondary:#1ac0a1" style="width: 40px; height: 40px">
      </lord-icon>
      &nbsp; Update Move Info
    </h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-9"></div>
      <div class="col-md-3">
        <span class="required-input"> * </span>
        <span class="required-label">Required fields</span>
      </div>
    </div>
    <div class="col-12 col-md-12">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="_lead.firstName" class="col-form-label">
            First Name
            <span class="required-input"> * </span>
          </label>
          <div class="input-group" ngbDropdown #firstNameDropdown="ngbDropdown" placement="bottom"
            [autoClose]="true || _lead.firstName === null">
            <input type="text" class="form-control" [(ngModel)]="_lead.firstName"
              id="_lead.firstName" autocomplete="on" placeholder="Enter first name"
              (keyup)="searchCustomer('firstName'); firstNameDropdown.open()"
              (focus)="firstNameDropdown.open()" [disabled]="_contentDisable" />

            <div class="dropdown-menu scrollable-menu" ngbDropdownMenu
              *ngIf="_searchedCustomersList.length > 0">
              <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                <button class="dropdown-item" (click)="selectCustomer(customer)">
                  <div>
                    <p> {{customer.customerName}}</p>
                  </div>
                  <div>
                    <p>{{customer.phone}}</p>
                  </div>
                  <div>
                    <p>{{customer.email}}</p>
                  </div>
                </button>
                <hr *ngIf="!isLast">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label for="_lead.lastName" class="col-form-label">Last Name</label>
          <div class="input-group" ngbDropdown #lastNameDropdown="ngbDropdown" placement="bottom"
            [autoClose]="true || _lead.lastName === null">
            <input type="text" class="form-control" [(ngModel)]="_lead.lastName" id="_lead.lastName"
              autocomplete="on" placeholder="Enter first name"
              (keyup)="searchCustomer('lastName'); lastNameDropdown.open()"
              (focus)="lastNameDropdown.open()" [disabled]="_contentDisable" />

            <div class="dropdown-menu scrollable-menu" ngbDropdownMenu
              *ngIf="_searchedCustomersList.length > 0">
              <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                <button class="dropdown-item" (click)="selectCustomer(customer)">
                  <div>
                    <p> {{customer.customerName}}</p>
                  </div>
                  <div>
                    <p>{{customer.phone}}</p>
                  </div>
                  <div>
                    <p>{{customer.email}}</p>
                  </div>
                </button>
                <hr *ngIf="!isLast">
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="_lead.phoneNo" class="col-form-label">
            Phone Number
            <span class="required-input"> * </span>
          </label>
          <!-- <input type="text" class="form-control" [(ngModel)]="_lead.phone" id="_lead.phone"
            autocomplete="on" placeholder="Enter phone"
            (keypress)="validatePhoneNumber($event, _lead.phone)" /> -->
          <!-- <div *ngIf="isFieldEmpty(_lead.phoneNo)" class="invalid-input-color">* required</div> -->
          <div class="input-group" ngbDropdown #phoneDropdown="ngbDropdown" placement="bottom"
            [autoClose]="true || _lead.phone === null">
            <input type="text" class="form-control" [(ngModel)]="_lead.phone" id="_lead.phone"
              autocomplete="on" placeholder="Enter phone" (keypress)="validatePhoneNumber($event)"
              [disabled]="_contentDisable" (keyup)="searchCustomer('phone');phoneDropdown.open()"
              (focus)="phoneDropdown.open()" [disabled]="_contentDisable" />

            <div class="dropdown-menu scrollable-menu" ngbDropdownMenu
              *ngIf="_searchedCustomersList.length > 0">
              <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                <button class="dropdown-item">
                  <div>
                    <p> {{customer.phone}}</p>
                  </div>
                  <div>
                    <p>{{customer.customerName}}</p>
                  </div>
                  <div>
                    <p>{{customer.email}}</p>
                  </div>
                </button>
                <hr *ngIf="!isLast">
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <label for="_lead.email" class="col-form-label">
            Email
            <span class="required-input"> * </span>
          </label>
          <!-- <input type="email" class="form-control" [(ngModel)]="_lead.email" id="_lead.email"
            autocomplete="on" placeholder="Enter email" required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            (keyup)="searchCustomer()" /> -->
          <div class="input-group" ngbDropdown #emailDropdown="ngbDropdown" placement="bottom"
            [autoClose]="true || _lead.email === null">
            <input type="email" class="form-control" [(ngModel)]="_leadEmail" id="_lead.email"
              autocomplete="on" placeholder="Enter email" (input)="onEmailChange($event)"
              (keyup)="searchCustomer('email');emailDropdown.open()" (focus)="emailDropdown.open()"
              required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              [disabled]="_contentDisable" />

            <div class="dropdown-menu scrollable-menu" ngbDropdownMenu
              *ngIf="_searchedCustomersList.length > 0">
              <div *ngFor="let customer of _searchedCustomersList; let isLast=last">
                <button class="dropdown-item" (click)="selectCustomer(customer)">
                  <div>
                    <p>{{customer.email}}</p>
                  </div>
                  <div>
                    <p>{{customer.customerName}}</p>
                  </div>
                  <div>
                    <p>{{customer.phone}}</p>
                  </div>
                </button>
                <hr *ngIf="!isLast">
              </div>
            </div>
          </div>

          <!-- <div *ngIf="isFieldEmpty(_lead.email)" class="invalid-input-color">* required</div> -->
          <div
            *ngIf="_lead.email && _lead.email.length > 0 && !_lead.email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')"
            class="invalid-input-color">
            Invalid email format.
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="account" class="col-form-label">Account</label>
          <ng-select (change)="filterContacts('contact', _lead.accountId)" [items]=" _companies"
            bindLabel="customerName" bindValue="id" [searchable]="true"
            [(ngModel)]="_lead.accountId" placeholder="Select Account" [disabled]="_contentDisable">
          </ng-select>
        </div>

        <div class="col-md-6">
          <label for="poc" class="col-form-label">POC</label>
          <ng-select [items]="_filteredContactPOCs" bindLabel="customerName" bindValue="id"
            [searchable]="true" [(ngModel)]="_lead.pocId" placeholder="Select POC"
            [disabled]="_contentDisable">
          </ng-select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label for="thirdParty" class="col-form-label">Third Party</label>
          <ng-select (change)="filterContacts('thirdParty', _lead.thirdPartyId)"
            [items]=" _companies" bindLabel="customerName" bindValue="id" [searchable]="true"
            [(ngModel)]="_lead.thirdPartyId" placeholder="Select Third Party"
            [disabled]="_contentDisable">
          </ng-select>
        </div>

        <div class="col-md-6">
          <label for="contact" class="col-form-label">POC</label>
          <ng-select [items]="_filteredTPPOCs" bindLabel="customerName" bindValue="id"
            [searchable]="true" [(ngModel)]="_lead.thirdPartyPocId"
            placeholder="Select Third Party POC" [disabled]="_contentDisable">
          </ng-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <ng-container *ngIf="assignmentType === 'leadDetail'">
            <label for="contact" class="col-form-label">Assign To</label>
            <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="customerId"
              [(ngModel)]="_selectedUserIds" [searchable]="true" placeholder="Select User to Assign"
              [multiple]="true" [clearable]="true" (change)="onAssignUser($event)"
              [disabled]="_assignToDisable">
            </ng-select>
          </ng-container>
          <ng-container *ngIf="assignmentType === 'moveDetail'">
            <label for="contact" class="col-form-label">Assign To</label>
            <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="customerId"
              [(ngModel)]="_selectedUserIds" [searchable]="true" placeholder="Select User to Assign"
              [multiple]="true" [clearable]="true" [disabled]="_assignToDisable">
            </ng-select>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="assignmentType === 'leadDetail'">
      <button type="button" class="btn btn-primary btn-sm" (click)="updateLead()"
        [disabled]="_contentDisable">
        <i class="mdi mdi-content-save"> </i> &nbsp;Update Lead Info
      </button>
    </ng-container>
    <ng-container *ngIf="assignmentType === 'moveDetail'">
      <button type="button" class="btn btn-primary btn-sm" (click)="onAssignUser($event)"
        [disabled]="_assignToDisable">
        <i class="mdi mdi-content-save"> </i> &nbsp;Update Move Info
      </button>
    </ng-container>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()">
      <i class="mdi mdi-close"></i>&nbsp;Close
    </button>
  </div>
</ng-template>
<div class="row" *ngIf="_isDataLoaded">
  <div class="col-md-9 order-md-1">
    <div class="box-left border border-dark-grey p-3 custom-rounded">
      <div class="row">
        <div class="col-md-4">
          <label>
            <strong>Customer ID: </strong>
            <a class="move-id clickable-link" (click)="redirectToContact(_leadInfo.customerId)">
              {{ _leadInfo.customerId ? generalService.formatId(_leadInfo.customerId, 'customer') :
              '- - - - ' }}
            </a>
          </label>

        </div>

        <ng-container *ngIf="type === 'leadDetail'">
          <div class="col-md-2 d-flex align-items-center"
            *ngIf="_moveDetail.id !== 0 && _leadDetail.id !== 0">
            <label>
              <strong>Lead ID: </strong>
              <a class="lead-id clickable-link" (click)="handleClick(_leadDetail)">
                {{ _leadDetail.id ? generalService.formatId(_leadDetail.id, 'lead') : '- - - -' }}
              </a>
            </label>

          </div>
          <div class="col-md-2 d-flex align-items-center"
            *ngIf="_moveDetail.id !== 0 && _leadDetail.id !== 0">
            <label>
              <strong>Move ID: </strong>
              <a class="move-id clickable-link" (click)="handleMoveClick(_moveDetail)">
                {{ _moveDetail.id ? generalService.formatId(_moveDetail.id, 'move') : '- - - -' }}
              </a>
            </label>

          </div>
          <div class="col-md-4 d-flex align-items-center"
            *ngIf="_moveDetail.id === 0 && _leadDetail.id !== 0">
            <label>
              <strong>Lead ID: </strong>
              <a class="lead-id clickable-link" (click)="handleClick(_leadDetail)">
                {{ _leadDetail.id ? generalService.formatId(_leadDetail.id, 'lead') : '- - - -' }}
              </a>
            </label>

          </div>
          <div class="col-md-4 d-flex align-items-center"
            *ngIf="_moveDetail.id !== 0 && _leadDetail.id === 0">
            <label>
              <strong>Move ID: </strong> #
              <a class="move-id clickable-link" (click)="handleMoveClick(_moveDetail)">
                {{ _moveDetail.id ? _moveDetail.id : " - - - - " }}
              </a>
            </label>
          </div>
        </ng-container>

        <!-- <div class="col-md-4 d-flex align-items-center"
          *ngIf="_moveDetail.id !== 0 && _leadDetail.id !== 0 && type === 'leadDetail'">
          <label>
            <strong>Move ID: </strong> #
            <a class="move-id clickable-link" (click)="handleMoveClick(_moveDetail)">
              {{ _moveDetail.id ? _moveDetail.id : " - - - - " }}
            </a>
          </label>
        </div>
        <div class="col-md-4 d-flex align-items-center"
          *ngIf="_moveDetail.id === 0 && _leadDetail.id !== 0 && type === 'leadDetail'">
          <label>
            <strong>Lead ID: </strong> #
            <a class="lead-id clickable-link" (click)="handleClick(_leadDetail)">
              {{ _leadDetail.id ? _leadDetail.id : " - - - - " }}
            </a>
          </label>
        </div>
        <div class="col-md-4 d-flex align-items-center"
          *ngIf="_moveDetail.id === 0 && _leadDetail.id === 0 && type === 'leadDetail'">
          <label>
            <strong>Lead ID: </strong> #
            <a class="lead-id clickable-link" (click)="handleClick(_leadDetail)">
              {{ refId ? refId : " - - - - " }}
            </a>
          </label>
        </div> -->

        <!-- <div class="col-md-4 d-flex align-items-center"
          *ngIf="_moveDetail.id !== 0 && _leadDetail.id === 0 && type === 'leadDetail'">
          <label>
            <strong>Move ID: </strong> #
            <a class="move-id clickable-link" (click)="handleMoveClick(_moveDetail)">
            {{ _moveDetail.id ? _moveDetail.id : " - - - - " }}
            </a>
          </label>
        </div>
        <div class="col-md-4 d-flex align-items-center"
          *ngIf="_moveDetail.id === 0 && type === 'leadDetail'">
          <label>
            <strong>Lead ID: </strong> #
            <a class="move-id clickable-link" (click)="handleMoveClick(_moveDetail)">
            {{ refId ? refId : " - - - - " }}
            </a>
          </label>
        </div> -->
        <div class="col-md-4">
          <label>
            <strong>Email: </strong>{{ _leadInfo.email ? _leadInfo.email : " - - - - " }}
          </label>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-4">
          <!-- {{ _lead.fullName }} -->
          <label>
            <strong>Customer: </strong>
            <a (click)="redirectToContact(_leadInfo.customerId)" class="clickable-link">
              {{ _leadInfo.customer ? _leadInfo.customer : '- - - -' }}
            </a>
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <strong>Account: </strong>
            <span *ngIf="_leadInfo.accountName; else noAccount">
              <a (click)="redirectToAccount(_leadInfo.accountId)" class="clickable-link">
                {{ _leadInfo.accountName }}
              </a>
            </span>
            <ng-template #noAccount>- - - -</ng-template>
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <strong>POC: </strong>
            <span *ngIf="_leadInfo.pocName; else noPoc">
              <a (click)="redirectToContact(_leadInfo.pocId)" class="clickable-link">
                {{ _leadInfo.pocName }}
              </a>
            </span>
            <ng-template #noPoc>- - - -</ng-template>
          </label>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-4">
          <label>
            <strong> Phone: </strong>{{ _leadInfo.phone ? _leadInfo.phone : " - - - - " }}
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <strong>Third Party: </strong>
            <span *ngIf="_leadInfo.thirdPartyName; else noThirdParty">
              <a (click)="redirectToAccount(_leadInfo.thirdPartyId)" class="clickable-link">
                {{ _leadInfo.thirdPartyName }}
              </a>
            </span>
            <ng-template #noThirdParty>- - - -</ng-template>
          </label>
        </div>
        <div class="col-md-4">
          <label>
            <strong>POC: </strong>
            <span *ngIf="_leadInfo.thirdPartyPocName; else noThirdPartyPoc">
              <a (click)="redirectToContact(_leadInfo.thirdPartyPocId)" class="clickable-link">
                {{ _leadInfo.thirdPartyPocName }}
              </a>
            </span>
            <ng-template #noThirdPartyPoc>- - - -</ng-template>
          </label>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-4">
          <label>
            <strong> Assign To: </strong>{{ _userAssigned ? _userAssigned : " - - - - " }}
          </label>
        </div>
        <div class="col-md-4">
          <ng-container *ngIf="_moveDetail.id !== 0 && _leadDetail.id !== 0">
            <label>
              <strong>Tracking ID: </strong>
              {{ _moveDetail.trackingId ? _moveDetail.trackingId : '- - - -' }}
            </label>
          </ng-container>
        </div>
        <div class="col-md-4 ">
          <div class="text-end" *ngIf="_leadListUpdatePermission">
            <button type="button" class="btn btn-sm btn-outline-primary" (click)="openEditModal()">
              <!-- [disabled]="_contentDisable || _contentDisableForMove" -->
              <i class="feather icon-edit icon-sm"></i>EDIT
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="col-md-3 order-md-2">
    <div class="box-right border border-dark-grey p-3 custom-rounded">
      <h6>Internal Notes</h6>

      <textarea class="form-control" rows="4"
        [ngModel]="(type === 'leadDetail') ? _leadInternalNotes : _moveInternalNotes"
        (ngModelChange)="updateNotes($event)" [disabled]="_contentDisable"
        placeholder="Write internal notes for unique items like arts, etc."></textarea>
    </div>
  </div>
</div>
