import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'src/services/reports.service';
import { DealsModel } from 'src/app/views/crm/sales/deals/deals.models';
import { SaleService } from 'src/services/sale.service';
import { ContactService } from 'src/services/contact.service';
import { eSalesReportType, SalesReportDTO, TotalSalesDetailReportDTO } from 'src/app/views/movd/reports/models/sales-reports-dto.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { GeneralService } from 'src/services/general.service';
import { eReportSubType } from 'src/app/views/movd/reports/models/move-reports-dto.model';
import { eContactType } from 'src/app/views/crm/contact/contact.models';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {
  _saleReports: SalesReportDTO = new SalesReportDTO;
  showSalesSections: boolean = false;

  _moduleActiveTabId = 1;
  _salesActiveTabId = 2;
  _leadtosaleActiveTabId = 1;
  _totalsalesActiveTabId = 2;


  columnMode: ColumnMode = ColumnMode.force;
  loadingIndicator = true;
  eReportSubType = eReportSubType;
  eSalesReportType = eSalesReportType;
  _summarizeReportData: any[] = [];
  summarizeTableColumns: any[] = [];
  _detailReportData: any[] = [];
  detailTableColumns: any[] = [];

  _totalSalesReportData: any[] = [];  // for total sales
  totalSalesTableColumns: any[] = []; // for total sales

  _totalSalesDetailReportData: any[] = []; // for total sales detail report
  totalSalesDetailTableColumns: any[] = [];// for total sales detail report

  _leadToSaleSummarizedReportData: any[] = [];
  leadToMoveSummarizeTableColumns: any[] = [];  //lead to sale conversion

  _leadToSaleDetailReportData: any[] = [];
  leadToSaleDetailTableColumns: any[] = [];



  _dealName!: string | null;
  _allContacts: any[] = [];
  _allCustomers: any = [];
  _deal = new DealsModel();
  _companyId: number = 0;
  _contacts: any = [];
  _companies: any = [];
  _pipesList: any[] = [];
  _saleSubType: number = 0;
  _saleType: number = 0;

  constructor(private _reportsService: ReportsService,
    private _saleService: SaleService, private _contactService: ContactService, public generalService: GeneralService,
  ) { }

  ngOnInit(): void {

    this.onChangeModuleTab(2, eSalesReportType.TotalSales); // temporarily calling this to dislay the total sales tab by default
    this.onChangeSalesSubType(2, eReportSubType.DetailReport)

    this.getCustomers();
    this.getContacts();
    // this.getSalesReports();
    let companyId = Number(localStorage.getItem('companyId'));
    if (companyId) {
      this._companyId = companyId;
    }
    this.getPipesList(this._companyId);
  }
  clearFilters(): void {
    this._saleReports = new SalesReportDTO();
  }


  getSalesReports(): void {
    console.log('Sales reports:', this._saleReports);
    this._reportsService.getSalesReports(this._saleReports).subscribe({
      next: (data) => {

        // if (data.length > 0) {
        if (this._saleReports.saleReportType === eSalesReportType.TotalLeads) {
          if (this._saleReports.salesSubType === eReportSubType.SummarizeReport) {
            this._summarizeReportData = data;
            this.summarizeTableColumns = Object.keys(this._summarizeReportData[0])
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'new':
                    header = 'New';
                    break;
                  case 'inProgress':
                    header = 'InProgress';
                    break;
                  case 'won':
                    header = 'Won';
                    break;
                  case 'lost':
                    header = 'Lost';
                    break;
                  case 'totalLeads':
                    header = 'Total Leads';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });
          } else if (this._saleReports.salesSubType === eReportSubType.DetailReport) {
            this._detailReportData = data;

            var totalSalesDetailModel = new TotalSalesDetailReportDTO(); // for columns

            this.detailTableColumns = Object.keys(totalSalesDetailModel)
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'leadId':
                    header = 'Lead ID';
                    break;
                  case 'account':
                    header = 'Account';
                    break;
                  case 'customer':
                    header = 'Customer';
                    break;
                  case 'status':
                    header = 'Status';
                    break;
                  case 'origin':
                    header = 'Origin';
                    break;
                  case 'destination':
                    header = 'Destination';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });
          }

        }
        if (this._saleReports.saleReportType === eSalesReportType.TotalSales) {
          if (this._saleReports.salesSubType === eReportSubType.SummarizeReport) {
            this._totalSalesReportData = data;
            this.totalSalesTableColumns = Object.keys(this._totalSalesReportData[0])
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'lead':
                    header = 'Lead';
                    break;
                  case 'prospect':
                    header = 'Prospect';
                    break;
                  case 'won':
                    header = 'Won';
                    break;
                  case 'lost':
                    header = 'Lost';
                    break;
                  case 'totalSales':
                    header = 'Total sales';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });

          }
          else if (this._saleReports.salesSubType === eReportSubType.DetailReport) {

            this._totalSalesDetailReportData = data;
            this.totalSalesDetailTableColumns = Object.keys(this._totalSalesDetailReportData[0])
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'dealName':
                    header = 'Deal Name';
                    break;
                  case 'status':
                    header = 'Status';
                    break;
                  case 'contactName':
                    header = 'Contact Name';
                    break;
                  case 'dealOwner':
                    header = 'Deal Owner';
                    break;
                  case 'amount':
                    header = 'Amount';
                    break;
                  case 'probability':
                    header = 'Probability';
                    break;
                  case 'lastContact':
                    header = 'Last Contact';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });
          }
        }
        if (this._saleReports.saleReportType === eSalesReportType.LeadToSaleConversion) {
          if (this._saleReports.salesSubType === eReportSubType.SummarizeReport) {
            console.log("reports", data);
            this._leadToSaleSummarizedReportData = data;
            this.leadToMoveSummarizeTableColumns = Object.keys(this._leadToSaleSummarizedReportData[0])
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'totalLeads':
                    header = 'Total Leads';
                    break;
                  case 'totalMoves':
                    header = 'Total Sales';
                    break;
                  case 'leadToMoveRate':
                    header = 'Lead To Sale Converstion Rate';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });
          }
          else if (this._saleReports.salesSubType === eReportSubType.DetailReport) {
            this._leadToSaleDetailReportData = data;
            this.leadToSaleDetailTableColumns = Object.keys(this._leadToSaleDetailReportData[0])
              .map((key) => {
                let header: string;
                let prop: string = key;
                switch (key) {
                  case 'date':
                    header = 'Date';
                    break;
                  case 'leadId':
                    header = 'Lead ID';
                    break;
                  case 'account':
                    header = 'Account';
                    break;
                  case 'customer':
                    header = 'Customer';
                    break;
                  case 'status':
                    header = 'Status';
                    break;
                  case 'reason':
                    header = 'Reason';
                    break;
                  case 'origin':
                    header = 'Origin';
                    break;
                  case 'destination':
                    header = 'Destination';
                    break;
                  default:
                    header = key; // Use the original key if no specific header is defined
                    break;
                }
                return { header, prop };
              });
          }

        }
        // }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  onChangeModuleTab(type: any, SalesReportType: eSalesReportType) {
    this._saleReports.saleReportType = SalesReportType;

  }
  onChangeSalesSubType(type: any, reportSubType: eReportSubType) {
    this._saleReports.salesSubType = reportSubType;
  }

  toggleSalesSections() {
    this.showSalesSections = !this.showSalesSections;
  }

  getPipesList(companyId: number) {
    this._saleService.getPipes(companyId).subscribe(
      (data: any) => {
        // console.log("data ", data);
        this._pipesList = data && data.length > 0 ? data.filter((item: any) => item.isActive === true) : [];
        this.getContacts();
        // console.log("_pipesList ", this._pipesList);
      },
      (error: any) => {
        console.error("error getting pipes list", error);
      }
    );
  }
  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        if (response && response.length > 0) {
          this._allCustomers = response;
          this._contacts = response.filter((item: { contactType: number; }) => item.contactType === 0);
          this._companies = response.filter((item: { contactType: number; }) => item.contactType === 1);

        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }

  getContacts() {
    this._contactService.getContacts().subscribe(
      (data: any) => {
        console.log("get contacts response", data);
        if (data.contacts && data.contacts.length > 0) {
          this._allContacts = data.contacts;
          // this._allContacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId === this._companyId);
          this._contacts = data.contacts.filter((item: { contactType: number; companyId: number }) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);
        }
        console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

}
