export class UserModel {
  id: number;
  username: string | null;
  fullName: string | null;
  email: string;
  password: string;
  roleId: number;
  customerId: number | null;
  phone: string | null;
  companyName: string | null;
  website: string | null;
  industryId: number | null;
  companyId: number | null;
  city: string | null;
  country: string | null;
  addressId: number | null;
  profileImage: string | null;
  streetAddress: string | null;
  isActive: boolean;
  twoFactorEnabled: boolean;

  constructor() {
    this.id = 0;
    this.username = null;
    this.fullName = null;
    this.email = '';
    this.password = '';
    this.roleId = 0;
    this.customerId = null;
    this.phone = null;
    this.companyName = null;
    this.website = null;
    this.industryId = null;
    this.companyId = null;
    this.city = null;
    this.country = null;
    this.addressId = null;
    this.profileImage = null;
    this.streetAddress = null;
    this.isActive = false;
    this.twoFactorEnabled = false;
  }
}
// export class UserModel {
//   id: number;
//   username: string;
//   email: string;
//   password: string;
//   roleId: number;
//   companyName: string;
//   constructor() {
//     this.id = 0;
//     this.username = '';
//     this.email = '';
//     this.password = '';
//     this.roleId = 0;
//     this.companyName = '';
//   }
// }
export class UserDTO {
  id: number;
  username: string;
  email: string;
  roleId: number;
  role: string;
  companyId: number;
  customerId: number;
  otpMessage: string | null;

  constructor() {
    this.id = 0;
    this.username = '';
    this.email = '';
    this.roleId = 0;
    this.role = '';
    this.companyId = 0;
    this.customerId = 0;
    this.otpMessage = null;
  }
}
export interface EnumOption {
  id: number;
  value: string;
}
export enum eEmailType {
  Signup = 1,
  ActivationLink = 2,
  SuperAdmin = 3,
  ResetPassword = 4,
  SetPassword = 5,
  OTPVerification = 6,
  Lead = 7,
  Move = 8
}
// export const EmailTypeOptions: EnumOption[] = [
//   { id: eEmailType.Signup, value: 'Signup Request' },
//   { id: eEmailType.ActivationLink, value: 'Account Activation Link' },
//   { id: eEmailType.SuperAdmin, value: 'SuperAdmin for New Company' },
//   { id: eEmailType.ResetPassword, value: 'Reset Password Request' },
//   { id: eEmailType.SetPassword, value: 'Set Password Link' },
//   { id: eEmailType.Lead, value: 'Lead' },
//   { id: eEmailType.Move, value: 'Move' },
// ];