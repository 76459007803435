import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  email: string = '';
  isLoading = false;

  forgotPasswordForm: FormGroup = new FormGroup({});
  errorMessage: string | null = null;
  submitted = false;

  constructor(private authService: AuthService,
    private _appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  forgotpassword(e: Event) {

    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.isLoading = true;

    e.preventDefault();
    const emailInput = document.getElementById('txtemail') as HTMLInputElement;

    this.email = emailInput.value;

    if (this.email) {
      // console.log("email", this.email.toString()); // Log the email

      this.authService.forgotPassword(this.email.trim().toString()).subscribe({
        next: (response) => {
          // console.log("forgot password response", response);
          this._appComponent.showSuccessSwal('email', 'sent');
        },
        error: (error) => {
          this._appComponent.showErrorSwal('Oops! The email authentication', '');
        }
      });
      this.isLoading = false;
    } else {
      console.error("Email is not provided");
      this.isLoading = false;
    }
  }


}
