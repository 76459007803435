<div class="row">
  <div class="col-12 col-md-12">
    <input type="hidden" value="{{id}}" />
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="customerName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="customerName" id="customerName" maxlength="50"
          autocomplete="on" placeholder="Enter full name" required />
        <div *ngIf="!customerName" class="error-message error" style="color: red;">* required</div>
      </div>
    </div>
    <div class="row mb-3" [hidden]="!_contactVisible">
      <div class="col-md-3">
        <label for="customerCompany" class="col-form-label">Company</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_companies" bindLabel="value" bindValue="id" [searchable]="true"
          [(ngModel)]="customerCompany" (change)="onChangeCompany()" placeholder="Select Company">
        </ng-select>
      </div>
    </div>
    <div class="row mb-3" [hidden]="!_contactVisible">
      <div class="col-md-3">
        <label for="role" class="col-form-label">Position</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="role" id="role" autocomplete="on" maxlength="50"
          placeholder="Enter Position" (keypress)="validateRole($event, role)" />
      </div>



      <!--<div class="col-md-9">
    <ng-select [items]="_roles"
               bindLabel="value"
               bindValue="id"
               [searchable]="true"
               [(ngModel)]="customerRole"
               placeholder="Select Role">
    </ng-select>
  </div>-->
    </div>
    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="_industryId" class="col-form-label">Industry</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_industries" bindLabel="title" bindValue="id" [searchable]="true" [(ngModel)]="_industryId"
          placeholder="Select Industry Name">
        </ng-select>
      </div>
    </div>
    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="_compOwnerID" class="col-form-label">Owner</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_compOwners" bindLabel="customerName" bindValue="id" [searchable]="true"
          [(ngModel)]="_compOwnerID" placeholder="Select Owner Name">
        </ng-select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="phone" class="form-label">Phone</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="phone" id="phone" autocomplete="on"
          placeholder="Enter Phone #" (keypress)="validatePhoneNumber($event)" />
        <div *ngIf="phone.length<=0" class="error-message error" style="color: red;">
          * required
        </div>
        <div *ngIf="phone.length > 0 && phone.length < 8" style="color: red">
          Invalid number
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="email" id="email" autocomplete="on"
          maxlength="75" placeholder="Enter email" required
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" (blur)="checkEmailExists(email)"
          (keyup)="checkEmailExists(email)" />
        <div *ngIf="!email || (email && email.length === 0)" class="error-message error"
          style="color: red;">
          * required
        </div>
        <div *ngIf="email && email.length > 0 && !email.match('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')"
          class="error-message error" style="color: red;">
          invalid email format.
        </div>
        <div *ngIf="_emailExists" class="required-input">
          already exists
        </div>
      </div>
    </div>

    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="noOfEmployees" class="col-form-label">No. of Employees</label>
      </div>
      <div class="col-md-9">
        <ng-select [(ngModel)]="noOfEmployees" id="noOfEmployees" placeholder="Select No. of Employees">
          <ng-option value="0-25">0-25</ng-option>
          <ng-option value="26-50">26-50</ng-option>
          <ng-option value="50-150">50-150</ng-option>
          <ng-option value="150-500">150-500</ng-option>
        </ng-select>
      </div>
    </div>

    <!--<div class="row" [hidden]="!_companyVisible">-->
    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="openPositions" class="form-label">Open Positions</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="openPositions" autocomplete="on" maxlength="50"
          id="openPositions" placeholder="Enter number of open positions" />
      </div>
    </div>
    <!--</div>-->

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="details" class="form-label">Details</label>
      </div>
      <div class="col-md-9">
        <textarea class="form-control" [(ngModel)]="details" autocomplete="on" id="details" maxlength="200"
          placeholder="Enter details"></textarea>
      </div>
    </div>

    <hr />
    <!-- <input type="hidden" value="{{adrs_id}}" />
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="adrs_phone" class="form-label">Phone</label>
      </div>
      <div class="col-md-9">
        <input type="tel" class="form-control" [(ngModel)]="adrs_phone" id="adrs_phone"
          autocomplete="on" placeholder="Enter Phone #" required pattern="[0-9]{10,}"
          (keypress)="validatePhoneNumber($event)" />
        <div *ngIf="adrs_phone && adrs_phone.trim().length > 0 && adrs_phone.trim().length < 8"
          style="color: red">
          Invalid number. Please enter at least 8 characters.
        </div>
        <div *ngIf="!isValidPhoneNumber(adrs_phone)" style="color: red">
          only numeric digits
        </div>
      </div>
    </div> -->
    <div class="row d-none mb-3">
      <div class="col-md-3">
        <label for="adrs_email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="email" class="form-control" [(ngModel)]="adrs_email" id="adrs_email" autocomplete="on"
          maxlength="75" placeholder="Enter email" required>
        <!--<input type="email" class="form-control" [formControl]="adrs_email" id="adrs_email" autocomplete="on" placeholder="Enter email">
        <div *ngIf="adrs_email.invalid && (adrs_email.dirty || adrs_email.touched)" class="error-message error">
          <div *ngIf="adrs_email.errors && adrs_email.errors['required'] && adrs_email.touched" style="color:red">Email is required.</div>
          <div *ngIf="adrs_email.errors && adrs_email.errors['adrs_email'] && adrs_email.touched" style="color:red">Invalid email format.</div>
        </div>-->
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="adrs_street" class="form-label">Street Address</label>
      </div>
      <div class="col-md-9">
        <textarea class="form-control" [(ngModel)]="adrs_street" autocomplete="on" id="adrs_street"
          placeholder="Enter street details" maxlength="150"></textarea>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="adrs_city" class="form-label">City</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="adrs_city" id="adrs_city" maxlength="50" autocomplete="on"
          placeholder="Enter city" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="adrs_country" class="form-label">Country</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="countries" bindLabel="value" bindValue="id" placeholder="Select a country"
          [(ngModel)]="adrs_country" id="adrs_country">
        </ng-select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="salesType" class="form-label">{{_salesTypeLabel}}</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_salesTypeOption" bindLabel="value" bindValue="id" [searchable]="true" [(ngModel)]="_salesType"
          placeholder="Select Type">
        </ng-select>
      </div>
    </div>

    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="companyURL" class="form-label">Company URL</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="companyURL" autocomplete="on" id="companyURL"
          placeholder="Company URL" />
      </div>
    </div>
    <div class="row mb-3" [hidden]="!_companyVisible">
      <div class="col-md-3">
        <label for="linkedInURL" class="form-label">LinkedIn Page</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="linkedInURL" autocomplete="on" id="linkedInURL"
          placeholder="Enter LinkedIn URL" />
      </div>
    </div>

    <hr>
    <div class="row text-end">
      <div class="col-md-12">
        <ng-container *ngIf="_updatePermission">
          <button type="button" class="btn btn-primary btn-xs" (click)="addContact();">
            <i class="mdi mdi-content-save"></i>&nbsp;Save
          </button>
        </ng-container>
        <button type="button" class="btn btn-danger btn-xs ms-2" (click)="invokeParentFunction()">
          <i class="mdi mdi-close"> </i>&nbsp;Close</button>
      </div>
    </div>
  </div>
</div>



<!--<div class="row mb-3">
      <div class="col-md-3">
        <label for="fax" class="form-label">Fax</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="fax" autocomplete="on" id="fax" placeholder="Enter fax #">
      </div>
    </div>-->