import { MoveAttachment } from "./move-attachments.model";
import { SurveyMaterial } from "./survery-material.model";

export class MoveSurvey {
    leadDetailId: number;
    internalNotes: string;
    externalNotes: string | null;
    others: string | null;
    qoutationAmount: number;
    surveyMaterials: SurveyMaterial[];
    createdBy: number | null;
    customerSignature: string | null;
    managerSignature: string | null;
    surveyAttachments: MoveAttachment[];

    constructor() {
        this.leadDetailId = 0;
        this.internalNotes = '';
        this.externalNotes = null;
        this.others = null;
        this.qoutationAmount = 0;
        this.surveyMaterials = [];
        this.createdBy = null;
        this.customerSignature = null;
        this.managerSignature = null;
        this.surveyAttachments = [];
    }
}
