<div class="mt-3">
    <div class="page-title">
        <!-- <h5>{{ title }}</h5> -->
    </div>

    <nav>
        <ul class="breadcrumb">
            <ng-container *ngFor="let breadcrumb of previousBreadcrumbs; let i = index; let last = last">
                <li [class.active]="last">
                    <ng-container *ngIf="i === 0">
                        <a [routerLink]="breadcrumb.url" [ngStyle]="{'color': '#1AC0A1'}">{{ breadcrumb.text }}</a>
                    </ng-container>
                    <ng-container *ngIf="i !== 0 && !last">
                        <a [routerLink]="breadcrumb.url" [ngStyle]="{'color': '#1AC0A1'}"
                            (click)="onBreadcrumbClick(breadcrumb)">
                            {{ breadcrumb.text }}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="i !== 0 && last">
                        <span [ngStyle]="{'color': '#000'}">{{ breadcrumb.text }}</span>
                    </ng-container>
                    <span *ngIf="!last" class="breadcrumb-separator">/</span>
                </li>
            </ng-container>
        </ul>
    </nav>
</div>