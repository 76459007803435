<ng-template #addLabourModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="mdi mdi-account-plus"> </i> &nbsp; Add Labour
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_labour.name" class="col-form-label">Full Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" 
          id="_labour.name" autocomplete="on" placeholder="Enter Full Name" [(ngModel)]="_labour.name"
          maxlength="30" required pattern="[A-Za-z\s]{1,20}" />
        <div *ngIf="!_labour.name" class="error-message error" style="color: red;">*
          required</div>
      </div>

    </div>
     <div class="row mb-3">
      <div class="col-md-3">
        <label for="selectedLabourType" class="col-form-label"> Type</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_eLabourTypes" bindLabel="value" bindValue="id" [searchable]="true"
           placeholder="Select Labour Type" [(ngModel)]="_labour.type">
        </ng-select>
        <!-- <div *ngIf="!selectedRoleIds || selectedRoleIds.length === 0" class="error-message error"
          style="color: red;">
          * required
        </div> -->
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_labour.nic" class="col-form-label">National ID</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_labour.nic"
          id="_labour.nic"  placeholder="Enter National Identity Number"
           required />
        <!-- <div *ngIf="!_labour.nic" class="error-message error" style="color: red;">*
          required</div> -->
      </div>
    </div>

   <div class="row mb-3">
      <div class="col-md-3">
        <label for="_labour.email" class="form-label">Email</label>
      </div>
      <div class="col-md-9">
        <input type="email" class="form-control" [(ngModel)]="_labour.email" id="_labour.email"
          autocomplete="on" maxlength="75" placeholder="Enter email"
           />
        <!-- <div *ngIf="!_userEmail || _userEmail.length === 0" class="error-message error"
          style="color: red;">
          * required
        </div> -->

        <!-- <div *ngIf="_emailExists" style="color: red;">
          already exists
        </div>
        <div *ngIf="!_emailExists && _userEmail && !(_userEmail.match(emailPattern))"
          style="color: red;">
          invalid format
        </div> -->
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_labour.phone" class="form-label">Phone</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_labour.phone"
          id="_labour.phone" autocomplete="on" maxlength="15" placeholder="Phone Number"
           />
        <!-- <div *ngIf="!isValidLength()" style="color: red;">
          Only numeric digits are allowed.
        </div> -->
      </div>

    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_labour.city" class="form-label">Address</label>
      </div>
      <div class="col-md-9">
        <textarea type="text" class="form-control" [(ngModel)]="_labour.address" autocomplete="on" placeholder="Enter Address"  >
          </textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveLabour()">
      <i class="mdi mdi-content-save"> </i> &nbsp; Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()" >
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-container >
  <div class="card rounded" >
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 col-sm-auto align-right">
          <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
            placeholder="Search..." [(ngModel)]="searchTerm" (keyup)="performSearch()" />
        </div>
        <div class="col-md-4 col-md-auto"></div>
        <div class="col-md-5 col-md-auto">
          <button class="btn btn-xs btn-primary btn-icon-text float-end"
            (click)="openAddModal(addLabourModal)" style="margin-left:3px;width: 120px;">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp; Add Labour
          </button>
        </div>
      </div>
      &nbsp;
       <div class="row" *ngIf="_labourTableData.length === 0">
        <div class="col-md-12" style="align-self: auto">
          <div class="card">
            <div class="card-body text-center">
              <p>No Labour, currently. Please Add a labour.</p>
            </div>
          </div>
        </div>
      </div>
      &nbsp;

      <div class="row" *ngIf="_labourTableData.length > 0">
        <div class="col-md-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <ngx-datatable [rows]="_labourTableData" class="bootstrap" [loadingIndicator]="true"
                  [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10"
                  [pagination]="true" [pageSize]="10" [rowHeight]="40">
                  <ngx-datatable-column *ngFor="let column of _labourTableColumns"
                    [name]="column.header">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                      <ng-container *ngIf="column.header === 'Name'">
                        <div class="d-flex align-items-center hover"
                        (mouseenter)="onRowMouseEnter(row)"
                        (mouseleave)="onRowMouseLeave(row)">
                          {{ row.name | slice:0:15 }}
                          <button class="btn btn-secondary button-wrapper btn-icon-text
                        btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                        (click)="previewClick(row)">
                            Preview
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="column.header === 'Type'">
                        {{ enumMappingService.mapLabourType(row.type) || '-' }}
                      </ng-container>
                      <ng-container *ngIf="column.header !== 'Name' && column.header !== 'Type'">
                        {{ value || '-'}}
                      </ng-container>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Action">
                    <ng-template ngx-datatable-cell-template let-row="row"
                      let-rowIndex="rowIndex" let-value="value">
                      <button type="button" class="btn btn-xs datatable-action-cell"
                        [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                        (click)="updateLabourStatus(row, row.isActive)">
                        {{ !row.isActive ? "Activate" : "Deactivate" }}
                      </button>
                      &nbsp;
                      <button type="button"
                        class="btn btn-xs btn-danger datatable-action-cell ml-2"
                        (click)="updateLabourStatus(row, row.isActive, true)">
                        Delete
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</ng-container>