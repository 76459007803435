import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { InventoryService } from 'src/services/inventory.service';
import { AppComponent } from 'src/app/app.component';
import { PackingMaterial, MaterialTypeOptions, eMaterialType } from '../../../models/packing-material.model';
import { EnumMappingService } from '../../../services/movd.enum.service';

@Component({
  selector: 'app-packing-material',
  templateUrl: './packing-material.component.html',
  styleUrls: ['./packing-material.component.scss']
})
export class PackingMaterialComponent implements OnInit {

  @ViewChild('addPackingMaterialModal') addPackingMaterialModal: any
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  searchTerm: string = '';
  _ngbModalRef: NgbModalRef | undefined;
  _packingMaterial = new PackingMaterial();
  _ePackingMaterialTypes = MaterialTypeOptions;

  _companyId: number = 0;
  _packingMaterialTableData: any[] = [];
  _packingMaterialTableDataList: any[] = [];
  _packingMaterialTableColumns: any[] = [];
  ColumnMode = ColumnMode;

  constructor(private _modalService: NgbModal, private _inventoryService: InventoryService, private _appComponent: AppComponent, public enumMappingService: EnumMappingService,) {
    this._companyId = Number(localStorage.getItem('companyId'));
  }

  ngOnInit(): void {
    this.refreshPackingMaterialTableData();
  }
  onChangeTab(_type: any) {

  }

  performSearch() {
    if (this.searchTerm === '') {
      this._packingMaterialTableData = this._packingMaterialTableDataList;
      return;
    }

    const searchTerms = this.searchTerm.toLowerCase().split(' ');

    const filteredData = this._packingMaterialTableDataList.filter((row: any) => {
      return Object.values(row).some((value: any) => {
        if (Object.values(eMaterialType).includes(value)) {
          const materialTypeText = this.enumMappingService.mapMaterialType(value).toLowerCase();
          return searchTerms.some(term => materialTypeText.includes(term));
        } else {
          const valueText = String(value).toLowerCase();
          return searchTerms.some(term => valueText.includes(term));
        }
      });
    });

    this._packingMaterialTableData = filteredData;

    if (this.table) {
      this.table.offset = 0;
    }

    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  openAddModal(template: TemplateRef<any>) {
    this._packingMaterial = new PackingMaterial();
    this._ngbModalRef = this._modalService.open(this.addPackingMaterialModal, { size: 'md', });
  }
  closeModal() {
    this._ngbModalRef?.close();
  }
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }
  previewClick(row: any) {
    //console.log("row id", row);
    let rowId = row.id;
    this.openEditModel(Number(rowId));
  }
  openEditModel(id: number): void {
    this._packingMaterial = new PackingMaterial();
    const data = this._packingMaterialTableData.filter(item => item.id === id)[0];
    this._packingMaterial = { ...data };
    this._ngbModalRef = this._modalService.open(this.addPackingMaterialModal, { size: 'md', });
  }

  savePackingMaterial() {
    if (this._packingMaterial.name === '') {
      return;
    }
    this._packingMaterial.ownedBy = this._companyId;
    // console.log('packing material', this._packingMaterial);
    this._inventoryService.savePackingMaterial(this._packingMaterial).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal('updated', 'Packing Material');
        this.closeModal();
        this.refreshPackingMaterialTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating Packing Material', error);
      }
    });
  }
  async updatePackingMaterialStatus(row: any, status: any, softDelete = false) {
    const id = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this Packing material',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._inventoryService.updatePackingMaterialStatus(id, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'packing material')
        this.refreshPackingMaterialTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating user status:', error);
        this._appComponent.showErrorSwal('updating', 'packing material')
      }
    });
  }

  refreshPackingMaterialTableData() {
    this._inventoryService.getPackingMaterial().subscribe({
      next: (data: any) => {
        var packingMaterialProps = new PackingMaterial();

        this._packingMaterialTableData = data;
        this._packingMaterialTableDataList = data;
        this._packingMaterialTableColumns = Object.keys(packingMaterialProps)
          .filter((key) =>
            key === 'name' ||
            key === 'type' ||
            key === 'weight' ||
            key === 'volume' ||
            key === 'quantity' ||
            key === 'cost'
          )
          .map((key) => {
            let header: string;

            switch (key) {
              case 'name':
                header = 'Name';
                break;
              case 'type':
                header = 'Type';
                break;
              case 'weight':
                header = 'Weight';
                break;
              case 'volume':
                header = 'Volume';
                break;
              case 'quantity':
                header = 'Quantity';
                break;
              case 'cost':
                header = 'Cost';
                break;

              default:
                header = key;
                break;
            }
            return { header };
          });
      },
      error: (error: any) => {
        console.error("error getting packing material data", error);
      }
    });
  }
}
