export class DraftBLDTO {
    moveDetailId: number;
    shipperInfo: string | null;
    consigneeInfo: string | null;
    notifyParty: string | null;

    constructor() {
        this.moveDetailId = 0;
        this.shipperInfo = null;
        this.consigneeInfo = null;
        this.notifyParty = null;
    }
}