export class SurveyPlace {
  id: number;
  title: string;
  ownedBy: number;
  areas: string[];
  createdBy: number;
  isActive: boolean | null;

  constructor() {
    this.id = 0;
    this.title = '';
    this.ownedBy = 0;
    this.isActive = true;
    this.areas = [];
    this.createdBy = 0;
  }
}

export interface AllSurveyPlacesResponse {
  surveyPlaces: SurveyPlace[];
  totalRecords: number;
}

export class AreaItemsModel {
  id: number;
  ownedBy: number;
  surveyPlaceId: number | null;
  title: string;
  //areaItems: string[];
  createdBy: number;
  isDeleted: boolean;
  estimatedVolume: number;
  weight: number;
  dimension:string;

  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.surveyPlaceId = null;
    this.title = '';
    //this.areaItems =[];
    this.createdBy = 0;
    this.isDeleted = false;
    this.weight = 0;
    this.estimatedVolume = 0;
    this.dimension = '';
  }
}
export interface AreaItemsResponse {
  surveyItems: AreaItemsModel[];
  totalRecords: number;
}
