<!-- <ng-template #inventoryListTemplate>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
          <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo"
            width="150">
        </ng-container>
        <ng-template #defaultLogo>
          <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
        </ng-template>
      </div>

      <h1 class="text-center mt-3">Inventory List</h1>
      &nbsp;
      <h4> General Info</h4>
      <table class="table table-bordered mt-2" *ngIf="_packagingDetailDTO">
        <tbody>
          <tr>
            <td><label>Move ID</label></td>
            <td>#{{ _packagingDetailDTO?.moveDetailId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer ID</label></td>
            <td>#{{ _packagingDetailDTO?.customerId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer Name</label></td>
            <td>{{ _packagingDetailDTO?.customerName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Account Name</label></td>
            <td>{{ _packagingDetailDTO?.accountName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Move Type</label></td>
            <td>{{ enumMappingService.mapMoveType(_packagingDetailDTO?.moveType || 0) }}</td>
          </tr>
          <tr>
            <td><label>Move Category</label></td>
            <td>
              {{
              enumMappingService.mapMoveCategoryType(_packagingDetailDTO?.moveCategory
              || 0)
              }}
            </td>
          </tr>
          <tr>
            <td><label>Transport Type</label></td>
            <td>
              {{
              enumMappingService.mapTransportType(_packagingDetailDTO?.moveTransport ||
              0)
              }}
            </td>
          </tr>
          <tr>
            <td><label>Origin</label></td>
            <td>{{ _packagingDetailDTO?.origin || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Destination</label></td>
            <td>{{ _packagingDetailDTO?.destination || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>POE</label></td>
            <td>{{ _packagingDetailDTO?.portOfLoading|| ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Client Notes</label></td>
            <td>{{ _externalNotesInvent || ' - ' }}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4> Inventory List </h4>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description of Article</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of inventoryList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.description}}</td>
              <td>{{row.weight}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>Total Items: {{calculateTotal().totalItems}}</td>
            <td colspan="2">Total Weight: {{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</ng-template> -->

<!-- <ng-template #weightCertificateList>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
          <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo"
            width="150">
        </ng-container>
        <ng-template #defaultLogo>
          <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
        </ng-template>
      </div>

      <h1 class="text-center mt-3"> {{ _weightCertTitle}} </h1> 
      &nbsp;
      <h4> General Info</h4>
      <table class="table table-bordered mt-2" *ngIf="_packagingDetailDTO">
        <tbody>
          <tr>
            <td><label>Move ID</label></td>
            <td>#{{ _packagingDetailDTO?.moveDetailId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer ID</label></td>
            <td>#{{ _packagingDetailDTO?.customerId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer Name</label></td>
            <td>{{ _packagingDetailDTO?.customerName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Account Name</label></td>
            <td>{{ _packagingDetailDTO?.accountName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Move Type</label></td>
            <td>
              {{ enumMappingService.mapMoveType(_packagingDetailDTO?.moveType || 0) }}
            </td>
          </tr>
          <tr>
            <td><label>Move Category</label></td>
            <td>
              {{
              enumMappingService.mapMoveCategoryType(_packagingDetailDTO?.moveCategory
              || 0)
              }}
            </td>
          </tr>
          <tr>
            <td><label>Transport Type</label></td>
            <td>
              {{
              enumMappingService.mapTransportType(_packagingDetailDTO?.moveTransport
              ||
              0)
              }}
            </td>
          </tr>
          <tr>
            <td><label>Origin</label></td>
            <td>{{ _packagingDetailDTO?.origin || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Destination</label></td>
            <td>{{ _packagingDetailDTO?.destination || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>POE</label></td>
            <td>{{ _packagingDetailDTO?.portOfLoading|| ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Client Notes</label></td>
            <td>{{ _externalNotesWeight || ' - ' }}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4> Items List </h4>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Dimensions</th>
            <th>Gross Lbs</th>
            <th>Tare Lbs</th>
            <th>Net Lbs</th>
            <th>Volume Weight</th>
            <th>Seal Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of weightCertsList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.dimensions}}</td>
              <td>{{row.gross}}</td>
              <td>{{row.tare}}</td>
              <td>{{row.net}}</td>
              <td>{{row.volumeWeight}}</td>
              <td>{{row.sealNo}}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</ng-template> -->

<div class="row title-and-mutliple-moves-btn">
  <div class="col-md-4">
    <h5>DELIVERY FORM</h5>
  </div>
  <div class="col-md-8">
    <ng-container *ngFor="let packagingDetail of _packingBoardDTOList">
      <ng-container *ngIf="packagingDetail.leadId === _leadId">
        <button class="muliple-moves-btn float-end mt-1" (click)="showMoveById(packagingDetail)"
          [class.active]="packagingDetail.packagingDetailId === _packagingDetailId">
          {{ packagingDetail.moveTypeLabel }} -
          {{ packagingDetail.moveTransportLabel }}
          <i *ngIf="packagingDetail.moveTransport === 1" class="mdi mdi-airplane-takeoff"></i>
          <i *ngIf="packagingDetail.moveTransport === 2" class="mdi mdi-truck-delivery"></i>
          <i *ngIf="packagingDetail.moveTransport === 3" class="mdi mdi-ferry"></i>
          <i *ngIf="packagingDetail.moveTransport === 4" class="mdi mdi-ferry"></i>
        </button>
        &nbsp;
      </ng-container>
    </ng-container>
    &nbsp;
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-12 order-md-1">
    <div class="box-left border border-dark-grey p-3 custom-rounded">
      <div class="row">
        <app-lead-info-header [type]="_moveDetail" [refId]="_moveDetailId"
          [assignmentType]="_assignmentType"
          [assignmentRefId]="_moveDetailId"></app-lead-info-header>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 order-md-1 mt-3">
        <ul ngbNav #defaultNav="ngbNav" class="nav-tabs" [(activeId)]="defaultNavActiveId">
          <li [ngbNavItem]="1">
            <a ngbNavLink (click)="onChangeTab(1)" class="container">
              <span class="text">INVENTORY LIST</span>
            </a>
            <ng-template ngbNavContent>
              <ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-2"
                [(activeId)]="defaultNavActiveIdForType">
                <li [ngbNavItem]="1">
                  <a ngbNavLink (click)="onChangeTabForType(0)" class="nav-tab sub-tabs">
                    <span class="text">All</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <table class="custom-table-sm border border-gray">
                          <thead>
                            <tr>
                              <th>Item No #</th>
                              <th>Description Of Item</th>
                              <th>Weight ({{_weightUnit}})</th>
                              <th>Category</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="_inventoryList.length === 0">
                              <td colspan="5" class="text-center">No Items available</td>
                            </tr>
                            <tr *ngFor="let row of _inventoryList; let index = index">
                              <ng-container *ngIf="row.isDeleted === false">
                                <td>{{row.itemNo}}</td>
                                <td>{{row.description}}</td>
                                <td>{{row.weight}}</td>
                                <td>{{ enumMappingService.mapPackingType(row.packagingType) }}
                                </td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink (click)="onChangeTabForType(ePackagingType.AssembleDisassemble);"
                    class="nav-tab sub-tabs">
                    <span class="text"> Assemble / Disassemble Items </span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <table class="custom-table-sm border border-gray">
                          <thead>
                            <tr>
                              <th>Item #</th>
                              <th>Description of Item </th>
                              <th> Weight ({{_weightUnit}})</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="_assembleDisassembleList.length === 0">
                              <td colspan="4" class="text-center">No Items available</td>
                            </tr>
                            <tr *ngFor="let row of _assembleDisassembleList; let index = index">
                              <td>{{row.itemNo}}</td>
                              <td>{{row.description}}</td>
                              <td>{{row.weight}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink (click)="onChangeTabForType(ePackagingType.CratedItem)"
                    class="nav-tab sub-tabs">
                    <span class="text"> Crated Items </span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <table class="custom-table-sm border border-gray">
                          <thead>
                            <tr>
                              <th>Item No #</th>
                              <th>Description of Item</th>
                              <th>Dimensions</th>
                              <th>Cuft</th>
                              <th>Weight</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="_cratedItemList.length === 0">
                              <td colspan="7" class="text-center">No Items available</td>
                            </tr>
                            <tr *ngFor="let row of _cratedItemList; let index = index">
                              <ng-container
                                *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.CratedItem)">
                                <td>{{row.itemNo}}</td>
                                <td>{{row.description}}</td>
                                <td>{{row.dimensions}}</td>
                                <td>{{row.cuft}}</td>
                                <td>{{row.weight}}</td>
                                <td>{{row.remarks}}</td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4">
                  <a ngbNavLink (click)="onChangeTabForType(ePackagingType.Electronic)"
                    class="nav-tab sub-tabs">
                    <span class="text"> Electronic Items </span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <table class="custom-table-sm border border-gray">
                          <thead>
                            <tr>
                              <th>Item No #</th>
                              <th>Description</th>
                              <th>Make</th>
                              <th>Model / Serial</th>
                              <th>Weight</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="_electronicList.length === 0">
                              <td colspan="7" class="text-center">No Items available</td>
                            </tr>
                            <tr *ngFor="let row of _electronicList; let index = index">
                              <ng-container
                                *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.Electronic)">
                                <td>{{row.itemNo}}</td>
                                <td>{{row.description}}</td>
                                <td>{{row.make}}</td>
                                <td>{{row.model}}</td>
                                <td>{{row.weight}}</td>
                                <td>{{row.remarks}}</td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink (click)="onChangeTabForType(ePackagingType.HighValue)"
                    class="nav-tab sub-tabs">
                    <span class="text"> High Value Items</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="row">
                      <div class="col-12 mt-3">
                        <table class="custom-table-sm border border-gray">
                          <thead>
                            <tr>
                              <th>Item No #</th>
                              <th>Description of Item</th>
                              <th>Dimensions</th>
                              <th>Weight</th>
                              <th>Value in ({{_currency}})</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="_highValueList.length === 0">
                              <td colspan="6" class="text-center">No Items available</td>
                            </tr>
                            <tr *ngFor="let row of _highValueList; let index = index">
                              <ng-container
                                *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.HighValue)">
                                <td>{{row.itemNo}}</td>
                                <td>{{row.description}}</td>
                                <td>{{row.dimensions}}</td>
                                <td>{{row.weight}}</td>
                                <td>{{row.remarks}}</td>
                              </ng-container>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>

                </li>
                <div class="export-btn">
                  <button type="button" class="btn btn-sm btn-primary float-end"
                    (click)="downloadPdf()">
                    <i class="mdi mdi-file-export"></i> &nbsp; Export
                  </button>
                  &nbsp;
                  <button class="btn btn-sm btn-primary float-end me-2" (click)="viewPdf();">
                    <i class="mdi mdi-file-eye"> </i> &nbsp; View
                  </button>
                </div>
              </ul>

              <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>

              <div class="col-12 mt-5">
                <h6 class="heading"> &nbsp; BEFORE SIGNING, CHECK ITEMS CONDITION & DESCRIBE LOSS OR
                  DAMAGE(IF ANY)</h6>
                <div class="internal-notes-box mt-2">
                  <textarea class="form-control" rows="3" placeholder="External Notes"
                    [disabled]="true" [(ngModel)]="_externalNotesInvent"></textarea>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="custom-margin">
                      <div class="signature-box">
                        <div class="row">
                          <div class="col-6">
                            <div><label>Customer Signature</label></div>
                            <canvas #customerSingatureCanvasInvent class="signature-pad"></canvas>
                          </div>
                          <div class="col-6">
                            <div><label>Suvery Manager Signature</label></div>
                            <canvas #surveyMgrSingatureCanvasInvent class="signature-pad"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

          </li>
          <li [ngbNavItem]="2" *ngIf="_showWeightCertificate">
            <a ngbNavLink (click)="onChangeTab(2)" class="container">
              <span class="text">{{_weightCertTitle}}</span>
            </a>
            <ng-template ngbNavContent>
              <app-delivery-weight-certificate
                [packagingDetailId]="_packingDetail.packagingDetailId" [activeTab]="2"
                [weightCertificateTitle]="_weightCertTitle"
                [leadId]="_leadId"></app-delivery-weight-certificate>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink (click)="onChangeTab(3)" class="container">
              <span class="text">BINGO SHEET</span>
            </a>
            <ng-template ngbNavContent>
              <div class="mt-3">
                <h2>Delivery Acknowledgment</h2>

                <ng-container *ngIf="_inventoryList.length > 0; else noItems">
                  <p>
                    Click on the item number to acknowledge its presence. The button color
                    indicates acknowledgment status.
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex flex-wrap">
                        <div *ngFor="let row of _inventoryList; let index = index"
                          class="me-2 mb-2">
                          <ng-container *ngIf="row.id && row.isDeleted === false">
                            <div class="d-flex align-items-center">
                              <button
                                [class]="'btn btn-sm ' + (row.deliveryBingoBit  ? 'btn-primary' : 'btn-secondary')"
                                (click)="updateBingo(row)">
                                {{ row.itemNo }}
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #noItems>
                  <p>No items in the delivery.</p>
                </ng-template>
              </div>

            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink (click)="onChangeTab(4)" class="container">
              <span class="text">APPRAISAL FORM</span>
            </a>
            <ng-template ngbNavContent class="hidden-pdf-content">
              <app-appraisal-form [packagingDetailId]="_packingDetail.packagingDetailId"
                [appraisalType]="_appraisalType"></app-appraisal-form>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="_activeTab === 1">
  <hr>
  <div #pdfContentInventory class="hidden-pdf-content ">
    <ng-container [ngTemplateOutlet]="inventoryListTemplate"></ng-container>
  </div>
</div> -->

<!-- <div *ngIf="_activeTab === 2">
  <hr>
  <div #pdfContentWeight>
    <ng-container [ngTemplateOutlet]="weightCertificateList"></ng-container>
  </div>
</div> -->


<!-- <div class="col-md-3 order-md-2 mt-2">
  <div class="mt-3 border border-dark-grey custom-rounded order-md-2 box-right">
    <app-moves-status [refId]="_packingDetail.id" [moveCategory]="_packingDetail.categoryType"
      [statusType]="_statusType" [version]="_statusVersion"></app-moves-status>
  </div>
</div> -->