export class MoveSunmmarizeReportDTO {
  date: Date | null;
  completed: number | null;
  cancelled: number | null;
  reverted: number | null;
  local: number | null;
  import: number | null;
  export: number | null;
  totalMoves: number | null;

  constructor() {
    this.date = null;
    this.completed = null;
    this.cancelled = null;
    this.reverted = null;
    this.local = null;
    this.import = null;
    this.export = null;
    this.totalMoves = null;
  }
}