
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ContactService } from '../../../../../services/contact.service';
import { AppComponent } from '../../../../app.component';
import { DashboardItem } from '../../../movd/models/dashboard-item.model';
import { MovdService } from '../../../movd/services/movd.service';
import { CustomerDTO, DealDetailsDTO, TasksModel, TasksResponse } from '../profile.models';
import { AuthService } from 'src/services/auth.service';
import { LeadBoardDTO, LeadBoardResponse } from 'src/app/views/movd/lead-board/lead-board.model';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnChanges {
  @Input() customerId: any;
  @Input() isDeal: any;
  @Input() type: any;
  @Output() taskAdded: EventEmitter<TasksModel> = new EventEmitter<TasksModel>();
  _tasksList: TasksModel[] = [];

  _profile = new CustomerDTO();
  _taskUpdate: boolean = false;
  _saveTaskTitle: string = 'Create';
  _task = new TasksModel();
  // for showing current time
  selectedDate!: string;
  selectedTime!: string;
  dashboardItems: DashboardItem[] = [];

  _companyUsers: any[] = [];
  _companyId: number = 0;
  _currentUserId: number = 0;

  // for showing current time

  hoveredDate: NgbDateStruct | null = null;
  _statsFromDate: string | Date | null = null;
  _statsToDate: string | Date | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  _allLeadsList: LeadBoardDTO[] = [];
  calendarOptions: CalendarOptions | undefined;
  _selectedUserId: number | null = 0;
  _selectedCustomerId: number | null = 0;

  constructor(
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private _contactService: ContactService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private calendar: NgbCalendar,
    private _router: Router,) {

    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customerId'] || changes['isDeal'] || changes['type']) {
      if (changes['customerId']) {
        this.customerId = changes['customerId'].currentValue;
      }
      if (changes['type']) {
        this.type = changes['type'].currentValue;
      }

      if (changes['isDeal']) {
        this.isDeal = changes['isDeal'].currentValue;
      }

      this.getCompanyUsers()
      this.getLeadsForBoardById();
      this.fetchData('Task');

    }
  }

  getLeadsForBoardById() {
    this._movdService.getLeadsForBoard(null, null, null).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log("leads for baord", data);
        if (data.leads && data.leads.length > 0) {
          this._allLeadsList = data.leads;
          // console.log('survey board', this._allLeadsList);
          this.calendarOptions = {
            initialView: 'dayGridMonth', // Set the initial view
            events: this.getEvents(), // Call a function to fetch and format your events
          };
          /* this.dashboardItems = this.createDashboardItems(this._allLeadsList);*/
          // console.log("dashboard items", this.dashboardItems);
        }
      },
      error: (error) => {
        console.error("error getting lead for board", error);
      }
    });
  }
  getEvents() {
    // Initialize an array to store FullCalendar events
    const eventData: { title: string; start: any; end: any; color: string; }[] = [];

    this._allLeadsList.forEach((lead) => {
      if (lead.surveyDate) {
        eventData.push({
          title: 'Survey',
          start: lead.surveyDate,
          end: lead.surveyDate,
          color: 'blue',
        });
      }

      if (lead.packagingDateFrom) {
        eventData.push({
          title: 'Packing',
          start: lead.packagingDateFrom,
          end: lead.packagingDateTo, // You may want to use lead.packagingDateTo for the end date
          color: 'green', // Customize event color if needed
        });
      }
    });

    return eventData;
  }


  openTasksModal(content: TemplateRef<any>, id = 0) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);

    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);
  }

  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }
  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }
  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }
  editTask(dataItem: any) {
    this._selectedCustomerId = null;
    this._selectedUserId = null;

    this._task.id = dataItem.id;
    this._task.title = dataItem.title;
    this._task.details = dataItem.details;
    this._task.activityDate = dataItem.activityDate;
    this._task.status = dataItem.status;
    this._task.assignedto = dataItem.assignedto;
    this._task.dueDate = this.formatTaskDate(dataItem.dueDate);
    this._task.done = dataItem.done;
    this._task.priority = dataItem.priority;
    this._task.queue = dataItem.queue;
    this._task.type = dataItem.type;

    if (dataItem.assignedto) {
      this._selectedUserId = dataItem.assignedto;
    }

    if (dataItem.customerId) {
      this._selectedCustomerId = dataItem.customerId;
    }
  }
  async deleteTask(id: any) {
    await this.confirmAndDelete("Task", id);
  }
  async confirmAndDelete(type: string, id: any) {
    const confirmation = confirm(`Are you sure you want to delete this ${type}?`);

    if (!confirmation) {
      return;
    }

    try {
      switch (type) {
        case 'Task':
          await this._contactService.deleteTask(id).toPromise();
          break;
        default:
          break;
      }
      this._appComponent.showSuccessSwal('Deleted', type);
      if (type === 'Task') {
        await this.fetchData('Task');
      }
    } catch (error: any) {
      console.error(`error deleting ${type}`);
      this._appComponent.showErrorSwal('Deleting', type);
    }
  }


  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }


  getCompanyUsers() {

    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }

    this.saveData(this._task, 'Task');
  }
  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;

    if (this.type == 'profile') {
      if (this._task.assignedto != null) {
        entity.customerId = this.customerId;
        entity.assignedto = this._selectedUserId;
      }
      else {
        entity.customerId = this.customerId;
        entity.assignedto = this._currentUserId;
      }
    }
    else {
      if (this._task.assignedto != null) {
        entity.assignedto = this._selectedUserId;
        entity.customerId = this._selectedCustomerId;
      }
      else {
        entity.assignedto = this._currentUserId;
        entity.customerId = null;
      }
    }
    if (this.isDeal) {
      entity.dealId = this.customerId
    }

    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this.selectedDate}T${this.selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();

    // console.log('saving task data', entity);

    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);
            this.taskAdded.emit(entity); // Emit the newly saved task data
          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }
  async fetchData(type: string): Promise<void> {
    try {
      // console.log('fetching tasks');
      let data: TasksResponse | undefined;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          // console.log(`current cust id ${this.customerId} && isDeal${this.isDeal}`);
          if (this.type == 'profile') {
            data = await this._contactService.getTasks(this.customerId, this.isDeal, null, null, null).toPromise();
          }
          else {
            data = await this._contactService.getTasks(this._currentUserId, this.isDeal, null, null, null).toPromise();
          }
          // console.log("tasks list", data);

          // this._tasksList = data;
          break;
      }
      if (data && data.tasks && data.tasks.length > 0) {
        this._tasksList = data.tasks;
        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        (this as any)[listName] = data;

        (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = this._tasksList.length === 1 ? this._tasksList[0] : this._tasksList[this._tasksList.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }

        // Get company users
        let companyUsers = await this._authService.getUsersOfCompany().toPromise();

        // Add assignedToUser to each task
        for (let task of this._tasksList) {
          if (companyUsers && companyUsers.length > 0) {
            const assignedUser = companyUsers.filter(user => user.id === task.assignedto)[0];
            // console.log("single assigned user", assignedUser);

            if (assignedUser) {
              task.assignedToName = assignedUser.customerName;
            } else {
              task.assignedToName = null;
            }
          } else {
            task.assignedToName = null;
          }

        }

      } else {
        this._tasksList = []
      }
      // console.log('fetching tasks lissts', this._tasksList);
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }
  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }

  todoTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => task.done);
  }
  doneTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => !task.done);
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
  onUserSelect(event: any) {
    const selectedUserId = this._task.assignedto;
    const selectedUser = this._companyUsers.find(user => user.id === selectedUserId);
    if (selectedUser) {
      this._selectedUserId = selectedUser.id;
      this._selectedCustomerId = selectedUser.customerId;
    } else {
      this._selectedUserId = null;
      this._selectedCustomerId = null;
    }

  }

}
