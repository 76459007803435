<div class="card rounded">
  <div class="card-header">
    <div class="row">
      <div class="col-md-3 col-sm-auto align-right">
        <input id="table-complete-temp" type="text" class="form-control" name="searchTemp"
          placeholder="Search..." [(ngModel)]="_searchTemp" (keyup)="performTempsSearch()" />
      </div>
      <div class="col-md-9 col-md-auto text-end">
        <button class="btn btn-xs btn-primary btn-icon-text" (click)="addNewTemplate()"
          style="margin-left: 3px; width: 155px">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
          </lord-icon>
          &nbsp; Add New Template
        </button>
      </div>
    </div>
    &nbsp;


    <div class="row" *ngIf="_tempTableData.length === 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <p>No Templates, currently. Please Add an Template.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="_tempTableData.length > 0">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <ngx-datatable [rows]="_tempTableData" class="bootstrap" [loadingIndicator]="true"
                [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10" [pagination]="true"
                [pageSize]="10" [rowHeight]="40">
                <ngx-datatable-column *ngFor="let column of _tempTableColumns"
                  [name]="column.header">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div *ngIf="column.header === 'Template Name'">
                      <div class="d-flex align-items-center hover"
                        (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                        {{ row.title}}
                        <button
                          class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                          *ngIf="row.isHovered" (click)="TempPreviewClick(row.id)">
                          Preview
                        </button>
                      </div>
                    </div>
                    <div *ngIf="column.header !== 'Template Name' && column.header !== 'Body'">
                      {{ value }}
                    </div>
                    <!-- <div *ngIf="column.header !== 'Template Name' && column.header !== 'Subject'">
                      {{ value }}
                    </div> -->
                  </ng-template>

                </ngx-datatable-column>

                <ngx-datatable-column name="Action">
                  <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                    let-value="value">
                    <button type="button" class="btn btn-xs datatable-action-cell"
                      [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                      (click)="updateTempStatus(row, row.isActive)">
                      {{ !row.isActive ? "Activate" : "Deactivate" }}
                    </button>

                    <span style="margin-right: 10px;"></span>

                    <button type="button" class="btn btn-xs datatable-action-cell btn-danger"
                      (click)="deleteTemplate(row)">
                      Delete
                    </button>
                  </ng-template>
                </ngx-datatable-column>




              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>




<ng-template #newTemplateModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addRoleModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp; {{_title}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mt-2">
      <div class="col-md-12">
        <label class="form-label fw-bold">Template Type</label>
      </div>
      <div class="col-md-12">
        <ng-select [(ngModel)]="_tempTitle" name="title" required
          placeholder="Select Template Type">
          <ng-option *ngFor="let template of templates"
            [value]="template.value">{{template.label}}</ng-option>
        </ng-select>
        <div *ngIf="!_tempTitle" class="error-message error" style="color: red">
          * Please select a template type
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-0">
      <div class="col-md-12">
        <label class="form-label fw-bold">Subject</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" [(ngModel)]="_tempSubject" name="subject">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="form-label fw-bold">Body Text</label>
      </div>
      <div class="col-md-12">
        <quill-editor [(ngModel)]="_tempBody" [modules]="editorConfig"></quill-editor>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTemplate(modal)"
      [disabled]="!_tempTitle">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal('Temp', modal);">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>