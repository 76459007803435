import { eProcessStatus } from "./lead-detail.model";

export class PackagingDetail {
  id: number;
  leadId: number;
  leadDetailId: number;
  moveDetailId: number;
  packagingDateFrom: Date | null;
  packagingDateTo: Date | null;
  additionalNotes: string | null;
  internalNotes: string | null;
  ownedBy: number;
  creationDate: Date;
  createdBy: number;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;
  packingStatus: eProcessStatus;
  deliveryStatus: eProcessStatus;

  constructor() {
    this.id = 0;
    this.leadId = 0;
    this.leadDetailId = 0;
    this.moveDetailId = 0;
    this.packagingDateFrom = null;
    this.packagingDateTo = null;
    this.additionalNotes = null;
    this.internalNotes = null;
    this.ownedBy = 0;
    this.creationDate = new Date();
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
    this.packingStatus = eProcessStatus.New;
    this.deliveryStatus = eProcessStatus.New;
  }
}

export const packingListColumns: string[] = [
  "Packing ID",
  "Lead ID",
  "Move ID",
  "Account",
  "Customer",
  "Origin",
  "Destination",
  "Move",
  "POE",
  "POC",
  "Third Party",
  "Third Party POC",
  "Move Category",
  "Transport Type",
  "Warehouse Storage",
  "Survey Date",
  "Pack Date",
  "POE Date",
  "Loading Date",
  "Storage Date",
  "Delivery Date",
  "Account Manager"
];

export const deliveryListColumns: string[] = [
  "Deliv. ID",
  "Lead ID",
  "Move ID",
  "Account",
  "Customer",
  "Origin",
  "Destination",
  "Move",
  "POE",
  "POC",
  "Third Party",
  "Third Party POC",
  "Move Category",
  "Transport Type",
  "Warehouse Storage",
  "Survey Date",
  "Pack Date",
  "POE Date",
  "Loading Date",
  "Storage Date",
  "Delivery Date",
  "Account Manager"
];
