<h5 class="dashboard-heading">TEMPLATE SETTINGS</h5>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="nav-tab">
      <span class="text"> EMAIL </span>
    </a>
    <ng-template ngbNavContent>
      <app-email-template></app-email-template>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="nav-tab">
      <span class="text"> QUOTATION </span>
    </a>
    <ng-template ngbNavContent>
      <app-quotation-setting></app-quotation-setting>
    </ng-template>


  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeTab(3)" class="nav-tab">
      <span class="text"> INVOICING </span>
    </a>
    <ng-template ngbNavContent>
      <div>
        <h3>Section Under development </h3>
        <p>This section is currently under development. Stay tuned for updates!</p>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(4)" class="nav-tab">
      <span class="text">APPRAISAL FORM</span>
    </a>
    <ng-template ngbNavContent>
      <app-appraisal-settings></app-appraisal-settings>
    </ng-template>
  </li>
</ul>



<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>