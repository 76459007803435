import { Country } from "../../movd/models/address.model";

export class OptionModel {
  id: number;
  value: string;

  constructor() {
    this.id = 0;
    this.value = '';
  }
}

// export const _contactColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "ACCOUNT", "POSITION", "TYPE"];
export const _contactColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "ACCOUNT", "POSITION", "TYPE"];
export const _compColumns: string[] = ["NAME", "PHONE", "EMAIL", "CITY", "COUNTRY", "OWNER"];
export const _dealsColumns: string[] = ["Name", "Contact Name", "Created On", "Deal Owner", "Amount", "Probability", "Sales Rep", "Last Contact"];


export interface ContactsResponse {
  contacts: Customer[];
  totalRecords: number;
}



export class Customer {
  id: number = 0;
  customerName: string | null;
  phone: string | null;
  companyId: number | null;
  role: string | null;
  industryId: number | null;
  compOwnerId: number | null;
  email: string | null;
  fax: string | null;
  dateOfBirth: Date | null;
  details: string | null;
  contactType: eContactType | null;
  salesType: eSalesType | null;
  companyURL: string | null;
  linkedInURL: string | null;
  noOfEmployees: string | null;
  openPositions: number | null;
  ownedBy: number | null;
  createdBy: number | null;

  constructor() {
    this.id = 0;
    this.customerName = null;
    this.phone = null;
    this.companyId = null;
    this.role = null;
    this.industryId = null;
    this.compOwnerId = null;
    this.email = null;
    this.fax = null;
    this.dateOfBirth = null;
    this.details = null;
    this.contactType = null;
    this.salesType = null;
    this.companyURL = null;
    this.linkedInURL = null;
    this.noOfEmployees = null;
    this.openPositions = null;
    this.ownedBy = null;
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
  }
}


export class Address {
  id: number;
  customerId: number | null;
  phoneNo: string | null;
  email: string | null;
  fax: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  country: Country | null;


  constructor() {
    this.id = 0;
    this.customerId = null;
    this.phoneNo = null;
    this.email = null;
    this.fax = null;
    this.streetAddress = null;
    this.zipCode = '';
    this.city = null;
    this.state = null;
    this.country = null;
  }
}

export enum eSalesType {
  Sales = 0,
  Partner = 1,
  Vendor = 3
}

export enum eContactType {
  Contact = 0,
  Company = 1,
}

export interface EnumOption {
  id: number;
  value: string;
}

export const eSalesTypeOptions: EnumOption[] = [
  { id: eSalesType.Sales, value: 'Sales' },
  { id: eSalesType.Partner, value: 'Partner' },
  { id: eSalesType.Vendor, value: 'Vendor' },
];