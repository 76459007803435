<ng-template #movesFilterContent>
  <div class="row">
    <div class="col-md-12 ">
      <button type="button" class="btn btn-primary btn-sm float-end mt-2" (click)="getReports()">
        <i class="mdi mdi-file-document"></i> Generate Report
      </button>
      <button
        class="btn btn-secondary-outline btn-clear btn-sm btn-border float-end mt-2 me-3  d-block"
        (click)="toggleSection()">
        <i class="mdi mdi-filter-outline"></i>
        Filter Report
        <i [ngClass]="showSection ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
      </button>
      <button class="btn btn-secondary-outline btn-clear btn-sm btn-border float-end mt-2 me-3"
        (click)="clearFilter()">
        <i class="mdi mdi-filter-remove-outline"></i>
        Clear Filter
      </button>

    </div>
    <div class="section" [ngClass]="{ 'd-none': !showSection }">
      <div class="row mt-3">
        <div class="col-md-4 ">
          <div class="input-group range-selection">
            <!-- <label class="form-label fw-bold ">
        &nbsp; Select Date
      </label> -->
            <input type="text" class="form-control clickable custom-border"
              placeholder="Select Date Range" name="datepicker" ngbDatepicker
              #datepicker="ngbDatepicker"
              [value]="generalService.formatDate(_moveReports.fromDate, _moveReports.toDate)"
              (dateSelect)="onDateSelect($event)" [autoClose]="'outside'" [displayMonths]="2"
              [dayTemplate]="daysTemplate" outsideDays="hidden" [startDate]="fromDate!"
              (click)="datepicker.toggle()" tabindex="-1" searchable="false">

            <button class="input-group-text custom-border" type="button" title="icon-calendar"
              (click)="datepicker.toggle()">
              <i class="feather icon-calendar icon-md" [style.color]="'#1AC0A1'"></i>
            </button>

            <ng-template #daysTemplate let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="col-md-4">

          <div class="input-group range-selection" *ngIf="!customerSatisfaction">
            <input type="text" class="form-control clickable" placeholder="Enter Your Origin"
              [(ngModel)]="_moveReports.origin" />
          </div>
        </div>
        <div class="col-md-4" *ngIf="!customerSatisfaction">

          <div class="input-group range-selection">
            <input type="text" class="form-control clickable" placeholder="Enter Your Destination"
              [(ngModel)]="_moveReports.destination" />
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!customerSatisfaction">
        <div class="col-md-4 ">
          <ng-select [items]="_moveType" bindLabel="value" bindValue="id" [searchable]="true"
            [(ngModel)]="_moveReports.moveType" placeholder="Select Move Type">
          </ng-select>
        </div>

        <div class="col-md-4" *ngIf="!customerSatisfaction">
          <ng-select [items]="_moveCategory" bindLabel="value" bindValue="id" [searchable]="true"
            placeholder="Select Move Category" (change)="onChangeCategory($event)"
            [(ngModel)]="_moveReports.moveCategory">
          </ng-select>
        </div>
        <div class="col-md-4" *ngIf="!customerSatisfaction">
          <ng-select [items]="_transportType" bindLabel="value" bindValue="id" [searchable]="true"
            [multiple]="true" (change)="onChangeTransport($event)"
            placeholder="Select Transport Type (can select multiples)"
            [(ngModel)]="_moveReports.transportTypes">
          </ng-select>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!customerSatisfaction">
        <div class="col-md-4">
          <ng-select (change)="filterContacts('thirdParty', _selectedThirdPartyIds)"
            [items]=" _companies" bindLabel="customerName" bindValue="id" [searchable]="true"
            [multiple]="true" [(ngModel)]="_selectedThirdPartyIds"
            placeholder="Select Third Party (can select multiples)">
          </ng-select>
        </div>
        <div class="col-md-4" *ngIf="!customerSatisfaction">
          <ng-select (change)="filterContacts('contact', _selectedAccountIds)" [items]=" _companies"
            bindLabel="customerName" bindValue="id" [searchable]="true" [multiple]="true"
            placeholder="Select Account (can select multiples)" [(ngModel)]="_selectedAccountIds">
          </ng-select>
        </div>
        <div class="col-md-4" *ngIf="!customerSatisfaction">
          <input type="text" class="form-control clickable" placeholder="Enter Account Manager Name"
            [(ngModel)]="_moveReports.accountManagerName" />
        </div>
      </div>

    </div>
  </div>
</ng-template>
<ng-template #leadsSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_leadSummarizeReportData" [sorts]="leadSummarizeTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of leadSummarizeTableColumns" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }"
        [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'New'">
            {{ row.new || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'InProgress'">
            {{ row.inProgress || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Won'">
            {{ row.won || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Lost'">
            {{ row.lost || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Total Leads'">
            {{ row.totalLeads || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>
<ng-template #leadsDetailReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_leadDetailReportData" [sorts]="leadDetailTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of leadDetailTableColumns" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }"
        [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Lead ID' && column.prop === 'leadDetailId'">
            {{ generalService.formatId(row.leadDetailId, 'lead') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Account'">
            {{ row.account || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Customer'">
            {{ row.customer || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Status'">
            {{ row.status || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Origin'">
            {{ row.origin || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Destination'">
            {{ row.destination || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>
<ng-template #movesSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_moveSummarizeReportData" [sorts]="moveSummarizeTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of moveSummarizeTableColumns" [name]="column.header"
        [prop]="column.prop" [width]="40" class="thead" [style]="{ border: '1px solid black' }"
        [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Completed'">
            {{ row.completed || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Cancelled'">
            {{ row.cancelled || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Reverted'">
            {{ row.reverted || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Local'">
            {{ row.local || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Import'">
            {{ row.import || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Export'">
            {{ row.export || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Total Moves'">
            {{ row.totalMoves || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>
<ng-template #movesDetailReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_moveDetailReportData" [sorts]="moveDetailTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of moveDetailTableColumns" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }"
        [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Move ID'">
            {{ generalService.formatId(row.moveId, 'move') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Account'">
            {{ row.account || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Customer'">
            {{ row.customer || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Status'">
            {{ row.status || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Origin'">
            {{ row.origin || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Destination'">
            {{ row.destination || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>
<ng-template #leadToMoveSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_leadToMoveSummarizedReportData"
      [sorts]="leadToMoveSummarizeTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of leadToMoveSummarizeTableColumns"
        [name]="column.header" [prop]="column.prop" class="thead"
        [style]="{ border: '1px solid black' }" [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Total Leads'">
            {{ row.totalLeads || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Total Moves'">
            {{ row.totalMoves || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Lead To Move Converstion Rate'">
            {{ row.leadToMoveRate || 0 }} %
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>
<ng-template #leadToMoveDetailReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_leadToMoveDetailReportData" [sorts]="leadToMoveDetailTableColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode"
      [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
      [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of leadToMoveDetailTableColumns"
        [name]="column.header" [prop]="column.prop" class="thead"
        [style]="{ border: '1px solid black' }" [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Lead ID'">
            {{ generalService.formatId(row.leadDetailId, 'lead') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Account'">
            {{ row.account || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Customer'">
            {{ row.customer || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Origin'">
            {{ row.origin || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Destination'">
            {{ row.destination || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Status'">
            {{ row.status || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Lost Reason'">
            <div class="lostReason-wrapper " [ngbPopover]="row.lostReason" triggers="hover">
              {{ row.lostReason || '-' }}
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ul ngbNav #moduleTabNav="ngbNav" class="nav-tabs mt-2" [(activeId)]="_moduleActiveTabId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeReportType(1,eMoveReportType.TotalLeads)"
      class="nav-tab sub-tabs">
      <span class="text">Total Leads</span>
    </a>
    <ng-template ngbNavContent>
      <ul ngbNav #reportTabNav1="ngbNav" class="nav-tabs mt-2" [(activeId)]="_reportActiveTabId1">
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="onChangeLeadReportSubType(1, eReportSubType.SummarizeReport)"
            class="nav-tab sub-tabs">
            <span class="text">Summarized Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="leadsSummarizeReport"></ng-container>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="onChangeLeadReportSubType(2, eReportSubType.DetailReport)"
            class="nav-tab sub-tabs">
            <span class="text">Detail Report</span>
          </a>

          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="leadsDetailReport"></ng-container>

          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="reportTabNav1" class="border border-top-0 p-3"></div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeReportType(2,eMoveReportType.TotalMoves)"
      class="nav-tab sub-tabs">
      <span class="text">Total Moves</span>
    </a>
    <ng-template ngbNavContent>
      <ul ngbNav #reportTabNav2="ngbNav" class="nav-tabs mt-2" [(activeId)]="_reportActiveTabId2">
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="onChangeMoveReportSubType(1, eReportSubType.SummarizeReport)"
            class="nav-tab sub-tabs">
            <span class="text">Summarized Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="movesSummarizeReport"></ng-container>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="onChangeMoveReportSubType(2, eReportSubType.DetailReport)"
            class="nav-tab sub-tabs">
            <span class="text">Detail Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="movesDetailReport"></ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="reportTabNav2" class="border border-top-0 p-3"></div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeReportType(3, eMoveReportType.LeadToMove)"
      class="nav-tab sub-tabs">
      <span class="text">Lead-To-Move Conversion Rate</span>
    </a>
    <ng-template ngbNavContent>
      <ul ngbNav #reportTabNav3="ngbNav" class="nav-tabs mt-2" [(activeId)]="_reportActiveTabId3">
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="onChangeLeadToMoveReportSubType(1, eReportSubType.SummarizeReport)"
            class="nav-tab sub-tabs">
            <span class="text">Summarized Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="leadToMoveSummarizeReport"></ng-container>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="onChangeLeadToMoveReportSubType(2, eReportSubType.DetailReport)"
            class="nav-tab sub-tabs">
            <span class="text">Detail Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="leadToMoveDetailReport"></ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="reportTabNav3" class="border border-top-0 p-3"></div>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeReportType(4, eMoveReportType.CustomerSatisfaction)"
      class="nav-tab sub-tabs">
      <span class="text">Customer Satisfaction</span>
    </a>
    <ng-template ngbNavContent>
      <app-customer-satisfaction [moveReports]="_moveReports"
        #customerSatisfaction></app-customer-satisfaction>
    </ng-template>

  </li>
</ul>
<ng-container *ngTemplateOutlet="movesFilterContent"></ng-container>
<div [ngbNavOutlet]="moduleTabNav" class="border border-top-0 p-3"></div>