import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { } // Inject Router to handle navigation

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token');
    const expiry = localStorage.getItem('token_expiry');

    // console.log("token", token);
    // console.log("expiry", expiry);

    if (token && expiry) {
      const now = new Date().getTime();
      // console.log("now time", now);
      // console.log("expiry time", expiry);

      if (now > +expiry) {
        // console.log("token expired");

        // Show SweetAlert message
        Swal.fire({
          title: 'Session Expired',
          text: 'Your session has expired. Please log in again.',
          icon: 'warning',
          confirmButtonText: 'OK'
        }).then(() => {
          // Clean up localStorage
          localStorage.removeItem('isLoggedin');
          localStorage.removeItem('access_token');
          localStorage.removeItem('token_expiry');

          // Optionally navigate to login page
          this.router.navigate(['login']);
        });

        // Optionally prevent further request processing
        return new Observable<HttpEvent<any>>(); // Return empty observable or handle appropriately
      }

      // Clone and add Authorization header
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log("setting token in the request");
    }

    return next.handle(req);
  }
}
