import { ChangeDetectorRef, Component, Input, NgZone, OnInit, SimpleChanges } from '@angular/core';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../models/companysetting.model';
import { MovdService } from '../services/movd.service';
import { EnumMappingService } from '../services/movd.enum.service';
import { GeneralService } from 'src/services/general.service';
import { MoveSurvey } from '../models/move-survery.model';
import { LeadDetail } from '../models/lead-detail.model';
import { SurveyMaterial } from '../models/survery-material.model';
import { Address } from '../../crm/contact/contact.models';
import { LeadBoardDTO } from '../lead-board/lead-board.model';

@Component({
  selector: 'app-survey-summary',
  templateUrl: './survey-summary.component.html',
  styleUrls: ['./survey-summary.component.scss']
})
export class SurveySummaryComponent implements OnInit {
  // Public Input property
  @Input() leadDetailId: any;

  // Private objects
  _companySetting = new CompanySetting(); // CompanySetting object
  _moveSurvey = new MoveSurvey(); // MoveSurvey object
  _leadDetail: LeadDetail = new LeadDetail(); // LeadDetail object
  // variables
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  _leadBoardItem = new LeadBoardDTO();
  // Arrays of SurveyMaterial objects
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _containers: SurveyMaterial[] = [];
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];
  _surveyMaterialsList!: SurveyMaterial[];
  _filteredAreaItems: SurveyMaterial[] = [];
  // Maps
  _surveyPlaceToItemMap: Map<string, string> = new Map();
  // Nullable variable
  _selectedSurveyPlace: string | null = null;
  // Boolean flag
  _isDataLoaded: boolean = false;
  // Constants
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  constructor(private _movdService: MovdService, public enumMappingService: EnumMappingService,
    public generalService: GeneralService, private _changeDetRef: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit(): void {
    // this.getLeadsForBoardById();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("lead survey", changes['leadDetailId'].currentValue);
    if (changes['leadDetailId']) {
      this.leadDetailId = changes['leadDetailId'].currentValue;
      // console.log('lead detail id', this.leadDetailId);
      if (this.leadDetailId && this.leadDetailId !== 0) {
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._containers = [];
        this._filteredAreaItems = [];
        this._surveyMaterialsList = [];
        this.ngZone.run(() => {
          // Your data update logic here
          this.getMoveSurveys();
          this.getCompanySetting();
          this.getLeadDetailById(this.leadDetailId);
          this.getLeadsForBoardById();
        });
      }
      this._changeDetRef.detectChanges();
    }
  }
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', this._currency);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }
  getLeadDetailById(leadDetailId: any) {
    this._movdService.getLeadDetailById(leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("one move detail", data);
        if (data) {
          this._leadDetail = data;
          if (this._leadDetail && this._leadDetail.addresses !== null) {
            const originAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            }
          }
        } else {
          this._leadDetail = new LeadDetail();
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  getMoveSurveys() {
    this._movdService.getMoveSurvey(this.leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("move survey list", data);
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;
          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              case 'Container':
                this._containers.push(material);
                break;
              // Handle other types if needed
              default:
                break;
            }
          });

          this.initializeMaterialRows();

          if (this._surveyPlaceToItemMap.size > 0) {
            this._selectedSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
            this.filterItemsBySurveyPlace(this._selectedSurveyPlace);
          }

          if (this._areaItems.length > 0) {
            this._areaItems.forEach((item) => {
              if (item.surveyPlace !== null && item.item) {
                this._surveyPlaceToItemMap.set(item.surveyPlace, item.item);
              }
            });

            // Get the first key from the map and call filterItemsBySurveyPlace
            const firstSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
            if (firstSurveyPlace) {
              this.filterItemsBySurveyPlace(firstSurveyPlace);
            }
          }
        }
        else {
          this._moveSurvey = new MoveSurvey();
          this.initializeMaterialRows();
        }

        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  initializeMaterialRows() {
    if (this._materials && this._materials.length === 0) {
      this.addRow('Material', this._materials);
    }
    if (this._labour && this._labour.length === 0) {
      this.addRow('Labour', this._labour);
    }
    if (this._vehicles && this._vehicles.length === 0) {
      this.addRow('Vehicle', this._vehicles);
    }
    if (this._containers && this._containers.length === 0) {
      this.addRow('Container', this._containers);
    }
  }
  getLeadsForBoardById() {
    this._movdService.getLeadsForBoard(null, null, null).subscribe({
      next: (data: any) => {
        this._isDataLoaded = false;
        if (data.leads && data.leads.length > 0) {
          // console.log("get lead for board", data);
          let filteredData = data.leads.find((leads: any) => leads.leadDetailId === this.leadDetailId);
          // console.log('dataa', this.leadDetailId);
          if (filteredData) {
            filteredData.surveryDate = filteredData.surveryDate ? this.generalService.formatDate(filteredData.surveyDate, null) : this.generalService.formatDate(filteredData.surveyDate, null);
            filteredData.packagingDate = filteredData.packagingDateFrom && filteredData.packagingDateTo ? this.generalService.formatDate(filteredData.packagingDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.packagingDateTo, null) : this.generalService.formatDate(filteredData.packagingDateFrom, null);
            delete filteredData.packagingDateFrom;
            delete filteredData.packagingDateTo;

            filteredData.loadingDate = filteredData.loadingDateFrom && filteredData.loadingDateTo ? this.generalService.formatDate(filteredData.loadingDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.loadingDateTo, null) : this.generalService.formatDate(filteredData.loadingDateFrom, null);
            delete filteredData.loadingDateFrom;
            delete filteredData.loadingDateTo;

            filteredData.storageDate = filteredData.storageDateFrom && filteredData.storageDateTo ? this.generalService.formatDate(filteredData.storageDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.storageDateTo, null) : this.generalService.formatDate(filteredData.storageDateFrom, null);
            delete filteredData.storageDateFrom;
            delete filteredData.storageDateTo;
          }

          this._leadBoardItem = filteredData;

        } else {
          this._leadBoardItem = new LeadBoardDTO();
        }

      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  addRow(type: string, targetArray: SurveyMaterial[]): void {
    const newMaterial = new SurveyMaterial();
    newMaterial.leadDetailId = this.leadDetailId;
    newMaterial.type = type;
    targetArray.push(newMaterial);
  }
  filterItemsBySurveyPlace(selectedSurveyPlace: string | null) {
    if (selectedSurveyPlace) {
      this._filteredAreaItems = this._areaItems.filter((item) => item.surveyPlace === selectedSurveyPlace);
    } else {
      this._filteredAreaItems = [];
    }
  }
  calculateVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }

  getItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }
}
