export class LeadsDetailReportDTO {
  date: Date | null;
  leadDetailId: number | null;
  account: string | null;
  customer: string | null;
  status: string | null;
  origin: string | null;
  destination: string | null;

  constructor() {
    this.date = null;
    this.leadDetailId = null;
    this.account = null;
    this.customer = null;
    this.status = null;
    this.origin = null;
    this.destination = null;
  }
}