import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Lead } from '../models/lead.model';
import { MovdService } from '../services/movd.service';
import { LeadDetail } from '../models/lead-detail.model';
import { NavigationExtras, Router } from '@angular/router';
import { MoveDetail } from '../models/move-detail.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SearchCustomerDTO } from '../interfaces/search-customer-dto';
import { Customer } from '../models/customer.model';
import { ContactService } from 'src/services/contact.service';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/services/auth.service';
import { GeneralService } from 'src/services/general.service';
import { eAssignmentType, UserAssignment } from '../models/userAssignment.model';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { LeadBoardDTO, LeadBoardResponse } from '../lead-board/lead-board.model';
import { MoveBoardDTO, MovesListResponse } from '../moves-board/moves-board.model';
import { eContactType } from '../../crm/contact/contact.models';

@Component({
  selector: 'app-lead-info-header',
  templateUrl: './lead-info-header.component.html',
  styleUrls: ['./lead-info-header.component.scss'],
})
export class LeadInfoHeaderComponent implements OnChanges {
  @ViewChild('editModal') editModal: any;
  @Input() refId: any;
  @Input() type: any;
  @Input() assignmentType: any;
  @Input() assignmentRefId: any;
  _editModalRef: NgbModalRef | undefined;
  // _moveDetailId: number = 0;
  // _leadId: number = 0;

  _leadDetail = new LeadDetail();
  _moveDetail = new MoveDetail();
  _isDataLoaded: boolean = false;
  _leadInfo: LeadBoardDTO | MoveBoardDTO = new LeadBoardDTO();
  _leadInternalNotes: any;
  _moveInternalNotes: any;

  _lead = new Lead();
  _userAssignment = new UserAssignment();
  _searchCustomer = new SearchCustomerDTO();
  _searchedCustomersList: SearchCustomerDTO[] = [];
  _leadEmail: string | null = null;
  _selectedAccountId: number | null = 0;
  _selectedThirdPartyId: number | null = 0;
  phone: any;
  _allCustomers: any = [];
  _filteredContactPOCs: Customer[] | null = [];
  _filteredTPPOCs: Customer[] | null = [];
  _contacts: any = [];
  _companies: any = [];
  _companyUsers: any[] = [];
  _selectedUserIds: number[] = [];
  _currentUserId: number = 0;
  _refIdForHeader: number = 0;
  _userAssigned: string | null = null;
  _companyId = 0;
  _eAssignmentType: eAssignmentType = eAssignmentType.Lead;
  _contentDisable: boolean = false;
  _assignToDisable: boolean = false;
  _userRole: string | null = null;
  _leadListUpdatePermission = false;
  _leadInfoFormDisabled = false;

  constructor(private router: Router,
    private _changeDetectRef: ChangeDetectorRef, private _movdService: MovdService,
    private _contactService: ContactService, private _modalService: NgbModal,
    private _appComponent: AppComponent, private _authService: AuthService, public generalService: GeneralService, private breadcrumbService: BreadcrumbService,
  ) {
    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this._userRole = localStorage.getItem('role');
  }
  // ngOnInit() {

  //   this.checkContentDisable();
  // }


  async ngOnChanges(changes: SimpleChanges) {
    const permissions = this._authService.getPermissionsFromLocalStorage();
    this._leadListUpdatePermission = permissions.includes('Leads List.Update') || this._userRole === 'CompanyAdmin';
    // Check if 'refId' or 'type' has changed
    if (changes['refId'] || changes['type'] || changes['assignmentType'] || changes['assignmentRefId']) {
      // Check if 'refId' and 'type' are both defined
      if (changes['refId']) {
        // console.log("ref id", this.refId);
        this.refId = changes['refId'].currentValue;
      }

      if (changes['type']) {
        // console.log("type", this.type);
        this.type = changes['type'].currentValue;
      }
      if (this.refId && this.type) {
        console.log("ref id and type", this.refId, this.type);
        if (this.type === 'leadDetail') {
          this.getLeadDetailById();
        } else if (this.type === 'moveDetail') {
          this.getMoveDetailById();
        }
      }
      if (changes['assignmentType']) {
        // console.log("type", this.type);
        this.assignmentType = changes['assignmentType'].currentValue;
        if (this.assignmentType === 'leadDetail') {
          this._eAssignmentType = eAssignmentType.Lead;
        }
        else if (this.assignmentType === 'moveDetail') {
          this._eAssignmentType = eAssignmentType.Move;
          // this._leadInfoFormDisabled = true;

          this.getMoveDetailById();
        }
      }
      if (changes['assignmentRefId']) {
        // console.log("type", this.type);
        this.assignmentRefId = changes['assignmentRefId'].currentValue;
        this._refIdForHeader = this.assignmentRefId;
      }
    }
    await this.getCompanyUsers();
    this.getCustomers();
    this.getUserAssignmentByRefId();
    // console.log('content disable', this._contentDisable);

  }

  handleClick(data: LeadDetail) {
    const leadDetailId = data.id;
    const leadId = data.leadId;
    const queryParams = { leadDetailId, leadId };
    // console.log("lead info query params and data", queryParams, data);
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,
    };
    this.router.navigate(['/lead-detail'], navigationExtras);
  }

  handleMoveClick(data: MoveDetail) {
    const leadDetailId = data.leadDetailId;
    const moveDetailId = data.id;
    const leadId = data.leadId;
    const queryParams = { leadDetailId, moveDetailId, leadId };
    // console.log("lead info query params and data", queryParams, data);
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,
    };
    this.router.navigate(['/move-detail'], navigationExtras);
  }

  getLeadDetailById(): void {
    this._movdService.getLeadDetailById(this.refId).subscribe({
      next: (data: any) => {
        this._leadDetail = new LeadDetail();
        if (data) {
          this._leadDetail = data;
          this.checkContentDisable();
          this._leadInternalNotes = this._leadDetail.internalNotes;
          // console.log('lead detail notes', this._leadInternalNotes);
          this.getLeadDetailsByLeadId(this.refId, this._leadDetail.leadId);
          this.getMoveDetailsByLeadDetailId(this._leadDetail.id);
        }
        this._isDataLoaded = true;
        // this.checkContentDisable();
        // console.log('one lead detail', data);
        // console.log("_isDataLoaded", this._isDataLoaded);
      },
      error: (error) => {
        console.error("error getting lead detail by id", error);
      },
    });

  }

  getMoveDetailById(): void {
    this._movdService.getMoveDetailById(this.refId).subscribe({
      next: (data: any) => {
        this._moveDetail = new MoveDetail();
        // console.log('one move detail', data);

        if (data) {
          this._moveDetail = data;
          this._moveInternalNotes = this._moveDetail.internalNotes;
          this.getMoveDetailsByLeadId(this.refId, this._moveDetail.leadId);
        }
        this._isDataLoaded = true;
        this.checkContentDisable();
        // console.log('one move detail', data);
        // console.log("_isDataLoaded", this._isDataLoaded);
      },
      error: (error) => {
        console.error("error getting move detail by id", error);
      },
    });
  }

  redirectToAccount(id: any) {
    // const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,
    };
    this.router.navigate(['/profile'], navigationExtras);
  }

  redirectToContact(id: any) {
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'contact';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,

    };
    this.router.navigate(['/profile'], navigationExtras);
  }
  checkContentDisable() {
    if (this.assignmentType === 'leadDetail') {
      if (this._leadDetail && (this._leadDetail.markLost === true || this._leadDetail.moveInitiated === true)) {
        // All fields disabled
        this._contentDisable = true;
        this._assignToDisable = true;
      } else {
        // All fields enabled
        this._contentDisable = false;
        this._assignToDisable = false;
      }
    } else if (this.assignmentType === 'moveDetail') {
      if (this._moveDetail && (this._moveDetail.completed === true || this._moveDetail.cancelled === true)) {
        // All fields disabled except assignTo
        this._contentDisable = true;
        this._assignToDisable = true;
      } else {
        // All fields disabled
        this._contentDisable = true;
        this._assignToDisable = false;
      }
    } else {
      // For packing page
      // All fields disabled
      this._contentDisable = true;
      this._assignToDisable = true;
    }
    // console.log('Content disable:', this._contentDisable);
    // console.log('AssignTo disable:', this._assignToDisable);
  }





  async onAssignUser(event: any) {
    // console.log("event on assign", event);

    let text = 'You are about to assign to user.';
    let confirmButtonText = 'Yes';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this._userAssignment.refId = this._refIdForHeader;
      this._userAssignment.userIds = this._selectedUserIds;
      this._userAssignment.assignmentType = this._eAssignmentType;
      this._userAssignment.createdBy = this._currentUserId;
      // console.log('Assignment Type', this._userAssignment.eAssignmentType);
      // console.log('userAssignment model', this._userAssignment)
      this._movdService.saveUserAssignments(this._userAssignment).subscribe({
        next: response => {
          this._appComponent.showSuccessSwal("Assigned", "User");
          this.getUserAssignmentByRefId();
        },
        error: (error: any) => {
          // Handle error
          console.error("error in saving user assignment", error);
        }
      });
    }
    else {
      this.getUserAssignmentByRefId();

    }
  }
  getUserAssignmentByRefId() {
    this._movdService.getUserAssignmentByRefId(this._refIdForHeader, this._eAssignmentType).subscribe({
      next: (response: any[]) => {
        if (response && response.length > 0) {
          // console.log('response', response);
          const userIds = response.map(userAssignment => userAssignment.userId);
          this._selectedUserIds = userIds;
          const assignedUsers = response.map(userAssignment => {
            const user = this._companyUsers.find(companyUser => companyUser.customerId === userAssignment.userId);
            return user ? user.customerName : '';
          });
          this._userAssigned = assignedUsers.join(', ');
        } else {
          this._selectedUserIds = [];
          this._userAssigned = null;
        }
      },
      error: (error: any) => {
        // Handle error
        console.error("error in getting user assignment", error);
      }
    });
  }

  // get lead detail by lead Id
  getLeadDetailsByLeadId(refId: number, leadId: number): void {
    this._movdService.getLeadDetailsByLeadId(leadId).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log("lead details by leadId", data);
        if (data && data.leads && data.leads.length > 0) {
          const filteredData: LeadBoardDTO = data.leads.filter((item: { leadDetailId: number }) => item.leadDetailId === refId)[0];
          if (filteredData) {
            this._leadInfo = filteredData;
            // console.log('filtered lead infor', this._leadInfo);
            let lead: Lead = {
              id: filteredData.leadId || 0,
              firstName: null,
              lastName: null,
              customerId: filteredData.customerId,
              customerName: filteredData.customer,
              phone: filteredData.phone,
              email: filteredData.email,
              accountId: filteredData.accountId,
              pocId: filteredData.pocId,
              thirdPartyId: filteredData.thirdPartyId,
              thirdPartyPocId: filteredData.thirdPartyPocId,
              ownedBy: this._companyId,
              accountName: filteredData.accountName,
              pocName: filteredData.pocName,
              thirdPartyName: filteredData.thirdPartyName,
              thirdPartyPocName: filteredData.thirdPartyPocName,
              createdBy: this._companyId,
              modificationDate: new Date(),
              modifiedBy: 0,
              deletionDate: null,
              isDeleted: false,
              leadDetailId: filteredData.leadDetailId
            }
            if (filteredData.customer) {
              const names = filteredData.customer.split(' ');
              lead.firstName = names[0] || null;
              lead.lastName = names.slice(1).join(' ') || null;
            }
            this._lead = lead;
            this._leadEmail = this._lead.email;
            // console.log('_lead ', this._lead);
          }
        } else {
          this._leadInfo = new LeadBoardDTO();
        }
      },
      error: (error) => {
        console.error("error getting lead detail", error);
      }
    });
  }

  getMoveDetailsByLeadId(refId: number, leadId: number) {
    // console.log(`move details by lead id ${refId} && lead id ${leadId}`);
    this._movdService.getMoveDetailsByLeadId(leadId).subscribe({
      next: (data: MovesListResponse) => {
        // console.log("move details by lead id", data);
        if (data.movesList.length > 0) {
          const filteredData = data.movesList.filter((item) => item.moveDetailId === refId)[0];
          // console.log("lead info for this move", filteredData);
          if (filteredData) {
            // console.log("lead info for this move", filteredData);
            this._leadInfo = filteredData;
            let lead: Lead = {
              id: filteredData.leadId || 0,
              firstName: null,
              lastName: null,
              customerId: filteredData.customerId,
              customerName: filteredData.customer,
              phone: filteredData.phone,
              email: filteredData.email,
              accountId: filteredData.accountId,
              pocId: filteredData.pocId,
              thirdPartyId: filteredData.thirdPartyId,
              thirdPartyPocId: filteredData.thirdPartyPocId,
              ownedBy: this._companyId,
              accountName: filteredData.accountName,
              pocName: filteredData.pocName,
              thirdPartyName: filteredData.thirdPartyName,
              thirdPartyPocName: filteredData.thirdPartyPocName,
              createdBy: this._companyId,
              modificationDate: new Date(),
              modifiedBy: 0,
              deletionDate: null,
              isDeleted: false,
              leadDetailId: filteredData.leadDetailId
            }
            if (filteredData.customer) {
              const names = filteredData.customer.split(' ');
              lead.firstName = names[0] || null;
              lead.lastName = names.slice(1).join(' ') || null;
            }
            this._lead = lead;
            this._leadEmail = this._lead.email;
          }
        } else {
          this._leadInfo = new MoveBoardDTO();
        }
      },
      error: (error) => {
        console.error("error move details by lead id", error);
      }
    });
  }

  getMoveDetailsByLeadDetailId(leadDetailId: number) {
    this._moveDetail = new MoveDetail();
    this._movdService.getMoveDetailsByLeadDetailId(leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("move details by lead detail id", data);
        if (data) {
          this._moveDetail = data;
        }
      },
      error: (error) => {
        console.error("error move details by lead id", error);
      }
    });
  }
  updateNotes(newNotes: string) {
    if (this.type === 'leadDetail') {
      this._movdService.saveLeadInternalNotes(this._leadDetail.id, newNotes).subscribe(
        (response) => {
          // console.log('lead notes saved successfully:', response);
        },
        (error) => {
          // console.error('Error saving internal notes:', error);
        }
      );
    } else if (this.type === 'moveDetail') {
      // console.log('move detail id', this._moveDetail.id);
      this._movdService.saveMoveInternalNotes(this._moveDetail.id, newNotes).subscribe(
        (response) => {
          // console.log('move notes saved successfully:', response);
        },
        (error) => {
          // console.error('Error saving internal notes:', error);
        }
      );
    }
  }
  openEditModal() {
    this.filterContacts('thirdParty', this._lead.thirdPartyId);
    this.filterContacts('contact', this._lead.accountId);
    // console.log('lead poc accnt', this._filteredContactPOCs);
    this._editModalRef = this._modalService.open(this.editModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._editModalRef.result.then(
      (result) => {

      },
      (reason) => {

        if (reason === 'backdrop-click') {

        }
      }
    );
  }

  closeModal() {
    this._modalService.dismissAll();

  }

  searchCustomer(type: string) {
    this._searchedCustomersList = []
    let name = null;
    let phone = null;
    let email = null;

    if (this._lead.firstName) {
      this._lead.customerName = this._lead.lastName
        ? `${this._lead.firstName} ${this._lead.lastName}`
        : this._lead.firstName;
    } else {
      this._lead.customerName = null;
    }
    this._lead.email = this._leadEmail;
    name = this._lead.customerName;
    phone = this._lead.phone;
    email = this._lead.email;

    // console.log("name", name);
    // console.log("phone", phone);
    // console.log("email", email);

    if (name === null && email === null && phone === null) {
      return;
    }

    this._movdService.searchCustomer(name, phone, email).subscribe({
      next: (response: any) => {
        // console.log("searched Customers list", response);
        if (response && response.length > 0) {
          this._searchedCustomersList = response;
        }
      },

    });
    this._changeDetectRef.detectChanges();
  }

  selectCustomer(searchedCustomer: SearchCustomerDTO) {
    // console.log("searched customer:", searchedCustomer);
    if (searchedCustomer.customerName) {
      let names = searchedCustomer.customerName.split(' ');
      this._lead.firstName = names[0];
      this._lead.lastName = names.length > 1 ? names.slice(1).join(' ') : '';
    }

    this._lead.customerId = searchedCustomer.customerId;
    this._lead.phone = searchedCustomer.phone;
    this._leadEmail = searchedCustomer.email;

    this._changeDetectRef.detectChanges();
  }

  onEmailChange(event: Event) {
    const newEmail = (event.target as HTMLInputElement)?.value;
    if (newEmail) {
      const selectedCustomer = this._searchedCustomersList.find(customer =>
        customer.email?.toLowerCase() === newEmail.toLowerCase()
      );

      if (selectedCustomer) {
        this._lead.customerId = selectedCustomer.customerId;
      } else {
        this._lead.customerId = 0;
      }

      this._leadEmail = newEmail;
    }
  }
  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.key;
    const isNumeric = /[0-9]/.test(input);
    const currentPhone = this._lead.phone || '';

    // If it's not a valid input, prevent further changes
    if (!isNumeric && input !== '+') {
      event.preventDefault();
      return;
    }

    // Automatically prepend '+' if it's not already present and the input is a number
    if (currentPhone.length === 0 && isNumeric) {
      this._lead.phone = '+' + input;
      event.preventDefault();
      return;
    }

    // Prevent '+' sign if it's not at the beginning of the number
    if (input === '+' && currentPhone.length !== 0) {
      event.preventDefault();
      return;
    }

    const newValue = currentPhone + input;
    if (newValue.length > 15) {
      event.preventDefault();
      return;
    }
    else if (newValue.length > 0 && newValue.length < 8) {
      this.phone = newValue;
    }
    // Assign the new value to the phone number
    // this._lead.phone = newValue;
  }
  isValidPhoneNumber(value: string): boolean {
    return /[0-9+^$]/.test(value) && value.length >= 8 && value.length <= 15;
  }

  getCompanyUsers(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._authService.getUsersOfCompany().subscribe(
        (data: any) => {
          // console.log('data of users', data);
          if (data && data.length > 0) {
            // Filter users based on assignment type
            if (this.assignmentType === 'leadDetail') {
              // Filter users having specific roles (Account Manager, Move Manager, Survey Manager)
              this._companyUsers = data.filter((user: { roleNames: string[]; }) =>
                user.roleNames && (
                  user.roleNames.includes('Account Manager') ||
                  user.roleNames.includes('Move Manager') ||
                  user.roleNames.includes('Survey Manager')
                )
              );
            } else {
              this._companyUsers = data;
            }
            resolve(); // Resolve the promise when data is received and processed
          }
        },
        error => {
          console.error("Error getting company users", error);
          reject(error); // Reject the promise in case of error
        }
      );
    });
  }


  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        if (response.contacts && response.contacts.length > 0) {
          this._allCustomers = response.contacts;
          this._contacts = response.contacts.filter((item: Customer) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);
          this._companies = response.contacts.filter((item: Customer) => item.contactType === eContactType.Company && item.id !== this._companyId);

          if (this._selectedAccountId) {
            this._filteredContactPOCs = this._contacts.filter((contact: Customer) => contact.companyId === this._selectedAccountId);
          }

          if (this._selectedThirdPartyId) {
            this._filteredTPPOCs = this._contacts.filter((contact: Customer) => contact.companyId === this._selectedThirdPartyId);
          }
          // console.log("_contacts", this._contacts);
          // console.log("_companies", this._companies);
        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }
  filterContacts(type: string, id: number | null) {


    if (this._contacts && this._contacts.length > 0) {
      if (type === 'contact') {
        this._selectedAccountId = id;
        if (this._contacts && this._contacts.length > 0) {
          this._filteredContactPOCs = this._contacts.filter((contact: Customer) => contact.companyId === id);
          // console.log("filter contacts POC", this._filteredContactPOCs);
        }
      } else if (type === 'thirdParty') {
        this._selectedThirdPartyId = id;
        this._filteredTPPOCs = this._contacts.filter((contact: Customer) => contact.companyId === id);
        // console.log("filter thrid party POC", this._filteredTPPOCs);
      }
    }
  }
  updateLead() {
    this._lead.email = this._leadEmail;
    if (
      this._lead.firstName === null || this._lead.firstName.trim().length === 0 ||
      this._lead.phone === null || this._lead.phone.trim().length === 0 ||
      this._lead.email === null || this._lead.email.trim().length === 0
    ) {
      return;
    }

    this._lead.modifiedBy = Number(localStorage.getItem('currentUserId'));
    this._lead.ownedBy = this._companyId;
    if (this._lead.firstName) {
      this._lead.customerName = this._lead.lastName
        ? `${this._lead.firstName} ${this._lead.lastName}`
        : this._lead.firstName;
    } else {
      this._lead.customerName = null;
    }

    // console.log("detail before save", this._lead);
    this._movdService.saveLead(this._lead).subscribe({
      next: (response: any) => {
        // console.log("createLead response", response);
        this._appComponent.showSuccessSwal("updated", "lead");
        this.getLeadDetailById();
        if (this._editModalRef && this._editModalRef !== undefined) {
          this._editModalRef.close();
        }
      },
      error: (error: any) => {
        // this._appComponent.showErrorSwal("creating", "record");
        console.error("error updating lead", error);
      }
    });
  }

}
