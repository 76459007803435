<ng-template #generateEmailModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="addRoleModalLabel">
      <i class="mdi mdi-email-outline"></i> {{_title}}
    </h5>
    <button type="button" class="btn-close" label="Close" (click)="closeModal('Temp',modal)"></button>
  </div>
  <div class="modal-body">
    <div class="modal-body"><app-generate-email [emailType]="eEmailType.Lead" [refId]="leadDetailId"
        [templateType]="templateType" [dictionary]="_dictionary" [pdfData]="pdfData"
        (emailSent)="closeModal('Temp',modal)"></app-generate-email></div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="generateTemplate(modal)">
      <i class="mdi mdi-content-save"> </i> &nbsp; Send
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal('Temp', modal);">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div> -->
</ng-template>

<div class="center">
  <h4 class="tab-heading">Quotation</h4>
  <hr>
</div>

<div class="row mt-2">
  <div class="col-md-12">
    <button type="button" class="btn btn-sm btn-primary float-end" (click)="openTempModal()">
      <i class="mdi mdi-email"></i> Generate Email
    </button>
    <ng-container *ngIf="_createPermission">

      <button type="button" class="btn btn-sm btn-primary float-end" (click)="saveLeadQuotation();"
        [disabled]="disabled === true">
        <i class="mdi mdi-content-save"> </i> &nbsp; Save
      </button>
    </ng-container>
    <button type="button" class="btn btn-sm btn-primary float-end" (click)="downloadPdf();">
      <i class="mdi mdi-file-export"> </i> &nbsp; Export
    </button>
    <button class="btn btn-sm btn-primary float-end" (click)="viewPdf();">
      <i class="mdi mdi-file-eye"> </i> &nbsp; View
    </button>

  </div>
</div>

<div class=" col-md-6">
  <div *ngIf="previewData" class="mt-4">
    <h4>Preview :</h4>
    <div class="col-md-12">
      <!-- Other content -->
      <button type="button" class="btn btn-secondary float-end" (click)="openEditModal(editModal)"
        [disabled]="disabled === true">
        Edit
      </button>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-sm">
        <tr>
          <th class="w-50"></th>
        </tr>
        <tbody>
          <tr>
            <td><strong>Type</strong></td>
            <td><span class="preview-value">{{ previewData.type }}</span></td>
          </tr>
          <tr>
            <td><strong>Method</strong></td>
            <td><span class="preview-value">{{ previewData.method }}</span></td>
          </tr>
          <tr>
            <td><strong>From POE</strong></td>
            <td><span class="preview-value">{{ previewData.fromPoe }}</span></td>
          </tr>
          <tr>
            <td><strong>To Residence</strong></td>
            <td><span class="preview-value">{{ previewData.toResidence }}</span></td>
          </tr>
          <tr>
            <td><strong>EST. Weight</strong></td>
            <td><span class="preview-value">{{ previewData.estWeight }}</span></td>
          </tr>
          <tr>
            <td><strong>Charges</strong></td>
            <td><span class="preview-value">{{ previewData.charges }}</span></td>
          </tr>
          <tr>
            <td><strong>Inclusions</strong></td>
            <td><span class="preview-value">{{ previewData.inclusions }}</span></td>
          </tr>
          <tr>
            <td><strong>Exclusions</strong></td>
            <td><span class="preview-value">{{ previewData.exclusions }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="mt-4">

</div>


<!-- Modal Content -->
<ng-template #templateModal let-modal>
  <div class="modal-header">

    <h5></h5>
  </div>

  <div class="mt-4">
    <div class="modal-body">
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="type" class="col-form-label">Type</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control small-input" [(ngModel)]="type" id="type"
            [disabled]="disabled === true" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="method" class="col-form-label">Method</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control small-input" [(ngModel)]="method" id="method"
            [disabled]="disabled === true" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="fromPoe" class="col-form-label">From POE</label>
        </div>
        <div class="col-md-9">

        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="toResidence" class="col-form-label">To Residence</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control small-input" [(ngModel)]="toResidence" id="toResidence" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="estWeight" class="col-form-label">EST. Weight</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control small-input" [(ngModel)]="estWeight" id="estWeight" />
          <!--autocomplete="on"placeholder="Enter EST. Weight" required-->
          <!--<div *ngIf="!estWeight" class="error-message error" style="color: red;">* required</div>-->
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="charges" class="col-form-label">Charges</label>
        </div>
        <div class="col-md-9">
          <input type="text" class="form-control small-input" [(ngModel)]="charges" id="charges" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="inclusions" class="col-form-label">Inclusions</label>
        </div>
        <div class="col-md-9">
          <textarea class="form-control" [(ngModel)]="inclusions" id="inclusions"></textarea>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3">
          <label for="exclusions" class="col-form-label">Exclusions</label>
        </div>
        <div class="col-md-9">
          <textarea class="form-control" [(ngModel)]="exclusions" id="exclusions"></textarea>
        </div>
      </div>

    </div>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary" (click)="exportData()">EXPORT</button>
    <button type="button" class="btn btn-primary" (click)="sendDataToPreview()">SEND</button>
  </div>



</ng-template>
<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Details</h4>

  </div>

  <div class="modal-body">

    <label for="editType">Type</label>
    <input type="text" class="form-control" id="editType" [(ngModel)]="previewData.type" />


    <label for="editMethod">Method</label>
    <input type="text" class="form-control" id="editMethod" [(ngModel)]="previewData.method" />


    <label for="editFromPol">From POL</label>
    <input type="text" class="form-control" id="editFromPol" [(ngModel)]="previewData.fromPol" />

    <label for="editFromPod">From POD</label>
    <input type="text" class="form-control" id="editFromPod" [(ngModel)]="previewData.fromPod" />

    <label for="editToResidence">To Residence</label>
    <input type="text" class="form-control" id="editToResidence" [(ngModel)]="previewData.toResidence" />


    <label for="editEstWeight">EST. Weight</label>
    <input type="text" class="form-control" id="editEstWeight" [(ngModel)]="previewData.estWeight" />


    <label for="editCharges">Charges</label>
    <input type="text" class="form-control" id="editCharges" [(ngModel)]="previewData.charges" />


    <label for="editInclusions">Inclusions</label>
    <input type="text" class="form-control" id="editInclusions" [(ngModel)]="previewData.inclusions" />

    <label for="editExclusions">Exclusions</label>
    <input type="text" class="form-control" id="editExclusions" [(ngModel)]="previewData.exclusions" />

  </div>


  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cancel click')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="saveChanges(modal)">Save</button>
  </div>
</ng-template>

<ng-template #basicModal let-modal>
  <div class="modal-dialog custom-modal-dialog">
    <div class="modal-body">
      <app-lead-survey-preview [leadDetailId]="leadDetailId"></app-lead-survey-preview>
    </div>
  </div>
</ng-template>


<div class="row">
  <input type="hidden" id="Id" [(ngModel)]="_leadQuotation.id" />
  <input type="hidden" id="leadDetailId" [(ngModel)]="_leadQuotation.leadDetailId" />
  <input type="hidden" id="creationDate" [(ngModel)]="_leadQuotation.creationDate" />
  <input type="hidden" id="createdBy" [(ngModel)]="_leadQuotation.createdBy" />
  <input type="hidden" id="modificationDate" [(ngModel)]="_leadQuotation.modificationDate" />
  <input type="hidden" id="modifiedBy" [(ngModel)]="_leadQuotation.modifiedBy" />
</div>
<div class="row">
  <div class="row">
    <div class="col-md-3 ms-auto d-flex align-items-center">
      <label class="fw-bold me-2">Currency:</label>
      <ng-select id="currency" [(ngModel)]="_selectedCurrency" [items]="_currencies" bindLabel="value" bindValue="id"
        [searchable]="true" placeholder="Select Currency" class="flex-grow-1">
      </ng-select>
    </div>
  </div>
  <div class=" col-md-6 mt-2">
    <label for="date">Quotation Date:</label>
    <div class="input-group input-cross">
      <input class="form-control clickable" placeholder="Select Date" name="dp"
        [value]=" generalService.formatDate(_leadQuotation.date, null)" ngbDatepicker #dp="ngbDatepicker"
        (click)="dp.toggle()" outsideDays="hidden" [dayTemplate]="dt"
        (dateSelect)="onDateSelect($event, 'Quotation'); dp.close()" [startDate]="_quotationDate!"
        [disabled]="disabled === true" readonly />
      <button class="btn btn-small cross-btn" type="button" title="Clear Date" *ngIf="_leadQuotation.date"
        (click)="_leadQuotation.date = null" [hidden]="disabled === true">
        <i class="feather icon-x"></i>
      </button>
      <button class="input-group-text" type="button" (click)="dp.toggle()">
        <i class="feather icon-calendar icon-md text-muted"></i>
      </button>
    </div>
    <ng-template #dt let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'quotation')">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
  <div class="col-md-6 mt-2">
    <label for="validationDate">Validation Date:</label>
    <div class="input-group input-cross">
      <input class="form-control clickable" placeholder="Select Date" name="d"
        [value]="generalService.formatDate(_leadQuotation.validationDate, null)" ngbDatepicker #d="ngbDatepicker"
        (click)="d.toggle()" outsideDays="hidden" [dayTemplate]="t"
        (dateSelect)="onDateSelect($event, 'Validation'); d.close()" [startDate]="_validationDate!"
        [disabled]="disabled === true" readonly />
      <button class="btn btn-small cross-btn" type="button" title="Clear Date" *ngIf="_leadQuotation.validationDate"
        (click)="_leadQuotation.validationDate = null" [hidden]="disabled === true">
        <i class="feather icon-x"></i>
      </button>
      <button class="input-group-text" type="button" (click)="d.toggle()">
        <i class="feather icon-calendar icon-md text-muted"></i>
      </button>
    </div>
    <ng-template #t let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'validation')">
        {{ date.day }}
      </span>
    </ng-template>
  </div>


  <div class=" col-md-6 mt-4" *ngIf="!((_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Sea) ||
  (_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Air))">
    <label for="charges">Origin Charges:</label>
    <div class="input-group">
      <input type="number" class="form-control" [(ngModel)]="_leadQuotation.originCharges"
        placeholder="Enter origin charges" [disabled]="disabled === true" />
      <span class="input-group-text">{{ _currency }}</span>
    </div>
  </div>
  <div class=" col-md-6 mt-4" *ngIf="!( (_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.SeaLCL) ||
  (_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.Sea) ||
  (_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.Air) ||
  (_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Sea) ||
  (_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Air))">
    <label for="charges">Destination Charges:</label>
    <div class="input-group">
      <input type="number" class="form-control" [(ngModel)]="_leadQuotation.destinationCharges"
        placeholder="Enter destination charges" [disabled]="disabled === true" />
      <span class="input-group-text">{{ _currency }}</span>
    </div>
  </div>
  <div class=" col-md-6 mt-4" *ngIf="!((_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Sea) ||
  (_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Air))">
    <label for="charges">Freight Charges:</label>
    <div class="input-group">
      <input type="number" class="form-control" [(ngModel)]="_leadQuotation.freightCharges"
        placeholder="Enter freight charges" [disabled]="disabled === true" />
      <span class="input-group-text">{{ _currency }}</span>
    </div>
  </div>
  <ng-container
    *ngIf="_moveTransport.categoryType==eMoveCategoryType.PortToDoor && _moveTransport.transportType==eTransportType.Sea">
    <div class=" col-md-6 mt-4">
      <label for="destinationCustomClearance">Destination Custom Clearance:</label>
      <input type="text" class="form-control" id="destinationCustomClearance"
        [(ngModel)]="_leadQuotation.destinationCustomClearance" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Delivery:</label>
      <input type="text" class="form-control" id="delivery" [(ngModel)]="_leadQuotation.delivery"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="returnEmptyUnit">Return Empty Unit:</label>
      <input type="text" class="form-control" id="returnEmptyUnit" [(ngModel)]="_leadQuotation.returnEmptyUnit"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="inlandHaulage">Inland Haulage:</label>
      <input type="text" class="form-control" id="inlandHaulage" [(ngModel)]="_leadQuotation.inlandHaulage"
        [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.Sea">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack & Load:</label>
      <input type="text" class="form-control" id="packAndLoad" [(ngModel)]="_leadQuotation.packAndLoad"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Dock Transfer:</label>
      <input type="text" class="form-control" id="dockTransfer" placeholder="Enter Dock Transfer"
        [(ngModel)]="_leadQuotation.dockTransfer" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" [(ngModel)]="_leadQuotation.exportClearance"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" [(ngModel)]="_leadQuotation.transitTime"
        [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.Air">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" [(ngModel)]="_leadQuotation.exportClearance"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" [(ngModel)]="_leadQuotation.transitTime"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack Out:</label>
      <input type="text" class="form-control" id="packOut" [(ngModel)]="_leadQuotation.packOut"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">AirPort Transfer:</label>
      <input type="text" class="form-control" id="airPortTransfer" [(ngModel)]="_leadQuotation.airPortTransfer"
        [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="(_moveTransport.categoryType==eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType==eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType==eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType==eTransportType.Air">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack & Load:</label>
      <input type="text" class="form-control" id="packAndLoad" [(ngModel)]="_leadQuotation.packAndLoad"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Dock Transfer:</label>
      <input type="text" class="form-control" id="dockTransfer" [(ngModel)]="_leadQuotation.dockTransfer"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" [(ngModel)]="_leadQuotation.exportClearance"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" placeholder="Enter transit time"
        [(ngModel)]="_leadQuotation.transitTime" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Delivery:</label>
      <input type="text" class="form-control" id="delivery" placeholder="Enter delivery"
        [(ngModel)]="_leadQuotation.delivery" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="returnEmptyUnit">Return Empty Unit:</label>
      <input type="text" class="form-control" id="returnEmptyUnit" placeholder="Enter return empty unit"
        [(ngModel)]="_leadQuotation.returnEmptyUnit" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Import Clearance:</label>
      <input type="text" class="form-control" id="importClearance" placeholder="Enter import clearance"
        [(ngModel)]="_leadQuotation.importClearance" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack Out:</label>
      <input type="text" class="form-control" id="packOut" placeholder="Enter pack out"
        [(ngModel)]="_leadQuotation.packOut" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">AirPort Transfer:</label>
      <input type="text" class="form-control" id="airPortTransfer" [(ngModel)]="_leadQuotation.airPortTransfer"
        [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="(_moveTransport.categoryType==eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType==eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType==eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType==eTransportType.Sea">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack & Load:</label>
      <input type="text" class="form-control" id="packAndLoad" [(ngModel)]="_leadQuotation.packAndLoad"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Dock Transfer:</label>
      <input type="text" class="form-control" id="dockTransfer" [(ngModel)]="_leadQuotation.dockTransfer"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" [(ngModel)]="_leadQuotation.exportClearance"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" [(ngModel)]="_leadQuotation.transitTime"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Delivery:</label>
      <input type="text" class="form-control" id="delivery" [(ngModel)]="_leadQuotation.delivery"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="returnEmptyUnit">Return Empty Unit:</label>
      <input type="text" class="form-control" id="returnEmptyUnit" [(ngModel)]="_leadQuotation.returnEmptyUnit"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Import Clearance:</label>
      <input type="text" class="form-control" id="importClearance" placeholder="Enter import clearance"
        [(ngModel)]="_leadQuotation.importClearance" [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="(_moveTransport.categoryType==eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType==eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType==eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType==eTransportType.SeaLCL">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack & Load:</label>
      <input type="text" class="form-control" id="packAndLoad" [(ngModel)]="_leadQuotation.packAndLoad"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Dock Transfer:</label>
      <input type="text" class="form-control" id="dockTransfer" [(ngModel)]="_leadQuotation.dockTransfer"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" [(ngModel)]="_leadQuotation.exportClearance"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" [(ngModel)]="_leadQuotation.transitTime"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Delivery:</label>
      <input type="text" class="form-control" id="delivery" [(ngModel)]="_leadQuotation.delivery"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Commodity:</label>
      <input type="text" class="form-control" id="commodity" [(ngModel)]="_leadQuotation.commodity"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="destinationCustomClearance">Destination Custom Clearance:</label>
      <input type="text" class="form-control" id="destinationCustomClearance"
        [(ngModel)]="_leadQuotation.destinationCustomClearance" [disabled]="disabled === true" />
    </div>
  </ng-container>

  <ng-container
    *ngIf="_moveTransport.categoryType==eMoveCategoryType.DoorToPort && _moveTransport.transportType==eTransportType.SeaLCL">
    <div class=" col-md-6 mt-4">
      <label for="delivery">Pack & Load:</label>
      <input type="text" class="form-control" id="packAndLoad" [(ngModel)]="_leadQuotation.packAndLoad"
        [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Dock Transfer:</label>
      <input type="text" class="form-control" id="dockTransfer" placeholder="Enter Dock Transfer"
        [(ngModel)]="_leadQuotation.dockTransfer" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Export Clearance:</label>
      <input type="text" class="form-control" id="exportClearance" placeholder="Enter export clearance"
        [(ngModel)]="_leadQuotation.exportClearance" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Transit Time:</label>
      <input type="text" class="form-control" id="transitTime" placeholder="Enter transit time"
        [(ngModel)]="_leadQuotation.transitTime" [disabled]="disabled === true" />
    </div>
    <div class=" col-md-6 mt-4">
      <label for="delivery">Commodity:</label>
      <input type="text" class="form-control" id="commodity" placeholder="Enter commodity"
        [(ngModel)]="_leadQuotation.commodity" [disabled]="disabled === true" />
    </div>
  </ng-container>
  <div class=" col-md-6 mt-4">
    <label for="accessorialRates">Accessorial Rates:</label>
    <input type="text" class="form-control" id="accessorialRates" placeholder="Enter Accessorial Rates"
      [(ngModel)]="_leadQuotation.accessorialRates" [disabled]="disabled === true" />
  </div>
  <div class="row">
    <br />
  </div>

  <div class=" col-md-6">
    <label for="inclusions">Inclusions:</label>
    <textarea rows="4" class="form-control" id="inclusions" placeholder="Enter Inclusions"
      [(ngModel)]="_leadQuotation.inclusions" [disabled]="disabled === true"></textarea>
  </div>
  <div class=" col-md-6">
    <label for="exclusions">Exclusions:</label>
    <textarea rows="4" class="form-control" id="exclusions" placeholder="Enter exclusions"
      [(ngModel)]="_leadQuotation.exclusions" [disabled]="disabled === true"></textarea>
  </div>
  <div class=" col-md-6 mt-4">
    <label for="insuranceLiabilities">Insurance Liabilities:</label>
    <textarea rows="4" class="form-control" id="insuranceLiabilities" placeholder="Enter Insurance Liabilities"
      [(ngModel)]="_leadQuotation.insuranceLiabilities" [disabled]="disabled === true"></textarea>
  </div>
  <div class=" col-md-6 mt-4">
    <label for="termsAndConditions">Payment Terms:</label>
    <textarea rows="4" class="form-control" id="termsAndConditions" placeholder="Enter Terms And Conditions"
      [(ngModel)]="_leadQuotation.termsAndConditions" [disabled]="disabled === true"></textarea>
  </div>
</div>


<br />
<!--<hr>
<h4 class="text-center"> Quotation View </h4>
<hr>-->
<div #pdfComponent class="hidden-content">
  <app-lead-survey-preview [leadDetailId]="leadDetailId"></app-lead-survey-preview>
</div>




<!-- <button class="btn btn-sm btn-primary float-end" (click)="openPdfModel();">
  <i class="mdi mdi-file-eye"> </i> &nbsp; View Old
</button> -->