import { Injectable } from '@angular/core';
import { eMoveCategoryType, eMoveType, eProcessStatus, eTransportType } from '../models/lead-detail.model';
import { ePackingType } from '../models/packaging-inventory-item.model';
import { eMultipleChoiceAnswer } from '../models/appraisal.model';
import { Country, CountryOptions } from '../models/address.model';
import { eLabourType } from '../models/labour.model';
import { eVehicleType } from '../models/vehicle.model';
import { eMaterialType } from '../models/packing-material.model';
import { eAssignmentType } from '../models/userAssignment.model';

@Injectable({
    providedIn: 'root'
})

export class EnumMappingService {
    mapMoveType(moveType: eMoveType): string {
        switch (moveType) {
            case eMoveType.Residential:
                return 'Residential';
            case eMoveType.Diplomatic:
                return 'Diplomatic';
            case eMoveType.Cargo:
                return 'Cargo';
            case eMoveType.Commercial:
                return 'Commercial';
            default:
                return ' - ';
        }
    }

    mapMoveCategoryType(moveCategory: eMoveCategoryType): string {
        switch (moveCategory) {
            case eMoveCategoryType.DoorToDoorLocal:
                return 'Door - Door (Local)';
            case eMoveCategoryType.DoorToPort:
                return 'Door - Port';
            case eMoveCategoryType.PortToDoor:
                return 'Port - Door';
            case eMoveCategoryType.DoorToDoorImport:
                return 'Door - Door (Import)';
            case eMoveCategoryType.DoorToDoorExport:
                return 'Door - Door (Export)';
            default:
                return ' - ';
        }
    }

    mapTransportType(transportType: eTransportType): string {
        switch (transportType) {
            case eTransportType.Sea:
                return 'Sea';
            case eTransportType.Air:
                return 'Air';
            case eTransportType.Land:
                return 'Land';
            case eTransportType.SeaLCL:
                return 'Sea (LCL)';
            default:
                return ' - ';
        }
    }

    mapPackingType(packingType: ePackingType): string {
        switch (packingType) {
            case ePackingType.AssembleDisassemble:
                return 'Assemble/Disassemble';
            case ePackingType.CratedItem:
                return 'Crated Item';
            case ePackingType.Electronic:
                return 'Electronic';
            case ePackingType.HighValue:
                return 'High Value';
            default:
                return ' - ';
        }
    }
    mapMultipleChoice(answer: eMultipleChoiceAnswer | number): string {
        switch (answer) {
            case eMultipleChoiceAnswer.Dissatisfied | 0:
                return '😞';
            case eMultipleChoiceAnswer.Poor | 1:
                return '😐';
            case eMultipleChoiceAnswer.Satisfied | 2:
                return '😊';
            case eMultipleChoiceAnswer.VeryGood | 3:
                return '😄';
            case eMultipleChoiceAnswer.Excellent | 4:
                return '🌟';
            default:
                return ' - ';
        }
    }
    mapLabourType(labourType: eLabourType): string {
        switch (labourType) {
            case eLabourType.Driver:
                return 'Driver';
            case eLabourType.Packer:
                return 'Packer';
            case eLabourType.Loader:
                return 'Loader';
            default:
                return ' - ';
        }
    }
    mapVehicleType(vehicleType: eVehicleType): string {
        switch (vehicleType) {
            case eVehicleType.Van:
                return 'Van';
            case eVehicleType.Truck:
                return 'Truck';
            default:
                return ' - ';
        }
    }
    mapMaterialType(materialType: eMaterialType): string {
        switch (materialType) {
            case eMaterialType.CardboardBox:
                return 'Cardboard Box';
            case eMaterialType.BubbleWrap:
                return 'Bubble Wrap';
            case eMaterialType.PackingPeanuts:
                return 'Packing Peanuts';
            case eMaterialType.AirPillows:
                return 'Air Pillows';
            case eMaterialType.FoamWraps:
                return 'Foam Wraps';
            case eMaterialType.PlasticMailers:
                return 'Plastic Mailers';
            case eMaterialType.WoodenCrates:
                return 'Wooden Crates';
            case eMaterialType.SteelDrums:
                return 'Steel Drums';
            case eMaterialType.PalletizedCargo:
                return 'Palletized Cargo';
            default:
                return ' - ';
        }
    }
    mapCountry(countryId: Country): string {
        // Find the corresponding entry in the CountryOptions array
        const country = CountryOptions.find((option) => option.id === countryId);

        // If found, return the value, otherwise return 'Unknown'
        return country ? country.value : ' - ';
    }

    mapProcessStatus(status: eProcessStatus): string {
        switch (status) {
            case eProcessStatus.New:
                return 'New';
            case eProcessStatus.InProgress:
                return 'In Progress';
            case eProcessStatus.Completed:
                return 'Completed';
            case eProcessStatus.Cancelled:
                return 'Cancelled';
            default:
                return ' - ';
        }
    }
    mapAssignmentType(assignmentType: eAssignmentType): string {
        switch (assignmentType) {
            case eAssignmentType.Lead:
                return 'Lead';
            case eAssignmentType.Move:
                return 'Move';
            case eAssignmentType.Survey:
                return 'Survey';
            case eAssignmentType.Packing:
                return 'Packing';
            case eAssignmentType.Delivery:
                return 'Delivery';
            default:
                return ' - ';
        }
    }
}
