<ng-template #addAreaModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addRoleModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp; {{_title}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_area.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_areaTitle" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_areaTitle" id="_areaTitle" autocomplete="on"
          placeholder="Enter Area name" required pattern="[A-Za-z\s]{1,15}" title="Area name between 1-15 characters."
          (input)="validateItemExists()" (keypress)="validateItemExists()" />
        <div *ngIf="areaExists" style="color: red">Already exists</div>
        <div *ngIf="!_areaTitle || (_areaTitle && _areaTitle.length === 0)" class="error-message error"
          style="color: red">
          * required
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-xs" (click)="saveAndCloseArea(modal)" [disabled]="!_areaTitle">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-xs" (click)="closeModal('Area', modal);">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>

  </div>
</ng-template>



<ng-template #addAreaItemsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="mdi mdi-account-plus"> </i> &nbsp; {{_title}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="hidden" value="_areaItems.id" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Select Area</label>
      </div>
      <div class="col-md-8">
        <ng-select bindValue="id" [searchable]="true" [(ngModel)]="_areaItems.surveyPlaceId" [items]="_areaTableData"
          [placeholder]="'Please Select an area...'" [bindLabel]="'title'">
        </ng-select>
        <div *ngIf="!_areaItems.surveyPlaceId" class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Name</label>
      </div>
      <div class="col-md-8">
        <input type="text" class="form-control" [(ngModel)]="_areaItems.title" autocomplete="on"
          placeholder="Enter Area Item name" required pattern="[A-Za-z\s]{1,15}" title="Area Item name should only contain letters and spaces, and be
                         between 1-15 characters long." />
        <div *ngIf="
                  !_areaItems.title ||
                  (_areaItems.title && _areaItems.title.length === 0)
                " class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Estimated Weight</label>
      </div>
      <div class="col-md-8">
        <input type="number" class="form-control" [(ngModel)]="_areaItems.weight"
          placeholder="Enter estimated weight" />
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Estimated Volume</label>
      </div>
      <div class="col-md-8">
        <input type="text" class="form-control" [(ngModel)]="_areaItems.estimatedVolume"
          placeholder="Enter estimated Volume" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Dimension</label>
      </div>
      <div class="col-md-8">
        <input type="text" class="form-control" [(ngModel)]="_areaItems.dimension" placeholder="Enter dimension" />
      </div>
    </div>
  </div>
  <div class="modal-footer text-end">
    <button type="button" class="btn btn-primary btn-xs" (click)="saveAndCloseAreaItems(modal)"
      [disabled]="isButtonDisabled('areaItems')">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-xs" (click)="closeModal('AreaItems', modal);">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<h5>SURVEY SETTINGS</h5>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">
  <!-- Tab 1 Area  -->
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="container">
      <span class="text"> Area </span>
    </a>
    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
              <input id="table-complete-search" type="text" class="form-control" name="searchArea"
                placeholder="Search..." [(ngModel)]="_searchArea" (keyup)="onSearchArea()" />
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openAreaModal(addAreaModal)"
                style="margin-left: 3px; width: 130px">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
                </lord-icon>
                &nbsp; Add New Area
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row" *ngIf="_areaTableData.length === 0">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body text-center">
                  <p>No Areas, currently. Please Add an Area.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="_areaTableData.length > 0">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <ngx-datatable [rows]="_areaTableData" class="bootstrap" [loadingIndicator]="true"
                      [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="_pageSize" [pagination]="true"
                      (page)="onAreaPageChange($event)" [count]="_areaTotalRecords" [externalPaging]="true"
                      [pageSize]="_pageSize" [rowHeight]="40">
                      <ngx-datatable-column *ngFor="let column of _areaTableColumns" [name]="column.header">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                          <ng-container *ngIf="column.header === 'Area Name'">
                            <!--<div class="d-flex align-items-center">-->
                            <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                              (mouseleave)="onRowMouseLeave(row)">
                              {{ row.title | slice : 0 : 15 }}
                              <button
                                class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                                *ngIf="row.isHovered" (click)="AreaPreviewClick(row.id)">
                                Preview
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="column.header !== 'Area Name'">
                            {{ value }}
                          </ng-container>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Action">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                          let-value="value">
                          <button type="button" class="btn btn-xs datatable-action-cell"
                            [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                            (click)="updateAreaStatus(row, row.isActive)">
                            {{ !row.isActive ? "Activate" : "Deactivate" }}
                          </button>
                          &nbsp;
                          <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                            (click)="updateAreaStatus(row, row.isActive, true)">
                            Delete
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </li>
  <!-- Tab 2 Area items -->
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="container">
      <span class="text"> Area Items </span>
    </a>
    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
              <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
                [(ngModel)]="_searchAreaItems" (keyup)="onSearchAreaItems()" />
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end"
                (click)="openAreaItemsModal(addAreaItemsModal)" style="margin-left: 3px; width: 130px">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
                </lord-icon>
                &nbsp; Add Area Item
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row" *ngIf="_areaItemsTableData.length === 0">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body text-center">
                  <p>No Areas Items, currently. Please Add an Area Item.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="_areaItemsTableData.length > 0">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <ngx-datatable [rows]="_areaItemsTableData" class="bootstrap" [loadingIndicator]="true"
                      [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="_pageSize" [pagination]="true"
                      (page)="onAreaItemsPageChange($event)" [count]="_areaItemsTotalRecords" [externalPaging]="true"
                      [pageSize]="_pageSize" [rowHeight]="40">
                      <ngx-datatable-column *ngFor="let column of _areaItemsTableColumns" [name]="column.header">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                          <ng-container *ngIf="column.header === 'Item Name'">
                            <!--<div class="d-flex align-items-center">-->
                            <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                              (mouseleave)="onRowMouseLeave(row)">
                              {{ row.title | slice : 0 : 15 }}
                              <button
                                class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                                *ngIf="row.isHovered" (click)="AreaItemsPreviewClick(row.id)">
                                Preview
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="column.header === 'Area'">
                            {{ row.area || ' - ' }}
                          </ng-container>
                          <ng-container *ngIf="checkHeader(column.header, _volumeUnit)">
                            {{ row.estimatedVolume || ' - ' }}

                          </ng-container>
                          <ng-container *ngIf="checkHeader(column.header, _weightUnit)">
                            {{ row.weight || ' - ' }}
                          </ng-container>
                          <ng-container *ngIf="checkHeader(column.header, _dimensionUnit)">
                            {{ row.dimension || ' - ' }}
                          </ng-container>

                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Action">
                        <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                          let-value="value">
                          <button type="button" class="btn btn-xs datatable-action-cell"
                            [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                            (click)="updateAreaItemsStatus(row, row.isActive)">
                            {{ !row.isActive ? "Activate" : "Deactivate" }}
                          </button>
                          &nbsp;
                          <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                            (click)="updateAreaItemsStatus(row, row.isActive, true)">
                            Delete
                          </button>
                        </ng-template>
                      </ngx-datatable-column>

                    </ngx-datatable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>