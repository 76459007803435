
<div class="mt-2">
  <div class="row">
    <div class="col-12">
      <button type="button" class="btn btn-sm btn-primary float-end"
        (click)="downloadWeightPdf()">
        <i class="mdi mdi-file-export"></i> &nbsp; Export
      </button>
      &nbsp;
      <button class="btn btn-sm btn-primary float-end me-2" (click)="viewWeightPdf();">
        <i class="mdi mdi-file-eye"> </i> &nbsp; View
      </button>
    </div>
    <div class="col-12 mt-3">
      <table class="custom-table-sm border border-gray">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Dimensions</th>
            <th>Gross Lbs</th>
            <th>Tare Lbs</th>
            <th>Net Lbs</th>
            <th>Volume Weight</th>
            <th>Seal Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="weightCertsList.length === 0">
            <td colspan="7">No items in weight certificate</td>
          </tr>
          <ng-container *ngIf="weightCertsList.length > 0">
            <tr *ngFor="let row of weightCertsList; let index = index">
              <td>{{row.itemNo}}</td>
              <td>{{row.dimensions}}</td>
              <td>{{row.gross}}</td>
              <td>{{row.tare}}</td>
              <td>{{row.net}}</td>
              <td>{{row.volumeWeight}}</td>
              <td>{{row.sealNo}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="col-12 mt-5">
  <h6 class="heading"> &nbsp; BEFORE SIGNING, CHECK ITEMS CONDITION & DESCRIBE LOSS OR
    DAMAGE(IF ANY)</h6>
  <div class="internal-notes-box mt-2">
    <textarea class="form-control" rows="3" placeholder="External Notes" [disabled]="true"
      [(ngModel)]="_externalNotesWeight"></textarea>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
        <div class="custom-margin">
          <div class="signature-box">
            <div class="row">
              <div class="col-6">
                <div><label>Customer Signature</label></div>
                <canvas #customerSingatureCanvasWeight class="signature-pad"></canvas>
              </div>
              <div class="col-6">
                <div><label>Suvery Manager Signature</label></div>
                <canvas #surveyMgrSingatureCanvasWeight class="signature-pad"></canvas>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
</div>