<div class="row justify-content-end">
  <div class="col-md-12">
    <button type="button" class="btn btn-primary btn-sm float-end " (click)="downloadPdf()">
      Export &nbsp;<i class="mdi mdi-file-export" ></i>
    </button>
    <button class="btn btn-primary btn-sm me-2 float-end export-button" (click)="viewPdf()">
      View &nbsp;<i class="mdi mdi-file-eye" ></i>
    </button>
  </div>
</div>

<div class="card mt-3">
  <div class="card-body">
    <div class="row" *ngIf="!_showNoAppraisalMsg">
      <div class="col-md-12 appraisal-container">
        <h3 class="text-center"><strong>{{_appraisalSetting.title}}</strong></h3>

        <p class="intro-msg mt-3">{{_appraisalSetting.introMessage}}</p>

        <ng-container *ngFor="let question of _yesNoQuest; let x = index">
          <p class="text-center intro-msg mt-3">{{ question.questionText }}</p>
          <div class="checkbox-container mt-3">
            <ng-container *ngFor="let option of yesNoOptions">
              <input type="radio" class="form-check-input" [value]="option.id"
                [checked]="option.id === question.appraisalResponse.selectedAnswer"
                (change)="selectYesNoOption(question.id, option.id, question.appraisalResponse.id || 0)"
                name="question{{ x }}">
              &nbsp;
              <!-- <label>{{ enumMappingService.mapMultipleChoice(option.id) }}</label> -->
              &nbsp;
            </ng-container>
          </div>
          <div class="checkbox-container">
            <div>
              <label for="yes-checkbox"><strong>Yes</strong></label>
            </div>
            &nbsp;
            &nbsp;
            &nbsp;
            <div>
              <label for="no-checkbox"><strong>No</strong></label>
            </div>
          </div>
        </ng-container>

        <div class="table-responsive mt-3">
          <table class="table table-striped">
            <tbody>
              <ng-container *ngFor="let appraisal of _multipleChoiceQuest; let y = index">
                <tr>
                  <td class="intro-msg">{{ appraisal.questionText }}</td>
                  <td class="options">
                    <ng-container *ngFor="let option of multipleChoiceOptions">
                      <input type="radio" class=" form-check-input" [value]="option.id"
                        [checked]="option.id === appraisal.appraisalResponse.selectedAnswer"
                        (change)="selectMultipleOption(appraisal.id, option.id, appraisal.appraisalResponse.id || 0)"
                        name="appraisal{{ y }}">
                      &nbsp;
                      <label>{{ enumMappingService.mapMultipleChoice(option.id) }}</label>
                      &nbsp;
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="text-center intro-msg mt-3">
          <label>Dissatisfied (😞)
            Poor (😐)
            Satisfied (😊)
            Very Good (😄)
            Excellent (🌟)</label>
        </div>
        <label class="mt-3 label">Please use this Box if you have any additional comments or
          suggestions for improvement:</label>
        <textarea class="form-control intro-msg" placeholder="Please Add a comment" rows="3"
          [(ngModel)]="_appraisalDetail.userComment"></textarea>
        <h3 class="mt-3 text-center">FINAL WALKTHROUGH</h3>
        <div class="walk-msg mt-3">
          <span class="intro-text">I</span>
          <input type="text" class="name-input" [(ngModel)]="_appraisalDetail.name" placeholder="Enter Name">
          <span class="walkthrough-message">{{_appraisalSetting.walkthroughMessage}}</span>
      </div>
      
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="custom-margin">
              <div class="signature-box">
                <div class="row">
                  <div class="col-md-6">
                    <div><label>Signature</label></div>
                    <canvas #signatureCanvas class="signature-pad"></canvas>
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <button class="btn btn-danger btn-xs" (click)="clearSignature()">Clear</button>
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-4">
                    <label for="date">Date</label>
                    <div class="input-group input-cross">

                      <input class="form-control clickable" placeholder="Select Date" name="dp"
                        [value]="generalService.formatDate(_appraisalDetail.date, null)"
                        ngbDatepicker #dp="ngbDatepicker" (click)="dp.toggle()" outsideDays="hidden"
                        [dayTemplate]="dt" (dateSelect)="onDateSelect($event); dp.close()"
                        [startDate]="_date!" readonly />
                      <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                        *ngIf="_appraisalDetail.date" (click)="_appraisalDetail.date = null">
                        <i class="feather icon-x"></i>
                      </button>
                      <button class="input-group-text" type="button" (click)="dp.toggle()">
                        <i class="feather icon-calendar icon-md text-muted"></i>
                      </button>
                    </div>
                    <ng-template #dt let-date let-focused="focused">
                      <span class="custom-day" [class.focused]="focused"
                        [class.highlight]="isHighlighted(date)">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <ng-container>
              <button class="btn btn-primary btn-sm float-end" (click)="saveAppraisalDetail()">
                <i class="mdi mdi-content-save"></i> &nbsp; SAVE
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center" *ngIf="_showNoAppraisalMsg">
      <p> Complete bingo sheet to fill the appraisal form. </p>

    </div>
  </div>
</div>