export class SurveyMaterial {
    id: number;
    leadDetailId: number;
    surveyPlace: string | null;
    item: string | null;
    quantity: number;
    price: number | null;
    type: string | null;
    weight: number | null;
    volume: number | null;
    isDeleted: boolean;
    dimension: string | null;

    constructor() {
        this.id = 0;
        this.leadDetailId = 0;
        this.surveyPlace = null;
        this.item = null;
        this.quantity = 0;
        this.price = null;
        this.type = null;
        this.weight = null;
        this.volume = null;
        this.isDeleted = false;
        this.dimension = null;
    }
}
