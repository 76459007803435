import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent {
  @Input() currentPage = 1;
  @Input() pageSize = 10;
  @Input() totalRecords = 0;
  @Output() pageChange = new EventEmitter<number>();

  totalPages(): number {
    return Math.ceil(this.totalRecords / this.pageSize);
  }

  onPageChange(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages() && pageNumber !== this.currentPage) {
      this.pageChange.emit(pageNumber);
    }
  }

}
