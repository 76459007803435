<!-- <div class="reset-password-container text-center mt-3">
    <h2>Reset Password</h2>

    <form class="form-container mx-auto" style="max-width: 300px;">
        <div class="form-group">
            <label for="newPassword">New Password:</label>
            <input type="password" id="newPassword" name="newPassword" class="form-control form-control-sm"
                [(ngModel)]="newPassword" required>
        </div>

        <div class="form-group">
            <label for="confirmNewPassword">Confirm New Password:</label>
            <input type="password" id="confirmNewPassword" name="confirmNewPassword"
                class="form-control form-control-sm" [(ngModel)]="confirmNewPassword" required>
        </div>

        <button type="button" class="btn btn-primary mt-3" (click)="resetPassword()">
            Change Password
        </button>
    </form>
</div> -->

<!-- <div class="forgot-password-container text-center">

  <div class="forms-container">
    <div class="signin-signup">
      <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
        <img src="assets/images/movd/movd_logo.png" width="200" />
      </a>
      <form class="form-container">
        <h2>Forgot Password</h2>
        <p>Enter your email address to reset your password.</p>

        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" class="form-control" [(ngModel)]="email" required>
        </div>

        <button type="button" class="btn btn-primary mt-3" (click)="forgotpassword()">Send Email</button>
      </form>
    </div>
  </div>

</div> -->


<div class="loader-overlay" *ngIf="isLoading === true">
    <div id="loader">
        <div class="spinner"></div>
    </div>
</div>

<div class="login-container">

    <div class="forms-container">
        <div class="signin-signup">
            <form [formGroup]="resetPasswordForm" class="sign-in-form"
                (ngSubmit)="resetPassword($event)">
                <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
                    <img src="assets/images/movd/movd_logo.png" width="200" />
                </a>
                <a class="sidebar-brand nobleui-logo mt-2" style="font-size: 20px;">
                    <span>Reset Password</span>
                </a>

                <div class="input-field mt-2">
                    <i class="fas fa-lock"></i>
                    <input type="password" class="form-control" id="passwordInput"
                        formControlName="password" placeholder="Password">
                </div>
                <div class="text-center"
                    *ngIf="resetPasswordForm.get('password')?.invalid && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched || submitted)">
                    <div *ngIf="resetPasswordForm.get('password')?.errors?.['required']"
                        class="error-message ">
                        *Password is required!</div>
                    <div *ngIf="resetPasswordForm.get('password')?.errors?.['passwordLength']"
                        class="error-message">
                        <p>*Password must be at least 8 characters long and must contain one special
                            character!</p>
                    </div>
                </div>

                <div class="input-field mt-2">
                    <i class="fas fa-lock"></i>
                    <input type="password" class="form-control" id="confirmPasswordInput"
                        formControlName="confirmPassword" placeholder="Confirm Password">
                </div>
                <div
                    *ngIf="resetPasswordForm.get('confirmPassword')?.invalid && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched || submitted)">
                    <div *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']"
                        class="error-message">
                        *Enter your password again!
                    </div>
                </div>

                <br />
                <div>
                    <input type="submit" value="Reset" class="btn btn-primary no-capitalization" />
                    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
                </div>
                <div class="mt-3">
                    <a routerLink="/login" class="clickable-link">Back To Login</a>
                </div>
            </form>
        </div>
    </div>

    <div class="panels-container">
      <div class="panel left-panel">
        <div class="content">
          <img src="assets/images/movd/movd00.svg" class="logo-image" alt="Techflo Logo" />
        </div>
      </div>
    </div>

</div>
