<div class="center">
  <h4 class="tab-heading">messages</h4>
  <hr>
</div>
<!--Calls Modal-->
<ng-template #callsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="callsModalLabel">
      <div class="TitleIcon">
        <lord-icon src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover"
          colors="primary:#5f606c" class="solid_icons"></lord-icon>
        <span class="TitleText"> Call </span>
      </div>
    </h5>
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_call.id" />
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="_call.outcome" class="input-form-labels">Call Outcome</label>
        <ng-select class="textarea-left-align input-color" [(ngModel)]="_call.outcome"
          id="_call.outcome" required>
          <ng-option value="" selected>Select an outcome</ng-option>
          <ng-option value="Busy">Busy</ng-option>
          <ng-option value="Connected">Connected</ng-option>
          <ng-option value="Left live message">Left live message</ng-option>
          <ng-option value="Left voice mail">Left voice mail</ng-option>
          <ng-option value="No answer">No answer</ng-option>
          <ng-option value="Wrong number">Wrong number</ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <label for="_call.direction" class="input-form-labels">Call Direction</label>
        <ng-select class="textarea-left-align input-color" [(ngModel)]="_call.direction"
          id="_call.direction" required>
          <ng-option value="" selected>Select call direction</ng-option>
          <ng-option value="Inbound">Inbound</ng-option>
          <ng-option value="Outbound">Outbound</ng-option>
        </ng-select>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="selectedDate" class="input-form-labels">Date</label>
        <div class="position-relative">
          <input type="date" class="form-control textarea-left-align input-color"
            [(ngModel)]="selectedDate" id="selectedDate" placeholder="Select date" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Date"
            *ngIf="selectedDate" (click)="selectedDate = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <label for="selectedTime" class="input-form-labels">Time</label>
        <div class="position-relative">
          <input type="time" class="form-control textarea-left-align input-color"
            [(ngModel)]="selectedTime" id="selectedTime" placeholder="Select time" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Time"
            *ngIf="selectedTime" (click)="selectedTime = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control" [(ngModel)]="_call.details" id="_call.details"
          autocomplete="on" value="{{_call.details}}" placeholder="Describe the call..." required
          #callDetails="ngModel"></textarea>
        <div *ngIf="callDetails.invalid && (callDetails.dirty || callDetails.touched)"
          class="error-message error" style="color: red;">
          <div *ngIf="callDetails.errors?.['required']">Write something to log a call.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <button type="button" class="btn btn-primary btn-sm" (click)="saveCall();">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
  </div>
</ng-template>

<!--Emails Modal-->
<ng-template #emailsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="emailsModalLabel">
      <div class="TitleIcon">
        <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="hover" class="modal_icons"
          colors="primary:#121331,secondary:#6571ff"></lord-icon>
        <span class="TitleText"> Email</span>
      </div>
    </h5>
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_email.id" />
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="from">From</label>
        <input type="email" class="form-control input-color" [(ngModel)]="_email.from"
          placeholder="From" required />
      </div>
      <div class="col-md-6">
        <label for="to">To</label>
        <input type="email" class="form-control input-color" [(ngModel)]="_email.to"
          placeholder="To" required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="selectedDate">Date</label>
        <div class="position-relative">
          <input type="date" class="form-control input-color" [(ngModel)]="selectedDate"
            id="selectedDate" placeholder="Select date" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Date"
            *ngIf="selectedDate" (click)="selectedDate = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <label for="selectedTime">Time</label>
        <div class="position-relative">
          <input type="time" class="form-control input-color" [(ngModel)]="selectedTime"
            id="selectedTime" placeholder="Select time" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Time"
            *ngIf="selectedTime" (click)="selectedTime = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control" [(ngModel)]="_email.details" id="_email.details"
          autocomplete="on" value="{{_email.details}}" placeholder="Describe the email..." required
          #emailDetails="ngModel"></textarea>
        <div *ngIf="emailDetails.invalid && (emailDetails.dirty || emailDetails.touched)"
          class="error-message error" style="color: red;">
          <div *ngIf="emailDetails.errors?.['required']">Write something to log an email.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <button type="button" class="btn btn-primary btn-sm" (click)="saveEmail();">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
  </div>
</ng-template>

<!--Meetings Modal-->
<ng-template #meetingsModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="meetingsModalLabel">
      <div class="TitleIcon">
        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover"
          colors="primary:#121331,secondary:#1ac0a1" class="modal_icons"></lord-icon>
        <span class="TitleText"> Meeting </span>
      </div>
    </h5>
    <!--{{_meetingTitle}}-->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 mb-3">
        <label for="subject" class="input-form-labels">Subject</label>
        <input type="text" class="form-control input-color" [(ngModel)]="_meeting.title"
          id="subject" placeholder="Enter a Subject" required />
      </div>

      <div class="col-md-6">
        <label for="selectedDate" class="input-form-labels">Date</label>
        <div class="position-relative">
          <input type="date" class="form-control input-color" [(ngModel)]="selectedDate"
            id="selectedDate" [placeholder]="selectedDate ? '' : 'Select a Date'" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Date"
            *ngIf="selectedDate" (click)="selectedDate = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <label for="selectedTime" class="input-form-labels">Time</label>
        <div class="position-relative">
          <input type="time" class="form-control textarea-left-align input-color"
            [(ngModel)]="selectedTime" id="selectedTime" placeholder="Select time" required />
          <button class="btn btn-small cross-btn" type="button" title="Clear Time"
            *ngIf="selectedTime" (click)="selectedTime = null">
            <i class="feather icon-x"></i>
          </button>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <input type="hidden" id="_meeting.id" />
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_meeting.details"
          id="_meeting.details" autocomplete="on" value="{{_meeting.details}}"
          placeholder="Describe the meeting" required #meetingDetails="ngModel"></textarea>
        <div *ngIf="meetingDetails.invalid && (meetingDetails.dirty || meetingDetails.touched)"
          class="error-message error" style="color: red;">
          <div *ngIf="meetingDetails.errors?.['required']">Write something to log a meeting.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <button type="button" class="btn btn-primary btn-sm" (click)="saveMeeting();">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
  </div>
</ng-template>

<ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs mt-3 nav-fill">

  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="fetchEmails()" class="container">
      <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="hover"
        colors="primary:#121331,secondary:#6571ff" style="width:25px;height:25px"></lord-icon>
      <span class="text">Emails</span>
    </a>
    <ng-template ngbNavContent>
      <br>

      <div class="d-flex justify-content-end">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-primary btn-xs btn-icon-text universeButton square-button"
            (click)="openEmailsModel(emailsModal);" [disabled]="contentDisabled">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp;Log Email &nbsp;
          </button>
        </ng-container>
      </div>
      &nbsp;&nbsp;

      <div *ngIf="_emailsList.length === 0" class="text-center">
        <p>Log an email activity to keep track of your emails.</p>
      </div>

      <div *ngFor="let dataItem of _emailsList; let i = index">
        <div class="card rounded">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title-icon img-xs rounded-circle">{{ dataItem.details.charAt(0) }}</div>
                <div class="ms-2">
                  <p popoverTitle="Email" [ngbPopover]="customPopoverContent">
                    <ng-template #customPopoverContent>
                      <div>
                        <p><strong>From: </strong> {{ dataItem.from }}</p>
                        <p><strong>To: </strong> {{ dataItem.to }}</p>

                        <p><strong>Detail: </strong>{{ dataItem.details }}</p>
                      </div>
                    </ng-template>
                    {{
                    dataItem.details.length > 80 ? (dataItem.details | slice:0:80) + '...' :
                    dataItem.details
                    }}
                  </p>
                  <p class="tx-11 text-muted ">
                    {{dataItem.activityDate | date:'MMM d, yyyy \'at\' h:mma' }}
                  </p>
                  <p><strong>From: </strong> {{ dataItem.from }}&nbsp;
                    <strong>To: </strong> {{ dataItem.to }}
                  </p>
                </div>
              </div>
              <div ngbDropdown *ngIf="_updatePermission || _deletePermission">
                <button class="btn p-0 no-dropdown-toggle-icon" type="button"
                  id="dropdownMenuButton3" ngbDropdownToggle [disabled]="contentDisabled">
                  <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                  <ng-container *ngIf="_updatePermission">
                    <a ngbDropdownItem class="d-flex align-items-center"
                      (click)="openEmailsModel(emailsModal, dataItem.id); editEmail(dataItem); false">
                      <i class="feather icon-edit"></i> &nbsp;<span class="">Edit</span>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="_deletePermission">
                    <a ngbDropdownItem class="d-flex align-items-center"
                      (click)="deleteEmail(dataItem.id)">
                      <i class="feather icon-trash"></i>
                      &nbsp;<span class="">Delete</span>
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="fetchCalls()" class="container">
      <lord-icon src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover"
        colors="primary:#5f606c" style="width:23px;height:23px">
      </lord-icon>
      <span class="text">Calls</span>
    </a>
    <ng-template ngbNavContent>
      <br>
      <div class="d-flex justify-content-end">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-primary btn-xs btn-icon-text universeButton square-button"
            (click)="openCallsModel(callsModal);" [disabled]="contentDisabled">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp;Log Call
          </button>
        </ng-container>
      </div>
      &nbsp;
      <!-- Modal shifted to top-->
      <div *ngIf="_callsList.length === 0" class="text-center">
        <p>Log a call activity to keep track of your discussion and notes.</p>
      </div>
      <div *ngFor="let dataItem of _callsList; let i = index">
        <div class="card rounded">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title-icon img-xs rounded-circle">{{ dataItem.details.charAt(0) }}</div>
                <div class="ms-2">
                  <ng-template #popoverContent>
                    <p><strong>Call Direction:</strong> {{dataItem.direction}}</p>
                    <p><strong>Call Outcome:</strong> {{dataItem.outcome}}</p>
                    <p><strong>Details:</strong> {{dataItem.details}}</p>
                    <p>
                      <strong>Date:</strong> {{dataItem.activityDate | date:'MMM d, yyyy'}} at
                      {{dataItem.activityDate | date:'h:mm a'}}
                    </p>

                  </ng-template>
                  <p popoverTitle="Call" [ngbPopover]="popoverContent">
                    {{
                    dataItem.details.length > 80 ? (dataItem.details | slice:0:80) + '...' :
                    dataItem.details
                    }}
                  </p>
                  <p class="tx-11 text-muted">
                    {{dataItem.activityDate | date:'MMM d, yyyy \'at\' h:mma' }}
                  </p>
                  <p><strong>Call Direction:</strong> {{dataItem.direction}} &nbsp;
                    <strong>Call Outcome:</strong> {{dataItem.outcome}}
                  </p>
                </div>
              </div>
              <div ngbDropdown *ngIf="_updatePermission || _deletePermission">
                <button class="btn p-0 no-dropdown-toggle-icon" type="button"
                  id="dropdownMenuButton3" ngbDropdownToggle [disabled]="contentDisabled">
                  <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                  <ng-container *ngIf="_updatePermission">
                    <a ngbDropdownItem class="d-flex align-items-center" href=""
                      (click)="openCallsModel(callsModal, dataItem.id); editCall(dataItem);false">
                      <i class="feather icon-edit"></i> &nbsp; <span class="">Edit</span>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="_deletePermission">
                    <a ngbDropdownItem class="d-flex align-items-center"
                      (click)="deleteCall(dataItem.id)">
                      <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="fetchMeetings()" class="container">
      <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover"
        colors="primary:#121331,secondary:#1ac0a1" style="width:25px;height:25px"></lord-icon>

      <span class="text">Meetings</span>
    </a>
    <ng-template ngbNavContent>
      <br>
      <div class="d-flex justify-content-end">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-primary btn-xs btn-icon-text universeButton square-button"
            (click)="openMeetingsModel(meetingsModal);" [disabled]="contentDisabled">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp;Log Meeting &nbsp;
          </button>
        </ng-container>
      </div>
      &nbsp;
      <!-- Modal shifted to top-->
      <div *ngIf="_meetingsList.length === 0" class="text-center">
        <p>Log a meeting activity to keep track of your meeting and notes.</p>
      </div>
      <div *ngFor="let dataItem of _meetingsList; let i = index">
        <div class="card rounded">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title-icon img-xs rounded-circle">{{ dataItem.details.charAt(0) }}</div>
                <div class="ms-2">
                  <p class="fw-bold">{{dataItem.detail}}</p>
                  <ng-template #popoverContent>
                    <p><strong>Subject:</strong> {{dataItem.title}}</p>
                    <p><strong>Detail:</strong> {{dataItem.details}}</p>

                    <p>
                      <strong>Date:</strong> {{dataItem.activityDate | date:'MMM d, yyyy'}} at
                      {{dataItem.activityDate | date:'h:mm a'}}
                    </p>

                  </ng-template>
                  <p popoverTitle="Meeting" [ngbPopover]="popoverContent">
                    {{
                    dataItem.title.length > 60 ? (dataItem.title | slice:0:60) + '...' :
                    dataItem.title
                    }}
                  </p>
                  <p popoverTitle="Meeting" [ngbPopover]="popoverContent">
                    {{
                    dataItem.details.length > 80 ? (dataItem.details | slice:0:80) + '...' :
                    dataItem.details
                    }}
                  </p>
                  <p class="tx-11 text-muted">
                    {{dataItem.activityDate | date:'MMM d, yyyy \'at\' h:mma'}}
                  </p>
                </div>
              </div>
              <div ngbDropdown *ngIf="_updatePermission || _deletePermission">
                <button class="btn p-0 no-dropdown-toggle-icon" type="button"
                  id="dropdownMenuButton3" ngbDropdownToggle [disabled]="contentDisabled">
                  <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                  <ng-container *ngIf="_updatePermission">
                    <a ngbDropdownItem class="d-flex align-items-center"
                      (click)="openMeetingsModel(meetingsModal, dataItem.id); editMeeting(dataItem); false">
                      <i class="feather icon-edit"></i> &nbsp;<span class="">Edit</span>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="_deletePermission">
                    <a ngbDropdownItem class="d-flex align-items-center"
                      (click)="deleteMeeting(dataItem.id)">
                      <i class="feather icon-trash"></i>
                      &nbsp;<span class="">Delete</span>
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>