<ng-template #addQuestionModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="emailsModalLabel">
      <span class="TitleText"> Question </span>
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_email.id" />
    </div>
    <div class="row mt-2">
      <div class="form-group col-md-12">
        <label for="questionText">Question</label>
        <input type="text" id="questionText" class="form-control input-color"
          [(ngModel)]="_appraisalQuestion.questionText" placeholder="Enter question" required />
      </div>
    </div>
    <div class="row mt-2">
      <div class="form-group col-md-6">
        <label for="questionType">Question Type</label>
        <ng-select id="questionType" class="form-control input-color"
          [(ngModel)]="_appraisalQuestion.questionType" required>
          <ng-option [value]="_quesitonType.YesNo">Yes/No</ng-option>
          <ng-option [value]="_quesitonType.MultipleChoice">Multiple Choice</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="modal-footer text-end">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveAppraisalQuestion();">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>


<ng-template #appraisalTemplate>
  <div class="col-md-10 mt-1">
    <label class="fw-bold">Title</label>
    <textarea class="form-control quot-text-area" name="introMessage"
      [(ngModel)]="_appraisalSetting.title"></textarea>
  </div>
  <div class="col-md-10 mt-2">
    <label class="fw-bold">Appraisal Intro</label>
    <textarea class="form-control quot-text-area" name="introMessage"
      [(ngModel)]="_appraisalSetting.introMessage"></textarea>
  </div>
  <div class="col-md-10 mt-2">
    <label class="fw-bold">Walkthrough Message</label>
    <textarea class="form-control quot-text-area" name="walkthroughMessage"
      [(ngModel)]="_appraisalSetting.walkthroughMessage"></textarea>
  </div>
  <div class="col-md-10 mt-3">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveAppraisalSettings()">
      <i class="mdi mdi-content-save"></i> Save
    </button>
  </div>
  <div class="row mt-2">
    <div class="col-md-2">
      <h3>Questions</h3>
    </div>
    <div class="col-md-7"></div>
    <div class="col-md-3 text-end">
      <button type="button" class="btn btn-primary btn-xs btn-icon-text"
        (click)="openQuestionModal(addQuestionModal, null)">
        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
          colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
        </lord-icon> &nbsp; Add Question </button>
    </div>
    <ng-container *ngIf="_appraisalQuestionsList.length === 0">
      <div class="card rounded">
        <div class="card-header text-center">
          <p>No Questions currently.
          <p>
        </div>
      </div>
    </ng-container>
    <div class="mt-1" *ngFor="let dataItem of _appraisalQuestionsList; let i = index">
      <div class="card rounded">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="title-icon img-xs rounded-circle">
                {{
                dataItem.questionText.charAt(0)
                }}
              </div>
              <div class="ms-2">
                <ng-template #popoverContent>
                  <p><strong>Q:</strong> {{dataItem.questionText}}</p>
                </ng-template>
                <p popoverTitle='Question' [ngbPopover]="popoverContent">
                  {{
                  dataItem.questionText.length > 80 ? (dataItem.questionText | slice:0:80) +
                  '...' :
                  dataItem.questionText
                  }}
                </p>
              </div>

            </div>
            <div ngbDropdown>
              <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                ngbDropdownToggle>
                <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                <a ngbDropdownItem class="d-flex align-items-center"
                  (click)="openQuestionModal(addQuestionModal, dataItem);">
                  <i class="feather icon-edit"></i> &nbsp;<span class="">Edit</span>
                </a>
                <a ngbDropdownItem class="d-flex align-items-center"
                  (click)="deleteQuestion(dataItem.id)">
                  <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="card rounded">
  <div class="card-header">
    <div class="row">
      <ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-1" [(activeId)]="defaultNavActiveId">
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="onChangeTab(1,enumAppraisalType.Packing)" class="nav-tab sub-tabs">
            <span class="text"> Origin (Packing)</span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="appraisalTemplate"></ng-container>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="onChangeTab(2,enumAppraisalType.Delivery)"
            class="nav-tab sub-tabs">
            <span class="text"> Destination (Delivery) </span>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="appraisalTemplate"></ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
    </div>
  </div>
</div>

<!-- [(ngModel)]="appraisalSettings.AppraisalSetting.introMessage"
[(ngModel)]="appraisalSettings.AppraisalSetting.walkthroughMessage" -->