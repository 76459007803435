import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { GeneralService } from 'src/services/general.service';
import { ReportsService } from 'src/services/reports.service';
// import { CustomerSatisfaction, eCustomerSatisfactionSubType } from '../../models/customer-satisfaction.model';
import { eMoveReportType, eReportSubType, MoveReportsDTO } from '../../models/move-reports-dto.model';
import { CustomerSatisfactionSummarizeReportDTO } from '../../models/customer-satisfaction-summarized-report.model';
import { PackingAppraisalSummarizeReportDTO } from '../../models/packing-appraisal-summarise-report.model';
import { DeliveryAppraisalSummarizeReportDTO } from '../../models/delivery-appraisal-summarise-report.model';

@Component({
  selector: 'app-customer-satisfaction',
  templateUrl: './customer-satisfaction.component.html',
  styleUrls: ['./customer-satisfaction.component.scss']
})
export class CustomerSatisfactionComponent implements OnInit {
  _reportActiveTabId4 = 1;
  // @Input() moveReportsData: MoveReportsDTO = new MoveReportsDTO();
  @Input() moveReports!: MoveReportsDTO;

  // @Input() _moveReports: MoveReportsDTO = new MoveReportsDTO();

  eMoveReportType = eMoveReportType;
  eReportSubType = eReportSubType;
  columnMode: ColumnMode = ColumnMode.force;
  loadingIndicator = true;
  showSection: boolean = false;
  _customerSatisfactionSummaryData: any[] = [];
  _customerSatisfactionSummarizeReportModel = new CustomerSatisfactionSummarizeReportDTO();
  _customerSatisfactionSummaryColumns: any[] = [];
  _packingAppraisalsummaryData: any[] = [];
  _packingAppraisalSummarizeReportModel = new PackingAppraisalSummarizeReportDTO()
  _packingAppraisalsummarycolumnData: any[] = [];
  _deliveryAppraisalsummaryData: any[] = [];
  _deliveryAppraisalSummarizeReportDTO = new DeliveryAppraisalSummarizeReportDTO()
  _deliveryAppraisalsummarycolumnData: any[] = [];

  constructor(public generalService: GeneralService, private _reportsService: ReportsService) { }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('Received move reports data:', this._moveReports);
  //   // Call a method to handle the received data or perform any other action
  //   this.getCustomerSatisfactionReports(this._moveReports);
  // }



  ngOnInit(): void {
    // this.getCustomerSatisfactionReports(this._moveReports);

  }

  getCustomerSatisfactionReports(moveReports: any) {
    console.log("reports movd", this.moveReports);
    moveReports = this.moveReports;

    this._reportsService.getLeadReports(moveReports).subscribe({
      next: (data: any[]) => {
        console.log("reports jsexenwk", data);
        if (moveReports.moveReportType === eMoveReportType.CustomerSatisfaction &&
          moveReports.reportSubType === eReportSubType.SummarizeReport) {
          this._customerSatisfactionSummaryData = data;
          this._customerSatisfactionSummaryColumns = Object.keys(this._customerSatisfactionSummarizeReportModel)
            .map((key) => {
              let header: string;
              let prop: string = key;
              switch (key) {
                case 'date':
                  header = 'Date';
                  break;
                case 'dissatisfaction':
                  header = 'Dissatisfaction (😞)';
                  break;
                case 'poor':
                  header = 'Poor (😐)';
                  break;
                case 'satisfied':
                  header = 'Satisfied (😊)';
                  break;
                case 'veryGood':
                  header = 'Very Good (😄)';
                  break;
                case 'excellent':
                  header = 'Excellent (🌟)';
                  break;
                case 'overallRate':
                  header = 'Overall Rate';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }
              return { header, prop };
            });

        }
        else if (moveReports.moveReportType === eMoveReportType.DeliveryCustSatisfaction &&
          moveReports.reportSubType === eReportSubType.SummarizeReport) {
          this._deliveryAppraisalsummaryData = data;
          this._deliveryAppraisalsummarycolumnData = Object.keys(this._deliveryAppraisalSummarizeReportDTO)
            .map((key) => {
              let header: string;
              let prop: string = key;
              switch (key) {
                case 'date':
                  header = 'Date';
                  break;
                case 'refId':
                  header = 'Delivery ID';
                  break;
                case 'moveId':
                  header = 'Move ID';
                  break;
                case 'dissatisfaction':
                  header = 'Dissatisfaction (😞)';
                  break;
                case 'poor':
                  header = 'Poor (😐)';
                  break;
                case 'satisfied':
                  header = 'Satisfied (😊)';
                  break;
                case 'veryGood':
                  header = 'Very Good (😄)';
                  break;
                case 'excellent':
                  header = 'Excellent (🌟)';
                  break;
                case 'overallRate':
                  header = 'Overall Rate';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }
              return { header, prop };
            });
        }
        else if (moveReports.moveReportType === eMoveReportType.PackingCustSatisfaction &&
          moveReports.reportSubType === eReportSubType.SummarizeReport) {
          this._packingAppraisalsummaryData = data;
          this._packingAppraisalsummarycolumnData = Object.keys(this._packingAppraisalSummarizeReportModel)
            .map((key) => {
              let header: string;
              let prop: string = key;
              switch (key) {
                case 'date':
                  header = 'Date';
                  break;
                case 'refId':
                  header = 'Packing ID';
                  break;
                case 'moveId':
                  header = 'Move ID';
                  break;
                case 'dissatisfaction':
                  header = 'Dissatisfaction (😞)';
                  break;
                case 'poor':
                  header = 'Poor (😐)';
                  break;
                case 'satisfied':
                  header = 'Satisfied (😊)';
                  break;
                case 'veryGood':
                  header = 'Very Good (😄)';
                  break;
                case 'excellent':
                  header = 'Excellent (🌟)';
                  break;
                case 'overallRate':
                  header = 'Overall Rate';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }
              return { header, prop };
            });
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }


  ensureNumber(value: any): number {
    // Ensure that value is a number
    if (typeof value === 'number') {
      return value;
    }
    // If value is not a number, return 0
    return 0;
  }

  calculateOverallRate(row: any): string {
    const totalResponses = row.dissatisfaction + row.poor + row.satisfied + row.veryGood + row.excellent;
    const overallRate = ((row.dissatisfaction * 1) + (row.poor * 2) + (row.satisfied * 3) + (row.veryGood * 4) + (row.excellent * 5)) / totalResponses;
    return overallRate.toFixed(2); // Rounded to 2 decimal places
  }

  onChangeCustSatisfactionSubType(type: any, moveReportType: eMoveReportType, reportSubType: eReportSubType) {
    this._reportActiveTabId4 = type;

    // Create a copy of _moveReports
    const newMoveReports = { ...this.moveReports };

    // Modify the copy
    newMoveReports.moveReportType = moveReportType;
    newMoveReports.reportSubType = reportSubType;

    // Use the modified copy
    this.moveReports = newMoveReports;
  }


  toggleSection() {
    this.showSection = !this.showSection;
  }

  clearFilter() {
    // Implement if needed
  }
}

