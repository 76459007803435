import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppraisalDTO, AppraisalDetail, AppraisalQuestion, AppraisalSetting, eAppraisalType, eQuestionType } from '../../../models/appraisal.model';
import { MovdService } from '../../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-appraisal-settings',
  templateUrl: './appraisal-settings.component.html',
  styleUrls: ['./appraisal-settings.component.scss']
})
export class AppraisalSettingsComponent implements OnInit {
  _appraisalDTO = new AppraisalDTO();
  _appraisalSetting = new AppraisalSetting();

  _appraisalQuestion = new AppraisalQuestion();
  _appraisalQuestionsList: AppraisalQuestion[] = []

  defaultNavActiveId: number = 1;

  enumAppraisalType = eAppraisalType;

  _currentAppraisalType: eAppraisalType = eAppraisalType.Packing;
  _quesitonType = eQuestionType;
  _addQuestionModalRef: NgbModalRef | undefined;

  constructor(
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private _modalService: NgbModal) { }

  ngOnInit(): void {
    this.onChangeTab(1, eAppraisalType.Packing);
  }

  onChangeTab(tabIndex: number, type: eAppraisalType): void {
    this.defaultNavActiveId = tabIndex;
    this._currentAppraisalType = type;
    this.getAppraisalSettings(this._currentAppraisalType);
    // this.appraisalSettings = this._movdService.getAppraisalSettings(type);
  }

  getAppraisalSettings(type: eAppraisalType) {
    this._movdService.getAppraisalSettings(type).subscribe({
      next: (response: any) => {
        // console.log('gettting appraisal settings', response);
        if (response) {
          this._appraisalDTO = response;

          if (this._appraisalDTO.appraisalSetting) {
            this._appraisalSetting = this._appraisalDTO.appraisalSetting;
          } else {
            this._appraisalSetting = new AppraisalSetting();
          }

          if (this._appraisalDTO.questions && this._appraisalDTO.questions.length > 0) {
            this._appraisalQuestionsList = this._appraisalDTO.questions;
          } else {
            this._appraisalQuestionsList = [];
          }
        } else {
          this._appraisalDTO = new AppraisalDTO();
          this._appraisalSetting = new AppraisalSetting();
          this._appraisalQuestionsList = [];
        }
      },
      error: (error: any) => {
        console.error('Error getting appraisal settings', error);
      }
    });
  }

  saveAppraisalSettings() {
    // this._appraisalDTO = 

    if (!this._appraisalDTO) {
      this._appraisalDTO = new AppraisalDTO();
    }

    if (!this._appraisalDTO.appraisalDetail) {
      this._appraisalDTO.appraisalDetail = new AppraisalDetail();
    }

    this._appraisalDTO.appraisalSetting = this._appraisalSetting;
    this._appraisalDTO.appraisalSetting.appraisalType = this._currentAppraisalType;

    // console.log("appraisal DTO", this._appraisalDTO);

    this._movdService.saveAppraisalSettings(this._appraisalDTO).subscribe({
      next: (response: any) => {
        // console.log('gettting appraisal settings', response);
        this._appComponent.showSuccessSwal('saved', 'appraisal setting');

      },
      error: (error: any) => {
        console.error('Error getting appraisal settings', error);
      }
    });
  }

  openQuestionModal(template: any, dataItem: AppraisalQuestion | null) {
    this._appraisalQuestion = new AppraisalQuestion();

    if (dataItem) {
      this._appraisalQuestion = dataItem;
    }

    this._addQuestionModalRef = this._modalService.open(template);
  }

  saveAppraisalQuestion() {
    if (this._appraisalQuestion && this._appraisalQuestion.questionText.trim().length === 0) {
      return;
    }

    this._appraisalQuestion.appraisalSettingId = this._appraisalDTO.appraisalSetting.id;

    this._movdService.saveAppraisalQuestion(this._appraisalQuestion).subscribe({
      next: (response: any) => {
        this._addQuestionModalRef?.close();
        this._appraisalQuestionsList.push(this._appraisalQuestion);
        this._appComponent.showSuccessSwal('saved', 'quesiton');
        this.getAppraisalSettings(this._currentAppraisalType);
        // console.log('save appraisal question response', response);
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('saving', 'quesiton');
        console.error('Error getting appraisal settings', error);
      }
    });
  }

  async deleteQuestion(id: number) {
    let text: string = 'You are about to delete a question.';
    let confirmButtonText: string = 'Yes, delete it!';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this._movdService.deleteAppraisalQuestion(id).subscribe({
        next: (response: any) => {
          this.getAppraisalSettings(this._currentAppraisalType);
          // console.log('delete appraisal question respnse', response);
        },
        error: (error: any) => {
          console.error('Error deleting appraisal quesiton', error);
        }
      });
    }
    else {
      return;
    }
  }
}
