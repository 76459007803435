export class PackagingInventoryItem {
  id: number;
  packagingInventoryId: number;
  itemNo: number | null;
  description: string | null;
  weight: number | null;
  packagingType: number | null;
  make: string | null;
  model: string | null;
  remarks: string | null;
  value: number | null;
  dimensions: string | null;
  cuft: string | null;
  packingBingoBit: boolean;
  deliveryBingoBit: boolean;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean | null;
  quantity: number;  // not mapped property

  constructor() {
    this.id = 0;
    this.packagingInventoryId = 0;
    this.itemNo = null;
    this.description = null;
    this.weight = null;
    this.packagingType = null;
    this.make = null;
    this.model = null;
    this.remarks = null;
    this.value = null;
    this.dimensions = null;
    this.cuft = null;
    this.packingBingoBit = false;
    this.deliveryBingoBit = false;
    this.createdBy = 0;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
    this.quantity = 0;
  }
}

export enum ePackingType {
  AssembleDisassemble = 10,
  CratedItem = 20,
  Electronic = 30,
  HighValue = 40
}

export const ePackingTypeOptions: { id: number; value: string }[] = [
  { id: ePackingType.AssembleDisassemble, value: 'Assemble/Disassemble' },
  { id: ePackingType.CratedItem, value: 'Crated Item' },
  { id: ePackingType.Electronic, value: 'Electronic' },
  { id: ePackingType.HighValue, value: 'High Value' },
];

