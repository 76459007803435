export class SurveyItem {
    id: number;
    surveyPlaceId: number | null;
    title: string;
    estimatedVolume: number | null;
    weight: number | null;
    ownedBy: number;
    isActive: boolean | null;
    dimension: string | null;


    constructor() {
        this.id = 0;
        this.surveyPlaceId = null;
        this.title = '';
        this.estimatedVolume = null;
        this.weight = null;
        this.ownedBy = 0;
        this.isActive = true;
        this.dimension = null;
    }
}