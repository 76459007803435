import { Address } from "../contact/contact.models";


export class CustomerDTO {
  id: number;
  customerName: string;
  phone: string;
  email: string;
  fax: string;
  dateOfBirth: Date;
  details: string;
  contactType: number;
  city: string;
  country: number;
  role: string;
  title: string;
  contactId: number;
  //roleId: number;
  companyId: number;
  industryId: number;
  compOwnerId: number;
  creationDate: Date;
  createdBy: number;
  companyName: string;
  ownerName: string;
  industryName: string;
  companyURL: string;
  linkedInURL: string;
  noOfEmployees: string;
  openPositions: number;
  salesType: number;
  addressId: number;
  streetAddress: string;
  zipCode: string;
  constructor() {
    this.id = 0;
    this.customerName = ' ';
    this.phone = '';
    this.email = '';
    this.fax = '';
    this.dateOfBirth = new Date();
    this.details = '';
    this.contactType = 0;
    this.city = '';
    this.country = 0;
    this.role = '';
    this.title = '';
    //this.roleId = 0;
    this.companyId = 0;
    this.contactId = 0;
    this.industryId = 0;
    this.compOwnerId = 0;
    this.creationDate = new Date();
    this.createdBy = 0;
    this.companyName = '';
    this.ownerName = '';
    this.industryName = '';
    this.companyURL = '';
    this.linkedInURL = '';
    this.noOfEmployees = '';
    this.openPositions = 0;
    this.salesType = 0;
    this.addressId = 0;
    this.streetAddress = '';
    this.zipCode = '';
  }
}

export class DealDetailsDTO {
  id?: number;
  customerId: number;
  customerName: string;
  phone?: string;
  email?: string;
  role?: string;
  companyName?: string;
  amount: number;
  probability: number;
  pipeName?: string;
  name: string;
  creationDate?: Date;
  createdBy?: string;
  // contactId: number;
  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.customerName = ' ';
    this.phone = '';
    this.email = '';
    this.role = '';
    this.companyName = '';
    this.amount = 0;
    this.probability = 0;
    this.pipeName = '';
    this.name = '';
    this.creationDate = new Date();
    this.createdBy = '';
    // this.contactId = 0;
  }
}

export class ActivityModel {
  customerId: any;
  dealId: any;
  title: string;
  details: string;
  activityDate: any;
  id: any;
  timeAgo: string;

  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.dealId = 0;
    this.title = '';
    this.details = '';
    this.activityDate = '2023-06-15T10:43:52.102Z';
    this.timeAgo = '';
  }
}


export class NotesModel {
  id: number;
  customerId: number;
  dealId: number;
  title: string;
  details: string;
  createdBy: number;
  activityDate: Date;
  timeAgo: string;
  entityType: string = 'NotesModel';
  constructor() {
    this.id = 0;
    this.dealId = 0;
    this.customerId = 0;
    this.title = '';
    this.details = '';
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.activityDate = new Date();
    this.timeAgo = '';
  }
}


export class EmailsModel {
  customerId: number;
  dealId: number;
  details: string;
  to: string;
  from: string;
  activityDate: Date;
  createdBy: number;
  title: string;
  id: number;
  timeAgo: string;
  entityType: string = 'EmailsModel';
  constructor() {
    this.id = 0;
    this.dealId = 0;
    this.customerId = 0;
    this.details = '';
    this.to = '';
    this.from = '';
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.activityDate = new Date();
    this.timeAgo = '';
    this.title = '';
  }
}

export class CallsModel {
  date: string | null = null;
  time: string | null = null;
  customerId: number;
  moveDetailId: number;
  dealId: number;
  details: string;
  activityDate: Date;
  createdBy: number;
  id: number;
  outcome: string;
  direction: string;
  timeAgo: string;
  entityType: string = 'CallsModel';
  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.dealId = 0;
    this.moveDetailId = 0;
    this.details = '';
    this.activityDate = new Date();
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.timeAgo = '';
    this.outcome = '';
    this.direction = '';
  }
}

export interface TasksResponse {
  tasks: TasksModel[];
  totalRecords: number;
}

export class TasksModel {
  id: number;
  customerId: number;
  dealId: number;
  leadDetailId: number;
  title: string;
  type: string;
  details: string;
  status: any;
  activityDate: string;
  createdBy: number;
  assignedto: number | null;
  assignedToName: string | null;
  dueDate: any;
  priority: string;
  queue: string;
  timeAgo: string;
  entityType: string = 'TasksModel';
  done: boolean;


  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.dealId = 0;
    this.leadDetailId = 0;
    this.details = '';
    this.title = '';
    this.type = "To-do";
    this.status = 0;
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.activityDate = ''; //"2023 - 06 - 01 T00:00:00";
    this.assignedto = null;
    this.assignedToName = null;
    this.dueDate = null;
    this.priority = "None";
    this.queue = "None";
    this.timeAgo = '';
    this.done = false;
  }
}


export class MeetingsModel {

  id: any;
  customerId: any;
  dealId: any;
  details: string;
  title: string;
  activityDate: string;
  createdBy: number | undefined;
  attendance: any;
  outcome: string;
  organizer: any;
  location: any;
  startDate: any;
  endDate: any;
  timeAgo: string;
  entityType: string = 'MeetingsModel';
  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.dealId = 0;
    this.details = '';
    this.title = '';
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.activityDate = ''; //"2023 - 06 - 01 T00:00:00";
    this.attendance = 0;
    this.outcome = '';
    this.organizer = 0;
    this.location = '';
    this.startDate = "2023-06-12T05:48:08.605Z";
    this.endDate = "2023-06-12T05:48:08.605Z";
    this.timeAgo = '';
  }
}

export class LatestActivityModel {
  id: number;
  type: string;
  title: string;
  details: string;
  activityDate: Date;
  createdBy: string;
  timeAgo: '';

  constructor() {
    this.id = 0;
    this.type = '';
    this.title = '';
    this.details = '';
    this.activityDate = new Date();
    this.createdBy = '';
    this.timeAgo = '';
  }
}

//export class CustomerTaskQueue {
//  id: any;
//  customerId: any;
//  queue: string;

//  constructor() {
//    this.id = 0;
//    this.customerId = 0;
//    this.queue = '';
//  }
//}

export class AttachmentModel {
  customerId: any;
  dealId: any;
  details: string;
  title: string;
  activityDate: string;
  createdBy: number;
  fileName: any;
  fileSize: any;
  url: any;
  id: any;
  timeAgo: string;

  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.dealId = 0;
    this.details = '';
    this.title = '';
    this.activityDate = ''; //"2023 - 06 - 01 T00:00:00";
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.fileName = '';
    this.fileSize = '';
    this.url = '';
    this.timeAgo = '';
  }
}
export class DealDetailModel {
  id: any;
  customerId: any;
  customerName: string;
  phone: string;
  email: string;
  role: string;
  companyName: string;
  amount: any;
  probability: any;
  pipeName: string;
  name: string;

  constructor() {
    this.id = 0;
    this.customerId = 0;
    this.customerName = '';
    this.phone = '';
    this.email = '';
    this.role = '';
    this.companyName = '';
    this.amount = '';
    this.probability = '';
    this.pipeName = '';
    this.name = '';
  }
}


export interface CustomerUpdateDTOModel {
  id: any;
  customerName: string;
  industryId: number;
  compOwnerId: number;
  companyId: number;
  detail: string;
  phone: string;
  country: number;
  city: string;
  email: string;
  companyURL: string;
  linkedInURL: string;
  noOfEmployees: string;
  openPositions: number;
  company: string;
  role: string;
  address: Address;
  salesType: number;
}

export interface DealUpdateDTOModel {
  id: any;
  name: string;
  customerId: number;
  //dealOwnerId: number;
  //pipeId: number;
  amount: number;
  probability: number;
  //  customerName: string
  //salesRepId: number;

}

//constructor() {
//  this.id = 0;
//  this.customerName = '';
//  this.phone = '';
//  this.email = '';
//  this.role = '';
//  this.companyName = '';
//}

// constructor() {
// //  this.id = 0;
// //  this.pipeId = 0;
//   this.contactId = 0;
// //  this.name = '';
// //  this.dealOwnerId = 0;
// //  this.amount = 0;
// //  this.probability = 0;
// //  this.salesRepId = 0;
// //  this.name = '';
// }
