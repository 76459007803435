export class DashboardItem {
  leadDetailId?: number;
  type?: string;
  content?: string;
  date?: string;
  time?: string;

  constructor(type: string, content: string | undefined, date: string, time: string, leadDetailId: number) {
    this.leadDetailId = leadDetailId;
    this.type = type;
    this.content = content;
    this.date = date;
    this.time = time;
  }
}