<div *ngIf="_isDataLoaded">
  <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">-->
  <!--<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">-->
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">

        <div class="text-center">
          <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
            <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo"
              width="150">
          </ng-container>
          <ng-template #defaultLogo>
            <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
          </ng-template>
        </div>

        <h1 class="text-center mt-3">Quotation</h1>
        <div class="text-left mt-3">
          <div class="row border from-to-row">
            <!-- Column 1: From -->
            <div class="col-md-4 border">
              <h5 class="heading-margin text-center">From:</h5>
              <hr>
              <address>
                <strong style="color: #0066cc;">{{ _companySetting.companyName }}</strong><br>
                <span style="color: #444;">{{ _companySetting.streetAddress }}</span><br>
                <span style="color: #444;">{{ _companySetting.city }}, {{ _companySetting.country
                  }}</span><br>
                <span style="color: #777;">Email: {{ _companySetting.email }}</span><br>
                <span style="color: #777;">Contact: {{ _companySetting.contact }}</span>
              </address>
            </div>

            <!-- Column 2: To -->
            <div class="col-md-4 border">
              <h5 class="heading-margin text-center">To:</h5>
              <hr>
              <address>
                <strong style="color: #0066cc;"> {{ _leadSummary.customer || '' }}</strong><br>
                <!-- <span style="color: #444;">456 Client Street</span><br>
                <span style="color: #444;">City, State, ZIP</span><br> -->
                <span style="color: #777;">Email: {{ _leadSummary.email}}</span>
              </address>
            </div>

            <!--Column 3: Quotation Details-->
            <div class="col-md-4 border">
              <h5 class="heading-margin text-center">Quotation Details</h5>
              <hr>
              <p>
                Date: {{ generalService.formatDate(_leadQuotation.date, null) }}<br />
                Quotation #: {{ _leadQuotation.id ? _leadQuotation.id : ' - ' }}<br />
                Move #: {{ _leadQuotation.leadDetailId ? _leadQuotation.leadDetailId : ' - '
                }}<br />
                Valid Till: {{ generalService.formatDate(_leadQuotation.validationDate, null) }}
                <br />
                Customer #: {{ _leadSummary.customerId ? _leadSummary.customerId : ' - ' }}<br />
              </p>
            </div>
          </div>
        </div>
        <br />
        <table class="table table-bordered border">
          <tr>
            <td>Category:</td>
            <td>{{getMoveCategoryLabel(_leadSummary.moveCategory) || ' - '}}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>{{getMoveTypeLabel(_leadSummary.moveType) || ' - '}}</td>
          </tr>
          <tr
            *ngIf="!((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>From:</td>
            <td>{{ _leadSummary.origin || ' - '}}</td>
          </tr>
          <tr
            *ngIf="((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>From POL:</td>
            <td>{{ _leadSummary.portOfLoading || ' - '}}</td>
          </tr>
          <tr
            *ngIf="((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>From POD:</td>
            <td>{{ _leadSummary.portOfDischarge || ' - '}}</td>
          </tr>

          <!--<tr *ngIf="!((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>To POE:</td>
            <td>{{ _leadSummary.portOfExit || ' - '}}</td>
          </tr>-->
          <tr
            *ngIf="((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>To Residence:</td>
            <td>{{ _leadSummary.destination || ' - '}}</td>
          </tr>

          <tr *ngIf="_moveDetail.portOfLoading && _moveDetail.portOfLoading.trim() ! ==  ''">
            <td>TO POL:</td>
            <td>{{ _moveDetail.portOfLoading || ' - '}}</td>
          </tr>
          <tr *ngIf="_moveDetail.portOfDischarge && _moveDetail.portOfDischarge.trim() ! ==  ''">
            <td>TO POD:</td>
            <td>{{ _moveDetail.portOfDischarge || ' - '}}</td>
          </tr>
          <tr
            *ngIf="!(
                      (_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Air) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>Destination:</td>
            <td>{{ _leadSummary.destination || ' - ' }}</td>
          </tr>
          <tr>
            <td>Est. Weight/Volume:</td>
            <td>
              {{ calculateTotal().toFixed(2) + ' ' + _weightUnit }} /
              {{ calculateTotal().toFixed(2) + ' ' + _volumeUnit }}
            </td>
          </tr>
          <tr
            *ngIf="!((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>Origin Charges:</td>
            <td>
              {{
              (_leadQuotation.originCharges ? _currency + ' ' + _leadQuotation.originCharges :
              _currency + ' 0')
              }}
            </td>
          </tr>
          <tr
            *ngIf="!((_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>Freight Charges:</td>
            <td>
              {{
              (_leadQuotation.freightCharges ? _currency + ' ' + _leadQuotation.freightCharges
              : _currency + ' 0')
              }}
            </td>
          </tr>
          <tr
            *ngIf="!( (_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.SeaLCL) ||
                      (_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Air) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea) ||
                      (_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air))">
            <td>Destination Charges:</td>
            <td>
              {{
              (_leadQuotation.destinationCharges ? _currency + ' ' +
              _leadQuotation.destinationCharges : _currency + ' 0')
              }}
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>
              {{
              (_leadQuotation.originCharges && _leadQuotation.freightCharges &&
              _leadQuotation.destinationCharges ? _currency + ' ' + (_leadQuotation.originCharges +
              _leadQuotation.freightCharges + _leadQuotation.destinationCharges) : _currency + '
              0')
              }}
            </td>
          </tr>
        </table>
        &nbsp;

        <div class="mt-4">

          <ng-container
            *ngIf="(_leadQuotation.destinationCustomClearance || _leadQuotation.inlandHaulage || _leadQuotation.delivery || _leadQuotation.returnEmptyUnit ||
                  _leadQuotation.packAndLoad || _leadQuotation.dockTransfer || _leadQuotation.exportClearance || _leadQuotation.transitTime ||
                  _leadQuotation.packOut || _leadQuotation.airPortTransfer || _leadQuotation.importClearance)">
            <h4>Estimated Services Schedule </h4>


            <div
              *ngIf="_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Sea">

              <div *ngIf="_leadQuotation.packAndLoad">
                Pack and Load: {{ _leadQuotation.packAndLoad }} <br />
              </div>
              <div *ngIf="_leadQuotation.dockTransfer">
                Dock Transfer: {{ _leadQuotation.dockTransfer }} <br />
              </div>
              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
            </div>

            <div
              *ngIf="_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.Air">

              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
              <div *ngIf="_leadQuotation.packOut">
                Pack Out: {{ _leadQuotation.packOut }} <br />
              </div>
              <div *ngIf="_leadQuotation.airPortTransfer">
                AirPort Transfer: {{ _leadQuotation.airPortTransfer }} <br />
              </div>
            </div>

            <div
              *ngIf="_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Air">
              <div *ngIf="_leadQuotation.destinationCustomClearance">
                Destination Custom Clearance: {{ _leadQuotation.destinationCustomClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.delivery">
                Delivery: {{ _leadQuotation.delivery }} <br />
              </div>
            </div>

            <div
              *ngIf="_moveTransport.categoryType == eMoveCategoryType.PortToDoor && _moveTransport.transportType == eTransportType.Sea">
              <div *ngIf="_leadQuotation.destinationCustomClearance">
                Destination Custom Clearance: {{ _leadQuotation.destinationCustomClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.delivery">
                Delivery: {{ _leadQuotation.delivery }} <br />
              </div>
              <div *ngIf="_leadQuotation.returnEmptyUnit">
                Return of empty unit: {{ _leadQuotation.returnEmptyUnit }} <br />
              </div>
              <div *ngIf="_leadQuotation.inlandHaulage">
                Inland Haulage: {{ _leadQuotation.inlandHaulage }} <br />
              </div>
            </div>

            <div
              *ngIf="(_moveTransport.categoryType == eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType == eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType == eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType == eTransportType.Air">

              <div *ngIf="_leadQuotation.packAndLoad">
                Pack and Load: {{ _leadQuotation.packAndLoad }} <br />
              </div>
              <div *ngIf="_leadQuotation.dockTransfer">
                Dock Transfer: {{ _leadQuotation.dockTransfer }} <br />
              </div>
              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
              <div *ngIf="_leadQuotation.delivery">
                Delivery: {{ _leadQuotation.delivery }} <br />
              </div>
              <div *ngIf="_leadQuotation.returnEmptyUnit">
                Return of empty unit: {{ _leadQuotation.returnEmptyUnit }} <br />
              </div>
              <div *ngIf="_leadQuotation.importClearance">
                Import Clearance: {{ _leadQuotation.importClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.packOut">
                Pack Out: {{ _leadQuotation.packOut }} <br />
              </div>
              <div *ngIf="_leadQuotation.airPortTransfer">
                AirPort Transfer: {{ _leadQuotation.airPortTransfer }} <br />
              </div>
            </div>

            <div
              *ngIf="(_moveTransport.categoryType == eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType == eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType == eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType == eTransportType.Sea">

              <div *ngIf="_leadQuotation.packAndLoad">
                Pack and Load: {{ _leadQuotation.packAndLoad }} <br />
              </div>
              <div *ngIf="_leadQuotation.dockTransfer">
                Dock Transfer: {{ _leadQuotation.dockTransfer }} <br />
              </div>
              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
              <div *ngIf="_leadQuotation.delivery">
                Delivery: {{ _leadQuotation.delivery }} <br />
              </div>
              <div *ngIf="_leadQuotation.returnEmptyUnit">
                Return of empty unit: {{ _leadQuotation.returnEmptyUnit }} <br />
              </div>
              <div *ngIf="_leadQuotation.importClearance">
                Import Clearance: {{ _leadQuotation.importClearance }} <br />
              </div>
            </div>
            <div
              *ngIf="(_moveTransport.categoryType == eMoveCategoryType.DoorToDoorLocal ||  _moveTransport.categoryType == eMoveCategoryType.DoorToDoorImport || _moveTransport.categoryType == eMoveCategoryType.DoorToDoorExport) && _moveTransport.transportType == eTransportType.SeaLCL">

              <div *ngIf="_leadQuotation.packAndLoad">
                Pack and Load: {{ _leadQuotation.packAndLoad }} <br />
              </div>
              <div *ngIf="_leadQuotation.dockTransfer">
                Dock Transfer: {{ _leadQuotation.dockTransfer }} <br />
              </div>
              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
              <div *ngIf="_leadQuotation.delivery">
                Delivery: {{ _leadQuotation.delivery }} <br />
              </div>
              <div *ngIf="_leadQuotation.commodity">
                Commodity: {{ _leadQuotation.commodity }} <br />
              </div>
              <div *ngIf="_leadQuotation.destinationCustomClearance">
                Destination Custom Clearance: {{ _leadQuotation.destinationCustomClearance }} <br />
              </div>
            </div>

            <div
              *ngIf="_moveTransport.categoryType == eMoveCategoryType.DoorToPort && _moveTransport.transportType == eTransportType.SeaLCL">

              <div *ngIf="_leadQuotation.packAndLoad">
                Pack and Load: {{ _leadQuotation.packAndLoad }} <br />
              </div>
              <div *ngIf="_leadQuotation.dockTransfer">
                Dock Transfer: {{ _leadQuotation.dockTransfer }} <br />
              </div>
              <div *ngIf="_leadQuotation.exportClearance">
                Export Clearance: {{ _leadQuotation.exportClearance }} <br />
              </div>
              <div *ngIf="_leadQuotation.transitTime">
                Transit Time: {{ _leadQuotation.transitTime }} <br />
              </div>
              <div *ngIf="_leadQuotation.commodity">
                Commodity: {{ _leadQuotation.commodity }} <br />
              </div>
            </div>

          </ng-container>


        </div>
        <div class="mt-4">
          <h4>Accessorial Rates :</h4>
          <p>
            {{ _leadQuotation.accessorialRates ? _leadQuotation.accessorialRates : ' - ' }}
          </p>
        </div>
        <div class="mt-4">
          <h4>Inclusions*:</h4>
          <p>
            {{ _leadQuotation.inclusions ? _leadQuotation.inclusions : ' - ' }}
          </p>
        </div>
        <div class="mt-4">
          <h4>Exclusions*:</h4>
          <p>
            {{ _leadQuotation.exclusions ? _leadQuotation.exclusions : ' - ' }}
          </p>
        </div>
        <div class="mt-4">
          <h4>Insurance Liability:</h4>
          <p>
            {{ _leadQuotation.insuranceLiabilities ? _leadQuotation.insuranceLiabilities : ' - ' }}
          </p>
        </div>
        <!-- Terms and Conditions -->
        <div class="mt-4">
          <h4>Payment Terms</h4>
          <p>
            {{ _leadQuotation.termsAndConditions ? _leadQuotation.termsAndConditions : ' - ' }}
          </p>
        </div>
        <br />
        <div class="row mt-4 border">
          <br />
          <br />
          <br />
          <h3>QUOTATION ACCEPTANCE NOTE:</h3>
          <br />
          <br />
          <p class="text-center">I have read the above quotation and authorize <b>{{
              _companySetting.companyName }}</b>
            to proceed.</p>
          <br />
          <br />
          <div class="col-md-6 align-items-center">
            <p><strong>Signature:____________________________________</strong></p>
          </div>
          <div class="col-md-6 align-items-center">
            <p><strong>Date:____________________________________</strong></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>