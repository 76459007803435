<div class="container">
  <div class="forms-container">
    <div class="signin-signup">

      <form [formGroup]="registerForm" (ngSubmit)="onRegister()" class="sign-up-form">
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
          <img src="assets/images/movd/movd_logo.png" width="200" />
        </a>
        <a class="sidebar-brand nobleui-logo" style="font-size: 20px;">Sign<span>up</span></a>

        <div class="row">
          <div class="input-field required-input" id="first-name-field">
            <i class="fas fa-user"></i>
            <input type="text" class="form-control" id="firstname" formControlName="firstname"
              placeholder="First name" autocomplete="on" required>
            <!-- <div
        *ngIf="registerForm.get('firstname')?.invalid && (registerForm.get('firstname')?.dirty || registerForm.get('firstname')?.touched || submitted)">
        <div *ngIf="registerForm.get('firstname')?.errors?.['required']"
          class="error-message">First name is required</div>
      </div> -->
          </div>

          <div class="input-field not-required-input" id="last-name-field">
            <i class="fas fa-user"></i>
            <input type="text" class="form-control" id="lastname" formControlName="lastname"
              placeholder="Last name" autocomplete="on">
          </div>
        </div>

        <div class="row">
          <div class="input-field required-input" id="email-field">
            <i class="fas fa-envelope"></i>
            <input type="email" class="form-control" id="txtemail" formControlName="email"
              placeholder="Email" autocomplete="on"
              (blur)="checkEmailExists(registerForm.get('email')?.value)"
              (keyup)="checkEmailExists(registerForm.get('email')?.value)" required>
          </div>

          <div class="input-field not-required-input" id="phone-field">
            <i class="fas fa-phone"></i>
            <input type="tel" class="form-control" id="phoneNumber" formControlName="phoneNumber"
              placeholder="Phone Number" autocomplete="on">
          </div>
        </div>

        <div class="row">
          <div class="input-field not-required-input" id="organization-field">
            <i class="fas fa-building"></i>
            <input type="text" class="form-control" id="organization" formControlName="organization"
              placeholder="Organization" autocomplete="on">
          </div>

          <div class="input-field not-required-input" id="website-field">
            <i class="fas fa-globe"></i>
            <input type="url" class="form-control" id="website" formControlName="website"
              placeholder="Website" autocomplete="on">
          </div>
        </div>

        <div class="row text-right">
          <div class="input-field required-input">
            <i class="fas fa-lock"></i>
            <input type="password" class="form-control" id="txtpass" formControlName="password"
              placeholder="Password">

          </div>
        </div>
        <div *ngIf="emailExists == true" class="text-danger small mt-2">
          email already exists
        </div>
        <div *ngIf="!passwordMeetsRequirements(registerForm.get('password')?.value)"
          class="text-danger small mt-2">
          *Password must be at least 8 characters long and must contain one special character!
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="checkbox" id="twoFactorEnabled"
            formControlName="twoFactorEnabled">
          <label class="form-check-label" for="twoFactorEnabled">
            Two Factor Authentication.
          </label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input" type="checkbox" id="agreeTerms"
            formControlName="agreeTerms">
          <label class="form-check-label" for="agreeTerms">
            I agree to <a href="https://getmovd.com/terms-conditions" target="_blank">Terms &
              Conditions</a> and
            <a href="https://getmovd.com/privacy-policy/" target="_blank">Privacy Policy</a>.
          </label>
        </div>


        <button type="submit" class="btn btn-primary">Sign Up</button>
      </form>

    </div>
  </div>


  <div class="panels-container">
    <div class="panel left-panel">
      <!--<div class="content">
        <h3>One of Us ?</h3>
        <p>
          Using a thorough approach to understand the requirements of our partners, we build tailored solutions from the ground up.
        </p>
  <a (click)="redirectToLogin()" class="btn transparent" id="sign-in-btn">
    <span class="button-text">Sign In</span>
  </a>
      </div>-->
      <div class="content">
        <img src="assets/images/movd/movd00.svg" class="logo-image" alt="Techflo Logo" />
        <a (click)="redirectToLogin()" class="btn transparent" id="sign-in-btn">
          <span class="button-text">Sign In</span>
        </a>
      </div>

    </div>

  </div>

</div>