<div *ngIf="_userRole === 'SuperAdmin'">
  <app-super-admin-dashboard></app-super-admin-dashboard>
</div>

<div *ngIf="_userRole === 'CompanyAdmin'">
  <app-company-dashboard></app-company-dashboard>
</div>

<div *ngIf="_userRole === 'Survey Manager'">
  <app-survey-dashboard></app-survey-dashboard>
</div>

<div *ngIf="_userRole === 'Move/Operations Manager'">
  <app-move-manager-dashboard></app-move-manager-dashboard>
</div>

<div *ngIf="_userRole === 'Packing Manager'">
  <app-packing-manager-dashboard></app-packing-manager-dashboard>
</div>

<div *ngIf="_userRole === 'Sales Manager'">
  <app-sales-manager-dashboard></app-sales-manager-dashboard>
</div>