import { eAppraisalType } from "./appraisal.model";

export class DashboardStats {
  newLeads: number;
  leadsInProgress: number;
  wonLeads: number;
  lostLeads: number;
  leadToMoveConversionRate: number;
  local: number;
  import: number;
  export: number;
  totalMoves: number;
  newSurveys: number;
  inProgressSurveys: number;
  completedSurveys: number;
  newPackings: number;
  inProgressPackings: number;
  completedPackings: number;
  newDeliveries: number;
  inProgressDeliveries: number;
  completedDeliveries: number;
  dashboardCSATDTO: DashboardCSATsDTO[] | null;

  constructor() {
    this.newLeads = 0;
    this.leadsInProgress = 0;
    this.wonLeads = 0;
    this.lostLeads = 0;
    this.leadToMoveConversionRate = 0;
    this.local = 0;
    this.import = 0;
    this.export = 0;
    this.totalMoves = 0;
    this.newSurveys = 0;
    this.inProgressSurveys = 0;
    this.completedSurveys = 0;
    this.newPackings = 0;
    this.inProgressPackings = 0;
    this.completedPackings = 0;
    this.newDeliveries = 0;
    this.inProgressDeliveries = 0;
    this.completedDeliveries = 0;
    this.completedDeliveries = 0;
    this.dashboardCSATDTO = null;
  }
}

export class DashboardCSATsDTO {
  appraisalType: eAppraisalType;
  zeroCount: number; // '0s' - No or Dissatisfied
  oneCount: number; // '1s'
  twoCount: number; // '2s'
  threeCount: number; // '3s'
  fourCount: number; // '4s' - Yes or Excellent

  constructor() {
    this.appraisalType = eAppraisalType.Packing; // Replace null with a default value if needed
    this.zeroCount = 0;
    this.oneCount = 0;
    this.twoCount = 0;
    this.threeCount = 0;
    this.fourCount = 0;
  }
}
