<ng-template #quotation>
  <div class="col-md-10 mt-1">
    <label class="fw-bold">Inclusions</label>
    <textarea class="form-control quot-text-area" [(ngModel)]="_quotationSetting.inclusions"
      placeholder="Enter Inclusions" name="inclusions"></textarea>
  </div>
  <div class="col-md-10 mt-4">
    <label class="fw-bold">Exclusions</label>
    <textarea class="form-control quot-text-area" [(ngModel)]="_quotationSetting.exclusions"
      placeholder="Enter Exclusions" name="exclusions"></textarea>
  </div>
  <div class="col-md-10 mt-4">
    <label class="fw-bold">Insurance Liabilities</label>
    <textarea class="form-control quot-text-area" [(ngModel)]="_quotationSetting.insuranceLiabilities"
      placeholder="Enter Insurance Liabilities" name="insuranceLiabilities"></textarea>
  </div>
  <div class="col-md-10 mt-4">
    <label class="fw-bold">Payment Terms</label>
    <textarea class="form-control quot-text-area" placeholder="Enter payment terms"
      [(ngModel)]="_quotationSetting.paymentTerms" name="paymentTerms"></textarea>
  </div>

  <div class="col-md-10 mt-4">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveQuotationSettings()">
      <i class="mdi mdi-content-save"></i> &nbsp; Save</button>
  </div>
</ng-template>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-4" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1,enumCategoryType.DoorToPort, enumTransportType.Sea)" class="nav-tab sub-tabs">
      <span class="text"> DTP (SEA)</span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2,enumCategoryType.DoorToPort, enumTransportType.Air)" class="nav-tab sub-tabs">
      <span class="text"> DTP (AIR) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeTab(3,enumCategoryType.PortToDoor, enumTransportType.Air)" class="nav-tab sub-tabs">
      <span class="text"> PTD (AIR) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(4,enumCategoryType.PortToDoor, enumTransportType.Sea)" class="nav-tab sub-tabs">
      <span class="text"> PTD (SEA) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="5">
    <a ngbNavLink (click)="onChangeTab(5,enumCategoryType.DoorToDoorImport, enumTransportType.Air)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Import (AIR) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="6">
    <a ngbNavLink (click)="onChangeTab(6,enumCategoryType.DoorToDoorImport, enumTransportType.Sea)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Import (SEA) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="7">
    <a ngbNavLink (click)="onChangeTab(7,enumCategoryType.DoorToDoorImport, enumTransportType.SeaLCL)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Import (SEA LCL) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="8">
    <a ngbNavLink (click)="onChangeTab(8,enumCategoryType.DoorToDoorExport, enumTransportType.Air)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Export (AIR) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="9">
    <a ngbNavLink (click)="onChangeTab(9,enumCategoryType.DoorToDoorExport, enumTransportType.Sea)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Export (SEA) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="10">
    <a ngbNavLink (click)="onChangeTab(10,enumCategoryType.DoorToDoorExport, enumTransportType.SeaLCL)"
      class="nav-tab sub-tabs">
      <span class="text"> DTD Export (SEA LCL) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="11">
    <a ngbNavLink (click)="onChangeTab(11,enumCategoryType.DoorToPort, enumTransportType.SeaLCL)"
      class="nav-tab sub-tabs">
      <span class="text"> DTP (SEA LCL) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="12">
    <a ngbNavLink (click)="onChangeTab(12,enumCategoryType.PortToDoor, enumTransportType.SeaLCL)"
      class="nav-tab sub-tabs">
      <span class="text"> PTD (SEA LCL) </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="13">
    <a ngbNavLink (click)="onChangeTab(13,enumCategoryType.DoorToDoorLocal, enumTransportType.Land)"
      class="nav-tab sub-tabs">
      <span class="text"> Local </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="quotation"></ng-container>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>