<ng-template #addModuleModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addModuleModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp; Add Module
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_module.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_moduleTitle" class="col-form-label">Module Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_moduleName" id="_moduleTitle"
          autocomplete="on" placeholder="Enter Module name" required pattern="[A-Za-z\s]{1,15}"
          title="Module name between 1-15 characters." />
        <!-- <div *ngIf="!_moduleTitle || (_moduleTitle && _moduleTitle.length === 0)" class="error-message error"
             style="color: red">
          * required
        </div> -->
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm">
      <i class="mdi mdi-content-save"> </i> &nbsp; Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close()">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<div>
  <!-- <h4 class="text fw-bold mt-2">
    Give Permission to Roles
  </h4> -->
  <ng-container *ngIf="!_isDataLoaded">
    <span id="loader">
      <span class="spinner"></span>
    </span>
  </ng-container>

  <div *ngIf="_isDataLoaded && _rolesPermissions && _rolesPermissions.length === 0">
    <p>No data available. </p>
  </div>

  <div *ngIf="_isDataLoaded && _rolesPermissions && _rolesPermissions.length > 0">

    <div class="card rounded">
      <div class="card-header">
        <div class="row">
          <!-- <div class="col-md-12 text-end">
            <button type="button" class="btn btn-primary btn-sm" (click)="saveChanges()">Save
              Permissions</button>
          </div> -->
        </div>

        <div class="row mt-2" *ngIf="_rolesPermissions.length > 0">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">

                  <ngx-datatable class="bootstrap my-datatable" [rows]="_rolesPermissions"
                    [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                    [rowHeight]="40" [limit]="10" [scrollbarH]="true" [scrollbarV]="false">
                    <ngx-datatable-column class="datatable-row-left" name="Role" prop="roleName"
                      [frozenLeft]="true" [width]="200" [sortable]="false">
                      <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column *ngFor="let moduleName of _moduleNames"
                      [name]="moduleName" [sortable]="false">
                      <ng-template ngx-datatable-header-template let-column="column">
                        <div>
                          <div>
                            <span
                              class="datatable-header-cell-label draggable">{{column.name}}</span>
                          </div>
                          <div
                            style="display: flex; flex-direction: row; justify-content: space-between;">
                            <i *ngFor="let action of actions" [class]="actionIcons[action]"></i>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <div
                          style="display: flex; flex-direction: row; justify-content: space-between;">
                          <ng-container *ngFor="let permission of row.permissions">
                            <div *ngIf="getModuleName(permission.permissionName) === moduleName">
                              <input type="checkbox" class="form-check-input"
                                [checked]="permission.isSelected"
                                (change)="handleCheckboxChange(row.roleId, permission.permissionId, $event)"
                                [disabled]="row.roleName === 'CompanyAdmin'">
                              <i [class]="actionIcons[getAction(permission.permissionName)]"></i>
                            </div>
                          </ng-container>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>



                  <!-- <ngx-datatable class="bootstrap my-datatable" [rows]="_rolesPermissions"
                    [columnMode]="ColumnMode.force" [headerHeight]="50" [footerHeight]="50"
                    [rowHeight]="40" [limit]="10" [scrollbarH]="true" [scrollbarV]="false">
                    <ngx-datatable-column class="datatable-row-left" name="Role" prop="roleName"
                      [frozenLeft]="true" [width]="200" [sortable]="false">
                      <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column *ngFor="let moduleName of _moduleNames"
                      [name]="moduleName" [sortable]="false">
                      <ng-template ngx-datatable-header-template let-column="column">
                        <span class="datatable-header-cell-wrapper">
                          <span class="datatable-header-cell-label draggable">{{column.name}}</span>
                        </span>
                        <hr>
                        <div
                          style="display: flex; flex-direction: row; justify-content: space-between;">
                          <i *ngFor="let action of actions" [class]="actionIcons[action]"></i>
                        </div>
                      </ng-template>
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <div
                          style="display: flex; flex-direction: row; justify-content: space-between;">
                          <ng-container *ngFor="let permission of row.permissions">
                            <div *ngIf="getModuleName(permission.permissionName) === moduleName">
                              <input type="checkbox" class="form-check-input"
                                [checked]="permission.isSelected"
                                (change)="handleCheckboxChange(row.roleId, permission.permissionId, $event)">
                              <i [class]="actionIcons[getAction(permission.permissionName)]"></i>
                            </div>
                          </ng-container>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- <table class="role-table mt-4">
      <thead>
        <tr>
          <th class="role-column" rowspan="2">Roles</th>
          <ng-container *ngFor="let name of _moduleNames;">
            <th colspan="4">{{ name }}</th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let name of _moduleNames;">
            <ng-container *ngFor="let action of actions;">
              <th>
                <i [class]="actionIcons[action]"></i>
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let rolePermission of _rolesPermissions">
          <tr *ngIf="rolePermission.roleId !== 0 && rolePermission.roleName !== 'CompanyAdmin'">
            <td class="checkbox-column">{{ rolePermission.roleName }}</td>
            <ng-container *ngFor="let permission of rolePermission.permissions">
              <td class="checkbox-column">
                <input type="checkbox" class="form-check-input" [checked]="permission.isSelected"
                  (change)="handleCheckboxChange(rolePermission.roleId, permission.permissionId, $event)">
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table> -->



  </div>
</div>