export interface AuditLogResponse {
  logs: AuditLogModel[];
  totalRecords: number;
}


export class AuditLogModel {
  public id: number;
  public tableName: string;
  public action: string;
  public oldData: string;
  public newData: string;
  public creationDate: Date;
  public modificationDate: Date;
  public isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.tableName = '';
    this.action = '';
    this.oldData = '';
    this.newData = '';
    this.creationDate = new Date();
    this.modificationDate = new Date();
    this.isDeleted = false;
  }
}
