<!-- <ng-template #inventoryListTemplate>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
          <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo" width="150">
        </ng-container>
        <ng-template #defaultLogo>
          <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
        </ng-template>
      </div>

      <h1 class="text-center mt-3">Inventory List</h1>
      &nbsp;
      <h4> General Info</h4>
      <table class="table table-bordered mt-2" *ngIf="_packagingDetailDTO">
        <tbody>
          <tr>
            <td><label>Move ID</label></td>
            <td>#{{ _packagingDetailDTO?.moveDetailId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer ID</label></td>
            <td>#{{ _packagingDetailDTO?.customerId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer Name</label></td>
            <td>{{ _packagingDetailDTO?.customerName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Account Name</label></td>
            <td>{{ _packagingDetailDTO?.accountName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Move Type</label></td>
            <td>{{ enumMappingService.mapMoveType(_packagingDetailDTO?.moveType || 0) }}</td>
          </tr>
          <tr>
            <td><label>Move Category</label></td>
            <td>{{ enumMappingService.mapMoveCategoryType(_packagingDetailDTO?.moveCategory
              || 0) }}
            </td>
          </tr>
          <tr>
            <td><label>Transport Type</label></td>
            <td>{{ enumMappingService.mapTransportType(_packagingDetailDTO?.moveTransport ||
              0) }}
            </td>
          </tr>
          <tr>
            <td><label>Origin</label></td>
            <td>{{ _packagingDetailDTO?.origin || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Destination</label></td>
            <td>{{ _packagingDetailDTO?.destination || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>POE</label></td>
            <td>{{ _packagingDetailDTO?.portOfLoading || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Client Notes</label></td>
            <td>{{ _externalNotesInvent || ' - ' }}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4 class="text-center"> Inventory Items  </h4>
      <h5 class="mt-2"> Assemble/Disassemble Items </h5>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description of Item</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of _inventoryList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.description}}</td>
              <td>{{row.weight}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>Total Items: {{calculateTotal().totalItems}}</td>
            <td colspan="2">Total Weight: {{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>
      <h5 class="mt-2"> Crated Items  </h5>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description of Item</th>
            <th>Dimensions</th>
            <th>Cuft</th>
            <th>Weight ({{_weightUnit}})</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of _inventoryList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.description}}</td>
              <td>{{row.dimensions}}</td>
              <td>{{row.cuft}}</td>
              <td>{{row.weight}}</td>
              <td>{{row.remarks}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>Total Items: {{calculateTotal().totalItems}}</td>
            <td colspan="2">Total Weight: {{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>
      <h5 class="mt-2"> Electronic Items  </h5>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description of Item</th>
            <th>Make</th>
            <th>Model/Serial</th>
            <th>Weight ({{_weightUnit}})</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of _inventoryList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.description}}</td>
              <td>{{row.make}}</td>
              <td>{{row.model}}</td>
              <td>{{row.weight}}</td>
              <td>{{row.remarks}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>Total Items: {{calculateTotal().totalItems}}</td>
            <td colspan="2">Total Weight: {{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>
      <h5 class="mt-2"> Hight Value Items  </h5>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description of Item</th>
            <th>Dimensions</th>
            <th>Weight ({{_weightUnit}})</th>
            <th>Value in ({{_currency}})</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of _inventoryList; let index = index">
            <ng-container *ngIf="row.isDeleted===false">
              <td>{{row.itemNo}}</td>
              <td>{{row.description}}</td>
              <td>{{row.dimensions}}</td>
              <td>{{row.weight}}</td>
              <td>{{row.value}}</td>
            </ng-container>
          </tr>
          <tr>
            <td>Total Items: {{calculateTotal().totalItems}}</td>
            <td colspan="2">Total Weight: {{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</ng-template> -->

<ng-template #inventImgPreview let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Preview</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="_inventoryFile?.startsWith('data:image')">
      <img [src]="sanitizeDocumentUrl(_inventoryFile)" alt="Image" class="modal-image" width="100%" height="100%">
    </ng-container>
    <ng-container *ngIf="_inventoryFile?.startsWith('data:application')">
      <embed [src]="sanitizeDocumentUrl(_inventoryFile)" type="application/pdf" width="100%" height="600px">
    </ng-container>
  </div>
  <div class="modal-footer">
    <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf" (change)="handleInventFileInput($event)"
      #fileInput />
    <ng-container *ngIf="_createPermission">
      <button class="btn btn-primary" (click)="fileInput.click()">
        <i class="mdi mdi-upload"></i>&nbsp; Upload</button>
    </ng-container>
    <ng-container *ngIf="_deletePermission">
      <button class="btn btn-danger" (click)="removeInventFile()">
        <i class="mdi mdi-delete"></i> &nbsp; Remove</button>
    </ng-container>
  </div>
</ng-template>

<ng-template [ngIf]="showInventoryList">
</ng-template>


<div *ngIf="activeTab === 1">
  <div class="mt-2">
    <div class="row">
      <!-- <div class="col-md-12">
        <button type="button" class="btn btn-sm btn-primary float-end" (click)="generatePDF()">
          <i class="mdi mdi-file-export"></i> &nbsp; Export
        </button>
        &nbsp;
        <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf" (change)="handleInventFileInput($event)"
          #fileInput />
        <ng-container *ngIf="_createPermission==false">
          <button class="btn btn-sm btn-outline-primary float-end me-2" (click)="fileInput.click()">
            <i class="mdi mdi-upload"></i>&nbsp; {{ _packagingInventory.uploadedFile ? 'Uploaded' :
            'Upload' }}
          </button>
        </ng-container>
        <ng-container *ngIf="_readPermission">
          <label *ngIf="_packagingInventory.uploadedFile" class="float-end me-3 mt-2"><strong>
              Uploaded File: &nbsp;</strong><a class="clickable-link"
              (click)="openInventFileModal(inventImgPreview)"><span>Click to Open</span></a></label>
        </ng-container>
      </div> -->
      <div class="col-md-12">

        <ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-2" [(activeId)]="defaultNavActiveId">
          <li [ngbNavItem]="1">
            <a ngbNavLink (click)="onChangeTab(0)" class="nav-tab sub-tabs">
              <span class="text">All</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 mt-3">
                  <table class="custom-table-sm border border-gray">
                    <thead>
                      <tr>
                        <th width="10%">Item No #</th>
                        <th width="45%">Description Of Item</th>
                        <th width="10%">Weight ({{_weightUnit}})</th>
                        <th width="25%">Category</th>
                        <th width="10%" *ngIf="_inventoryList.length !== 0 && (_createPermission || _deletePermission)">
                        </th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr *ngIf="_inventoryList.length === 0">
                        <td colspan="5" class="text-center">No Items available</td>
                      </tr>
                      <tr *ngFor="let row of _inventoryList; let index = index">
                        <ng-container *ngIf="row.isDeleted === false">

                          <td>
                            <input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                              readonly>
                          </td>


                          <td><input class="form-control desc-form-control" type="text" [(ngModel)]="row.description">
                          </td>
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.weight">
                          </td>
                          <td>
                            <ng-select class="ng-select" [(ngModel)]="row.packagingType" bindValue="id"
                              bindLabel="value" [items]="_ePackingTypeOptions" placeholder="Select Item Category">
                            </ng-select>
                          </td>
                          <td class="actions-column"
                            *ngIf="_inventoryList.length !== 0 && (_createPermission || _deletePermission)">
                            <ng-container *ngIf="_deletePermission">
                              <button class="btn btn-lg feather icon-trash"
                                (click)="deleteInventoryItem(index,_inventoryList)"></button>
                            </ng-container>
                            <ng-container *ngIf="_createPermission || _updatePermission">
                              <button class="btn btn-lg feather icon-save"
                                (click)="saveInventoryItem(index,1)"></button>
                            </ng-container>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                  <div class="d-flex justify-content-center mt-2">
                    <ng-container *ngIf="_createPermission">
                      <button class="plus-button float-end mt-1" (click)="addInventoryItem()">
                        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                          colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink (click)="onChangeTab(ePackagingType.AssembleDisassemble);" class="nav-tab sub-tabs">
              <span class="text"> Assemble / Disassemble Items </span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 mt-3">
                  <table class="custom-table-sm border border-gray">
                    <thead>
                      <tr>
                        <th width="10%">Item No #</th>
                        <th width="50%">Description of Item </th>
                        <th width="10%"> Weight ({{_weightUnit}})</th>
                        <th width="10%"
                          *ngIf="_assembleDisassembleList.length !== 0 && (_createPermission || _deletePermission)">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="_assembleDisassembleList.length === 0">
                        <td colspan="4" class="text-center">No Items available</td>
                      </tr>
                      <tr *ngFor="let row of _assembleDisassembleList; let index = index">
                        <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                            readonly></td>
                        <td><input class="form-control desc-form-control" type="text" [(ngModel)]="row.description">
                        </td>
                        <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.weight"></td>
                        <td class="actions-column"
                          *ngIf="_assembleDisassembleList.length !== 0 && (_createPermission || _deletePermission)">
                          <ng-container *ngIf="_deletePermission">
                            <button class="btn btn-lg feather icon-trash"
                              (click)="deleteInventoryItem(index,_assembleDisassembleList)"></button>
                          </ng-container>
                          <ng-container *ngIf="_createPermission || _updatePermission">
                            <button class="btn btn-lg feather icon-save" (click)="saveInventoryItem(index,2)"></button>
                          </ng-container>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <div class="d-flex justify-content-center mt-2">
                    <ng-container *ngIf="_createPermission">
                      <button class="plus-button float-end mt-1" (click)="addInventoryItem()">
                        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                          colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink (click)="onChangeTab(ePackagingType.CratedItem)" class="nav-tab sub-tabs">
              <span class="text"> Crated Items </span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 mt-3">
                  <table class="custom-table-sm border border-gray">
                    <thead>
                      <tr class="custom-table-header">
                        <th width="10%">Item No #</th>
                        <th width="50%">Description of Item</th>
                        <th width="10%">Dimensions</th>
                        <th width="10%">Cuft</th>
                        <th width="10%">Weight</th>
                        <th width="10%">Remarks</th>
                        <th *ngIf="_cratedItemList.length !== 0 && (_createPermission || _deletePermission)">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="_cratedItemList.length === 0">
                        <td colspan="7" class="text-center">No Items available</td>
                      </tr>
                      <tr *ngFor="let row of _cratedItemList; let index = index">
                        <ng-container
                          *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.CratedItem)">
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                              readonly>
                          </td>
                          <td><input class="form-control desc-form-control" type="text" [(ngModel)]="row.description">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.dimensions">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.cuft"></td>
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.weight">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.remarks"></td>
                          <td class="actions-column"
                            *ngIf="_cratedItemList.length !== 0 && (_createPermission || _deletePermission)">
                            <ng-container *ngIf="_deletePermission">
                              <button class="btn btn-lg feather icon-trash"
                                (click)="deleteInventoryItem(index,_cratedItemList)"></button>
                            </ng-container>
                            <ng-container *ngIf="_createPermission">
                              <button class="btn btn-lg feather icon-save"
                                (click)="saveInventoryItem(index,3)"></button>
                            </ng-container>
                          </td>
                        </ng-container>
                      </tr>


                    </tbody>
                  </table>

                  <div class="d-flex justify-content-center mt-2">
                    <ng-container *ngIf="_createPermission">
                      <button class="plus-button float-end mt-1" (click)="addInventoryItem()">
                        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                          colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink (click)="onChangeTab(ePackagingType.Electronic)" class="nav-tab sub-tabs">
              <span class="text"> Electronic Items </span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 mt-3">
                  <table class="custom-table-sm border border-gray">
                    <thead>
                      <tr>
                        <th width="10%">Item No #</th>
                        <th width="30%">Description</th>
                        <th width="10%">Make</th>
                        <th width="20%">Model / Serial</th>
                        <th width="10%">Weight</th>
                        <th width="10%">Remarks</th>
                        <th *ngIf="_electronicList.length !== 0 && (_createPermission || _deletePermission)">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="_electronicList.length === 0">
                        <td colspan="7" class="text-center">No Items available</td>
                      </tr>
                      <tr *ngFor="let row of _electronicList; let index = index">
                        <ng-container
                          *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.Electronic)">
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                              readonly>
                          </td>
                          <td><input class="form-control desc-form-control" type="text" [(ngModel)]="row.description">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.make"></td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.model"></td>
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.weight">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.remarks"></td>
                          <td class="actions-column"
                            *ngIf="_electronicList.length !== 0 && (_createPermission || _deletePermission)">
                            <ng-container *ngIf="_deletePermission">
                              <button class="btn btn-lg feather icon-trash"
                                (click)="deleteInventoryItem(index,_electronicList)"></button>
                            </ng-container>
                            <ng-container *ngIf="_createPermission || _updatePermission">
                              <button class="btn btn-lg feather icon-save"
                                (click)="saveInventoryItem(index,4)"></button>
                            </ng-container>
                          </td>
                        </ng-container>
                      </tr>


                    </tbody>
                  </table>

                  <div class="d-flex justify-content-center mt-2">
                    <ng-container *ngIf="_createPermission">
                      <button class="plus-button float-end mt-1" (click)="addInventoryItem()">
                        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                          colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink (click)="onChangeTab(ePackagingType.HighValue)" class="nav-tab sub-tabs">
              <span class="text"> High Value Items</span>
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col-12 mt-3">
                  <table class="custom-table-sm border border-gray">
                    <thead>
                      <tr>
                        <th width="10%">Item No #</th>
                        <th width="40%">Description of Item</th>
                        <th width="10%">Dimensions</th>
                        <th width="10%">Weight</th>
                        <th width="10%">Value in ({{_currency}})</th>
                        <th width="10%" *ngIf="_highValueList.length !== 0 && (_createPermission || _deletePermission)">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="_highValueList.length === 0">
                        <td colspan="6" class="text-center">No Items available</td>
                      </tr>
                      <tr *ngFor="let row of _highValueList; let index = index">
                        <ng-container
                          *ngIf="row.isDeleted === false && (row.packagingType === ePackagingType.HighValue)">
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                              readonly>
                          </td>
                          <td><input class="form-control desc-form-control" type="text" [(ngModel)]="row.description">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.dimensions">
                          </td>
                          <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.weight">
                          </td>
                          <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.value"></td>
                          <td class="actions-column"
                            *ngIf="_highValueList.length !== 0 && (_createPermission || _deletePermission)">
                            <ng-container *ngIf="_deletePermission">
                              <button class="btn btn-lg feather icon-trash"
                                (click)="deleteInventoryItem(index,_highValueList)"></button>
                            </ng-container>
                            <ng-container *ngIf="_createPermission || _updatePermission">
                              <button class="btn btn-lg feather icon-save"
                                (click)="saveInventoryItem(index,5)"></button>
                            </ng-container>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>

                  <div class="d-flex justify-content-center mt-2">
                    <ng-container *ngIf="_createPermission">
                      <button class="plus-button float-end mt-1" (click)="addInventoryItem()">
                        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                          colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>

          </li>
          <div class="export-btn">
            <button type="button" class="btn btn-sm btn-primary float-end" (click)="generatePdf('Download')">
              <i class="mdi mdi-file-export"></i> &nbsp; Export
            </button>
            &nbsp;
            <button class="btn btn-sm btn-primary float-end me-2" (click)="generatePdf('View');">
              <i class="mdi mdi-file-eye"> </i> &nbsp; View
            </button>
            &nbsp;
            <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf" (change)="handleInventFileInput($event)"
              #fileInput />
            <ng-container *ngIf="_createPermission">
              <button class="btn btn-sm btn-outline-primary float-end me-2" (click)="fileInput.click()">
                <i class="mdi mdi-upload"></i>&nbsp; {{ _packagingInventory.uploadedFile ?
                'Uploaded' :
                'Upload' }}
              </button>
            </ng-container>
            <ng-container *ngIf="_readPermission">
              <label *ngIf="_packagingInventory.uploadedFile" class="float-end me-3 mt-2"><strong>
                  Uploaded File: &nbsp;</strong><a class="clickable-link"
                  (click)="openInventFileModal(inventImgPreview)"><span>Click to
                    Open</span></a></label>
            </ng-container>
          </div>
        </ul>

        <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>

      </div>

    </div>
    <div class="row">
      <!-- <div class="col-12 mt-3">
        <table class="custom-table-sm border border-gray">
          <thead>
            <tr>
              <th>Item #</th>
              <th>Description Of Article</th>
              <th>Weight ({{_weightUnit}})</th>
              <th>Category</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let row of inventoryList; let index = index">
              <ng-container *ngIf="row.isDeleted === false">
                <td><input class="form-control" type="number" [(ngModel)]="row.itemNo"></td>
                <td><input class="form-control" type="text" [(ngModel)]="row.description"></td>
                <td><input class="form-control" type="number" [(ngModel)]="row.weight"></td>
                <td>
                  <ng-select class="form-control" [(ngModel)]="row.packagingType" [bindValue]="'value'"
                    [bindLabel]="'label'">
                    <ng-option *ngFor="let packagingTypeOption of packagingTypeOptions"
                      [value]="packagingTypeOption.value">
                      {{ packagingTypeOption.label }}
                    </ng-option>
                  </ng-select>

                </td>
                <td class="actions-column">
                  <ng-container *ngIf="_deletePermission">
                    <button class="btn feather icon-trash" (click)="deleteInventoryItem(index)"></button>
                  </ng-container>
                  <ng-container *ngIf="_createPermission">
                    <button class="btn feather icon-save" (click)="saveInventoryItem(index)"></button>
                  </ng-container>
                </td>
              </ng-container>
            </tr>


          </tbody>
        </table>

        <div class="d-flex justify-content-center mt-2">
          <ng-container *ngIf="_createPermission">
            <button class="plus-button float-end mt-1" (click)="addInventoryItem()"> <lord-icon
                src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover" colors="primary:#000000,secondary:#ebe6ef">
              </lord-icon>
            </button>
          </ng-container>
        </div>
      </div> -->

      <div class="col-12 mt-5">
        <h6 class="heading"> &nbsp; BEFORE SIGNING, CHECK ITEMS CONDITION & DESCRIBE LOSS OR
          DAMAGE(IF ANY)</h6>
        <div class="internal-notes-box mt-2">
          <textarea class="form-control" rows="3" placeholder="External Notes"
            [(ngModel)]="_externalNotesInvent"></textarea>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="custom-margin">
              <div class="signature-box">
                <div class="row">
                  <div class="col-6">
                    <div><label>Customer Signature</label></div>
                    <canvas *ngIf="activeTab === 1" #customerSingatureCanvasInvent class="signature-pad"></canvas>
                  </div>
                  <div class="col-6">
                    <div><label>Suvery Manager Signature</label></div>
                    <canvas *ngIf="activeTab === 1" #surveyMgrSingatureCanvasInvent class="signature-pad"></canvas>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <button class="btn btn-danger btn-xs"
                      (click)="clearSignature('customer', 'inventory')">Clear</button>
                  </div>
                  <div class="col-md-6">
                    <button class="btn btn-danger btn-xs"
                      (click)="clearSignature('manager', 'inventory')">Clear</button>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <ng-container *ngIf="_createPermission">
                    <button class="btn btn-primary btn-sm float-end" (click)="saveInventSingature()">
                      <i class="mdi mdi-content-save"></i> &nbsp; Save Signature</button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <ng-container *ngIf="_createPermission">
              <button class="btn btn-primary btn-sm float-end" (click)="savePackagingInventory()">
                <i class="mdi mdi-content-save"></i> &nbsp; SUBMIT INVENTORY
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- <hr> -->
  <!-- <div #pdfContent class="hidden-pdf-content">
    <ng-container [ngTemplateOutlet]="inventoryListTemplate"></ng-container>
  </div> -->
</div>

<div *ngIf="activeTab === 3">

  <div class="mt-3">
    <h2>Packing Acknowledgment</h2>

    <ng-container *ngIf="_inventoryList && _inventoryList.length > 0; else noItems">
      <p>Click on the item number to acknowledge its presence. The button color indicates
        acknowledgment status.</p>

      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-wrap">
            <div *ngFor="let row of _displayInventoryList; let index = index" class="me-2 mb-2">
              <ng-container *ngIf="row.id && row.isDeleted === false">
                <div class="d-flex align-items-center">
                  <button [class]="'btn btn-sm ' + (row.packingBingoBit  ? 'btn-primary' : 'btn-secondary')"
                    (click)="updateBingo(row)">
                    {{ row.itemNo }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #noItems>
      <p>No items in the packing.</p>
    </ng-template>
  </div>

</div>