<h5>DOCUMENTS</h5>

<ng-template #addDocumentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addDocumentModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp; {{_title}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_document.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_documentTitle" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_documentTitle" id="_documentTitle" autocomplete="on"
          placeholder="Enter Document name" required pattern="[A-Za-z\s]{1,15}"
          title="Document name between 1-15 characters." (input)="validateItemExists()"
          (keypress)="validateItemExists()" />
        <div *ngIf="documentExists" style="color: red">Already exists</div>
        <div *ngIf="!_documentTitle || (_documentTitle && _documentTitle.length === 0)" class="error-message error"
          style="color: red">
          * required
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close()">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <button type="button" class="btn btn-primary btn-sm" (click)="saveAndCloseClientDoc(modal)"
      [disabled]="!_documentTitle">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
  </div>
</ng-template>
<ng-template #documentTemplate>
  <label class="text fw-bold mt-2" *ngIf="selectedTabType === _clientAttachment">
    CHOOSE THE DOCUMENT NAMES YOU WANT TO BE ADDED TO THE LIST OF CLIENT DOCUMENTS
  </label>

  <label class="text fw-bold mt-2" *ngIf="selectedTabType === _moveAttachment">
    CHOOSE THE DOCUMENT NAMES YOU WANT TO BE ADDED TO THE LIST OF MOVE DOCUMENTS
  </label>

  <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openClientAddDocModal()"
    style="margin-left: 3px; width: 160px">
    <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover" colors="primary:#000000,secondary:#ebe6ef"
      style="width: 20px; height: 20px">
    </lord-icon>
    &nbsp; Add New Document
  </button>

  <div class="card rounded mt-4" style="background-color: #6572ff1b">
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <ngx-datatable class="bootstrap" [rows]="dataTable" [columns]="displayedColumns" [loadingIndicator]="true"
                [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
                [rowHeight]="40" [scrollbarH]="true">

                <!-- Iterate through displayedColumns -->
                <ngx-datatable-column *ngFor="let col of displayedColumns" [name]="col.name" [prop]="col.prop"
                  [width]="col.prop === 'title' ? 120 : 30"> <!-- Adjust the width for 'title' column here -->
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                    <!-- Display title with hover preview -->
                    <div *ngIf="col.prop === 'title'">
                      <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                        (mouseleave)="onRowMouseLeave(row)">
                        <div [ngbPopover]="row.title" triggers="mouseenter:mouseleave" [placement]="'top'"
                          [container]="'body'">
                          {{ row.title }}
                        </div>
                        <button class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                          *ngIf="row.isHovered" (click)="ClientDocPreview(row.id)">
                          Preview
                        </button>
                      </div>
                    </div>

                    <!-- Handle other columns -->
                    <div *ngIf="col.prop !== 'title'">
                      <input *ngIf="col.prop === 'all'; else normalCheckbox" type="checkbox" class="form-check-input"
                        [checked]="row[col.prop]" (change)="onAllClickChange(row, col.prop)" />
                      <ng-template #normalCheckbox>
                        <input type="checkbox" class="form-check-input" [checked]="row[col.prop]"
                          (change)="onClickChange(row, col.prop)" />
                      </ng-template>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <!-- Action column -->
                <ngx-datatable-column name="Action">
                  <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                    <button type="button" class="btn btn-xs datatable-action-cell" [class.btn-success]="!row.isActive"
                      [class.btn-danger]="row.isActive" (click)="updateDocumentStatus(row, row.isActive)">
                      {{ !row.isActive ? "Activate" : "Deactivate" }}
                    </button>
                    &nbsp;
                    <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                      (click)="updateDocumentStatus(row, row.isActive, true)">
                      Delete
                    </button>
                  </ng-template>
                </ngx-datatable-column>

              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">

  <li [ngbNavItem]="1" (click)="onTabChange(_clientAttachment)">
    <a ngbNavLink class="nav-tab">
      <span class="text"> CLIENT DOCUMENTS </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="documentTemplate"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" (click)="onTabChange(_moveAttachment)">
    <a ngbNavLink class="nav-tab">
      <span class="text"> MOVE DOCUMENTS </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="documentTemplate"></ng-container>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>