import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AuthService } from '../../../../services/auth.service';
import { ContactService } from '../../../../services/contact.service';
import { SaleService } from '../../../../services/sale.service';
import { AppComponent } from '../../../app.component';
import { AddCompanyModel, PipeAttributeModel, PipeModel, RoleModel } from '../super-admin/super-admin.models';
import { CountryOptions } from '../../movd/models/address.model';
import { BreadcrumbService } from 'src/services/breadcrumb.service';

@Component({
  selector: 'app-company-admin',
  templateUrl: './company-admin.component.html',
  styleUrls: ['./company-admin.component.scss']
})
export class CompanyAdminComponent implements OnInit {


  _userEmail!: string | null;
  _existingUserEmail!: string | null;
  isRowHovered: boolean = false;
  _currentUserId: number = 0;
  _emailExists: boolean = false;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //for data table
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  tableData: any[] = []; // Array to hold the table data
  _tableDataList: any[] = []; // Array to hold the table data
  tableColumns: any[] = []; // Array to hold the table column headers
  ColumnMode = ColumnMode;

  //for adding company user
  @ViewChild('addCompanyModal') addCompanyModal: any
  _companyUser = new AddCompanyModel();
  _modalTitle = "Add User";
  _saveTitle = "Create";
  _modalFunction: any;
  _ngbModalRef: NgbModalRef | undefined;
  formSubmitted_user: boolean = false;
  formSubmitted_role: boolean = false;
  formSubmitted_leadst: boolean = false;
  formSubmitted_stattr: boolean = false;

  _roles: any[] = [];
  _roleId: any;
  _companyId: number = 0;
  _roleDisabled = false;
  _externalRole: string | null = null;

  searchTerm: string = '';
  // selectedRoleId: number | null = null;

  _addBtnTitle = 'Add User';

  closeResult: string = '';
  defaultNavActiveId = 1;

  //roles
  @ViewChild('addRoleModal') addRoleModal: any;
  _role = new RoleModel();
  searchRole: string = '';
  roleTableData: any[] = [];
  _roleDataList: any[] = [];
  roleTableColumns: any[] = [];

  //pipes
  @ViewChild('addPipeModal') addPipeModal: any;
  _pipe = new PipeModel();
  searchPipe: string = '';
  pipeTableData: any[] = [];
  _pipeDataList: any[] = [];
  pipeTableColumns: any[] = [];

  //pipe attribute
  @ViewChild('addPipeAttrbModal') addPipeAttrbModal: any;
  _pipeAttrb = new PipeAttributeModel();
  searchPipeAttrb: string = '';
  pipeAttrbTableData: any[] = [];
  _pipeAttrbDataList: any[] = [];
  pipeAttrbTableColumns: any[] = [];
  types: {
    id: number,
    value: string
  }[] = [
      { id: 1, value: "StringValue" },
      { id: 2, value: "NumericValue" },
      { id: 3, value: "DateValue" },
      { id: 4, value: "BooleanValue" }
    ];

  selectedTypeId: any;
  selectedRoleIds: string | null = null;
  selectedRoleId: number | null = null;
  roleIdsList: number[] = [];
  roleNamesList: string[] = [];

  // for address modification

  countries = CountryOptions;

  constructor(
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    private _authService: AuthService,
    private _contactService: ContactService,
    private _saleService: SaleService,
    private _router: Router,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {

    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Admin Settings', url: '/company-admin' }
    ]);

    const role = localStorage.getItem('role');
    if (role == 'CompanyAdmin') {
      const currentUserId = localStorage.getItem('currentUserId');
      this._currentUserId = Number(currentUserId);
      this._companyId = Number(localStorage.getItem('companyId'));

      //console.log("current user id", this._currentUserId);

      this._companyUser = new AddCompanyModel();

      //this.getRoles(this._currentUserId);
      this.getRoles(this._companyId);
      this.refreshTableData();
      // this.refreshPipeTableData();
      this.refreshRoleTableData();
      // this.refreshPipeAttrbTableData();
    }
    else {
      this._router.navigate(['/dashboard']);
    }
  }

  refreshTableData() {
    this._authService.getUsersOfCompany().subscribe(
      (data: any[]) => {
        console.log("company users data", data);
        this._tableDataList = data;
        const allUsers = data;

        if (allUsers.length > 0) {
          this.tableData = allUsers.filter(
            (item: { roleNames: string }) =>
              item.roleNames && // Check if roleNames is not null
              !item.roleNames.includes('SuperAdmin') &&
              !item.roleNames.includes('CompanyAdmin')
          );
          this.tableData.forEach((item: any) => {
            const roleIdList = item.roleIds.split(',').map((id: string) => parseInt(id.trim(), 10));
            const roleNameList = item.roleNames.split(',').map((name: string) => name.trim());
            this.roleIdsList = this.roleIdsList.concat(roleIdList);
            this.roleNamesList = this.roleNamesList.concat(roleNameList);
          });
          //console.log("filter users data", this.tableData);

          this.tableColumns = Object.keys(data[0])
            .filter((key) =>
              key === 'customerName' ||
              key === 'email' ||
              key === 'roleNames' ||
              key === 'phone'
            )
            .map((key) => {
              let header: string;

              switch (key) {
                case 'customerName':
                  header = 'Customer Name';
                  break;
                case 'email':
                  header = 'Email';
                  break;
                case 'roleNames':
                  header = 'Role Names';
                  break;
                case 'phone':
                  header = 'Phone';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }

              return { header };
            });

        }
      },
      (error: any) => {
        console.error("error getting users data", error);
      });
  }

  getRoles(companyId: any) {
    this._contactService.getRoles(companyId).subscribe(
      (data: any) => {
        // console.log("roles", data);
        let filteredData = data.filter(
          (item: { roleName: string }) =>
            item.roleName !== 'SuperAdmin' &&
            item.roleName !== 'CompanyAdmin');

        if (filteredData && filteredData.length > 0) {
          this._roles = filteredData;
        }
      });
  }

  openAddModal(template: TemplateRef<any>) {
    this._companyUser = new AddCompanyModel();
    this.formSubmitted_user = false;
    this._modalTitle = "Add User";
    this._saveTitle = "Create User";
    this._modalFunction = "add";

    this._userEmail = this._companyUser.email;
    this._companyUser = new AddCompanyModel();
    this._userEmail = null;
    this._emailExists = false;
    this.selectedRoleIds = null;
    this.selectedRoleId = null;
    this._ngbModalRef = this._modalService.open(this.addCompanyModal, { size: 'md', backdrop: 'static' });
  }
  openEditModal() {
    this._modalTitle = "Update User";
    this._saveTitle = "Update";
    this._modalFunction = "update";
    this._emailExists = false;
    this._ngbModalRef = this._modalService.open(this.addCompanyModal, { size: 'md', backdrop: 'static' });
  }

  closeModal() {
    this._existingUserEmail = null;
    this.selectedRoleIds = null;
    this.selectedRoleId = null;
    this._roleDisabled = false;
    this._externalRole = null;
    this._ngbModalRef?.close();
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  previewClick(row: any) {
    //console.log("row id", row);
    let rowId = row.id;
    this.getUserAndOpenModal(Number(rowId));
  }

  getUserAndOpenModal(id: number): void {
    this._companyUser = new AddCompanyModel();
    const data = this.tableData.filter(item => item.id === id)[0];
    // console.log("getUserAndOpenModal", data);
    this._companyUser = data;
    if (data && data.roleIds) {
      this._companyUser.companyName = data.customerName;
      if (typeof data.roleIds === 'string') {
        this._companyUser.roleIds = data.roleIds.split(',').map((roleId: string) => parseInt(roleId.trim(), 10));
        // console.log("data.roleIds", data.roleIds);
        this.selectedRoleId = data.roleIds[0];
      }
      else if (Array.isArray(data.roleIds)) {
        this._companyUser.roleIds = data.roleIds;
        this.selectedRoleId = data.roleIds[0];

      }
      // this.selectedRoleIds = this._companyUser.roleIds;
      // console.log("selectedRoleIds", this.selectedRoleIds);
    }

    if (data.roleNames && (data.roleNames.includes('Moving Account') || data.roleNames.includes('ThirdParty'))) {
      this._roleDisabled = true;
      this._externalRole = data.roleNames;
      // console.log("external role", this._externalRole);

    }

    this._userEmail = this._companyUser.email; // 
    this._existingUserEmail = this._companyUser.email; // 

    // to show currentUser in selected item
    // let filteredRole = this._roles.find((role: { value: any; }) => role.value === data.roleName);
    // if (filteredRole && filteredRole !== undefined) {
    //   //console.log("filtered data ", filteredRole);
    //   this.selectedRoleId = filteredRole.id;
    // }
    // console.log("filtered data ", filteredRole);

    this.openEditModal();
  }

  resetPassword(id: any) {
    const data = this.tableData.filter(item => item.id === id)[0];
    this._companyUser = data;

    this._companyUser.password = "9pCIA9i8UF/k/ZM3Sk/UpQ=="; //newpass

    //console.log("reset password ", this._companyUser);
    this._authService.register(this._companyUser).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal('reset password', '')
      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating user status:', error);
        this._appComponent.showErrorSwal('updating', '')
      }
    );
  }

  closePopup() {
    this._modalService.dismissAll('by: calling closeModal()');
    //this._popupVisible = false;

  }

  async updateUserStatus(row: any, status: any, softDelete = false) {
    const userId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this user',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._authService.updateUserStatus(userId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'user')
        this.refreshTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating user status:', error);
        this._appComponent.showErrorSwal('updating', 'user')
      }
    );
  }

  saveCompanyUser() {
    this.formSubmitted_user = true;

    if (this._emailExists || this._userEmail === null || this.selectedRoleId === null) {
      // if (this._emailExists || this._userEmail === null || this.selectedRoleIds === null) {
      // Handle the case when the email already exists in 'tableData'
      // You can show an error message or take appropriate action.
      // For example:
      // this.errorMessage = 'Email already exists.';
      return;
    }
    this._companyUser.email = this._userEmail;

    // this._companyUser.roleIds = this.selectedRoleIds;
    this._companyUser.roleIds = [this.selectedRoleId];

    // console.log("role ids", this._companyUser.roleIds);

    // console.log("save user", this._companyUser);
    if (this._companyUser.id == 0) {
      this._companyUser.createdBy = this._currentUserId;
      this._companyUser.companyId = this._companyId;

      this._authService.register(this._companyUser).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'user');
          this.refreshTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'user');
        }
      );
    }
    else {
      // // to assign multiple roles to a user
      // this._companyUser.roleIds = this.selectedRoleIds;

      // console.log('company userrrr', this._companyUser);
      this._authService.register(this._companyUser).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'user');
          this.refreshTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'user');
        }
      )
    }
  }

  performSearch() {
    // Check if the search term is empty
    if (this.searchTerm === '') {
      this.tableData = this._tableDataList;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._tableDataList.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.tableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  onChangeTab(tabNo: any) {
    //1 for users
    //2 for Roles
    //3 for pipes
    //console.log("tab No", tabNo);

    if (tabNo === 1) {
      this._addBtnTitle = 'Add User';
    } else if (tabNo === 2) {
      this._addBtnTitle = 'Add Role';
    } else if (tabNo === 3) {
      this._addBtnTitle = 'Add Status';
    } else if (tabNo === 4) {
      this._addBtnTitle = 'Add Attribute';
    } else {
      this._addBtnTitle = 'Add ';
    }

  }

  //pipes
  openAddPipe(template: TemplateRef<any>) {
    this._modalTitle = "Add Lead Status";
    this._saveTitle = "Create";
    this._modalFunction = "add";

    this._pipe = new PipeModel();

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openEditPipe() {
    this._modalTitle = "Lead Status";
    this._saveTitle = "Update";

    this._modalService.open(this.addPipeModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  savePipe() {
    this.formSubmitted_leadst = true;
    //console.log("save pipe", this._pipe);
    if (this._pipe.pipeName.trim().length === 0) {
      return;
    }

    if (this._pipe.id == 0) {
      this._pipe.createdBy = this._currentUserId;
      this._pipe.companyId = this._companyId;

      this._saleService.addPipe(this._pipe).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'status');
        }
      );
    }
    else {

      this._saleService.updatePipe(this._pipe).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'status');
        }
      )
    }
    this._pipe = new PipeModel();

    this.formSubmitted_leadst = false;
  }

  refreshPipeTableData() {
    this._saleService.getPipes(this._companyId).subscribe(
      (data: any) => {
        //console.log("company pipes data", data);
        if (data.length > 0) {
          this.pipeTableData = data;
          this._pipeDataList = data;
          this.pipeTableColumns = Object.keys(data[0])
            .filter((key) =>
              key !== 'id' &&
              key !== 'order' &&
              key !== 'isDeleted')

            .map((key) => {
              let header: string;
              let isCheckbox: boolean = false;

              if (key == 'pipeName') {
                header = 'Title';
              } else {
                header = key;
              }

              return { header };
            });
        }
      },
      (error: any) => {
        console.error("error getting lead status data", error);
      });

  }

  async updatePipeStatus(row: any, status: any, softDelete = false) {
    const pipeId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this status',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._saleService.updatePipeStatus(pipeId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'attribute')
        this.refreshPipeTableData();
        this.table?.recalculate();
        //this.table?.renderRows();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating attribute status:', error);
        this._appComponent.showErrorSwal('updating', 'attribute')
      }
    );
  }

  pipePreviewClick(id: number) {
    //console.log("pipe id", id);
    this.getPipeAndOpenModal(Number(id));
  }

  getPipeAndOpenModal(id: number): void {
    this._pipe = new PipeModel();
    const data = this.pipeTableData.filter(item => item.id === id)[0];
    this._pipe = data;
    //console.log("_pipe", this._pipe);

    this.openEditPipe();
  }
  performPipesSearch() {
    // Check if the search term is empty
    if (this.searchPipe === '') {
      this.pipeTableData = this._pipeDataList;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._pipeDataList.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchPipe.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.pipeTableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  // pipe attribute
  openAddPipeAttribute(template: TemplateRef<any>) {
    this._modalTitle = "Add Status Attribute";
    this._saveTitle = "Create";

    this._pipeAttrb = new PipeAttributeModel();
    this.selectedTypeId = "Selected Data Type";

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openEditPipeAttribute() {
    this._modalTitle = "Update Attribute";
    this._saveTitle = "Update";

    this._modalService.open(this.addPipeAttrbModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  savePipeAttribute() {
    this.formSubmitted_stattr = true;
    if (this._pipeAttrb.name.trim().length === 0) {
      return;
    }

    this._pipeAttrb.dataType = this.selectedTypeId;

    //console.log("save pipe attrb", this._pipeAttrb);

    if (this._pipeAttrb.id == 0) {
      this._pipeAttrb.createdBy = this._currentUserId;
      this._pipeAttrb.companyId = this._companyId;

      //console.log("save pipe attrb", this._pipeAttrb);
      this._saleService.addPipeAttribute(this._pipeAttrb).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'attribute');
          this.refreshPipeAttrbTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
          // if (this._pipeAttrb.name.trim().length === 0) {
          //   return;
          // }
        },

        error => {
          this._appComponent.showErrorSwal('saving', 'attribute');
        }
      );
    }
    else {
      //console.log("update pipe attrb", this._pipeAttrb);
      this._saleService.updatePipeAttribute(this._pipeAttrb).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'attribute');
          this.refreshPipeAttrbTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'attribute');
        }
      )
    }
    this._pipeAttrb = new PipeAttributeModel();


    this.formSubmitted_stattr = false;
  }

  refreshPipeAttrbTableData() {
    this._saleService.getPipeAttributes(this._companyId).subscribe(
      (data: any) => {
        //console.log("company pipe attrb data", data);
        if (data.length > 0) {
          this.pipeAttrbTableData = data;
          this._pipeAttrbDataList = data;
          this.pipeAttrbTableColumns = Object.keys(data[0])
            .filter((key) =>
              key !== 'id' &&
              key !== 'companyId' &&
              key !== 'creationDate' &&
              key !== 'deletionDate' &&
              key !== 'createdBy' &&
              key !== 'modificationDate' &&
              key !== 'modifiedBy' &&
              key !== 'isActive' &&
              key !== 'isDeleted')

            .map((key) => {
              let header: string;
              if (key == 'name') {
                header = 'Attribute Name';
              } else if (key == 'dataType') {
                header = 'Data Type';
              } else {
                header = key;
              }
              return { header };
            });
        }
      },
      (error: any) => {
        console.error("error getting pipe attr data", error);
      });

  }

  updatePipeAttrbStatus(row: any, status: any) {
    const pipeAttrbId = Number(row.id);
    let action: string;

    if (row.isDeleted) {
      status = false;
      action = 'activated';
    } else {
      status = true;
      action = 'deactivated';
    }
    //console.log("_pipeAttrb", pipeAttrbId);

    this._saleService.updatePipeAttributeStatus(pipeAttrbId, status).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'attribute')
        this.refreshPipeAttrbTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating role status:', error);
        this._appComponent.showErrorSwal('updating', 'not successful')
      }
    );
  }

  pipeAttrbPreviewClick(id: number) {
    //console.log("_pipeAttrb id", id);
    this.getPipeAttrbAndOpenModal(Number(id));
  }

  getPipeAttrbAndOpenModal(id: number): void {
    this._pipeAttrb = new PipeAttributeModel();
    let data = this.pipeAttrbTableData.filter(item => item.id === id)[0];
    this._pipeAttrb = data;
    if (data && data !== undefined) {
      const item = this.types.find((item: { value: any; }) => item.value === data.dataType);
      // Check if the stringValueObj is found, and if so, get the "id" property
      this.selectedTypeId = item ? item.value : null;
    }
    this.openEditPipeAttribute();
  }


  performPipeAttrbSearch() {
    // Check if the search term is empty
    if (this.searchPipeAttrb === '') {
      this.pipeAttrbTableData = this._pipeAttrbDataList;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._pipeAttrbDataList.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchPipeAttrb.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.pipeAttrbTableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  //roles
  openAddRole(template: TemplateRef<any>) {
    this._modalTitle = "Add Role";
    this._saveTitle = "Create";

    this._role = new RoleModel();

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  openEditRole() {
    this._modalTitle = "Update Role";
    this._saveTitle = "Update";

    this._modalService.open(this.addRoleModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }

  saveRole() {

    if (this._role.roleName && this._role.roleName.trim().length === 0) {
      return;
    }
    //console.log("save role", this._role);
    if (this._role.id == 0) {
      this._role.createdBy = this._currentUserId;
      this._role.companyId = this._companyId;

      this._contactService.addRole(this._role).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'role');
          this.refreshRoleTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'role');
        }
      );
    }
    else {
      this._contactService.updateRole(this._role).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'role');
          this.refreshRoleTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'role');
        }
      )
    }
    this._role = new RoleModel();

    this.formSubmitted_role = false;
  }

  refreshRoleTableData() {
    this._contactService.getRoles(this._companyId).subscribe(
      (data: any) => {
        // console.log("company role data", data);
        if (data.length > 0) {
          this._roleDataList = data;
          this.roleTableData = data.filter(
            (item: { roleName: string }) =>
              item.roleName !== 'SuperAdmin'
              && item.roleName !== 'CompanyAdmin');

          this.roleTableColumns = Object.keys(data[0])
            .filter((key) =>
              key !== 'id' &&
              key !== 'companyId' &&
              key !== 'creationDate' &&
              key !== 'deletionDate' &&
              key !== 'createdBy' &&
              key !== 'modificationDate' &&
              key !== 'modifiedBy' &&
              key !== 'isActive' &&
              key !== 'isDeleted')

            .map((key) => {
              let header: string;
              if (key == 'roleName') {
                header = 'Role Name';
              } else {
                header = key;
              }
              return { header };
            });
        }
      },
      (error: any) => {
        console.error("error getting role data", error);
      });

  }

  async updateRoleStatus(row: any, status: any, softDelete = false) {
    const roleId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }
    //console.log("_role", roleId);

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this role',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'delete';
      }
    }

    this._contactService.updateRoleStatus(roleId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'role');
        this.refreshRoleTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating role status:', error);
        this._appComponent.showErrorSwal('updating', 'role');
      }
    );
  }

  rolePreviewClick(id: number) {
    //console.log("role id", id);
    this.getRoleAndOpenModal(Number(id));
  }

  getRoleAndOpenModal(id: number): void {
    this._role = new RoleModel();
    const data = this.roleTableData.filter(item => item.id === id)[0];
    this._role = data;
    //console.log("_pipe", this._role);

    this.openEditRole();
  }


  performRolesSearch() {
    // Check if the search term is empty
    if (this.searchRole === '') {
      this.roleTableData = this._roleDataList;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._roleDataList.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchRole.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.roleTableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }


  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.key;
    const isNumeric = /[0-9]/.test(input);
    const currentPhone = this._companyUser.phone || '';

    // If it's not a valid input, prevent further changes
    if (!isNumeric && input !== '+') {
      event.preventDefault();
      return;
    }

    // Automatically prepend '+' if it's not already present and the input is a number
    if (currentPhone.length === 0 && isNumeric) {
      this._companyUser.phone = '+' + input;
      event.preventDefault();
      return;
    }

    // Prevent '+' sign if it's not at the beginning of the number
    if (input === '+' && currentPhone.length !== 0) {
      event.preventDefault();
      return;
    }





    const newValue = currentPhone + input;
    if (newValue.length > 15) { // Considering the maximum length as '+1 (555) 123-4567^123'

      event.preventDefault();
      return;
    }


    // this._companyUser.phone = newValue;
  }





  isValidPhoneNumber(): boolean {
    return /[0-9+^$]/.test(this._companyUser.phone);
  }


  // validateEmailExists(): void {
  //   const input = this._userEmail;

  //   if (!input || input.trim().length === 0 || !input.match(this.emailPattern)) {
  //     // Invalid or empty email format, reset 'emailExists' and return.
  //     this.emailExists = false;
  //     return;
  //   }

  //   // Check if the email exists in the tableData
  //   this.emailExists = this.tableData.some(user => user.email === input && user.email !== this._companyUser.email);

  //   if (!this.emailExists) {
  //     // If it doesn't exist in tableData, check with the API
  //     this.checkEmailExists(input);
  //   }
  // }


  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  isValidLength(): boolean {
    return this._companyUser.phone !== null && this._companyUser.phone !== undefined &&
      this._companyUser.phone.length >= 10 && this._companyUser.phone.length <= 15;
  }

  checkEmailExists(email: string | null) {
    if (!email) {
      // If the email is null or empty, reset the _emailExists flag
      this._emailExists = false;
      return;
    }

    this._authService.checkEmailExists(email).subscribe({
      next: (response: boolean) => {
        this._emailExists = response;
        // console.log("checking email exists or not", response);
      },
      error: (error: any) => {
        console.error("error validating email", error);
      }
    });
  }

  // send creds btn
  // on changing move category
  async sendCreds(user: any) {
    let text: string = "Please note that sending these will also activate the user. Please confirm your action.";
    let confirmButtonText: string = "Yes!";

    if (!user.isActive) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        text,
        confirmButtonText
      );

      if (await confirmed === true) {
        this.forgotpassword(user);
      } else {
        return;
      }
    }
    else {
      this.forgotpassword(user);
    }

  }

  forgotpassword(user: any) {
    if (user.email) {
      // console.log("email", this.email.toString()); // Log the email
      this._authService.forgotPassword(user.email.trim().toString(), false).subscribe({
        next: (response) => {
          // console.log("forgot password response", response);
          if (!user.isActive) {
            this.updateUserStatus(user, false);
          }
          this._appComponent.showSuccessSwal('email', 'sent');

        },
        error: (error) => {
          this._appComponent.showErrorSwal('Oops! The email authentication', '');
        }
      });
    } else {
      console.error("Email is not provided");
    }
  }

}
