<ng-container >
  <div class="body">
    <div class="receipent">
      <div class="col-md-12">
        <label class="form-label fw-bold">Recipients</label>
      </div>
      <input type="text" class="form-control" name="recipients" placeholder="Enter Recipients Emails" [(ngModel)]="_recipients">
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <label class="form-label fw-bold">Template Type</label>
      </div>
      <div class="col-md-12">
        <ng-select [(ngModel)]="templateType" name="title" required placeholder="Select Template Type"
          [value]="templateType" disabled>
          <!-- <ng-option *ngFor="let template of TemplateModal.templates"
            [value]="template.value">{{template.label}}</ng-option> -->
        </ng-select>
      </div>
    </div>
    <div class="row mt-4 mb-0">
      <div class="col-md-12">
        <label class="form-label fw-bold">Subject</label>
      </div>
      <div class="col-md-12">
        <input type="text" class="form-control" [(ngModel)]="_tempSubject" name="subject" placeholder="Enter Email Subject">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="form-label fw-bold">Body Text</label>
      </div>

      <div class="col-md-12">
        <quill-editor [(ngModel)]="_tempBody" [modules]="generalService.getEditorConfig" placeholder="Add Email Body Text Here"></quill-editor>

      </div>
    </div>
    <div class="float-end mt-3">
      <button type="button" class="btn btn-primary btn-sm" (click)="sendEmail()">
        <i class="mdi mdi-content-save"> </i> &nbsp; Send Email
      </button>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" >
      <i class="mdi mdi-content-save"> </i> &nbsp; Send
    </button>
    <button type="button" class="btn btn-danger btn-sm">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div> -->
</ng-container>
