<ng-template #addDealModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="addDealModalLabel"> <i class="mdi mdi-account-plus"> </i> &nbsp;
      {{_modalTitle}} </h5>
    <!---->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon'); closePopup();" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <input type="hidden" id="_deal.id" />
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.name" class="col-form-label">Deal Name</label>
          </div>
          <div class="col-md-9">
            <input type="text" class="form-control" [(ngModel)]="_dealName" id="_deal.dealName" autocomplete="on"
              placeholder="Enter Deal name" required pattern="[A-Za-z\s]{1,25}" title="Deal name should only contain letters and spaces, and be
                   between 1-25 characters long." maxlength="50" />

            <div *ngIf="!_dealName" class="error-message error" style="color: red;">* required</div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.contactId" class="col-form-label">Contact Name</label>
          </div>
          <div class="col-md-9">
            <ng-select [items]="_contacts" bindLabel="customerName" bindValue="id" [searchable]="true"
              [(ngModel)]="_deal.contactId" placeholder="Select Contact">
            </ng-select>
            <!-- Show required message if contactId is not selected -->
            <div *ngIf="!_deal.contactId" class="error-message error" style="color: red;">*
              required!</div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.pipeId" class="col-form-label">Deal Status</label>
          </div>
          <div class="col-md-9">
            <ng-select [items]="_pipesList" bindLabel="pipeName" bindValue="id" [searchable]="true"
              [(ngModel)]="_deal.pipeId" placeholder="Select Status">
            </ng-select>
            <div *ngIf="!_deal.pipeId || (_deal.pipeId && _deal.pipeId === 0)" class="error-message error"
              style="color: red;">
              * required
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.dealOwnerId" class="col-form-label">Deal Owner</label>
          </div>
          <div class="col-md-9">
            <ng-select [items]="_allContacts" bindLabel="customerName" bindValue="id" [searchable]="true"
              [(ngModel)]="_deal.dealOwnerId" placeholder="Select Deal Owner">
            </ng-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.amount" class="col-form-label">Amount</label>
          </div>
          <div class="col-md-9">
            <input type="text" class="form-control" id="_deal.amount" [(ngModel)]="_deal.amount"
              placeholder="Enter Amount" (keypress)="validateNumericField($event)" maxlength="15">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.probability" class="col-form-label">Probability</label>
          </div>
          <div class="col-md-9">
            <input type="text" class="form-control" id="_deal.probability" [(ngModel)]="_deal.probability"
              placeholder="Enter Probability" (keypress)="validateNumericField($event)" maxlength="15">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-3">
            <label for="_deal.lastContact" class="col-form-label">Last Contact</label>
          </div>
          <div class="col-md-9">
            <input type="date" class="form-control" id="_deal.lastContact" [(ngModel)]="_deal.lastContact"
              placeholder="Select Last Contact Date" [max]="getCurrentDate()">
          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="_updatePermission">
      <button type="button" class="btn btn-primary btn-sm" (click)="saveDeal();">
        <i class="mdi mdi-content-save"> </i> &nbsp;Save Deal
      </button>
    </ng-container>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-container *ngIf="_readPermission">


  <div class="col-md-5">
    <h5>SALES</h5>
    <!-- <nav class="page-breadcrumb">
      <ol class="breadcrumb">
       
        <li class="breadcrumb-item active" aria-current="sales"></li>
      </ol>
    </nav> -->

  </div>

  <form>
    <div class="row mt-3">
      <div class="col-xl-3 col-md-3  col-sm-12 col-xs-12 align-right">
        <input id="table-complete-search" type="text" class="form-control" name="searchTerm" placeholder="Search..."
          [(ngModel)]="_searchTerm" (keyup)="onSearch()" />

      </div>
      <div class="col-xl-9 col-md-9  col-sm-12 col-xs-12 mt-3">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-xs btn-primary btn-icon-text float-end" style="margin-left:2px"
            (click)="openAddDealModal(addDealModal);">
            <i class="mdi mdi-account-plus"> </i>
            &nbsp; Add Deal
          </button>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" [(ngModel)]="dynamicPipes" value="all" name="btnradio" id="pipe0"
            autocomplete="off" (change)="onPipeChange('all')">
          <label class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3 custom-label"
            for="pipe0">All</label>
          <ng-container *ngIf="_pipesList.length > 0">
            <ng-container *ngFor="let pipe of _pipesList; let i = index">
              <input type="radio" class="btn-check" [(ngModel)]="dynamicPipes" [value]="pipe.id" [name]="'btnradio'"
                [id]="'pipe' + (i+1)" autocomplete="off" (change)="onPipeChange(pipe.id)">
              <label class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3 custom-label"
                [for]="'pipe' + (i+1)">{{
                pipe.pipeName }}</label>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-datatable">
        <ngx-datatable [rows]="_displayedDeals" class="bootstrap custom-datatable" [columnMode]="columnMode"
          [summaryRow]="false" [footerHeight]="40" [limit]="10" [pagination]="true"
          [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true" [pageSize]="_pageSize">
          <ngx-datatable-column *ngFor="let column of tableColumns" [name]="column.header" class="thead">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <ng-container *ngIf="column.header === 'Name'">
                <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                  (mouseleave)="onRowMouseLeave(row)">

                  <div class="customer-name-wrapper " (click)="dealClick(row.id)">
                    <a class="customer-name dealView text-decoration-underline"
                      [ngClass]="{ 'preview-active': row.name.showPreview }" ngbPopover="{{row.name}}" triggers="hover"
                      placement="top" [attr.data-id]="row.id">
                      <span onmouseover="this.style.textDecoration='underline'"
                        onmouseout="this.style.textDecoration='none'">
                        {{ row.name }}
                      </span>
                    </a>
                  </div>
                  <button class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                    *ngIf="row.isHovered" (click)="previewClick(row.id)">
                    Preview
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="column.header !== 'Name' && column.header !== 'Last Contact'">
                {{value || ' - '}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Last Contact'">
                {{formatDateForTable(value)}}
              </ng-container>
            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>
      </div>
    </div>

    <!-- <div class="row" *ngIf="_displayedDeals.length === 0">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-center">
            <h6> No Deals, currently. Please Add a deal.</h6>
          </div>
        </div>
      </div>
    </div>
     -->
  </form>
</ng-container>