export class ConsignmentDetail {
  id: number;
  moveDetailId: number;
  shipperInfo: string | null;
  consigneeInfo: string | null;
  notifyInfo: string | null;
  vessels: string | null;
  voyageNo: string | null;
  portOfLoading: string | null;
  portOfDischarge: string | null;
  carrierReference: string | null;
  blNo: string | null;
  awbNo: string | null;
  exportReference: string | null;
  forwardingAgent: string | null;
  consigneeReference: string | null;
  placeOfReceipt: string | null;
  placeOfDelivery: string | null;
  specificationOfFreightCharges: string | null;
  freightPayment: string | null;
  issuingCarrierDetail: string | null;
  carrierIATACode: string | null;
  specialInstruction: string | null;
  receivedOn: string | null;
  deliveredOn: string | null;
  consignmentItems: ConsignmentDetailItem[];
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.moveDetailId = 0;
    this.shipperInfo = null;
    this.consigneeInfo = null;
    this.notifyInfo = null;
    this.vessels = null;
    this.voyageNo = null;
    this.portOfLoading = null;
    this.portOfDischarge = null;
    this.carrierReference = null;
    this.blNo = null;
    this.awbNo = null;
    this.exportReference = null;
    this.forwardingAgent = null;
    this.consigneeReference = null;
    this.placeOfReceipt = null;
    this.placeOfDelivery = null;
    this.specificationOfFreightCharges = null;
    this.freightPayment = null;
    this.issuingCarrierDetail = null;
    this.carrierIATACode = null;
    this.specialInstruction = null;
    this.receivedOn = null;
    this.deliveredOn = null;
    this.consignmentItems = [];
    this.isDeleted = false;
  }
}

export class ConsignmentDetailItem {
  id: number;
  consignmentDetailId: number;
  marksAndNo: string | null;
  noOfPackages: string | null;
  description: string | null;
  netWeight: string | null;
  grossWeight: string | null;
  volume: string | null;
  commodityItemNo: string | null;
  chargeableWeight: string | null;
  rateOrCharge: string | null;
  total: string | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.consignmentDetailId = 0;
    this.marksAndNo = null;
    this.noOfPackages = null;
    this.description = null;
    this.netWeight = null;
    this.grossWeight = null;
    this.volume = null;
    this.commodityItemNo = null;
    this.chargeableWeight = null;
    this.rateOrCharge = null;
    this.total = null;
    this.isDeleted = false;
  }
}


