<!-- Modal for Add Area -->
<ng-template #addAreaModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Area</h4>
  </div>
  <div class="modal-body">
    <label for="areaName fw-bold">Area Name</label>
    <input type="text" class="form-control" id="areaName" placeholder="Enter Area Name to Add"
      [(ngModel)]="_surveyPlace.title" />
    <div *ngIf="!_surveyPlace.title || (_surveyPlace.title && _surveyPlace.title.trim().length === 0)"
      class="error-message error" style="color: red">* required </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveArea(modal)">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.dismiss()">
      <i class="mdi mdi-close"></i>&nbsp;Close
    </button>
  </div>
</ng-template>

<!-- Modal for Add new Item -->

<ng-template #addItemModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Item</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <!-- <input type="hidden" value="_areaItems.id" /> -->
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Select Area</label>
      </div>
      <div class="col-md-8">
        <ng-select [bindValue]="'id'" [bindLabel]="'title'" [searchable]="true" [(ngModel)]="_surveyItem.surveyPlaceId"
          [items]="_surveyPlacesList" [placeholder]="'Please Select an area...'" [disabled]="_contentDisable">
        </ng-select>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Name</label>
      </div>
      <div class="col-md-8">
        <input type="text" class="form-control" [(ngModel)]="_surveyItem.title" autocomplete="on"
          placeholder="Enter Area Item name" required pattern="[A-Za-z\s]{1,15}" title="Area Item name should only contain letters and spaces, and be
                         between 1-15 characters long." [disabled]="_contentDisable" />
        <div *ngIf="
                  !_surveyItem.title ||
                  (_surveyItem.title && _surveyItem.title.length === 0)
                " class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Estimated Weight</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input type="number" class="form-control" [(ngModel)]="_surveyItem.weight"
            placeholder="Enter estimated weight" [disabled]="_contentDisable" />
          <span class="input-group-text">{{ _weightUnit }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Estimated Volume</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="_surveyItem.estimatedVolume"
            placeholder="Enter estimated Volume" [disabled]="_contentDisable" />
          <span class="input-group-text">{{ _volumeUnit }}</span>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-4">
        <label class="col-form-label">Dimension</label>
      </div>
      <div class="col-md-8">
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="_surveyItem.dimension" placeholder="Enter dimension"
            [disabled]="_contentDisable" />
          <span class="input-group-text">{{ _dimensionsUnit }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary btn-sm" (click)="saveItem(modal)">
      <i class="mdi mdi-content-save"> </i> &nbsp;Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.dismiss()">
      <i class="mdi mdi-close"></i>&nbsp;Close
    </button>
  </div>
</ng-template>

<!-- modal for add labour -->

<ng-template #addLabourModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Labour</h4>
  </div>
  <div class="modal-body">
    <div class="col-md-6">
      <label for="quantity"> Quantity</label>
      <input type="number" class="form-control form-control-sm" id="quantity" name="quantity" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">
      Close
    </button>
    <button type="button" class="btn btn-primary">Save</button>
  </div>
</ng-template>

<!-- modal for add vehicle
<ng-template #addVehicleModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Vehicle</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <label for="item"> Vehicle Name</label>
        <ng-select bindValue="title" [searchable]="true" [items]="_survey.items"
          [placeholder]="'Please Select an item...'">
        </ng-select>
      </div>
      <div class="col-md-6">
        <label for="quantity"> Quantity</label>
        <input type="number" class="form-control form-control-sm" id="quantity" name="quantity" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">
      Close
    </button>
    <button type="button" class="btn btn-primary">Save</button>
  </div>
</ng-template> -->

<!-- Image Modal Template -->
<ng-template #imagePreviewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{_imagePreviewTitle}}</h4>
  </div>
  <div class="modal-body">
    <img [src]="_imageToDisplay" alt="Image" class="modal-image">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Close</button>
  </div>
</ng-template>
<!-- Survey Form Code -->

<!-- For viewing/printing Invoice -->
<ng-template #basicModal let-modal>
  <div class="modal-dialog custom-modal-dialog">
    <!--<div class="modal-header">
      <h5 class="modal-title container d-flex align-items-center" id="exampleModalLabel">
        <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover"
                   colors="primary:#110a5c,secondary:#66a1ee" style="width:20px;height:20px">
        </lord-icon>
        &nbsp; Lead Qutation
      </h5>
    </div>-->

    <div class="modal-body">
      <!-- <app-survey-print-preview [moveDetailId]="moveDetailId"><> -->
      <!-- <app-survey-summary [leadDetailId]="_leadDetailId"></app-survey-summary> -->
    </div>
    <!--<div class="modal-footer">-->
    <!--<button type="button" class="btn btn-danger" (click)="closeViewModal();modal.close('by: close button')">
        Close
      </button>-->
    <!-- <i class="mdi mdi-close"> </i>&nbsp; -->
    <!--</div>-->
  </div>
</ng-template>

<!-- <div *ngIf="_isDataLoaded === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div> -->

<!-- <div *ngIf="_isDataLoaded === false"> -->
<div class="row title-and-mutliple-moves-btn">
  <div class="col-md-4">
    <h5>SURVEY FORM</h5>
  </div>
  <div class="col-md-8">
    <ng-container *ngFor="let lead of _leadBoardDTOList">
      <ng-container *ngIf="lead.leadId === _leadId">
        <button class="muliple-moves-btn float-end mt-1" (click)="showMoveById(lead)"
          [class.active]="lead.leadDetailId === _leadDetail.id">

          {{ enumMappingService.mapMoveType(lead.moveType || 0) }} -
          {{ enumMappingService.mapTransportType(lead.moveTransport || 0) }}
          <i *ngIf="lead.moveTransport === 1" class="mdi mdi-airplane-takeoff"></i>
          <i *ngIf="lead.moveTransport === 2" class="mdi mdi-truck-delivery"></i>
          <i *ngIf="lead.moveTransport === 3" class="mdi mdi-ferry"></i>
          <i *ngIf="lead.moveTransport === 4" class="mdi mdi-ferry"></i>
        </button>
        &nbsp;
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-12">
    <app-lead-info-header [type]="_typeForInfoHeader" [refId]="_leadDetail.id" [assignmentType]="_assignmentType"
      [assignmentRefId]="_leadDetail.id"></app-lead-info-header>
  </div>
  <div class="col-md-6">
    &nbsp; &nbsp;
    <!--<label for="item">Move Detail</label>-->
    <!-- <ng-select [bindValue]="'id'" bindLabel="id" [searchable]="true" [items]="_moveDetails"
      [placeholder]="'Please Select Move Detail...'" [(ngModel)]="_selectedMoveDetailId">
      <ng-template #optionTemplate let-item="item">
        {{ item.id }} 
      </ng-template>
    </ng-select> -->

    <div class="row">
      <label class="mt-3 fw-bold"> &nbsp;Area</label>
      <div class="col-md-9">
        <ng-select [bindValue]="'id'" [searchable]="true" [(ngModel)]="_selectedSurveyPlaceId"
          [items]="_surveyPlacesList" [placeholder]="'Please Select an area...'" [disabled]="_contentDisable"
          [bindLabel]="'title'" (ngModelChange)="onSurveyPlaceChange($event);">
        </ng-select>

      </div>
      <div class="col-md-3 text-end">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-primary btn-icon-text" (click)="openAreaModal(addAreaModal)"
            [disabled]="_contentDisable">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
            </lord-icon> &nbsp;Add Area
          </button>
        </ng-container>
      </div>
      <div class="col-md-9 mt-2">
        <div *ngIf="_dbSurveyItemsByPlace.length === 0 && !_selectedSurveyPlaceId">
          No Area Selected.
        </div>
        <div *ngIf="_dbSurveyItemsByPlace.length === 0 && _selectedSurveyPlaceId">
          No Items available for this area.
        </div>
        <div *ngIf="_dbSurveyItemsByPlace.length > 0" class="item-container">
          <div *ngFor="let item of _dbSurveyItemsByPlace" class="mb-2 me-1">
            <button class="btn btn-outline-primary btn-sm p-2" (click)="saveAndAddToTable(item)"
              [disabled]="_contentDisable">
              {{ item.title }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-2 text-end">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-primary btn-icon-text" (click)="openItemModal(addItemModal)"
            [disabled]="_contentDisable">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
            </lord-icon>
            &nbsp;Add Item
          </button>
        </ng-container>

      </div>
    </div>
    <table class="table table-bordered survey-tbl">
      <thead>
        <tr class="table-heading">
          <th colspan="4" class="white-text">Area Items</th>
        </tr>
        <tr>
          <th class="text-center article-column" style="width: 40%;">ARTICLE</th>
          <th class="text-center" style="width: 20%;">QUANTITY</th>
          <th class="text-center" style="width: 15%;">Volume ({{_volumeUnit}})</th>
          <th class="text-center" style="width: 15%;">Weight ({{_weightUnit}})</th>
          <th class="text-center" style="width: 15%;">Dimension ({{_dimensionsUnit}})</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of _areaItems; let i = index">
          <ng-container *ngIf="!item.isDeleted">
            <td>{{ item.item }} ({{ item.surveyPlace }})</td>
            <td class="text-center">
              <ng-container *ngIf="_createPermission">
                <button class="btn btn-sm btn-outline-primary" (click)="changeQuantity(i, 'increase')"
                  title="Change Quantity" [disabled]="_contentDisable">
                  <i class="fas fa-plus"></i>
                </button>
              </ng-container>
              {{ item.quantity }}
              <ng-container *ngIf="_createPermission">
                <button class="btn btn-sm btn-outline-primary" (click)="changeQuantity(i, 'decrease')"
                  title="Change Quantity" [disabled]="_contentDisable">
                  <i class="fas fa-minus"></i>
                </button>
              </ng-container>
            </td>
            <td class="text">
              <input type="number" class="form-control" [(ngModel)]="item.volume" [disabled]="_contentDisable" />
            </td>
            <td class="text">
              <input type="number" class="form-control" [(ngModel)]="item.weight" [disabled]="_contentDisable" />
            </td>
            <td class="text">
              <input type="text" class="form-control" [(ngModel)]="item.dimension" [disabled]="_contentDisable" />
            </td>

          </ng-container>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="_createPermission">
      <div class="picture-upload-box">
        <span class="upload-text">Upload Picture</span>
        <input type="file" id="pictureUpload" (change)="handlePictureUpload($event)" multiple accept="image/*"
          [disabled]="_contentDisable" />
        <label for="pictureUpload" class="upload-button">
          <i class="fas fa-plus"></i>
        </label>
      </div>
    </ng-container>
    <div *ngFor="let attachment of _attachments; let i = index">
      <ng-container *ngIf="attachment.isDeleted === false">
        <div class="selected-image-row">
          <div class="file-info">
            <ng-container *ngIf="_readPermission">
              <a (click)="openImageModal(imagePreviewModal, attachment.imageData, attachment.title)">
                <span class="file-name">{{attachment.title}}</span>
              </a>
            </ng-container>
          </div>
          <ng-container *ngIf="_deletePermission">
            <button class="remove-button" (click)="removeImage(i)">X</button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-md-6">
    <div class="row mt-4">
      <div class="col-md-12">
        <button type="button" class="btn btn-primary float-end" (click)="generatePdf('Download');">
          Export Survey &nbsp; <i class="mdi mdi-file-export"></i>
        </button>
        <!-- <i class="mdi mdi-file-pdf"> </i> &nbsp; -->
        <button class="btn btn-primary float-end export" (click)="generatePdf('View');">
          View Survey &nbsp; <i class="mdi mdi-file-eye"></i>
        </button>
      </div>
    </div>

    <!-- <div class="col-md-6">
        <button class="btn btn-primary float-end">
          Add Packing Material
        </button>
      </div>
      <div class="col-md-6">
        <button class="btn btn-primary float-end">
          Add Labor + Vehicle
        </button>
      </div> -->

    <div class="row">
      <div class="row mt-4">
        <div class="col-md-11 mt-3">
          <label class="label">PACKING MATERIALS</label>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row mt-2" *ngFor="let row of _materials; let first = first; let i = index">
        <ng-container *ngIf="!row.isDeleted">
          <div class="col-md-8">
            <label *ngIf="first">Item</label>
            <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-3">
            <label *ngIf="first">Quantity</label>
            <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="_contentDisable" />
          </div>
          <!-- <div class="col-md-4">
          <label *ngIf="first">Price</label>
          <input type="number" class="form-control" [(ngModel)]="row.price" />
        </div> -->

          <div class="col-md-1  d-flex align-items-center">
            <ng-container *ngIf="_createPermission && !_contentDisable">
              <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Material', _materials)"></button>
            </ng-container>
            <ng-container *ngIf="_deletePermission && !_contentDisable">
              <button class="btn feather icon-trash " *ngIf="!first" (click)="removeRow(i, _materials)"></button>
            </ng-container>
            <!-- <i class="btn btn-danger bt-sm pt-0" *ngIf="!first" (click)="removeRow(i)"></i> -->
          </div>
        </ng-container>
      </div>

      <!-- Labor + Vehicle -->
      <div class="row mt-4">
        <div class="col-md-11 mt-3">
          <label class="label">LABOUR</label>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let row of _labour; let first = first; let i = index">
        <ng-container *ngIf="!row.isDeleted">
          <div class="col-md-8">
            <label *ngIf="first">Detail</label>
            <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-3">
            <label *ngIf="first">Quantity</label>
            <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="_contentDisable" />
          </div>
          <!-- <div class="col-md-4">
          <label *ngIf="first">Price</label>
          <input type="number" class="form-control" [(ngModel)]="row.price" />
        </div> -->

          <div class="col-md-1  d-flex align-items-center">
            <ng-container *ngIf="_createPermission && !_contentDisable">
              <button class="btn feather icon-plus" *ngIf="first"
                (click)="addRow('Labour', _labour)"></button></ng-container>
            <ng-container *ngIf="_deletePermission  && !_contentDisable">
              <button class="btn feather icon-trash" *ngIf="!first"
                (click)="removeRow(i, _labour)"></button></ng-container>
            <!-- <button class="btn btn-primary bt-sm pt-0" *ngIf="first" (click)="addRow()"> +
          </button> -->
          </div>
        </ng-container>
      </div>

      <!-- Vehicles -->
      <div class="row mt-4">
        <div class="col-md-11 mt-3">
          <label class="label">VEHICLES</label>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let row of _vehicles; let first = first; let i = index">
        <ng-container *ngIf="!row.isDeleted">
          <div class="col-md-8">
            <label *ngIf="first">Item</label>
            <input type="text" class="form-control" [(ngModel)]="row.item" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-3">
            <label *ngIf="first">Quantity</label>
            <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="_contentDisable" />
          </div>
          <!-- <div class="col-md-4">
          <label *ngIf="first">Price</label>
          <input type="number" class="form-control" [(ngModel)]="row.price" />
        </div> -->

          <div class="col-md-1  d-flex align-items-center">
            <ng-container *ngIf="_createPermission && !_contentDisable">
              <button class="btn feather icon-plus" *ngIf="first"
                (click)="addRow('Vehicle', _vehicles)"></button></ng-container>
            <ng-container *ngIf="_deletePermission && !_contentDisable">
              <button class="btn feather icon-trash" *ngIf="!first"
                (click)="removeRow(i, _vehicles)"></button></ng-container>
          </div>
        </ng-container>
      </div>


      <!-- container size -->
      <div class="row mt-4">
        <div class="col-md-11 mt-3">
          <label class="label">Container Size</label>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let row of _containers; let first = first; let i = index">
        <ng-container *ngIf="!row.isDeleted">
          <div class="col-md-8">
            <label *ngIf="first">Size</label>
            <!-- <input type="text" class="form-control" [(ngModel)]="row.item" /> -->
            <ng-select [(ngModel)]="row.item" [items]="_containerSizes" placeholder="Select container size"
              [disabled]="_contentDisable">
            </ng-select>
          </div>

          <div class="col-md-3">
            <label *ngIf="first">Quantity</label>
            <input type="text" class="form-control" [(ngModel)]="row.quantity" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-1  d-flex align-items-center">
            <ng-container *ngIf="_createPermission && !_contentDisable">
              <button class="btn feather icon-plus" *ngIf="first" (click)="addRow('Container', _containers)"></button>
            </ng-container>
            <ng-container *ngIf="_deletePermission && !_contentDisable">
              <button class="btn feather icon-trash" *ngIf="!first" (click)="removeRow(i, _containers)"></button>
            </ng-container>
          </div>
        </ng-container>
      </div>

    </div>
  </div>


  <div class="col-md-12">
    <div class="internal-notes-box mt-6">
      <textarea class="form-control" rows="3" [(ngModel)]="_moveSurvey.internalNotes" placeholder="Internal Notes"
        [disabled]="_contentDisable"></textarea>
    </div>

    <div class="internal-notes-box">
      <textarea class="form-control" rows="3" [(ngModel)]="_moveSurvey.externalNotes" placeholder="External Notes"
        [disabled]="_contentDisable"></textarea>

    </div>
  </div>


  <div class="col-md-12">
    <div class="custom-margin">
      <div class="signature-box">
        <!-- Customer Signature -->
        <div class="row">
          <div class="col-6">
            <div><label>Customer Signature</label></div>
            <canvas #customerSingatureCanvas class="signature-pad"></canvas>


            <div class="row">
              <div class="col-12">
                <button class="btn btn-danger btn-xs" (click)="clearSingature('cust')">Clear</button>
              </div>
            </div>
          </div>
          <!-- Survey Manager Signature -->
          <div class="col-6">
            <div><label>Survey Manager Signature</label></div>
            <canvas #surveyMgrSingatureCanvas class="signature-pad"></canvas>


            <div class="row">
              <div class="col-12">
                <button class="btn btn-danger btn-xs" (click)="clearSingature('manager')">Clear</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <ng-container *ngIf="_createPermission || _updatePermission">
              <button class="btn btn-primary float-end" (click)="saveSingature()" [disabled]="_contentDisable">
                <i class="mdi mdi-content-save"></i> &nbsp; Save Signature
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <!-- <button class="btn btn-primary me-2 float-end" type="button">
        <i class="fas fa-plus"></i> ADD SIGNATURE
      </button> -->
        <ng-container *ngIf="_createPermission || _updatePermission">
          <button class="btn btn-primary float-end" (click)="saveMoveSurvey()" [disabled]="_contentDisable">
            <i class="mdi mdi-content-save"></i> &nbsp; SUBMIT INVENTORY
          </button>
        </ng-container>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</div>
<div #pdfComponent class="hidden-content">
  <app-survey-summary [leadDetailId]="_leadDetailId"></app-survey-summary>
  <!-- Other content -->
</div>
<!-- </div> -->