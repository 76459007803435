<ng-template #filePreviewModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{_filePreviewTitle}}</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="_fileToPreview?.startsWith('data:image')">
      <img [src]="sanitizeDocumentUrl(_fileToPreview)" alt="Image" class="modal-image" width="100%"
        height="100%">
    </ng-container>
    <ng-container *ngIf="_fileToPreview?.startsWith('data:application')">
      <embed [src]="sanitizeDocumentUrl(_fileToPreview)" type="application/pdf" width="100%"
        height="600px">
    </ng-container>
    <ng-container
      *ngIf="_fileToPreview?.startsWith('data:application/vnd.openxmlformats-officedocument')">
      <iframe [src]="sanitizeOfficeUrl(_fileToPreview)" width="100%" height="600px"></iframe>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #addDocumentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addDocumentModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{_documentModaltitle}}
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_document.id" />
      </div>
    </div>
    <div class="row mb-3">
      <!-- Name Field -->
      <div class="col-md-3">
        <label for="_documentTitle" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" id="_documentTitle" autocomplete="on"
          [(ngModel)]="_documentTitle" placeholder="Enter Document name" required
          pattern="[A-Za-z\s]{1,15}" title="Document name between 1-15 characters."
          (keypress)="validateTitleExists()" (input)="validateTitleExists()"
          (blur)="validateTitleExists()" (keyup.enter)="validateTitleExists()" />
        <div *ngIf="_titleExists" style="color: red">Already exists</div>
        <div *ngIf="!_documentTitle || (_documentTitle && _documentTitle.length === 0)"
          class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Due Date Field -->
      <div class="col-md-3">
        <label for="_dueDate" class="col-form-label">Due Date</label>
      </div>
      <div class="col-md-9">
        <input type="date" class="form-control" id="_dueDate" autocomplete="off"
          [(ngModel)]="_dueDate" placeholder="Enter Due Date" required />
        <div *ngIf="!_dueDate" class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <!-- [disabled]="!_dueDate || !_documentTitle || _documentTitle === ''"> -->
    <button type="button" class="btn btn-primary btn-sm"
      (click)="saveDocuments('single', _document)"
      [disabled]="isButtonDisabled() || contentDisabled">
      <i class="mdi mdi-content-save"></i>&nbsp;{{_saveDocTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeDocumentModal(modal)">
      <i class="mdi mdi-close"></i>&nbsp;Close
    </button>
  </div>

</ng-template>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Document</h4>
  </div>
  <div class="modal-body">
    <!-- <div id="loader" *ngIf="isImageLoading">
    </div> (load)="stopLoading()"-->
    <ng-container *ngIf="_fileToPreview?.startsWith('data:image')">
      <img [src]="sanitizeDocumentUrl(_fileToPreview)" alt="Image" class="modal-image" width="100%"
        height="100%">
    </ng-container>
    <ng-container *ngIf="_fileToPreview?.startsWith('data:application')">
      <embed [src]="sanitizeDocumentUrl(_fileToPreview)" type="application/pdf" width="100%"
        height="600px">
    </ng-container>
    <ng-container
      *ngIf="_fileToPreview?.startsWith('data:application/vnd.openxmlformats-officedocument')">
      <iframe [src]="sanitizeOfficeUrl(_fileToPreview)" width="100%" height="600px"></iframe>
    </ng-container>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="_createPermission">
      <button class="btn btn-primary btn-sm" (click)="resetProgress(); fileInput.click()">
        Update Document
        <input type="file" #fileInput (change)="onFileSelected($event, _selectedFile)"
          accept=".jpg,.jpeg,.png,.pdf" style="display: none;" [disabled]="contentDisabled" />
      </button>
    </ng-container>
    <ng-container *ngIf="_deletePermission">
      <button class="btn btn-danger btn-sm" (click)="removeDocument(_selectedFile);">
        Remove </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #generateTemplateModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="addRoleModalLabel">
      <i class="mdi mdi-email-outline"></i>   {{_title}}
    </h5>
    <button type="button" class="btn-close" label="Close"
      (click)="closeModal('Temp',modal)"></button>
  </div>

  <div class="modal-body"><app-generate-email [emailType]="eEmailType.Move" [refId]="moveDetailId"
      [templateType]="templateType" [dictionary]="_dictionary"
      [pdfData]="pdfData" (emailSent)="closeModal('Temp',modal)"></app-generate-email></div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" >
      <i class="mdi mdi-content-save"> </i> &nbsp; Send
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal('Temp', modal)">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div> -->
</ng-template>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs nav" role="tablist"
  [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink class="sub-tabs">CHECKLIST</a>
    <ng-template ngbNavContent>
      <div class="button-row">
        <ng-container *ngIf="documentType === eAttachmentType.ClientDocuments">
          <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openTempModal()"
            [disabled]="contentDisabled" style="margin-left: 3px; width: 135px">
            <i class="mdi mdi-email-outline" style="width: 20px; height: 20px"></i>
              Generate Email
          </button>
        </ng-container>
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-xs btn-primary btn-icon-text float-end" [disabled]="contentDisabled"
            (click)="openAddDocumentModal()" style="margin-left: 3px; width: 135px">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
            </lord-icon>
              Add Document
          </button>
        </ng-container>
      </div>
      <p *ngIf="_combinedList.length > 0">PLEASE SELECT THE DOCUMENTS NEEDED FOR THIS MOVE</p>
      <div class="mt-4">
        <ng-container *ngIf="_combinedList.length === 0">
          <div class="text-center">
            No files have been added as required docs by the admin.
          </div>
        </ng-container>

        <ng-container *ngIf="_combinedList.length > 0">
          <table class="table table-bordered table-striped table-responsive">
            <thead>
              <tr>
                <th style="width: 7%;"></th>
                <th style="width: 70%;">File Name</th>
                <th style="width: 12%;">Due Date</th>
                <th style="width: 6%;"></th>
                <th style="width: 6%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of _combinedList" class="mt-3">
                <td class="text-center">
                  <input type="checkbox" class="form-check-input"
                    [checked]="isInMoveAttachmentList(item) && item.isDeleted === false"
                    (change)="onCheckboxChange($event, item)"
                    [disabled]="_createPermission === false || contentDisabled">
                </td>
                <td>{{ item.title }}</td>
                <td class="text-center"><span *ngIf="item.dueDate">
                    {{ item.dueDate | date: 'mediumDate' }}</span>
                </td>

                <td class="text-center">
                  <ng-container *ngIf="isInMoveAttachmentList(item)">
                    <a class="action-icons"><i *ngIf="_updatePermission"
                        class="feather icon-edit icon-md"
                        (click)="openEditDocumentModal(item)"></i></a>
                  </ng-container>
                </td>
                <td class="text-center">
                  <ng-container *ngIf="isInMoveAttachmentList(item)">
                    <a class="action-icons"><i *ngIf="_createPermission"
                        class="feather icon-trash icon-md" (click)="deleteDocument(item)"></i></a>
                  </ng-container>
                </td>
              </tr>
            </tbody>

          </table>
        </ng-container>
      </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="2">
    <a ngbNavLink class="sub-tabs">UPLOADED</a>
    <ng-template ngbNavContent>
      <table class="table table-bordered table-striped table-responsive">
        <thead>
          <tr>
            <th style="width: 7%;  "></th>
            <th style="width: 40%;  ">File Name</th>
            <th style="width: 10%;  ">Uploaded on</th>
            <th style="width: 10%;  ">Due Date</th>
            <th style="width: 15%;  "></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
            </td>
            <td><span>Survey</span></td>
            <td></td>
            <td></td>
            <td class="text-center">
              <!-- <a class="action-icons"></a>
              <a class="action-icons"></a> -->
              <a class="action-icons" (click)="generateSurveyPdf('View')">
                <i class="feather icon-eye icon-md"></i>
              </a>
              <a class="action-icons" (click)="generateSurveyPdf('Download')">
                <i class="feather icon-download icon-md"></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>
            </td>
            <td><span>Packing Inventory</span></td>
            <td></td>
            <td></td>
            <td class="text-center">
              <!-- <a class="action-icons"></a>
              <a class="action-icons"></a> -->
              <a class="action-icons" (click)="generatePackingPdf('View')">
                <i class="feather icon-eye icon-md"></i>
              </a>
              <a class="action-icons" (click)="generatePackingPdf('Download')">
                <i class="feather icon-download icon-md"></i>
              </a>
            </td>
          </tr>
          <ng-container *ngIf="_moveAttachmentList.length === 0">
            <tr>
              <td colspan="5" class="text-center">
                No documents have been added by the administrator at this time.
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="_moveAttachmentList.length > 0">
            <tr *ngFor="let item of _moveAttachmentList">
              <ng-container *ngIf="!item.isDeleted">
                <td class="text-center"><input type="checkbox" class="form-check-input"
                    [checked]="isUploaded(item)" [disabled]="true">
                </td>
                <td>
                  <ng-container *ngIf="item.imageData; else noLink">
                    <a (click)="openPreviewModal(filePreviewModal, item)">{{ item.title }}</a>
                  </ng-container>
                  <ng-template #noLink>{{ item.title }}</ng-template>
                </td>
                <td>{{ item.imageData ? generalService.formatDate(item.modificationDate, null) :
                  ' - ' }}</td>
                <td><span *ngIf="item.dueDate">{{ item.dueDate | date: 'mediumDate'
                    }}</span> </td>
                <td class="text-center">
                  <ng-container *ngIf="_createPermission">
                    <a class="action-icons" (click)="resetProgress(); fileInput.click()">
                      <input type="file" #fileInput (change)="onFileSelected($event, item)"
                        accept=".jpg,.jpeg,.png,.pdf" style="display: none;" />
                      <i class="feather icon-upload icon-md"></i></a>
                  </ng-container>
                  <ng-container *ngIf="item.imageData; else noActions">
                    <a class="action-icons" (click)="openEditModal(editModal, item)"><i
                        class="feather icon-edit icon-md"></i></a>
                    <a class="action-icons" (click)="downloadFile(item)"><i
                        class="feather icon-download icon-md"></i></a>
                  </ng-container>
                  <ng-template #noActions>
                    <span class="action-icons"><i class="feather icon-edit icon-md"></i></span>
                    <span class="action-icons"><i class="feather icon-download icon-md"></i></span>
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div class="simple-rectangle mt-3">
        <strong><label>UPLOAD FILES</label></strong>
        <div class="dashes-rectangle mt-3">
          <div class="progress">
            <div class="progress-bar" role="progressbar" [style.width]="_progress + '%'"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>



<!-- <button class="btn btn-danger btn-sm">Remove</button> -->

<!-- <span>{{_uploadedFiles}} files uploaded, {{_remainingFiles}} files remaining</span> -->
<!-- ,.docx,.doc -->

<!-- <div class="mt-2 text-end">
        <button class="btn btn-primary" (click)="saveDocuments('list')">
          UPLOAD FILES
        </button>
      </div> -->