<ng-template #moveSummaryTemplate>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">
                <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
                    <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo" width="150">
                </ng-container>
                <ng-template #defaultLogo>
                    <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
                </ng-template>
            </div>

            <h1 class="text-center mt-3">Move Summary</h1>
            &nbsp;
            <h4> General Info</h4>
            <table class="table table-bordered mt-2" *ngIf="_moveBoardItem">
                <tbody>
                    <tr>
                        <td><label>Move ID</label></td>
                        <td>#{{ _moveBoardItem?.moveDetailId || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Customer ID</label></td>
                        <td>#{{ _moveBoardItem?.customerId || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Customer Name</label></td>
                        <td>{{ _moveBoardItem?.customerName || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Account Name</label></td>
                        <td>{{ _moveBoardItem?.accountName || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Move Type</label></td>
                        <td>{{ enumMappingService.mapMoveType(_moveBoardItem?.moveType || 0) }}</td>
                    </tr>
                    <tr>
                        <td><label>Move Category</label></td>
                        <td>{{ enumMappingService.mapMoveCategoryType(_moveBoardItem?.moveCategory
                            || 0) }}
                        </td>
                    </tr>
                    <tr>
                        <td><label>Transport Type</label></td>
                        <td>{{ enumMappingService.mapTransportType(_moveBoardItem?.moveTransport ||
                            0) }}
                        </td>
                    </tr>
                    <tr>
                        <td><label>POL</label></td>
                        <td>{{ _moveBoardItem?.portOfLoading || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>POD</label></td>
                        <td>{{ _moveBoardItem?.portOfDischarge || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Survey Date</label></td>
                        <td>{{ _moveBoardItem?.surveyDate || ' - '}}</td>
                    </tr>
                    <tr>
                        <td><label>Packing Date</label></td>
                        <td>{{ _moveBoardItem?.packagingDate || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Loading Date</label></td>
                        <td>{{ _moveBoardItem?.loadingDate || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Client Notes</label></td>
                        <td>{{ _moveDetail.additionalNotes || ' - ' }}</td>
                    </tr>
                </tbody>
            </table>

            &nbsp;
            <h4>Address Details</h4>
            <table class="table table-bordered mt-2" *ngIf="_moveBoardItem">
                <tbody>
                    <ng-container *ngFor="let i of [0, 1, 2]">
                        <ng-container *ngIf="_originAddresses[i] || _destinationAddresses[i]">
                            <tr *ngIf="i !==0 ">
                                <td colspan="4"><strong>{{ i === 0 ? 'First' : i === 1 ? 'Second' :
                                        'Third' }}
                                        Address</strong></td>
                            </tr>
                            <tr>
                                <td><label><strong>Origin</strong></label></td>
                                <td></td>
                                <td><label><strong>Destination</strong></label></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><label>Steet</label></td>
                                <td>{{ _originAddresses[i].streetAddress || '-' }}</td>
                                <td><label>Steet</label></td>
                                <td>{{ _destinationAddresses[i].streetAddress || '-' }}</td>
                            </tr>
                            <tr>
                                <td><label>City</label></td>
                                <td>{{ _originAddresses[i].city || '-' }}</td>
                                <td><label>City</label></td>
                                <td>{{ _destinationAddresses[i].city || '-' }}</td>
                            </tr>
                            <tr>
                                <td><label>State</label></td>
                                <td>{{ _originAddresses[i].state || '-' }}</td>
                                <td><label>State</label></td>
                                <td>{{ _destinationAddresses[i].state || '-' }}</td>
                            </tr>
                            <tr>
                                <td><label>Country</label></td>
                                <td>{{ _originAddresses[i].country || '-' }}</td>
                                <td><label>Country</label></td>
                                <td>{{ _destinationAddresses[i].country || '-' }}</td>
                            </tr>
                            <tr>
                                <td><label>Postal Code</label></td>
                                <td>{{ _originAddresses[i].zipCode || '-' }}</td>
                                <td><label>Postal Code</label></td>
                                <td>{{ _destinationAddresses[i].zipCode || '-' }}</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>

            &nbsp;
            <h4> Shipment</h4>
            <table class="table table-bordered mt-2">
                <thead>
                    <tr>
                        <th>Shipment</th>
                        <th>Volume ({{_volumeUnit}})</th>
                        <th>Weight ({{_weightUnit}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ enumMappingService.mapTransportType(_moveBoardItem?.moveTransport ||
                            0) }}
                        </td>
                        <td>{{calculateVolume('', _volumeUnit, 'total')}}</td>
                        <td>{{calculateWeight('', _weightUnit, 'total')}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</ng-template>

<ng-template #completeMoveModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Complete Move (s)</h4>
    </div>
    <div class="modal-body">
        <h6>Please select the Move(s) you would like to complete:</h6>

        <div class="row" *ngIf="getActiveMovesCount() > 1">
            <div class="col-1">
                <input type="checkbox" [(ngModel)]="_selectAllMoves" (change)="onSelectAllChange()"
                    class="form-check-input" />
            </div>
            <div class="col">
                Select All
            </div>
        </div>

        <div class="mt-2" *ngFor="let move of _moveBoardDTOList">
            <div *ngIf="move.leadId === _leadId && !move.cancelled && !move.completed" class="row">
                <div class="col-1">
                    <input type="checkbox" [(ngModel)]="move.selected" class="form-check-input"
                        [checked]="move.completed" [disabled]="move.completed === true" />
                </div>
                <div class="col">
                    {{ generalService.formatId(move.moveDetailId, 'move') }} -
                    {{ enumMappingService.mapMoveType(move.moveType) }} -
                    {{ enumMappingService.mapTransportType(move.moveTransport) }}
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" (click)="modal.dismiss('Cancel click')"><i
                class="mdi mdi-close"></i>&nbsp;
            Cancel</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="completeMove()">Move <i
                class="feather icon-check-circle"></i></button>
    </div>
</ng-template>

<ng-template #cancelMoveModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Cancel Move</h4>
    </div>
    <div class="modal-body">
        <!-- Add your modal content here -->

        <h6>Please select the Move(s) you would like to mark as Lost:</h6>

        <div *ngFor="let move of _moveBoardDTOList" class="mt-2">
            <div *ngIf="move.leadId === _leadId && !move.completed && !move.cancelled" class="row">
                <div class="col-1 mt-2">
                    <input type="checkbox" class="form-check-input" [checked]="move.cancelled"
                        [(ngModel)]="move.selected" />
                </div>
                <div class="col mt-2">
                    {{ generalService.formatId(move.moveDetailId, 'move') }} -
                    {{ enumMappingService.mapMoveType(move.moveType) }} -
                    {{ enumMappingService.mapTransportType(move.moveTransport) }}
                </div>
                <div class="mb-3 mt-3">
                    <label for="reasonTextarea" class="form-label">Reason (optional):</label>
                    <textarea class="form-control" id="reasonTextarea" [(ngModel)]="move.cancelReason"
                        placeholder="Enter reason"></textarea>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary complete-move btn-sm"
            (click)="modal.dismiss('Cancel click')">Close</button>
        <button type="button" class="btn revert-to-lead-btn btn-sm" (click)="revertToLead(_moveBoardDTOList)">Revert to
            Lead</button>

        <button type="button" class="btn btn-danger btn-sm" (click)="markLeadsLost(_moveBoardDTOList)">Cancel
            Move</button>
    </div>
</ng-template>

<!-- <div *ngIf="_isDataLoaded">
    <div class="row">
        <div class="col-12"> -->
<div *ngIf="_isDataLoaded">
    <div class="row title-and-mutliple-moves-btn">
        <div class="col-md-4">
            <h5>MOVE INFORMATION FORM</h5>
        </div>
        <div class="col-md-8">
            <ng-container *ngFor="let move of _moveBoardDTOList">
                <ng-container *ngIf="move.leadId === _leadId">
                    <button class="muliple-moves-btn float-end" (click)="showMoveById(move.moveDetailId)"
                        [class.active]="move.moveDetailId === _moveDetail.id"
                        ngbPopover="{{generalService.formatId(move.moveDetailId, 'move') }}" triggers="hover"
                        placement="top">

                        {{ enumMappingService.mapMoveType(move.moveType) }} -
                        {{ enumMappingService.mapTransportType(move.moveTransport) }}
                        <i *ngIf="move.moveTransport === 1" class="mdi mdi-airplane-takeoff"></i>
                        <i *ngIf="move.moveTransport === 2" class="mdi mdi-truck-delivery"></i>
                        <i *ngIf="move.moveTransport === 3" class="mdi mdi-ferry"></i>
                        <i *ngIf="move.moveTransport === 4" class="mdi mdi-ferry"></i>
                    </button>
                    &nbsp;
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="row mt-1">
        <div class="col-md-12 order-md-1">
            <div class="box-left border border-dark-grey p-3 custom-rounded">
                <div class="row">
                    <app-lead-info-header [type]="_leadDetailType" [assignmentType]="_assignmentType"
                        [assignmentRefId]="_moveDetail.id" [refId]="_leadDetailId"></app-lead-info-header>
                </div>
            </div>

            <div class="row">
                <div class="col-md-9 order-md-1 mt-3">
                    <ul ngbNav #defaultNav="ngbNav" class="nav-tabs" [(activeId)]="defaultNavActiveId">
                        <li [ngbNavItem]="1" (click)="_currentTabId = 1">
                            <a ngbNavLink class="container">
                                <span class="text">MOVE INFO</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="col-12 mt-2 text-end">
                                    <button class="btn btn-primary btn-sm" (click)="viewPdf()">
                                        View &nbsp;<i class="mdi mdi-file-export"></i>
                                    </button>
                                    &nbsp;
                                    <button class="btn btn-primary btn-sm" (click)="downloadPdf()">
                                        Export &nbsp;<i class="mdi mdi-file-export"></i>
                                    </button>
                                </div>
                                <app-move-info-form [moveDetailId]="_moveDetail.id" [refreshMoveInfo]="_refreshMoveInfo"
                                    [_contentDisable]="_contentDisable" (draftBLData)="handleDraftBLData($event)"
                                    (version)="handleVersionChange($event)"
                                    (categoryTransportChange)="handleCategoryTransportChange($event)">
                                </app-move-info-form>
                                <div class="border border-dark-grey mt-3">
                                    <!-- [class.disabled]="_contentDisable" -->
                                    <app-client-journey [moveDetailId]="_moveDetail.id"
                                        [moveCategory]="_moveDetail.categoryType"
                                        [moveTransport]="_moveDetail.transportType" [version]="_statusVersion"
                                        [contentDisabled]="_contentDisable"></app-client-journey>
                                </div>
                            </ng-template>

                        </li>

                        <!-- Export Air or Sea Shipment -->
                        <li [ngbNavItem]="2" *ngIf="_draftTabLabel && _readPermissionDraftBLAWB"
                            (click)="_currentTabId = 2">
                            <a ngbNavLink class="container">
                                <span class="text">{{_draftTabLabel}}</span>
                            </a>
                            <ng-template ngbNavContent>
                                <!-- <ng-container>
                                    <div class="col-md-12 mt-4">
                                        <div class="row mt-4">
                                            <div class="col-md-12">
                                                <label for="shipperInfo">Shipper Info</label>
                                                <textarea class="form-control" id="shipperInfo"
                                                    [(ngModel)]="_moveDetail.shipperInfo"
                                                    placeholder="Enter Shipper Details"
                                                    [disabled]="_createPermissionDraftBLAWB === false"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-12">
                                                <label for="consigneeInfo">Consignee Info</label>
                                                <textarea class="form-control" id="consigneeInfo"
                                                    [(ngModel)]="_moveDetail.consigneeInfo"
                                                    placeholder="Enter Consignee Details"
                                                    [disabled]="_createPermissionDraftBLAWB === false"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-12">
                                                <label for="notifyParty">Notify Party</label>
                                                <textarea class="form-control" id="notifyParty"
                                                    [(ngModel)]="_moveDetail.notifyParty"
                                                    placeholder="Enter NotifyParty Details"
                                                    [disabled]="_createPermissionDraftBLAWB === false"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end mt-4">
                                        <ng-container *ngIf="_createPermission">
                                            <button class="btn btn-sm btn-primary submit-info"
                                                (click)="saveDraftBL()">
                                                <i class="feather icon-save"></i> &nbsp; SAVE
                                            </button>
                                        </ng-container>
                                    </div>
                                </ng-container> -->
                                <app-consignment-details [contentDisabled]="_contentDisable"
                                    [moveDetailId]="_moveDetail.id" [moveCategory]="_moveDetail.categoryType"
                                    [moveTransport]="_moveDetail.transportType" [clientName]="_clientName"
                                    [clientsCompany]="_clientsCompany"
                                    [contentDisable]="_contentDisable"></app-consignment-details>
                            </ng-template>
                        </li>
                        <!-- <li [ngbNavItem]="3" (click)="_currentTabId = 3">
                            <a ngbNavLink class="container">
                                <span class="text">SURVEY</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-survey-summary
                                    [leadDetailId]="_moveDetail.leadDetailId"></app-survey-summary>
                            </ng-template>
                        </li> -->
                        <li [ngbNavItem]="4" (click)="_currentTabId = 4">
                            <a ngbNavLink class="container">
                                <span class="text">MESSAGES</span>
                            </a>
                            <ng-template ngbNavContent>
                                <!-- [class.disabled]="_contentDisable" -->
                                <div>
                                    <app-communications [leadDetailId]="_moveDetail.leadDetailId"
                                        [contentDisabled]="_contentDisable"></app-communications>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5" *ngIf="_readPermissionClientDocTab" (click)="_currentTabId = 5">
                            <a ngbNavLink class="container">
                                <span class="text">CLIENT DOCUMENTS</span>
                            </a>
                            <ng-template ngbNavContent>
                                <!-- [class.disabled]="_contentDisable" -->
                                <div><app-move-documents [documentType]="_clientDocType" [moveDetailId]="_moveDetail.id"
                                        [moveCategory]="_moveDetail.categoryType" [clientName]="_clientName"
                                        [clientsCompany]="_clientsCompany"
                                        [contentDisable]="_contentDisable"></app-move-documents>
                                </div>
                            </ng-template>
                        </li>

                        <li [ngbNavItem]="6" *ngIf="_readPermissionMoveDocTab" (click)="_currentTabId = 6">
                            <a ngbNavLink class="container">
                                <span class="text">MOVE DOCUMENTS</span>
                            </a>
                            <!-- [class.disabled]="_contentDisable" -->
                            <ng-template ngbNavContent>
                                <div>
                                    <app-move-documents [documentType]="_moveDocType" [moveDetailId]="_moveDetail.id"
                                        [leadDetailId]="_leadDetailId" [leadId]="_leadId"
                                        [moveCategory]="_moveDetail.categoryType"
                                        [contentDisabled]="_contentDisable"></app-move-documents>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
                </div>
                <div class="col-md-3 order-md-2 mt-2">

                    <ng-container *ngIf="_userRole !== 'ThirdParty'  && this._userRole !== 'Moving Account'">
                        <div *ngIf="showMoveCancelAndCompleteButton(); else completedOrCancelled" class="text-end">

                            <button class="btn cancel-move-btn btn-sm" (click)="cancelMove()"
                                [disabled]="_moveDetail.completed">
                                CANCEL MOVE <i class="feather icon-slash"></i>
                            </button>

                            <button class="btn complete-move-btn btn-sm" (click)="openInitiateMoveModal()">
                                COMPLETE MOVE <i class="feather icon-arrow-up-right"></i>
                            </button>
                        </div>

                        <ng-template #completedOrCancelled>
                            <div *ngIf="_moveDetail.completed" class="text-end">
                                <button class="btn complete-move-btn btn-sm"
                                    [class.active]="_moveDetail.completed === true">
                                    MOVE COMPLETED <i class="feather icon-check-circle"></i>
                                </button>
                            </div>

                            <div *ngIf="_moveDetail.cancelled" class="text-end">
                                <button type="button" class="btn btn-danger btn-sm" (click)="reInitiateMove()">
                                    RE-INITIATE MOVE <i class="feather icon-refresh-cw"></i>
                                </button>
                            </div>
                        </ng-template>
                    </ng-container>

                    <!-- [class.disabled]="_contentDisable" -->
                    <div *ngIf="_userRole !== 'ThirdParty' && this._userRole !== 'Moving Account'"
                        class="mt-3 border border-dark-grey custom-rounded order-md-2 box-right move-status-box">
                        <app-moves-status [contentDisabled]="_contentDisable" [refId]="_moveDetail.id"
                            [moveCategory]="_moveDetail.categoryType" [version]="_statusVersion"
                            [moveTransport]="_moveDetail.transportType" [statusType]="_statusType"
                            (allStatusesDone)="handleAllStatusesDone($event)"></app-moves-status>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div *ngIf="_currentTabId===1">
        <!-- <hr> -->
        <div #pdfContent class="hidden-pdf-content">
            <ng-container [ngTemplateOutlet]="moveSummaryTemplate"></ng-container>
        </div>
    </div>
</div>