import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { eAppraisalType } from '../../models/appraisal.model';
import { MovdService } from '../../services/movd.service';
import { AppraisalDTO, AppraisalDetail, AppraisalQuestion, AppraisalResponse, AppraisalSetting, MultipleChoiceOptions, YesNoOptions, eMultipleChoiceAnswer, eQuestionType, eYesNoAnswer } from '../../models/appraisal.model';
import SignaturePad from 'signature_pad';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { GeneralService } from 'src/services/general.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { EnumMappingService } from '../../services/movd.enum.service';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { MoveCategoryTypeOptions, MoveTypeOptions } from '../../models/lead-detail.model';

@Component({
  selector: 'app-appraisal-form',
  templateUrl: './appraisal-form.component.html',
  styleUrls: ['./appraisal-form.component.scss']
})
export class AppraisalFormComponent implements OnChanges, AfterViewInit {

  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  SignaturePad!: SignaturePad;
  @Input() packagingDetailId: any;
  @Input() appraisalType: any;

  pdfDocGenerator: any;
  _companySetting = new CompanySetting();
  _volumeUnit: string = '';
  _weightUnit: string = '';
  _currency: string = '';
  _appraisalDTO = new AppraisalDTO;
  _appraisalDetail = new AppraisalDetail;
  _appraisalSetting = new AppraisalSetting
  _appraisalQuestion: AppraisalQuestion[] = []
  _yesNoQuest: AppraisalQuestion[] = []
  _multipleChoiceQuest: AppraisalQuestion[] = []
  _refId: number = 0;
  _date: NgbDate | null = null;
  eQuestionType: eQuestionType = eQuestionType.YesNo;
  eYesNo = eYesNoAnswer;
  eMultipleChoice = eMultipleChoiceAnswer;
  multipleChoiceOptions = MultipleChoiceOptions;
  yesNoOptions = YesNoOptions;

  _showNoAppraisalMsg = false;

  constructor(private _movdService: MovdService,
    private _appComponent: AppComponent, private _http: HttpClient,
    public generalService: GeneralService, public enumMappingService: EnumMappingService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packagingDetailId'] || changes['appraisalType']) {
      if (changes['packagingDetailId']) {
        // console.log('ng onchanges');

        this.packagingDetailId = changes['packagingDetailId'].currentValue;
        this.getAppraisal();
      }
    }
  }

  ngAfterViewInit() {
    this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    this.SignaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.SignaturePad.clear();
  }

  getAppraisal() {
    const refId = this.packagingDetailId;
    const appraisalType = this.appraisalType;
    // console.log('packaging detail id ', this.packagingDetailId)
    // console.log('appraisal Type', this.appraisalType);

    this._appraisalDTO = new AppraisalDTO();
    this._appraisalSetting = new AppraisalSetting();
    this._appraisalDetail = new AppraisalDetail();
    this._yesNoQuest = [];
    this._multipleChoiceQuest = [];
    if (this.SignaturePad) {
      this.clearSignature();
    }

    this._movdService.getAppraisal(refId, appraisalType).subscribe({
      next: (data: any) => {
        // console.log('get appraisal response', data);
        if (data) {
          this._appraisalDTO = data;
          if (this._appraisalDTO) {
            if (this._appraisalDTO.appraisalSetting) {
              this._appraisalSetting = this._appraisalDTO.appraisalSetting;
            }
            if (this._appraisalDTO.appraisalDetail) {
              this._appraisalDetail = this._appraisalDTO.appraisalDetail;
              if (this._appraisalDetail.signature) {
                this.renderSignature(this._appraisalDetail.signature);
              }
            }
            if (this._appraisalDTO.questions) {
              this._yesNoQuest = [];
              this._multipleChoiceQuest = [];
              for (const question of this._appraisalDTO.questions) {
                if (question.questionType === eQuestionType.YesNo) {
                  this._yesNoQuest.push(question);
                } else if (question.questionType === eQuestionType.MultipleChoice) {
                  this._multipleChoiceQuest.push(question);
                  // console.log("question ", question);

                }

              }
            }
            // console.log('yes no quest', this._yesNoQuest);
            // console.log('multiple choice ', this._multipleChoiceQuest);
            this.createPdf();
          }
        }

        // console.log('consignment detail list', this._consignmentItemList);
        // this._appComponent.showSuccessSwal('Save', 'Consignment Info');
      },
      error: (error: any) => {
        // this._appComponent.showErrorSwal('Saving', 'record');
        console.error("Error getting appraisal", error);
        if (error.status === 404) {
          // console.log("404");
          this._showNoAppraisalMsg = true;
        }
      },
    });
  }
  saveAppraisalDetail() {
    const SignatureDataURL = this.SignaturePad.toDataURL();

    if (this.SignaturePad.isEmpty()) {
      this._appraisalDetail.signature = null;
    } else {
      this._appraisalDetail.signature = SignatureDataURL;
    }


    this._appraisalDTO = {
      appraisalSetting: this._appraisalSetting,
      appraisalDetail: this._appraisalDetail,
      questions: [...this._yesNoQuest, ...this._multipleChoiceQuest]
    };
    // console.log('question response', this._appraisalDTO.appraisalDetail);

    this._movdService.saveAppraisal(this._appraisalDTO).subscribe({
      next: (data: any) => {
        this._appComponent.showSuccessSwal('Save', 'Appraisal Detail');

        this.getAppraisal();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('Saving', 'record');
        // console.log("Error", error);
      },
    });
  }
  renderSignature(signatureDataURL: string) {
    const img = new Image();

    img.onload = () => {
      if (this.SignaturePad) {
        this.SignaturePad.clear();
        this.SignaturePad.fromDataURL(signatureDataURL);
      } else {
        // console.error('SignaturePad is not defined');
      }

    };

    img.src = signatureDataURL;
  }
  onDateSelect(date: NgbDate) {
    const dateToSet = this.convertNgbDateToDate(date);
    this._appraisalDetail.date = dateToSet;
    this._date = this.convertDateToNgbDate(this.generalService.formatDate(this._appraisalDetail.date, null));
  }
  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }
  isHighlighted(date: NgbDateStruct) {
    let dateToCompare: NgbDate | null;
    dateToCompare = this.convertDateToNgbDate(this._appraisalDetail.date);


    if (dateToCompare) {
      return (
        date.year === dateToCompare.year &&
        date.month === dateToCompare.month &&
        date.day === dateToCompare.day
      );
    }
    return false;
  }
  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }
  updateSelectedAnswer(question: AppraisalQuestion, selectedValue: number) {
    if (question.appraisalResponse !== undefined) {
      question.appraisalResponse.selectedAnswer = selectedValue;
      // console.log(question);
    } else {
      // console.error("Appraisal response is undefined for question:", question);
    }
  }

  selectMultipleOption(questionId: number, optionId: number, responseId: number) {
    // this._multipleChoiceQuest.forEach(appraisal => {
    //   if (appraisal.appraisalResponse && appraisal.appraisalResponse.id === responseId) {
    //     appraisal.appraisalResponse.appraisalQuestionId = appraisalId;
    //     appraisal.appraisalResponse.selectedAnswer = optionId;
    //   } else if (!appraisal.appraisalResponse) {
    //     appraisal.appraisalResponse = new AppraisalResponse();
    //     appraisal.appraisalResponse.appraisalQuestionId = appraisalId;
    //     appraisal.appraisalResponse.selectedAnswer = optionId;
    //   }
    // });

    this._multipleChoiceQuest.forEach(question => {
      if (question && question.id === questionId) {
        if (question.appraisalResponse && question.appraisalResponse.id === responseId) {
          question.appraisalResponse.selectedAnswer = optionId
        } else {
          question.appraisalResponse = new AppraisalResponse();
          question.appraisalResponse.selectedAnswer = optionId;
          question.appraisalResponse.appraisalQuestionId = question.appraisalResponse.appraisalQuestionId;
          question.appraisalResponse.appraisalDetailId = question.appraisalResponse.appraisalDetailId;
        }
      }
    });

    // console.log(`Appraisal ${appraisalId} selected option ${optionId} and response id ${responseId}`);
  }

  selectYesNoOption(questionId: number, optionId: number, responseId: number) {
    this._yesNoQuest.forEach(question => {
      if (question && question.id === questionId) {
        if (question.appraisalResponse && question.appraisalResponse.id === responseId) {
          question.appraisalResponse.selectedAnswer = optionId
        } else {
          question.appraisalResponse = new AppraisalResponse();
          question.appraisalResponse.selectedAnswer = optionId;
          question.appraisalResponse.appraisalQuestionId = question.appraisalResponse.appraisalQuestionId;
          question.appraisalResponse.appraisalDetailId = question.appraisalResponse.appraisalDetailId;
        }
      }
    });

    // console.log(`Appraisal response answer id`, this._yesNoQuest);
    // console.log(`Appraisal ${appraisalId} selected option ${optionId}`);
  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        // console.log('getting company settings:', response);
        this._companySetting = response;
        if (response.weightUnit) {
          this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
        }
        if (response.volumeUnit) {
          this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
        }
        if (response.currency) {
          this._currency = getCurrencySymbol(response.currency);
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }
  getYesNoOptionText(id: number): string {
    const option = this.yesNoOptions.find(option => option.id === id);
    return option ? option.value : '';
  }
  createPdf() {
    return new Promise<void>((resolve, reject) => {
      const logoUrl = 'assets/images/movd/movd_logo.png';
      const checkedImageUrl = 'assets/images/movd/Checked.png';
      const uncheckedImageUrl = 'assets/images/movd/Unchecked.png';

      this.getImageAsBase64(logoUrl).then(logoBase64data => {
        this.getImageAsBase64(checkedImageUrl).then(checkedImageBase64 => {
          this.getImageAsBase64(uncheckedImageUrl).then(uncheckedImageBase64 => {
            const docDefinition = {
              content: [
                {
                  image: logoBase64data,
                  width: 110,
                  alignment: 'center'
                },
                {
                  text: this._appraisalSetting.title,
                  style: 'header',
                  alignment: 'center',
                  margin: [0, 15, 0, 10]
                },
                {
                  text: this._appraisalSetting.introMessage,
                  style: 'columnText',
                  alignment: 'left',
                  margin: [0, 10, 0, 10]
                },
                ...this._yesNoQuest.map(question => {
                  const selectedAnswer = this.getYesNoOptionText(question.appraisalResponse.selectedAnswer);
                  const yesChecked = selectedAnswer === 'Yes';
                  const noChecked = selectedAnswer === 'No';

                  let yesImageToShow = yesChecked ? checkedImageBase64 : uncheckedImageBase64;
                  let noImageToShow = noChecked ? checkedImageBase64 : uncheckedImageBase64;

                  const questionWithOptions = {
                    table: {
                      widths: ['60%', '40%'], // Adjust the widths as needed
                      body: [
                        [
                          {
                            text: `${question.questionText}`,
                            style: 'questionText',
                          },
                          {
                            columns: [
                              {
                                width: 'auto',
                                alignment: 'center',
                                stack: [
                                  { image: yesImageToShow, width: 10, height: 10 },
                                  { text: 'Yes', style: 'checkboxText', margin: [10, 0] }
                                ]
                              },
                              {
                                width: 'auto',
                                alignment: 'center',
                                stack: [
                                  { image: noImageToShow, width: 10, height: 10 },
                                  { text: 'No', style: 'checkboxText', margin: [10, 0] }
                                ]
                              }
                            ],
                            columnGap: 10 // Adjust the gap between columns
                          }
                        ]
                      ]
                    },
                    margin: [0, 0, 0, 0] // No margin between tables
                  };

                  return questionWithOptions;
                }),
                {
                  text: '',
                  // style: '',
                  alignment: 'center',
                  margin: [0, 10, 0, 10]
                },
                ...this._multipleChoiceQuest.flatMap((appraisal, index) => {
                  const options = this.multipleChoiceOptions.map(option => {
                    const isChecked = option.id === appraisal.appraisalResponse.selectedAnswer;
                    const checkboxImage = isChecked ? checkedImageBase64 : uncheckedImageBase64;

                    return {
                      image: checkboxImage,
                      width: 10,
                      height: 10,
                      value: option.value
                    };
                  });

                  const optionColumns = options.map(option => ({
                    columns: [
                      { image: option.image, width: 10, height: 10, margin: [0, 2, 0, 0] }, // Assuming width and height are correct
                      { text: option.value, margin: [3, 0, 3, 4] } // Adjust margin as needed
                    ]
                  }));

                  const questionWithOptions = {
                    table: {
                      widths: ['25%', '75%',],
                      body: [
                        [
                          { text: appraisal.questionText, style: 'label', }, // Reduce bottom margin here
                          { columns: optionColumns, margin: [0, 5, 0, 0] }
                        ]
                      ]
                    },
                    // layout: 'lightHorizontalLines',
                    margin: [0, 0, 0, 0]
                  };

                  return questionWithOptions;
                })
              ],
              styles: {
                header: {
                  fontSize: 20,
                  bold: true,
                  margin: [0, 0, 0, 10]
                },
                columnText: {
                  fontSize: 11,
                },
                smallHeadings: {
                  bold: true,
                  fontSize: 14,
                  color: 'black'
                },
                checkboxText: {
                  fontSize: 12,
                },
                checkboxLabel: {
                  bold: true,
                  fontSize: 12,
                  alignment: 'center'
                },
                questionText: {
                  fontSize: 12,
                  bold: true,
                  margin: [0, 0, 10, 0]
                },
              },
              footer: function (currentPage: any, pageCount: any) {
                return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 0, 35, 0] };
              }
            };

            // if (this._multipleChoiceQuest.length > 0) {
            //   var multipleChoiceQuestData = [];
            //   for (let row of this._multipleChoiceQuest) {
            //     multipleChoiceQuestData.push([row.questionText]);

            //   }
            //   docDefinition.content.push(
            //     {
            //       table: {
            //         layout: 'lightHorizontalLines',
            //         headerRows: 1,
            //         widths: ['100%'],
            //         body: [
            //           [],
            //           // Dynamic data goes here
            //           ...multipleChoiceQuestData,
            //         ],
            //       },
            //       margin: [0, 10, 0, 10], // [left, top, right, bottom]
            //     },
            //   );

            //   if (docDefinition && docDefinition.content && docDefinition.content[6] && docDefinition.content[4].table && docDefinition.content[4].table.body) {
            //     // Add dynamic data to the second table
            //     multipleChoiceQuestData.forEach(data => {
            //       docDefinition.content[4].table.body.push(data);
            //     });
            //   }

            // }
            docDefinition.content.push(
              { text: 'User Comment', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
            );
            docDefinition.content.push(
              { text: this._appraisalDetail.userComment || ' - ', style: 'columnText', alignment: 'left', margin: [0, 10, 0, 10] },
            );
            docDefinition.content.push(
              { text: 'Final WalkThrough', style: 'header', alignment: 'center', margin: [0, 10, 0, 10] },
            );
            let nameText = this._appraisalDetail.name ? this._appraisalDetail.name : '______________________', bold: true;
            let text = `I ${nameText}, ${this._appraisalSetting.walkthroughMessage || ''}`;

            docDefinition.content.push({
              text: text,
              style: 'columnText',
              alignment: 'left',
              margin: [0, 10, 0, 10]
            });
            (docDefinition as { content: any[] }).content.push({
              stack: [
                {
                  columns: [
                    {
                      stack: [
                        {
                          text: 'Signature:',
                          style: 'columnText',
                          alignment: 'left',
                          margin: [0, 30, 0, 0]
                        },
                        {
                          stack: [
                            this._appraisalDetail.signature ? {
                              image: this._appraisalDetail.signature,
                              width: 100,
                              height: 50,
                              margin: [0, 10, 0, 0]
                            } : { text: '________________________', alignment: 'left', margin: [0, 10, 0, 0] }
                          ]
                        }
                      ]
                    },
                    {
                      stack: [
                        {
                          text: 'Date:', style: 'columnText', alignment: 'right',
                          margin: [0, 30, 30, 0]
                        },
                        this.generalService.formatDate(this._appraisalDetail.date, null) ? {
                          text: this.generalService.formatDate(this._appraisalDetail.date, null),
                          width: 100,
                          height: 50,
                          margin: [0, 10, 0, 0],
                          alignment: 'right'
                        } : '-'
                      ]
                    }
                  ]
                }
              ]
            });



            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            this.pdfDocGenerator = pdfDocGenerator;
            resolve();
          });
        });
      }).catch(error => {
        reject(error);
      });
    });
  }

  getImageAsBase64(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this._http.get(url, { responseType: 'blob' }).subscribe((res: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(res);
      }, error => {
        reject(error);
      });
    });
  }
  getEmojiImage(optionId: number): string {
    switch (optionId) {
      case eMultipleChoiceAnswer.Dissatisfied:
        return 'assets/images/emojis/dissatisfied.png'; // Path to the image for dissatisfied emoji
      case eMultipleChoiceAnswer.Poor:
        return 'assets/images/emojis/poor.png'; // Path to the image for poor emoji
      case eMultipleChoiceAnswer.Satisfied:
        return 'assets/images/emojis/satisfied.png'; // Path to the image for satisfied emoji
      case eMultipleChoiceAnswer.VeryGood:
        return 'assets/images/emojis/very_good.png'; // Path to the image for very good emoji
      case eMultipleChoiceAnswer.Excellent:
        return 'assets/images/emojis/excellent.png'; // Path to the image for excellent emoji
      default:
        return 'assets/images/emojis/unknown.png'; // Path to a default image for unknown emojis
    }
  }

  async viewPdf() {
    try {
      // Always create a new PDF
      await this.createPdf();
  
      // Open the newly created PDF
      await this.pdfDocGenerator.open();
    } catch (error) {
      this._appComponent.showAlertMessageDialog('View PDF', 'An error occurred while creating or opening the PDF. Please try again.');
    }
  }

  async downloadPdf() {
    try {
      // Always create a new PDF
      await this.createPdf();
      // Download the newly created PDF
      await this.pdfDocGenerator.download();
    } catch (error) {
     
     
      this._appComponent.showAlertMessageDialog('Export PDF', 'An error occurred while creating or downloading the PDF. Please try again.');
    }
  }
}