import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { GeneralService } from 'src/services/general.service';
import { MoveCategoryTypeOptions, MoveTypeOptions, TransportTypeOptions } from '../models/lead-detail.model';
import { ContactService } from 'src/services/contact.service';
import { MovdService } from '../services/movd.service';
import { ReportsService } from 'src/services/reports.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DealsModel } from 'src/app/views/crm/sales/deals/deals.models';
import { MoveReportsDTO } from './models/move-reports-dto.model';
import { SaleService } from 'src/services/sale.service';
import { SalesReportDTO } from './models/sales-reports-dto.model';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { eContactType } from '../../crm/contact/contact.models';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  defaultNavActiveId = 1;
  _moduleActiveTabId = 1;
  _reportActiveTabId = 1;

  columnMode: ColumnMode = ColumnMode.force;
  loadingIndicator = true;
  hoveredDate: NgbDateStruct | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  _moveReports: MoveReportsDTO = new MoveReportsDTO;
  _saleReports: SalesReportDTO = new SalesReportDTO;
  _pipesList: any[] = [];

  _companies: any = [];
  _contacts: any = [];
  // _lead: any;
  _allCustomers: any = [];
  // _displayedData: any = ['18 April, 24', '6', '4', '5', '2', '17'];
  _selectedAccountIds: number[] | null = null;
  _selectedThirdPartyIds: number[] | null = null;
  _reportName: string | null = 'Total Leads';
  showSections: boolean = false;
  showSalesSections: boolean = false;
  _summarizeReportData: any[] = [];
  summarizeTableColumns: any[] = [];
  _detailReportData: any[] = [];
  detailTableColumns: any[] = [];

  _dealName!: string | null;
  _allContacts: any[] = [];
  _deal = new DealsModel();
  _companyId: number = 0

  constructor(private _contactService: ContactService, public generalService: GeneralService,
    private _reportsService: ReportsService, private _movdService: MovdService,
    private _saleService: SaleService,
    private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Reports', url: '/reports' }
    ]);

    this.getContacts();
    this.getSalesReports();
    let companyId = Number(localStorage.getItem('companyId'));
    if (companyId) {
      this._companyId = companyId;
    }
    this.getPipesList(this._companyId);
  }

  getPipesList(companyId: number) {
    this._saleService.getPipes(companyId).subscribe(
      (data: any) => {
        // console.log("data ", data);

        this._pipesList = data;
        this.getContacts();
      },
      (error: any) => {
        console.error("error getting pipes list", error);
      }
    );
  }

  getsummarizeReport() {
    this._reportsService.getLeadReports(this._moveReports).subscribe({
      next: (data: any[]) => {
        // console.log("leads for baord", data);

        // if (data.length > 0) {
        this._summarizeReportData = data;
        // Update tableColumns to include new combined date columns
        this.summarizeTableColumns = Object.keys(this._summarizeReportData[0])
          .filter((key) => key !== 'creationDate'

          )
          .map((key) => {
            let header: string;
            let prop: string = key;
            switch (key) {
              case 'date':
                header = 'Date';
                break;
              case 'new':
                header = 'New';
                break;
              case 'inProgress':
                header = 'InProgress';
                break;
              case 'won':
                header = 'Won';
                break;
              case 'lost':
                header = 'Lost';
                break;
              case 'totalLeads':
                header = 'Total Leads';
                break;
              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }
            return { header, prop };
          });
        // }
      },
      error: (error) => {
        console.error(error);
      }
    });
  };

  getLeadReports() {
    // this._moveReports.reportName = this._reportName;
    // this._moveReports.thirdPartyIds = this._selectedThirdPartyIds
    // this._moveReports.accountIds = this._selectedAccountIds
    // console.log('reports params', this._reports);

    this._reportsService.getLeadReports(this._moveReports).subscribe({
      next: (response) => {
        // console.log('getting company settings:', response);

        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }
  getSalesReports(): void {
    // console.log('Sales reports:', this._saleReports);
    this._reportsService.getSalesReports(this._saleReports).subscribe({
      next: (data) => {

        // Handle the fetched data, such as updating the UI or storing it in a variable
      },
      error: (error: any) => {
        console.error('Error fetching sales reports:', error);
        // Handle errors
        // You can also display an error message to the user if needed
      }
    });
  }



  onChangeTab(type: any) {

  }
  onChangeModuleTab(type: any) {
  }
  onChangeLeadModule(type: any, reportName: string | null) {
    this._reportName = reportName;
  }
  onChangeReportTab(type: any) {

  }
  toggleSections() {
    this.showSections = !this.showSections;
  }
  toggleSalesSections() {
    this.showSalesSections = !this.showSalesSections;
  }
  onDateSelect(date: any) {
    const selectedDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    this.hoveredDate = { year: date.year, month: date.month, day: date.day };
    if (!this._moveReports.fromDate && !this._moveReports.toDate) {
      this._moveReports.fromDate = selectedDate;
    } else if (
      this._moveReports.fromDate &&
      !this._moveReports.toDate &&
      selectedDate > this._moveReports.fromDate
    ) {
      this._moveReports.toDate = selectedDate;
    } else {
      this._moveReports.fromDate = selectedDate;
      this._moveReports.toDate = null;
    }
    this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._moveReports.fromDate, null));
    this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._moveReports.toDate));
    if (this._moveReports.fromDate && this._moveReports.toDate) {
      const filterDateFrom = new Date(this._moveReports.fromDate);
      const filterDateTo = new Date(this._moveReports.toDate);
      this._moveReports.fromDate = filterDateFrom;
      this._moveReports.toDate = filterDateTo;
    }
  }

  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }
  isHovered(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    const hoveredDate = this.hoveredDate;

    if (!hoveredDate) {
      return false;
    }
    return fromDate && !toDate && date.after(fromDate) && date.before(toDate) && date.equals(hoveredDate);
  }

  isInside(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    return fromDate && toDate && date.after(fromDate) && date.before(toDate);
  }
  isRange(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    if (fromDate && toDate) {
      return date.equals(fromDate) || date.equals(toDate) || this.isInside(date) || this.isHovered(date) || this.isRangeSelected(date, fromDate, toDate);
    }

    return false;
  }
  isRangeSelected(date: NgbDate, fromDate: NgbDate, toDate: NgbDate) {
    if (!fromDate || !toDate) {
      return false;
    }

    return date.after(fromDate) && date.before(toDate);
  }
  onChangeCategory(event: Event) {
  }

  onChangeTransport(event: Event) {
  }

  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        if (response && response.length > 0) {
          this._allCustomers = response;
          this._contacts = response.filter((item: { contactType: number; }) => item.contactType === eContactType.Contact);
          this._companies = response.filter((item: { contactType: number; }) => item.contactType === eContactType.Company);

        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }
  filterContacts(type: string, ids: number[] | null) {

    // console.log("filter contacts", id);
    if (this._contacts && this._contacts.length > 0) {
      if (type === 'contact') {
        this._selectedAccountIds = ids;
      } else if (type === 'thirdParty') {
        this._selectedThirdPartyIds = ids;
      }
    }
  }
  getContacts() {
    this._contactService.getContacts().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._allContacts = data;
          // this._allContacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId === this._companyId);
          this._contacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  // validateNumericField(event: KeyboardEvent): void {
  //   const input = event.key;
  //   const value = (event.target as HTMLInputElement).value;
  //   const isNumericOrDecimal = /^[0-9.]$/.test(input);
  //   const hasDecimalPoint = value.includes('.');
  //   const newValue = value + input;
  //   const isValidNumber = /^[-+]?(?:\d+)?(?:\.\d*)?$/.test(newValue);

  //   if (!isNumericOrDecimal || (input === '.' && hasDecimalPoint) || !isValidNumber) {
  //     event.preventDefault();
  //   }
  // }
}
