import { Address } from "./address.model";

export class Labour {
  id: number;
  ownedBy: number;
  name: string;
  type: eLabourType | null;
  nic: string;
  address: string | null;
  phone: string | null;
  email: string | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.name = '';
    this.type = null;
    this.nic = '';
    this.address = null;
    this.phone = null;
    this.email = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
export enum eLabourType {
  Driver = 0,
  Packer = 1,
  Loader = 2
}
export interface EnumOption {
  id: number;
  value: string;
}

export const LabourTypeOptions: EnumOption[] = [
  { id: eLabourType.Driver, value: 'Driver' },
  { id: eLabourType.Packer, value: 'Packer' },
  { id: eLabourType.Loader, value: 'Loader' },
];