<div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
    <div>
        <h4 class="mb-3 mb-md-0">Welcome {{ getNameFromLocalStorage() }}</h4>
    </div>
    <div class="d-flex align-items-center flex-wrap text-nowrap">

    </div>
</div>


<ng-container *ngIf="_isLoading">
    <span id="loader">
        <span class="spinner"></span>
    </span>
</ng-container>

<ng-container *ngIf="!_isLoading">
    <div class="row" *ngIf="isDataLoaded">
        <div class="col-12 col-xl-12 stretch-card">
            <div class="row flex-grow-1">
                <div class="col-md-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Customers</h6>

                            </div>
                            <div class="row" *ngIf="_customerStats && _customerStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_customerStats[0].totalCustomers}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_customerStats[0].changeOverPreviousWeek}}</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="customersChartOptions.series"
                                            [chart]="customersChartOptions.chart"
                                            [colors]="customersChartOptions.colors"
                                            [xaxis]="customersChartOptions.xaxis"
                                            [stroke]="customersChartOptions.stroke"
                                            [markers]="customersChartOptions.markers"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Deals</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="ordersChartOptions.series"
                                            [chart]="ordersChartOptions.chart" [colors]="ordersChartOptions.colors"
                                            [plotOptions]="ordersChartOptions.plotOptions"
                                            [xaxis]="ordersChartOptions.xaxis"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Activities</h6>

                            </div>
                            <div class="row">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_totalActivites}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <!--<span>+2.8%</span>-->
                                            <!--<i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>-->
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="growthChartOptions.series"
                                            [chart]="growthChartOptions.chart" [colors]="growthChartOptions.colors"
                                            [xaxis]="growthChartOptions.xaxis" [stroke]="growthChartOptions.stroke"
                                            [markers]="growthChartOptions.markers"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> <!-- row -->



    <div class="row">
        <div class="col-lg-7 col-xl-8 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title mb-0">Monthly Deals</h6>
                        <div ngbDropdown class="mb-2">

                        </div>
                    </div>
                    <p class="text-muted">Deals are activities related to selling or the number of goods or
                        services sold in a given time period.</p>

                    <apx-chart [series]="monthlySalesChartOptions.series" [chart]="monthlySalesChartOptions.chart"
                        [colors]="monthlySalesChartOptions.colors" [fill]="monthlySalesChartOptions.fill"
                        [grid]="monthlySalesChartOptions.grid" [xaxis]="monthlySalesChartOptions.xaxis"
                        [yaxis]="monthlySalesChartOptions.yaxis" [legend]="monthlySalesChartOptions.legend"
                        [stroke]="monthlySalesChartOptions.stroke" [dataLabels]="monthlySalesChartOptions.dataLabels"
                        [plotOptions]="monthlySalesChartOptions.plotOptions"></apx-chart>

                </div>
            </div>
        </div>
        <div class="col-lg-5 col-xl-4 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title">Latest Tasks</h6>
                        <div ngbDropdown class="mb-2">

                        </div>
                    </div>

                    <app-task [customerId]="_localCustomerId" [isDeal]="false" type="dashboard"></app-task>
                </div>
            </div>
        </div>
    </div> <!-- row -->

    <div class="row">
        <div class="col-lg-5 col-xl-4 grid-margin grid-margin-xl-0 stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title mb-0">Top Sales</h6>

                    </div>
                    <div class="d-flex flex-column">
                        <ng-container *ngIf="_topSalesList.length > 0">
                            <ng-container *ngFor="let rep of _topSalesList">
                                <span class="d-flex align-items-center border-bottom py-3">
                                    <div class="me-3">
                                        <div class="title-icon img-xs rounded-circle">
                                            {{ rep.dealOwnerName.charAt(0) }}
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <a (click)="redirectToProfile(rep)">
                                            <div class="d-flex justify-content-between">
                                                <h6 class="text-body mb-2">{{ rep.dealOwnerName }}</h6>
                                                <p class="text-muted tx-12">{{ rep.dealCount }} Deals</p>
                                            </div>
                                        </a>
                                    </div>
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="_topSalesList.length === 0">
                            <p> No Sales Currently</p>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-7 col-xl-8 stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title mb-0">Latest Sales Activities</h6>

                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-0">#</th>
                                    <th class="pt-0">Deal Name</th>
                                    <th class="pt-0">Date</th>
                                    <th class="pt-0">Amount</th>
                                    <th class="pt-0">Deal Owner</th>
                                    <th class="pt-0">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let deal of _dealsList; let i = index">
                                    <tr>
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ deal.name }}</td>
                                        <td>{{ deal.creationDate | date: 'dd/MM/yyyy' }}</td>
                                        <td>{{ deal.amount }}</td>
                                        <td>{{ deal.dealOwner }}</td>
                                        <td>
                                            {{ deal.pipeName }}
                                            <!--</span>-->
                                        </td>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>