<ng-template #otpModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">&nbsp; Enter OTP</h4>
    <!-- <button type="button" class="btn-close" (click)="modal.close('by: close icon')"
      aria-label="Close"></button> -->
  </div>
  <div class="modal-body ">
    <form (ngSubmit)="verifyOTP()">
      <div class="text-danger small mt-3"> This otp is valid for only 10 minutes. </div>
      <div class="form-group mt-3">
        <div class="otp-input-container">
          <input type="text" class="form-control otp-input" id="otp1" [(ngModel)]="_enteredOTP1"
            name="otp1" maxlength="1" (keyup)="_enteredOTP1?.length == 1 ? otp2.focus() : null">
          <input type="text" class="form-control otp-input" id="otp2" [(ngModel)]="_enteredOTP2"
            name="otp2" maxlength="1" (keyup)="_enteredOTP2?.length == 1 ? otp3.focus() : null"
            #otp2>
          <input type="text" class="form-control otp-input" id="otp3" [(ngModel)]="_enteredOTP3"
            name="otp3" maxlength="1" (keyup)="_enteredOTP3?.length == 1 ? otp4.focus() : null"
            #otp3>
          <input type="text" class="form-control otp-input" id="otp4" [(ngModel)]="_enteredOTP4"
            name="otp4" maxlength="1" #otp4>
        </div>
      </div>
      <div *ngIf="_showOtpErrorMessage" class="text-danger small mt-2 mb-2"> {{_otpErrorMsg}}
      </div>
    </form>
  </div>
  <div class="modal-footer mt-3">
    <button *ngIf="!_showOtpErrorMessage" type="submit" class="btn btn-primary mt-2 mb-2"
      (click)="verifyOTP()">Verify</button>
    <button *ngIf="_showOtpErrorMessage" type="button" class="btn btn-secondary mt-2 mb-2"
      (click)="modal.close('by: close icon')">Close</button>
  </div>
</ng-template>



<div class="loader-overlay" *ngIf="isLoading === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div>

<div class="login-container">

  <div class="forms-container">
    <div class="signin-signup">
      <form [formGroup]="loginForm" class="sign-in-form" (ngSubmit)="onLoggedin($event)">
        <a routerLink="/" class="sidebar-brand nobleui-logo" style="font-size: 50px;">
          <!--Tech<span>flo</span>-->
          <img src="assets/images/movd/movd_logo.png" width="200" />
        </a>
        <a class="sidebar-brand nobleui-logo" style="font-size: 20px;">
          Sign<span>in</span>
        </a>

        <div class="input-field">
          <i class="fas fa-envelope"></i>
          <input type="email" class="form-control" id="txtemail" formControlName="email"
            placeholder="Email" autocomplete="on">
        </div>
        <div
          *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched || submitted)">
          <div *ngIf="loginForm.get('email')?.errors?.['required']" class="text-danger small">
            *Email is required!
          </div>
        </div>


        <div class="input-field">
          <i class="fas fa-lock"></i>
          <input type="password" class="form-control" id="txtpass" formControlName="password"
            placeholder="Password">
        </div>
        <div
          *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched || submitted)">
          <div *ngIf="loginForm.get('password')?.errors?.['required']" class="text-danger small">
            *Password is required!
          </div>
        </div>
        <br />
        <div class="remember-me-label" for="authCheck">
          <input type="checkbox" id="authCheck" formControlName="rememberMe">
          Remember me
        </div>

        <div>
          <input type="submit" value="Login" class="btn btn-primary" (click)="onLoggedin($event)" />
          <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
        </div>
        <div class="mt-1">
          <a routerLink="/forgot-password" class="forgot-password-link">Forgot Password?</a>
        </div>
        <div>
          <a routerLink="/register" class="forgot-password-link ">Sign up</a>
        </div>

      </form>
    </div>
  </div>
  <div class="panels-container">
    <div class="panel left-panel">
      <div class="content">
        <img src="assets/images/movd/movd00.svg" class="logo-image" alt="Techflo Logo" />
      </div>
    </div>
  </div>



</div>