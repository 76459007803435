import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { InventoryService } from 'src/services/inventory.service';
import { AppComponent } from 'src/app/app.component';
import { eVehicleType, Vehicle, VehicleTypeOptions } from '../../../models/vehicle.model';
import { EnumMappingService } from '../../../services/movd.enum.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  @ViewChild('addVehicleModal') addVehicleModal: any
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  searchTerm: string = '';
  _ngbModalRef: NgbModalRef | undefined;
  _vehicle = new Vehicle();
  _eVehicleTypes = VehicleTypeOptions;

  _companyId: number = 0;
  _vehicleTableData: any[] = [];
  _vehicleTableDataList: any[] = [];
  _vehicleTableColumns: any[] = [];
  ColumnMode = ColumnMode;

  constructor(private _modalService: NgbModal, private _inventoryService: InventoryService, private _appComponent: AppComponent, public enumMappingService: EnumMappingService,) {
    this._companyId = Number(localStorage.getItem('companyId'));
  }

  ngOnInit(): void {
    this.refreshVehicleTableData();
  }
  onChangeTab(_type: any) {

  }

  performSearch() {
    if (this.searchTerm === '') {
      this._vehicleTableData = this._vehicleTableDataList;
      return;
    }

    const searchTerms = this.searchTerm.toLowerCase().split(' ');

    const filteredData = this._vehicleTableDataList.filter((row: any) => {
      return Object.values(row).some((value: any) => {
        if (Object.values(eVehicleType).includes(value)) {
          const vehicleTypeText = this.enumMappingService.mapVehicleType(value).toLowerCase();
          return searchTerms.some(term => vehicleTypeText.includes(term));
        } else {
          const valueText = String(value).toLowerCase();
          return searchTerms.some(term => valueText.includes(term));
        }
      });
    });

    this._vehicleTableData = filteredData;

    if (this.table) {
      this.table.offset = 0;
    }

    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }
  openAddModal(template: TemplateRef<any>) {
    this._vehicle = new Vehicle();
    this._ngbModalRef = this._modalService.open(this.addVehicleModal, { size: 'md', });
  }
  closeModal() {
    this._ngbModalRef?.close();
  }
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }
  previewClick(row: any) {
    //console.log("row id", row);
    let rowId = row.id;
    this.openEditModel(Number(rowId));
  }
  openEditModel(id: number): void {
    this._vehicle = new Vehicle();
    const data = this._vehicleTableData.filter(item => item.id === id)[0];
    this._vehicle = { ...data };
    this._ngbModalRef = this._modalService.open(this.addVehicleModal, { size: 'md', });
  }

  saveVehicle() {
    if (this._vehicle.name === '') {
      return;
    }
    this._vehicle.ownedBy = this._companyId;
    // console.log('vehicle', this._vehicle);
    this._inventoryService.saveVehicle(this._vehicle).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal('updated', 'Vehicle');
        this.closeModal();
        this.refreshVehicleTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating vehicle', error);
      }
    });
  }
  async updateVehicleStatus(row: any, status: any, softDelete = false) {
    const id = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this vehicle',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._inventoryService.updateVehicleStatus(id, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'vehicle')
        this.refreshVehicleTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating vehicle status:', error);
        this._appComponent.showErrorSwal('updating', 'vehicle')
      }
    });
  }

  refreshVehicleTableData() {
    this._inventoryService.getVehicle().subscribe({
      next: (data: any) => {
        var vehicleProps = new Vehicle();
        this._vehicleTableData = data;
        this._vehicleTableDataList = data;
        this._vehicleTableColumns = Object.keys(vehicleProps)
          .filter((key) =>
            key === 'name' ||
            key === 'type' ||
            key === 'modelNo' ||
            key === 'registrationNo' ||
            key === 'capacity'
          )
          .map((key) => {
            let header: string;

            switch (key) {
              case 'name':
                header = 'Name';
                break;
              case 'type':
                header = 'Type';
                break;
              case 'modelNo':
                header = 'ModelNo';
                break;
              case 'registrationNo':
                header = 'RegistrationNo';
                break;
              case 'capacity':
                header = 'Capacity';
                break;
              default:
                header = key;
                break;
            }
            return { header };
          });
      },
      error: (error: any) => {
        console.error("error getting vehicle data", error);
      }
    });
  }
}
