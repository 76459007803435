import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../app.component';
import { UserModel } from '../auth.models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {


  registerForm: FormGroup = new FormGroup({});
  // submitted = false;
  submitted = false;
  emailExists = false;
  agreeToTerms = false;
  username: string = '';
  email: string = '';
  password: string = '';
  _roleId: number = 0;
  _user = new UserModel();

  constructor(private router: Router,
    private authService: AuthService,
    private _appComponent: AppComponent,
    private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    // this.registerForm = new FormGroup({
    //   username: new FormControl('', [Validators.required]),
    //   firstname: new FormControl('', [Validators.required]),
    //   email: new FormControl('', [Validators.required, Validators.email]),
    //   password: new FormControl('', Validators.required)
    // });
    this.registerForm = this.formBuilder.group({
      myControl: new FormControl(),
      firstname: ['', Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      organization: [''],
      website: [''],
      password: ['', Validators.required],
      twoFactorEnabled: [false],
      agreeTerms: [false, Validators.requiredTrue]
    });
  }

  register() {

  }


  onRegister() {


    this.submitted = true;

    // console.log("register form", this.agreeToTerms);

    if (this.emailExists || this.agreeToTerms || !this.passwordMeetsRequirements(this.registerForm.get('password')?.value)) {
      // console.log("invalid");
      return;
    }

    const firstname = this.registerForm.get('firstname')?.value;
    const lastname = this.registerForm.get('lastname')?.value;
    const email = this.registerForm.get('email')?.value;
    const phoneNumber = this.registerForm.get('phoneNumber')?.value;
    const organization = this.registerForm.get('organization')?.value;
    const website = this.registerForm.get('website')?.value;
    const password = this.registerForm.get('password')?.value;
    const twoFactorEnabled = this.registerForm.get('twoFactorEnabled')?.value;

    // Assign values to _user object
    this._user.username = firstname + (lastname ? ' ' + lastname : '');
    this._user.fullName = firstname + (lastname ? ' ' + lastname : '');
    this._user.email = email;
    this._user.phone = phoneNumber;
    this._user.companyName = organization;
    this._user.website = website;
    this._user.password = password;
    this._user.roleId = this._roleId;
    this._user.customerId = 0;
    this._user.addressId = 0;
    this._user.isActive = false;
    this._user.twoFactorEnabled = twoFactorEnabled ? twoFactorEnabled : false;

    // console.log("user to be registered", this._user)

    this.authService.register(this._user).subscribe(
      res => {
        // Handle successful registration
        this._appComponent.showSuccessLogin("Registered Successfully");
        //localStorage.setItem('isLoggedin', 'true');

        this.redirectToLogin();

        if (localStorage.getItem('isLoggedin')) {
          this.router.navigate(['/']);
        }
      },
      err => {
        // Handle registration error
        console.error("user registration failed", err);
        this._appComponent.showFailureLogin("User Registration Failed");
      }
    );


  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  checkEmailExists(email: string | null) {
    if (!email) {
      return;
    }
    this.authService.checkEmailExists(email).subscribe({
      next: (response: boolean) => {
        this.emailExists = response;
        // console.log("checking email exists or not", response);
      },
      error: (error: any) => {
        console.error("error validating email", error);
      }
    })
  }

  passwordMeetsRequirements(password: string): boolean {
    const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  }

}
