import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UserModel } from '../auth/auth.models';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from 'src/services/contact.service';
import { UserProfileDTO } from './edit-profile.model';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { CountryOptions } from '../movd/models/address.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})

export class EditProfileComponent implements OnInit {
  @Output() profilePictureUpdated = new EventEmitter<string | null>();

  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  SignaturePad!: SignaturePad;
  // modals
  _imageModalRef: NgbModalRef | undefined;
  _tempImageToDisplay: string | null = null;

  // objects
  _user = new UserModel();
  _userProfile = new UserProfileDTO();
  _currentPassword!: string;
  _newPassword!: string;
  _confirmPassword!: string;
  countries = CountryOptions;

  //variables
  _currentUserId: number = 0;
  _emailExists: boolean = false;
  _showEditButton: boolean = false;
  _showDropdown = false;
  _showPopup = false;
  isPasswordVisible: boolean = false;
  _imageToDisplay!: string | null;
  _displayImage: boolean = true;


  _role: string | null;

  constructor(
    private _generalService: GeneralService,
    private _appComponent: AppComponent,
    private _authService: AuthService,
    private _contactService: ContactService,
    private _modalService: NgbModal,
    private _router: Router
  ) {
    this._role = localStorage.getItem('role');
  }

  ngOnInit(): void {
    this._currentUserId = Number(localStorage.getItem("currentUserId"));
    // console.log("user id", this._currentUserId);
    if (this._role && this._role === 'SuperAdmin') {
      // this.showErrorDialog('Error', 'SuperAdmin cannot edit profile');
      this.showErrorDialog('Error', 'SuperAdmin cannot edit profile').then((confirmed) => {
        if (!confirmed) {
          this._router.navigate(['/dashboard']);
        }
      });
    } else {
      setTimeout(() => {
        this.getUserById();
      }, 0);
    }
  }
  ngAfterViewInit() {
    // this.initializeSignaturePad();
  }

  initializeSignaturePad() {
    this.SignaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
  }

  clearSignature() {
    this.SignaturePad.clear();
  }
  toggleDropdown() {
    // this.isDropdownOpen = !this.isDropdownOpen;
  }

  passwordMeetsRequirements(password: string): boolean {

    const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  }

  getUserById() {
    if (this.SignaturePad) {
      this.clearSignature();
    }
    this._contactService.getUserProfile().subscribe({
      next: (response: any) => {
        if (response) {
          this._userProfile = response;
          if (this._userProfile.signature) {
            this.renderSignature(this._userProfile.signature);
          }
          localStorage.setItem('name', response.companyName);
          this._imageToDisplay = response.profileImage;

        }

      },
      error: error => {
        console.error("get user profile error", error);
      }
    });
  }
  renderSignature(signatureDataURL: string) {
    const img = new Image();

    img.onload = () => {
      if (this.SignaturePad) {
        this.SignaturePad.clear();
        this.SignaturePad.fromDataURL(signatureDataURL);
      } else {
        // console.error('SignaturePad is not defined');
      }

    };

    img.src = signatureDataURL;
  }
  openChangePasswordModal(content: any) {
    this._currentPassword = '';
    this._newPassword = '';
    this._confirmPassword = '';
    this._modalService.open(content, { centered: true, size: 'md' });
  }
  changePassword() {
    if (!this.passwordMeetsRequirements) {
      return;
    }
    this._authService.changePassword(this._currentUserId, this._currentPassword, this._newPassword).subscribe({
      next: (data) => {
        this._modalService.dismissAll('by: calling closeModal()');
        this._appComponent.showSuccessSwal('updated', 'profile');
        this.getUserById();
      },
      error: error => {
        this._appComponent.showErrorSwal('updating', 'profile');
        console.error("saving profile error", error);
      }
    });
  }

  editProfile() {
    if (this._emailExists) {
      // Handle the case when the email already exists in 'tableData'
      // You can show an error message or take appropriate action.
      // For example:
      // this.errorMessage = 'Email already exists.';
      return;
    }

    if (
      ((!this._userProfile.email || this._userProfile.email.trim().length === 0) || !this.isValidEmail(this._userProfile.email.trim()))
    ) {
      return;
    }
    // const SignatureDataURL = this.SignaturePad.toDataURL();

    // if (this.SignaturePad.isEmpty()) {
    //   this._userProfile.signature = null;
    // }
    this._userProfile.profileImage = this._imageToDisplay;

    this._contactService.updateUserProfile(this._userProfile).subscribe({
      next: response => {
        this._modalService.dismissAll('by: calling closeModal()');
        this._appComponent.showSuccessSwal('updated', 'profile');
        //this.profilePictureUpdated.emit(this._imageToDisplay);
        this._generalService.setProfilePicture(this._imageToDisplay);
        this.profilePictureUpdated.emit(this._imageToDisplay);
        // console.log("saving profile response", response);
        this.getUserById();
      },
      error: error => {
        this._appComponent.showErrorSwal('updating', 'profile');
        console.error("saving profile error", error);
      }
    });
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    // console.log("ispassword", this.isPasswordVisible);
  }

  saveImage() {

  }

  openImageModal(template: TemplateRef<any>, image: any) {
    this._imageToDisplay = image;

    // this._modalService.open(template);
    this._imageModalRef = this._modalService.open(template, {
      // backdrop: 'static',
      keyboard: false,
    });

    this._imageModalRef.result.then(
      (result) => {
      },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }
  saveSingature() {
    const signatureDataURL = this.SignaturePad.toDataURL();

    this._userProfile.signature = signatureDataURL;
    this.editProfile();
  }
  handlePictureUpload(event: any) {
    const file: File = event.target.files[0];

    // Check if a file was selected
    if (file) {
      if (file.size > 1048576) { // 1 MB in bytes
        alert("File is too large! Please select a file that is less than 1 MB.");
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // Set the _imageToDisplay to the new image source
        this._imageToDisplay = e.target.result;
        this._displayImage = true;
        this._userProfile.profileImage = this._imageToDisplay;

        this._contactService.updateUserProfile(this._userProfile).subscribe({
          next: response => {
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal('updated', 'profile');
            //this.profilePictureUpdated.emit(this._imageToDisplay);
            this._generalService.setProfilePicture(this._imageToDisplay);
            this.profilePictureUpdated.emit(this._imageToDisplay);
            // console.log("saving profile response", response);
            this.getUserById();
          },
          error: error => {
            this._appComponent.showErrorSwal('updating', 'profile');
            console.error("saving profile error", error);
          }
        });
      };

      reader.readAsDataURL(file);
    }

  }

  async removeImage() {

    let text: string = 'You are about to remove file.';
    let confirmButtonText: string = 'Yes, remove it!';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this._displayImage = false;
      this._imageToDisplay = null;
      this.editProfile();
    }
    else {
      return;
    }
  }

  async showErrorDialog(title: string, text: string) {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false,
    });

    return result.isConfirmed;
  }

}
