import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labor-setting',
  templateUrl: './labor-setting.component.html',
  styleUrls: ['./labor-setting.component.scss']
})
export class LaborSettingComponent implements OnInit {
  
  defaultNavActiveId: number = 1;


  constructor() { }

  ngOnInit(): void {

  }
  onChangeTab(_type: any) {

  }

}
