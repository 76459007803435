import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Breadcrumb, BreadcrumbService } from 'src/services/breadcrumb.service';


@Component({
  selector: 'app-title-breadcrumb',
  templateUrl: './title-breadcrumb.component.html',
  styleUrls: ['./title-breadcrumb.component.scss']
})
export class TitleBreadcrumbComponent implements OnInit {
  previousBreadcrumbs: Breadcrumb[] = [];
  title: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    // Subscribe to breadcrumbs changes
    this.breadcrumbService.breadcrumbs$.subscribe(breadcrumbs => {
      // console.log("previous breadcrumbs: ", breadcrumbs);

      this.previousBreadcrumbs = breadcrumbs;
    });

    // Subscribe to router events to get the current title and update breadcrumbs
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.activatedRoute)
    ).subscribe(route => {
      this.updateTitleAndBreadcrumbs(route);
    });
  }
  onBreadcrumbClick(breadcrumb: Breadcrumb) {
    if (breadcrumb.url) {
      this.router.navigateByUrl(breadcrumb.url);
    }
  }


  private updateTitleAndBreadcrumbs(route: ActivatedRoute): void {
    this.title = this.getTitle(route);
  }

  private getTitle(route: ActivatedRoute | null): string {
    let title = '';
    let currentRoute: ActivatedRoute | null = route;

    while (currentRoute) {
      const routeData = currentRoute.snapshot.data;
      if (routeData && routeData['title']) {
        title = routeData['title'];
        break;
      }
      currentRoute = currentRoute.firstChild;
    }

    return title;
  }
}
