import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { ContactServicePaths } from '../enums/contact-service-paths';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import { UserProfileDTO } from 'src/app/views/edit-profile/edit-profile.model';
import { TasksModel } from 'src/app/views/crm/profile/profile.models';
import { ContactsResponse, Customer } from 'src/app/views/crm/contact/contact.models';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  readonly APIUrl = environment.baseUrl;
  private _overviewListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public overviewList$: Observable<any[]> = this._overviewListSubject.asObservable();

  private _customerType: number = 0;

  setCustomerType(type: number) {
    this._customerType = type;
  }

  getCustomerType(): number {
    return this._customerType;
  }

  updateOverviewList(overviewList: any[]) {
    this._overviewListSubject.next(overviewList);
  }

  constructor(private http: HttpClient) {

    // Set the request _headers with the Authorization header
    /*if (this._token !== null) {
      this._headers = new HttpHeaders({
        'Authorization': `Bearer ${this._token}`
      });
    }*/
  }

  getUserProfile() {
    const id = localStorage.getItem('currentUserId');
    return this.http.get(this.APIUrl + '/Contact/GetUserProfile' + '/' + id);
  }

  updateUserProfile(userProfile: UserProfileDTO) {
    return this.http.post(this.APIUrl + '/Contact/UpdateUserProfile', userProfile);
  }

  getCustomerStats(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + '/Contact/GetCustomerStats' + '/' + companyId);
  }
  GetCustomersStatsForChart(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + '/Contact/GetCustomersStatsForChart' + '/' + companyId);
  }
  getActivitiesStatsForChart(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + '/Contact/GetActivitiesStatsForChart' + '/' + companyId);
  }

  // getContacts(): Observable<any[]> {
  //   const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
  //   return this.http.get<any[]>(this.APIUrl + '/Contact/GetContacts' + '/' + companyId);
  // }
  getContacts(page: number | null = null, pageSize: number | null = null, searchTerm: string | null = null, contactType: number | null = null): Observable<{ contacts: Customer[], totalRecords: number }> {
    const ownedBy = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    let params = new HttpParams()
    if (ownedBy !== null) params = params.set('ownedBy', ownedBy.toString());
    if (contactType !== null) params = params.set('contactType', contactType.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.APIUrl}/Contact/GetContacts`;
    return this.http.get<{ contacts: Customer[], totalRecords: number }>(url, { params });
  }
  getContact(id: any) {
    const url = `${this.APIUrl}/Contact/GetContact/${id}`;
    return this.http.get(url);
  }



  addContact(contact: any) {
    return this.http.post(this.APIUrl + '/Contact/AddContact', contact);
  }

  addContactWithAddress(contact: any, address: any) {
    return this.http.post(this.APIUrl + '/Contact/AddContactWithAddress', contact, address);
  }

  updateContact(contact: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateContact', contact);
  }

  updateContactFromProfile(contact: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateContactFromProfile', contact);
  }

  deleteContact(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteContact/${id}`;
    return this.http.post(url, {});
  }

  getAddressByContactId(id: any) {
    const url = `${this.APIUrl}/Contact/GetAddressByContactId/${id}`;
    return this.http.get(url);
  }
  //end contact
  addCustomerCall(customerCall: any) {
    return this.http.post(this.APIUrl + '/Contact/AddCall', customerCall);
  }

  getCustomerCall(id: any) {
    const url = `${this.APIUrl}/Contact/GetCall/${id}`;
    return this.http.get(url);
  }
  getCustomerCalls(id: any, isdeal: boolean, isMove: boolean) {
    const url = `${this.APIUrl}/Contact/GetCalls/${id}?isdeal=${isdeal}`;
    return this.http.get(url);
  }
  updateCustomerCall(customerCall: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateCall', customerCall);
  }

  deleteCustomerCall(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteCall/${id}`;
    return this.http.post(url, {});
  }
  //end calls
  addCustomerEmail(customerEmail: any) {
    return this.http.post(this.APIUrl + '/Contact/AddEmail', customerEmail);
  }
  getCustomerEmail(id: any) {
    const url = `${this.APIUrl}/Contact/GetEmail/${id}`;
    return this.http.get(url);
  }

  getCustomerEmails(id: any, isdeal: boolean, isMove: boolean) {
    const url = `${this.APIUrl}/Contact/GetEmails/${id}?isdeal=${isdeal}`;
    return this.http.get(url);
  }
  updateCustomerEmail(customerEmail: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateEmail', customerEmail);
  }

  deleteCustomerEmail(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteEmail/${id}`;
    return this.http.post(url, {});
  }
  //end email
  addCustomerNote(customerNote: any) {
    //console.log("customerNote", customerNote);
    return this.http.post(this.APIUrl + '/Contact/AddNote', customerNote);
  }

  getCustomerNote(id: any) {
    const url = `${this.APIUrl}/Contact/GetNote/${id}`;
    return this.http.get(url);
  }
  getCustomerNotes(id: any, isdeal: boolean) {
    const url = `${this.APIUrl}/Contact/GetNotes/${id}?isdeal=${isdeal}`;
    return this.http.get(url);
  }
  updateCustomerNote(customerNote: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateNote', customerNote);
  }

  deleteCustomerNote(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteNote/${id}`;
    return this.http.post(url, {});
  }

  //end note
  addCustomerAttachment(customerAttachment: any) {
    return this.http.post(this.APIUrl + '/Contact/AddAttachment', customerAttachment);
  }

  getCustomerAttachment(id: any) {
    const url = `${this.APIUrl}/Contact/GetAttachment/${id}`;
    return this.http.get(url);
  }
  getCustomerAttachments(id: any, isdeal: boolean) {
    const url = `${this.APIUrl}/Contact/GetAttachments/${id}?isdeal=${isdeal}`;
    return this.http.get(url);
  }

  updateAttachment(customerAttachment: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateAttachment', customerAttachment);
  }

  deleteAttachment(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteAttachment/${id}`;
    return this.http.delete(url);
  }
  //end attachment
  addCustomerActivity(customerActivity: any) {
    return this.http.post(this.APIUrl + '/Contact/AddActivity', customerActivity);
  }

  getCustomerActivity(id: any) {
    const url = `${this.APIUrl}/Contact/GetActivity/${id}`;
    return this.http.get(url);
  }
  getCustomerActivities(id: any) {
    const url = `${this.APIUrl}/Contact/GetActivities/${id}`;
    return this.http.get(url);
  }
  updateCustomerActivity(customerActivity: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateActivity', customerActivity);
  }

  deleteCustomerActivity(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteActivity/${id}`;
    return this.http.delete(url);
  }
  //end customer activity
  addRole(Addrole: any) {
    return this.http.post(this.APIUrl + '/Contact/addRole', Addrole);
  }

  updateRole(role: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateRole', role);
  }

  updateRoleStatus(roleId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Contact/UpdateRoleStatus?id=${roleId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }

  getRole() {
    return this.http.get(this.APIUrl + '/Contact/GetRole');
  }

  // getRoles(id:any): Observable<any[]> {
  //   return this.http.get<any>(this.APIUrl + ContactServicePaths.getRoles + "/" + id);
  // }
  addIndustry(industry: any) {
    return this.http.post(this.APIUrl + '/Contact/AddIndustry', industry);
  }

  getIndustries(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + '/Contact/GetIndustries');
  }

  updateIndustry(industry: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateIndustry', industry);
  }

  updateIndustryStatus(industryId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Contact/UpdateIndustryStatus?id=${industryId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }

  updateActivity(customerActivity: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateActivity', customerActivity);
  }

  deleteActivity(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteActivity/${id}`;
    return this.http.delete(url);
  }
  getCompanies() {
    const ownedBy = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get(this.APIUrl + '/Contact/GetCompanies?ownedby=' + ownedBy);
  }


  getRoles(id: any) {
    const url = `${this.APIUrl}/Contact/GetRoles/${id}`;
    return this.http.get(url);
  }

  getUserRoles(id: any) {
    const url = `${this.APIUrl}/Contact/GetUserRoles/${id}`;
    return this.http.get(url);
  }

  //end role
  addMeeting(addmeeting: any) {
    return this.http.post(this.APIUrl + '/Contact/AddMeeting', addmeeting);
  }

  getMeeting(id: any) {
    const url = `${this.APIUrl}/Contact/GetMeeting/${id}`;
    return this.http.get(url);
  }

  getMeetings(id: any, isDeal: boolean, isMove: boolean): Observable<any[]> {
    const url = `${this.APIUrl}/Contact/GetMeetings/${id}?isDeal=${isDeal}`;
    return this.http.get<any[]>(url);
  }

  updateMeeting(updateMeeting: any) {
    return this.http.post(this.APIUrl + '/Contact/UpdateMeeting', updateMeeting);
  }

  deleteMeeting(id: any) {
    const url = `${this.APIUrl}/Contact/DeleteMeeting/${id}`;
    return this.http.post(url, {});
  }

  addTask(task: any) {
    //console.log("task", task)
    return this.http.post(this.APIUrl + '/Contact/AddTask', task);
  }

  //getTasks(id: any, isdeal: boolean): Observable<any[]> {
  //  return this.http.get<any>(this.APIUrl + ContactServicePaths.getTasks.replace('{id}', id));
  //}
  // getTasks(id: any, isDeal: boolean): Observable<any[]> {
  //   const url = `${this.APIUrl}/Contact/GetTasks/${id}?isDeal=${isDeal}`;
  //   return this.http.get<any[]>(url);
  // }
  getTasks(
    id: any, isDeal: boolean, page: number | null = null, pageSize: number | null = null, searchTerm: string | null = null, isDone: boolean = false): Observable<{ tasks: TasksModel[], totalRecords: number }> {
    let params = new HttpParams()
      .set('id', id.toString())
      .set('isDeal', isDeal.toString())
      .set('isDone', isDone.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.APIUrl}/Contact/GetTasks`;
    return this.http.get<{ tasks: TasksModel[], totalRecords: number }>(url, { params });
  }

  updateTask(updatedTask: any) {
    /*   console.log("update task", updatedTask)*/
    return this.http.post(this.APIUrl + '/Contact/UpdateTask', updatedTask);
  }
  updateTaskStatus(id: number, status: boolean): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString());
    return this.http.post(this.APIUrl + `/Contact/UpdateTaskStatus`, null, { params });
  }

  //deleteTask(id: any) {
  //  return this.http.delete(this.APIUrl + '/Contact/DeleteTask/{id}'.replace('{id}', id));
  //}
  deleteTask(id: any) {
    const params = new HttpParams().set('id', id.toString());
    return this.http.post(`${this.APIUrl}/Contact/DeleteTask`, {}, { params });
  }

  //getLatestActivities(id: any, isdeal: boolean) {
  //  return this.http.get<any>(this.APIUrl + ContactServicePaths.getLatestActivities.replace('{id}', id)}?isDeal = ${ isDeal };
  //}

  getLatestActivities(id: any, isDeal: boolean): Observable<any[]> {
    const url = `${this.APIUrl}/Contact/GetLatestActivites/${id}?isDeal=${isDeal}`;
    return this.http.get<any[]>(url);
  }


  Export(jsonData: any, headers: string[], filename = 'Roster-CSV', fileExtension: string) {

    let formattedData;

    if (fileExtension === 'csv') {
      formattedData = this.ConvertToCSV(jsonData, headers);
    } else if (fileExtension === 'xlsx') {
      formattedData = this.ConvertToXLSX(jsonData, headers);
    } else if (fileExtension === 'txt') {
      formattedData = this.ConvertToTXT(jsonData, headers);
    } else if (fileExtension === 'pdf') {
      formattedData = this.ConvertToPDF(jsonData, headers, filename);
    }
    else {
      console.error('Unsupported file extension.');
      return;
    }


    let blob = new Blob(['\ufeff' + formattedData], { type: `text/${fileExtension};charset=utf-8;` });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

    if (isSafariBrowser) { // If Safari, open in a new window to save the file with a random filename.
      dwldLink.setAttribute('target', '_blank');
    }

    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', `${filename}.${fileExtension}`);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);

  }
  ConvertToPDF(jsonData: any[], headers: string[], filename: string) {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    // Set font styles
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');

    // Set the position for the _headers
    let headerX = 10;
    let headerY = 20;

    // Set the position for the data
    let dataX = 10;
    let dataY = 30;

    // Add the _headers to the PDF
    doc.text(headers, headerX, headerY);

    // Format the content as required for the PDF
    const content = this.formatContent(jsonData, headers);

    // Split the content into lines
    const lines = doc.splitTextToSize(content, 250);

    // Calculate the number of lines that fit on a page
    const linesPerPage = doc.internal.pageSize.height - dataY;

    // Determine the number of pages required
    const totalPages = Math.ceil(lines.length / linesPerPage);

    // Add the data to the PDF
    let currentLine = 0;
    for (let page = 1; page <= totalPages; page++) {
      doc.text(lines.slice(currentLine, currentLine + linesPerPage), dataX, dataY);
      currentLine += linesPerPage;

      // If there are more lines, add a new page
      if (currentLine < lines.length) {
        doc.addPage();
      }
    }

    // Save the PDF with the specified filename
    doc.save(`${filename}.pdf`);
  }
  formatContent(jsonData: any[], headers: string[]): string {
    const lineDelimiter = '\n';

    // Generate the header row
    let txt = headers.join('\t');
    txt += lineDelimiter;

    // Generate the data rows
    for (const item of jsonData) {
      let row = '';
      for (const header of headers) {
        if (item.hasOwnProperty(header)) {
          row += `${item[header]}\t`;
        } else {
          row += '\t';
        }
      }
      txt += row.trim(); // Trim the trailing tabs
      txt += lineDelimiter;
    }

    return txt;
  }


  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        //let value=array[i][head]=='undefined' || array[i][head]==null? '':array[i][head];
        // added fpr commas in data
        let value = array[i][head] == 'undefined' || array[i][head] == null ? '' : '\"' + array[i][head] + '\"';
        line += value + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }


  ConvertToXLSX(jsonData: any[], headers: string[]): string {
    // Import the required modules


    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a new worksheet and add the _headers
    const worksheet = XLSX.utils.json_to_sheet([headers]);

    // Convert the JSON data to an array of objects
    const data = jsonData.map(item => {
      const obj = {} as any;
      for (const header of headers) {
        obj[header] = item[header] || '';
      }
      return obj;
    });

    // Add the data to the worksheet
    XLSX.utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: 'A2' });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate the XLSX binary data
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    return excelData;
  }

  ConvertToTXT(jsonData: any[], headers: string[]): string {
    const columnDelimiter = '\t';
    const lineDelimiter = '\n';

    // Generate the TXT header row
    let txt = headers.join(columnDelimiter);
    txt += lineDelimiter;

    // Generate the TXT data rows
    for (const item of jsonData) {
      let row = '';
      for (const header of headers) {
        if (item.hasOwnProperty(header)) {
          row += `${item[header]}${columnDelimiter}`;
        } else {
          row += `${columnDelimiter}`;
        }
      }
      txt += row.slice(0, -1); // Remove the trailing delimiter
      txt += lineDelimiter;
    }

    return txt;
  }


}
