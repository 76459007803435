import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { MovdService } from '../../services/movd.service';
import { eAttachmentType } from '../../models/move-attachments.model';
import { AppComponent } from 'src/app/app.component';
import { RequiredDocument } from '../../models/required-document.model';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';

@Component({
  selector: 'app-documents-setting',
  templateUrl: './documents-setting.component.html',
  styleUrls: ['./documents-setting.component.scss']
})

export class DocumentsSettingComponent implements OnInit {

  @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('addDocumentModal') addDocumentModal!: any;

  defaultNavActiveId: number = 1;
  ColumnMode = ColumnMode;
  selectedTabType: number = 20;
  _saveTitle = "Create";
  _title = "Add New Document";
  _documentTitle!: string | null;
  _requiredDocument = new RequiredDocument();
  documentExists: boolean = false;

  displayedColumns = [
    { prop: 'title', name: 'Title' },
    { prop: 'all', name: 'All' },
    { prop: 'doorToDoorLocal', name: 'Door to Door (Local)' },
    { prop: 'doorToPort', name: 'Door to Port' },
    { prop: 'portToDoor', name: 'Port to Door' },
    { prop: 'doorToDoorImport', name: 'Door to Door (Import)' },
    { prop: 'doorToDoorExport', name: 'Door to Door (Export)' },
  ];

  dataTable: any[] = [];

  _documentModalRef: NgbModalRef | undefined;

  _clientAttachment = eAttachmentType.ClientDocuments;
  _moveAttachment = eAttachmentType.MoveDocuments;

  constructor(private modalService: NgbModal, private _movdService: MovdService
    , private _appComponent: AppComponent, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Documents Settings', url: '/documents-setting' },


    ]);

    this.getRequiredDocuments();
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  openClientAddDocModal() {
    this._requiredDocument = new RequiredDocument();
    this._saveTitle = 'Save';
    this._title = 'Add New Document';
    this._documentModalRef = this.modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false
    });
  }

  openMoveAddDocModal() {
    this._requiredDocument = new RequiredDocument();
    this._saveTitle = 'Save';
    this._title = 'Add New Document';
    this._documentModalRef = this.modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false
    });
  }

  ClientDocPreview(id: number) {
    this.getClientDocAndOpenModal(Number(id));
  }

  getClientDocAndOpenModal(id: number): void {
    this.documentExists = false;
    const data = this.dataTable.filter(item => item.id === id)[0];
    if (data) {
      this._requiredDocument = data;
      this._documentTitle = this._requiredDocument.title;
      this.openEditClientDoc();
    }
  }

  openEditClientDoc() {
    this._saveTitle = 'Update';
    this._title = 'Update Document Name';
    this._documentModalRef = this.modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false,
    });
    this._documentModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  MoveDocPreview(id: number) {
    this.getClientDocAndOpenModal(Number(id));
  }

  getMoveDocAndOpenModal(id: number): void {
    // this.areaExists = false;
    const data = this.dataTable.filter(item => item.id === id)[0];
    if (data) {
      this._requiredDocument = data;
      this._documentTitle = this._requiredDocument.title;
      this.openEditClientDoc();
    }
  }

  openEditMoveDoc() {
    this._saveTitle = 'Update';
    this._title = 'Update Document Name';
    this._documentModalRef = this.modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false,
    });
    this._documentModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  saveAndCloseClientDoc(modal: any): void {
    if (this._documentTitle === null || this.documentExists === true) {
      return;
    }

    this._requiredDocument.title = this._documentTitle;
    this._requiredDocument.type = this.selectedTabType;
    const companyId = Number(localStorage.getItem('companyId'));
    this._requiredDocument.ownedBy = companyId;
    this._requiredDocument.createdBy = companyId;
    // console.log('client _requiredDocument saving', this._requiredDocument);
    this._movdService.saveRequiredDocument(this._requiredDocument).subscribe({
      next: (response: any) => {

        this._documentTitle = null;
        this._documentModalRef?.close();
        //console.log('Save document response', response);
        this._appComponent.showSuccessSwal('created', 'record');
        this.getRequiredDocuments();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('creating', 'record');
        console.error('Error', error);
      }
    });

  }

  async updateDocumentStatus(row: any, status: any, softDelete = false) {
    const documentId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this document',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._movdService.updateDocumentStatus(documentId, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'document');
        this.getRequiredDocuments();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
        // console.log('area status updated', response);
      },
      error: (error: any) => {
        console.error('Error updating area status:', error);
        this._appComponent.showErrorSwal('updating', 'area');
      }
    });
  }

  validateItemExists(): void {
    const input = this._documentTitle;
    if (!input || input.trim().length === 0) {
      // Invalid or empty email format, reset 'emailExists' and return.
      this.documentExists = false;
      return;
    }

    if (this.dataTable && this.dataTable.length > 0) {
      this.documentExists = this.dataTable.some(item => item.title.toLowerCase() === input.toLowerCase());

    } else {
      this.documentExists = false; // Reset 'emailExists' if 'tableData' is empty
    }
  }

  onClickChange(row: any, prop: string): void {
    row[prop] = !row[prop];

    if (
      row.doorToDoorLocal !== null &&
      row.doorToPort !== null &&
      row.portToDoor !== null &&
      row.doorToDoorImport !== null &&
      row.doorToDoorExport !== null &&
      row.doorToDoorLocal &&
      row.doorToPort &&
      row.portToDoor &&
      row.doorToDoorImport &&
      row.doorToDoorExport
    ) {
      row.all = true;
    } else {
      row.all = false;
    }

    const updateData = {
      id: row.id,
      title: row.title,
      all: row.all,
      doorToDoorLocal: row.doorToDoorLocal,
      doorToPort: row.doorToPort,
      portToDoor: row.portToDoor,
      doorToDoorImport: row.doorToDoorImport,
      doorToDoorExport: row.doorToDoorExport,
      type: this.selectedTabType,
      modificationDate: new Date(),
      modifiedBy: row.ownedBy,
    };

    //console.log('updating checkbox', updateData);

    this._movdService.saveRequiredDocument(updateData).subscribe({
      next: (response) => {
        // console.log('Backend data updated successfully:', response);
      },
      error: (error) => {
        console.error('Error updating backend data:', error);
      }
    });

  }

  onAllClickChange(row: any, prop: string): void {
    row[prop] = !row[prop];
    if (row[prop]) {
      row.doorToDoorLocal = true;
      row.doorToPort = true;
      row.portToDoor = true;
      row.doorToDoorImport = true;
      row.doorToDoorExport = true;
    } else {
      row.doorToDoorLocal = false;
      row.doorToPort = false;
      row.portToDoor = false;
      row.doorToDoorImport = false;
      row.doorToDoorExport = false;
    }
    const updateData = {
      id: row.id,
      title: row.title,
      all: row.all,
      doorToDoorLocal: row.doorToDoorLocal,
      doorToPort: row.doorToPort,
      portToDoor: row.portToDoor,
      doorToDoorImport: row.doorToDoorImport,
      doorToDoorExport: row.doorToDoorExport,
      type: this.selectedTabType,
      modificationDate: new Date(),
    };

    // console.log('updating checkbox', updateData);

    this._movdService.saveRequiredDocument(updateData).subscribe({
      next: (response) => {
        // console.log('Backend data updated successfully:', response);
      },
      error: (error) => {
        console.error('Error updating backend data:', error);
      }
    });

  }

  getRequiredDocuments(): void {
    this._movdService.getRequiredDocuments(this.selectedTabType).subscribe({
      next: (data) => {
        if (data) {
          this.dataTable = data;
        }
      },
      error: (error) => {
        console.error('Error fetching document settings:', error);
      }
    });
  }

  onTabChange(type: any): void {
    if (type === this._clientAttachment) {
      this.selectedTabType = this._clientAttachment;
    } else if (type === this._moveAttachment) {
      this.selectedTabType = this._moveAttachment;
    }

    this.getRequiredDocuments();
    //console.log('Selected tab type:', this.selectedTabType);
  }
}
