import { Component, OnInit, TemplateRef } from '@angular/core';
import { ContactService } from 'src/services/contact.service';
import { CustomerStatsDTO, DealsStatsDTO } from 'src/app/views/dashboard/dashboard.models';
import { MovdService } from '../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TasksModel, TasksResponse } from 'src/app/views/crm/profile/profile.models';
import { CalendarOptions } from '@fullcalendar/angular';
import { DashboardItem } from '../../models/dashboard-item.model';
import { Observable } from 'rxjs';
import { DashboardStats } from '../../models/dashboardStats.model';
import { GeneralService } from 'src/services/general.service';
import { formatDate } from '@angular/common';
import { LeadBoardDTO, LeadBoardResponse } from '../../lead-board/lead-board.model';


@Component({
  selector: 'app-move-manager-dashboard',
  templateUrl: './move-manager-dashboard.component.html',
  styleUrls: ['./move-manager-dashboard.component.scss']
})
export class MoveManagerDashboardComponent implements OnInit {

  _localCustomerId = 0;

  public donutChartOptionsMoves: any;
  public donutChartOptionsLeads: any;
  public lineChartOptions: any;
  public pieChartOptions: any;
  public barChartOptions: any;
  defaultNavActiveId = 1;
  _currentTabId = 1;
  dashboardItems: DashboardItem[] = [];
  _allLeadsList: LeadBoardDTO[] = [];
  calendarOptions: CalendarOptions | undefined;
  _isLoading: boolean = false;
  isDataLoaded = true;
  // _customerStats: CustomerStatsDTO[] = [];
  public customersChartOptions: any = {};
  _dealsStats: DealsStatsDTO[] = [];
  _dashboardStats: DashboardStats = new DashboardStats();
  _tasksList: TasksModel[] = [];
  _task = new TasksModel();
  _companyId: number = 0;
  _currentUserId: number = 0;
  _taskUpdate: boolean = false;
  _isDeal: boolean = false;
  _saveTaskTitle: string = 'Create';
  // for showing current time
  selectedDate!: string;
  selectedTime!: string;
  hoveredDate: NgbDateStruct | null = null;
  _statsFromDate: string | Date | null = null;
  _statsToDate: string | Date | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  constructor(private _movdService: MovdService,
    private _contactService: ContactService, private _appComponent: AppComponent,
    private _modalService: NgbModal, public generalService: GeneralService,
    private calendar: NgbCalendar,) {
    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this._localCustomerId = Number(localStorage.getItem('customerId'));
  }

  ngOnInit(): void {
    this.calculateDateRange();
    this.getLeadsForBoardById();
    this.fetchData('Task');
    this.donutChartOptionsLeads = this.generalService.getDonutChartOptions('leads', this._dashboardStats);
    this.donutChartOptionsMoves = this.generalService.getDonutChartOptions('moves', this._dashboardStats);
    this.lineChartOptions = this.generalService.getLineChartOptions(this._dashboardStats.dashboardCSATDTO ?? []);
    this.pieChartOptions = this.generalService.getPieChartOptions(this._dashboardStats);
    this.barChartOptions = this.generalService.getBarChartOptions(this._dashboardStats);

  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }
  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
  getLeadsForBoardById() {
    this._movdService.getLeadsForBoard(null, null, null).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log("leads for baord", data);
        if (data.leads && data.leads.length > 0) {
          this._allLeadsList = data.leads;
          // console.log('survey board', this._allLeadsList);
          this.calendarOptions = {
            initialView: 'dayGridMonth', // Set the initial view
            events: this.getEvents(), // Call a function to fetch and format your events
          };
          this.dashboardItems = this.createDashboardItems(this._allLeadsList);
          // console.log("dashboard items", this.dashboardItems);
        }
      },
      error: (error) => {
        console.error("error getting lead for board", error);
      }
    });
  }
  calculateDateRange() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this._statsToDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    this._statsFromDate = formatDate(firstDayOfMonth, 'yyyy-MM-dd', 'en-US');
    this.getDashboardStats();
  }
  getDashboardStats() {
    this._movdService.getDashboardStats(this._statsFromDate, this._statsToDate).subscribe({
      next: (data: any) => {
        // console.log("dashboard stats", data);
        if (data) {
          this._dashboardStats = data;
          // Set chart options only if data is available
          this.donutChartOptionsLeads = this.generalService.getDonutChartOptions('leads', this._dashboardStats);
          this.donutChartOptionsMoves = this.generalService.getDonutChartOptions('moves', this._dashboardStats);
        }

        this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._statsFromDate, null));
        this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._statsToDate));
        // console.log("dashboard stats", this._dashboardStats);

        if (data && data.dashboardCSATDTO) {
          this.lineChartOptions = this.generalService.getLineChartOptions(data && data.dashboardCSATDTO);
        }
      },
      error: (error) => {
        console.error("error getting dashboard stats", error);
      }
    });
  }
  createDashboardItems(leadData: any): any[] {
    const dashboardItems: any[] = [];

    // Get the current date for reference
    const currentDate = new Date();

    // Define types for the dashboard items
    const itemTypes = ['weekSurvey', 'daySurvey', 'weekPacking', 'dayPacking'];

    // Loop through the defined item types
    for (const itemType of itemTypes) {
      let content: string | undefined; // Default content if not found
      let date: string = ''; // Default date if not found
      let time: string = '';
      let leadDetailId: number = 0;
      // Loop through the lead data to find matching items
      leadData.forEach((lead: any) => {
        if (lead.surveyDate && this.isDateInCurrentWeek(new Date(lead.surveyDate)) && itemType === 'weekSurvey') {
          content = `Move # ${lead.leadDetailId} - ${lead.customerName}`;
          date = this.formatDateForDashCards(lead.surveyDate);
          time = this.formatTimeForDashCards(lead.surveyDate);
          leadDetailId = lead.leadDetailId;
        }

        if (lead.surveyDate && this.isSameDay(new Date(lead.surveyDate), currentDate) && itemType === 'daySurvey') {
          content = `Move # ${lead.leadDetailId} - ${lead.customerName}`;
          date = this.formatDateForDashCards(lead.surveyDate);
          time = this.formatTimeForDashCards(lead.surveyDate);
          leadDetailId = lead.leadDetailId;

        }

        // show packing when move is initiated
        if (lead.moveInitiated === true) {
          if (lead.packagingDateFrom && this.isDateInCurrentWeek(new Date(lead.packagingDateFrom)) && itemType === 'weekPacking') {
            content = `Move # ${lead.leadDetailId} - ${lead.customerName}`;
            date = this.formatDateForDashCards(lead.packagingDateFrom);
            time = this.formatTimeForDashCards(lead.packagingDateFrom);
            leadDetailId = lead.leadDetailId;

          }

          if (lead.packagingDateFrom && this.isSameDay(new Date(lead.packagingDateFrom), currentDate) && itemType === 'dayPacking') {
            content = `Move # ${lead.leadDetailId} - ${lead.customerName}`;
            date = this.formatDateForDashCards(lead.packagingDateFrom);
            time = this.formatTimeForDashCards(lead.packagingDateFrom);
            leadDetailId = lead.leadDetailId;

          }

        }
      });


      // Create the dashboard item
      const dashboardItem = new DashboardItem(itemType, content, date, time, leadDetailId);

      // Add the dashboard item to the array
      dashboardItems.push(dashboardItem);
    }

    return dashboardItems;

  }
  getEvents() {
    // Initialize an array to store FullCalendar events
    const eventData: { title: string; start: any; end: any; color: string; }[] = [];

    this._allLeadsList.forEach((lead) => {
      if (lead.surveyDate) {
        eventData.push({
          title: 'Survey',
          start: lead.surveyDate,
          end: lead.surveyDate,
          color: 'blue',
        });
      }

      if (lead.packagingDateFrom) {
        eventData.push({
          title: 'Packing',
          start: lead.packagingDateFrom,
          end: lead.packagingDateTo, // You may want to use lead.packagingDateTo for the end date
          color: 'green', // Customize event color if needed
        });
      }
    });

    return eventData;
  }
  isDateInCurrentWeek(dateToCheck: Date): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDateOfMonth = currentDate.getDate();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday) for the current date
    // const currentDayOfWeek = currentDate.getDay();
    // to consider monday as first day of week
    const currentDayOfWeek = (currentDate.getDay() + 6) % 7; // Adjust Sunday to 6

    // Calculate the start date of the current week (assuming Sunday is the first day of the week)
    const startDateOfWeek = new Date(currentYear, currentMonth, currentDateOfMonth - currentDayOfWeek);

    // Calculate the end date of the current week
    const endDateOfWeek = new Date(currentYear, currentMonth, currentDateOfMonth + (6 - currentDayOfWeek));

    // Check if the date falls within the current week
    return dateToCheck >= startDateOfWeek && dateToCheck <= endDateOfWeek;
  }
  formatDateForDashCards(dateString: Date | string) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return formattedDate;
  }
  formatTimeForDashCards(dateString: Date | string) {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    return formattedTime;
  }
  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  todoTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => task.done);
  }
  // Inside your component class
  doneTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => !task.done);
  }

  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;
    entity.customerId = this._currentUserId;

    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this.selectedDate}T${this.selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();



    // console.log('task data saviing', this._task.dueDate)


    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);
          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }
  async fetchData(type: string): Promise<void> {
    try {
      // console.log('fetching tasks');
      let data: TasksResponse | undefined;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          data = await this._contactService.getTasks(this._currentUserId, this._isDeal, null, null, null).toPromise();
          // this._tasksList = data;
          break;
      }
      if (data && data.tasks && data.tasks.length > 0) {
        this._tasksList = data.tasks;
        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        (this as any)[listName] = data;

        (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = this._tasksList.length === 1 ? this._tasksList[0] : this._tasksList[this._tasksList.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }
      }
      // console.log('fetching tasks lissts', this._tasksList);
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }
  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }

  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }
  openTasksModal(content: TemplateRef<any>, id = 0) {
    this._task = new TasksModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);
  }
  openModal(content: TemplateRef<any>, title: string, id: number) {

    //console.log("id", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }
  editTask(dataItem: any) {
    this._task.id = dataItem.id;
    this._task.title = dataItem.title;
    this._task.details = dataItem.details;
    this._task.activityDate = dataItem.activityDate;
    this._task.status = dataItem.status;
    this._task.assignedto = dataItem.assignedto;
    this._task.dueDate = this.formatTaskDate(dataItem.dueDate);
    this._task.done = dataItem.done;
    this._task.priority = dataItem.priority;
    this._task.queue = dataItem.queue;
    this._task.type = dataItem.type;

  }
  async deleteTask(id: any) {
    await this.confirmAndDelete("Task", id);
  }

  async confirmAndDelete(type: string, id: any) {
    const confirmation = confirm(`Are you sure you want to delete this ${type}?`);

    if (!confirmation) {
      return;
    }

    try {
      switch (type) {
        case 'Task':
          await this._contactService.deleteTask(id).toPromise();
          break;
        default:
          break;
      }

    } catch (error: any) {
      if (error.status === 200) {
        this._appComponent.showSuccessSwal("Deleted", type);
        if (type === 'Task') {
          await this.fetchData('Task');
        }
        else {
          this._appComponent.showErrorSwal("Deleting", type);
        }
      }
    }
  }
  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }
    this.saveData(this._task, 'Task');
  }
  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }
  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }
  getNameFromLocalStorage(): string | null {
    if (localStorage.getItem('role') == 'SuperAdmin') {
      return "Techflo";
    }
    return window.localStorage.getItem('name');
  }
  onDateSelect(date: any) {
    const selectedDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    this.hoveredDate = { year: date.year, month: date.month, day: date.day };
    if (!this._statsFromDate && !this._statsToDate) {
      this._statsFromDate = selectedDate;
    } else if (
      this._statsFromDate &&
      !this._statsToDate &&
      selectedDate > this._statsFromDate
    ) {
      this._statsToDate = selectedDate;
    } else {
      this._statsFromDate = selectedDate;
      this._statsToDate = null;
    }
    this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._statsFromDate, null));
    this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._statsToDate));
    if (this._statsFromDate && this._statsToDate) {
      const statsDateFrom = new Date(this._statsFromDate);
      const statsDateTo = new Date(this._statsToDate);
      this._statsFromDate = statsDateFrom;
      this._statsToDate = statsDateTo;
    }
    this.getDashboardStats();
  }
  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }
  isHovered(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._statsFromDate);
    const toDate = this.convertDateToNgbDate(this._statsToDate);
    const hoveredDate = this.hoveredDate;

    if (!hoveredDate) {
      return false;
    }
    return fromDate && !toDate && date.after(fromDate) && date.before(toDate) && date.equals(hoveredDate);
  }

  isInside(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._statsFromDate);
    const toDate = this.convertDateToNgbDate(this._statsToDate);
    return fromDate && toDate && date.after(fromDate) && date.before(toDate);
  }

  isRange(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._statsFromDate);
    const toDate = this.convertDateToNgbDate(this._statsToDate);
    if (fromDate && toDate) {
      return date.equals(fromDate) || date.equals(toDate) || this.isInside(date) || this.isHovered(date) || this.isRangeSelected(date, fromDate, toDate);
    }

    return false;
  }
  isRangeSelected(date: NgbDate, fromDate: NgbDate, toDate: NgbDate) {
    if (!fromDate || !toDate) {
      return false;
    }

    return date.after(fromDate) && date.before(toDate);
  }
}
