import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../../services/auth.service';
import { ContactService } from '../../../../../services/contact.service';
import { AppComponent } from '../../../../app.component';

import { TasksModel, TasksResponse } from '../profile.models';
import { Observable } from 'rxjs';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BreadcrumbService } from 'src/services/breadcrumb.service';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss']
})
export class TaskBoardComponent implements OnInit {
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  _tasksList: TasksModel[] = [];
  // _filteredTasksList: TasksModel[] = [];
  filteredTasks: any[] = [];
  _customerId: number = 0;
  _currentUserId: number = 0;
  @Input() isDeal: any = false;
  loadingIndicator: boolean = true;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  _task = new TasksModel();
  _selectedDate!: string;
  _selectedTime!: string;
  _tabType: string = 'to-do';
  _saveTaskTitle: string = 'Create';
  _taskUpdate = false;
  _companyUsers: any[] = [];
  _selectedUserId: number | null = 0;
  _selectedCustomerId: number | null = 0;

  _searchTerm: string = '';
  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _isDone: boolean = false;

  constructor(
    private _appComponent: AppComponent,
    private _contactService: ContactService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private calendar: NgbCalendar,
    private breadcrumbService: BreadcrumbService,) {
    this._customerId = Number(localStorage.getItem('customerId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);


  }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Tasks', url: '/task-board' },

    ]);


    this.getCompanyUsers();
    this.fetchData('Task');

  }

  async fetchData(type: string): Promise<void> {
    try {
      let data: TasksResponse | undefined;
      switch (type) {
        case 'Task':
        case 'TaskModel':
          data = await this._contactService.getTasks(this._currentUserId, this.isDeal, this._currentPage, this._pageSize, this._searchTerm, this._isDone).toPromise();
          break;
      }

      if (data && data.tasks && data.tasks.length > 0) {
        this._tasksList = data.tasks;
        this._totalRecords = data.totalRecords;

        // Filter tasks based on the selected tab
        if (this._tabType === 'to-do') {
          this._tasksList = this._tasksList.filter(task => !task.done);
        } else if (this._tabType === 'done') {
          this._tasksList = this._tasksList.filter(task => task.done);
        } else if (this._tabType === 'assigned') {
          console.log('Current User ID:', this._currentUserId); // Debug log
          this._tasksList = this._tasksList.filter(task => {
            console.log('Task Assigned To:', task.assignedto); // Debug log
            return task.assignedto === this._currentUserId;
          });
        }

        // Sort tasks by activityDate
        this._tasksList.sort((a: TasksModel, b: TasksModel) => {
          const dateA = new Date(a.activityDate);
          const dateB = new Date(b.activityDate);
          return dateB.getTime() - dateA.getTime(); // Sort in descending order
        });

        // Calculate timeAgo for the most recent task
        const mostRecentData = this._tasksList.length === 1 ? this._tasksList[0] : this._tasksList[this._tasksList.length - 1];
        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }

        // Get company users
        const companyUsers = await this._authService.getUsersOfCompany().toPromise();

        console.log("companyUsers", companyUsers);

        // Assign user names to tasks
        for (let task of this._tasksList) {
          if (companyUsers && companyUsers.length > 0) {
            const assignedUser = companyUsers.find(user => user.id === task.assignedto);
            task.assignedToName = assignedUser ? assignedUser.customerName : null;
          } else {
            task.assignedToName = null;
          }
        }
      } else {
        this._tasksList = [];
        this._totalRecords = 0;
      }
    } catch (error) {
      console.error(`Error fetching ${type}s:`, error);
    }
  }
  async deleteTask(id: number): Promise<void> {
    const confirmation = await this._appComponent.showConfirmationDialog(
      'Are you sure?',
      'Do you want to delete this task?',
      'Yes, delete it!'
    );

    if (confirmation) {
      this._contactService.deleteTask(id).subscribe(
        () => {
          console.log('Task deleted successfully');
          this.fetchData('Task'); // Refresh the data
          this._appComponent.showSuccessSwal('deleted', 'Task');
        },
        (error: any) => {
          console.error('Error deleting task:', error);
          this._appComponent.showErrorSwal('deleting', 'Task');
        }
      );
    }
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }

  onTabChange(type: string) {
    console.log('Tab Changed To:', type); // Debug log

    if (type === 'to-do') {
      this._tabType = 'to-do';
      this._isDone = false;
      this.fetchData('Task');
    } else if (type === 'done') {
      this._tabType = 'done';
      this._isDone = true;
      this.fetchData('Task');
    } else if (type === 'assigned') {
      this._tabType = 'assigned';
      this._isDone = false; // Reset _isDone if needed
      this.fetchData('Task');
    }
  }


  // filterTasks(): void {
  //   // Filter tasks where done is true
  //   this.filteredTasks = this._tasksList.filter(task => task.done);
  // }

  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }

  openTasksModal(content: TemplateRef<any>, id = 0, task: any | null) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);
    if (task) {
      this._selectedCustomerId = null;
      this._selectedUserId = null;
      this._task = task;
      this._task.dueDate = this.formatTaskDate(task.dueDate);
      if (task.assignedto) {
        this._selectedUserId = task.assignedto;
      }

      if (task.customerId) {
        this._selectedCustomerId = task.customerId;
      }
    }

    this._selectedDate = this.formatDate(new Date());
    this._selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);

  }
  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }
  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }


  saveTask() {
    if (this._task.title.trim().length === 0) {
      return;
    }

    this.saveData(this._task, 'Task');
  }
  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = 0;
    if (this._task.assignedto != null) {
      entity.assignedto = this._selectedUserId;
      entity.customerId = this._selectedCustomerId;
    }
    else {
      entity.assignedto = this._currentUserId;
      entity.customerId = null;
    }


    entity.createdBy = this._currentUserId;
    const activityDate = new Date(`${this._selectedDate}T${this._selectedTime}:00.000Z`);
    entity.activityDate = activityDate.toISOString();

    // console.log('saving task data', entity);

    let observable: Observable<any> | null = null;;

    if (entityType === 'Task') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);

          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
          // console.log('task data saviing', this._task.dueDate)
        },
        (error: any) => {
          console.error("error saving data", error);
          // Handle errors as needed
        }
      );
    }
  }

  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }

  getCompanyUsers() {

    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }
  onPageChange(event: any): void {
    this._currentPage = event.offset + 1;
    this.fetchData('Task');
  }

  onSearch(): void {
    this._currentPage = 1;
    this.fetchData('Task');
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  // Inside your component
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }
  onUserSelect(event: any) {
    const selectedUserId = this._task.assignedto;
    const selectedUser = this._companyUsers.find(user => user.id === selectedUserId);
    if (selectedUser) {
      this._selectedUserId = selectedUser.id;
      this._selectedCustomerId = selectedUser.customerId;
    } else {
      this._selectedUserId = null;
      this._selectedCustomerId = null;
    }
    console.log('selected user id ', this._selectedUserId);
    console.log('selected customer id ', this._selectedCustomerId);
  }
}
