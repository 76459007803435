<nav class="page-breadcrumb">
  <ol class="breadcrumb">
    <h5>CONTACTS & COMPANIES</h5>
  </ol>
</nav>
<!-- Modal -->
<ng-template #xlModal let-modal>
  <div class="modal-header">
    <!-- Add Contact Inside -->
    <h5 class="modal-title container" id="exampleModalLabel">
      <lord-icon src="https://cdn.lordicon.com/uukerzzv.json" trigger="hover" colors="primary:#110a5c,secondary:#1ac0a1"
        style="width:40px;height:40px">
      </lord-icon>
      &nbsp;{{_modalTitle}}
    </h5>


    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <app-add-edit-contact [contact]="_contact" (callingParent)="parentFunction()">
    </app-add-edit-contact>
  </div>
</ng-template>

<ng-template #columnModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title container" id="exampleModalLabel">
      <!-- Edit Columns Inside -->
      <lord-icon src="https://cdn.lordicon.com/puvaffet.json" trigger="hover" delay="2000"
        colors="primary:#110a5c,secondary:#1ac0a1" style="width:40px;height:40px">
      </lord-icon>


      &nbsp;Edit Columns
    </h5>
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <table>
      <ng-container *ngIf="_contact.contactType == 0">
        <tr *ngFor="let column of _allContColumns">
          <td>
            <label>
              <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedContColumns[column]"
                [ngModelOptions]="{ standalone: true }" (ngModelChange)="toggleColumnVisibility()" />
              {{ column }}
            </label>
          </td>
        </tr>
      </ng-container>

      <ng-container *ngIf="_contact.contactType == 1">
        <tr *ngFor="let column of _allCompColumns">
          <td>
            <label>
              <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedCompColumns[column]"
                [ngModelOptions]="{ standalone: true }" (ngModelChange)="toggleColumnVisibility()" />
              {{ column }}
            </label>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>


<ng-container *ngIf="_readPermission">
  <ul ngbNav #defaultNav="ngbNav" class="nav-tabs" [(activeId)]="defaultNavActiveId">
    <li [ngbNavItem]="1">
      <a ngbNavLink (click)="onChangeType(0)" class="container">
        <lord-icon src="https://cdn.lordicon.com/jqeuwnmb.json" trigger="hover"
          colors="primary:#121331,secondary:#1ac0a1" style="width:30px;height:30px">
        </lord-icon>

        <span class="text">Contacts</span>

      </a>

      <ng-template ngbNavContent>
        <div class="row">

          <!-- <div class="col-md-4 col-sm-auto align-right">
            <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
              placeholder="Search..........." [(ngModel)]="_searchTerm" (keyup)="onSearch()" />

          </div> -->
          <div class="col-md-4 col-sm-auto align-right">
            <input type="text" id="table-complete-search" class="form-control" placeholder="Search..."
              [(ngModel)]="_searchTerm" (keyup.enter)="onSearch()" (input)="onInputChange($event)" />
            <small *ngIf="!_searchTerm" class="form-text text-muted">Type to search.</small>
            <small *ngIf="_searchTerm" class="form-text text-muted">Press Enter to search.</small>
          </div>


          <div class="col-8">
            <ng-container *ngIf="_createPermission">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openBasicModal(xlModal);"
                style="margin-left:3px;width: 130px;">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                </lord-icon>
                &nbsp; Add Contact
              </button>
            </ng-container>
            <button class="btn  btn-xs btn-secondary btn-icon-text float-end" (click)="exportData('csv','contact')"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/pqxdilfs.json" trigger="" delay="2000"
                colors="outline:#131432,primary:#606874,secondary:#08a88a,tertiary:#ebe6ef"
                style="width:23px;height:23px">
              </lord-icon>
              &nbsp; Export
            </button>
            <button class="btn  btn-xs btn-secondary btn-icon-text float-end" (click)="openColumnModal(columnModal)"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
                colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd"
                style="width:20px;height:20px">
              </lord-icon>
              &nbsp;
              Edit Columns
            </button>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12 my-datatable">
            <ngx-datatable [rows]="contactTableData" class="bootstrap custom-datatable" [columnMode]="ColumnMode.force"
              [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize" [pagination]="true"
              (page)="onPageChange($event)" [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true"
              [count]="_totalRecords" [externalPaging]="true" [pageSize]="_pageSize">
              <ngx-datatable-column *ngFor="let column of contactTableColumns" [name]="column.header" class="thead">
                <ng-template ngx-datatable-header-template>
                  {{ column.header }}
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <ng-container *ngIf="column.header === 'NAME'">
                    <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                      (mouseleave)="onRowMouseLeave(row)">
                      <div class="customer-name-wrapper">
                        <a class="customer-name profileClick text-decoration-underline" [ngbPopover]="row.customerName"
                          triggers="hover" placement="top" [ngClass]="{ 'preview-active': row.showPreview }"
                          [attr.data-id]="row.id.toString()">
                          {{row.customerName}}
                        </a>
                      </div>
                      <button
                        class="btn btn-secondary button-wrapper btn-icon-text previewClick btn-small-transparent btn-smaller"
                        *ngIf="row.isHovered" [attr.data-id]="row.id">
                        Preview
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="column.header === 'COUNTRY'">
                    {{ enumMappingService.mapCountry(row.country) || '-'}}
                  </ng-container>

                  <ng-container *ngIf="column.header === 'POSITION'">
                    {{ row.role || '-'}}
                  </ng-container>

                  <ng-container *ngIf="column.header === 'ACCOUNT'">
                    {{ row.companyName || '-'}}
                  </ng-container>

                  <ng-container *ngIf="column.header === 'TYPE'">
                    {{ row.salesType || '-'}}
                  </ng-container>

                  <ng-container
                    *ngIf="column.header !== 'NAME' && column.header !== 'POSITION'  && column.header !== 'ACCOUNT'  && column.header !== 'TYPE' && column.header !== 'COUNTRY'">
                    {{ value || '-'}}
                  </ng-container>

                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">

      <a ngbNavLink (click)="onChangeType(1)" class="container">
        <lord-icon src="https://cdn.lordicon.com/gqzfzudq.json" trigger="hover"
          colors="primary:#1ac0a1,secondary:#121331" style="width:30px;height:30px">
        </lord-icon>
        <span class="text">Companies</span>
      </a>

      <ng-template ngbNavContent>
        <div class="row">
          <!-- <div class="col-md-4 col-sm-auto align-right">
            <input id="table-complete-search" type="text" class="form-control" name="searchTerm" placeholder="Search..."
              [(ngModel)]="_searchTerm" (keyup)="onSearch()" />
          </div> -->

          <div class="col-md-4 col-sm-auto align-right">
            <input type="text" id="_searchTerm" class="form-control" placeholder="Search..." [(ngModel)]="_searchTerm"
              (keyup.enter)="onSearch()" (input)="onInputChange($event)" />
            <small *ngIf="!_searchTerm" class="form-text text-muted">Type to search.</small>
            <small *ngIf="_searchTerm" class="form-text text-muted">Press Enter to search.</small>
          </div>
          <div class="col-8 ">
            <ng-container *ngIf="_createPermission">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openBasicModal(xlModal);"
                style="margin-left:3px;width: 130px;">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                </lord-icon>
                &nbsp; Add Company
              </button>
            </ng-container>

            <button class="btn  btn-xs btn-secondary btn-icon-text float-end" (click)="exportData('csv','companies')"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/pqxdilfs.json" trigger="hover" delay="2000"
                colors="outline:#131432,primary:#606874,secondary:#08a88a,tertiary:#ebe6ef"
                style="width:23px;height:23px">
              </lord-icon>
              &nbsp; Export
            </button>
            <button class="btn  btn-xs btn-secondary btn-icon-text float-end" (click)="openColumnModal(columnModal)"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
                colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd"
                style="width:20px;height:20px">
              </lord-icon>
              &nbsp; Edit Columns
            </button>

          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12 my-datatable">
            <ngx-datatable [rows]="companyTableData" class="bootstrap custom-datatable" [columnMode]="ColumnMode.force"
              [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize" [pagination]="true"
              (page)="onPageChange($event)" [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true"
              [count]="_totalRecords" [externalPaging]="true" [pageSize]="_pageSize">
              <ngx-datatable-column *ngFor="let column of companyTableColumns" [name]="column.header" class="thead">
                <ng-template ngx-datatable-header-template>
                  {{ column.header }}
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                  <ng-container *ngIf="column.header === 'NAME'">
                    <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                      (mouseleave)="onRowMouseLeave(row)">
                      <div class="customer-name-wrapper">
                        <a class="customer-name profileClick text-decoration-underline"
                          ngbPopover="{{row.customerName}}" triggers="hover" placement="top"
                          [ngClass]="{ 'preview-active': row.showPreview }" [attr.data-id]="row.id.toString()">
                          {{ row.customerName}}
                        </a>
                      </div>
                      <button
                        class="btn btn-secondary button-wrapper btn-icon-text previewClick btn-small-transparent btn-smaller"
                        *ngIf="row.isHovered" [attr.data-id]="row.id">
                        Preview
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="column.header === 'COUNTRY'">
                    {{ enumMappingService.mapCountry(row.country) || '-'}}
                  </ng-container>

                  <ng-container *ngIf="column.header === 'OWNER'">
                    {{ row.ownerName || '-' }}
                  </ng-container>

                  <ng-container
                    *ngIf="column.header !== 'NAME' && column.header !== 'OWNER' && column.header !== 'COUNTRY'">
                    {{ value || '-'}}
                  </ng-container>
                  <ng-container *ngIf="column.header === 'TYPE'">
                    {{ row.salesType || '-'}}
                  </ng-container>


                  <!-- Sales Type (Contact type => Lead, Vendor, etc) -->
                  <!-- <ng-container *ngIf="column.header === 'TYPE'"> 
                    {{ value }}
                  </ng-container> -->
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>


          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div id="popupContainer" class="overlay" [style.display]="_popupVisible ? 'flex' : 'none'">
    <div class="popup">

      <h5 class="align-content-start"><i class="mdi mdi-account-plus"> </i> &nbsp;{{_modalTitle}}
      </h5>
      <br />
      <app-add-edit-contact [contact]="_contact" (callingParent)="parentFunction()">
      </app-add-edit-contact>
      <span class="close-btn" (click)="closePopup()">&times;</span>
    </div>
  </div>


  <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
</ng-container>