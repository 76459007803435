export interface CustomerStatsDTO {
  totalCustomers: number;
  previousWeekTotalCustomers: number;
  changeOverPreviousWeek: string;
}
export interface DealsStatsDTO {
  totalDeals: number;
  previousWeekTotalDeals: number;
  changeOverPreviousWeekFormatted: string;
}

export class SalesDTO {
  id: number | null;
  name: string | null;
  contactId: number | null;
  contactName: string | null;
  pipeId: number | null;
  pipeName: string | null;
  creationDate: Date | null;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean | null;
  dealOwnerId: number | null;
  dealOwner: string | null;
  amount: number | null;
  probability: number | null;
  lastContact: Date | null;
  salesRepId: number | null;
  salesRep: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.contactId = null;
    this.contactName = null;
    this.pipeId = null;
    this.pipeName = null;
    this.creationDate = null;
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = null;
    this.dealOwnerId = null;
    this.dealOwner = null;
    this.amount = null;
    this.probability = null;
    this.lastContact = null;
    this.salesRepId = null;
    this.salesRep = null;
  }
}

export class TopSalesDTO {
  dealOwnerId: number;
  dealOwnerName: string;
  dealCount: number;
  contactType: number;

  constructor() {
    this.dealOwnerId = 0;
    this.dealOwnerName = '';
    this.dealCount = 0;
    this.contactType = 0;
  }
}

