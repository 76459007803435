<ng-template #tasksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="tasksModalLabel">
      <div class="TitleIcon">
        <lord-icon class="solid_icons" src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover"
          colors="primary:#5f606c"></lord-icon>
        <span class="TitleText">Task</span>
      </div>
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_task.id" />
      <div class="col-md-12">
        <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title" autocomplete="on"
          value="{{_task.title}}" placeholder="Enter your task" required #taskTitle="ngModel" />
        <div *ngIf="taskTitle.invalid && (taskTitle.dirty)" class="error-message error" style="color: red;">
          <div *ngIf="taskTitle.errors?.['required']"> * required.</div>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.dueDate" class="form-label no-border">Due Date</label>
        <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate" autocomplete="on"
          required />
      </div>
      <div class="col-md-6">
        <label for="_task.assignedto" class="form-label no-border">Assigned To</label>
        <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="id" [searchable]="true"
          (change)="onUserSelect($event)" [(ngModel)]="_task.assignedto" placeholder="Select Contact" [clearable]="true"
          required>
        </ng-select>
      </div>

    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-4">
        <label for="_task.type" class="input-form-labels">Type</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
          <ng-option value="To-do" selected>To-do</ng-option>
          <ng-option value="Call">Call</ng-option>
          <ng-option value="Email">Email</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <label for="_task.priority" class="input-form-labels">Priority</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Low">Low</ng-option>
          <ng-option value="Medium">Medium</ng-option>
          <ng-option value="High">High</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <ng-container *ngIf="_taskUpdate">
          <label for="_task.label" class="input-form-labels">Mark as Done</label>
          <ng-select class="left-align input-color" [(ngModel)]="_task.done" [bindLabel]="_task.done ? 'Done' : 'None'"
            (ngModelChange)="toggleTaskDone(_task)" id="_task.done">
            <ng-option [value]="true">Done</ng-option>
            <ng-option [value]="false">None</ng-option>
          </ng-select>

          <span class="checkmark" [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
        </ng-container>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details" autocomplete="on"
          value="{{_task.details}}" placeholder="Notes..." required></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
      <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>
<ng-template #switchRoleModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title">Switch Role</h4>

  </div>
  <div class="modal-body">
    <div class="form-group">
      <ng-select [items]="roles" bindLabel="roleName" bindValue="id" [searchable]="false" [(ngModel)]="selectedRoleId"
        placeholder="Select Role" [clearable]="false" (change)="onRoleChange($event)">
      </ng-select>
    </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>



<nav class="navbar">

  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <div class="breadcrumb mr-3">
      <!-- <app-title-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-title-breadcrumb> -->
      <app-title-breadcrumb></app-title-breadcrumb>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item nav-notifications" ngbDropdown (click)="onTaskListClick()">
        <a class="dropdown-item" ngbDropdownToggle title="Create Task">
          <i class="feather icon-list"></i>
        </a>


        <div ngbDropdownMenu class="px-0" aria-labelledby="taskListDropdown"
          style="width: 350px; max-height: 350px; overflow-y: auto;">
          <h3 style="text-align: center;">Tasks</h3>

          <ul ngbNav #nav="ngbNav" class="nav-tabs sub-tabs" role="tablist">


            <li [ngbNavItem]="1" (click)="onTabChange('todo')">
              <a ngbNavLink class="nav-link ms-3">TO-DO</a>
              <ng-template ngbNavContent>
                <div *ngFor="let task of _tasksList.slice(0, 3); let i = index">
                  <div *ngIf="!task.done"
                    class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
                    style="font-size: 16px;">
                    <div class="d-flex align-items-center ">
                      <div class="title-icon img-xs rounded-circle">
                        {{ task.title.charAt(0) }}
                      </div>
                      <div class="ms-2">
                        <p class="fw-normal" ngbPopover="{{ task.title }}">
                          {{
                          task.title.length > 15 ? (task.title |
                          slice:0:15) + '...' :
                          task.title
                          }}
                        </p>
                        <a class="tx-11 text-muted">
                          {{ task.activityDate | date : "MMM d, yyyy 'at' h:mm a"}}</a>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: column; align-items: flex-end;">
                      <span class="overdue-message" [ngClass]="{ 'red-text': isOverdue(task), 'done-text': task.done }">
                        <span *ngIf="task.done; else notDone" class="badge bg-success">Done</span>
                        <ng-template #notDone>
                          <span *ngIf="isOverdue(task)" class="badge bg-danger">Over Due</span>
                          <span *ngIf="!task.dueDate || (!isOverdue(task) && !task.done)"
                            class="badge bg-info">Pending</span>
                        </ng-template>
                      </span>

                      <label class="due-date tx-11 text-muted">
                        {{ task.dueDate | date : "MMM d, yyyy" }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="px-2 py-1 d-flex align-items-center justify-content-end border-bottom"
                  *ngIf="_tasksList.length > 3">
                  <a class="nav-link see-all-link" (click)="seeAllTasks()">See all</a>
                </div>
              </ng-template>


            </li>

            <li [ngbNavItem]="2" (click)="onTabChange('done')">
              <a ngbNavLink class="nav-link">DONE</a>
              <ng-template ngbNavContent>
                <div *ngFor="let task of _tasksList.slice(0, 3); let i = index">
                  <div *ngIf="task.done"
                    class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
                    style="font-size: 16px;">
                    <div class="d-flex align-items-center">
                      <div class="title-icon img-xs rounded-circle">
                        {{ task.title.charAt(0) }}
                      </div>
                      <div class="ms-2">
                        <p class="fw-normal" ngbPopover="{{ task.title }}">
                          {{
                          task.title.length > 15 ? (task.title |
                          slice:0:15) + '...' :
                          task.title
                          }}
                        </p>
                        <a class="tx-11 text-muted">
                          {{ task.activityDate | date : "MMM d, yyyy 'at' h:mm a"}}</a>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end;">
                      <span class="overdue-message" [ngClass]="{ 'red-text': isOverdue(task), 'done-text': task.done }">
                        <span *ngIf="task.done; else notDone" class="badge bg-success">Done</span>
                        <ng-template #notDone>
                          <span *ngIf="isOverdue(task)" class="badge bg-danger">Over Due</span>
                        </ng-template>
                      </span>
                      <div class="tx-11 text-muted">{{ task.dueDate | date : "MMM d, yyyy"}}</div>
                    </div>
                  </div>
                </div>
                <div class="px-2 py-1 d-flex align-items-center justify-content-end border-bottom"
                  *ngIf="_tasksList.length > 3">
                  <a class="nav-link see-all-link" (click)="seeAllTasks()">See all</a>
                </div>
              </ng-template>

            </li>

            <button class="btn btn-primary btn-xs btn-icon-text universeButton square-button small-add-task-button"
              (click)="openTasksModal(tasksModal)" style="margin-bottom: 0.5rem; font-size: 10px; padding: 5px;">
              <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                colors="primary:#000000,secondary:#ebe6ef" style="width: 15px; height: 15px;">
              </lord-icon>
              Add Task
            </button>
          </ul>
          <div [ngbNavOutlet]="nav" class="border border-top-0 p-3"></div>
        </div>


      </li>
      <h1></h1>



      <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator" *ngIf="hasUnreadNotifications()">
            <div class="circle"></div>
          </div>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="notificationDropdown">
          <ng-container *ngIf="notifications.length > 0; else noNotifications">
            <div class="p-1">
              <a *ngFor="let notification of notifications" (click)="markAsRead(notification.id, notification.type)"
                class="dropdown-item d-flex align-items-center py-2">
                <div
                  class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                  <i class="feather icon-check-circle icon-sm text-white"></i>
                </div>
                <div class="flex-grow-1 me-2">
                  <p>{{ notification.message }}</p>
                  <p class="tx-12 text-muted">{{ notification.creationDate | date:'medium' }}</p>
                </div>
              </a>
            </div>
          </ng-container>

          <ng-template #noNotifications>
            <div class="p-3">
              <p class="text-muted">No notifications</p>
            </div>
          </ng-template>
        </div>

      </li>
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <div class="wd-30 ht-30 rounded-circle custom-rounded-circle custom-icon"
            style="background-color: #1ac0a24a; color: black; display: flex; justify-content: center; align-items: center;font-size:medium">
            <ng-container *ngIf="_imageToDisplay; else defaultIcon">
              <img [src]="_imageToDisplay" alt="Image" width="50" height="50">
            </ng-container>
            <ng-template #defaultIcon>
              <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
                colors="primary:#121331,secondary:#1ac0a1"></lord-icon>
            </ng-template>
          </div>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">

          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <div class="wd-50 ht-50 rounded-circle custom-rounded-circle custom-icon"
                style="background-color: #1ac0a24a; color: black; display: flex; justify-content: center; align-items: center;font-size:xx-large">
                <ng-container *ngIf="_imageToDisplay; else defaultIcon">
                  <img [src]="_imageToDisplay" alt="Image" width="50" height="50">
                </ng-container>
                <ng-template #defaultIcon>
                  <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
                    colors="primary:#121331,secondary:#6571ff"></lord-icon>
                </ng-template>

              </div>
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">{{ getNameFromLocalStorage() }}</p>
              <p class="tx-12 text-muted">{{ getEmailFromLocalStorage() }}</p>
              <p class="tx-12 text-muted">{{ getRoleFromLocalStorage() }}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2" *ngIf="roles && roles.length > 1">
              <a href="#" (click)="openSwitchRoleModal(switchRoleModal)" class="d-flex text-body ms-0">
                <i class="feather icon-repeat me-2 icon-md"></i>
                <span>Switch Role</span>
              </a>
            </li>



            <li class="dropdown-item py-2">
              <a [routerLink]="['/edit-profile']" (click)="false" class="d-flex text-body ms-0">
                <i class="feather icon-edit me-2 icon-md"></i>
                <span>Edit Profile</span>
              </a>
            </li>


            <li class="dropdown-item py-2">
              <a [routerLink]="['/login']" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>

</nav>