import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
    breadcrumbs$ = this.breadcrumbsSubject.asObservable();
    private previousUrl: string = '';
    private previousOfPreviousUrl: string = '';
    private currentUrl: string = '';

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousOfPreviousUrl = this.previousUrl;
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.urlAfterRedirects;
            }
        });

        // Initialize breadcrumbs from localStorage if available
        const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
        if (storedBreadcrumbs) {
            this.breadcrumbsSubject.next(JSON.parse(storedBreadcrumbs));
        }
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
        this.breadcrumbsSubject.next(breadcrumbs);
        localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
    }

    getPreviousUrl(): string {
        return this.previousUrl;
    }

    getPreviousOfPreviousUrl(): string {
        // console.log("previousOfPreviousUrl", this.previousOfPreviousUrl)
        return this.previousOfPreviousUrl;
    }
}

export interface Breadcrumb {
    text: string;
    url: string;
}
