export class Lead {
  id: number;
  firstName: string | null;
  lastName: string | null;
  customerId: number | null;
  customerName: string | null; // Not-mapped property
  phone: string | null; // Not-mapped property
  email: string | null; // Not-mapped property
  accountId: number | null;
  pocId: number | null;
  thirdPartyId: number | null;
  thirdPartyPocId: number | null;
  ownedBy: number;
  accountName: string | null; // Not-mapped property
  pocName: string | null; // Not-mapped property
  thirdPartyName: string | null; // Not-mapped property
  thirdPartyPocName: string | null; // Not-mapped property
  createdBy: number;
  modificationDate: Date | null;
  modifiedBy: number;
  deletionDate: Date | null;
  isDeleted: boolean;
  leadDetailId: number | null;

  constructor() {
    this.id = 0;
    this.firstName = null;
    this.lastName = null;
    this.customerId = null;
    this.customerName = this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : null;
    this.phone = null;
    this.email = null;
    this.accountId = null;
    this.pocId = null;
    this.thirdPartyId = null;
    this.thirdPartyPocId = null;
    this.ownedBy = 0;
    this.accountName = null;
    this.pocName = null;
    this.thirdPartyName = null;
    this.thirdPartyPocName = null;
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.modificationDate = null;
    this.modifiedBy = 0;
    this.deletionDate = null;
    this.isDeleted = false;
    this.leadDetailId = 0;
  }
}
