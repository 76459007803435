<ng-template #customerSatisfactionSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_customerSatisfactionSummaryData" [sorts]="_customerSatisfactionSummaryColumns"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode" [summaryRow]="false"
      [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
      [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of _customerSatisfactionSummaryColumns" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }" [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Dissatisfaction (😞)' || column.header === 'Poor (😐)' || 
                            column.header === 'Satisfied (😊)' || column.header === 'Very Good (😄)' || 
                            column.header === 'Excellent (🌟)'">
            <span [innerHTML]="value"></span>
          </ng-container>
          <ng-container *ngIf="column.header === 'Overall Rate'">
            {{ value || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ng-template #packingAppraisalSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_packingAppraisalsummaryData" [sorts]="_packingAppraisalsummarycolumnData"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode" [summaryRow]="false"
      [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
      [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of _packingAppraisalsummarycolumnData" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }" [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Packing ID'">
            {{ generalService.formatId( row.refId, 'packing') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Move ID'">
            {{ generalService.formatId(row.moveId, 'move') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Dissatisfaction (😞)' || column.header === 'Poor (😐)' || 
                            column.header === 'Satisfied (😊)' || column.header === 'Very Good (😄)' || 
                            column.header === 'Excellent (🌟)'">
            <span [innerHTML]="value"></span>
          </ng-container>
          <ng-container *ngIf="column.header === 'Overall Rate'">
            {{ row.overallRate || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ng-template #deliveryAppraisalSummarizeReport>
  <div class="my-datatable">
    <ngx-datatable [rows]="_deliveryAppraisalsummaryData" [sorts]="_deliveryAppraisalsummarycolumnData"
      class="bootstrap custom-datatable table-bordered table-responsive" [columnMode]="columnMode" [summaryRow]="false"
      [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
      [rowHeight]="40" [scrollbarH]="true">

      <ngx-datatable-column *ngFor="let column of _deliveryAppraisalsummarycolumnData" [name]="column.header"
        [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }" [sortable]="true">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
          <ng-container *ngIf="column.header === 'Date'">
            {{ generalService.formatDate(row.date,null) || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Delivery ID'">
            {{ generalService.formatId(row.refId, 'delivery') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Move ID'">
            {{ generalService.formatId(row.moveId, 'move') || '-' }}
          </ng-container>
          <ng-container *ngIf="column.header === 'Dissatisfaction (😞)' || column.header === 'Poor (😐)' || 
                            column.header === 'Satisfied (😊)' || column.header === 'Very Good (😄)' || 
                            column.header === 'Excellent (🌟)'">
            <span [innerHTML]="value"></span>
          </ng-container>
          <ng-container *ngIf="column.header === 'Overall Rate'">
            {{ row.overallRate || '-' }}
          </ng-container>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-template>

<ul ngbNav #reportTabNav4="ngbNav" class="nav-tabs mt-2" [(activeId)]="_reportActiveTabId4">
  <li [ngbNavItem]="1">
    <a ngbNavLink
      (click)="onChangeCustSatisfactionSubType(1, eMoveReportType.CustomerSatisfaction, eReportSubType.SummarizeReport)"
      class="nav-tab sub-tabs">
      <span class="text">Overall Appraisal Report</span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="customerSatisfactionSummarizeReport"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink
      (click)="onChangeCustSatisfactionSubType(2, eMoveReportType.PackingCustSatisfaction, eReportSubType.SummarizeReport)"
      class="nav-tab sub-tabs">
      <span class="text">Packing Appraisal Report</span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="packingAppraisalSummarizeReport"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink
      (click)="onChangeCustSatisfactionSubType(3, eMoveReportType.DeliveryCustSatisfaction, eReportSubType.SummarizeReport)"
      class="nav-tab sub-tabs">
      <span class="text">Delivery Appraisal Report</span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="deliveryAppraisalSummarizeReport"></ng-container>
    </ng-template>
  </li>
</ul>


<div [ngbNavOutlet]="reportTabNav4" class="border border-top-0 p-3"></div>