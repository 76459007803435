import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { log } from "console";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private baseUrl = environment.baseUrl;
  private companyId: string;

  constructor(private http: HttpClient) {
    const companyId = localStorage.getItem('companyId');
    if (companyId) {
      this.companyId = companyId;
    } else {
      this.companyId = '0';
    }
  }
  saveLabour(data: any) {
    return this.http.post(`${this.baseUrl}/Inventory/SaveLabour`, data);
  }
  getLabour() {
    const params = { ownedBy: this.companyId };
    return this.http.get(`${this.baseUrl}/Inventory/GetLabour`, { params });
  }
  updateLabourStatus(id: any, status: boolean, softDelete = false) {
    const params = new HttpParams()
      .set('id', id)
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    const url = `${this.baseUrl}/Inventory/UpdateLabourStatus`;
    return this.http.post(url, null, { params });
  }
  saveVehicle(data: any) {
    return this.http.post(`${this.baseUrl}/Inventory/SaveVehicle`, data);
  }
  getVehicle() {
    const params = { ownedBy: this.companyId };
    return this.http.get(`${this.baseUrl}/Inventory/GetVehicle`, { params });
  }
  updateVehicleStatus(id: any, status: boolean, softDelete = false) {
    const params = new HttpParams()
      .set('id', id)
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    const url = `${this.baseUrl}/Inventory/UpdateVehicleStatus`;
    return this.http.post(url, null, { params });
  }
  savePackingMaterial(data: any) {
    return this.http.post(`${this.baseUrl}/Inventory/SavePackingMaterial`, data);
  }
  getPackingMaterial() {
    const params = { ownedBy: this.companyId };
    return this.http.get(`${this.baseUrl}/Inventory/GetPackingMaterial`, { params });
  }
  updatePackingMaterialStatus(id: any, status: boolean, softDelete = false) {
    const params = new HttpParams()
      .set('id', id)
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    const url = `${this.baseUrl}/Inventory/UpdatePackingMaterialStatus`;
    return this.http.post(url, null, { params });
  }
  saveInventoryAssignment(data: any) {
    return this.http.post(`${this.baseUrl}/Inventory/SaveInventoryAssignment`, data);
  }
  getInventoryAssignment(refId: any, userId: any, assignmentType: any) {
    const params = new HttpParams()
      .set('refId', refId)
      .set('userId', userId)
      .set('assignmentType', assignmentType);
    return this.http.get(`${this.baseUrl}/Inventory/GetInventoryAssignment`, { params });
  }
}