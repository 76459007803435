<div class="container">
    <div>
        <a class="sidebar-brand nobleui-logo">
            <img src="assets/images/movd/1 (2).png" alt="MOVD" class="img-fluid" height="50" />
          </a>
    </div>
<div *ngIf="_isDataLoaded">
  <div class="row">
      <div class="col-1"></div>
      <div class="col-5">
          <h4>MOVE DETAILS</h4>
      </div>
      <div class="col-6">
      </div>
  </div>

  &nbsp; &nbsp;

          <div class="row">
            <div class="col-md-1 mt-1"></div>
              <div class="col-md-10  mt-1">
                  <ul ngbNav #defaultNav="ngbNav" class="nav-tabs"
                      [(activeId)]="defaultNavActiveId">
                      <li [ngbNavItem]="1" (click)="_currentTabId = 1">
                          <a ngbNavLink class="container">
                              <span class="text">MOVE INFO</span>
                          </a>
                          <ng-template ngbNavContent>
                              <div class="col-12 mt-2 text-end">
                                  <button class="btn btn-primary btn-sm" (click)="downloadPdf()">
                                      Export &nbsp;<i class="mdi mdi-file-export"></i>
                                  </button>
                              </div>
                              <app-move-info-form [moveDetailId]="_moveDetail.id"
                                  [refreshMoveInfo]="_refreshMoveInfo"
                                  [_contentDisable]="_contentDisable"
                                  (draftBLData)="handleDraftBLData($event)"
                                  (version)="handleVersionChange($event)"
                                  (categoryTransportChange)="handleCategoryTransportChange($event)">
                              </app-move-info-form>
                              <div class="border border-dark-grey mt-3">
                                  <app-client-journey [moveDetailId]="_moveDetail.id"
                                      [moveCategory]="_moveDetail.categoryType"
                                      [moveTransport]="_moveDetail.transportType"
                                      [version]="_statusVersion"
                                      [contentDisabled]="_contentDisable"></app-client-journey>
                              </div>
                          </ng-template>

                      </li>

                      <!-- Export Air or Sea Shipment -->
                      <li [ngbNavItem]="2" *ngIf="_draftTabLabel"
                          (click)="_currentTabId = 2">
                          <a ngbNavLink class="container">
                              <span class="text">{{_draftTabLabel}}</span>
                          </a>
                          <ng-template ngbNavContent>
                             
                              <app-consignment-details [contentDisabled]="_contentDisable"
                                  [moveDetailId]="_moveDetail.id"
                                  [moveCategory]="_moveDetail.categoryType"
                                  [moveTransport]="_moveDetail.transportType"
                                  [clientName]="_clientName"
                                  [clientsCompany]="_clientsCompany"
                                  [contentDisable]="_contentDisable"></app-consignment-details>
                          </ng-template>
                      </li>
                      
                      <li [ngbNavItem]="4" (click)="_currentTabId = 4">
                          <a ngbNavLink class="container">
                              <span class="text">MESSAGES</span>
                          </a>
                          <ng-template ngbNavContent>
                              <div>
                                  <app-communications [leadDetailId]="_moveDetail.leadDetailId"
                                      [contentDisabled]="_contentDisable"></app-communications>
                              </div>
                          </ng-template>
                      </li>
                      <li [ngbNavItem]="5" 
                          (click)="_currentTabId = 5">
                          <a ngbNavLink class="container">
                              <span class="text">CLIENT DOCUMENTS</span>
                          </a>
                          <ng-template ngbNavContent>
                              <div><app-move-documents [documentType]="_clientDocType"
                                      [moveDetailId]="_moveDetail.id"
                                      [moveCategory]="_moveDetail.categoryType"
                                      [clientName]="_clientName"
                                      [clientsCompany]="_clientsCompany"
                                      [contentDisable]="true"></app-move-documents>
                              </div>
                          </ng-template>
                      </li>

                      <li [ngbNavItem]="6" 
                          (click)="_currentTabId = 6">
                          <a ngbNavLink class="container">
                              <span class="text">MOVE DOCUMENTS</span>
                          </a>
                          <ng-template ngbNavContent>
                              <div>
                                  <app-move-documents [documentType]="_moveDocType"
                                      [moveDetailId]="_moveDetail.id"
                                      [leadDetailId]="_leadDetailId" [leadId]="_leadId"
                                      [moveCategory]="_moveDetail.categoryType"
                                      [contentDisabled]="true"></app-move-documents>
                              </div>
                          </ng-template>
                      </li>
                  </ul>
                  <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
              </div>
              <div class="col-md-1 mt-1"></div>
          </div>
</div>
</div>