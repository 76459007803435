<ng-template #datePickerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Select a Date</h4>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input class="form-control clickable" name="dp" ngbDatepicker #d="ngbDatepicker" placeholder="Select a date"
        [value]="formatDate1()" (click)="d.toggle()" (dateSelect)="onDateSelectStatus($event); d.close()" readonly />
      <button class="input-group-text" type="button" title="date-range" (click)="d.toggle()">
        <i class="feather icon-calendar icon-md text-muted"></i>
      </button>
    </div>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary btn-sm"
      (click)="saveDateAndStatus(selectedStatus); modal.close('by: save button')">
      <!-- *ngIf="selectedStatus?.showDatePicker" -->
      <i class="mdi mdi-content-save"> </i>Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="cancelClick(datePickerModal)">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>

  </div>
</ng-template>

<div class="container" *ngIf="_isDataLoaded">
  <div class="row">
    <div class="col-md-12 mt-3">
      <h5>Client Journey</h5>
      <div class="row mt-3" *ngIf="_statusData && _statusData.length > 0; else noData">
        <div class="col-sm-2  checkbox-container" *ngFor="let status of _statusData; let last = last">
          <div class="checkbox-column">
            <input type="checkbox" class="form-check-input" [checked]="status.moveStatusDone"
              (change)="updateStatus(status)" [disabled]="contentDisabled" />
            <ng-container *ngIf="!last">
              <p class="mt-0 m-4 mb-0 horizantal-line"><br /></p>
            </ng-container>
            <ng-container *ngIf="last">
              <p class="mt-0  mb-0"><br /></p>
            </ng-container>
            <label>
              {{ status.statusTitle }}
            </label>
            <br />
            <small class="text-muted">{{ status.moveStatusDate | date : "MMM d, yyyy" }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="row">
    <div class="col-md-12 mt-3">
      <p>No data added by admin</p>
    </div>
  </div>
</ng-template>
<!-- || status.statusTitle === 'Lead Closed' -->