import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ContactService } from 'src/services/contact.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowContactComponent } from '../app/views/crm/contact/show-contact/show-contact.component';
import { AddEditContactComponent } from '../app/views/crm/contact/add-edit-contact/add-edit-contact.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './views/crm/profile/profile.component';
import { LoginComponent } from './views/auth/login/login.component';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import { DealsComponent } from './views/crm/sales/deals/deals.component';
import { SuperAdminComponent } from './views/admin/super-admin/super-admin.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CompanyAdminComponent } from './views/admin/company-admin/company-admin.component';
import { AuthInterceptor } from './views/auth/auth.interceptor';
import { ContactComponent } from './views/crm/contact/contact.component';
import { ContactModule } from './views/crm/contact/contact.module';
import { LeadBoardComponent } from './views/movd/lead-board/lead-board.component';
import { LeadDetailsComponent } from './views/movd/lead-details/lead-details.component';
import { RouterModule } from '@angular/router';
import { LeadSummaryComponent } from './views/movd/lead-summary/lead-summary.component';
import { MoveDocumentsComponent } from './views/movd/move-documents/move-documents.component';
import { GeneralSettingComponent } from './views/movd/admin/general-setting/general-setting';
import { ShipperInfoComponent } from './views/movd/shipper-info/shipper-info.component';
import { CommunicationsComponent } from './views/movd/communications/communications.component';
import { LeadSurveyComponent } from './views/movd/move-manager/lead-survey/lead-survey.component';
import { LeadQuotationComponent } from './views/movd/lead-quotation/lead-quotation.component';
import { EnumMappingService } from './views/movd/services/movd.enum.service';
import { SurveyListComponent } from './views/movd/survey-manager/survey-list/survey-list.component';
import { PackingListComponent } from './views/movd/survey-manager/packing-list/packing-list.component';
import { SurveyDashboardComponent } from './views/movd/survey-manager/survey-dashboard/survey-dashboard.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import daygridPlugin from '@fullcalendar/daygrid';
import { ColorPickerModule } from 'ngx-color-picker';
import { LeadSurveyPreviewComponent } from './views/movd/move-manager/lead-survey-preview/lead-survey-preview.component';
import { InventorySettingComponent } from './views/movd/admin/inventory-setting/inventory-setting.component';
import { LaborSettingComponent } from './views/movd/admin/labor-setting/labor-setting.component';
import { TemplateSettingComponent } from './views/movd/admin/templates/template-setting.component';
import { StatusSettingComponent } from './views/movd/admin/status-setting/status-setting.component';
import { AddSurveyComponent } from './views/movd/survey-manager/add-survey/add-survey.component';
import { LeadSummaryPreviewComponent } from './views/movd/lead-summary-preview/lead-summary-preview.component';
import { PackingFormComponent } from './views/movd/move-manager/packing-form/packing-form.component';
import { GeneralService } from 'src/services/general.service';
import { EditProfileComponent } from './views/edit-profile/edit-profile.component';
import { MovesStatusComponent } from './views/movd/moves-status/moves-status.component';
import { MovesBoardComponent } from './views/movd/moves-board/moves-board.component';
import { MoveDetailsComponent } from './views/movd/move-details/move-details.component';
import { MoveInfoFormComponent } from './views/movd/move-info-form/move-info-form.component';
import { ClientJourneyComponent } from './views/movd/client-journey/client-journey.component';
import { DocumentsSettingComponent } from './views/movd/admin/documents-setting/documents-setting.component';
import { LeadInfoHeaderComponent } from './views/movd/lead-info-header/lead-info-header.component';
import { PackingDetailsComponent } from './views/movd/packing-details/packing-details.component';
import { EmailTemplateComponent } from './views/movd/admin/templates/email-template/email-template.component';
import { DeliveryListComponent } from './views/movd/survey-manager/delivery-list/delivery-list.component';
import { DeliveryDetailsComponent } from './views/movd/survey-manager/delivery-details/delivery-details.component';
import { WeightCertificateComponent } from './views/movd/move-manager/weight-certificate/weight-certificate.component';
import { SurveySettingsComponent } from './views/movd/admin/survey-settings/survey-settings.component';
import { QuotationSettingComponent } from './views/movd/admin/templates/quotation-setting/quotation-setting.component';
import { SurveySummaryComponent } from './views/movd/survey-summary/survey-summary.component';
import { AuthGuard } from 'src/app/core/guard/auth-guard';
import { RolePermissionsComponent } from './views/movd/admin/role-permissions/role-permissions.component';
import { CompanyDashboardComponent } from './views/movd/admin/company-dashboard/company-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MoveManagerDashboardComponent } from './views/movd/move-manager/move-manager-dashboard/move-manager-dashboard.component';
import { SuperAdminDashboardComponent } from './views/super-admin-dashboard/super-admin-dashboard.component';
import { PackingManagerDashboardComponent } from './views/movd/packing/packing-manager-dashboard/packing-manager-dashboard.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { DeliveryWeightCertificateComponent } from './views/movd/survey-manager/delivery-weight-certificate/delivery-weight-certificate.component';
import { FormatUrlPipe } from 'src/pipes/formatUrl';
import { ConsignmentDetailsComponent } from './views/movd/move-manager/consignment-details/consignment-details.component';
import { AppraisalSettingsComponent } from './views/movd/admin/templates/appraisal-settings/appraisal-settings.component';
import { AppraisalFormComponent } from './views/movd/move-manager/appraisal-form/appraisal-form.component';
import { TaskComponent } from './views/crm/profile/task/task.component';
import { AuthModule } from './views/auth/auth.module';
import { TaskBoardComponent } from './views/crm/profile/task-board/task-board.component';
import { SalesManagerDashboardComponent } from './views/movd/sales-manager/sales-manager-dashboard/sales-manager-dashboard.component';
import { AuditLogComponent } from './views/audit-log/audit-log.component';
import { CustomPaginationComponent } from './views/custom-pagination/custom-pagination.component';
import { QuillModule } from 'ngx-quill';
import { GenerateEmailComponent } from './views/movd/generate-email/generate-email.component';
import { ReportsComponent } from './views/movd/reports/reports.component';
import { MoveReportsComponent } from './views/movd/reports/move-reports/move-reports.component';
import { CustomerSatisfactionComponent } from './views/movd/reports/move-reports/customer-satisfaction/customer-satisfaction.component';
import { SalesReportComponent } from './views/movd/reports/sales-report/sales-report.component';
import { ClientMovePageComponent } from './views/movd/client-move-page/client-move-page.component';
import { DispatchBoardComponent } from './views/movd/admin/dispatch-board/dispatch-board.component';
import { LabourComponent } from './views/movd/admin/inventory-setting/labour/labour.component';
import { VehiclesComponent } from './views/movd/admin/inventory-setting/vehicles/vehicles.component';
import { PackingMaterialComponent } from './views/movd/admin/inventory-setting/packing-material/packing-material.component';


FullCalendarModule.registerPlugins([interactionPlugin, daygridPlugin]);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ErrorPageComponent,
    ContactComponent,
    ShowContactComponent,
    AddEditContactComponent,
    ProfileComponent,
    LoginComponent,
    DealsComponent,
    SuperAdminComponent,
    CompanyAdminComponent,
    LeadBoardComponent,
    LeadDetailsComponent,
    LeadSummaryComponent,
    LeadInfoHeaderComponent,
    MoveDocumentsComponent,
    AddSurveyComponent,
    GeneralSettingComponent,
    LeadQuotationComponent,
    ShipperInfoComponent,
    CommunicationsComponent,
    LeadSurveyComponent,
    SurveyListComponent,
    PackingListComponent,
    SurveyDashboardComponent,
    LaborSettingComponent,
    InventorySettingComponent,
    TemplateSettingComponent,
    StatusSettingComponent,
    LeadSurveyPreviewComponent,
    LeadSummaryPreviewComponent,
    MovesStatusComponent,
    PackingFormComponent,
    EditProfileComponent,
    ClientJourneyComponent,
    MovesBoardComponent,
    MoveDetailsComponent,
    MoveInfoFormComponent,
    DocumentsSettingComponent,
    PackingDetailsComponent,
    EmailTemplateComponent,
    DeliveryListComponent,
    DeliveryDetailsComponent,
    WeightCertificateComponent,
    SurveySettingsComponent,
    QuotationSettingComponent,
    SurveySummaryComponent,
    RolePermissionsComponent,
    CompanyDashboardComponent,
    MoveManagerDashboardComponent,
    SuperAdminDashboardComponent,
    PackingManagerDashboardComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DeliveryWeightCertificateComponent,
    FormatUrlPipe,
    ConsignmentDetailsComponent,
    AppraisalSettingsComponent,
    AppraisalFormComponent,
    TaskComponent,
    TaskBoardComponent,
    AuditLogComponent,
    CustomPaginationComponent,
    GenerateEmailComponent,
    SalesManagerDashboardComponent,
    ReportsComponent,
    MoveReportsComponent,
    CustomerSatisfactionComponent,
    SalesReportComponent,
    ClientMovePageComponent,
    DispatchBoardComponent,
    LabourComponent,
    VehiclesComponent,
    PackingMaterialComponent,


  ],
  exports: [AddEditContactComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    LayoutModule,
    ContactModule,
    CommonModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxDatatableModule,
    RouterModule,
    NgbNavModule,
    FullCalendarModule,
    ColorPickerModule,
    NgApexchartsModule,
    QuillModule.forRoot()
  ],
  providers: [
    ContactService,
    AuthGuard,
    EnumMappingService,
    GeneralService,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        },
      },
    },
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
    ],
    DecimalPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
