import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { LoginComponent } from '../app/views/auth/login/login.component';
import { RegisterComponent } from './views/auth/register/register.component';

import { LeadBoardComponent } from './views/movd/lead-board/lead-board.component';
import { LeadDetailsComponent } from './views/movd/lead-details/lead-details.component';
import { AddSurveyComponent } from './views/movd/survey-manager/add-survey/add-survey.component';
import { ShipperInfoComponent } from './views/movd/shipper-info/shipper-info.component';
import { GeneralSettingComponent } from './views/movd/admin/general-setting/general-setting';
import { SurveyListComponent } from './views/movd/survey-manager/survey-list/survey-list.component';
import { SurveyDashboardComponent } from './views/movd/survey-manager/survey-dashboard/survey-dashboard.component';
import { PackingListComponent } from './views/movd/survey-manager/packing-list/packing-list.component';
import { LaborSettingComponent } from './views/movd/admin/labor-setting/labor-setting.component';
import { InventorySettingComponent } from './views/movd/admin/inventory-setting/inventory-setting.component';
import { TemplateSettingComponent } from './views/movd/admin/templates/template-setting.component';
import { StatusSettingComponent } from './views/movd/admin/status-setting/status-setting.component';
import { MovesBoardComponent } from './views/movd/moves-board/moves-board.component';
import { MoveDetailsComponent } from './views/movd/move-details/move-details.component';
import { DocumentsSettingComponent } from './views/movd/admin/documents-setting/documents-setting.component';
import { PackingDetailsComponent } from './views/movd/packing-details/packing-details.component';
import { DeliveryListComponent } from './views/movd/survey-manager/delivery-list/delivery-list.component';
import { DeliveryDetailsComponent } from './views/movd/survey-manager/delivery-details/delivery-details.component';
import { SurveySettingsComponent } from './views/movd/admin/survey-settings/survey-settings.component';
import { AuthGuard } from 'src/app/core/guard/auth-guard';
import { RolePermissionsComponent } from './views/movd/admin/role-permissions/role-permissions.component';
import { CompanyDashboardComponent } from './views/movd/admin/company-dashboard/company-dashboard.component';
import { MoveManagerDashboardComponent } from './views/movd/move-manager/move-manager-dashboard/move-manager-dashboard.component';
import { SuperAdminDashboardComponent } from './views/super-admin-dashboard/super-admin-dashboard.component';
import { PackingManagerDashboardComponent } from './views/movd/packing/packing-manager-dashboard/packing-manager-dashboard.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SuperAdminComponent } from './views/admin/super-admin/super-admin.component';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { TaskComponent } from './views/crm/profile/task/task.component';
import { TaskBoardComponent } from './views/crm/profile/task-board/task-board.component';
import { SalesManagerDashboardComponent } from './views/movd/sales-manager/sales-manager-dashboard/sales-manager-dashboard.component';
import { AuditLogComponent } from './views/audit-log/audit-log.component';
import { ReportsComponent } from './views/movd/reports/reports.component';
import { ClientMovePageComponent } from './views/movd/client-move-page/client-move-page.component';
import { DispatchBoardComponent } from './views/movd/admin/dispatch-board/dispatch-board.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'client-move', component: ClientMovePageComponent },

  {
    path: '',
    component: BaseComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'leads-board',
        component: LeadBoardComponent,
        data: {
          title: 'Leads Dashboard',
        },
      },
      {
        path: 'moves-board',
        component: MovesBoardComponent,
        data: {
          title: 'Moves Dashboard',
        },
      },
      {
        path: 'moves-manager-dashboard',
        component: MoveManagerDashboardComponent,
        data: {
          title: 'Moves Manager Dashboard',
        },
      },

      {
        path: 'lead-detail',
        component: LeadDetailsComponent,
        data: {
          title: 'Lead Information Form',
        },
      },
      {
        path: 'move-detail',
        component: MoveDetailsComponent,
        data: {
          title: 'Move Details',
        },
      },
      {
        path: 'packing-details',
        component: PackingDetailsComponent,
        data: {
          title: 'Packing Details',
        },
      },
      // {
      //   path: 'client-move',
      //   component: ClientMovePageComponent,
      //   data: {
      //     title: 'Client Move',
      //   },
      // },
      {
        path: 'task-board',
        component: TaskBoardComponent,
        data: {
          title: 'Task',
        },
      },
      {
        path: 'sales-manager-dashboard',
        component: SalesManagerDashboardComponent,
        data: {
          title: 'Sales Dashboard',
        },
      },

      {
        path: 'delivery-details',
        component: DeliveryDetailsComponent,
        data: {
          title: 'Delivery Details',
        },
      },
      {
        path: 'general-setting',
        component: GeneralSettingComponent,
        data: {
          title: 'General Setting',
        },
      },
      {
        path: 'labor-setting',
        component: LaborSettingComponent,
        data: {
          title: 'Labor Setting',
        },
      },
      {
        path: 'inventory-setting',
        component: InventorySettingComponent,
        data: {
          title: 'Inventory Setting',
        },
      },
      {
        path: 'template-setting',
        component: TemplateSettingComponent,
        data: {
          title: 'Template Setting',
        },
      },
      {
        path: 'documents-setting',
        component: DocumentsSettingComponent,
        data: {
          title: 'Documents Setting',
        },
      },
      {
        path: 'status-setting',
        component: StatusSettingComponent,
        data: {
          title: 'Status Setting',
        },
      },
      {
        path: 'survey-setting',
        component: SurveySettingsComponent,
        data: {
          title: 'Survey Setting',
        },
      },
      {
        path: 'audit-logs',
        component: AuditLogComponent,
        data: {
          title: 'Audit Logs',
        },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          title: 'Reports',
        },
      },
      {
        path: 'dispatch-board',
        component: DispatchBoardComponent,
        data: {
          title: 'Dispatch Board',
        },
      },
      {
        path: 'user-permissions',
        component: RolePermissionsComponent,
        data: {
          title: 'Role Permissions',
        },
      },
      {
        path: 'company-dashboard',
        component: CompanyDashboardComponent,
        data: {
          title: 'Company Dashboard',
        },
      },


      {
        path: 'shipper-info',
        component: ShipperInfoComponent,
        data: {
          title: 'Shipper Info',
        },
      },
      {
        path: 'add-survey',
        component: AddSurveyComponent,
        data: {
          title: 'Survey Form',
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        data: {
          title: 'Super Admin',
        },
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./views/dashboard/dashboard.module').then(
      //       (m) => m.DashboardModule
      //     ),
      // },
      // {
      //   path: 'super-admin',
      //   loadChildren: () =>
      //     import('./views/admin/super-admin/super-admin.module').then(
      //       (m) => m.SuperAdminModule
      //     ),
      // },
      {
        path: 'super-admin-dashboard',
        component: SuperAdminDashboardComponent,
        data: {
          title: 'dashboard',
        },
      },

      {
        path: 'company-admin',
        loadChildren: () =>
          import('./views/admin/company-admin/company-admin.module').then(
            (m) => m.CompanyAdminModule
          ),


      },


      {
        path: 'contact',
        loadChildren: () =>
          import('./views/crm/contact/contact.module').then(
            (m) => m.ContactModule
          ),

      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./views/crm/profile/profile.module').then(
            (m) => m.ProfileModule
          ),

      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./views/crm/sales/deals/deals.module').then(
            (m) => m.DealsModule
          ),
      },
      {
        path: 'edit-profile',
        loadChildren: () =>
          import('./views/edit-profile/edit-profile.module').then(
            (m) => m.EditProfileModule
          ),
      },
      {
        path: 'survey-dashboard',
        component: SurveyDashboardComponent,
        data: {
          title: 'survey-dashboard',
        },
      },
      {
        path: 'survey-list',
        component: SurveyListComponent,
        data: {
          title: 'Survey List',
        },
      },
      {
        path: 'packing-manager-dashboard',
        component: PackingManagerDashboardComponent,
        data: {
          title: 'Packing Dashboard',
        },
      },
      {
        path: 'packing-list',
        component: PackingListComponent,
        data: {
          title: 'Packing List',
        },
      },
      // {
      //   path: 'profile-task',
      //   component: TaskComponent,
      //   data: {
      //     title: 'Profile Task',
      //   },
      // },
      {
        path: 'delivery-list',
        component: DeliveryListComponent,
        data: {
          title: 'Delivery List',
        },
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
