import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Lead } from '../models/lead.model';
import { MoveStatus } from '../models/move-status.model';
import { MoveAttachment } from '../models/move-attachments.model';
import { ContactServicePaths } from 'src/enums/contact-service-paths';
import { CompanySetting } from '../models/companysetting.model';
import { LeadQuotation } from '../models/lead-quotation.model';
import { DraftBLDTO } from '../interfaces/draft-bl-dto';
import { EmailTemplate } from '../models/email-template.model';
import { eMoveCategoryType, eProcessStatus, eTransportType, MoveTransportModal } from '../models/lead-detail.model';
import { DashboardStats } from '../models/dashboardStats.model';
import { catchError, tap } from 'rxjs/operators';
import { ConsignmentDetail, ConsignmentDetailItem } from '../models/consignment-detail.model';
import { AppraisalDTO, AppraisalSetting, eAppraisalType } from '../models/appraisal.model';
import { eStatusType, Status } from '../models/status.model';
import { email } from 'ngx-custom-validators/src/app/email/validator';
import { eEmailType } from '../../auth/auth.models';
import { eAssignmentType } from '../models/userAssignment.model';
import { Shift } from '../models/shiftTime.model';
import { UnassignDispatchLogResponse, UnassignedDispatchItemDTO } from '../admin/dispatch-board/dispatch-board.model';
import { AreaItemsModel, AllSurveyPlacesResponse } from '../models/movd-comp-admin.model';
import { LeadBoardDTO, LeadBoardResponse } from '../lead-board/lead-board.model';
import { MoveBoardDTO, MovesListResponse } from '../moves-board/moves-board.model';
import { PackagingBoardListDTO, PackingListResponse } from '../survey-manager/packing-list/packing-board.model';
@Injectable({
  providedIn: 'root',
})
export class MovdService {
  private baseUrl = environment.baseUrl; // Replace with your .NET API URL
  private companyId: string;
  private role: string;


  constructor(private http: HttpClient) {
    const companyId = localStorage.getItem('companyId');
    const role = localStorage.getItem('role');
    if (companyId) {
      this.companyId = companyId;
    } else {
      this.companyId = '0';
    }
    if (role) {
      this.role = role;
    } else {
      this.role = 'User';
    }
  }

  getCompanyId(): string {
    return this.companyId;
  }

  getDashboardStats(fromDate: string | Date | null, toDate: string | Date | null) {
    const params = new HttpParams()
      .set('ownedBy', this.companyId ? this.companyId.toString() : '')
      .set('fromDate', fromDate instanceof Date ? fromDate.toISOString() : fromDate || '')
      .set('toDate', toDate instanceof Date ? toDate.toISOString() : toDate || '');
    return this.http.get<DashboardStats>(`${this.baseUrl}/Movd/GetDashboardStats`, { params: params });
  }
  getDispatchBoardStats(date: any) {
    const params = new HttpParams()
      .set('ownedBy', this.companyId ? this.companyId.toString() : '')
      .set('date', date);
    //console.log('dispatch params', params);

    return this.http.get<DashboardStats>(`${this.baseUrl}/Movd/GetDispatchBoardStats`, { params: params });
  }


  saveLead(data: Lead) {
    return this.http.post(`${this.baseUrl}/Movd/SaveLead`, data);
  }

  searchCustomer(name: string | null, phone: string | null, email: string | null): Observable<any[]> {
    const params = new HttpParams()
      .set('name', name || '')
      .set('phone', phone || '')
      .set('email', email || '')
      .set('ownedBy', this.companyId || '');

    return this.http.get<any[]>(`${this.baseUrl}/Movd/SearchCustomer`, { params });
  }

  // to mark lost single lead
  markLeadLost(leadDetailId: number, MarkLost: boolean) {
    const params = {
      leadDetailId: leadDetailId.toString(),
      markLost: MarkLost,
    };

    const id = leadDetailId.toString();
    const url = `${this.baseUrl}/Movd/MarkLeadLost?leadDetailId=${id}&markLost=${MarkLost}`;
    return this.http.post(url, {});
  }

  // to mark lost muliple leads
  markLeadsLost(leadDetails: any[]) {
    const url = `${this.baseUrl}/Movd/MarkLeadsLost`;
    const body = leadDetails.map(leadDetail => ({
      LeadDetailId: leadDetail.leadDetailId,
      MarkLost: leadDetail.markLost,
      LostReason: leadDetail.lostReason
    }));
    return this.http.post(url, body);
  }

  // InitiateMove(leadDetailId: number) {
  //   const params = {
  //     leadDetailId: leadDetailId.toString()
  //   };
  //   return this.http.get(`${this.baseUrl}${MovdServicePaths.InitiateMove}`, { params });
  // }
  initiateMove(leadDetailIds: number[]) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post(`${this.baseUrl}/Movd/InitiateMove`, JSON.stringify(leadDetailIds), { headers });
  }

  updateLead(id: any, data: Lead) {
    const params = { id: id.toString(), lead: data };
    return this.http.post(`${this.baseUrl}/Movd/UpdateLead`, {
      params,
    });
  }

  getLeadById(id: any) {
    return this.http.get(
      `${this.baseUrl}/Movd/GetLeadById/${id}`
    );
  }

  GetStatuses(statusType: eStatusType, category: eMoveCategoryType, transport: eTransportType): Observable<any[]> {
    const params = {
      ownedBy: this.companyId,
      statusType: statusType.toString() || '0',
      category: category.toString() || '0',
      transport: transport.toString() || '0',
    };
    return this.http.get<any[]>(`${this.baseUrl}/Movd/GetStatuses`, { params });
  }

  getLeadsForBoard(page: number | null, pageSize: number | null, searchTerm: string | null, isSurveyList: boolean = false): Observable<LeadBoardResponse> {
    const ownedBy = this.companyId;
    let params = new HttpParams()
      .set('ownedBy', ownedBy.toString());
    if (isSurveyList) params = params.set('isSurveyList', isSurveyList.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.baseUrl}/Movd/GetLeadsForBoard`;

    console.log("getLeadsForBoard params: ", params);

    return this.http.get<LeadBoardResponse>(url, { params });
  }

  saveLeadInternalNotes(leadDetailId: number, notes: string | null): Observable<any> {
    const queryParams = `?leadDetailId=${leadDetailId}&notes=${notes || ''}`;
    return this.http.post<any>(`${this.baseUrl}/Movd/SaveLeadInternalNotes${queryParams}`, {});
  }


  saveLeadDetail(data: any) {
    return this.http.post(`${this.baseUrl}/Movd/SaveLeadDetail`, data);
  }

  saveSurveyStatus(leadDetailId: number, surveyStatus: eProcessStatus) {
    const params = new HttpParams()
      .set('leadDetailId', leadDetailId.toString())
      .set('surveyStatus', surveyStatus.toString());
    return this.http.post(`${this.baseUrl}/Movd/SaveSurveyStatus`, {}, { params: params });
  }

  // saveSurveyStatus(leadDetailId: number, surveyStatus: eProcessStatus) {
  //   const params = { leadDetailId: leadDetailId, surveyStatus: surveyStatus };
  //   console.log("params", params);
  //   return this.http.post(`${this.baseUrl}/Movd/SaveSurveyStatus`, params);
  // }

  // saveSurveyStatus(leadDetailId: number, surveyStatus: eProcessStatus) {
  //   const params = `?leadDetailId=${leadDetailId}&surveyStatus=${surveyStatus}`;
  //   console.log("params", params);
  //   return this.http.post(`${this.baseUrl}/Movd/SaveSurveyStatus${params}`, {});
  // }

  getLeadDetailById(leadDetailId: number) {
    const params = { leadDetailId: leadDetailId.toString() };
    return this.http.get(
      `${this.baseUrl}/Movd/GetLeadDetailById`,
      { params }
    );
  }

  getLeadDetailsByLeadId(leadId: number, isAddSurvey = false): Observable<LeadBoardResponse> {
    // const params = { ownedId: this.companyId, leadId: leadId.toString() };
    // return this.http.get(`${this.baseUrl}/Movd/GetLeadDetailsByLeadId`, { params });

    let params = new HttpParams();
    params = params.append('ownedBy', this.companyId);

    if (leadId) {
      params = params.append("leadId", leadId);
    }

    if (isAddSurvey) {
      params = params.append("isSurveyList", "true")
    }

    console.log("params", params);

    return this.http.get<LeadBoardResponse>(`${this.baseUrl}/Movd/GetLeadsForBoard`, { params });

  }

  getLeadDetailsForProfile(customerId: number) {
    const params = { ownedId: this.companyId, customerId: customerId.toString() };
    return this.http.get(`${this.baseUrl}/Movd/GetLeadDetailsForProfile`, { params });
  }
  getMoveDetailsForProfile(customerId: number) {
    const params = { ownedId: this.companyId, customerId: customerId.toString() };
    return this.http.get(`${this.baseUrl}/Movd/GetMoveDetailsForProfile`, { params });
  }

  getLeadDetail(leadDetailId: number, leadId: number) {
    const params = {
      leadDetailId: leadDetailId.toString(),
      leadId: leadId.toString(),
    };
    return this.http.get(`${this.baseUrl}/Movd/GetLeadDetail`, {
      params,
    });
  }

  // Get Lead Details By LeadDetailId through GetLeadsForBoard Api
  getLeadSummary(leadDetailId: number): Observable<LeadBoardResponse> {
    let params = new HttpParams();
    params = params.append('ownedBy', this.companyId);
    params = params.append("leadDetailId", leadDetailId.toString());
    const url = `${this.baseUrl}/Movd/GetLeadsForBoard`;

    console.log("getLeadSummary params: ", params);
    return this.http.get<LeadBoardResponse>(url, { params });
  }

  getLeads(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/leads`);
  }

  getLeadStatus(leadDetailId: number, statusType: number, category: number, transportType: number) {
    const params = {
      ownedBy: this.companyId,
      leadDetailId: leadDetailId.toString(),
      statusType: statusType.toString(),
      category: category.toString(),
      transportType: transportType?.toString() || '0',
    };
    // console.log("params of get lead status for lead board", params);
    return this.http.get(`${this.baseUrl}/Movd/GetLeadStatus`, {
      params,
    });
  }

  saveLeadQuotationSettings(leadQuotationSettings: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/Movd/SaveLeadQuotationSettings`, leadQuotationSettings);
  }

  getLeadQuotationSettings(categoryType: eMoveCategoryType | null, transportType: eTransportType | null): Observable<any> {
    const params = {
      ownedBy: this.companyId,
      CategoryType: categoryType || '',
      TransportType: transportType || ''
    };

    return this.http.get(`${this.baseUrl}/Movd/GetLeadQuotationSettings`, { params });
  }
  deleteLeadQuotationSettings(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/Movd/DeleteLeadQuotationSettings?id=${id}`);
  }
  saveSurveyPlace(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveSurveyPlace`,
      data
    );
  }

  // getAllSurveyPlaces(ownedBy: number): Observable<any[]> {
  //   const params = { ownedBy: ownedBy.toString() };
  //   return this.http.get<any[]>(
  //     `${this.baseUrl}/Movd/GetAllSurveyPlaces`,
  //     { params }
  //   );
  // }

  getAllSurveyPlaces(page: number | null, pageSize: number | null, searchTerm: string | null): Observable<AllSurveyPlacesResponse> {
    const ownedBy = this.companyId;
    let params = new HttpParams()
      .set('ownedBy', ownedBy.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.baseUrl}/Movd/GetAllSurveyPlaces`;
    return this.http.get<AllSurveyPlacesResponse>(url, { params });
  }

  // updateSurveyPlaceStatus(id: number, status: boolean){
  //   const params = {id:id.toString(), status:status.toString()}
  //   return this.http.post(`${this.baseUrl}${MovdServicePaths.updateSurveyPlaceStatus}`, {params})
  // }

  updateSurveyPlaceStatus(id: number, status: boolean, softDelete = false): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateSurveyPlaceStatus`,
      null,
      { params }
    );
  }

  deleteSurveyPlace(data: any) {
    return this.http.delete(
      `${this.baseUrl}/Movd/DeleteSurveyPlace`,
      data
    );
  }
  saveSurveyItem(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveSurveyItem`,
      data
    );
  }
  // getAllSurveyItems(ownedBy: number, page: number, pageSize: number, searchTerm: string | null): Observable<any[]> {
  //   const params = { ownedBy: ownedBy.toString() };
  //   return this.http.get<any[]>(
  //     `${this.baseUrl}/Movd/GetAllSurveyItems`,
  //     { params }
  //   );
  // }
  getAllSurveyItems(page: number | null, pageSize: number | null, searchTerm: string | null): Observable<{ surveyItems: AreaItemsModel[], totalRecords: number }> {
    const ownedBy = this.companyId;
    let params = new HttpParams()
      .set('ownedBy', ownedBy.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.baseUrl}/Movd/GetAllSurveyItems`;
    return this.http.get<{ surveyItems: AreaItemsModel[], totalRecords: number }>(url, { params });
  }

  getSurveyItemsBySurveyPlaceId(placeId: number): Observable<any[]> {
    const params = { placeId: placeId.toString() };
    return this.http.get<any[]>(
      `${this.baseUrl}/Movd/GetSurveyItemsBySurveyPlaceId`,
      { params }
    );
  }
  updateSurveyItemStatus(id: number, status: boolean, softDelete = false): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateSurveyItemStatus`,
      null,
      { params }
    );
  }
  saveSurveyMaterial(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveSurveyMaterial`,
      data
    );
  }

  saveMoveSurvey(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveMoveSurvey`,
      data
    );
  }

  saveLeadQuotation(leadQuotation: LeadQuotation): Observable<LeadQuotation> {
    return this.http.post<LeadQuotation>(
      `${this.baseUrl}/Movd/SaveLeadQuotation`,
      leadQuotation
    );
  }
  getMoveTransportByLeadDetailId(
    leadDetailId: number
  ): Observable<MoveTransportModal> {
    return this.http.get<MoveTransportModal>(
      `${this.baseUrl}/Movd/GetMoveTransportByLeadDetailId?LeadDetailId=${leadDetailId}`
    );
  }
  getLeadQuotationsByLeadDetailId(
    leadDetailId: number
  ): Observable<LeadQuotation[]> {
    return this.http.get<LeadQuotation[]>(
      `${this.baseUrl}/Movd/GetLeadQuotationsByLeadDetailId?LeadDetailId=${leadDetailId}`
    );
  }

  deleteLeadQuotation(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/Movd/DeleteLeadQuotation/${id}`
    );
  }

  saveStatus(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveStatus`,
      data
    );
  }
  updateLeadStatus(id: number, status: boolean, softDelete = false): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateLeadStatus`,
      null,
      { params }
    );
  }

  saveMoveStatus(status: MoveStatus) {
    // const updateData = { statusIds }; // Create an object with the item IDs to update
    return this.http.post(
      `${this.baseUrl}/Movd/SaveMoveStatus`,
      status
    );
  }

  saveClientJourneyStatus(status: MoveStatus) {
    // const updateData = { statusIds }; // Create an object with the item IDs to update
    return this.http.post(
      `${this.baseUrl}/Movd/SaveClientJourneyStatus`,
      status
    );
  }

  saveReorderedStatuses(statusList: Status[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/Movd/SaveReorderedStatus`, statusList);
  }

  createMoveStatusList(statusList: MoveStatus[]): Observable<any> {
    // const updateData = { statusIds }; // Create an object with the item IDs to update
    return this.http.post(
      `${this.baseUrl}/Movd/CreateMoveStatusList`,
      statusList
    );
  }

  saveMoveAttachment(attachments: MoveAttachment[]): Observable<any> {
    // const updateData = { statusIds }; // Create an object with the item IDs to update
    return this.http.post(
      `${this.baseUrl}/Movd/SaveMoveAttachment`,
      attachments
    );
  }

  getMoveSurvey(leadDetailId: number) {
    const params = { leadDetailId: leadDetailId.toString() };
    return this.http.get<any[]>(
      `${this.baseUrl}/Movd/GetMoveSurvey`,
      { params }
    );
  }

  // for survey add component to select move detail id
  getLeadDetailByOwnedId() {
    // const params = { ownedBy: this.companyId.toString() };
    const id = this.companyId.toString();
    return this.http.get(
      `${this.baseUrl}/Movd/GetLeadDetailsByOwnedId?ownedBy=${id}`
    );
  }

  // calling contact apis
  getCustomerCalls(id: any, isdeal: boolean, isMove: boolean) {
    const url = `${this.baseUrl}/Movd/GetCalls/${id}?isdeal=${isdeal}&isMove=${isMove}`;
    return this.http.get(url);
  }

  getCustomerEmails(id: any, isdeal: boolean, isMove: boolean) {
    const url = `${this.baseUrl}/Movd/GetEmails/${id}?isdeal=${isdeal}&isMove=${isMove}`;
    return this.http.get(url);
  }

  getMeetings(id: any, isDeal: boolean, isMove: boolean): Observable<any[]> {
    const url = `${this.baseUrl}/Movd/GetMeetings/${id}?isDeal=${isDeal}&isMove=${isMove}`;
    return this.http.get<any[]>(url);
  }

  getTasks(id: any, isDeal: boolean, isMove: boolean): Observable<any[]> {
    const url = `${this.baseUrl}/Contact/GetTasks/${id}?isDeal=${isDeal}&isMove=${isMove}`;
    return this.http.get<any[]>(url);
  }

  getCompanySettings(): Observable<CompanySetting> {
    const ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.get<CompanySetting>(
      `${this.baseUrl}/Movd/GetCompanySettings?ownedBy=${ownedBy}`
    );
  }

  createOrUpdateCompanySettings(
    companySetting: CompanySetting
  ): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/Movd/CreateOrUpdateCompanySettings`,
      companySetting
    );
  }
  saveShift(shift: Shift) {
    console.log('shift params ', shift);
    return this.http.post(`${this.baseUrl}/Movd/SaveShift`, shift);
  }

  getShift(): Observable<Shift> {
    const ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.get<Shift>(
      `${this.baseUrl}/Movd/GetShift?ownedBy=${ownedBy}`
    );
  }
  updateQuotationSettings(companySetting: CompanySetting): Observable<any> {
    const companyId = Number(localStorage.getItem('companyId'));
    companySetting.ownedBy = companyId;

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateQuotationSettings`,
      companySetting
    );
  }


  deleteCompanySettings(): Observable<any> {
    const ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.delete(
      `${this.baseUrl}/DeleteCompanySettings?ownedBy=${ownedBy}`
    );
  }


  // getMovesForBoard(): Observable<any[]> {
  //   const params = { ownedId: this.companyId };
  //   return this.http.get<any[]>(
  //     `${this.baseUrl}/Movd/GetMovesForBoard`,
  //     { params }
  //   );
  // }

  getMovesForBoard(page: number | null, pageSize: number | null, searchTerm: string | null, leadId: number | null = null): Observable<MovesListResponse> {
    const ownedBy = this.companyId;
    const customerId = Number(localStorage.getItem("customerId"));

    let params = new HttpParams()
      .set('ownedId', ownedBy.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);

    if (leadId) params = params.append("leadId", leadId);

    if (this.role === 'ThirdParty') {
      params = params.append('thirdPartyId', customerId);
    } else if (this.role === 'Moving Account') {
      params = params.append('accountId', customerId);
    }

    const url = `${this.baseUrl}/Movd/GetMovesForBoard`;
    return this.http.get<MovesListResponse>(url, { params });
  }

  getMoveDetailsByLeadDetailId(leadDetailId: number) {
    const params = { leadDetailId: leadDetailId.toString() };
    return this.http.get<any>(`${this.baseUrl}/Movd/GetMoveDetailByLeadDetailId`, { params });
  }

  getMoveDetailsByLeadId(leadId: number): Observable<MovesListResponse> {
    const params = { ownedId: this.companyId, leadId: leadId.toString() };
    return this.http.get<MovesListResponse>(`${this.baseUrl}/Movd/GetMovesForBoard`, { params });
  }

  getMoveDetailById(moveDetailId: number): Observable<any[]> {
    const params = { ownedId: this.companyId, moveDetailId };
    return this.http.get<any>(
      `${this.baseUrl}/Movd/GetMoveDetailById`,
      { params }
    );
  }
  getMoveDetailsByTrackingId(trackingId: string) {
    const params = { trackingId: trackingId };
    console.log('params', params)
    return this.http.get<any>(`${this.baseUrl}/Public/GetMoveDetailByTrackingId`, { params });
  }

  saveMoveInternalNotes(moveDetailId: number, notes: string): Observable<any> {
    const queryParams = `?moveDetailId=${moveDetailId}&notes=${notes}`;
    return this.http.post<any>(`${this.baseUrl}/Movd/SaveMoveInternalNotes${queryParams}`, {});
  }

  saveMoveDetail(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveMoveDetail`,
      data
    );
  }

  completeMove(moveDetailIds: number[]) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post(`${this.baseUrl}/Movd/CompleteMove`, JSON.stringify(moveDetailIds), { headers });
  }

  // to cancel muliple moves
  cancelMoves(moveDetails: any[], isRevert: boolean = false) {
    const url = `${this.baseUrl}/Movd/CancelMoves?isRevert=${isRevert}`;
    const body = moveDetails.map(moveDetail => ({
      MoveDetailId: moveDetail.moveDetailId,
      Cancelled: moveDetail.cancelled,
      CancelReason: moveDetail.cancelReason
    }));
    return this.http.post(url, body);
  }

  reInitiateMove(moveDetailId: number) {
    const params = new HttpParams()
      .set('moveDetailId', moveDetailId.toString());

    return this.http.post(`${this.baseUrl}/Movd/ReInitiateMove`, null, { params });
  }

  saveDraftBLorAWB(draftBL: DraftBLDTO) {
    return this.http.post(`${this.baseUrl}/Movd/SaveDraftBL`, draftBL);
  }

  saveConsignment(consignmentDetail: ConsignmentDetail): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Movd/SaveConsignment`, consignmentDetail);
  }

  getConsignmentByMoveDetailId(moveDetailId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/Movd/GetConsignmentByMoveDetailId?moveDetailId=${moveDetailId}`);
  }

  softDeleteConsignment(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/Movd/SoftDeleteConsignment?id=${id}`);
  }

  saveConsignmentItem(consignmentItem: ConsignmentDetailItem): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/Movd/SaveConsignmentItem`, consignmentItem);
  }

  deleteConsignmentItem(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/Movd/DeleteConsignmentItem/${id}`);
  }

  saveAttachments(data: any) {
    return this.http.post(`${this.baseUrl}/Movd/SaveAttachments`, data);
  }
  deleteAttachment(id: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/Movd/DeleteAttachment/${id}`, {});
  }



  getMoveAttachments(moveDetailId: number, type: number): Observable<any[]> {
    const params = { moveDetailId: moveDetailId, type: type };
    return this.http.get<any[]>(
      `${this.baseUrl}/Movd/GetMoveAttachments`, { params });
  }

  saveRequiredDocument(data: any) {
    return this.http.post(`${this.baseUrl}/Movd/SaveRequiredDocument`, data);
  }

  getRequiredDocuments(type: number): Observable<any[]> {
    const params = { ownedId: this.companyId, type: type.toString() };
    return this.http.get<any[]>(
      `${this.baseUrl}/Movd/GetRequiredDocuments`, { params });
  }

  updateDocumentStatus(id: number, status: boolean, softDelete = false): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString())
      .set('softDelete', softDelete.toString());

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateDocumentStatus`,
      null,
      { params }
    );
  }

  deleteRequiredDocument(id: number) {
    return this.http.delete<void>(
      `${this.baseUrl}/MovdDeleteRequiredDocument/${id}`
    );
  }
  saveEmailTemplates(template: EmailTemplate) {
    return this.http.post(`${this.baseUrl}/Movd/SaveEmailTemplates`, template);
  }

  getEmailTemplates(ownedBy: number): Observable<EmailTemplate[]> {
    const params = { ownedBy: ownedBy.toString() };
    return this.http.get<EmailTemplate[]>(
      `${this.baseUrl}/Movd/GetEmailTemplates`,
      { params }
    );
  }

  sendEmail(template: EmailTemplate, emails: string, pdfData: string, emailType: eEmailType, refId: number,) {
    const params = { template: template, emails: emails, pdfData: pdfData, emailType: emailType, refId: refId, ownedBy: this.companyId }; // Change 'PdfBlob' to 'PdfBase64'
    // console.log("params ", params);
    return this.http.post(`${this.baseUrl}/Movd/SendEmail`, params);
  }


  getEmailTemplate(ownedBy: number, title: string, replacePlaceholders = false, placeHoldersValues: { [key: string]: string } | null = null) {
    const params: any = {
      ownedBy: ownedBy.toString(),
      title: title,
      replacePlaceholders: replacePlaceholders.toString()
    };

    if (placeHoldersValues) {
      params.placeHoldersValues = JSON.stringify(placeHoldersValues);
    }

    return this.http.get(`${this.baseUrl}/Movd/GetEmailTemplate`, { params });
  }


  updatEmailTemplateStatus(id: number, status: boolean): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('status', status.toString());

    return this.http.post(
      `${this.baseUrl}/Movd/UpdateEmailTemplateStatus`,
      null,
      { params }
    );
  }
  // getPackagingBoardList(): Observable<any[]> {
  //   const params = { ownedId: this.companyId };
  //   return this.http.get<any[]>(`${this.baseUrl}/Movd/GetPackagingBoardList`, { params });
  // }

  getPackagingBoardList(page: number | null, pageSize: number | null, searchTerm: string | null, leadId: number | null = null): Observable<PackingListResponse> {
    const ownedId = this.companyId;
    let params = new HttpParams()
      .set('ownedId', ownedId.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    if (leadId) params = params.set('leadId', leadId.toString());

    const url = `${this.baseUrl}/Movd/GetPackagingBoardList`;

    return this.http.get<PackingListResponse>(url, { params });
  }

  deleteEmailTemplate(id: number) {
    return this.http.delete<void>(
      `${this.baseUrl}/Movd/DeleteEmailTemplate/${id}`
    );
  }

  savePackagingDetail(data: any) {
    return this.http.post(`${this.baseUrl}/Movd/SavePackagingDetail`, data);
  }

  savePackingAndDeliveryStatus(packingDetailId: number, status: eProcessStatus, statusType: string) {
    const params = new HttpParams()
      .set('packingDetailId', packingDetailId.toString())
      .set('status', status.toString())
      .set('statusType', statusType);
    return this.http.post(`${this.baseUrl}/Movd/SavePackingAndDeliveryStatus`, {}, { params: params });
  }

  getPackagingDetailsByLeadId(leadId: number): Observable<PackingListResponse> {
    const params = { ownedId: this.companyId, leadId: leadId.toString() };
    return this.http.get<PackingListResponse>(`${this.baseUrl}/Movd/GetPackagingBoardList`, { params });
  }

  getPackagingDetailsByLeadDetailId(leadDetailId: number) {
    const params = { ownedId: this.companyId, leadDetailId: leadDetailId.toString() };
    return this.http.get<any>(`${this.baseUrl}/Movd/GetPackagingDetailByLeadDetailId`, { params });
  }
  savePackagingInventory(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SavePackagingInventory`,
      data
    );
  }
  getPackagingInventoryByPackagingDetailId(packagingDetailId: number) {
    const params = { packagingDetailId };
    return this.http.get<any>(
      `${this.baseUrl}/Movd/GetPackagingInventoryByPackagingDetailId`,
      { params }
    );
  }
  getPackagingInventoryItemsByPackagingInventoryId(packagingInventoryId: number) {
    const params = { packagingInventoryId };
    return this.http.get<any>(
      `${this.baseUrl}/Movd/GetPackagingInventoryByPackagingInventoryId`,
      { params }
    );
  }
  savePackagingInventoryItem(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SavePackagingInventoryItem`,
      data
    );
  }
  savePackagingWeightCertItem(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SavePackagingWeightCertItem`,
      data
    );
  }
  getPackagingWeightCertByPackagingDetailId(packagingDetailId: number) {
    const params = { packagingDetailId };
    return this.http.get<any>(
      `${this.baseUrl}/Movd/GetPackagingWeightCertByPackagingDetailId`,
      { params }
    );
  }
  getPackagigWeightCertItemsByPackagingInventoryId(packagingWeightCertId: number) {
    const params = { packagingWeightCertId };
    return this.http.get<any>(
      `${this.baseUrl}/Movd/GetPackagingWeightCertItemByPackagingInventoryId`,
      { params }
    );
  }
  savePackagingWeightCert(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SavePackagingWeightCert`,
      data
    );
  }

  getAppraisalSettings(type: eAppraisalType) {
    const params = new HttpParams()
      .set('ownedBy', this.companyId.toString())
      .set('type', type);
    return this.http.get<AppraisalDTO>(`${this.baseUrl}/Movd/GetAppraisalSettings`, { params });
  }

  saveAppraisalSettings(appraisalDTO: AppraisalDTO) {
    return this.http.post(`${this.baseUrl}/Movd/SaveAppraisalSettings`, appraisalDTO);
  }

  saveAppraisal(appraisalDTO: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/Movd/SaveAppraisal`, appraisalDTO);
  }

  getAppraisal(refId: number, appraisalType: number): Observable<any> {
    const params = new HttpParams()
      .set('refId', refId.toString())
      .set('appraisalType', appraisalType.toString());

    return this.http.get(`${this.baseUrl}/Movd/GetAppraisal`, { params });
  }

  saveAppraisalQuestion(question: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/Movd/SaveAppraisalQuestion`, question);
  }

  deleteAppraisalQuestion(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/Movd/DeleteAppraisalQuestion/${id}`);
  }
  saveUserAssignments(data: any) {
    return this.http.post(
      `${this.baseUrl}/Movd/SaveUserAssignment`,
      data
    );
  }
  getUserAssignmentByRefId(refId: number, eAssignmentType: eAssignmentType): Observable<any> {
    const params = new HttpParams()
      .set('refId', refId.toString())
      .set('eAssignmentType', eAssignmentType.toString());
    return this.http.get(`${this.baseUrl}/Movd/GetUserAssignmentByRefId`, { params });
  }

  getUnassignedDispatchItems(page: number, pageSize: number, searchTerm: string | null): Observable<{ unassignedDispatchItems: UnassignedDispatchItemDTO[], totalRecords: number }> {
    const ownedBy = this.companyId;
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('ownedBy', ownedBy.toString());
    if (searchTerm) {
      params = params.set('searchTerm', searchTerm);
    }
    const url = `${this.baseUrl}/Movd/GetUnassignedDispatchItems`;
    return this.http.get<{ unassignedDispatchItems: UnassignedDispatchItemDTO[], totalRecords: number }>(url, { params });
  }

  getAssignedDispatchItems(date: any): Observable<any[]> {
    const params = { ownedId: this.companyId, date: date };
    return this.http.get<any[]>(`${this.baseUrl}/Movd/GetAssignedDispatchItems`, { params });
  }
  getAssignedDispatchItemById(id: number, type: eAssignmentType): Observable<any> {
    const params = new HttpParams()
      .set('refId', id)
      .set('type', type)
      .set('ownedId', this.companyId);
    return this.http.get<any>(`${this.baseUrl}/Movd/GetAssignedDispatchItemById`, { params });
  }
}
