<h5>STATUS SETTINGS</h5>
<ng-template #addStatusModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="mdi mdi-account-plus"> </i> &nbsp; Add Status
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <input type="hidden" value="_status.id" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_status.statusName" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_statusTitle" id="_status.statusName"
          autocomplete="on" placeholder="Enter Status name" required pattern="[A-Za-z\s]{1,15}"
          title="Status name should only contain letters and spaces, and be between 1-15 characters long."
          (keypress)="validateItemExists()" (blur)="validateItemExists()"
          (keyup.enter)="validateItemExists()"
          [ngClass]="{'error-input': formSubmitted && (!_statusTitle || (_statusTitle && _statusTitle.length === 0))}" />
        <div *ngIf="statusExists" style="color: red">Already exists</div>
        <div *ngIf="!_statusTitle || (_statusTitle && _statusTitle.length === 0)"
          class="error-message error" style="color: red">
          * required
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_status.order" class="col-form-label">Status Order</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_status.order"
          id="_status.statusName" autocomplete="on" placeholder="Enter Status Order" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveStatus(modal)">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-template #commonContent>
  <!-- <div class="card rounded"> -->
  <div class="card-header">
    <div class="row">
      <div class="col-md-3 col-sm-auto align-right">
        <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
          [(ngModel)]="_searchStatus" (keyup)="performStatusSearch()" />
      </div>
      <div class="col-md-4 col-md-auto"></div>
      <div class="col-md-5 col-md-auto">
        <button class="btn btn-xs btn-primary btn-icon-text float-end"
          (click)="openStatusModal(addStatusModal)" style="margin-left: 3px; width: 120px">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
          </lord-icon>
          &nbsp; Add Status
        </button>
        <!-- <button class="btn btn-xs btn-primary btn-icon-text float-end"
          (click)="saveReorderedStatuses()" style="margin-left: 3px; width: 120px">
          &nbsp; Save
        </button> -->
      </div>
    </div>
    &nbsp;
    <div class="row" *ngIf="_filteredTableData.length === 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body text-center">
            <p>No Status, currently. Please Add a Status.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="_filteredTableData.length > 0">
      <div class="col-md-12 stretch-card">

        <div class="card">

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <span class="required-input"> * </span>
                <span class="required-label">Defualt Statuses</span>
              </div>
            </div>

            <div class="table-responsive">
              <ngx-datatable [rows]="_filteredTableData" class="bootstrap" [loadingIndicator]="true"
                [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10" [pagination]="true"
                [pageSize]="10" [rowHeight]="40"
                (drop)="onDrop($event, _filteredTableData, 'statuses')"
                (dragover)="onDragOver($event)">
                <ngx-datatable-column *ngFor="let column of _statusTableColumns"
                  [name]="column.header">
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div class="d-flex align-items-center hover" draggable="true"
                      (dragstart)="onDragStart($event, row, _filteredTableData)"
                      (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)">
                      <img class="drag-icon" src="assets/images/movd/drag.png" alt="Draggable Icon">
                      <ng-container *ngIf="column.header === 'Status Name'">
                        {{ row.title }}
                        <span *ngIf="row.required" class="required-asterisk">*</span>
                        <button
                          class="btn btn-secondary button-wrapper btn-icon-text btn-small-transparent btn-smaller"
                          *ngIf="row.isHovered" (click)="StatusPreviewClick(row.id)">
                          Preview
                        </button>
                      </ng-container>
                      <ng-container *ngIf="column.header !== 'Status Name'">
                        {{ value }}
                      </ng-container>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action">
                  <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                    let-value="value">
                    <button type="button" class="btn btn-xs datatable-action-cell"
                      [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                      (click)="updateStatus(row, row.isActive)">
                      {{ !row.isActive ? "Activate" : "Deactivate" }}
                    </button>
                    &nbsp;
                    <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                      (click)="updateStatus(row, row.isActive, true)">
                      Delete
                    </button>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>



          </div>
        </div>
      </div>
    </div>


  </div>
  <!-- </div> -->
</ng-template>

<ng-template #statusCategories>
  <ul ngbNav #categoriesTypeNav="ngbNav" class="nav-tabs mt-3"
    [(activeId)]="_categoriesActiveTabId">
    <li [ngbNavItem]="1">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(1,enumCategoryType.DoorToPort, enumTransportType.Sea)"
        class="nav-tab sub-tabs">
        <span class="text"> DTP (SEA)</span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(2,enumCategoryType.DoorToPort, enumTransportType.Air)"
        class="nav-tab sub-tabs">
        <span class="text"> DTP (AIR) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(3,enumCategoryType.PortToDoor, enumTransportType.Air)"
        class="nav-tab sub-tabs">
        <span class="text"> PTD (AIR) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(4,enumCategoryType.PortToDoor, enumTransportType.Sea)"
        class="nav-tab sub-tabs">
        <span class="text"> PTD (SEA) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(5,enumCategoryType.DoorToDoorImport, enumTransportType.Air)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Import (AIR) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(6,enumCategoryType.DoorToDoorImport, enumTransportType.Sea)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Import (SEA) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="7">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(7,enumCategoryType.DoorToDoorImport, enumTransportType.SeaLCL)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Import (SEA LCL) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="8">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(8,enumCategoryType.DoorToDoorExport, enumTransportType.Air)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Export (AIR) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="9">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(9,enumCategoryType.DoorToDoorExport, enumTransportType.Sea)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Export (SEA) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="10">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(10,enumCategoryType.DoorToDoorExport, enumTransportType.SeaLCL)"
        class="nav-tab sub-tabs">
        <span class="text"> DTD Export (SEA LCL) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="11">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(11,enumCategoryType.DoorToPort, enumTransportType.SeaLCL)"
        class="nav-tab sub-tabs">
        <span class="text"> DTP (SEA LCL) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="12">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(12,enumCategoryType.PortToDoor, enumTransportType.SeaLCL)"
        class="nav-tab sub-tabs">
        <span class="text"> PTD (SEA LCL) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="13">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(13,enumCategoryType.DoorToDoorLocal, enumTransportType.Land)"
        class="nav-tab sub-tabs">
        <span class="text"> Local (Land) </span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="14">
      <a ngbNavLink
        (click)="onChangeCategoriesTab(13,enumCategoryType.DoorToDoorLocal, enumTransportType.Air)"
        class="nav-tab sub-tabs">
        <span class="text"> Local (Air)</span>
      </a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="commonContent"></ng-container>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="categoriesTypeNav" class="border border-top-0 p-3"></div>
</ng-template>

<ng-template #addPipeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="#addPipeModalLabel">
      <i class="mdi mdi-account-plus"> </i> &nbsp;{{ _modalTitle }}
    </h5>
    <!---->
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon'); closePopup();"
            aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <!--<input type="hidden" value="_companyUser.id" />-->
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_pipe.pipeName" class="col-form-label">Title</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" [(ngModel)]="_pipe.pipeName" id="_pipe.pipeName"
          autocomplete="on" placeholder="Enter Lead Status Title" maxlength="50" required
          pattern="[A-Za-z\s]{1,15}"
          title="Pipe name should only contain letters and spaces, and be between 1-15 characters long." />
        <div *ngIf="!_pipe.pipeName|| (_pipe.pipeName && _pipe.pipeName.length === 0)"
          class="error-message error" style="color: red;">
          * required
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_status.order" class="col-form-label">Order</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_pipe.order" id="_pipe.order"
          autocomplete="on" placeholder="Enter Status Order" />
        <div *ngIf="!_pipe.order" class="error-message error" style="color: red;">
          * required
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- Modal footer content -->

    <button type="button" class="btn btn-primary btn-sm" (click)="savePipe()">
      <i class="mdi mdi-content-save"> </i> &nbsp; {{ _saveTitle }}
    </button>
    <!--Save Company-->
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
    <!--<p>Modal Footer</p>-->
  </div>
</ng-template>

<ul ngbNav #statusTypeNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="statusTypeActiveTabId">
  <li [ngbNavItem]="1">
    <a ngbNavLink class="nav-tab" (click)="onChangeStatusTab(_eStatusType.Lead)">
      <span class="text"> Lead </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="statusCategories"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink class="nav-tab" (click)="onChangeStatusTab(_eStatusType.Move)">
      <span class="text"> Move </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="statusCategories"></ng-container>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink class="nav-tab" (click)="onChangeStatusTab(_eStatusType.ClientJourney)">
      <span class="text"> Client Journey </span>
    </a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="statusCategories"></ng-container>
    </ng-template>
  </li>

  <li [ngbNavItem]="4">
    <a ngbNavLink class="container">

      <span class="text"> Deal Status </span>
    </a>
    <ng-template ngbNavContent>
      <div class="card-header">
        <div class="row">
          <div class="col-md-3 col-sm-auto align-right">
            <input id="table-complete-search" type="text" class="form-control" name="searchRoles"
              placeholder="Search..." [(ngModel)]="searchPipe" (keyup)="performPipesSearch()" />
          </div>
          <div class="col-md-9 col-sm-auto text-end">
            <button class="btn btn-xs btn-primary btn-icon-text" (click)="openAddPipe(addPipeModal)"
              style="margin-left:3px;width: 130px;">
              <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
              </lord-icon>
              &nbsp; Add Status
            </button>
          </div>
        </div>
        &nbsp;
        <div class="row" *ngIf="pipeTableData.length === 0">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body text-center">
                <p>No Status, currently. Please Add a Sales Status.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="pipeTableData.length > 0">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <span class="required-input"> * </span>
                    <span class="required-label">Defualt Statuses</span>
                  </div>
                </div>

                <div class="table-responsive">
                  <ngx-datatable [rows]="pipeTableData" class="bootstrap" [loadingIndicator]="true"
                    [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10"
                    [pagination]="true" [pageSize]="10" [rowHeight]="40"
                    (drop)="onDrop($event, pipeTableData, 'pipes')" (dragover)="onDragOver($event)">
                    <ngx-datatable-column *ngFor="let column of pipeTableColumns"
                      [name]="column.header">
                      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                        <ng-container *ngIf="column.header === 'Title'">
                          <div class="d-flex align-items-center hover" draggable="true"
                            (mouseenter)="onRowMouseEnter(row)" (mouseleave)="onRowMouseLeave(row)"
                            (dragstart)="onDragStart($event, row, pipeTableData)">
                            <img class="drag-icon" src="assets/images/movd/drag.png"
                              alt="Draggable Icon">
                            {{ row.pipeName | slice:0:15 }}
                            <span *ngIf="row.required" class="required-asterisk">*</span>
                            <button class="btn btn-secondary button-wrapper btn-icon-text
                            btn-small-transparent btn-smaller" *ngIf="row.isHovered"
                              (click)="pipePreviewClick(row.id)">
                              Preview
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="column.header !== 'Title'">
                          {{ value }}
                        </ng-container>
                      </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Action">
                      <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
                        let-value="value">
                        <button type="button" class="btn btn-xs datatable-action-cell"
                          [class.btn-success]="!row.isActive" [class.btn-danger]="row.isActive"
                          (click)="updatePipeStatus(row, row.isActive)">
                          {{ !row.isActive ? "Activate" : "Deactivate" }}
                        </button>
                        &nbsp;
                        <button type="button"
                          class="btn btn-xs btn-danger datatable-action-cell ml-2"
                          (click)="updatePipeStatus(row, row.isActive, true)">
                          Delete
                        </button>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

</ul>
<div [ngbNavOutlet]="statusTypeNav" class="border border-top-0 p-3"></div>