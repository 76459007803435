import { Injectable, EventEmitter } from '@angular/core';
import { QuillModules } from 'ngx-quill';
import { eAppraisalType } from 'src/app/views/movd/models/appraisal.model';
import { DashboardCSATsDTO, DashboardStats } from 'src/app/views/movd/models/dashboardStats.model';
import { eProcessStatus } from 'src/app/views/movd/models/lead-detail.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private profilePicture: string | null = null;
  //profilePictureUpdated: any;
  donutChartOptionsMoves: any;
  donutChartOptionsLeads: any;
  obj = {
    primary: "#1AC0A1",
    secondary: "#8C8C8E",
    success: "#05a34a",
    info: "#43A8B6",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#292929",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif"
  }
  profilePictureUpdated = new EventEmitter<string | null>();

  formatDate(startDate: string | Date | null, endDate: string | Date | null): string | null {
    const isValidDate = (date: any) => date && !isNaN(Date.parse(date));

    if (isValidDate(startDate)) {
      const formatDate = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
        return `${day}-${month}-${year}`;
      };

      const startDateFormatted = formatDate(new Date(startDate as string));

      if (isValidDate(endDate)) {
        const endDateFormatted = formatDate(new Date(endDate as string));
        return `${startDateFormatted} - ${endDateFormatted}`;
      } else {
        return startDateFormatted;
      }
    }

    return null;
  }

  getProfilePicture(): string | null {
    return this.profilePicture;
  }

  setProfilePicture(image: string | null): void {
    this.profilePicture = image;
    this.profilePictureUpdated.emit(image);
  }

  formatTime(time: string | Date | null): string | null {
    const isValidTime = (input: any) => input && !isNaN(Date.parse(input));

    if (isValidTime(time)) {
      const formatSingleDigit = (digit: number) => digit.toString().padStart(2, '0');

      const date = new Date(time as string);
      const hours = date.getHours();
      const minutes = formatSingleDigit(date.getMinutes());
      const seconds = formatSingleDigit(date.getSeconds());

      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;

      return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
    }

    return null;
  }

  formatDateTime(dateTime: string | Date | null): string | null {
    const date = this.formatDate(dateTime, null);
    const time = this.formatTime(dateTime);
    return date && time ? `${date} ${time}` : null;
  }

  getButtonLabel(packingStatus: eProcessStatus): string {
    switch (packingStatus) {
      case eProcessStatus.New:
        return 'Start';
      case eProcessStatus.InProgress:
        return 'Edit';
      case eProcessStatus.Completed:
        return 'View';
      default:
        return 'View';
    }
  }

  getProcessStatusLabel(packingStatus: eProcessStatus): string {
    switch (packingStatus) {
      case eProcessStatus.New:
        return 'New';
      case eProcessStatus.InProgress:
        return 'InProgress';
      case eProcessStatus.Completed:
        return 'Completed';
      default:
        return ' - ';
    }
  }
  getDonutChartOptions(viewType: string, _dashboardStats: any) {
    let series: any;
    let labels;

    if (viewType === 'leads') {
      series = [
        _dashboardStats.newLeads,
        _dashboardStats.leadsInProgress,
        _dashboardStats.wonLeads,
        _dashboardStats.lostLeads
      ];
      labels = ['New', 'In Progress', 'Won', 'Lost'];
    } else if (viewType === 'moves') {
      series = [
        _dashboardStats.local,
        _dashboardStats.import,
        _dashboardStats.export
      ];
      labels = ['Local', 'Import', 'Export'];
    } else if (viewType === 'survey') {
      series = [
        _dashboardStats.newSurveys,
        _dashboardStats.inProgressSurveys,
        _dashboardStats.completedSurveys
      ];
      labels = ['New', 'In Progress', 'Completed'];
    } else if (viewType === 'packing') {
      series = [
        _dashboardStats.newPackings,
        _dashboardStats.inProgressPackings,
        _dashboardStats.completedPackings,
      ];
      labels = ['New', 'In Progress', 'Completed'];
    } else if (viewType === 'delivery') {
      series = [
        _dashboardStats.newDeliveries,
        _dashboardStats.inProgressDeliveries,
        _dashboardStats.completedDeliveries
      ];
      labels = ['New', 'In Progress', 'Completed'];
    }

    // Check if all series values are 0
    const noData = series.every((value: number) => value === 0);

    if (noData) {
      // If all values are 0, return a message
      return {
        series: [0],  // Add a dummy data point to prevent errors
        labels: ['No Data Available to show'],
        chart: {
          height: 300,
          type: "donut",
          foreColor: this.obj.bodyColor,
          background: this.obj.cardBg,
          toolbar: {
            show: false
          },
        },
        colors: [this.obj.primary],
        stroke: {
          colors: ['rgba(0,0,0,0)']
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: 'center',
          fontFamily: this.obj.fontFamily,
          itemMargin: {
            horizontal: 8,
            vertical: 0
          },
        },
        dataLabels: {
          enabled: false
        }
      };
    }
    return {
      series: series,
      labels: labels,
      chart: {
        height: 300,
        type: "donut",
        foreColor: this.obj.bodyColor,
        background: this.obj.cardBg,
        toolbar: {
          show: false
        },
      },
      colors: [this.obj.primary, this.obj.secondary, this.obj.info, this.obj.danger],
      // colors: ['#FF5733', '#C70039', '#900C3F', '#581845'], // Updated colors
      stroke: {
        colors: ['rgba(0,0,0,0)']
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: 'center',
        fontFamily: this.obj.fontFamily,
        itemMargin: {
          horizontal: 8,
          vertical: 0
        },
      },
      dataLabels: {
        enabled: true
      }
    };
  }

  getBarChartOptions(_dashboardStats: any) {
    return {
      series: [
        {
          name: 'Available',
          data: [30, 40, 45, 50]
        },
        {
          name: 'Occupied',
          data: [10, 20, 15, 30]
        },
        {
          name: 'Unavailable',
          data: [20, 20, 30, 20]
        }
      ],
      chart: {
        type: 'bar',
        height: '280',
        parentHeightOffset: 0,
        foreColor: this.obj.bodyColor,
        background: this.obj.cardBg,
        toolbar: {
          show: false
        },
      },
      colors: [this.obj.primary, this.obj.secondary, this.obj.info],
      grid: {
        padding: {
          bottom: -4
        },
        borderColor: this.obj.gridBorder,
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        type: 'category', // Use 'category' for non-datetime categories
        categories: ['Mover', 'Packers', 'Drivers', 'Loaders'],
        axisBorder: {
          color: this.obj.gridBorder,
        },
        axisTicks: {
          color: this.obj.gridBorder,
        },
      },
      yaxis: {
        labels: {
          offsetX: 0
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4
        }
      }
    };
  }

  getLineChartOptions(_dashboardCSATs: DashboardCSATsDTO[]) {
    // console.log("_dashboardStats", _dashboardCSATs.length);

    // Assuming that the '0s' count is mapped to '1s', '1s' to '2s', etc.
    let seriesData;

    if (_dashboardCSATs && _dashboardCSATs.length > 0) {
      // Map the DashboardCSATDTO data to the series array
      seriesData = _dashboardCSATs.map(dashboardCSAT => {
        return {
          name: eAppraisalType[dashboardCSAT.appraisalType], // Assuming eAppraisalType is an enum
          data: [dashboardCSAT.zeroCount, dashboardCSAT.oneCount, dashboardCSAT.twoCount, dashboardCSAT.threeCount, dashboardCSAT.fourCount]
        };
      });
    } else {
      // Default series data with all values set to 0
      seriesData = [
        {
          name: "N/A",
          data: [0, 0, 0, 0, 0]
        }
      ];
    }

    return {
      series: seriesData,
      chart: {
        type: "line",
        height: '320',
        parentHeightOffset: 0,
        foreColor: this.obj.bodyColor,
        background: this.obj.cardBg,
        toolbar: {
          show: false
        },
      },

      colors: [this.obj.primary, this.obj.secondary, this.obj.info],
      grid: {
        padding: {
          bottom: -4
        },
        borderColor: this.obj.gridBorder,
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      xaxis: {
        categories: ['Dissatisfied', 'Poor', 'Satisfied', 'VeryGood', 'Excellent'],
        lines: {
          show: true
        },
        axisBorder: {
          color: this.obj.gridBorder,
        },
        axisTicks: {
          color: this.obj.gridBorder,
        },
        title: {
          text: 'Customer Satisfaction Levels',
        },
      },
      yaxis: {
        title: {
          text: 'Count of Appraisals',
        },
        labels: {
          offsetX: 0
        }
      },
      markers: {
        size: 0,
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: 'center',
        fontFamily: this.obj.fontFamily,
        itemMargin: {
          horizontal: 8,
          vertical: 0
        },
      },
      stroke: {
        width: 3,
        curve: "smooth",
        lineCap: "round"
      },
    }
  };
  getPieChartOptions(DashboardStats: any) {
    return {
      series: [44, 55, 13, 33],
      chart: {
        height: 300,
        type: "pie",
        foreColor: this.obj.bodyColor,
        background: this.obj.cardBg,
        toolbar: {
          show: false
        },
      },
      colors: [this.obj.primary, this.obj.secondary, this.obj.info, this.obj.danger],
      stroke: {

        colors: ['rgba(0,0,0,0)']
      },
      legend: {
        show: false, // Updated to hide the legend
        position: "top",
        horizontalAlign: 'center',
        fontFamily: this.obj.fontFamily,
        itemMargin: {
          horizontal: 8,
          vertical: 0
        },
      },
      dataLabels: {
        enabled: false
      }
    }
  };

  formatId(id: string | number, idType: 'lead' | 'move' | 'packing' | 'survey' | 'delivery' | 'customer'): string {
    let prefix = '';
    switch (idType) {
      case 'lead':
        prefix = 'L-';
        break;
      case 'move':
        prefix = 'M-';
        break;
      case 'packing':
        prefix = 'P-';
        break;
      case 'survey':
        prefix = 'S-';
        break;
      case 'customer':
        prefix = 'C-';
        break;
      case 'delivery':
        prefix = 'D-';
        break;
      default:
        console.error('Invalid ID type provided');
    }
    return `${prefix}${id}`;
  }

  getEditorConfig: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // superscript/subscript
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'align': [] }]
    ]
  };

}
