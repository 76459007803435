<h5 class="dashboard-heading">REPORTS</h5>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="nav-tab">
      <span class="text">MOVES</span>
    </a>
    <ng-template ngbNavContent>
      <app-move-reports></app-move-reports>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="nav-tab">
      <span class="text"> SALES </span>
    </a>
    <ng-template ngbNavContent>
      <app-sales-report></app-sales-report>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>