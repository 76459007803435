
import { Address } from "./address.model";
import { eMoveCategoryType, eMoveType, eProcessStatus, eTransportType } from "./lead-detail.model";
import { Lead } from "./lead.model";


export class MoveDetail {
    id: number;
    leadId: number;
    leadDetailId: number;
    moveType: eMoveType;
    categoryType: eMoveCategoryType;
    transportType: eTransportType;
    surveyDate: Date | null;
    deliveryDate: Date | null;
    packagingDateFrom: Date | null;
    packagingDateTo: Date | null;
    loadingDateFrom: Date | null;
    loadingDateTo: Date | null;
    storageDateFrom: Date | null;
    storageDateTo: Date | null;
    additionalNotes: string | null;
    internalNotes: string | null;
    ownedBy: number;
    addresses: Address[] | null;
    lead: Lead | null;
    creationDate: Date;
    createdBy: number;
    // modificationDate: Date;
    modifiedBy: number;
    // deletionDate: Date;
    isDeleted: boolean;
    insuranceRequired: boolean | null;
    portOfLoading: string | null;
    portOfDischarge: string | null;
    placeOfDelivery: string | null;
    scheduledArrivalDate: Date | null;
    arrivalDate: Date | null;
    scheduledDepartureDate: Date | null;
    departureDate: Date | null;
    scheduledDispatchDate: Date | null;
    dispatchDate: Date | null;
    blNo: string | null;
    awbNo: string | null;
    carrierName: string | null;
    flightNo: string | null;
    vesselName: string | null;
    shipperInfo: string | null;
    consigneeInfo: string | null;
    notifyParty: string | null;
    cancelled: boolean | null;
    completed: boolean | null;
    cancelReason: string | null;
    selected: boolean | null;
    trackingId: string | null;
    status: eProcessStatus | null;

    constructor() {
        this.id = 0;
        this.leadId = 0;
        this.leadDetailId = 0;
        this.moveType = eMoveType.Residential;
        this.categoryType = eMoveCategoryType.DoorToDoorLocal;
        this.transportType = eTransportType.Sea;
        this.surveyDate = null;
        this.deliveryDate = null;
        this.packagingDateFrom = null;
        this.packagingDateTo = null;
        this.loadingDateFrom = null;
        this.loadingDateTo = null;
        this.storageDateFrom = null;
        this.storageDateTo = null;
        this.additionalNotes = null;
        this.internalNotes = null;
        this.ownedBy = 0;
        this.addresses = [];
        this.lead = null;
        this.creationDate = new Date();
        this.createdBy = Number(localStorage.getItem('customerId')) || 0;
        // this.modificationDate = new Date;
        this.modifiedBy = 0;
        // this.deletionDate = new Date;
        this.isDeleted = false;
        this.insuranceRequired = null;
        this.portOfLoading = null;
        this.portOfDischarge = null;
        this.placeOfDelivery = null;
        this.scheduledArrivalDate = null;
        this.arrivalDate = null;
        this.scheduledDepartureDate = null;
        this.departureDate = null;
        this.scheduledDispatchDate = null;
        this.dispatchDate = null;
        this.blNo = null;
        this.awbNo = null;
        this.carrierName = null;
        this.flightNo = null;
        this.vesselName = null;
        this.shipperInfo = null;
        this.consigneeInfo = null;
        this.notifyParty = null;
        this.cancelled = null;
        this.completed = null;
        this.cancelReason = null;
        this.selected = null;
        this.trackingId = null;
        this.status = null;
    }
}
