import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { QuillModules } from 'ngx-quill';
import { Subject } from 'rxjs';
import { BreadcrumbService } from '../../../../../../services/breadcrumb.service';
import { AppComponent } from '../../../../../app.component';
import { EmailTemplate } from '../../../models/email-template.model';
import { MovdService } from '../../../services/movd.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {
  @ViewChild('newTemplateModal') newTemplateModal!: ElementRef;
  @ViewChild('addTempModal') addTempModal: any;
  @ViewChild('addTempItemsModal') addAreaItemsModal: any;
  @ViewChild('ngx-datatable') table?: DatatableComponent;

  //activeTemplate: string | null = null;
  newTemplate = new EmailTemplate();
  emailTemplates: EmailTemplate[] = []; // Store templates in a separate property
  activeTemplate: EmailTemplate | null = null;
  public TemplateModal = new EmailTemplate();

  templates = [
    { value: 'Quotation Template', label: 'Quotation Template' },
    { value: 'Sign-up Template', label: 'Sign-Up Template' },
    { value: 'Sign-in Template', label: 'Sign-In Template' },
    { value: 'Reset Template', label: 'Reset Password Template' },
    { value: 'Forgot Password Template', label: 'Forgot Password Template' },
    { value: 'Client Docs Template', label: 'Client Docs Template' },
    { value: 'Consignment Email Template', label: 'Consignment Email Template' },
  ];

  /*templateTitle!: string | null;*/

  editorConfig: QuillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],    // superscript/subscript
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'align': [] }]
    ]
  };

  emailTempArray: EmailTemplate[] = [];
  private modalRef: NgbModalRef | undefined;
  _searchTemp: string = '';
  _tempTableData: any[] = [];
  _tempDataList: any[] = [];
  _tempTableColumns: any[] = [];
  _searchTempItems: string = '';
  tempExists: boolean = false;


  /*_title = "Add New Template";*/

  _saveTitle = "Create";
  _title = "Add New Template";

  ColumnMode = ColumnMode;
  _temp = new EmailTemplate();
  _tempTitle!: string | null;
  _tempSubject!: string | null;
  _tempBody!: string | null;
  _tempModalRef: NgbModalRef | undefined;
  _areaItemsModalRef: NgbModalRef | undefined;
  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private modalService: NgbModal,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Template Settings', url: '/template-setting' },


    ]);

    /* this.getEmailTemplates();*/
    this.refreshTempTableData();
  }

  //getEmailTemplates(): void {
  //  const ownedBy = Number(localStorage.getItem('companyId'));

  //  this._movdService.getEmailTemplates(ownedBy).subscribe({
  //    next: (data: EmailTemplate[]) => {
  //      if (data.length > 0) {
  //        this.emailTempArray = data; // Assuming you want the first template
  //        console.log('Email Template retrieved successfully:', this.emailTempArray);
  //      } else {
  //        console.warn('No email templates found.');
  //      }
  //    },
  //    error: (error) => {
  //      console.error('Error retrieving email templates:', error);

  //    }
  //  });
  //}


  setActiveTemplate(template: EmailTemplate): void {
    this.activeTemplate = template;
    // Perform any other actions you need when a template is selected
  }
  // performTempSearch() {
  //   if (this._searchTemp === '') {
  //     this.refreshTempTableData();
  //   } else {
  //     const filteredData = this._tempTableData.filter((row: any) =>
  //       Object.values(row).some((value: any) =>
  //         String(value).toLowerCase().includes(this._searchTemp.toLowerCase())
  //       )
  //     );
  //     this._tempTableData = filteredData;
  //   }

  //   if (this.table) {
  //     this.table.offset = 0;
  //   }

  //   this.table?.recalculate();
  //   this.table?.bodyComponent.updateOffsetY();
  // }
  performTempsSearch() {
    // Check if the search term is empty
    if (this._searchTemp === '') {
      // Reset the displayed leads list to the original data
      this._tempTableData = this._tempDataList;
    } else {
      // Filter the original data array based on the search term
      const filteredData = this._tempDataList.filter((row: any) => {
        // Convert all values of the row to string and check if any value contains the search term
        return Object.values(row).some((value: any) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(this._searchTemp.toLowerCase());
          }
          return false; // Skip non-string values
        });
      });

      // Assign the filtered data to the displayed leads array
      this._tempTableData = filteredData;
    }

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }
  refreshTempTableData() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._movdService.getEmailTemplates(companyId).subscribe({
      next: (data: any) => {
        // console.log("EmailTemplates", data);

        if (data && data.length > 0) {
          this._tempTableData = data;
          this._tempDataList = data;
          this._tempTableColumns = Object.keys(data[0])
            .filter(
              (key) =>
                ![
                  'id',
                  'companyId',
                  'creationDate',
                  'deletionDate',
                  'createdBy',
                  'modificationDate',
                  'modifiedBy',
                  'isDeleted',
                  'isActive',
                  'ownedBy',
                  'body'
                ].includes(key)
            )
            .map((key) => {
              let header: string;
              let isCheckbox: boolean = false;

              switch (key) {
                case 'title':
                  header = 'Template Name';
                  break;

                case 'subject':
                  header = 'Subject';
                  break;

                case 'action':
                  header = 'Action';
                  break;

                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }

              return { header, isCheckbox };
            });

        }
      },
      error: (error: any) => {
        console.error('Error getting templates', error);
      }
    });
  }





  TempPreviewClick(id: number) {
    this.gettempAndOpenModal(Number(id));
  }
  gettempAndOpenModal(id: number): void {
    /* this.areaExists = false;*/
    const data = this._tempTableData.filter(item => item.id === id)[0];
    if (data) {
      this._temp = data;
      this._tempTitle = this._temp.title;
      this._tempSubject = this._temp.subject;
      this._tempBody = this._temp.body;
      this.openEditTemp();
    }
  }

  openEditTemp() {
    this._saveTitle = "Update";
    this._title = "Update Template";
    // this._modalService.open(this.addAreaModal, { size: 'md' }).result.then(
    //   (result) => {
    //   }
    // ).catch((res) => { });
    this._tempModalRef = this._modalService.open(this.newTemplateModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._tempModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }
  saveTemplate(modal: any): void {
    if (this.tempExists || this._tempTitle === null) {

      return;
    }

    const companyId = Number(localStorage.getItem('companyId'));
    this._temp.title = this._tempTitle;
    this._temp.subject = this._tempSubject;
    this._temp.body = this._tempBody;
    this._temp.ownedBy = companyId;

    // console.log("save template ", this._temp);

    this._movdService.saveEmailTemplates(this._temp).subscribe({
      next: (response: any) => {
        // Close the modal after saving
        this._modalService.dismissAll();

        this._tempTitle = null;
        this._tempSubject = null;
        this._tempBody = null;

        this._appComponent.showSuccessSwal('created', 'record');
        this.refreshTempTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('creating', 'record');
        console.error('Error', error);
      }
    });
  }

  updateTempStatus(row: any, status: any) {
    // console.log(`updating template status ${status}`);
    // console.log("updating template status", row);
    const tempId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    // console.log(`tempId ${tempId} updating template ${row} and status ${status}`);

    this._movdService.updatEmailTemplateStatus(tempId, status).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'template');
        this.refreshTempTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
        // console.log('area status updated', response);
      },
      error: (error: any) => {
        console.error('Error template status:', error);
        this._appComponent.showErrorSwal('updating', 'area');
      }
    });
  }

  validateItemExists(): void {
    const input = this._tempTitle;
    if (!input || input.trim().length === 0) {
      // Invalid or empty email format, reset 'emailExists' and return.
      this.tempExists = false;
      return;
    }

    if (this._tempTableData && this._tempTableData.length > 0) {
      this.tempExists = this._tempTableData.some(item => item && item.title && item.title.toLowerCase() === input.toLowerCase());

    } else {
      this.tempExists = false; // Reset 'emailExists' if 'tableData' is empty

    }
  }

  async deleteTemplate(row: EmailTemplate) {

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      'You are about to delete this template',
      'Yes, delete it!',
    );

    if (await confirmed === false) {
      return;
    }

    this._movdService.deleteEmailTemplate(row.id)
      .subscribe({
        next: () => {
          // console.log('Template deleted successfully.');
          // Remove the deleted template from the local emailTempArray
          this._tempTableData = this._tempTableData.filter(item => item.id !== row.id);
          this._appComponent.showSuccessSwal('delete', 'template');
        },
        error: (error) => {
          console.error('Error deleting template:', error);
          this._appComponent.showErrorSwal('delete', 'template');
        }
      });
  }

  addNewTemplate(): void {
    this._saveTitle = "Save";
    this._title = "Add New Template";

    this._tempTitle = null;
    this._tempSubject = null;
    this._tempBody = null;
    this.openModal();
  }



  openModal(): void {


    if (this.newTemplateModal) {
      this.modalRef = this.modalService.open(this.newTemplateModal, { centered: true });
    }
  }

  closeModal(type: string, modal: any) {
    switch (type) {
      case 'Temp':
        this._temp = new EmailTemplate();
        this._tempTitle = null;
        this._tempSubject = null;
        this._tempBody = null;
        this.tempExists = false;
        this.refreshTempTableData();
        modal.close();
        break;
      case 'TempItems':
        /* this._areaItems = new AreaItemsModel();*/
        /* this.emailTempArray();*/
        modal.close();
        break;

      default:
      // console.log('Unknown type: ' + type);
    }
  }
}
