import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { __makeTemplateObject } from 'tslib';
import { BehaviorSubject } from 'rxjs';
import { SaleServicePaths } from '../enums/sale-service-paths';
import { PipeModel } from 'src/app/views/admin/super-admin/super-admin.models';
import { DealsModel, DealsResponse } from 'src/app/views/crm/sales/deals/deals.models';
import { SalesDTO } from 'src/app/views/movd/sales-manager/sales-manager-dashboard/dashboard.models';
@Injectable({
  providedIn: 'root'
})
export class SaleService {
  readonly APIUrl = environment.baseUrl;
  private _overviewListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public overviewList$: Observable<any[]> = this._overviewListSubject.asObservable();


  constructor(private http: HttpClient) { }
  addPipe(pipe: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addPipe, pipe);
  }
  getDealsStats(companyId: any): Observable<any[]> {
    const url = `${this.APIUrl}${SaleServicePaths.getDealsStats.replace('{companyId}', companyId)}`;
    return this.http.get<any[]>(url);
  }
  getDealsStatsForChart(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + SaleServicePaths.getDealsStatsForChart + '/' + companyId);
  }
  getDealsSummaryLast12Months(): Observable<any[]> {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + SaleServicePaths.getDealsSummaryLast12Months + '/' + companyId);
  }

  updatePipe(pipe: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updatePipe, pipe);
  }

  updatePipeStatus(pipeId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Sales/UpdatePipeStatus?id=${pipeId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }
  getTopSales() {
    const ownedBy = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId') || '0';
    const params = new HttpParams()
      .set('ownedBy', ownedBy);
    // console.log("reset password params", params);
    return this.http.get(`${this.APIUrl}/Sales/GetTopSales`, { params: params });
  }


  getTopSalesRep() {
    const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    const url = this.APIUrl + SaleServicePaths.getTopSalesRep + '/' + companyId;
    return this.http.get(url);
  }
  getPipes(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getPipes.replace('{companyId}', id)}`;
    return this.http.get(url);
  }

  deletePipe(id: any) {
    return this.http.delete(this.APIUrl + SaleServicePaths.deleteDeal.replace('{id}', id));
  }

  saveReorderedPipes(statusList: PipeModel[]): Observable<any> {
    return this.http.post(`${this.APIUrl}/Sales/SaveReorderedPipes`, statusList);
  }

  addPipeAttribute(pipeAttrb: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addPipeAttribute, pipeAttrb);
  }

  getPipeAttributes(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getPipeAttributes.replace('{companyId}', id)}`;
    return this.http.get(url);
  }

  updatePipeAttribute(pipeAttrb: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updatePipeAttribute, pipeAttrb);
  }

  updatePipeAttributeStatus(pipeAttrbId: any, status: boolean) {
    const url = `${this.APIUrl}/Sales/UpdatePipeAttrbStatus?id=${pipeAttrbId}&status=${status}`;
    return this.http.post(url, null);
  }

  addDeal(deal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.addDeal, deal);
  }

  saveDeal(deal: any): Observable<any> {
    return this.http.post(this.APIUrl + SaleServicePaths.addDeal, deal);
  }

  updateDeal(updatedDeal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updateDeal, updatedDeal);
  }

  updateDealFromProfile(updatedDeal: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.updateDealFromProfile, updatedDeal);
  }

  // getDeals() {
  //   const companyId = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
  //   const url = this.APIUrl + SaleServicePaths.getDeals + '/' + companyId;
  //   return this.http.get(url);
  // }
  getDeals(page: number | null = null, pageSize: number | null = null, searchTerm: string | null = null): Observable<{ deals: SalesDTO[], totalRecords: number }> {
    const ownedBy = localStorage.getItem('role') === 'SuperAdmin' ? '0' : localStorage.getItem('companyId');
    let params = new HttpParams()
    if (ownedBy !== null) params = params.set('ownedBy', ownedBy.toString());
    if (page !== null) params = params.set('page', page.toString());
    if (pageSize !== null) params = params.set('pageSize', pageSize.toString());
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    const url = `${this.APIUrl}/Sales/GetDeals`;
    return this.http.get<any>(url, { params });
  }

  deleteDeal(id: any) {
    return this.http.post(this.APIUrl + SaleServicePaths.deleteDeal.replace('{id}', id), {});
  }

  getDeal(id: any) {
    const url = `${this.APIUrl}${SaleServicePaths.getDeal.replace('{id}', id)}`;
    return this.http.get(url);
  }
  //can't define type id as number as we can't assign number in replacing string
}
