import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ContactService } from "src/services/contact.service";
import { SaleService } from "src/services/sale.service";

import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import {
  ActivityModel,
  NotesModel,
  CallsModel,
  EmailsModel,
  AttachmentModel,
  MeetingsModel,
  TasksModel,
  CustomerDTO,
  DealDetailsDTO,
  CustomerUpdateDTOModel,
  DealUpdateDTOModel,
  TasksResponse
} from './profile.models';
import { HttpClient } from '@angular/common/http';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { AppComponent } from "src/app/app.component";
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { DealsModel } from '../sales/deals/deals.models';
import { Address, CountryOptions, eAddressType } from '../../movd/models/address.model';
import { MovdService } from '../../movd/services/movd.service';
import { AuthService } from 'src/services/auth.service';
import { GeneralService } from 'src/services/general.service';
import { Breadcrumb, BreadcrumbService } from 'src/services/breadcrumb.service';
import { LeadBoardDTO } from '../../movd/lead-board/lead-board.model';
import { MoveBoardDTO } from '../../movd/moves-board/moves-board.model';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  customerCompany: any;
  // Region: Properties
  _deal = new DealsModel();
  _contacts: any[] = [];

  // Nav
  defaultNavActiveId: any;
  @ViewChild('defaultNav') defaultNav!: NgbNav;


  _taskUpdate: boolean = false;
  _saveTaskTitle: string = 'Create';
  // Variables
  _viewname = '';
  _idForTask: number = 0;
  _isDeal = false;
  _customerId = 0;
  _dealId = 0;
  _currentUserId = 0;
  _isMove: boolean = false;
  _role: string = "";
  _lastActivityDate: any;
  [key: string]: any;

  // Objects
  _profile = new CustomerDTO();
  _note = new NotesModel();
  _call = new CallsModel();
  _email = new EmailsModel();
  _task = new TasksModel();
  _meeting = new MeetingsModel();
  _dealDetailDTO = new DealDetailsDTO();
  _activity = new ActivityModel();
  _attach = new AttachmentModel();
  countries = CountryOptions;
  // Lists
  _notesList: any[] = [];
  _callsList: any[] = [];
  _emailsList: any[] = [];
  _tasksList: any[] = [];
  _meetingsList: any[] = [];
  _attachList: any[] = [];
  _overviewList: any[] = [];
  _overviewTaskList: any[] = [];
  _overviewRecentCommunicationList: any[] = [];
  _overviewNotesList: any[] = [];
  _dealsList: any[] = [];
  _files: any = [];
  _companies: any[] = [];
  _companyUsers: any[] = [];
  // Flags for editing profile
  _isEditMode: boolean = false;
  _originalProfileName: string = '';
  _originalDealName: string = '';

  // Edit Flags for company/contact
  isEditContactName: boolean = false;
  isEditContact: boolean = false;
  isEditDealName: boolean = false;
  isModifyDealName: boolean = false;
  isEditNoOfEmployees: boolean = false;
  isEditEmail: boolean = false;
  isEditPhone: boolean = false;
  isEditType: boolean = false;
  isEditCountry: boolean = false;
  isEditCity: boolean = false;
  isEditAddr: boolean = false;
  isEditPosition: boolean = false;
  isEditDetail: boolean = false;
  isEditCompany: boolean = false;
  isEditOpenPositions: boolean = false;
  isEditCompanyURL: boolean = false;
  isEditLinkedInURL: boolean = false;
  // for deal
  isEditAmount: boolean = false;
  isEditProbability: boolean = false;

  // for loading page properly
  isDataLoaded = false;
  isLoading: boolean = true;

  // for saving attachment
  _mimeType: any;
  _createdDate: any;
  _selectedFile!: Blob | File;
  _showConfirmationDialog: boolean = false;
  _fetchedImage: any;
  _formData: any;

  // for showing current time
  selectedDate!: string;
  selectedTime!: string;

  // for updating contact in deal
  companyId: number = 0;

  // for getting leads for profile
  _leadDetailsList: LeadBoardDTO[] = [];
  _moveDetailsList: MoveBoardDTO[] = [];
  breadcrumbMappings: { [key: string]: Breadcrumb[] } = {};

  isSurveyMode!: boolean;
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;
  _types: any = null;
  selectedSalesType: any = null;
  previousUrl: string = '';
  constructor(private _route: ActivatedRoute,
    private _contactService: ContactService,
    private _saleService: SaleService,
    private _modalService: NgbModal,
    private _router: Router,
    public _generalService: GeneralService,
    private _appComponent: AppComponent,
    @Inject(DOCUMENT) private document: Document,
    private _movdService: MovdService,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this._userRole = localStorage.getItem('role');
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
    // console.log("current id ", this._currentUserId)
    let companyId = Number(localStorage.getItem('companyId'));
    if (companyId) {
      this.companyId = companyId
    }

    const now = new Date();
    this.selectedDate = this.formatDate(now);
    this.selectedTime = this.formatTime(now);
    this.isLoading = true;
    this.toggleSidebar(new Event('tempEvent'));
  };
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.add('sidebar-folded');
  }

  async ngOnInit(): Promise<void> {


    this.previousUrl = this.breadcrumbService.getPreviousUrl();
    this.updateBreadcrumbs();

    // this.breadcrumbService.setBreadcrumbs([
    //   { text: 'Dashboard', url: '/dashboard' },
    //   { text: 'Profile', url: '/profile' },

    // ]);

    const currentUserId = localStorage.getItem('currentUserId');
    this._currentUserId = Number(currentUserId);
    //console.log("currentUserId", this._currentUserId)
    this._isDeal = false;
    const now = new Date();
    this.fetchCompanies();
    this.fetchData('Task');
    this.selectedDate = this.formatDate(now);
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });



    await this._route.queryParams.subscribe(
      params => {
        // console.log("params ", params);

        const id = Number(params['id']);
        this._idForTask = id;
        // console.log("customer id ", id);
        this._viewname = params['text'];
        this._customerId = id;
        this._dealId = id;

        if (this._viewname === 'contact' || this._viewname === 'company') {
          this._isDeal = false;
          // Retrieve permissions from local storage
          const permissions = this._authService.getPermissionsFromLocalStorage();

          // Set permission variables
          this._readPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
          this._createPermission = permissions.includes('CRM - Contacts.Create') || this._userRole === 'CompanyAdmin';
          this._updatePermission = permissions.includes('CRM - Contacts.Update') || this._userRole === 'CompanyAdmin';
          this._deletePermission = permissions.includes('CRM - Contacts.Delete') || this._userRole === 'CompanyAdmin';

          if (this._readPermission === false) {
            this.checkPermissions();
            return;
          }


          this._contactService.getContact(this._customerId).subscribe(
            (data: any) => {
              if (data && data.length > 0) {
                this._profile = data[0];
                console.log('profile data', this._profile);
                this._types = [
                  { id: 0, value: 'None' },
                  { id: 1, value: 'Sales' },
                  { id: 2, value: 'Partner' },
                  { id: 3, value: 'Vendor' }
                ];
                const mappedSalesType = this._types.find((type: { id: number; }) => type.id === this._profile.salesType) || null;
                // console.log('sale type', mappedSalesType);
                this.selectedSalesType = mappedSalesType || null;
                // console.log('selected sale type', this.selectedSalesType);
                this.fetchLatestActivities();
                this.getDealsList();
                this.getLeadDetailsForProfile(this._customerId);
                this.getMoveDetailsForProfile(this._customerId);
                this.isDataLoaded = true;
                setTimeout(() => {
                  this.isLoading = false;
                }, 0);
                if (this._profile && this._profile.hasOwnProperty('role')) {
                  this._role = params['role'];
                }
              } else {
                // show a dialogue box to display this contact is deleted and go to previous page
                this.isLoading = false;
                this._appComponent.showErrorDialog('Error', 'This Contact does not exist.');
              }
            },
            error => {
              console.error("error getting a contact", error);
            }
          );
        }
        else {
          this._isDeal = true;
          // Retrieve permissions from local storage
          const permissions = this._authService.getPermissionsFromLocalStorage();

          // Set permission variables
          this._readPermission = permissions.includes('CRM - Sales.Read') || this._userRole === 'CompanyAdmin';
          this._createPermission = permissions.includes('CRM - Sales.Create') || this._userRole === 'CompanyAdmin';
          this._updatePermission = permissions.includes('CRM - Sales.Update') || this._userRole === 'CompanyAdmin';
          this._deletePermission = permissions.includes('CRM - Sales.Delete') || this._userRole === 'CompanyAdmin';

          if (!this._readPermission) {
            this.checkPermissions();
            return;
          }

          // console.log("id", id);
          this.getDealForProfile(id);
        }
      });

    this.getCompanyUsers();
  }

  private updateBreadcrumbs(): void {
    let previousOfPreviousUrl = this.breadcrumbService.getPreviousOfPreviousUrl();
    let urlParts = previousOfPreviousUrl.split('?');
    let path = urlParts[0];

    // Define the base breadcrumb mappings
    this.breadcrumbMappings = {
      '/leads-board': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Leads Dashboard', url: '/leads-board' },
        { text: 'Profile', url: '' },
      ],
      '/survey-list': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Survey List', url: '/survey-list' },
        { text: 'Profile', url: '' },
      ],
      '/sales': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Sales', url: '/sales' },
        { text: 'Profile', url: '' },
      ],
      '/packing-list': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Profile', url: '' },
      ],
      '/delivery-list': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Delivery List', url: '/delivery-list' },
        { text: 'Profile', url: '' },
      ],
      '/contact': [
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Contacts & Companies', url: '/contact' },
        { text: 'Profile', url: '' },
      ],
      '/move-detail': [
        { text: 'Move Dashboard', url: '/moves-board' },
        { text: 'Moves Information Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ],
      '/lead-detail': [
        { text: 'Leads Dashboard', url: '/leads-board' },
        { text: 'Lead Information Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ],

      '/packing-details': [
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Packing Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ],
      '/delivery-details': [
        { text: 'Delivery List', url: '/delivery-list' },
        { text: 'Delivery Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ],
    };

    // Update the lead-detail breadcrumb based on previousOfPreviousUrl
    if (path === '/survey-list') {
      console.log("url", this.breadcrumbService.getPreviousUrl())
      this.breadcrumbMappings['/lead-detail'] = [
        // { text: 'Dashboard', url: '/dashboard''/dashboard' },
        { text: 'Survey List', url: '/survey-list' },
        { text: 'Leads Information Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ];
    }
    else if (path === '/leads-board') {
      this.breadcrumbMappings['/lead-detail'] = [
        // { text: 'Dashboard', url: '/dashboard' },
        { text: 'Leads Dashboard', url: '/leads-board' },
        { text: 'Lead Information Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Profile', url: '' },
      ];
    }

    // Set the breadcrumbs based on the previous URL
    let previousUrl = this.breadcrumbService.getPreviousUrl();
    for (const key in this.breadcrumbMappings) {
      if (previousUrl.includes(key)) {
        this.breadcrumbService.setBreadcrumbs(this.breadcrumbMappings[key]);
        return;
      }
    }

    // // Default breadcrumbs if no match is found
    // this.breadcrumbService.setBreadcrumbs([
    //   { text: 'Dashboard', url: '/dashboard' },
    // ]);
  }





  getContact() {
    this._contactService.getContact(this._customerId).subscribe(
      (data: any) => {
        this._profile = data[0];
        // console.log('profile data', this._profile);
        this._types = [
          { id: 0, value: 'None' },
          { id: 1, value: 'Sales' },
          { id: 2, value: 'Partner' },
          { id: 3, value: 'Vendor' }
        ];
        const mappedSalesType = this._types.find((type: { id: number; }) => type.id === this._profile.salesType);
        // console.log('sale type', mappedSalesType);
        this.selectedSalesType = mappedSalesType || null;
        // console.log('selected sale type', this.selectedSalesType);
        this.fetchLatestActivities();
        this.getDealsList();
        this.getLeadDetailsForProfile(this._customerId);
        this.isDataLoaded = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      },
      error => {
        console.error("error getting a contact", error);
      }
    );
  }
  async checkPermissions() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit the profile page', 'read');
    // console.log("go to dashboard. no read permission");
  }

  async checkUpdatePermissions() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to update this data', '');
  }

  // get deal 
  getDealForProfile(id: number) {
    this._saleService.getDeal(id).subscribe(
      (data: any) => {
        // console.log('deals detail', data);

        if (data && data.length > 0) {
          this._dealDetailDTO = data[0];
          // console.log('deals detail', this._dealDetailDTO)
          this.getContacts();
          this.fetchLatestActivities();
          setTimeout(() => {
            this.isLoading = false;
          }, 0);

        }
        this.isDataLoaded = true;
      },
      error => {
        console.error("error getting a contact", error);
      }
    );
  }


  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  goToTab(tabId: number) {
    this.defaultNav.select(tabId);
  }

  // from show contacts
  deleteProfileClick(id: any) {
    this._contactService.deleteContact(id).subscribe({
      next: response => {
        this._appComponent.showSuccessSwal('deleted', 'contact');
        this._router.navigate(['/contact']);
      },
      error: error => {
        console.error("error deleting contact", error);
        this._appComponent.showErrorSwal('deleting', 'contact');
      }
    });
  }

  deleteDealClick(id: any) {
    // console.log('Deleting deal with id:', id);
    if (confirm('Are you sure you want to delete record?')) {
      this._saleService.deleteDeal(id).subscribe({
        next: response => {
          this._appComponent.showSuccessSwal('deleted', 'deal');

          this._router.navigate(['/sales']);
        },
        error: error => {
          console.error("error deleting deal", error);
          this._appComponent.showErrorSwal('deleting', 'deal');
        }
      });
    }
  }

  onFileSelected(event: any) {
    this._selectedFile = event.target.files[0];
  }

  onSubmit() {
    if (!this._selectedFile) {
      alert('Please select a file to upload');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const fileData = reader.result as ArrayBuffer;
      //console.log("file data", fileData)

      const fileBytes = Array.from(new Uint8Array(fileData));

      // Assign the fileBytes array to the Url property

      const base64String = btoa(String.fromCharCode.apply(null, fileBytes));
      this._attach.url = base64String;

      //Continue with saving the attachment in the database
      const formData = new FormData();
      formData.append('file', this._selectedFile);

      this._selectedFile = null!;
    };

    reader.readAsArrayBuffer(this._selectedFile);
  }

  RecentCommfilterOverviewList() {
    this._overviewRecentCommunicationList = this._overviewList.filter(dataItem => {
      // console.log("RecentCommfilterOverviewList");
      return (
        dataItem.type === 'Email' ||
        dataItem.type === 'Call' ||
        dataItem.type === 'Meeting'
      );
    });
  }

  TasksfilterOverviewList() {
    // console.log("TasksfilterOverviewList");
    this._overviewTaskList = this._overviewList.filter(dataItem => dataItem.type === 'Task');
  }

  NotesfilterOverviewList() {
    // console.log("NotesfilterOverviewList");
    this._overviewNotesList = this._overviewList.filter(dataItem => dataItem.type === 'Notes');
  }

  openNotesModel(content: TemplateRef<any>, id = 0) {
    this._note = new NotesModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.openModal(content, "Note", id);
  }

  openEmailsModel(content: TemplateRef<any>, id = 0) {
    this._email = new EmailsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.openModal(content, "Email", id);
  }

  openCallsModel(content: TemplateRef<any>, id = 0) {
    this._call = new CallsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.openModal(content, "Call", id);
  }

  openTasksModel(content: TemplateRef<any>, id = 0) {
    this._task = new TasksModel();
    this.openModal(content, "Task", id);
  }

  openMeetingsModel(content: TemplateRef<any>, id = 0) {
    this._meeting = new MeetingsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Meeting", id);
  }

  //openModal(content: TemplateRef<any>, title: string, id: number) {

  //  //console.log("id", id);

  //  if (id === 0) {
  //    title = "Add " + title;
  //  } else {
  //    title = "Update " + title;
  //  }

  //  this._modalService.open(content, {}).result.then((result) => {
  //    // Handle the result if needed
  //  }).catch((res) => { });
  //}

  isOverdue(task: TasksModel): boolean {
    const currentDateTime = new Date().getTime();
    const taskDueDateTime = new Date(task.dueDate).getTime();

    return currentDateTime > taskDueDateTime;
  }
  saveData(entity: any) {

    // console.log("save data", entity);

    const isAdding = entity.id === 0;

    if (this._viewname === 'deal') {
      entity.dealId = this._dealId;
    } else {
      entity.customerId = this._customerId;
    }

    entity.createdBy = this._currentUserId;
    // console.log(" check created by ", this._currentUserId);
    const activityDate = `${this.selectedDate}T${this.selectedTime}:00.000Z`;
    entity.activityDate = activityDate;

    const entityType = entity.entityType;

    // console.log("save entityType", entityType);
    // console.log("isAdding", isAdding);
    // console.log("currentUserIsdsdd", entity)
    let observable: Observable<any> | null = null;;

    if (entityType === 'NotesModel') {

      // console.log("save data", entityType);
      observable = isAdding ? this._contactService.addCustomerNote(entity) :
        this._contactService.updateCustomerNote(entity);
    } else if (entityType === 'EmailsModel') {
      observable = isAdding ? this._contactService.addCustomerEmail(entity) :
        this._contactService.updateCustomerEmail(entity);
    } else if (entityType === 'CallsModel') {
      observable = isAdding ? this._contactService.addCustomerCall(entity) :
        this._contactService.updateCustomerCall(entity);
    } else if (entityType === 'TasksModel') {
      observable = isAdding ? this._contactService.addTask(entity) :
        this._contactService.updateTask(entity);
    } else if (entityType === 'MeetingsModel') {
      observable = isAdding ? this._contactService.addMeeting(entity) :
        this._contactService.updateMeeting(entity);
    }
    // console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          // console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityName);
          } else {
            this.fetchData(entityType);
            this.fetchLatestActivities();
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityName);
          }
        },
        (error: any) => {
          console.error("Error:", error);
          // Handle errors as needed
        }
      );
    }
  }

  saveNote() {
    // console.log("save note")
    if (this._note.details.trim().length === 0) {
      return; // validation Failed
    }
    this.saveData(this._note);
  }

  saveEmail() {
    if (this._email.details.trim().length === 0) {
      return;
    }
    this.saveData(this._email);
  }

  saveCall() {
    if (this._call.details.trim().length === 0) {
      return;
    }

    this._call.date = this.selectedDate; // Adding date to the _call object
    this._call.time = this.selectedTime; // Adding time to the _call object



    this.saveData(this._call);
  }

  saveTask() {

    if (this._task.title.trim().length === 0) {
      return;
    }
    this.saveData(this._task);
  }

  saveMeeting() {
    if (this._meeting.details.trim().length === 0) {
      return;
    }
    this.saveData(this._meeting);
  }

  editNote(dataItem: any) {
    this._note.title = dataItem.title;
    this._note.details = dataItem.details;
    this._note.activityDate = dataItem.activityDate;
    this._note.id = dataItem.id;

  }

  editEmail(dataItem: any) {
    this._attach.title = dataItem.title;
    this._email.details = dataItem.details;
    this._email.from = dataItem.from;
    this._email.to = dataItem.to;
    this._email.activityDate = dataItem.activityDate;
    this._email.id = dataItem.id;
  }

  editCall(dataItem: any) {
    this._call.details = dataItem.details;
    this._call.activityDate = dataItem.activityDate;
    this._call.id = dataItem.id;
    this._call.outcome = dataItem.outcome;
    this._call.direction = dataItem.direction;
  }

  editTask(dataItem: any) {
    // console.log("dataItem", dataItem);

    this._task.id = dataItem.id;
    this._task.title = dataItem.title;
    this._task.details = dataItem.details;
    this._task.activityDate = dataItem.activityDate;
    this._task.status = dataItem.status;
    this._task.assignedto = dataItem.assignedto;
    this._task.dueDate = this.formatTaskDate(dataItem.dueDate);
    this._task.done = dataItem.done;
    this._task.priority = dataItem.priority;
    this._task.queue = dataItem.queue;
    this._task.type = dataItem.type;
    // console.log("this._task", this._generalService.formatDate(this._task.dueDate, null))
    // console.log("this._task", this._task);
  }

  editMeeting(dataItem: any) {
    this._meeting.id = dataItem.id;
    this._meeting.title = dataItem.title;
    this._meeting.details = dataItem.details;
    this._meeting.activityDate = dataItem.activityDate;
    this._meeting.organizer = dataItem.organizer;
    this._meeting.location = dataItem.location;
    this._meeting.startDate = dataItem.startDate;
    this._meeting.endDate = dataItem.endDate;
    this._meeting.attendance = dataItem.attendance;
    this._meeting.outcome = dataItem.outcome;

  }

  async confirmAndDelete(type: string, id: any) {
    // const confirmation = confirm(`Are you sure you want to delete this ${type}?`);

    let confirmed = this._appComponent.showConfirmationDialog("Are You Sure?", `Are you sure you want to delete this ${type}?`, "Yes");
    if (await confirmed === false) {
      return;
    }

    try {
      switch (type) {
        case 'Note':
          await this._contactService.deleteCustomerNote(id).toPromise();
          await this.fetchNotes();
          break;
        case 'Call':
          await this._contactService.deleteCustomerCall(id).toPromise();
          await this.fetchCalls();
          break;
        case 'Email':
          await this._contactService.deleteCustomerEmail(id).toPromise();
          await this.fetchEmails();
          break;
        case 'Task':
          await this._contactService.deleteTask(id).toPromise();
          await this.fetchTasks();
          break;
        case 'Meeting':
          await this._contactService.deleteMeeting(id).toPromise();
          await this.fetchMeetings();
          break;
        default:
          break;
      }
      this._appComponent.showSuccessSwal('Deleted', type);
    } catch (error: any) {
      console.error(`error deleting ${type}`);
      this._appComponent.showErrorSwal('Deleting', type);
    }
  }

  async deleteNote(id: any) {
    await this.confirmAndDelete("Note", id);
  }

  async deleteCall(id: any) {
    await this.confirmAndDelete("Call", id);
  }

  async deleteEmail(id: any) {
    await this.confirmAndDelete("Email", id);
  }

  async deleteTask(id: any) {
    await this.confirmAndDelete("Task", id);
  }

  async deleteMeeting(id: any) {
    await this.confirmAndDelete("Meeting", id);
  }

  async fetchData(type: string): Promise<void> {
    try {
      let data: any;
      switch (type) {
        case 'Note':
        case 'NotesModel':
          data = await this._contactService.getCustomerNotes(this._customerId, this._isDeal).toPromise();
          this._notesList = data;
          break;
        case 'Call':
        case 'CallsModel':
          data = await this._contactService.getCustomerCalls(this._customerId, this._isDeal, this._isMove).toPromise();
          this._callsList = data;
          break;
        case 'Email':
        case 'EmailsModel':
          data = await this._contactService.getCustomerEmails(this._customerId, this._isDeal, this._isMove).toPromise();
          this._emailsList = data;
          break;
        case 'Task':
        case 'TasksModel':
          const taskResponse: TasksResponse | undefined = await this._contactService.getTasks(this._customerId, this._isDeal, null, null, null).toPromise();

          if (taskResponse && taskResponse.tasks) {
            this._tasksList = taskResponse.tasks;
            this._tasksList = this._tasksList.filter((task: TasksModel) => task.assignedto === this._currentUserId);

            const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
            (this as any)[listName] = this._tasksList;

            (this as any)[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
              new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
            );

            const mostRecentTask = this._tasksList.length === 1 ? this._tasksList[0] : this._tasksList[this._tasksList.length - 1];

            if (mostRecentTask) {
              mostRecentTask.timeAgo = this.calculateActivityTime(mostRecentTask.activityDate);
            }
            // Add assignedToName to each task
            this._tasksList.forEach((task) => {
              const assignedUser = this._companyUsers.find(user => user.id === task.assignedTo);
              task.assignedToName = assignedUser ? assignedUser.customerName : null;
            });
          } else {
            this._tasksList = []; // Handle the case where no tasks are returned
          }

          break;
        case 'Meeting':
        case 'MeetingsModel':
          data = await this._contactService.getMeetings(this._customerId, this._isDeal, this._isMove).toPromise();
          this._meetingsList = data;
          break;
        default:
          break;
      }

      if (data && data.length > 0) {
        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        this[listName] = data;

        this[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = data.length === 1 ? data[0] : data[data.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }
      }
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }



  async fetchNotes(): Promise<void> {
    //console.log("fetchNotes");
    await this.fetchData('Note');
  }

  async fetchCalls(): Promise<void> {
    await this.fetchData('Call');
  }

  async fetchEmails(): Promise<void> {
    await this.fetchData('Email');
  }

  async fetchTasks(): Promise<void> {
    await this.fetchData('Task');
  }

  async fetchMeetings(): Promise<void> {
    await this.fetchData('Meeting');
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object

    // Check if activityTime is a valid date
    if (isNaN(activityTime.getTime())) {
      console.error('Invalid activityDate:', activityDate);
      return '';
    }

    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }


  async getDealsList() {
    this._saleService.getDeals().subscribe({
      next: (data: any) => {
        // console.log("deals list", data);
        const filteredData = data.filter((item: any) => {
          //const currentUserId = Number(localStorage.getItem('currentUserId'));
          const id = Number(this._profile.id);

          return (
            Number(item.contactId) === id ||
            item.createdBy === id ||
            item.salesRepId === id ||
            item.dealOwnerId === id
          );
        });

        // console.log("filtered data", filteredData);
        this._dealsList = filteredData as [];
        //this._dealsList = this._dealsList.slice(0, 3);
        //this._dealsList = filteredData;
      },
      error: (error: any) => {
        console.error('Error fetching deals:', error);
      }
    });
  }

  async fetchLatestActivities(): Promise<void> {
    // console.log(`_customerid ${this._customerId} And _isDeal ${this._isDeal}`);

    const data: any = await this._contactService.getLatestActivities(this._customerId, this._isDeal).toPromise();
    // console.log("overviewlist ", data);

    this._overviewList = data;
    if (data.length > 0) {
      this._overviewList.sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
        new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
      );

      this._lastActivityDate = this._overviewList[0].activityDate;
    }
    this.TasksfilterOverviewList();
    this.RecentCommfilterOverviewList();
    this.NotesfilterOverviewList();
  }

  showAttachment(item: any) {

    const modalRef = this._modalService.open(AttachmentModel);
    modalRef.componentInstance.attachment = item;
  }


  onEditClick(): void {
    this._isEditMode = true;
    this._originalProfileName = this._profile.customerName;
  }

  onSaveClick(): void {
    this._isEditMode = false;
  }

  onCancelClick(): void {
    this._isEditMode = false;
    this._profile.customerName = this._originalProfileName;
  }
  toggleTaskDone(task: TasksModel) {
    task.done = !task.done;

    this._task = task;

    const id = task.id;
    const status = !task.done;
    if (!this._task.done) {
      this._task.done = false;
    }
    else {
      this._task.done = true;
    }
    this._contactService.updateTaskStatus(id, status).subscribe(
      () => {
        task.done = status;
        // console.log('updating task status to done', task.done)
      },
      (error) => {
        console.error('Error updating task status:', error);
      }
    );

  }
  openTasksModal(content: TemplateRef<any>, id = 0) {
    // console.log("openTasksModal", id);
    this._task = new TasksModel();

    // console.log("task ", this._task);

    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Tasks", id);
  }

  openModal(content: TemplateRef<any>, title: string, id: number) {

    // console.log("openModal", id);

    if (id === 0) {
      title = "Add " + title;
      this._taskUpdate = false;
      this._saveTaskTitle = "Create";
    } else {
      title = "Update " + title;
      this._taskUpdate = true;
      this._saveTaskTitle = "Update";
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }

  todoTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => task.done);
  }
  doneTab(): boolean {
    return this._tasksList.length === 0 || this._tasksList.every(task => !task.done);
  }



  updateContact() {

    if (!this._updatePermission) {
      this.checkUpdatePermissions();
      return;
    }

    const address: Address = {
      id: this._profile.addressId || 0,
      customerId: this._profile.id,
      phoneNo: null,
      email: null,
      fax: null,
      streetAddress: this._profile.streetAddress,
      zipCode: null,
      city: this._profile.city,
      state: null,
      country: this._profile.country,
      addressType: eAddressType.Origin
    }
    const _updatedContact: CustomerUpdateDTOModel = {
      id: this._profile.id,
      customerName: this._profile.customerName,
      detail: this._profile.details,
      phone: this._profile.phone,
      country: this._profile.country,
      city: this._profile.city,
      email: this._profile.email,
      compOwnerId: this._profile.compOwnerId,
      linkedInURL: this._profile.linkedInURL,
      noOfEmployees: this._profile.noOfEmployees,
      openPositions: this._profile.openPositions,
      industryId: this._profile.industryId,
      companyURL: this._profile.companyURL,
      role: this._profile.role,
      company: this._profile.companyName,
      address: address,
      salesType: this._profile.salesType,
      companyId: this._profile.companyId
    };

    // Your logic to save the updated contact

    // console.log('update contact', _updatedContact);
    this._contactService.updateContactFromProfile(_updatedContact).subscribe(
      res => {
        this.getContact();
        this._appComponent.showSuccessSwal("updated", "Record");
      },
      error => {
        console.error("error updating contact", error);
        this._appComponent.showErrorSwal("updating", "Record");
      }
    );
  }

  updateDeal() {
    if (!this._updatePermission) {
      this.checkUpdatePermissions();
      return;
    }

    if (!this._dealDetailDTO.customerId) {

      return;
    }

    // console.log("for  contact id dto", this._dealDetailDTO.customerId);

    const _updatedDeal: DealUpdateDTOModel = {

      id: this._dealDetailDTO.id,
      name: this._dealDetailDTO.name,
      amount: this._dealDetailDTO.amount,
      probability: this._dealDetailDTO.probability,
      customerId: this._dealDetailDTO.customerId,

    };
    //udpate deal in database
    // console.log("_updatedDeal", _updatedDeal);

    this._saleService.updateDealFromProfile(_updatedDeal).subscribe(
      response => {
        this.getDealForProfile(this._dealId);
        this._appComponent.showSuccessSwal('updated', 'deal');
      },
      error => {
        this._appComponent.showErrorSwal('updating', 'deal');
      }
    )
  }

  onCancelContactName(): void {

    this.isEditContactName = false;
    this._originalProfileName = this._profile.customerName;
  }


  onCancelContact(): void {
    this.isEditContact = false;
    this._originalProfileName = this._profile.customerName;
  }

  onCancelDealName(): void {
    this.isEditDealName = false;
    this._originalDealName = this._deal.name;
  }
  onCancelDealNames(): void {
    this.isModifyDealName = false;
    this._originalDealName = this._deal.name;
  }

  onCancelNoOfEmployees() {
    this.isEditNoOfEmployees = false;
  }

  onCancelEmail() {
    this.isEditEmail = false;
  }

  onCancelPhone() {
    this.isEditPhone = false;
  }
  onCancelCountry() {
    this.isEditCountry = false;
  }
  onCancelCity() {
    this.isEditCity = false;
  }

  onCancelOpenPositions() {
    this.isEditOpenPositions = false;
  }

  onCancelCompanyURL() {
    this.isEditCompanyURL = false;
  }

  onCancelLinkedInURL() {
    this.isEditLinkedInURL = false;
  }

  onCancelAmount() {
    this.isEditAmount = false;
  }

  onCancelProbability() {
    this.isEditProbability = false;
  }

  // not using these features

  downloadImage(base64String: string, fileName: string) {
    // Convert the base64 string to a Blob object
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'image/png' });

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  dealClick(id: any) {

    const text = 'deal';
    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/profile'], navigationExtras);

  }


  // for updating contact in single deal page
  getContacts() {
    this._contactService.getContacts().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data) {
          this._contacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId !== this.companyId);
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }

  async getLeadDetailsForProfile(customerId: number) {
    // console.log("customer id ", customerId);
    this._movdService.getLeadDetailsForProfile(customerId).subscribe(
      (data: any) => {
        // console.log("get leads for profile", data);
        if (data && data.length > 0) {
          this._leadDetailsList = data as [];
        }
      },
      (error) => {
        console.error('Error fetching deals:', error);
      }
    );
  }
  async getMoveDetailsForProfile(customerId: number) {
    // console.log("customer id ", customerId);
    this._movdService.getMoveDetailsForProfile(customerId).subscribe(
      (data: any) => {
        // console.log("get moves for profile", data);
        if (data && data.length > 0) {
          this._moveDetailsList = data as [];
        }
      },
      (error) => {
        console.error('Error fetching deals:', error);
      }
    );
  }

  navigateToLeadDetail(data: any) {
    console.log("lead info click", data);
    const leadDetailId = data.leadDetailId;
    const leadId = data.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    // this.breadcrumbService.setBreadcrumbs([
    //   { text: 'Dashboard', url: '/dashboard' },
    //   { text: 'Profile', url: '/profile' },
    //   { text: 'Leads Information Form', url: '' },
    // ]);
    this._router.navigate(['/lead-detail'], navigationExtras);
  }
  navigateToMoveDetail(data: any) {
    console.log("move click ", data);
    const moveDetailId = data.moveDetailId;
    const leadId = data.leadId;
    const leadDetailId = data.leadDetailId;
    const queryParams = { moveDetailId, leadId, leadDetailId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/move-detail'], navigationExtras);
  }
  fetchCompanies() {
    this._contactService.getCompanies().subscribe(
      (data: any) => {
        this._companies = data.map((company: any) => ({ id: company.id, value: company.customerName }));
        //console.log("companies data", data);
      },
      (error: any) => {
        console.error('Error fetching companies:', error);
      }
    );
  }

  getCompanies() {
    this._contactService.getContacts().subscribe(data => {
      //alert(JSON.stringify(data));
      const comps = data.contacts.filter(item => item.contactType === 1);
      //debugger
      this._companies = comps.map((item: any) => {
        return { id: item.id, value: item.customerName };
      });

      const selectOption: { id: number, value: string } = { id: 0, value: 'Select Company' };

      // Add the custom row element at index 0
      this._companies.splice(0, 0, selectOption);

    });
  }
  getCountryName(id: number) {
    const country = this.countries.find(country => country.id === id);
    return country ? country.value : '';
  }


  onChangeCompany() {
    const value = this._profile.companyName;
    // this.role = ""; // Clear the selected role

    // this.getRoles(value);
  }
  // function to convert to format string as date
  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }

  getCompanyUsers() {
    this._authService.getUsersOfCompany().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._companyUsers = data;
        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }
}


