import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { CompanySetting, Currency, CurrencyOptions, DimensionUnit, DimensionUnitOptions, VolumeUnit, VolumeUnitOptions, WeightUnit, WeightUnitOptions } from '../../models/companysetting.model';
import { Shift } from '../../models/shiftTime.model';
import { GeneralService } from 'src/services/general.service';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
/*import { Lead } from '../../models/lead.model';*/

@Component({
  selector: 'app-general-setting',
  templateUrl: './general-setting.html',
  styleUrls: ['./general-setting.scss']
})
export class GeneralSettingComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  defaultNavActiveId: number = 1;
  ColumnMode = ColumnMode;



  isLetterHeadUnderConstruction: boolean = true;
  // objects
  _companySetting = new CompanySetting();

  _weightUnit = WeightUnitOptions;
  _volumeUnit = VolumeUnitOptions;
  _dimensionUnit = DimensionUnitOptions;
  _currency = CurrencyOptions;
  _selectedWeightUnit!: WeightUnit | null;
  _selectedVolumeUnit!: VolumeUnit | null;
  _selectedDimensionUnit!: DimensionUnit | null;
  _selectedCurrency: Currency = Currency.USD;
  _selectedColor = "#7987a1";

  selectedLogoFile: File | null = null;
  logoBase64String: string = '';

  selectedCurrency: string = 'PKR';

  currencyOptions: string[] = ['PKR', 'USD', 'EUR', 'GBP'];
  selectedMeasurementUnit: string = 'KG';
  measurementUnitOptions: string[] = ['KG', 'Foot', 'Inch', 'Centimeter'];

  selectedTheme: string = 'light';

  themeOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' }
  ];

  //for validations
  areaExists: boolean = false;

  _shift = new Shift();
  _companyId: number = 0;


  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    public generalService: GeneralService,
    private breadcrumbService: BreadcrumbService
  ) {
    this._companyId = Number(localStorage.getItem('companyId'));

  }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Company Settings', url: '/general-setting' }
    ]);

    //this.refreshAreaTableData();
    //this.refreshAreaItemsTableData();
    this.getCompanySetting();
    this.getShiftTime();
  }
  onChangeTab(_type: any) {

  }

  onCurrencyChange() {
    // console.log('Selected currency:', this.selectedCurrency);
  }
  onMeasurementUnitChange() {
    // console.log('Selected measurement unit:', this.selectedMeasurementUnit);
  }
  toggleTheme() {
    const body = document.getElementsByTagName('body')[0];

    if (this.selectedTheme === 'dark') {
      body.classList.add('dark-theme');
    } else {
      body.classList.remove('dark-theme');
    }
  }

  // updateCompanySettings() {

  //   this._companySetting.ownedBy = this._companyId;

  //   this._companySetting.currency = this._selectedCurrency;
  //   this._companySetting.weightUnit = this._selectedWeightUnit;
  //   this._companySetting.volumeUnit = this._selectedVolumeUnit;
  //   this._companySetting.themeColor = this._selectedColor;
  //   this._companySetting.logo = this.logoBase64String;
  //   this._movdService.createOrUpdateCompanySettings(this._companySetting).subscribe({
  //     next: (response: any) => {
  //       this._companySetting = response;

  //       this._appComponent.showSuccessSwal('Updated', 'setting');
  //       this.getCompanySetting();
  //       this.clearLogoFile();
  //     },
  //     error: (error: any) => {
  //       console.error('Error updating settings:', error);
  //       this._appComponent.showErrorSwal('updating', 'setting');

  //     }
  //   });
  // }
  updateCompanySettings() {
    this._companySetting.ownedBy = this._companyId;
    this._companySetting.currency = this._selectedCurrency;
    this._companySetting.weightUnit = this._selectedWeightUnit;
    this._companySetting.volumeUnit = this._selectedVolumeUnit;
    this._companySetting.dimensionUnit = this._selectedDimensionUnit;
    this._companySetting.themeColor = this._selectedColor;
    this._companySetting.logo = this.logoBase64String;
  
    this._movdService.createOrUpdateCompanySettings(this._companySetting).subscribe({
      next: (response: any) => {
        this._companySetting = response;
        this._appComponent.showSuccessSwal('Updated', 'setting');
        this.getCompanySetting();
        this.clearLogoFile();
      },
      error: (error: any) => {
        console.error('Error updating settings:', error);
        this._appComponent.showErrorSwal('updating', 'setting');
      }
    });
  }
  

  getCompanySetting() {
    this._companySetting = new CompanySetting();
    this._companySetting.ownedBy = this._companyId;
    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          this._selectedWeightUnit = this._companySetting.weightUnit;
          this._selectedVolumeUnit = this._companySetting.volumeUnit;
          this._selectedDimensionUnit = this._companySetting.dimensionUnit;
          this._selectedCurrency = this._companySetting.currency;
          this._selectedColor = this._companySetting.themeColor;
          this.logoBase64String = this._companySetting.logo || '';

        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  getShiftTime() {
    this._shift = new Shift();
    this._movdService.getShift().subscribe({
      next: (response: Shift) => {
        if (response) {
          this._shift = response;
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  saveShiftTime() {
    this._shift.ownedBy = this._companyId;
    this._shift.startTime = this.convertTo24HourFormat(this._shift.startTime);
    // console.log('Shift Time', this._shift);
    this._movdService.saveShift(this._shift).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal('Updated', 'setting');
        this.getShiftTime();
      },
      error: (error: any) => {
        console.error('Error updating settings:', error);
        this._appComponent.showErrorSwal('updating', 'setting');
      }
    });
  }
  convertTo24HourFormat(time12h: string): string {
    const [time, modifier] = time12h.split(' ');

    // Split hours and minutes
    let [hours, minutes] = time.split(':');

    // If it's 12 AM, convert it to 00
    if (hours === '12' && modifier === 'AM') {
      hours = '00';
    }

    // If it's PM and not 12 PM, add 12 to hours
    if (modifier === 'PM' && hours !== '12') {
      hours = String(parseInt(hours, 10) + 12);
    }

    // Ensure hours and minutes are formatted with leading zeros
    hours = hours.padStart(2, '0');
    minutes = minutes.padStart(2, '0');

    // Return the time in 24-hour format
    return `${hours}:${minutes}`;
  }
  onLogoFileSelected(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.logoBase64String = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.logoBase64String = '';
    }
  }

  clearLogoFile() {
    // console.log('Clear button clicked');
    this.logoBase64String = '';
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }



}
