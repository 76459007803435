import { Time } from "@angular/common";
import { Lead } from "./lead.model";
import { Address } from "./address.model";


export class LeadDetail {
  id: number;
  leadId: number;
  moveType: eMoveType;
  categoryType: eMoveCategoryType;
  transportType: eTransportType;
  surveyDate: Date | null;
  loadingDate: Date | null;
  deliveryDate: Date | null;
  deliverytime: Time | null;
  loadingTime: Time | null;
  /*  loadingTime: { hour: number, minute: number } | null;*/
  packagingDateFrom: Date | null;
  packagingTime: Date | null;
  packagingDateTo: Date | null;
  loadingDateFrom: Date | null;
  loadingDateTo: Date | null;
  storageDateFrom: Date | null;
  storageDateTo: Date | null;
  additionalNotes: string | null;
  internalNotes: string | null;
  ownedBy: number;
  addresses: Address[] | null;
  lead: Lead | null;
  // creationDate: Date;
  createdBy: number;
  // modificationDate: Date;
  modifiedBy: number;
  // deletionDate: Date;
  isDeleted: boolean;
  insuranceRequired: boolean | null;
  portOfLoading: string | null;
  portOfDischarge: string | null;
  placeOfDelivery: string | null;
  markLost: boolean | null;
  moveInitiated: boolean | null;
  selected: boolean | null;
  lostReason: string | null;
  creationDate: Date;
  surveyStatus: eProcessStatus;
  constructor() {
    this.id = 0;
    this.leadId = 0;
    this.moveType = eMoveType.Residential;
    this.categoryType = eMoveCategoryType.DoorToDoorLocal;
    this.transportType = eTransportType.Land;
    this.surveyDate = null;
    this.loadingDate = null;


    this.deliverytime = null;

    this.deliveryDate = null;
    this.packagingDateFrom = null;
    this.loadingTime = null;
    this.packagingTime = null;
    this.packagingDateTo = null;
    this.loadingDateFrom = null;
    this.loadingDateTo = null;
    this.storageDateFrom = null;
    this.storageDateTo = null;
    this.additionalNotes = null;
    this.internalNotes = null;
    this.ownedBy = Number(localStorage.getItem('companyId')) || 0;
    this.addresses = [];
    this.lead = null;
    // this.creationDate = new Date;
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    // this.modificationDate = new Date;
    this.modifiedBy = 0;
    // this.deletionDate = new Date;
    this.isDeleted = false;
    this.insuranceRequired = null;
    this.portOfLoading = null;
    this.portOfDischarge = null;
    this.placeOfDelivery = null;
    this.markLost = null;
    this.moveInitiated = null;
    this.selected = false;
    this.lostReason = null;
    this.creationDate = new Date();
    this.surveyStatus = eProcessStatus.New;
  }
}

export class MoveTransportModal {
  moveType: eMoveType;
  categoryType: eMoveCategoryType;
  transportType: eTransportType;
  constructor() {
    this.moveType = eMoveType.Residential;
    this.categoryType = eMoveCategoryType.DoorToDoorLocal;
    this.transportType = eTransportType.Sea;
  }
}
export enum eMoveType {
  Unknown = 0,
  Residential = 1,
  Diplomatic = 2,
  Cargo = 3,
  Commercial = 4,
}

export enum eMoveCategoryType {
  Unknown = 0,
  DoorToDoorLocal = 1,
  DoorToPort = 2,
  PortToDoor = 3,
  DoorToDoorImport = 4,
  DoorToDoorExport = 5,
}

export enum eTransportType {
  Unknown = 0,
  Air = 1,
  Land = 2,
  Sea = 3,
  SeaLCL = 4,
}

export enum eProcessStatus {
  New = 1,
  InProgress = 2,
  Completed = 3,
  Cancelled = 4,
}

export interface EnumOption {
  id: number;
  value: string;
}

export const MoveTypeOptions: EnumOption[] = [
  { id: eMoveType.Residential, value: 'Residential' },
  { id: eMoveType.Diplomatic, value: 'Diplomatic' },
  { id: eMoveType.Cargo, value: 'Cargo' },
  { id: eMoveType.Commercial, value: 'Commercial' },
];

export const MoveCategoryTypeOptions: EnumOption[] = [
  { id: eMoveCategoryType.DoorToDoorLocal, value: 'Door to Door (Local)' },
  { id: eMoveCategoryType.DoorToPort, value: 'Door to Port' },
  { id: eMoveCategoryType.PortToDoor, value: 'Port to Door' },
  { id: eMoveCategoryType.DoorToDoorImport, value: 'Door to Door (Import)' },
  { id: eMoveCategoryType.DoorToDoorExport, value: 'Door to Door (Export)' },
];

export const TransportTypeOptions: EnumOption[] = [
  { id: eTransportType.Air, value: 'Air' },
  { id: eTransportType.Land, value: 'Land' },
  { id: eTransportType.Sea, value: 'Sea' },
  { id: eTransportType.SeaLCL, value: 'Sea (LCL)' },
];

export const ProcessStatusOptions: EnumOption[] = [
  { id: eProcessStatus.New, value: 'New' },
  { id: eProcessStatus.InProgress, value: 'InProgress' },
  { id: eProcessStatus.Completed, value: 'Completed' },
  { id: eProcessStatus.Cancelled, value: 'Cancelled' },
];
