import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debug } from 'console';
import { ContactService } from "src/services/contact.service";
import { eSalesType, eSalesTypeOptions, OptionModel } from "../contact.models";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AppComponent } from 'src/app/app.component'
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { AuthService } from 'src/services/auth.service';
import { Customer } from 'src/app/views/movd/models/customer.model';
import { IndustryModel } from 'src/app/views/admin/super-admin/super-admin.models';
import { CountryOptions } from 'src/app/views/movd/models/address.model';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
@Component({
  selector: 'app-add-edit-contact',
  templateUrl: './add-edit-contact.component.html',
  styleUrls: ['./add-edit-contact.component.css']
})
export class AddEditContactComponent implements OnInit {

  @Input() contact: any;
  @Output() callingParent: EventEmitter<void> = new EventEmitter<void>();

  //#region local veriables
  _companies: any[] = [];
  //email: FormControl;
  // phone: FormControl;
  //phone: FormControl = new FormControl('', [Validators.required]);

  //adrs_phone: FormControl;
  //adrs_email: FormControl;
  //customerName: FormControl;
  countries = CountryOptions;
  customerCompany: any;
  formSubmitted: boolean = false;
  adrs: any;
  contactType: any = 0;
  id: any = 0;
  customerName: string = "";
  role: string = "";
  _salesType: any = null;
  _salesTypeLabel: string = "Contact Type";
  companyURL: string | null = null;
  noOfEmployees: string | null = null;
  linkedInURL: string | null = null;
  openPositions: string | null = null;
  //customerCompany: number = 0;

  selectedOption: string = 'contact'

  _compOwnerID: number | null = null;
  _industryId: number | null = null;

  phone: string = "";
  email: string = "";
  fax: string = "";
  details: string = "";
  _contactVisible = true;
  _companyVisible = false;
  _emailExists: boolean = false;

  // _companies: any; 
  _roles: any = null;

  _compOwners: Customer[] = [];
  _industries: IndustryModel[] = [];

  _salesTypeOption = eSalesTypeOptions;

  adrs_id: any = 0
  adrs_email: string = "";
  adrs_phone: string = "";
  adrs_street: string = "";
  adrs_city: string = "";
  adrs_state: string = "";
  adrs_zip: string = "";
  adrs_fax: string = "";
  adrs_country: string | null = null;
  _customerType: any;
  _currentUserId: number = 0;
  _currentCompanyId: number = 0;
  //#endregion local veriables

  //#region lifecycle hooks

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _contactService: ContactService,
    private _appComponent: AppComponent,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this._userRole = localStorage.getItem('role');
  }


  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Contacts & Companies', url: '' },

    ]);

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('CRM - Contacts.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('CRM - Contacts.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('CRM - Contacts.Delete') || this._userRole === 'CompanyAdmin';


    const currentUserId = localStorage.getItem('currentUserId');
    this._currentCompanyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(currentUserId);
    //console.log("currentUserId", this._currentUserId)
    this.fetchCompanies();
    this._customerType = this._contactService.getCustomerType();
    // this._types = [
    //   { id: 0, value: 'None' },
    //   { id: 1, value: 'Sales' },
    //   { id: 2, value: 'Partner' },
    //   { id: 3, value: 'Vendor' }
    // ];
    if (this.contact.Id != 0) {
      if (this.contact.contactType == 0) {
        if (this.contact.companyId != 0 || this.contact.companyId != null) {
          this.getRoles(this.contact.companyId);
        }
      }
      else {
        this.selectedOption = "company";
      }

      this.id = this.contact.id;
      this.customerName = this.contact.customerName;
      this.phone = this.contact.phone;
      this.role = this.contact.role;
      this.customerCompany = this.contact.companyId;
      this._industryId = this.contact.industryId;
      this._compOwnerID = this.contact.compOwnerId;
      this.email = this.contact.email;
      this.fax = this.contact.fax;
      this.details = this.contact.details;
      this.contactType = this.contact.contactType;
      this._salesType = this.contact.salesType;
      this.openPositions = this.contact.openPositions;
      this.companyURL = this.contact.companyURL;
      this.linkedInURL = this.contact.linkedInURL;
      this.noOfEmployees = this.contact.noOfEmployees;

      //console.log("this.contact", JSON.stringify(this.contact));
      var ad: any;

      this._contactService.getAddressByContactId(this.contact.id).subscribe(data => {
        this.adrs = data;
        this.adrs_id = this.adrs.id;
        this.adrs_email = this.adrs.email;
        this.adrs_phone = this.adrs.phoneNo;
        this.adrs_street = this.adrs.streetAddress;
        this.adrs_city = this.adrs.city;
        this.adrs_state = this.adrs.state;
        this.adrs_zip = this.adrs.zipCode;
        this.adrs_fax = this.adrs.fax;
        this.adrs_country = this.adrs.country;
      });;


    } else {
      this.id = 0;
      this.customerName = "";
      this.adrs_id = 0;
      // this.customerName = "";
      // this.phone = "";
      // this.email = "";
      this.fax = "";
      this.details = "";
      this.adrs_id = 0;
      this.adrs_email = "";
      this.adrs_phone = "";
      this.adrs_street = "";
      this.adrs_city = "";
      this.adrs_state = "";
      this.adrs_zip = "";
      this.adrs_fax = "";
      this.adrs_country = null;
    }
    this.onChangeType(this._contactService.getCustomerType());
  }

  //#endregion lifecycle hooks

  //#region private functions
  invokeParentFunction(): void {
    this.callingParent.emit();
  }
  onChangeType(_type: any) {
    this.contactType = this._contactService.getCustomerType();
    //alert(_type);
    if (this.contactType == 0) {
      //contact
      this._contactVisible = true;
      this._companyVisible = false;
      this._salesTypeLabel = "Contact Type";
    }
    else {
      //company
      this._contactVisible = false;
      this._companyVisible = true;
      this._salesTypeLabel = "Company Type";

      this.getIndustries();
      this.getUsers();
    }
  }
  fetchCompanies() {
    this._contactService.getCompanies().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this._companies = data.map((company: any) => ({ id: company.id, value: company.customerName }));
        }
        //console.log("companies data", data);
      },
      (error: any) => {
        console.error('Error fetching companies:', error);
      }
    );
  }
  getIndustries() {
    this._contactService.getIndustries().subscribe(data => {
      // console.log("Industries", data);
      if (data && data.length > 0) {
        this._industries = data;
      }
    });
  }
  getUsers() {
    this._contactService.getContacts().subscribe(data => {
      // console.log("comp owners", data);
      let compnayOwners = data.contacts.filter(item => item.contactType === 0);
      if (compnayOwners && compnayOwners.length > 0) {
        this._compOwners = compnayOwners;
      }

    });
  }

  getCompanies() {
    this._contactService.getContacts().subscribe(data => {
      //alert(JSON.stringify(data));
      const comps = data.contacts.filter(item => item.contactType === 1);
      //debugger
      this._companies = comps.map((item: any) => {
        return { id: item.id, value: item.customerName };
      });

      // const selectOption: { id: number, value: string } = { id: 0, value: 'Select Company' };

      // // Add the custom row element at index 0
      // this._companies.splice(0, 0, selectOption);

    });
  }
  getRoles(companyId: any) {
    this._contactService.getRoles(companyId).subscribe((data: any) => {
      let filteredData = data.filter(
        (item: { roleName: string }) =>
          item.roleName !== 'CompanyAdmin');

      this._roles = filteredData.map((item: any) => {
        return { id: item.id, value: item.roleName };
      });

      const selectOption: { id: number, value: string } = { id: 0, value: 'Select Role' };
      this._roles.splice(0, 0, selectOption);
    });
  }
  onChangeCompany() {
    const value = this.customerCompany;
    this.role = ""; // Clear the selected role

    this.getRoles(value);
  }
  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  addContact(): void {
    //this.invokeParentFunction();// closing Model
    // && this.phone.value && this.email.value && this.adrs_phone.value && this.adrs_email.value
    //if (this.customerName && this.email && this.adrs_email) {


    //Swal.fire({
    //  icon: 'success',
    //  title: 'Contact saved successfully',
    //  showConfirmButton: false,
    //  timer: 2000
    //});

    this.formSubmitted = true;

    if ((!this.customerName || this.customerName.trim().length === 0)
      || (!this.phone || this.phone.trim().length === 0)
      || ((!this.email || this.email.trim().length === 0) || !this.isValidEmail(this.email.trim()))
      || ((this.adrs_phone && this.adrs_phone.trim().length > 0 && this.adrs_phone.trim().length < 8))
      || (this._emailExists)) {
      //console.log('Validation failed.');

      // Return or handle the validation error as needed
      return;
    }

    var contactModel = {
      customerName: this.customerName,
      Id: this.id,
      phone: this.phone,
      IndustryId: this._industryId,
      compOwnerId: this._compOwnerID,
      role: this.role,
      CompanyId: this.customerCompany,
      OwnedBy: this._currentCompanyId,
      email: this.email,
      fax: this.fax,
      dateOfBirth: null,
      details: this.details,
      contactType: this.contactType,
      salesType: this._salesType,
      openPositions: this.openPositions,
      linkedInURL: this.linkedInURL,
      companyURL: this.companyURL,
      noOfEmployees: this.noOfEmployees,
      creationDate: new Date(),
      createdBy: this._currentUserId,
      modificationDate: null,
      modifiedBy: null,
      deletionDate: null,
      isDeleted: false,
      address: {
        Id: this.adrs_id,
        customerId: this.id,
        phoneNo: this.adrs_phone,
        email: this.adrs_email,
        fax: "",
        streetAddress: this.adrs_street,
        zipCode: "",
        city: this.adrs_city,
        state: "",
        country: this.adrs_country,
        creationDate: new Date(),
        createdBy: null,
        modificationDate: null,
        modifiedBy: null,
        deletionDate: null,
        isDeleted: false
      }
    };

    if (contactModel.Id != 0) {

      this._contactService.updateContact(contactModel).subscribe(
        res => {
          this._appComponent.showSuccessSwal("updated", "Record");
          this.fetchCompanies();
          this.invokeParentFunction();
        },
        error => {
          this._appComponent.showErrorSwal("updating", "Record");
        }
      );
    }
    else {
      this._contactService.addContact(contactModel).subscribe(
        res => {
          this._appComponent.showSuccessSwal("created", "Contact");
          this.invokeParentFunction();
        },
        error => {
          this._appComponent.showErrorSwal("creating", "contact");
        }
      );
    }
    //this.contact = null;
    this.formSubmitted = false;
  }
  updateContact() {
    var val = {
      Id: this.id,
      CusotmerName: this.customerName,
      Phone: this.phone,
      Role: this.role,
      email: this.email,
      fax: this.fax,
      details: this.details
    };
    //console.log("contact", val)
    this._contactService.updateContact(val).subscribe(
      res => {
        this._appComponent.showSuccessSwal("updated", "Contact");
      },
      error => {
        this._appComponent.showErrorSwal("updating", "contact");
      }
    );
  }
  validatePhoneNumber(event: KeyboardEvent): void {
    const input = event.key;
    const isNumeric = /[0-9]/.test(input); // Removed '+' from the regex

    if (!isNumeric) {
      event.preventDefault();
      return;
    }

    // Add '+' at the beginning if it's not there
    if (this.phone.length === 0) {
      this.phone = '+';
    }

    const newValue = this.phone + input;

    if (newValue.length > 16) { // Increased length to 16 to account for the '+'
      event.preventDefault(); // Prevent input if total length exceeds 16
    }

    if (newValue.length > 0 && newValue.length < 9) { // Increased length to 9 to account for the '+'
      this.adrs_phone = newValue;
    }
  }

  isValidPhoneNumber(value: string): boolean {
    return /[0-9^$]/.test(value) && value.length >= 8 && value.length <= 15;
  }

  validateRole(event: KeyboardEvent, value: string): void {
    const input = event.key;
    const isAlphabetic = /^[A-Za-z\s]+$/.test(input);

    if (!isAlphabetic) {
      event.preventDefault();
    }

  }
  isValidRole(value: string): boolean {
    return /^[A-Za-z\s]+$/.test(value);
  }

  checkEmailExists(email: string | null) {
    if (!email) {
      // If the email is null or empty, reset the _emailExists flag
      this._emailExists = false;
      return;
    }

    const isCustomer = true

    this._authService.checkEmailExists(email, isCustomer).subscribe({
      next: (response: boolean) => {
        this._emailExists = response;
        // console.log("checking email exists or not", response);
      },
      error: (error: any) => {
        console.error("error validating email", error);
      }
    });
  }
  //#endregion private functions
}

