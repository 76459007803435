export class PackagingWeightCert {
  id: number;
  packagingDetailId: number;
  externalNotes: string | null;
  customerSignature: string | null;
  managerSignature: string | null;
  uploadedFile: string | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.packagingDetailId = 0;
    this.externalNotes = null;
    this.customerSignature = null;
    this.managerSignature = null;
    this.uploadedFile = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
