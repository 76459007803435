export class LeadToMoveSunmmarizeReportDTO {
  date: Date | null;
  totalLeads: number | null;
  totalMoves: number | null;
  leadToMoveRate: number | null;

  constructor() {
    this.date = null;
    this.totalLeads = null;
    this.totalMoves = null;
    this.leadToMoveRate = null;
  }
}