import { EnumOption } from "./lead-detail.model";

export enum eAppraisalType {
  Packing = 0,
  Delivery = 1
}

export enum eQuestionType {
  YesNo = 0,
  MultipleChoice = 1
}

export class AppraisalDetail {
  id: number
  appraisalSettingId: number;
  userComment: string | null;
  signature: string | null;
  name: string | null;
  date: Date | string | null;
  refId: number;
  appraisalType: eAppraisalType;

  constructor() {
    this.id = 0;
    this.appraisalSettingId = 0;
    this.userComment = null;
    this.signature = null;
    this.name = null;
    this.date = null;
    this.refId = 0;
    this.appraisalType = eAppraisalType.Packing;
  }
}

export enum eYesNoAnswer {
  No = 0,
  Yes = 1
}
export enum eMultipleChoiceAnswer {
  Dissatisfied = 0,
  Poor = 1,
  Satisfied = 2,
  VeryGood = 3,
  Excellent = 4,
}

export class AppraisalQuestion {
  id: number = 0;
  appraisalSettingId: number = 0;
  questionText: string = '';
  questionType: eQuestionType = eQuestionType.YesNo;
  appraisalResponse: AppraisalResponse = new AppraisalResponse();
}

export class AppraisalResponse {
  id: number = 0;
  appraisalDetailId: number = 0;
  appraisalQuestionId: number = 0;
  selectedAnswer: number = 0;
}

export class AppraisalSetting {
  id: number = 0;
  title: string = '';
  introMessage: string = '';
  walkthroughMessage: string = '';
  appraisalType: eAppraisalType = eAppraisalType.Packing;
  ownedBy?: number = Number(localStorage.getItem('companyId'));
}

export class AppraisalDTO {
  appraisalSetting: AppraisalSetting = new AppraisalSetting();
  appraisalDetail: AppraisalDetail = new AppraisalDetail();
  questions: AppraisalQuestion[] = [];
}
export const MultipleChoiceOptions: EnumOption[] = [
  { id: eMultipleChoiceAnswer.Dissatisfied, value: 'Dissatisfied' },
  { id: eMultipleChoiceAnswer.Poor, value: 'Poor' },
  { id: eMultipleChoiceAnswer.Satisfied, value: 'Satisfied' },
  { id: eMultipleChoiceAnswer.VeryGood, value: 'Very Good' },
  { id: eMultipleChoiceAnswer.Excellent, value: 'Excellent' },

];
export const YesNoOptions: EnumOption[] = [
  { id: eYesNoAnswer.Yes, value: 'Yes' },
  { id: eYesNoAnswer.No, value: 'No' },

];
