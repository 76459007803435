import { Component, OnInit } from '@angular/core';
import { LeadDetail, eMoveCategoryType, eTransportType } from '../models/lead-detail.model';
import { EnumMappingService } from '../services/movd.enum.service';
import { eStatusType } from '../models/status.model';
import { ActivatedRoute } from '@angular/router';
import { MovdService } from '../services/movd.service';
import { StatusWithMoveStatus } from '../models/move-status.model';
import { eAppraisalType } from '../models/appraisal.model';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { LeadBoardDTO } from '../lead-board/lead-board.model';
import { PackagingBoardListDTO, PackingListResponse } from '../survey-manager/packing-list/packing-board.model';

@Component({
  selector: 'app-packing-details',
  templateUrl: './packing-details.component.html',
  styleUrls: ['./packing-details.component.scss']
})
export class PackingDetailsComponent implements OnInit {

  _leadBoardDTOList: LeadBoardDTO[] = [];
  // _packingDetail = new PackagingDetail();
  _moveDetail = 'moveDetail';
  _assignmentType = 'moveDetail';
  _packingDetail = new PackagingBoardListDTO();
  _appraisalType = eAppraisalType.Packing;
  _leadId: number = 0;
  defaultNavActiveId: number = 1;
  _statusVersion: number = 0;
  _statusType = eStatusType.Lead;
  _isDataLoaded: boolean = false;
  _moveDetailId: number = 0;
  _leadDetailId: number = 0;
  _packagingDetailId: number = 0;
  // _moveDetail = new MoveDetail();
  _leadStatuses: StatusWithMoveStatus[] = [];
  _packingBoardDTOList: PackagingBoardListDTO[] = [];
  _weightCertTitle!: string;
  _firstStatus: any;
  _lastStatus: any;
  previousUrl: string = '';


  _showWeightCertificate: boolean = false;

  constructor(public enumMappingService: EnumMappingService,
    private _route: ActivatedRoute, private _movdService: MovdService, private breadcrumbService: BreadcrumbService,) { }

  ngOnInit(): void {
    this.previousUrl = this.breadcrumbService.getPreviousUrl();
    this.updateBreadcrumbs();



    this._route.queryParams.subscribe(
      params => {
        this._packingDetail.packagingDetailId = Number(params['packagingDetailId']);
        this._leadId = Number(params['leadId']);
        this._moveDetailId = Number(params['moveDetailId']);
        this._leadDetailId = Number(params['leadDetailId']);
        // console.log("moveDetailId packingdetail id", this._moveDetailId, this._packingDetail.packagingDetailId);
        // console.log("query params", params);
        if (this._leadId && this._leadId !== 0) {
          this.getPackagingDetailsByLeadId();
        }
        else if (this._leadDetailId && this._leadDetailId !== 0) {
          this.getPackagingDetailsByLeadDetailId()
        }
      });
  }
  private updateBreadcrumbs(): void {
    if (this.previousUrl.includes('/moves-board')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Moves Dashboard', url: '/moves-board' },
        { text: 'Packing Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/packing-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Packing Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/profile')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Packing Form', url: '' },
      ]);
    }


  }

  getPackagingDetailsByLeadDetailId() {
    this._movdService.getPackagingDetailsByLeadDetailId(this._leadDetailId).subscribe({
      next: (data: any) => {
        //console.log("packaging details by leadDetailId", data);
        if (data) {
          //console.log("pack detail id", data.id);
          this._packagingDetailId = data.id;
          this._packingDetail.packagingDetailId = data.id;
          this._leadId = data.leadId;
          this._moveDetailId = data.moveDetailId;

          this.getPackagingDetailsByLeadId();
          this._showWeightCertificate = this.weightCertificateTitle();
        }
      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }

  getPackagingDetailsByLeadId() {
    this._movdService.getPackagingDetailsByLeadId(this._leadId).subscribe({
      next: (data: PackingListResponse) => {
        // console.log("packaging details by leadId", data);
        if (data && data.packingList && data.packingList.length > 0) {
          //console.log('packaginggg by lead id', data);
          this._packingBoardDTOList = data.packingList;
          this._isDataLoaded = true;
          const filteredData = data.packingList.find((item) => item.packagingDetailId === this._packingDetail.packagingDetailId);
          this._packingDetail = filteredData || new PackagingBoardListDTO();
          // this._moveDetailId = this._packingDetail.moveDetailId;
          // console.log('this._packingDetail = filteredData;', filteredData);
          this.weightCertificateTitle();
          this._showWeightCertificate = this.weightCertificateTitle();

        }
      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }

  showMoveById(packagingDetail: any) {
    this._packingDetail.packagingDetailId = packagingDetail.packagingDetailId;
    // console.log("selected packingid ", packagingDetail);
    this._moveDetailId = packagingDetail.moveDetailId;
    this._leadId = packagingDetail.leadId;
    this.getPackagingDetailsByLeadId();
    // this._packingDetail.moveDetailId = this._moveDetailId
  }

  onChangeTab(_type: any) {

  }

  weightCertificateTitle(): boolean {
    // console.log('moveTransport', this._packingDetail.moveTransport);
    if (this._packingDetail.moveTransport === eTransportType.Air) {
      this._weightCertTitle = 'AIR WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.Sea) {
      this._weightCertTitle = 'SEA WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.SeaLCL) {
      this._weightCertTitle = 'SEA(LCL) WEIGHT CERTIFICATE';
      return true;
    } else {
      // this._weightCertTitle = 'WEIGHT CERTIFICATE';
      return false;
    }
  }

}
