<ng-template #tasksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="tasksModalLabel">
      <div class="TitleIcon">
        <lord-icon src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover"
          colors="primary:#5f606c" class="solid_icons"></lord-icon>
        <span class="TitleText">Task</span>
      </div>
    </h5>
    <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_task.id" />
      <div class="col-md-12">
        <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title"
          autocomplete="on" placeholder="Enter your task" required #taskTitle="ngModel" />
        <div *ngIf="taskTitle.invalid && (taskTitle.dirty || taskTitle.touched)"
          class="error-message error" style="color: red">
          <div *ngIf="taskTitle.errors?.['required']">* required.</div>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.dueDate" class="form-label no-border">Due Date</label>
        <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate"
          autocomplete="on" value="{{ _task.dueDate }}" placeholder="{{ _task.dueDate }}"
          required />
      </div>
      <div class="col-md-6" *ngIf="_taskUpdate">
        <label for="_task.label" class="form-label no-border">Mark as Done</label>
        <!-- <input type="checkbox" [(ngModel)]="_task.done" id="_task.done"
          autocomplete="on" value="{{ _task.done }}"  (change)="toggleTaskDone(_task)" /> -->
        <ng-select class="left-align input-color" [(ngModel)]="_task.done"
          [bindLabel]="_task.done ? 'Done' : 'None'" (ngModelChange)="toggleTaskDone(_task)"
          id="_task.done">
          <ng-option [value]="true">Done</ng-option>
          <ng-option [value]="false">None</ng-option>
        </ng-select>

        <span class="checkmark"
          [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.type" class="input-form-labels">Type</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
          <ng-option value="To-do" selected>To-do</ng-option>
          <ng-option value="Call">Call</ng-option>
          <ng-option value="Email">Email</ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <label for="_task.priority" class="input-form-labels">Priority</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Low">Low</ng-option>
          <ng-option value="Medium">Medium</ng-option>
          <ng-option value="High">High</ng-option>
        </ng-select>
      </div>
      <!-- <div class="col-md-4">
        <label for="_task.queue" class="input-form-labels">Queue</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.queue" id="_task.queue">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Create a new queue">Create a new queue</ng-option>
        </ng-select>
      </div> -->
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details"
          autocomplete="on" value="{{ _task.details }}" placeholder="Notes..." required></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
      <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>
<div class="row">
  <div class="col-md-9">
    <h3>Dashboard</h3>
  </div>
  <div class="col-md-3">
    <div class="input-group range-selection">
      <input type="text" class="form-control clickable custom-border"
        placeholder="Select Date Range" name="datepicker" ngbDatepicker #datepicker="ngbDatepicker"
        [value]="generalService.formatDate(_statsFromDate,_statsToDate)"
        (dateSelect)="onDateSelect($event)" [autoClose]="'outside'" [displayMonths]="2"
        [dayTemplate]="daysTemplate" outsideDays="hidden" [startDate]="fromDate!"
        (click)="datepicker.toggle()" tabindex="-1">

      <button class="input-group-text custom-border" type="button" title="icon-calendar"
        (click)="datepicker.toggle()">
        <i class="feather icon-calendar icon-md" [style.color]="'#1AC0A1'"></i>
      </button>

      <ng-template #daysTemplate let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
          [class.faded]="
          isHovered(date) ||
          isInside(date)
        " (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
</div>
<div class="mt-5">
  <div class="row">

    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-center">
          <h6 class="mb-0"><strong> TODAY - PACKING </strong></h6>
        </div>
        <div class="card-body">
          <div *ngIf="dashboardItems.length > 0">
            <div *ngFor="let item of dashboardItems">
              <!-- Check if the item type is 'dayPacking' -->
              <ng-container *ngIf="item.type === 'dayPacking'">
                <a class="link" *ngIf="item.content !== undefined; else noPackingPlan"
                  (click)="navigatePackingToComponent(item.leadDetailId)">
                  {{ item.content }}
                </a>
                <div class="date-container">
                  <small class="text-muted">
                    {{ item.date !== undefined ? item.date : "N/A" }}
                  </small>
                </div>
              </ng-container>
              <ng-template #noPackingPlan>
                No Packing Plan for Today.
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-center">
          <h6 class="mb-0"><strong>THIS WEEK - PACKING</strong></h6>
        </div>
        <div class="card-body">
          <div *ngIf="dashboardItems.length > 0">
            <div *ngFor="let item of dashboardItems">
              <ng-container *ngIf="item.type === 'weekPacking'">
                <a class="link" *ngIf="item.content !== undefined; else noPackingPlan"
                  (click)="navigatePackingToComponent(item.leadDetailId)">
                  {{ item.content }}
                </a>
                <div class="date-container">
                  <small class="text-muted">
                    {{ item.date !== undefined ? item.date : "N/A" }}
                  </small>
                </div>
              </ng-container>
              <ng-template #noPackingPlan>
                No Packing Plan for this week.
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-center">
          <h6 class="mb-0"><strong> TODAY - DELIVERY </strong></h6>
        </div>
        <div class="card-body">
          <div *ngIf="dashboardItems.length > 0">
            <div *ngFor="let item of dashboardItems">
              <!-- Check if the item type is 'dayPacking' -->
              <ng-container *ngIf="item.type === 'dayPacking'">
                <a class="link" *ngIf="item.content !== undefined; else noDeliveryPlan"
                  (click)="navigatePackingToComponent(item.leadDetailId)">
                  {{ item.content }}
                </a>
                <div class="date-container">
                  <small class="text-muted">
                    {{ item.date !== undefined ? item.date : "N/A" }}
                  </small>
                </div>
              </ng-container>
              <ng-template #noDeliveryPlan>
                No Delivery Plan for Today.
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card">
        <div class="card-header text-center">
          <h6 class="mb-0"><strong>THIS WEEK - DELIVERY</strong></h6>
        </div>
        <div class="card-body">
          <div *ngIf="dashboardItems.length > 0">
            <div *ngFor="let item of dashboardItems">
              <ng-container *ngIf="item.type === 'weekPacking'">
                <a class="link" *ngIf="item.content !== undefined; else noDeliveryPlan"
                  (click)="navigatePackingToComponent(item.leadDetailId)">
                  {{ item.content }}
                </a>
                <div class="date-container">
                  <small class="text-muted">
                    {{ item.date !== undefined ? item.date : "N/A" }}
                  </small>
                </div>
              </ng-container>
              <ng-template #noDeliveryPlan>
                No Delivery Plan for this week.
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="mt-5">
  <div class="row">
    <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title">PACKINGS</h6>
          <apx-chart [series]="donutChartOptionsPacking.series"
            [labels]="donutChartOptionsPacking.labels" [chart]="donutChartOptionsPacking.chart"
            [colors]="donutChartOptionsPacking.colors" [stroke]="donutChartOptionsPacking.stroke"
            [legend]="donutChartOptionsPacking.legend"
            [dataLabels]="donutChartOptionsPacking.dataLabels"></apx-chart>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title">DELIVERIES</h6>
          <apx-chart [series]="donutChartOptionsDelivery.series"
            [labels]="donutChartOptionsDelivery.labels" [chart]="donutChartOptionsDelivery.chart"
            [colors]="donutChartOptionsDelivery.colors" [stroke]="donutChartOptionsDelivery.stroke"
            [legend]="donutChartOptionsDelivery.legend"
            [dataLabels]="donutChartOptionsDelivery.dataLabels"></apx-chart>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 grid-margin stretch-card">
      <div class="card" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); transition: 0.3s;">
        <div class="card-body" style="padding: 2em; background-color: #f8f9fa;">
          <h6 class="card-heading mb-0" style="text-align: center; font-size: 20px;">
            <strong>Labour Availability</strong>
          </h6>
          <hr>
          <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
            [colors]="barChartOptions.colors" [grid]="barChartOptions.grid"
            [xaxis]="barChartOptions.xaxis" [yaxis]="barChartOptions.yaxis"
            [plotOptions]="barChartOptions.plotOptions"></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3">
  <div class="row">
    <div class="col-md-6">
      <div class="grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">CSAT Score for Packings</h6>

            <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
              [colors]="lineChartOptions.colors" [grid]="lineChartOptions.grid"
              [xaxis]="lineChartOptions.xaxis" [yaxis]="lineChartOptions.yaxis"
              [markers]="lineChartOptions.markers" [legend]="lineChartOptions.legend"
              [stroke]="lineChartOptions.stroke"></apx-chart>

          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">CSAT Score for Deliveries</h6>

            <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
              [colors]="lineChartOptions.colors" [grid]="lineChartOptions.grid"
              [xaxis]="lineChartOptions.xaxis" [yaxis]="lineChartOptions.yaxis"
              [markers]="lineChartOptions.markers" [legend]="lineChartOptions.legend"
              [stroke]="lineChartOptions.stroke"></apx-chart>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-3">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="mt-2 mb-3">
            <h4 class="task-title">Tasks</h4>
          </div>

          <div class="calendar-container mt-2 p-0">
            <app-task [customerId]="_localCustomerId" [isDeal]="false"></app-task>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="calendar-container mt-2 p-0">
            <full-calendar class="calendar" [options]="calendarOptions"></full-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>