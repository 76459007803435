// email-template.model.ts

export class EmailTemplate {

  id: number;
  ownedBy: number;
  title: string;
  subject: string | null;
  body: string | null;
  isActive: boolean | null;
  isDeleted: boolean;
  deletionDate: Date | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;


  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.title = '';
    this.subject = '';
    this.body = '';
    this.isActive = null;
    this.isDeleted = false;
    this.deletionDate = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;

  }

}

// email-template.component.ts


