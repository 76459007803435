import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EnumMappingService } from '../../services/movd.enum.service';
import { ActivatedRoute } from '@angular/router';
import { MovdService } from '../../services/movd.service';
import { PdfService } from 'src/services/pdf.service';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MoveCategoryTypeOptions, MoveTypeOptions, TransportTypeOptions, eTransportType } from '../../models/lead-detail.model';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import SignaturePad from 'signature_pad';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { PackagingWeightCert } from '../../models/packaging-weight-cert.model';
import { PackagingWeightCertItem } from '../../models/packaging-weight-cert-item.model';
import { eStatusType } from '../../models/status.model';
import { StatusWithMoveStatus } from '../../models/move-status.model';
import { GeneralService } from '../../../../../services/general.service';
import { LeadBoardDTO } from '../../lead-board/lead-board.model';
import { PackagingBoardListDTO, PackingListResponse } from '../packing-list/packing-board.model';

@Component({
  selector: 'app-delivery-weight-certificate',
  templateUrl: './delivery-weight-certificate.component.html',
  styleUrls: ['./delivery-weight-certificate.component.scss']
})
export class DeliveryWeightCertificateComponent implements OnInit {


  _leadBoardDTOList: LeadBoardDTO[] = [];
  // _packingDetail = new PackagingDetail();
  _moveDetail = 'moveDetail';
  _packingDetail = new PackagingBoardListDTO();
  _leadId: number = 0;
  defaultNavActiveId: number = 1;
  defaultNavActiveIdForType: number = 1;
  _statusVersion: number = 0;
  _statusType = eStatusType.Lead;
  _isDataLoaded: boolean = false;
  _moveDetailId: number = 0;
  _leadStatuses: StatusWithMoveStatus[] = [];
  _packingBoardDTOList: PackagingBoardListDTO[] = [];
  _firstStatus: any;
  _lastStatus: any;
  inventoryItems: any[] = [];
  weightCertItem = new PackagingWeightCertItem();
  weightCertsList: any[] = []
  _packagingWeightCertId: number | null = null;
  _externalNotesWeight!: string | null;
  _packagingWeightCert = new PackagingWeightCert();
  @ViewChild('customerSingatureCanvasWeight') customerSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvasWeight') surveyMgrSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  customerSignaturePadWeight!: SignaturePad;
  surveyMgrSignaturePadWeight!: SignaturePad;

  @ViewChild('pdfContent') pdfContent!: any;
  @ViewChild('pdfContentInventory') pdfContentInventory!: any;
  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  pdfDocGenerator: any;
  _moveBoardItem: any;
  _moveBoardDTOList: any[] = [];

  _cd: any;
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;

  _packagingDetailDTO: any;
  _weightCertFile: any;
  modalRef: NgbModalRef | null = null;
  isLoading = false;
  _showWeightCertificate: boolean = false;
  _weightCertTitle!: string;
  _packagingDetailId: number | null = null;
  @ViewChild('pdfContentWeight') pdfContentWeight!: any;


  constructor(
    public enumMappingService: EnumMappingService,
    private _route: ActivatedRoute,
    public generalService: GeneralService,
    private _movdService: MovdService,
    private _pdfService: PdfService,
    private _http: HttpClient,
    private _appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(
      params => {
        this._packagingDetailId = Number(params['packagingDetailId']);
        this._leadId = Number(params['leadId']);
        this._moveDetailId = Number(params['moveDetailId']);
        // console.log("params ", params);
        if (this._leadId && this._leadId !== 0) {
          this.getPackagingDetailsByLeadId();
          this.getCompanySetting();
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packagingDetailId']) {
      this._packagingDetailId = changes['packagingDetailId'].currentValue;
      this._packagingWeightCert.packagingDetailId = changes['packagingDetailId'].currentValue;
      // console.log(" getting packingdetail is this s", this._packagingInventory.packagingDetailId);
      // console.log(" getting packingwieght id is ", this._packagingWeightCert.packagingDetailId);
      this.getPackagingDetailsByLeadId();
    }
  }
  ngAfterViewInit() {
    this.initializeWeightSignaturePads();
  }
  initializeWeightSignaturePads() {
    // console.log("this.customerSingatureCanvasWeight", this.customerSingatureCanvasWeight);
    this.customerSignaturePadWeight = new SignaturePad(this.customerSingatureCanvasWeight.nativeElement);
    this.surveyMgrSignaturePadWeight = new SignaturePad(this.surveyMgrSingatureCanvasWeight.nativeElement);
    // console.log("this.customerSingatureCanvasWeight", this.customerSignaturePadWeight);

  }
  getPackagingDetailsByLeadId() {
    this._movdService.getPackagingDetailsByLeadId(this._leadId).subscribe({
      next: (data: PackingListResponse) => {
        //onsole.log("packaging details by leadId", data);
        if (data && data.packingList.length > 0) {
          //console.log('packaginggg by lead id', data);
          this._packingBoardDTOList = data.packingList;
          this._isDataLoaded = true;
          const filteredData = data.packingList.find((item: PackagingBoardListDTO) => item.packagingDetailId === this._packagingDetailId);
          this._packingDetail = filteredData || new PackagingBoardListDTO();
          this._packagingDetailDTO = filteredData;
          // console.log('this._packingDetail = filteredData;', filteredData);
          this.weightCertificateTitle();
          this._showWeightCertificate = this.weightCertificateTitle();
          this.getPackagingWeightCertByPackagingDetailId();
        } else {

        }

      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }
  getPackagingWeightCertByPackagingDetailId() {
    if (!this._packagingDetailId) {
      return;
    } this._movdService.getPackagingWeightCertByPackagingDetailId(this._packagingDetailId).subscribe({
      next: (data: any) => {
        // console.log('getPackagingWeightCertByPackagingDetailId', data);
        if (data && data.length > 0) {
          this._packagingWeightCert = data[0];
          this._packagingWeightCertId = data[0].id;

          if (this._packagingWeightCert.externalNotes) {
            this._externalNotesWeight = this._packagingWeightCert.externalNotes;
          }
          if (this._packagingWeightCert.customerSignature) {
            this.renderCustomerSignature(this._packagingWeightCert.customerSignature, 'weight');
          }

          if (this._packagingWeightCert.managerSignature) {
            this.renderManagerSignature(this._packagingWeightCert.managerSignature, 'weight');
          }
          this.getPackagingWeightCertItemsByPackagingInventoryId();
        } else {
          this._packagingWeightCertId = 0;
          this._externalNotesWeight = null;
          this._packagingWeightCert = new PackagingWeightCert();
          this.weightCertsList = [];
          // this.initializeWeightSignaturePads();
        }
      },
      error: (error: any) => {
        console.error("error getting weigh certificates", error);
      }
    });
  }

  getPackagingWeightCertItemsByPackagingInventoryId() {
    this._packagingWeightCertId = this._packagingWeightCert.id;
    // console.log("weight certificate id:", this._packagingWeightCertId);
    this._movdService.getPackagigWeightCertItemsByPackagingInventoryId(this._packagingWeightCertId).subscribe({
      next: (data: any) => {
        // console.log('Packaging weight cert items get', data);
        if (data) {
          this.weightCertsList = data;
          // console.log('weight cets list', this.weightCertsList);
        } else {
          this.weightCertsList = [];
        }
      },
      error: (error: any) => {
        console.error("error gettting packing weight cert items", error);
        if (error && error.status === 404 && this.weightCertsList.length === 0) {
          this.weightCertsList = [];
        }
      }
    });
  }

  addWeightCertItem() {
    this.weightCertsList.push({
      number: null,
      dimensions: '',
      gross: null,
      tare: null,
      net: null,
      volumeWeight: null,
      sealNo: null,
      isDeleted: false,
    });
    // console.log("addWeightCertItem", this.weightCertsList);
  }
  renderCustomerSignature(signatureDataURL: string, type: string) {
    const img = new Image();
    img.onload = () => {
      if (type === 'weight') {
        // Check if customerSignaturePadInvent is defined before trying to clear it
        if (this.customerSignaturePadWeight) {
          // Clear the customer signature canvas (optional)
          this.customerSignaturePadWeight.clear();

          // Draw the customer signature
          this.customerSignaturePadWeight.fromDataURL(signatureDataURL);
        } else {

          console.error('customerSignaturePadWeight is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }

  renderManagerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (type === 'weight') {
        if (this.surveyMgrSignaturePadWeight) {
          this.surveyMgrSignaturePadWeight.clear();

          this.surveyMgrSignaturePadWeight.fromDataURL(signatureDataURL);
        } else {
          console.error('surveyMgrSignaturePadWeight is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  weightCertificateTitle(): boolean {
    // console.log('moveTransport', this._packingDetail.moveTransport);
    if (this._packingDetail.moveTransport === eTransportType.Air) {
      this._weightCertTitle = 'AIR WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.Sea) {
      this._weightCertTitle = 'SEA WEIGHT CERTIFICATE';
      return true;
    } else if (this._packingDetail.moveTransport === eTransportType.SeaLCL) {
      this._weightCertTitle = 'SEA(LCL) WEIGHT CERTIFICATE';
      return true;
    } else {
      // this._weightCertTitle = 'WEIGHT CERTIFICATE';
      return false;
    }
  }
  createWeightPdf() {
    return new Promise<void>((resolve, reject) => {
      // Include your docDefinition code here
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: this._weightCertTitle,
            style: 'header',
            alignment: 'left',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            table: {
              layout: 'lightHorizontalLines',
              widths: ['50%', '50%'], // Two columns
              body: []
            },
            margin: [0, 10, 0, 20] // [left, top, right, bottom]
          }
        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      // Your dynamic data for the second table
      var firstTableData = [];


      firstTableData.push(
        [{ text: 'Move Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.moveDetailId ? this.generalService.formatId(this._packagingDetailDTO.moveDetailId, 'move') : '-'), style: 'columnText', alignment: 'left' }]
      );




      firstTableData.push(
        [{ text: 'Customer Id:', style: 'columnText', alignment: 'left' },
        { text: (this._packagingDetailDTO?.customerId ? this.generalService.formatId(this._packagingDetailDTO.customerId, 'customer') : '-'), style: 'columnText', alignment: 'left' }]
      );


      firstTableData.push(
        [{ text: 'Customer Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.customerName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Account Name:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveType(this._packagingDetailDTO?.moveType || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Move Category:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveCategoryType(this._packagingDetailDTO?.moveCategory || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Transport Type:', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapTransportType(this._packagingDetailDTO?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Origin:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.origin || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Destination:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.destination || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'POL:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'POD:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Place Of Delivery:', style: 'columnText', alignment: 'left' },
        { text: this._packagingDetailDTO?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Client Notes:', style: 'columnText', alignment: 'left' },
        { text: this._externalNotesWeight || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[2] && docDefinition.content[2].table && docDefinition.content[2].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[2].table.body.push(data);
        });
      }
      // console.log('first table data', firstTableData);

      // Inventory Items List

      // Weight Certificate Items Table
      if (this.weightCertsList.length > 0) {
        docDefinition.content.push(
          { text: this._weightCertTitle + '  ITEMS', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] }
        );

        var weightCertsListData = [];
        for (let row of this.weightCertsList) {
          if (row.isDeleted === false) {
            weightCertsListData.push([row.itemNo, row.dimensions, row.gross, row.tare, row.net, row.volumeWeight, row.sealNo]);
          }
        }

        docDefinition.content.push(
          {
            table: {
              layout: 'lightHorizontalLines',
              headerRows: 1,
              widths: ['14%', '15%', '14%', '14%', '14%', '15%', '14%'],
              body: [
                [
                  { text: 'Item#', style: 'tableHeader', alignment: 'left' },
                  { text: 'Dimensions', style: 'tableHeader', alignment: 'left' },
                  { text: 'Gross Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Tare Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Net Lbs ', style: 'tableHeader', alignment: 'left' },
                  { text: 'Volume Weight', style: 'tableHeader', alignment: 'left' },
                  { text: 'Seal Number', style: 'tableHeader', alignment: 'left' },
                ],
                // Dynamic data goes here
                ...weightCertsListData,
              ],
            },
            margin: [0, 10, 0, 10], // [left, top, right, bottom]
          }
        );

        if (docDefinition && docDefinition.content && docDefinition.content[7] && docDefinition.content[7].table && docDefinition.content[7].table.body) {
          // Add dynamic data to the second table
          weightCertsListData.forEach(data => {
            docDefinition.content[7].table.body.push(data);
          });
        }
      }
      docDefinition.content.push({
        columns: [
          {
            stack: [
              {
                text: 'Customer Signature:', style: 'columnText', alignment: 'left',
                margin: [0, 30, 0, 0]
              },
              this._packagingWeightCert.customerSignature ? {
                image: this._packagingWeightCert.customerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0]
              } : null
            ]
          },
          {
            stack: [
              {
                text: 'Manager Signature:', style: 'columnText', alignment: 'right',
                margin: [0, 30, 0, 0]
              },
              this._packagingWeightCert.managerSignature ? {
                image: this._packagingWeightCert.managerSignature,
                width: 100,
                height: 50,
                margin: [0, 10, 0, 0],
                alignment: 'right'
              } : null
            ]
          }
        ]
      });
      // console.log('docDefinition:', docDefinition);

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async viewWeightPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createWeightPdf();
    }
    this.pdfDocGenerator.open();
  }
  async downloadWeightPdf() {
    if (!this.pdfDocGenerator || this.pdfDocGenerator) {
      await this.createWeightPdf();
    }
    this.pdfDocGenerator.download();
  }
}
