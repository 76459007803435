import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { __makeTemplateObject } from 'tslib';
import { BehaviorSubject } from 'rxjs';
import { AuditLogResponse } from '../app/views/audit-log/audit-log.models';
@Injectable({
  providedIn: 'root'
})
export class AuditLogService {
  readonly APIUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getAuditLogs(page: number, pageSize: number, searchTerm: string | null): Observable<AuditLogResponse> {
    const ownedBy = Number(localStorage.getItem("companyId"));
    let url = `${this.APIUrl}/AuditLog/GetLogs?page=${page}&pageSize=${pageSize}&ownedBy=${ownedBy}`;
    if (searchTerm) {
      url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
    }
    return this.http.get<AuditLogResponse>(url);
  }

}
