import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerSatisfaction } from "src/app/views/movd/reports/models/customer-satisfaction.model";
import { MoveReportsDTO } from "src/app/views/movd/reports/models/move-reports-dto.model";
import { SalesReportDTO } from "src/app/views/movd/reports/models/sales-reports-dto.model";


import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  readonly APIUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getLeadReports(reports: MoveReportsDTO): Observable<any> {
    return this.http.post(`${this.APIUrl}/Reports/GetMoveSummarizeReports`, reports);
  }
  getSalesReports(reports: SalesReportDTO): Observable<any> {
    return this.http.post(`${this.APIUrl}/Reports/GetSalesReports`, reports);
  }
  getCustomerSatisfactionReports(reports: CustomerSatisfaction): Observable<any> {
    return this.http.post(`${this.APIUrl}/Reports/GetCustomerSatisfaction`, reports);
  }

  // getLeadReports(): Observable<any> {
  //   return this.http.get(`${this.APIUrl}/Reports/GetLeadReports`);
  // }
}
