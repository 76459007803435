<ng-template #weightCertificateList>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">
                <ng-container *ngIf="_companySetting?.logo as companyLogo; else defaultLogo">
                    <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo" width="150">
                </ng-container>
                <ng-template #defaultLogo>
                    <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
                </ng-template>
            </div>

            <h1 class="text-center mt-3">{{weightCertificateTitle}}</h1>
            &nbsp;
            <h4> General Info</h4>
            <table class="table table-bordered mt-2" *ngIf="_packagingDetailDTO">
                <tbody>
                    <tr>
                        <td><label>Move ID</label></td>
                        <td>#{{ _packagingDetailDTO?.moveDetailId || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Customer ID</label></td>
                        <td>#{{ _packagingDetailDTO?.customerId || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Customer Name</label></td>
                        <td>{{ _packagingDetailDTO?.customerName || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Account Name</label></td>
                        <td>{{ _packagingDetailDTO?.accountName || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Move Type</label></td>
                        <td>{{ enumMappingService.mapMoveType(_packagingDetailDTO?.moveType || 0) }}
                        </td>
                    </tr>
                    <tr>
                        <td><label>Move Category</label></td>
                        <td>{{
                            enumMappingService.mapMoveCategoryType(_packagingDetailDTO?.moveCategory
                            || 0) }}
                        </td>
                    </tr>
                    <tr>
                        <td><label>Transport Type</label></td>
                        <td>{{
                            enumMappingService.mapTransportType(_packagingDetailDTO?.moveTransport
                            ||
                            0) }}
                        </td>
                    </tr>
                    <tr>
                        <td><label>Origin</label></td>
                        <td>{{ _packagingDetailDTO?.origin || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Destination</label></td>
                        <td>{{ _packagingDetailDTO?.destination || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>POL</label></td>
                        <td>{{ _packagingDetailDTO?.portOfLoading || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>POD</label></td>
                        <td>{{ _packagingDetailDTO?.portOfDischarge || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Place Of Delivery</label></td>
                        <td>{{ _packagingDetailDTO?.placeOfDelivery || ' - ' }}</td>
                    </tr>
                    <!-- <tr>
                        <td><label>Survey Date</label></td>
                        <td>{{ _packagingDetailDTO?.surveyDate || ' - '}}</td>
                    </tr>
                    <tr>
                        <td><label>Packing Date</label></td>
                        <td>{{ _packagingDetailDTO?.packagingDate || ' - ' }}</td>
                    </tr>
                    <tr>
                        <td><label>Loading Date</label></td>
                        <td>{{ _packagingDetailDTO?.loadingDate || ' - ' }}</td>
                    </tr> -->
                    <tr>
                        <td><label>Client Notes</label></td>
                        <td>{{ _externalNotesWeight || ' - ' }}</td>
                    </tr>
                </tbody>
            </table>

            &nbsp;
            <h4> Items List </h4>
            <table class="table table-bordered mt-2">
                <thead>
                    <tr>
                        <th>Item #</th>
                        <th>Dimensions</th>
                        <th>Gross Lbs</th>
                        <th>Tare Lbs</th>
                        <th>Net Lbs</th>
                        <th>Volume Weight</th>
                        <th>Seal Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of weightCertsList; let index = index">
                        <ng-container *ngIf="row.isDeleted===false">
                            <td>{{row.itemNo}}</td>
                            <td>{{row.dimensions}}</td>
                            <td>{{row.gross}}</td>
                            <td>{{row.tare}}</td>
                            <td>{{row.net}}</td>
                            <td>{{row.volumeWeight}}</td>
                            <td>{{row.sealNo}}</td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</ng-template>

<ng-template #weightCertImgPreview let-modal>
    <div class="modal-header">
        <h4 class="modal-title">File Preview</h4>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="_weightCertFile?.startsWith('data:image')">
            <img [src]="sanitizeDocumentUrl(_weightCertFile)" alt="Image" class="modal-image" width="100%"
                height="100%">
        </ng-container>
        <ng-container *ngIf="_weightCertFile?.startsWith('data:application')">
            <embed [src]="sanitizeDocumentUrl(_weightCertFile)" type="application/pdf" width="100%" height="600px">
        </ng-container>
    </div>
    <div class="modal-footer">
        <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf" (change)="handleWeightCertFileInput($event)"
            #fileInput />
        <ng-container *ngIf="_createPermission">
            <button class="btn btn-primary" (click)="fileInput.click()">
                <i class="mdi mdi-upload"></i> &nbsp; Upload</button>
        </ng-container>
        <ng-container *ngIf="_deletePermission">
            <button class="btn btn-danger" (click)="removeWeightCertFile()">
                <i class="mdi mdi-delete"></i> &nbsp; Remove</button>
        </ng-container>
    </div>
</ng-template>

<div class="mt-2">
    <div class="row">
        <div class="col-12">
            <button type="button" class="btn btn-sm btn-primary float-end" (click)="downloadPdf()">
                <i class="mdi mdi-file-export"></i> &nbsp; Export
            </button>
            &nbsp;
            <button class="btn btn-sm btn-primary float-end me-2" (click)="viewPdf();">
                <i class="mdi mdi-file-eye"> </i> &nbsp; View
            </button>
            <input type="file" class="file-input" accept=".jpg,.jpeg,.png,.pdf"
                (change)="handleWeightCertFileInput($event)" #fileInput />
            <ng-container *ngIf="_createPermission">
                <button class="btn btn-sm btn-outline-primary float-end me-2" (click)="fileInput.click()">
                    <i class="mdi mdi-upload"></i> &nbsp; {{ _packagingWeightCert.uploadedFile ?
                    'Uploaded' : 'Upload' }}
                </button>
            </ng-container>
            <ng-container *ngIf="_readPermission">
                <label *ngIf="_packagingWeightCert.uploadedFile" class="float-end me-3 mt-2"><strong>
                        Uploaded File: &nbsp;</strong><a class="clickable-link"
                        (click)="openWeightCertFileModal(weightCertImgPreview)">
                        <span> Click to Open</span>
                    </a>
                </label>
            </ng-container>
        </div>
        <div class="col-12 mt-3">
            <table class="custom-table-sm border border-gray">
                <thead>
                    <tr>
                        <th>Item No #</th>
                        <th>Dimensions</th>
                        <th>Gross Lbs</th>
                        <th>Tare Lbs</th>
                        <th>Net Lbs</th>
                        <th>Volume Weight</th>
                        <th>Seal Number</th>
                        <th *ngIf="weightCertsList.length !== 0 && (_createPermission || _deletePermission)"></th>
                        <!-- Empty header for the actions column -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="weightCertsList.length === 0">
                        <td colspan="7" class="text-center">No Items available</td>
                    </tr>
                    <tr *ngFor="let row of weightCertsList; let index = index">
                        <ng-container *ngIf="row.isDeleted===false">
                            <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.itemNo"
                                    readonly>
                            </td>
                            <td><input class="form-control table-form-control" type="text" [(ngModel)]="row.dimensions">
                            </td>
                            <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.gross">
                            </td>
                            <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.tare">
                            </td>
                            <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.net">
                            </td>
                            <td><input class="form-control table-form-control" type="number"
                                    [(ngModel)]="row.volumeWeight"></td>
                            <td><input class="form-control table-form-control" type="number" [(ngModel)]="row.sealNo">
                            </td>
                            <td class="actions-column"
                                *ngIf="weightCertsList.length !== 0 && (_createPermission || _deletePermission)">
                                <div class="button-group">
                                    <ng-container *ngIf="_deletePermission">
                                        <button class="btn feather icon-trash"
                                            (click)="deleteWeightCertItem(index)"></button>
                                    </ng-container>
                                    <ng-container *ngIf="_createPermission">
                                        <button class="btn feather icon-save"
                                            (click)="saveWeightCertItems(index)"></button>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center mt-2">
                <ng-container *ngIf="_createPermission">
                    <button class="plus-button float-end mt-1" (click)="addWeightCertItem()"> <lord-icon
                            src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef">
                        </lord-icon>
                    </button>
                </ng-container>
            </div>

        </div>

        <div class="col-12 mt-5">
            <h6 class="heading"> &nbsp; BEFORE SIGNING, CHECK ITEMS CONDITION & DESCRIBE LOSS OR
                DAMAGE(IF ANY)</h6>
            <div class="internal-notes-box mt-2">
                <textarea class="form-control" rows="3" placeholder="External Notes"
                    [(ngModel)]="_packagingWeightCert.externalNotes"></textarea>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="custom-margin">
                        <div class="signature-box">
                            <div class="row">
                                <div class="col-6">
                                    <div><label>Customer Signature</label></div>
                                    <canvas #customerSingatureCanvasWeight class="signature-pad"></canvas>
                                </div>
                                <div class="col-6">
                                    <div><label>Suvery Manager Signature</label></div>
                                    <canvas #surveyMgrSingatureCanvasWeight class="signature-pad"></canvas>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="btn btn-danger btn-xs"
                                        (click)="clearSignature('customer', 'weight')">Clear</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-danger btn-xs"
                                        (click)="clearSignature('manager', 'weight')">Clear</button>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <ng-container *ngIf="_createPermission">
                                    <button class="btn btn-primary btn-sm float-end" (click)="saveWeightSignature()">
                                        <i class="mdi mdi-content-save"></i> &nbsp; Save
                                        Signature</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <ng-container *ngIf="_createPermission">
                        <button class="btn btn-primary btn-sm float-end" (click)="savePackagingWeightCert()">
                            <i class="mdi mdi-content-save"></i> &nbsp; SUBMIT INVENTORY
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <hr> -->
<div #pdfContent class="hidden-pdf-content">
    <ng-container [ngTemplateOutlet]="weightCertificateList"></ng-container>
</div>