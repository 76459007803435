export class MoveAttachment {
    id: number;
    refId: number;
    title: string;
    dueDate: Date | null;
    type: eAttachmentType;
    url: string | null;
    isValid: boolean | null;
    imageData: string | null;
    creationDate: Date;
    modificationDate: Date | null;
    isDeleted: boolean;


    constructor() {
        this.id = 0;
        this.refId = 0;
        this.title = '';
        this.type = eAttachmentType.SurveyImages;
        this.url = null;
        this.isValid = null;
        this.imageData = null;
        this.creationDate = new Date();
        this.modificationDate = null;
        this.isDeleted = false;
        this.dueDate = null;
    }
}

export enum eAttachmentType {
    SurveyImages = 10,
    ClientDocuments = 20,
    MoveDocuments = 30
}
