<ng-container *ngIf="isLoading==true">
  <span id="loader">
    <span class="spinner"></span>
  </span>
</ng-container>
<div *ngIf="isLoading==false || !_readPermission">
  <!--Notes Modal-->
  <ng-template #notesModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="notesModalLabel">
        <div class="TitleIcon">
          <lord-icon class="modal_icons" colors="primary:#121331,secondary:#1ac0a1"
            src="https://cdn.lordicon.com/nocovwne.json" trigger="hover" state="hover-2"></lord-icon>
          <span class="TitleText">Note</span>
        </div>
      </h5>
      <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
    </div>
    <div class="modal-body">
      <div class="row">
        <input type="hidden" id="_note.id" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control no-border" [(ngModel)]="_note.details" id="_note.details" autocomplete="on"
            value="{{_note.details}}" placeholder="Start typing to leave a note..." required
            #noteDetails="ngModel"></textarea>
          <div class="error-message-container">
            <div *ngIf="noteDetails.invalid && (noteDetails.dirty || noteDetails.touched)" class="error-message error"
              style="color: red;">
              <div *ngIf="noteDetails.errors?.['required']">Write something to create a note.</div>
            </div>
          </div>

        </div>


      </div>
      &nbsp;
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
      <button type="button" class="btn btn-primary btn-sm" (click)="saveNote();">
        <i class="mdi mdi-content-save"> </i> &nbsp;Save note
      </button>
    </div>
  </ng-template>
  <!--Emails Modal-->
  <ng-template #emailsModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="emailsModalLabel">
        <div class="TitleIcon">
          <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="hover" class="modal_icons"
            colors="primary:#121331,secondary:#6571ff"></lord-icon>
          <span class="TitleText"> Email</span>
        </div>
      </h5>
      <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
    </div>
    <div class="modal-body">
      <div class="row">
        <input type="hidden" id="_email.id" />
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="from">From</label>
          <input type="email" class="form-control input-color" [(ngModel)]="_email.from" placeholder="From" required />
        </div>
        <div class="col-md-6">
          <label for="to">To</label>
          <input type="email" class="form-control input-color" [(ngModel)]="_email.to" placeholder="To" required />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="selectedDate">Date</label>
          <input type="date" class="form-control input-color" [(ngModel)]="selectedDate" id="selectedDate"
            placeholder="Select date" required />
        </div>
        <div class="col-md-6">
          <label for="selectedTime">Time</label>
          <input type="time" class="form-control input-color" [(ngModel)]="selectedTime" id="selectedTime"
            placeholder="Select time" required />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" [(ngModel)]="_email.details" id="_email.details" autocomplete="on"
            value="{{_email.details}}" placeholder="Describe the email..." required #emailDetails="ngModel"></textarea>
          <div *ngIf="emailDetails.invalid && (emailDetails.dirty || emailDetails.touched)" class="error-message error"
            style="color: red;">
            <div *ngIf="emailDetails.errors?.['required']">Write something to log an email.</div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer text-end">
      <ng-container *ngIf="_updatePermission">
        <button type="button" class="btn btn-primary btn-sm" (click)="saveEmail();">
          <i class="mdi mdi-content-save"> </i> &nbsp;Save
        </button>
      </ng-container>
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
    </div>
  </ng-template>
  <!--Calls Modal-->
  <ng-template #callsModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="callsModalLabel">
        <div class="TitleIcon">
          <lord-icon src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover" colors="primary:#5f606c"
            class="solid_icons"></lord-icon>
          <span class="TitleText"> Call </span>
        </div>
      </h5>
      <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
    </div>
    <div class="modal-body">
      <div class="row">
        <input type="hidden" id="_call.id" />
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="_call.outcome" class="input-form-labels">Call Outcome</label>
          <ng-select class="textarea-left-align input-color" [(ngModel)]="_call.outcome" id="_call.outcome" required>
            <ng-option value="" selected>Select an outcome</ng-option>
            <ng-option value="Busy">Busy</ng-option>
            <ng-option value="Connected">Connected</ng-option>
            <ng-option value="Left live message">Left live message</ng-option>
            <ng-option value="Left voice mail">Left voice mail</ng-option>
            <ng-option value="No answer">No answer</ng-option>
            <ng-option value="Wrong number">Wrong number</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6">
          <label for="_call.direction" class="input-form-labels">Call Direction</label>
          <ng-select class="textarea-left-align input-color" [(ngModel)]="_call.direction" id="_call.direction"
            required>
            <ng-option value="" selected>Select call direction</ng-option>
            <ng-option value="Inbound">Inbound</ng-option>
            <ng-option value="Outbound">Outbound</ng-option>
          </ng-select>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-6">
          <label for="selectedDate" class="input-form-labels">Date</label>
          <input type="date" class="form-control textarea-left-align input-color" [(ngModel)]="selectedDate"
            id="selectedDate" placeholder="Select date" required />
        </div>
        <div class="col-md-6">
          <label for="selectedTime" class="input-form-labels">Time</label>
          <input type="time" class="form-control textarea-left-align input-color" [(ngModel)]="selectedTime"
            id="selectedTime" placeholder="Select time" required />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control" [(ngModel)]="_call.details" id="_call.details" autocomplete="on"
            value="{{_call.details}}" placeholder="Describe the call..." required #callDetails="ngModel"></textarea>
          <div *ngIf="callDetails.invalid && (callDetails.dirty || callDetails.touched)" class="error-message error"
            style="color: red;">
            <div *ngIf="callDetails.errors?.['required']">Write something to log a call.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="_updatePermission">
        <button type="button" class="btn btn-primary btn-sm" (click)="saveCall();">
          <i class="mdi mdi-content-save"> </i> &nbsp;Save
        </button>
      </ng-container>
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
    </div>
  </ng-template>

  <!--Tasks Modal-->
  <ng-template #tasksModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="tasksModalLabel">
        <div class="TitleIcon">
          <lord-icon src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover" colors="primary:#5f606c"
            class="solid_icons"></lord-icon>
          <span class="TitleText">Task</span>
        </div>
      </h5>
      <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
    </div>
    <div class="modal-body">

      <div class="row">
        <input type="hidden" id="_task.id" />
        <div class="col-md-12">
          <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title" autocomplete="on"
            value="{{_task.title}}" placeholder="Enter your task" required #taskTitle="ngModel" />
          <div *ngIf="taskTitle.invalid && (taskTitle.dirty || taskTitle.touched)" class="error-message error"
            style="color: red;">
            <div *ngIf="taskTitle.errors?.['required']"> * required.</div>
          </div>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-6">
          <label for="_task.dueDate" class="form-label no-border">Due Date</label>
          <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate" autocomplete="on"
            required />
        </div>
        <div class="col-md-6">
          <label for="_task.assignedto" class="form-label no-border">Assigned To</label>
          <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="customerId" [searchable]="true"
            [(ngModel)]="_task.assignedto" placeholder="Select Contact" [clearable]="true" required>
          </ng-select>
        </div>
        <div class="col-md-6" *ngIf="_taskUpdate">
          <label for="_task.label" class="form-label no-border">Mark as Done</label>
          <!-- <input type="checkbox" [(ngModel)]="_task.done" id="_task.done"
       autocomplete="on" value="{{ _task.done }}"  (change)="toggleTaskDone(_task)" /> -->
          <ng-select class="left-align input-color" [(ngModel)]="_task.done" [bindLabel]="_task.done ? 'Done' : 'None'"
            (ngModelChange)="toggleTaskDone(_task)" id="_task.done">
            <ng-option [value]="true">Done</ng-option>
            <ng-option [value]="false">None</ng-option>
          </ng-select>

          <span class="checkmark" [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-6">
          <label for="_task.type" class="input-form-labels">Type</label>
          <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
            <ng-option value="To-do" selected>To-do</ng-option>
            <ng-option value="Call">Call</ng-option>
            <ng-option value="Email">Email</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6">
          <label for="_task.priority" class="input-form-labels">Priority</label>
          <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
            <ng-option value="None" selected>None</ng-option>
            <ng-option value="Low">Low</ng-option>
            <ng-option value="Medium">Medium</ng-option>
            <ng-option value="High">High</ng-option>
          </ng-select>
        </div>
        <!-- <div class="col-md-4">
        <label for="_task.queue" class="input-form-labels">Queue</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.queue" id="_task.queue">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Create a new queue">Create a new queue</ng-option>
        </ng-select>
      </div> -->
      </div>
      &nbsp;
      <div class="row">
        <div class="col-md-12">
          <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details" autocomplete="on"
            value="{{_task.details}}" placeholder="Notes..." required></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
        <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
      </button>
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
    </div>
  </ng-template>

  <!--Meetings Modal-->
  <ng-template #meetingsModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="meetingsModalLabel">
        <div class="TitleIcon">
          <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover"
            colors="primary:#121331,secondary:#1ac0a1" class="modal_icons"></lord-icon>
          <span class="TitleText"> Meeting </span>
        </div>
      </h5>
      <!--{{_meetingTitle}}-->
      <!--<button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>-->
    </div>
    <div class="modal-body">
      <!-- <div class="row">
        <div class="col-md-6">
          <label for="_meeting.outcome" class="input-form-labels">Outcome</label>
          <ng-select class="left-align input-color" [(ngModel)]="_meeting.outcome" id="_meeting.outcome" required>
            <ng-option value="">Select meeting outcome</ng-option>
            <ng-option value="(No value)">(No value)</ng-option>
            <ng-option value="Scheduled">Scheduled</ng-option>
            <ng-option value="Completed">Completed</ng-option>
            <ng-option value="Resheduled">Resheduled</ng-option>
            <ng-option value="No answer">No show</ng-option>
            <ng-option value="Cancelled">Cancelled</ng-option>
          </ng-select>
        </div>
      </div>-->
      &nbsp;
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="subject" class="input-form-labels">Subject</label>
          <input type="text" class="form-control " [(ngModel)]="_meeting.title" id="subject"
            placeholder="Enter a Subject" required />
        </div>
        <div class="col-md-6">
          <label for="selectedDate" class="input-form-labels">Date</label>
          <input type="date" class="form-control" [(ngModel)]="selectedDate" id="selectedDate" placeholder="Select date"
            required />
        </div>
        <div class="col-md-6">
          <label for="selectedTime" class="input-form-labels">Time</label>
          <input type="time" class="form-control" [(ngModel)]="selectedTime" id="selectedTime" placeholder="Select time"
            required />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <input type="hidden" id="_meeting.id" />
        <div class="col-md-12">
          <textarea class="form-control no-border" [(ngModel)]="_meeting.details" id="_meeting.details"
            autocomplete="on" value="{{_meeting.details}}" placeholder="Describe the meeting" required
            #meetingDetails="ngModel"></textarea>
          <div *ngIf="meetingDetails.invalid && (meetingDetails.dirty || meetingDetails.touched)"
            class="error-message error" style="color: red;">
            <div *ngIf="meetingDetails.errors?.['required']">Write something to log a meeting.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
      <button type="button" class="btn btn-primary btn-sm" (click)="saveMeeting();">
        <i class="mdi mdi-content-save"> </i> &nbsp;Save
      </button>
    </div>
  </ng-template>

  <div *ngIf="isDataLoaded">
    <nav class="page-breadcrumb">
      <ol class="breadcrumb">
        <!-- <li class="breadcrumb-item"><a routerLink="../dashboard">Dashboard</a></li> -->
        <h5 class="breadcrumb-item">PROFILE</h5>
        <!-- <ng-container *ngIf="_viewname === 'contact' || _viewname === 'company'">
          <li class="breadcrumb-item"><a routerLink="../contact">Contacts</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{_profile.customerName}}</li>
        </ng-container> -->
        <ng-container *ngIf="_viewname === 'deal' && _deal != null">
          <!-- <li class="breadcrumb-item"><a routerLink="../sales">Deals</a></li> -->
          <!-- <li class="breadcrumb-item active" aria-current="page">{{_dealDetailDTO.name}}</li> -->
        </ng-container>
      </ol>
    </nav>

    <div class="row profile-body">
      <div class=" d-md-block col-md-3" style="padding-right:0">
        <div class="card rounded">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-sm-12 col-xs-12">
                <div *ngIf="_viewname === 'contact' || _viewname === 'company'">
                  <div class="profile-icon img-lg rounded-circle" style="width:60px; height:60px">
                    <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
                      colors="primary:#121331,secondary:#1ac0a1" class="profile_icons">
                    </lord-icon>
                  </div>
                </div>
                <div *ngIf="_viewname === 'deal'">
                  <div class="profile-icon img-lg rounded-circle" style="width:60px; height:60px">
                    <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
                      colors="primary:#121331,secondary:#1ac0a1" class="profile_icons">
                    </lord-icon>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-sm-12 col-xs-12">
                <div class="justify-content-between mb-2 ml-2">
                  <ng-container *ngIf="_viewname !== 'deal'">
                    <div class="mt-3" (click)="isEditContactName = true" *ngIf="!isEditContactName">
                      <h5 class="profile-title  d-flex align-items-center" placement="top"
                        ngbPopover="{{_profile.customerName}}" triggers="hover">
                        <span class="custom-text-overflow">
                          {{ _profile.customerName}}</span>
                        <i class="feather icon-edit icon-sm"></i>
                      </h5>
                    </div>
                    <div class="mt-3" *ngIf="isEditContactName">
                      <label class="tx-11 fw-bolder mb-0 text-uppercase">Contact Name</label>
                      <input type="text" class="form-control" [(ngModel)]="_profile.customerName" />
                      <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditContactName = false">
                        Save
                      </button>
                      &nbsp;
                      <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelContactName()">
                        Cancel
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="_viewname === 'deal'">
                    <div class="mt-3" (click)="isEditDealName = true" *ngIf="!isEditDealName">
                      <h5 class="profile-title d-flex align-items-center" placement="top"
                        ngbPopover="{{_dealDetailDTO.name}}" triggers="hover">
                        <span class="custom-text-overflow">
                          {{ _dealDetailDTO.name}}</span>
                        <i class="feather icon-edit icon-sm"></i>
                      </h5>
                    </div>
                    <div class="mt-3" *ngIf="isEditDealName">
                      <label class="fw-bolder mb-0 text-uppercase">Deal Name</label>
                      <input type="text" class="form-control" [(ngModel)]="_dealDetailDTO.name" />
                      <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal(); isEditDealName = false">
                        Save
                      </button>
                      &nbsp;
                      <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelDealName()">
                        Cancel
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="_deletePermission">
                    <div ngbDropdown>
                      <button class="btn p-0 no-dropdown-toggle-icon text-end" type="button" id="dropdownMenuButton"
                        ngbDropdownToggle>
                        <i class="feather icon-more-horizontal icon-lg text-muted pb-3px text-end"></i>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <a ngbDropdownItem class="d-flex align-items-center" *ngIf="_viewname ==='deal'"
                          (click)="deleteDealClick(_dealDetailDTO.id)">
                          <i class="feather icon-trash icon-sm me-2"></i> <span class="">Delete</span>
                        </a>
                        <a ngbDropdownItem class="d-flex align-items-center" *ngIf="_viewname !=='deal'"
                          (click)="deleteProfileClick(_profile.id)">
                          <i class="feather icon-trash icon-sm me-2"></i> <span class="">Delete</span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="mt-3">
                </div>
              </div>
            </div>
            &nbsp;
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex flex-wrap justify-content-between mb-2">
                  <div>
                    <div class="icon-container">
                      <span (click)="openNotesModel(notesModal);">
                        <lord-icon src="https://cdn.lordicon.com/nocovwne.json" trigger="hover" state="hover-2"
                          colors="primary:#121331,secondary:#1ac0a1" class="profileOptionIcons"></lord-icon>
                      </span>
                    </div>
                    <span class="label1" style="margin-left: 5px;">Notes</span>
                  </div>
                  <div>
                    <div class="icon-container">
                      <span (click)="openEmailsModel(emailsModal);">
                        <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="hover"
                          colors="primary:#121331,secondary:#6571ff" class="profileOptionIcons"></lord-icon>
                      </span>
                    </div>
                    <span class="label1" style="margin-left: 5px;">Emails</span>
                  </div>
                  <div>
                    <div class="icon-container">
                      <span (click)="openCallsModel(callsModal);">
                        <lord-icon src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover" colors="primary:#5f606c"
                          class="profile_solid_icons">
                        </lord-icon>
                      </span>
                    </div>
                    <span class="label1" style="margin-left: 12px;">Calls</span>
                  </div>
                  <div>
                    <div class="icon-container">
                      <span (click)="openTasksModel(tasksModal);">
                        <lord-icon src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover" colors="primary:#5f606c"
                          class="profile_solid_icons">
                        </lord-icon>
                      </span>
                    </div>
                    <span class="label1" style="margin-left: 5px;">Tasks</span>
                  </div>
                  <div (click)="openMeetingsModel(meetingsModal);">
                    <div class="icon-container">
                      <span>
                        <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover"
                          colors="primary:#121331,secondary:#1ac0a1" class="profileOptionIcons">
                        </lord-icon>
                      </span>
                    </div>
                    <span class="label1" style="margin-left: 5px;">Meetings</span>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;
            <ng-container *ngIf="_viewname === 'contact'">
              <div class="row">
                <h6 class="fw-bolder mb-0 text-uppercase">Contact Details</h6>
              </div>

              <div class="mt-3" *ngIf="!isEditDetail">
                <p class="text-muted">{{ _profile.details }}
                  <a class="me-2" (click)="isEditDetail = true" style="color: black">
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </p>
              </div>
              <div class="mt-3" *ngIf="isEditDetail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Contact Detail</label>
                <input type="text" class="form-control" [(ngModel)]="_profile.details" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditDetail = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditDetail = false">
                  Cancel
                </button>
              </div>
              <div class="mt-3" *ngIf="!isEditPosition">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditPosition = true" style="color: black">
                    Position
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.role }}</p>
              </div>
              <div class="mt-3" *ngIf="isEditPosition">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Position</label>
                <input type="text" class="form-control" [(ngModel)]="_profile.role" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditPosition = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditPosition = false">
                  Cancel
                </button>
              </div>
              <div class="mt-3" *ngIf="!isEditCompany">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditCompany = true" style="color: black">
                    Company
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.companyName }}</p>
              </div>
              <div class="mt-3" *ngIf="isEditCompany">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Company</label>
                <ng-select [items]="_companies" bindLabel="value" bindValue="id" [searchable]="true"
                  [(ngModel)]="_profile.companyId" (change)="onChangeCompany()" placeholder="Select Company">
                </ng-select>
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditCompany = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditCompany = false">
                  Cancel
                </button>
              </div>


              <div class="mt-3" *ngIf="!isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditEmail = true" style="color: black; cursor: pointer;">
                    Email <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.email }}</p>
              </div>


              <div class="mt-3" *ngIf="isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Email</label>
                <input type="email" class="form-control" [(ngModel)]="_profile.email" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditEmail = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditEmail = false">
                  Cancel
                </button>
              </div>


              <div class="mt-3" *ngIf="!isEditPhone">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditPhone = true" style="color:black">
                    Phone
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.phone }}</p>
              </div>
              <div class="mt-3" *ngIf="isEditPhone">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Phone</label>
                <input type="tel" class="form-control" [(ngModel)]="_profile.phone" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditPhone = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditPhone = false">
                  Cancel
                </button>
              </div>
              <div class="mt-3" *ngIf="!isEditAddr">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditAddr = true" style="color:black">
                    Street Address
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.streetAddress }}</p>
              </div>
              <div class="mt-3" *ngIf="isEditAddr">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Street Address</label>
                <input type="tel" class="form-control" [(ngModel)]="_profile.streetAddress" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditAddr = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditAddr = false">
                  Cancel
                </button>
              </div>

              <div class="mt-3" *ngIf="!isEditCity">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditCity = true" style="color:black">
                    City
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.city }}</p>
              </div>

              <div class="mt-3" *ngIf="isEditCity">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">City</label>
                <input type="tel" class="form-control" [(ngModel)]="_profile.city" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditCity = false">Save</button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditCity = false">Cancel</button>
              </div>


              <!-- <div class="mt-3" *ngIf="!isEditCountry">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditCountry = true" style="color:black">
                    Country
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ _profile.country }}</p>
              </div> -->
              <div class="mt-3" *ngIf="!isEditCountry">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditCountry = true" style="color:black">
                    Country
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{ getCountryName(_profile.country) }}</p>
              </div>
              <div class="mt-3" *ngIf="isEditCountry">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Country</label>
                <ng-select [items]="countries" bindLabel="value" bindValue="id" placeholder="Select a country"
                  [(ngModel)]="_profile.country">
                </ng-select>
                <button class="btn btn-primary btn-xs mt-2"
                  (click)="updateContact(); isEditCountry = false">Save</button>
                 
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditCountry = false">Cancel</button>
              </div>


              <div class="mt-3" *ngIf="!isEditType">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditType = true" style="color:black">
                    Contact Type
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted" *ngIf="!isEditType">{{ selectedSalesType?.value || 'None' }}
                </p>
              </div>
              <div class="mt-3" *ngIf="isEditType">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Contact Type</label>
                <ng-select *ngIf="isEditType" [items]="_types" bindLabel="value" bindValue="id"
                  [(ngModel)]="_profile.salesType">
                </ng-select>
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact(); isEditType = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditType = false">
                  Cancel
                </button>
              </div>

            </ng-container>

            <!--Company Profile-->
            <div *ngIf="_viewname === 'company'">
              <div class="row">
                <h6 class="fw-bolder mb-0 text-uppercase">Company Details</h6>
              </div>
              <!-- Industry Name -->
              <div class="mt-3">
                <label class="tx-11 fw-bolder mb-0 text-uppercase" *ngIf="_profile.industryName">
                  Industry Name
                </label>
                <p class="text-muted">{{_profile.industryName}}</p>
              </div>
              <!-- Number of Employees -->
              <div class="mt-3" *ngIf="!isEditNoOfEmployees">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <span class="me-2" style="color:black">
                    Number of Employees
                  </span>
                  <i class="feather icon-edit icon-sm" (click)="isEditNoOfEmployees = true"></i>
                </label>
                <p class="text-muted">{{_profile.noOfEmployees}}</p>
              </div>

              <div class="mt-3" *ngIf="isEditNoOfEmployees">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Number of Employees</label>
                <input type="text" class="form-control" [(ngModel)]="_profile.noOfEmployees" maxlength="25" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();this.isEditNoOfEmployees = false;">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelNoOfEmployees()">
                  Cancel
                </button>
              </div>

              <!-- Company Email -->
              <div class="mt-3" *ngIf="!isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <span class="me-2" style="color:black">
                    Company Email
                  </span>
                  <i class="feather icon-edit icon-sm" (click)="isEditEmail = true"></i>
                </label>
                <p class="text-muted">{{_profile.email}}</p>
              </div>

              <div class="mt-3" *ngIf="isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Company Email</label>
                <input type="email" class="form-control" [(ngModel)]="_profile.email" maxlength="75" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();isEditEmail = false;">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelEmail()">
                  Cancel
                </button>
              </div>
              <div class="mt-3" *ngIf="!isEditPhone">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <span class="me-2" style="color:black">
                    Phone
                  </span>
                  <i class="feather icon-edit icon-sm" (click)="isEditPhone = true"></i>
                </label>
                <p class="text-muted">{{_profile.phone}}</p>
              </div>

              <div class="mt-3" *ngIf="isEditPhone">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Phone</label>
                <input type="tel" class="form-control" [(ngModel)]="_profile.phone" maxlength="30" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();isEditPhone = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelPhone()">
                  Cancel
                </button>
              </div>

              <!-- Number of open positions -->
              <div class="mt-3" *ngIf="!isEditOpenPositions">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <span class="me-2" style="color:black">
                    Number of open positions
                  </span>
                  <i class="feather icon-edit icon-sm" (click)="isEditOpenPositions = true"></i>
                </label>
                <p class="text-muted">{{_profile.openPositions}}</p>
              </div>

              <div class="mt-3" *ngIf="isEditOpenPositions">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Number of open positions</label>
                <input type="number" class="form-control" [(ngModel)]="_profile.openPositions" maxlength="10" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();isEditOpenPositions = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelOpenPositions()">
                  Cancel
                </button>
              </div>

              <!-- Company URL -->
              <div class="mt-3" *ngIf="!isEditCompanyURL">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <span class="me-2" style="color:black">
                    Company URL
                  </span>
                  <i class="feather icon-edit icon-sm" (click)="isEditCompanyURL = true"></i>
                </label>
                <p class="text-muted">
                  <a [href]="_profile.companyURL | formatUrl" target="_blank"
                    class="text-decoration-underline text-primary">{{_profile.companyURL}}</a>
                </p>
              </div>

              <div class="mt-3" *ngIf="isEditCompanyURL">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Company URL</label>
                <input type="url" class="form-control" [(ngModel)]="_profile.companyURL " maxlength="255" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();isEditCompanyURL = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelCompanyURL()">
                  Cancel
                </button>
              </div>

              <!-- LinkedIn Company Page -->
              <div class="mt-3" *ngIf="!isEditLinkedInURL">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" style="color:black">
                    LinkedIn Company Page
                    <i class="feather icon-edit icon-sm" (click)="isEditLinkedInURL = true"></i>
                  </a>
                </label>
                <p class="text-muted">
                  <a [href]="_profile.linkedInURL | formatUrl" target="_blank"
                    class="text-decoration-underline text-primary">{{_profile.linkedInURL}}</a>
                </p>
              </div>

              <div class="mt-3" *ngIf="isEditLinkedInURL">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">LinkedIn Company Page</label>
                <input type="url" class="form-control" [(ngModel)]="_profile.linkedInURL" maxlength="255" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateContact();isEditLinkedInURL = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelLinkedInURL()">
                  Cancel
                </button>
              </div>

            </div>

            <!--Deal Profile-->
            <div *ngIf="_viewname === 'deal'">
              <div class="row">
                <h6 class="fw-bolder mb-0 text-uppercase">Deal Details</h6>
              </div>

              <!-- <div class="col-md-9 mt-3">
   <ng-select [items]="_contacts" bindLabel="customerName" bindValue="id"
  [searchable]="true" [(ngModel)]="_dealDetailDTO.contactId" placeholder="Select Contact">
   </ng-select>
   </div> -->

              <div class="col-md-9 mt-3" (click)="isEditContactName = true" *ngIf="!isEditContactName">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditContactName = true" style="color: black">
                    Contact Name
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{_dealDetailDTO.customerName}}</p>
              </div>

              <div class="col-md-9 mt-3" *ngIf="isEditContactName">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Contact</label>
                <ng-select [items]="_contacts" bindLabel="customerName" bindValue="id" [searchable]="true"
                  [(ngModel)]="_dealDetailDTO.customerId" placeholder="Select Contact" required>
                </ng-select>
                <div *ngIf="!_dealDetailDTO.customerId" class="text-danger">required!</div>
                <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal(); isEditContactName = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelContactName()">
                  Cancel
                </button>
              </div>


              <div class="mt-3">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Company</label>
                <p class="text-muted">{{_dealDetailDTO.companyName}}</p>
              </div>


              <div class="mt-3" *ngIf="!isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  Email
                </label>
                <p class="text-muted">{{ _dealDetailDTO.email }}</p>
              </div>

              <!-- <div class="mt-3" *ngIf="isEditEmail">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Email</label>
                <input type="email" class="form-control" [(ngModel)]="_dealDetailDTO.email" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal(); isEditEmail = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="isEditEmail = false">
                  Cancel
                </button>
              </div> -->




              <div class="mt-3" *ngIf="!isEditPhone">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  Phone
                </label>
                <p class="text-muted">{{_dealDetailDTO.phone}}</p>
              </div>


              <!-- <div class="mt-3" *ngIf="isEditPhone">
                <input type="text" class="form-control" [(ngModel)]="_dealDetailDTO.phone" placeholder="Enter Phone"
                  required>
                <div *ngIf="!_dealDetailDTO.phone" class="text-danger">Phone is required!</div>
                <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal(); isEditPhone = false">Save</button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelPhone()">Cancel</button>
              </div> -->



              <!-- Amount Display Mode -->
              <div class="mt-3" *ngIf="!isEditAmount">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditAmount = true" style="color:black">
                    Deal Amount
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{_dealDetailDTO.amount}}</p>
              </div>

              <!-- Amount Edit Mode -->
              <div class="mt-3" *ngIf="isEditAmount">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Deal Amount</label>
                <input type="number" class="form-control" [(ngModel)]="_dealDetailDTO.amount" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal();isEditAmount = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelAmount(); isEditAmount = false">
                  Cancel
                </button>
              </div>


              <!--Probability-->
              <div class="mt-3" *ngIf="!isEditProbability">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">
                  <a class="me-2" (click)="isEditProbability = true" style="color:black">
                    Probability
                    <i class="feather icon-edit icon-sm"></i>
                  </a>
                </label>
                <p class="text-muted">{{_dealDetailDTO.probability}}</p>
              </div>
              <div class="mt-3" *ngIf="isEditProbability">
                <label class="tx-11 fw-bolder mb-0 text-uppercase">Probability</label>
                <input type="number" class="form-control" [(ngModel)]="_dealDetailDTO.probability" />
                <button class="btn btn-primary btn-xs mt-2" (click)="updateDeal();isEditProbability = false">
                  Save
                </button>
                &nbsp;
                <button class="btn btn-secondary btn-xs mt-2" (click)="onCancelAmount(); isEditProbability = false">
                  Cancel
                </button>
              </div>
            </div>

            <br>

            <div class="card-header">Recent Notes</div>
            <div class="card-body nopadding">
              <div *ngIf="_overviewNotesList.length === 0">
                <h5 class="card-body">No Recent Notes.</h5>
              </div>
              <div *ngIf="_overviewNotesList.length > 0">
                <div *ngFor="let dataItem of _overviewNotesList.slice(0, 3); let i = index">

                  <div class="card rounded">
                    <div class="card-header">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <div class="title-icon img-xs rounded-circle">
                            {{
                            dataItem.details.charAt(0)
                            }}
                          </div>
                          <div class="ms-2">

                            <ng-container *ngIf="_viewname !== 'deal'">
                              <p class="fw-normal fw-bold" ngbPopover="{{ dataItem.details }}">
                                {{
                                dataItem.type === 'Task' ? 'Task assigned to ' +
                                _profile.customerName : 'Logged ' +
                                dataItem.type + ' by ' + dataItem.createdBy
                                }}
                              </p>
                            </ng-container>
                            <ng-container *ngIf="_viewname === 'deal'">
                              <p class="fw-normal fw-bold" ngbPopover="{{ dataItem.details }}">
                                {{
                                dataItem.type === 'Task' ? 'Task created by ' +
                                dataItem.createdBy : 'Logged ' +
                                dataItem.type + ' by ' + dataItem.createdBy
                                }}
                              </p>
                            </ng-container>

                            <p class="fw-normal" ngbPopover="{{ dataItem.details }}">
                              {{
                              dataItem.details.length > 80 ? (dataItem.details | slice:0:80) +
                              '...' :
                              dataItem.details
                              }}
                            </p>
                            <p class="tx-11 text-muted">{{dataItem.timeAgo }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mt-3">
                  <button class="btn btn-primary" (click)="goToTab(2); fetchNotes();">
                    <i class="fas fa-sticky-note me-2"></i> View All
                  </button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- left wrapper end -->
      <!-- middle wrapper start -->
      <div class="col-md-9 col-xl-9" style=" --bs-gutter-x: 0; ">
        <div class="row">
          <div class="col-md-12">
            <div class="example">
              <ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs  nav-fill">
                <li [ngbNavItem]="1">
                  <a ngbNavLink (click)="fetchLatestActivities(); getDealsList();" class="container">
                    <lord-icon src="https://cdn.lordicon.com/wxnxiano.json" trigger="hover"
                      colors="primary:#1ac0a1,secondary:#121331" style="width:23px;height:23px">
                    </lord-icon>
                    <span class="text">Overview</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="card rounded" style="background-color:#1ac0a24a;">
                      <div class="card-header">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="info-row">
                              <span class="label bold-label">Create Date</span>
                              <ng-container *ngIf="_viewname === 'contact' || _viewname === 'company'">
                                <span class="data" id="_creationDate">
                                  {{
                                  _profile.creationDate | date:'MMM d, yyyy\'at\' h:mm a \'GMT\'Z'
                                  }}
                                </span>
                              </ng-container>
                              <ng-container *ngIf="_viewname === 'deal'">
                                <span class="data" id="_creationDate">
                                  {{
                                  _dealDetailDTO.creationDate
                                  | date:'MMM d, yyyy \'at\'
                                  h:mm a \'GMT\'Z'
                                  }}
                                </span> <!--'dd-MM-yyyy'-->
                              </ng-container>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="info-row">
                              <span class="label bold-label">Last Activity Date</span>
                              <span class="data" id="_lastActivityDate">
                                {{
                                _lastActivityDate | date:'MMM d, yyyy \'at\' h:mm a \'GMT\'Z' ||
                                ' - '
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="info-row">
                              <span class="label bold-label"> Created By</span>
                              <ng-container *ngIf="_viewname === 'contact' || _viewname === 'company'">
                                <span class="data" id="_createdBy"> {{_profile.createdBy}} </span>
                              </ng-container>
                              <ng-container *ngIf="_viewname === 'deal'">
                                <span class="data" id="_createdBy">
                                  {{_dealDetailDTO.createdBy}}
                                </span>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="card bg-light mb-3">
                          <div class="card-header">
                            Tasks
                          </div>
                          <div class="card-body nopadding">
                            <div *ngIf="_overviewTaskList.length === 0">
                              <p class="card-body" style="height: 90px">
                                No Recent Tasks have been
                                logged.
                              </p>
                            </div>
                            <div *ngIf="_overviewTaskList.length > 0">
                              <div *ngFor="let dataItem of _overviewTaskList.slice(0, 5); let i = index">

                                <div class="card rounded">
                                  <div class="card-header">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                        <div class="title-icon img-xs rounded-circle">
                                          {{
                                          dataItem.details.charAt(0)
                                          }}
                                        </div>
                                        <div class="ms-2">

                                          <ng-container *ngIf="_viewname !== 'deal'">
                                            <p class="fw-normal fw-bold" popoverTitle="{{ dataItem.title }}"
                                              ngbPopover="{{ dataItem.details }}">
                                              {{
                                              dataItem.type === 'Task' ? 'Task assigned to ' +
                                              _profile.customerName
                                              : 'Logged ' + dataItem.type + ' by ' +
                                              dataItem.createdBy
                                              }}
                                            </p>
                                          </ng-container>
                                          <ng-container *ngIf="_viewname === 'deal'">
                                            <p class="fw-normal fw-bold" popoverTitle="{{ dataItem.title }}"
                                              ngbPopover="{{ dataItem.details }}">
                                              {{
                                              dataItem.type === 'Task' ? 'Task created by ' +
                                              dataItem.createdBy :
                                              'Logged ' + dataItem.type + ' by ' +
                                              dataItem.createdBy
                                              }}
                                            </p>
                                          </ng-container>

                                          <p class="fw-normal" ngbPopover="{{ dataItem.details }}">
                                            {{
                                            dataItem.details.length > 30 ? (dataItem.details |
                                            slice:0:30) + '...' :
                                            dataItem.details
                                            }}
                                          </p>
                                          <p class="tx-11 text-muted">{{dataItem.timeAgo }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-3">
                          <button class="btn btn-primary" (click)="goToTab(5); fetchTasks()">
                            <i class="fas fa-tasks me-2"></i> View All
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="card bg-light mb-3">

                          <div class="card-header">
                            Recent
                            Communication
                          </div>
                          <div class="card-body nopadding">
                            <div *ngIf="_overviewRecentCommunicationList.length === 0">
                              <p class="card-body" style="height: 90px">
                                No Recent Communication has
                                been logged.
                              </p>
                            </div>
                            <div *ngFor="let dataItem of _overviewRecentCommunicationList.slice(0, 5); let i = index">

                              <div class="card rounded">
                                <div class="card-header">
                                  <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                      <div class="title-icon img-xs rounded-circle">
                                        {{
                                        dataItem.details.charAt(0)
                                        }}
                                      </div>
                                      <div class="ms-2">
                                        <ng-container *ngIf="_viewname !== 'deal'">
                                          <p class="fw-normal fw-bold" ngbPopover="{{ dataItem.details }}">
                                            {{
                                            dataItem.type === 'Task' ? 'Task assigned to ' +
                                            _profile.customerName :
                                            'Logged ' + dataItem.type + ' by ' +
                                            dataItem.createdBy
                                            }}
                                          </p>
                                        </ng-container>

                                        <ng-container *ngIf="_viewname === 'deal'">
                                          <p class="fw-normal fw-bold" ngbPopover="{{ dataItem.details }}">
                                            {{
                                            'Logged ' + dataItem.type + ' by ' +
                                            dataItem.createdBy
                                            }}
                                          </p>
                                        </ng-container>
                                        <p class="fw-normal" ngbPopover="{{ dataItem.details }}">
                                          {{
                                          dataItem.details.length > 30 ? (dataItem.details |
                                          slice:0:30) + '...' :
                                          dataItem.details
                                          }}
                                        </p>
                                        <p class="tx-11 text-muted">{{dataItem.timeAgo }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>

                          </div>

                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="card bg-light mb-3" *ngIf="_viewname !== 'deal'">
                          <div class="card-header">
                            Associated Sales, Leads, Moves
                          </div>
                          <div class="card-body nopadding ">
                            <div *ngIf="_dealsList.length === 0 && _leadDetailsList.length === 0">
                              <p class="card-body" style="height: 90px">
                                No Recent Sales/Leads/Moves have been
                                logged.
                              </p>
                            </div>
                            <!--DealsfilterOverviewList()-->
                            <div *ngIf="_dealsList.length > 0">
                              <div *ngFor="let dataItem of _dealsList | slice:0:3">
                                <div class="card rounded">
                                  <div class="card-header">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                        <a>
                                          <div (click)="dealClick(dataItem.id)"
                                            class="title-icon img-xs rounded-circle">
                                            {{
                                            dataItem.name.charAt(0)
                                            }}
                                          </div>
                                        </a>
                                        <div class="ms-2">
                                          <ng-container *ngIf="_viewname !== 'deal'">
                                            <a class="clickable-link" (click)="dealClick(dataItem.id)">
                                              <strong>
                                                {{ dataItem.name }} - {{dataItem.pipeName}}
                                              </strong>
                                            </a>
                                            <p class="tx-11 text-muted">
                                              {{
                                              dataItem.creationDate |
                                              date:'MMM d, yyyy
                                              \'at\' h:mm a'
                                              }}
                                            </p>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div *ngIf="_leadDetailsList.length === 0">
                              <p class="card-body" style="overflow-y: auto">No Recent Leads.</p>
                            </div> -->

                            <div *ngIf="_leadDetailsList && _leadDetailsList.length > 0">
                              <div *ngFor="let dataItem of _leadDetailsList | slice:0:3">
                                <div class="card rounded">
                                  <div class="card-header">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                        <a>
                                          <div (click)="navigateToLeadDetail(dataItem)"
                                            class="title-icon img-xs rounded-circle">
                                            {{ dataItem.leadDetailId }}
                                          </div>
                                        </a>
                                        <div class="ms-2">
                                          <ng-container *ngIf="_viewname !== 'deal'">
                                            <a class="clickable-link" (click)="navigateToLeadDetail(dataItem)">
                                              <strong> Lead
                                                {{dataItem.formattedLeadDetailId}} -
                                                {{dataItem.status}}</strong>
                                            </a>
                                            <p class="tx-11 text-muted">
                                              {{
                                              dataItem.creationDate |
                                              date:'MMM d, yyyy \'at\'h:mm a'
                                              }}
                                            </p>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div *ngIf="_moveDetailsList && _moveDetailsList.length > 0">
                              <div *ngFor="let dataItem of _moveDetailsList | slice:0:3">
                                <div class="card rounded">
                                  <div class="card-header">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                        <a>
                                          <div (click)="navigateToMoveDetail(dataItem)"
                                            class="title-icon img-xs rounded-circle">
                                            {{ dataItem.moveDetailId }}
                                          </div>
                                        </a>
                                        <div class="ms-2">
                                          <ng-container *ngIf="_viewname !== 'deal'">
                                            <a class="clickable-link" (click)="navigateToMoveDetail(dataItem)">
                                              <strong> Move
                                                {{dataItem.formattedMoveDetailId}} -
                                                {{dataItem.status}}</strong>
                                            </a>
                                            <p class="tx-11 text-muted">
                                              {{
                                              dataItem.creationDate |
                                              date:'MMM d, yyyy \'at\'h:mm a'
                                              }}
                                            </p>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>



                          </div>

                          <!-- <div class="card  mb-3" *ngIf="_viewname !== 'deal'">
                            <div class="card-header" style="background-color: #1ac0a24a">
                              Associated Leads
                            </div>
                            <div class="card-body nopadding ">

                            </div>
                          </div> -->


                        </div>
                        <!-- <div class="text-center mt-3"
                          *ngIf="_viewname !== 'deal' && _dealsList.length > 0">
                          <button class="btn btn-primary" routerLink="../sales">
                            <i class="fas fa-handshake me-2"></i> View All
                          </button>
                        </div> -->
                        &nbsp;
                        <!-- <div class="card bg-light mb-3" *ngIf="_viewname !== 'deal'">
                          <div class="card-header" style="background-color: #1ac0a24a">
                            Associated Leads
                          </div>
                          <div class="card-body nopadding ">
                            <div *ngIf="_leadDetailsList.length === 0">
                              <p class="card-body" style="height: 90px">No Recent Leads.</p>
                            </div>

                            <div *ngIf="_leadDetailsList && _leadDetailsList.length > 0">
                              <div *ngFor="let dataItem of _leadDetailsList | slice:0:3">
                                <div class="card rounded">
                                  <div class="card-header">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <div class="d-flex align-items-center">
                                        <a>
                                          <div (click)="navigateToLeadDetail(dataItem)"
                                            class="title-icon img-xs rounded-circle">
                                            {{ dataItem.leadDetailId }}
                                          </div>
                                        </a>
                                        <div class="ms-2">
                                          <ng-container *ngIf="_viewname !== 'deal'">
                                            <a class="clickable-link"
                                              (click)="navigateToLeadDetail(dataItem)">
                                              <strong> Lead #{{ dataItem.leadDetailId }} -
                                                {{dataItem.status}}</strong>
                                            </a>
                                            <p class="tx-11 text-muted">
                                              {{
                                              dataItem.creationDate |
                                              date:'MMM d, yyyy \'at\'h:mm a'
                                              }}
                                            </p>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="text-center mt-3"
                          *ngIf="_viewname !== 'deal' && _leadDetailsList.length > 0">
                          <button class="btn btn-primary" routerLink="../leads-board">
                            <i class="fas fa-handshake me-2"></i> View All
                          </button>
                        </div> -->

                      </div>
                    </div>

                  </ng-template>
                </li>

                <li [ngbNavItem]="2">
                  <a ngbNavLink (click)="fetchNotes();" class="container">
                    <lord-icon src="https://cdn.lordicon.com/nocovwne.json" trigger="hover"
                      colors="primary:#121331,secondary:#1ac0a1" state="hover-2" style="width:23px;height:23px">
                    </lord-icon>
                    <span class="text">Notes</span>
                  </a>
                  <ng-template ngbNavContent>
                    <br>
                    <ng-container *ngIf="_createPermission">
                      <div class="d-flex justify-content-end">
                        <button class="btn btn-primary btn-xs btn-icon-text" (click)="openNotesModel(notesModal);">
                          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                          </lord-icon>
                          &nbsp; Create Note &nbsp;
                        </button>
                      </div>
                    </ng-container>
                    &nbsp;
                    <div *ngIf="_notesList.length === 0" class="text-center">
                      <p>Take notes about this record to keep track of important info.</p>
                    </div>

                    <div *ngFor="let dataItem of _notesList; let i = index">
                      <div class="card rounded">
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="title-icon img-xs rounded-circle">
                                {{
                                dataItem.details.charAt(0)
                                }}
                              </div>

                              <div class="ms-2">
                                <p class="fw-bold">{{dataItem.title}}</p>
                                <ng-template #popoverContent>
                                  <p><strong>Details:</strong> {{dataItem.details}}</p>
                                </ng-template>
                                <p popoverTitle='Notes' [ngbPopover]="popoverContent">
                                  {{
                                  dataItem.details.length > 80 ? (dataItem.details | slice:0:80) +
                                  '...' :
                                  dataItem.details
                                  }}
                                </p>
                                <p class="tx-11 text-muted">
                                  {{
                                  dataItem.activityDate | date:'MMM d,
                                  yyyy \'at\' h:mm a'
                                  }}
                                </p>
                              </div>

                            </div>
                            <div ngbDropdown>
                              <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                                ngbDropdownToggle>
                                <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                                <a ngbDropdownItem class="d-flex align-items-center"
                                  (click)="openNotesModel(notesModal,dataItem.id);editNote(dataItem);false">
                                  <i class="feather icon-edit"></i> &nbsp;<span class="">Edit</span>
                                </a>
                                <a ngbDropdownItem class="d-flex align-items-center" (click)="deleteNote(dataItem.id)">
                                  <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink (click)="fetchEmails()" class="container">
                    <lord-icon src="https://cdn.lordicon.com/rhvddzym.json" trigger="hover"
                      colors="primary:#121331,secondary:#6571ff" style="width:25px;height:25px"></lord-icon>
                    <span class="text">Emails</span>
                  </a>
                  <ng-template ngbNavContent>
                    <br>

                    <div class="d-flex justify-content-end">
                      <ng-container *ngIf="_createPermission">
                        <button class="btn btn-primary btn-xs btn-icon-text" (click)="openEmailsModel(emailsModal);">
                          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                          </lord-icon>
                          &nbsp;Log Email &nbsp;
                        </button>
                      </ng-container>
                    </div>
                    &nbsp;&nbsp;

                    <div *ngIf="_emailsList.length === 0" class="text-center">
                      <p>Log an email activity to keep track of your emails.</p>
                    </div>

                    <div *ngFor="let dataItem of _emailsList; let i = index">
                      <div class="card rounded">
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="title-icon img-xs rounded-circle">
                                {{
                                dataItem.details.charAt(0)
                                }}
                              </div>
                              <div class="ms-2">
                                <p popoverTitle="Email" [ngbPopover]="customPopoverContent">
                                  <ng-template #customPopoverContent>
                                    <div>
                                      <p><strong>From: </strong> {{ dataItem.from }}</p>
                                      <p><strong>To: </strong> {{ dataItem.to }}</p>

                                      <p><strong>Detail: </strong>{{ dataItem.details }}</p>
                                    </div>
                                  </ng-template>
                                  {{
                                  dataItem.details.length > 80 ? (dataItem.details | slice:0:80)
                                  + '...' :
                                  dataItem.details
                                  }}
                                </p>
                                <p class="tx-11 text-muted ">
                                  {{
                                  dataItem.activityDate | date:'MMM d,
                                  yyyy \'at\' h:mm a'
                                  }}
                                </p>
                                <p>
                                  <strong>From: </strong> {{ dataItem.from }}&nbsp;
                                  <strong>To: </strong> {{ dataItem.to }}
                                </p>
                              </div>
                            </div>
                            <div ngbDropdown>
                              <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                                ngbDropdownToggle>
                                <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                                <a ngbDropdownItem class="d-flex align-items-center"
                                  (click)="openEmailsModel(emailsModal, dataItem.id); editEmail(dataItem); false">
                                  <i class="feather icon-edit"></i> &nbsp;<span class="">
                                    {{
                                    _updatePermission ? 'Edit' : 'Preview'
                                    }}
                                  </span>
                                </a>
                                <ng-container *ngIf="_deletePermission">
                                  <a ngbDropdownItem class="d-flex align-items-center"
                                    (click)="deleteEmail(dataItem.id)">
                                    <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                                  </a>
                                </ng-container>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="4">
                  <a ngbNavLink (click)="fetchCalls()" class="container">
                    <lord-icon src="https://cdn.lordicon.com/ssvybplt.json" trigger="hover" colors="primary:#5f606c"
                      style="width:23px;height:23px">
                    </lord-icon>
                    <span class="text">Calls</span>
                  </a>
                  <ng-template ngbNavContent>
                    <br>
                    <div class="d-flex justify-content-end">
                      <ng-container *ngIf="_createPermission">
                        <button class="btn btn-primary btn-xs btn-icon-text" (click)="openCallsModel(callsModal);">
                          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                          </lord-icon>
                          &nbsp;Log Call
                        </button>
                      </ng-container>
                    </div>
                    &nbsp;
                    <div *ngIf="_callsList.length === 0" class="text-center">
                      <p>Log a call activity to keep track of your discussion and notes.</p>
                    </div>
                    <div *ngFor="let dataItem of _callsList; let i = index">
                      <div class="card rounded">
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="title-icon img-xs rounded-circle">
                                {{
                                dataItem.details.charAt(0)
                                }}
                              </div>
                              <div class="ms-2">
                                <ng-template #popoverContent>
                                  <p><strong>Call Direction:</strong> {{dataItem.direction}}</p>
                                  <p><strong>Call Outcome:</strong> {{dataItem.outcome}}</p>
                                  <p><strong>Details:</strong> {{dataItem.details}}</p>
                                  <p>
                                    <strong>Date:</strong>
                                    {{dataItem.activityDate | date:'MMM d, yyyy'}} at
                                    {{dataItem.activityDate | date:'h:mm a'}}
                                  </p>

                                </ng-template>
                                <p popoverTitle="Call" [ngbPopover]="popoverContent">
                                  {{
                                  dataItem.details.length > 80 ? (dataItem.details | slice:0:80) +
                                  '...' :
                                  dataItem.details
                                  }}
                                </p>
                                <p class="tx-11 text-muted">
                                  {{dataItem.activityDate | date:'MMM d,yyyy \'at\' h:mm a'}}
                                </p>

                                <p>
                                  <strong>Call Direction:</strong> {{dataItem.direction}} &nbsp;
                                  <strong>Call Outcome:</strong> {{dataItem.outcome}}
                                </p>
                              </div>
                            </div>
                            <div ngbDropdown>
                              <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                                ngbDropdownToggle>
                                <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                                <a ngbDropdownItem class="d-flex align-items-center"
                                  (click)="openCallsModel(callsModal, dataItem.id); editCall(dataItem);false">
                                  <i class="feather icon-edit"></i> &nbsp;<span class="">
                                    {{_updatePermission ? 'Edit' : 'Preview'}}
                                  </span>
                                </a>
                                <a ngbDropdownItem class="d-flex align-items-center" (click)="deleteCall(dataItem.id)">
                                  <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink (click)="fetchTasks()" class="container">
                    <lord-icon src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover" colors="primary:#5f606c"
                      style="width:23px;height:23px"></lord-icon>
                    <span class="text">Tasks</span>
                  </a>
                  <ng-template ngbNavContent>
                    <!-- Main Tabs -->
                    <ul ngbNav #subTabs="ngbNav" class="nav-tabs sub-tabs" role="tablist">
                      <li [ngbNavItem]="1">

                        <ng-template ngbNavContent>
                          <app-task [customerId]="_idForTask" [isDeal]="_isDeal" type="profile"></app-task>
                        </ng-template>
                      </li>

                    </ul>
                    <div [ngbNavOutlet]="subTabs"></div>
                    <br>
                  </ng-template>
                </li>


                <li [ngbNavItem]="6">
                  <a ngbNavLink (click)="fetchMeetings()" class="container">
                    <lord-icon src="https://cdn.lordicon.com/zpxybbhl.json" trigger="hover"
                      colors="primary:#121331,secondary:#1ac0a1" style="width:25px;height:25px"></lord-icon>

                    <span class="text">Meetings</span>
                  </a>
                  <ng-template ngbNavContent>
                    <br>
                    <div class="d-flex justify-content-end">
                      <ng-container *ngIf="_createPermission">
                        <button class="btn btn-primary btn-xs btn-icon-text"
                          (click)="openMeetingsModel(meetingsModal);">
                          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                            colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
                          </lord-icon>
                          &nbsp;Log Meeting &nbsp;
                        </button>
                      </ng-container>
                    </div>
                    &nbsp;
                    <div *ngIf="_meetingsList.length === 0" class="text-center">
                      <p>Log a meeting activity to keep track of your meeting and notes.</p>
                    </div>
                    <div *ngFor="let dataItem of _meetingsList; let i = index">
                      <div class="card rounded">
                        <div class="card-header">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="title-icon img-xs rounded-circle">
                                {{
                                dataItem.details.charAt(0)
                                }}
                              </div>
                              <div class="ms-2">
                                <p class="fw-bold">{{dataItem.detail}}</p>
                                <ng-template #popoverContent>
                                  <p><strong>Subject:</strong> {{dataItem.title}}</p>
                                  <p><strong>Detail:</strong> {{dataItem.details}}</p>

                                  <p>
                                    <strong>Date:</strong>
                                    {{dataItem.activityDate | date:'MMM d, yyyy'}} at
                                    {{dataItem.activityDate | date:'h:mm a'}}
                                  </p>

                                </ng-template>
                                <p popoverTitle="Meeting" [ngbPopover]="popoverContent">
                                  {{
                                  dataItem.title.length > 60 ? (dataItem.title | slice:0:60) +
                                  '...' :
                                  dataItem.title
                                  }}
                                </p>
                                <p popoverTitle="Meeting" [ngbPopover]="popoverContent">
                                  {{
                                  dataItem.details.length > 80 ? (dataItem.details | slice:0:80) +
                                  '...' :
                                  dataItem.details
                                  }}
                                </p>
                                <p class="tx-11 text-muted">
                                  {{
                                  dataItem.activityDate | date:'MMM d,
                                  yyyy \'at\' h:mm a'
                                  }}
                                </p>
                              </div>

                            </div>
                            <div ngbDropdown>
                              <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                                ngbDropdownToggle>
                                <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                              </button>
                              <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">

                                <a ngbDropdownItem class="d-flex align-items-center"
                                  (click)="openMeetingsModel(meetingsModal, dataItem.id); editMeeting(dataItem); false">
                                  <i class="feather icon-edit"></i> &nbsp;<span class="">
                                    {{_updatePermission ? 'Edit' : 'Preview'}}
                                  </span>
                                </a>
                                <a ngbDropdownItem class="d-flex align-items-center"
                                  (click)="deleteMeeting(dataItem.id)">
                                  <i class="feather icon-trash"></i> &nbsp;<span class="">Delete</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                </li>
              </ul>
              <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="col-md-3">
  <div class="info-row">
    <span class="label bold-label">Lead Status</span>
    <span class="data" id="_saleStage"> - </span>
  </div>
</div> -->