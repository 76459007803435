import { eMoveCategoryType, eTransportType } from "./lead-detail.model";

export class Status {
    public id: number;
    public title: string;
    public order: number;
    public category: eMoveCategoryType;
    public transportType: eTransportType;
    public statusType: eStatusType;
    public ownedBy: number;
    public createdBy: number;
    public isDeleted: boolean;
    public isActive: boolean | null;
    public required: boolean;

    constructor() {
        this.id = 0;
        this.title = '';
        this.order = 0;
        this.category = eMoveCategoryType.DoorToDoorLocal; // You can define an appropriate default value
        this.transportType = eTransportType.Land; // You can define an appropriate default value
        this.statusType = eStatusType.Lead;
        this.ownedBy = Number(localStorage.getItem('companyId')) || 0;
        this.createdBy = 0;
        this.isDeleted = false;
        this.isActive = true;
        this.required = false;
    }
}

export enum eStatusType {
    Lead = 0,
    Move = 1,
    ClientJourney = 2,
}