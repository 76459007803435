export class UserProfileDTO {
    id: number;
    customerId: number;
    companyName: string;
    userName: string;
    email: string | null;
    password: string | null;
    roleId: number | null;
    roleName: string | null;
    phone: string | null;
    industryName: string | null;
    industryId: number | null;
    isDeleted: boolean | null;
    twoFactorEnabled: boolean;
    addressId: number | null;
    zipCode: string | null;
    streetAddress: string | null;
    city: string | null;
    country: string | null;
    role: string | null;
    signature: string | null;
    profileImage: string | null;

    constructor() {
        this.id = 0;
        this.customerId = 0;
        this.companyName = '';
        this.userName = '';
        this.email = null;
        this.password = null;
        this.roleId = null;
        this.roleName = null;
        this.phone = null;
        this.industryName = null;
        this.industryId = null;
        this.isDeleted = null;
        this.twoFactorEnabled = false;
        this.addressId = null;
        this.zipCode = null;
        this.streetAddress = null;
        this.city = null;
        this.country = null;
        this.role = null;
        this.signature = null;
        this.profileImage = null;
    }
}
