import { eAttachmentType } from "./move-attachments.model";

export class RequiredDocument {
    id: number;
    title: string;      // here it is nullable but db doesnot accpet null title
    all: boolean | null;
    doorToDoorLocal: boolean | null;
    doorToPort: boolean | null;
    portToDoor: boolean | null;
    doorToDoorImport: boolean | null;
    doorToDoorExport: boolean | null;
    ownedBy: number;
    type: eAttachmentType
    createdBy: number | null;
    creationDate: Date;
    modificationDate: Date | null;
    modifiedBy: number | null;
    deletionDate: Date | null;
    isDeleted: boolean;
    isActive: boolean | null;
    isSelected: boolean; //just for frontend use

    constructor() {
        this.id = 0;
        this.title = '';
        this.all = null;
        this.doorToDoorLocal = null;
        this.doorToPort = null;
        this.portToDoor = null;
        this.doorToDoorImport = null;
        this.doorToDoorExport = null;
        this.ownedBy = 0;
        this.createdBy = null;
        this.creationDate = new Date();
        this.modificationDate = null;
        this.modifiedBy = null;
        this.deletionDate = null;
        this.isDeleted = false;
        this.isActive = true;
        this.type = eAttachmentType.ClientDocuments;
        this.isSelected = false;
    }
}
