<div class="row">
  <div class="col-12 mt-2">
    <div class="row">
      <div class="col-md-4">
        <ng-select [(ngModel)]="_moveDetail.moveType" [items]="_moveType" bindLabel="value" bindValue="id"
          [searchable]="true" placeholder="Select Move Type" [disabled]="_contentDisable">
        </ng-select>
      </div>
      <div class="col-md-4">
        <ng-select [(ngModel)]="_moveDetail.categoryType" [items]="_moveCategory" bindLabel="value" bindValue="id"
          [searchable]="true" placeholder="Select Move Category" (click)="onChangeCategory()"
          [disabled]="_contentDisable">
        </ng-select>
      </div>
      <div class="col-md-4">
        <ng-select [(ngModel)]="_moveDetail.transportType" [items]="_transportType" bindLabel="value" bindValue="id"
          [searchable]="true" placeholder="Select Transport Type" (click)="onChangeTransport()"
          [disabled]="_contentDisable">
        </ng-select>
      </div>
    </div>

    <!-- Origin & Destination -->
    <div class="mt-4">
      <a class="mt-3 d-block" (click)="toggleSections()">
        <div class="d-flex justify-content-between align-items-center address-hover">
          <h5 class="mr-2 ">Addresses</h5>
          <span class="mr-2 arrow-icon">
            <i [ngClass]="showSections ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
          </span>
        </div>
        <hr class="mt-1 border-dark-grey">
      </a>
    </div>
    <div class="row mt-4">
      <div class="col-md-5" [ngClass]="{ 'd-none': !showSections }">
        <h6 class="text-center custom-text-color">ORIGIN</h6>
        &nbsp;
        <hr class="flex-grow-1 m-3 border-dark-grey" />

        <!-- Origin Form detail  -->
        <ul class="nav nav-tabs">
          <li *ngFor="
          let address of _originAddresses;
          let i = index" class="nav-item">
            <a class="nav-link" [class.active]="i === originActiveTabIndex" (click)="setOriginActiveTab(i)">
              Address {{ i + 1 }}
              <ng-container *ngIf="i !== 0">
                <button class="btn-close btn-close-tab" aria-label="Close" (click)="removeOriginTab(i)"></button>
              </ng-container>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          &nbsp;
          <div *ngFor="let address of _originAddresses; let i = index" class="tab-pane"
            [class.active]="i === originActiveTabIndex">
            <label for="originAddress" class="form-label fw-bold m-0">
              Address
            </label>
            <input type="text" id="originAddress" class="form-control" placeholder="Appt#, Street"
              [(ngModel)]="address.streetAddress" [disabled]="_contentDisable" />

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="originCity" class="form-label fw-bold m-0">
                  City <span class="required-input"> * </span>
                </label>
                <input type="text" id="originCity" class="form-control" placeholder="City" [(ngModel)]="address.city"
                  [disabled]="_contentDisable" />
                <label for="originCountry" class="form-label fw-bold m-0 mt-3">
                  Country <span class="required-input"> * </span>
                </label>

                <ng-select [items]="countries" bindLabel="value" bindValue="id" placeholder="Select Country"
                  [(ngModel)]="address.country" [disabled]="_contentDisable"></ng-select>
              </div>
              <div class="col-md-6">
                <label for="originProvince" class="form-label fw-bold m-0">
                  Province
                </label>
                <input type="text" id="originProvince" class="form-control" placeholder="Province"
                  [(ngModel)]="address.state" [disabled]="_contentDisable" />

                <label for="originZip" class="form-label fw-bold m-0 mt-3">
                  Zip Code
                </label>
                <input type="text" id="originZip" class="form-control" placeholder="Zip Code"
                  [(ngModel)]="address.zipCode" [disabled]="_contentDisable" />
              </div>
            </div>
          </div>
        </div>
        &nbsp;
        <ngb-alert *ngIf="originShowAlert" type="danger" (click)="closeOriginAlert()">
          You cannot add more than {{ maxAddresses }} origin
          addresses.
        </ngb-alert>
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-xs btn-primary btn-icon-text mt-4" (click)="addOriginTab()"
            [disabled]="_contentDisable">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
            </lord-icon>
            &nbsp; Add Another Origin
          </button>
        </ng-container>
        &nbsp;
      </div>
      <div class="col-md-2 p-0 dot-line" [ngClass]="{ 'd-none': !showSections }">
        <label class="dots-container">
          <label class="dot"></label>
          <label class="dot"></label>
          <label class="dot"></label>
          <label class="dot"></label>
          <label class="dot"></label>

        </label>
        <label class="vertical-line text-center"></label>
      </div>

      <div class="col-md-5" [ngClass]="{ 'd-none': !showSections }">
        <h6 class="text-center custom-text-color">
          DESTINATION
        </h6>
        &nbsp;
        <hr class="flex-grow- m-3 border-dark-grey" />

        <ul class="nav nav-tabs">
          <li *ngFor="
          let address of _destinationAddresses;
          let i = index
        " class="nav-item">
            <a class="nav-link" [class.active]="i === destActiveTabIndex" (click)="setDestActiveTab(i)">
              Address {{ i + 1 }}
              <ng-container *ngIf="i !== 0">
                <button class="btn-close btn-close-tab" aria-label="Close" (click)="removeDestTab(i)"></button>
              </ng-container>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          &nbsp;
          <div *ngFor="let address of _destinationAddresses; let i = index" class="tab-pane"
            [class.active]="i === destActiveTabIndex">
            <label for="destinationAddress" class="form-label fw-bold m-0">
              Address
            </label>
            <input type="text" id="destinationAddress" class="form-control" placeholder="Appt#, Street"
              [(ngModel)]="address.streetAddress" [disabled]="_contentDisable" />

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="destinationCity" class="form-label fw-bold m-0">
                  City <span class="required-input"> * </span>
                </label>
                <input type="text" id="destinationCity" class="form-control" placeholder="City"
                  [(ngModel)]="address.city" [disabled]="_contentDisable" />
                <div *ngIf="destTabErrors" class="text-danger">
                  City field is required.
                </div>
                <label for="destinationCity" class="form-label fw-bold m-0 mt-3">
                  Country <span class="required-input"> * </span>
                </label>
                <ng-select [items]="countries" bindLabel="value" bindValue="id" placeholder="Select Country"
                  [(ngModel)]="address.country" [searchable]="true" [disabled]="_contentDisable"></ng-select>
              </div>
              <div class="col-md-6">
                <label for="destinationProvince" class="form-label fw-bold m-0">
                  Province
                </label>
                <input type="text" id="destinationProvince" class="form-control" placeholder="Province"
                  [(ngModel)]="address.state" [disabled]="_contentDisable" />

                <label for="destinationZip" class="form-label fw-bold m-0 mt-3">
                  Zip Code
                </label>
                <input type="text" id="destinationZip" class="form-control" placeholder="Zip Code"
                  [(ngModel)]="address.zipCode" [disabled]="_contentDisable" />
              </div>
            </div>
          </div>
        </div>
        &nbsp;
        <ngb-alert *ngIf="destShowAlert" type="danger" (click)="closeDestAlert()">
          You cannot add more than
          {{ maxAddresses }} destination addresses.
        </ngb-alert>
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-xs btn-primary btn-icon-text mt-4" (click)="addDestTab()" [disabled]="_contentDisable">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
            </lord-icon>
            &nbsp; Add Another Destination
          </button>
        </ng-container>
        &nbsp;
      </div>
      <div class="mt-3">
        <a class="mt-3 d-block" (click)="shipmentSection()">
          <div class="d-flex justify-content-between align-items-center address-hover">
            <h5 class="mr-2">Shipment information</h5>
            <span class="mr-2 arrow-icon">
              <i [ngClass]="shipmentSections ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
            </span>
          </div>
          <hr class="mt-1 border-dark-grey">
        </a>
      </div>
      <!-- <h5 *ngIf="shipmentSections">SHIPMENT INFORMATION</h5> -->
      <!-- <h5>SHIPMENT INFORMATION</h5> -->
      <div class="row mt-2">
        <ng-container
          *ngIf="_moveDetail.categoryType == eMoveCategoryType.DoorToDoorExport || _moveDetail.categoryType == eMoveCategoryType.DoorToPort  && _moveDetail.transportType == eTransportType.Sea || _moveDetail.transportType == eTransportType.SeaLCL || _moveDetail.transportType == eTransportType.Air">
          <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
            <label class="form-label fw-bold m-0 mt-3">
              {{_portLabel}} Port of Loading
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.portOfLoading"
              placeholder="Enter Port of Loading" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
            <label class="form-label fw-bold m-0 mt-3">
              {{_portLabel}} Port of Discharge
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.portOfDischarge"
              placeholder="Enter Port of Discharge" [disabled]="_contentDisable" />
          </div>
        </ng-container>
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            Place of Delivery
          </label>
          <input type="text" class="form-control" [(ngModel)]="_moveDetail.placeOfDelivery"
            placeholder="Place of Delivery" [disabled]="_contentDisable" />
        </div>
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            Insurance Required
          </label>
          <div class="form-check">
            <label class="form-check-label" for="insurance-yes">Yes</label>
            <input type="radio" class="form-check-input" [(ngModel)]="_moveDetail.insuranceRequired" id="insurance-yes"
              name="insurance" [value]="true" [disabled]="_contentDisable">
          </div>
          <div class="form-check">
            <label class="form-check-label" for="insurance-no">No</label>
            <input type="radio" class="form-check-input" [(ngModel)]="_moveDetail.insuranceRequired" id="insurance-no"
              name="insurance" [value]="false" [disabled]="_contentDisable">
          </div>
        </div>
      </div>

      <hr [ngClass]="{ 'd-none': !shipmentSections }" class="mt-4 border-dark-grey" />
      <div class="row" *ngIf="_moveDetail.categoryType !== eMoveCategoryType.PortToDoor">
        <!-- Survey Date  -->
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Survey Date
          </label>
          <div class="input-group">
            <div class="position-relative">
              <input class="form-control clickable" name="dp" ngbDatepicker #d="ngbDatepicker"
                [value]="generalService.formatDate(_moveDetail.surveyDate, null)" (click)="d.toggle()"
                (dateSelect)="onDateSelect($event, 'SurveyDate'); d.close()" outsideDays="hidden"
                placeholder="Select Date" [dayTemplate]="t" [startDate]="surveyDateShow!" [disabled]="_contentDisable"
                readonly />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.surveyDate && !_contentDisable" (click)="_moveDetail.surveyDate = null"
                [disabled]="_contentDisable">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" title="date-range" (click)="d.toggle()">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>

            <input type="time" class="form-control input-color" [(ngModel)]="_surveyTime" id="surveyTime"
              [disabled]="_contentDisable" placeholder="Select time" required />

            <ng-template #t let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'survey')">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <input type="checkbox" class="form-check-input mt-2" title="storage-needed" [(ngModel)]="storageNeeded"
            [disabled]="_contentDisable" />
          <label class="m-2 text-muted small">Warehouse storage needed</label>
        </div>
        <!-- Packing date  -->
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Packing Date
          </label>
          <div class="input-group range-selection">
            <div class="position-relative">
              <input type="text" class="form-control clickable" placeholder="Select Date Range"
                [value]="generalService.formatDate(_moveDetail.packagingDateFrom,_moveDetail.packagingDateTo)"
                name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event, 'packing')" [displayMonths]="2" [dayTemplate]="daysTemplate"
                outsideDays="hidden" (click)="datepicker.toggle()" [startDate]="fromDate!" tabindex="-1"
                [disabled]="_contentDisable" readonly />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.packagingDateFrom && !_contentDisable"
                (click)="_moveDetail.packagingDateFrom = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" title="icon-calendar" (click)="datepicker.toggle()">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
            <input type="time" class="form-control input-color" [(ngModel)]="_packingTime" id="packingTime"
              [disabled]="_contentDisable" placeholder="Select time" required />

            <ng-template #daysTemplate let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date, 'packing')"
                [class.faded]="isHovered(date, 'packing') || isInside(date, 'packing')"
                (mouseenter)="hoveredDatePacking = date" (mouseleave)="hoveredDatePacking = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <input type="checkbox" class="form-check-input mt-2" title="packing-date" [(ngModel)]="sameDayDelivery"
            (change)="onSameDayDeliveryChange()" [disabled]="_contentDisable" />
          <label class="m-2 text-muted small">Same Day Delivery</label>
        </div>
      </div>

      <!-- Loading Date -->
      <div class="row">
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Loading Date
          </label>
          <div class="input-group range-selection">
            <div class="position-relative">
              <input type="text" class="form-control clickable" placeholder="Select Date Range"
                [value]="generalService.formatDate(_moveDetail.loadingDateFrom,_moveDetail.loadingDateTo)"
                name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event, 'loading')" [displayMonths]="2" [dayTemplate]="daysTemplate1"
                outsideDays="hidden" (click)="datepicker.toggle()" [startDate]="fromDate1!" tabindex="-1"
                [disabled]="sameDayDelivery|| _contentDisable" readonly />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.loadingDateFrom && !_contentDisable" (click)="_moveDetail.loadingDateFrom = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" title="date-range" (click)="datepicker.toggle()"
              [disabled]="sameDayDelivery|| _contentDisable">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
            <input type="time" class="form-control input-color" [(ngModel)]="_loadingTime" id="surveyTime"
              [disabled]="_contentDisable || sameDayDelivery" placeholder="Select time" required />

            <ng-template #daysTemplate1 let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date, 'loading')" [class.faded]="
            isHovered(date, 'loading') ||
            isInside(date, 'loading')
          " (mouseenter)="hoveredDateLoading = date" (mouseleave)="hoveredDateLoading = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>

        <!-- Delivery Date -->
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Delivery Date
          </label>
          <div class="input-group">
            <div class="position-relative">
              <input class="form-control clickable" name="dp" ngbDatepicker #dt="ngbDatepicker" title="date-range"
                [value]="generalService.formatDate(_moveDetail.deliveryDate, null)" (click)="dt.open()"
                (dateSelect)="onDateSelect($event, 'DeliveryDate'); dt.close()" outsideDays="hidden"
                placeholder="Select Date" [dayTemplate]="d" [startDate]="deliveryDateShow!"
                [disabled]="sameDayDelivery || _contentDisable" />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.deliveryDate && !_contentDisable" (click)="_moveDetail.deliveryDate = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" (click)="dt.open()" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
            <input type="time" class="form-control input-color" [(ngModel)]="_deliveryTime" id="deliveryTime"
              [disabled]="_contentDisable || sameDayDelivery" placeholder="Select time" required />

            <ng-template #d let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'delivery')">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- Storage Date -->
      <hr [ngClass]="{ 'd-none': !shipmentSections }" class="mt-3 border-dark-grey" />
      <div class="row" *ngIf="storageNeeded">
        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Storage Date
          </label>
          <div class="input-group range-selection">
            <div class="position-relative">
              <input type="text" class="form-control clickable" placeholder="Select Date Range" [value]="
        generalService.formatDate(
            _moveDetail.storageDateFrom,
            _moveDetail.storageDateTo
          )
        " name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event, 'storage')" [displayMonths]="2" [dayTemplate]="daysTemplate2"
                outsideDays="hidden" (click)="datepicker.toggle()" [disabled]="_contentDisable" [startDate]="fromDate2!"
                tabindex="-1" readonly />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.storageDateFrom && !_contentDisable" (click)="_moveDetail.storageDateFrom = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" title="date-range" (click)="datepicker.toggle()">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>

            <input type="time" class="form-control input-color" [(ngModel)]="_storageTime" id="surveyTime"
              [disabled]="_contentDisable" placeholder="Select time" required />


            <ng-template #daysTemplate2 let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date, 'storage')" [class.faded]="
            isHovered(date, 'storage') ||
            isInside(date, 'storage')
          " (mouseenter)="hoveredDateStorage = date" (mouseleave)="hoveredDateStorage = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>


      </div>

      <!-- <hr class="mt-3 border-dark-grey" /> -->

      <!-- Consignment Details -->

      <div class="mt-3">
        <a class="mt-3 d-block" (click)="toggleConsignmentSection()">
          <div class="d-flex justify-content-between align-items-center address-hover">
            <h5 class="mr-2">Conisgnment Details</h5>
            <span class="mr-2 arrow-icon">
              <i [ngClass]="consignmentSection ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
            </span>
          </div>
          <hr class="mt-1 border-dark-grey">
        </a>
      </div>
      <!-- <h5 class="mt-3">CONSIGNMENT DETAILS</h5> -->
      <div class="row mt-2" [ngClass]="{ 'd-none': !consignmentSection }">
        <ng-container
          *ngIf="_moveDetail.transportType == eTransportType.Sea || _moveDetail.transportType == eTransportType.SeaLCL || _moveDetail.transportType == eTransportType.Air">
          <div class="col-md-6 mb-3">
            <label class="form-label fw-bold m-0 mt-3">
              Scheduled Departure Date
            </label>
            <div class="input-group">
              <div class="position-relative">
                <input class="form-control clickable" name="sdp" ngbDatepicker #sdt="ngbDatepicker" title="date-range"
                  [value]="generalService.formatDate(_moveDetail.scheduledDepartureDate, null)" (click)="sdt.open()"
                  (dateSelect)="onDateSelect($event, 'ScheduledDepartureDate'); sdt.close()" outsideDays="hidden"
                  placeholder="Select Date" [dayTemplate]="sd" [startDate]="scheduledDepartureDateShow!"
                  [disabled]="_contentDisable" />
                <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                  *ngIf="_moveDetail.scheduledDepartureDate && !_contentDisable"
                  (click)="_moveDetail.scheduledDepartureDate = null">
                  <i class="feather icon-x"></i>
                </button>
              </div>
              <button class="input-group-text" type="button" (click)="sdt.open()" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
              <input type="time" class="form-control input-color" [(ngModel)]="_scheduledDepartureTime"
                id="scheduledDepartureTime" [disabled]="_contentDisable" placeholder="Select time" required />

              <ng-template #sd let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused"
                  [class.highlight]="isHighlighted(date, 'scheduledDeparture')">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label fw-bold m-0 mt-3">
              Departure Date
            </label>
            <div class="input-group">
              <div class="position-relative">
                <input class="form-control clickable" name="sdp1" ngbDatepicker #sdt1="ngbDatepicker" title="date-range"
                  [value]="generalService.formatDate(_moveDetail.departureDate, null)" (click)="sdt1.open()"
                  (dateSelect)="onDateSelect($event, 'DepartureDate'); sdt1.close()" outsideDays="hidden"
                  placeholder="Select Date" [dayTemplate]="sd1" [startDate]="departureDateShow!"
                  [disabled]="_contentDisable" />
                <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                  *ngIf="_moveDetail.departureDate && !_contentDisable" (click)="_moveDetail.departureDate = null">
                  <i class="feather icon-x"></i>
                </button>
              </div>
              <button class="input-group-text" type="button" (click)="sdt1.open()" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
              <input type="time" class="form-control input-color" [(ngModel)]="_departureTime" id="departureTime"
                [disabled]="_contentDisable" placeholder="Select time" required />

              <ng-template #sd1 let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'departure')">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="_moveDetail.transportType == eTransportType.Sea || _moveDetail.transportType == eTransportType.SeaLCL">
          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              BL No.
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.blNo" placeholder="Enter Bill of Lading No"
              [disabled]="_contentDisable" />
          </div>
          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              Vessel Name
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.vesselName" placeholder="Enter Vessel Name"
              [disabled]="_contentDisable" />
          </div>
        </ng-container>
        <ng-container *ngIf="_moveDetail.transportType == eTransportType.Air">
          <div class="col-md-6">
            <label class="form-label fw-bold m-0 mt-3">
              AWB No.
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.awbNo" placeholder="Enter AirWay Bill No"
              [disabled]="_contentDisable" />
          </div>
          <div class="col-md-3">
            <label class="form-label fw-bold m-0 mt-3">
              Carrier Name
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.carrierName"
              placeholder="Enter Carrier Name" [disabled]="_contentDisable" />
          </div>
          <div class="col-md-3">
            <label class="form-label fw-bold m-0 mt-3">
              Flight No.
            </label>
            <input type="text" class="form-control" [(ngModel)]="_moveDetail.flightNo" placeholder="Enter Flight No"
              [disabled]="_contentDisable" />
          </div>
        </ng-container>
        <ng-container *ngIf="_moveDetail.transportType == eTransportType.Land">
          <div class="col-md-6 mb-3">
            <label class="form-label fw-bold m-0 mt-3">
              Scheduled Dispatch Date
            </label>
            <div class="input-group">
              <div class="position-relative">
                <input class="form-control clickable" name="sdp2" ngbDatepicker #sdt2="ngbDatepicker" title="date-range"
                  [value]="generalService.formatDate(_moveDetail.scheduledDispatchDate, null)" (click)="sdt2.open()"
                  (dateSelect)="onDateSelect($event, 'ScheduledDispatchDate'); sdt2.close()" outsideDays="hidden"
                  placeholder="Select Date" [dayTemplate]="sd2" [startDate]="scheduledDispatchDateShow!"
                  [disabled]="_contentDisable" />
                <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                  *ngIf="_moveDetail.scheduledDispatchDate && !_contentDisable"
                  (click)="_moveDetail.scheduledDispatchDate = null">
                  <i class="feather icon-x"></i>
                </button>
              </div>
              <button class="input-group-text" type="button" (click)="sdt2.open()" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
              <input type="time" class="form-control input-color" [(ngModel)]="_scheduledDispatchTime"
                id="scheduledDispatchTime" [disabled]="_contentDisable" placeholder="Select time" required />

              <ng-template #sd2 let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused"
                  [class.highlight]="isHighlighted(date, 'scheduledDispatch')">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label class="form-label fw-bold m-0 mt-3">
              Dispatch Date
            </label>
            <div class="input-group">
              <div class="position-relative">
                <input class="form-control clickable" name="sdp3" ngbDatepicker #sdt3="ngbDatepicker" title="date-range"
                  [value]="generalService.formatDate(_moveDetail.dispatchDate, null)" (click)="sdt3.open()"
                  (dateSelect)="onDateSelect($event, 'DispatchDate'); sdt3.close()" outsideDays="hidden"
                  placeholder="Select Date" [dayTemplate]="sd3" [startDate]="dispatchDateShow!"
                  [disabled]="_contentDisable" />
                <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                  *ngIf="_moveDetail.dispatchDate && !_contentDisable" (click)="_moveDetail.dispatchDate = null">
                  <i class="feather icon-x"></i>
                </button>
              </div>
              <button class="input-group-text" type="button" (click)="sdt3.open()" title="date-range">
                <i class="feather icon-calendar icon-md text-muted"></i>
              </button>
              <input type="time" class="form-control input-color" [(ngModel)]="_dispatchTime" id="dispatchTime"
                [disabled]="_contentDisable" placeholder="Select time" required />

              <ng-template #sd3 let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'dispatch')">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <div class="col-md-6">
          <label class="form-label fw-bold m-0 mt-3">
            Scheduled Arrival Date
          </label>
          <div class="input-group">
            <div class="position-relative">
              <input class="form-control clickable" name="sdp4" ngbDatepicker #sdt4="ngbDatepicker" title="date-range"
                [value]="generalService.formatDate(_moveDetail.scheduledArrivalDate, null)" (click)="sdt4.open()"
                (dateSelect)="onDateSelect($event, 'ScheduledArrivalDate'); sdt4.close()" outsideDays="hidden"
                placeholder="Select Date" [dayTemplate]="sd4" [startDate]="scheduledArrivalDateShow!"
                [disabled]="_contentDisable" />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.scheduledArrivalDate && !_contentDisable"
                (click)="_moveDetail.scheduledArrivalDate = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" (click)="sdt4.open()" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
            <input type="time" class="form-control input-color" [(ngModel)]="_scheduledArrivalTime"
              id="scheduledArrivalTime" [disabled]="_contentDisable" placeholder="Select time" required />

            <ng-template #sd4 let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused"
                [class.highlight]="isHighlighted(date, 'scheduledArrival')">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label fw-bold m-0 mt-3">
            Arrival Date
          </label>
          <div class="input-group">
            <div class="position-relative">
              <input class="form-control clickable" name="sdp5" ngbDatepicker #sdt5="ngbDatepicker" title="date-range"
                [value]="generalService.formatDate(_moveDetail.arrivalDate, null)" (click)="sdt5.open()"
                (dateSelect)="onDateSelect($event, 'ArrivalDate'); sdt5.close()" outsideDays="hidden"
                placeholder="Select Date" [dayTemplate]="sd5" [startDate]="arrivalDateShow!"
                [disabled]="_contentDisable" />
              <button class="btn btn-small cross-btn" type="button" title="Clear Date"
                *ngIf="_moveDetail.arrivalDate && !_contentDisable" (click)="_moveDetail.arrivalDate = null">
                <i class="feather icon-x"></i>
              </button>
            </div>
            <button class="input-group-text" type="button" (click)="sdt5.open()" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
            <input type="time" class="form-control input-color" [(ngModel)]="_arrivalTime" id="arrivalTime"
              [disabled]="_contentDisable" placeholder="Select time" required />

            <ng-template #sd5 let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.highlight]="isHighlighted(date, 'arrival')">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>

      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label fw-bold m-0 mt-3">
              &nbsp; Additional Notes
            </label>
            <textarea class="form-control" rows="5" [(ngModel)]="_moveDetail.additionalNotes"
              placeholder="Write Additional notes for uniques items like arts, etc."
              [disabled]="_contentDisable"></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <ng-container *ngIf="_createPermission">
          <button class="btn btn-sm btn-primary submit-info" (click)="saveMoveDetail()" [disabled]="_contentDisable">
            <i class="feather icon-save"></i> &nbsp; SAVE
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>



<!-- style="display: none;" -->