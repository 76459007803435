import { eReportSubType } from "./move-reports-dto.model";

export class SalesReportDTO {
  saleReportType: eSalesReportType;
  salesSubType: eReportSubType;
  dealName: string | null;
  dealOwnerId: number[] | null;
  contactName: string | null;
  accountId: number[] | null;
  probability: number | null;
  amount: number | null;
  pipeIds: number | null;
  ownedBy: number;

  constructor() {
    this.saleReportType = eSalesReportType.TotalLeads;
    this.salesSubType = eReportSubType.SummarizeReport;
    this.dealName = null;
    this.dealOwnerId = null;
    this.contactName = null;
    this.accountId = null;
    this.probability = null;
    this.amount = null;
    this.pipeIds = null;
    this.ownedBy = Number(localStorage.getItem("companyId"));
  }
}
export enum eSalesReportType {
  TotalLeads = 0,
  TotalSales = 1,
  LeadToSaleConversion = 2,
}


export class TotalSalesDetailReportDTO {
  date: Date;
  dealName: string | null;
  status: string | null;
  contactName: string | null;
  dealOwner: string | null;
  amount: number | null;
  probability: number | null;
  lastContact: Date;

  constructor() {
    this.date = new Date();
    this.dealName = null;
    this.status = null;
    this.contactName = null;
    this.dealOwner = null;
    this.amount = null;
    this.probability = null;
    this.lastContact = new Date();
  }
}
