import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from '../../../services/contact.service';
import { SaleService } from '../../../services/sale.service';
import { CustomerStatsDTO, DealsStatsDTO } from '../dashboard/dashboard.models';
import { TasksResponse } from '../crm/profile/profile.models';
import { DealsModel } from '../crm/sales/deals/deals.models';


@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.scss']

})
export class SuperAdminDashboardComponent implements OnInit {
  _isLoading: boolean = true;
  isDataLoaded = false;

  public lineChartOptions: any = {};
  public pieChartOptions: any = {};
  public areaChartOptions: any = {};
  public radialBarChartOptions: any = {};

  _customerStats: CustomerStatsDTO[] = [];
  _dealsStats: DealsStatsDTO[] = [];
  _dealsList: any[] = [];
  _topSalesRepList: any;
  _totalActivites: any;
  _chartDataForCustomers: { dates: any[], data: number[] } = { dates: [], data: [] };
  _chartDataForDeals: { dates: any[], data: number[] } = { dates: [], data: [] };
  _chartDataFor12Months: { dates: any[], data: number[] } = { dates: [], data: [] };
  _chartDataForActivities: { dates: any[], data: number[] } = { dates: [], data: [] };
  _tasksList: any[] = [];
  /**
   * Apex chart
   */
  public donutChartOptionsMoves: any;
  public donutChartOptionsLeads: any;
  public customersChartOptions: any = {};
  public ordersChartOptions: any = {};
  public growthChartOptions: any = {};
  public revenueChartOptions: any = {};
  public monthlySalesChartOptions: any = {};
  public cloudStorageChartOptions: any = {};

  public donutChartOptions: any = {};
  public barChartOptions: any = {};
  public pieChartOptions1: any = {};
  public pieChartOptions2: any = {};


  // colors and font variables for apex chart 
  obj = {
    primary: "#1AC0A1",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif"
  }

  /**
   * NgbDatepicker
   */
  currentDate!: NgbDateStruct;

  constructor(private calendar: NgbCalendar,
    private _contactService: ContactService,
    private _salesService: SaleService,
    private _router: Router
  ) { }

  async ngOnInit(): Promise<void> {

    this.donutChartOptionsLeads = getDonutChartOptions(this.obj, 'leads');
    this.donutChartOptionsMoves = getDonutChartOptions(this.obj, 'moves');
    this.areaChartOptions = getAreaChartOptions(this.obj);
    this.barChartOptions = getBarChartOptions(this.obj);
    this.lineChartOptions = getLineChartOptions(this.obj);
    this.pieChartOptions1 = getPieChartOptions(this.obj, 'User By Location');
    this.pieChartOptions2 = getPieChartOptions(this.obj, 'User Types');
    this.radialBarChartOptions = getRadialBarChartOptions(this.obj);

    await this.getCustomerStats();
    await this.getDealsStats();
    await this.getDealsList();

    await this.getTopSalesRep();
    await this.getDealsSummaryLast12Months();

    // this._isLoading = false;
    this.isDataLoaded = true;

    await this.fetchTasks();

    this.currentDate = this.calendar.getToday();
    const currentUserId = Number(localStorage.getItem('currentUserId'));
    // Some RTL fixes. (feel free to remove if you are using LTR))
    if (document.querySelector('html')?.getAttribute('dir') === 'rtl') {
      this.addRtlOptions();
    }

    await this.delay(20);

    this._isLoading = false;
  }

  delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
  }

  async fetchTasks(): Promise<void> {
    try {
      const companyId = Number(localStorage.getItem('companyId'));
      //console.log("currentUserId ", companyId);
      //console.log("Before getting tasks");
      let data: TasksResponse | undefined = await this._contactService.getTasks(companyId, false, null, null, null).toPromise();
      //console.log("After getting tasks");
      //console.log(data);


      // console.log("_tasksList ", data);
      if (data && data.tasks && data.tasks.length > 0) {
        this._tasksList = data.tasks;
        // console.log("_tasksList ", data);
      }
    } catch (error) {
      console.error('Error fetching customer Tasks:', error);
    }
  }

  getNameFromLocalStorage(): string | null {
    if (localStorage.getItem('role') == 'SuperAdmin') {
      return "Techflo";
    }
    return window.localStorage.getItem('name');
  }

  getTopSalesRep() {
    this._salesService.getTopSalesRep().subscribe(
      (data: any) => { // Change the type from any[] to any
        this._topSalesRepList = data as any[]; // Explicitly cast 'data' to any[]

        // console.log("_topSalesRepList", this._topSalesRepList);
        if (this._topSalesRepList && this._topSalesRepList.length > 0) {
          this._topSalesRepList = this._topSalesRepList.slice(0, 3);
        }

      },
      (error) => {
        console.error('Error fetching deals:', error);
      }
    );
  }
  getDealsList() {
    this._salesService.getDeals().subscribe(
      data => { // Change the type from any[] to any
        this._dealsList = data.deals as any[]; // Explicitly cast 'data' to any[]
        this._dealsList = this._dealsList.slice(0, 3);
      },
      error => {
        console.error('Error fetching deals:', error);
      }
    );
  }
  async getDealsSummaryLast12Months() {
    await this._salesService.getDealsSummaryLast12Months().subscribe(
      data => {
        const customerCreationData = data;
        this._chartDataFor12Months.dates = customerCreationData.map((deal) => deal.date);
        this._chartDataFor12Months.data = customerCreationData.map((deal) => deal.nums);
      },
      error => {
        console.error('Error retrieving customer stats:', error);
      }
    );
  }

  async getDealsStats() {
    const companyId = Number(localStorage.getItem('companyId'));
    await this._salesService.getDealsStats(companyId).subscribe(
      data => {
        this._dealsStats = data;
      },
      error => {
        console.error('Error retrieving deals stats:', error);
      }
    );
  }




  async getCustomerStats() {
    await this._contactService.getCustomerStats().subscribe(
      data => {
        this._customerStats = data;
        // console.log("_customerStats", data);
      },
      error => {
        console.error('Error retrieving customer stats:', error);
      }
    );
  }
  /**
   * Only for RTL (feel free to remove if you are using LTR)
   */
  addRtlOptions() {
    // Revenue chart
    this.revenueChartOptions.yaxis.labels.offsetX = -25;
    this.revenueChartOptions.yaxis.title.offsetX = -75;

    //  Monthly sales chart
    this.monthlySalesChartOptions.yaxis.labels.offsetX = -10;
    this.monthlySalesChartOptions.yaxis.title.offsetX = -70;
  }
}


function getDonutChartOptions(obj: any, chartType: string) {
  let series, labels;

  if (chartType === 'leads') {
    series = [44, 55, 13, 20];
    labels = ['New', 'In Progress', 'Won', 'Lost'];
  } else if (chartType === 'moves') {
    series = [44, 55, 13];
    labels = ['Local', 'Import', 'Export'];
  }

  return {
    series: series,
    labels: labels,
    chart: {
      height: 300,
      type: "donut",
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
    },
    colors: [obj.primary, obj.warning, obj.danger, obj.info],
    // colors: ['#FF5733', '#C70039', '#900C3F', '#581845'], // Updated colors
    stroke: {
      colors: ['rgba(0,0,0,0)']
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'center',
      fontFamily: obj.fontFamily,
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
    },
    dataLabels: {
      enabled: false
    }
  }
};


// function getBarChartOptions(obj: any) {
//   return {
//     series: [
//       {
//         name: 'Available',
//         data: [30, 40, 45, 50]
//       },
//       {
//         name: 'Occupied',
//         data: [10, 20, 15, 30]
//       },
//       {
//         name: 'Unavailable',
//         data: [20, 20, 30, 20]
//       }
//     ],
//     chart: {
//       type: 'bar',
//       height: '280',
//       parentHeightOffset: 0,
//       foreColor: obj.bodyColor,
//       background: obj.cardBg,
//       toolbar: {
//         show: false
//       },
//     },
//     colors: [obj.primary, obj.secondary, obj.warning],
//     grid: {
//       padding: {
//         bottom: -4
//       },
//       borderColor: obj.gridBorder,
//       xaxis: {
//         lines: {
//           show: true
//         }
//       }
//     },
//     xaxis: {
//       type: 'category', // Use 'category' for non-datetime categories
//       categories: ['Mover', 'Packers', 'Drivers', 'Loaders'],
//       axisBorder: {
//         color: obj.gridBorder,
//       },
//       axisTicks: {
//         color: obj.gridBorder,
//       },
//     },
//     yaxis: {
//       labels: {
//         offsetX: 0
//       }
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: 4
//       }
//     }
//   };
// }
function getLineChartOptions(obj: any) {
  return {
    series: [
      {
        name: "",
        data: [1, 2, 3, 4, 5]
      },
      {
        name: "",
        data: [1, 2, 3, 4, 5]
      },
      {
        name: "",
        data: [1, 2, 3, 4, 5]
      }
    ],
    chart: {
      type: "line",
      height: '320',
      parentHeightOffset: 0,
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
    },

    colors: [obj.primary, obj.danger, obj.warning],
    grid: {
      padding: {
        bottom: -4
      },
      borderColor: obj.gridBorder,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: ['M1', 'M2', 'M3', 'M4', 'M5'],
      lines: {
        show: true
      },
      axisBorder: {
        color: obj.gridBorder,
      },
      axisTicks: {
        color: obj.gridBorder,
      },
    },
    yaxis: {
      labels: {
        offsetX: 0
      }
    },
    markers: {
      size: 0,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: 'center',
      fontFamily: obj.fontFamily,
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
    },
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round"
    },
  }
};

function getPieChartOptions(obj: any, chartType: string) {
  let series;
  let labels;
  if (chartType === 'User By Location') {
    series = [44, 55, 13]; // This represents the data for each label in 'User By Location' chart
    labels = ['US', 'PAK', 'UK'];
  } else if (chartType === 'User Types') {
    series = [44, 55, 13, 33]; // This represents the data for each label in 'User Types' chart
    labels = ['Account', 'Client', 'vendor', 'partner'];
  }
  return {
    series: series,
    labels: labels,
    chart: {
      height: 300,
      type: "pie",
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
    },
    colors: ['#ff5733', '#66ccff', '#99ff66', '#e6e6e6'],
    stroke: {
      colors: ['rgba(0,0,0,0)']
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: 'center',
      fontFamily: obj.fontFamily,
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
    },
    dataLabels: {
      enabled: true // This enables the display of labels on the chart
    }
  }
};

function getBarChartOptions(obj: any) {
  return {
    series: [
      {
        name: 'sales',
        data: [34, 56, 100, 150, 44] // Update this with your actual data
      }
    ],
    chart: {
      type: 'bar',
      height: '280',
      parentHeightOffset: 0,
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
    },
    colors: [obj.primary, obj.secondary, obj.warning],
    grid: {
      padding: {
        bottom: -4
      },
      borderColor: obj.gridBorder,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      type: 'category', // Use 'category' for non-datetime categories
      categories: ['Phone', 'Email', 'Web', 'Facebook', 'Twitter'], // Update x-axis categories
      axisBorder: {
        color: obj.gridBorder,
      },
      axisTicks: {
        color: obj.gridBorder,
      },
    },
    yaxis: {
      labels: {
        offsetX: 0,
        formatter: (value: number) => { return `${value}k`; } // Format y-axis labels as '0k', '5k', etc.
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4
      }
    }
  };
};
function getAreaChartOptions(obj: any) {
  return {
    series: [
      {
        name: 'Resolved',
        data: generateDayWiseTimeSeries(0, 18)
      }, {
        name: 'New',
        data: generateDayWiseTimeSeries(1, 18)
      }
    ],
    chart: {
      type: "area",
      height: 300,
      parentHeightOffset: 0,
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
      stacked: true,
    },
    colors: [obj.danger, obj.info],
    stroke: {
      curve: "smooth",
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        color: obj.gridBorder,
      },
      axisTicks: {
        color: obj.gridBorder,
      },
    },
    yaxis: {
      title: {
        text: 'Views',
      },
      tickAmount: 4,
      min: 0,
      labels: {
        offsetX: 0,
      },
      tooltip: {
        enabled: true
      }
    },
    grid: {
      padding: {
        bottom: -4
      },
      borderColor: obj.gridBorder,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy"
      },
    },
    fill: {
      type: 'solid',
      opacity: [0.4, 0.25],
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: 'center',
      fontFamily: obj.fontFamily,
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
    },
  }
};

function generateDayWiseTimeSeries(s: number, count: number) {
  var values = [[
    4, 3, 10, 9, 29, 19, 25, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5
  ], [
    2, 3, 8, 7, 22, 16, 23, 7, 11, 5, 12, 5, 10, 4, 15, 2, 6, 2
  ]];
  var i = 0;
  var series: any[] = [];
  var x = new Date("11 Nov 2020").getTime();
  while (i < count) {
    series.push([x, values[s][i]]);
    x += 86400000;
    i++;
  }
  return series;
}

function getRadialBarChartOptions(obj: any) {
  return {
    series: [4, 55, 97],
    chart: {
      height: 300,
      type: "radialBar",
      parentHeightOffset: 0,
      foreColor: obj.bodyColor,
      background: obj.cardBg,
      toolbar: {
        show: false
      },
    },
    colors: [obj.primary, obj.warning, obj.danger],
    grid: {
      padding: {
        top: 10
      }
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: true,
            label: 'TOTAL',
            fontSize: '14px',
            fontFamily: obj.fontFamily,
          }
        },
        track: {
          background: obj.gridBorder,
          strokeWidth: '100%',
          opacity: 1,
          margin: 5,
        },
      }
    },
    labels: ["Normal", "Low", "High"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: 'center',
      fontFamily: obj.fontFamily,
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
    },
  }
};



/**
 * Generating demo data for area chart
 */
function generateData(count: any, yrange: any) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = 'w' + (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
}

