import { Address } from "./address.model";

export class PackingMaterial {
  id: number;
  ownedBy: number;
  name: string;
  type: eMaterialType | null;
  weight: number | null;
  cost: number | null;
  volume: number | null;
  quantity: number | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.name = '';
    this.type = null;
    this.weight = null;
    this.cost = null;
    this.volume = null;
    this.quantity = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
export enum eMaterialType {
  CardboardBox = 0,
  BubbleWrap = 1,
  PackingPeanuts = 2,
  AirPillows = 3,
  FoamWraps = 4,
  PlasticMailers = 5,
  WoodenCrates = 6,
  SteelDrums = 7,
  PalletizedCargo = 8,
}

export interface EnumOption {
  id: number;
  value: string;
}

export const MaterialTypeOptions: EnumOption[] = [
  { id: eMaterialType.CardboardBox, value: 'Cardboard Box' },
  { id: eMaterialType.BubbleWrap, value: 'Bubble Wrap' },
  { id: eMaterialType.PackingPeanuts, value: 'Packing Peanuts' },
  { id: eMaterialType.AirPillows, value: 'Air Pillows' },
  { id: eMaterialType.FoamWraps, value: 'Foam Wraps' },
  { id: eMaterialType.PlasticMailers, value: 'Plastic Mailers' },
  { id: eMaterialType.WoodenCrates, value: 'Wooden Crates' },
  { id: eMaterialType.SteelDrums, value: 'Steel Drums' },
  { id: eMaterialType.PalletizedCargo, value: 'Palletized Cargo' },
];
