import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MoveDetail } from '../models/move-detail.model';
import { ActivatedRoute } from '@angular/router';
import { MovdService } from '../services/movd.service';
import { StatusWithMoveStatus } from '../models/move-status.model';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/services/general.service';
import { AppComponent } from 'src/app/app.component';
import { MoveTypeOptions, MoveCategoryTypeOptions, TransportTypeOptions, eMoveCategoryType, LeadDetail, eTransportType } from '../models/lead-detail.model';
import { EnumMappingService } from '../services/movd.enum.service';
import { Address, CountryOptions } from '../models/address.model';
import { AuthService } from 'src/services/auth.service';
import { eStatusType } from '../models/status.model';
import { MoveBoardDTO } from '../moves-board/moves-board.model';


@Component({
  selector: 'app-move-info-form',
  templateUrl: './move-info-form.component.html',
  styleUrls: ['./move-info-form.component.scss']
})
export class MoveInfoFormComponent implements OnChanges, OnInit {
  @Input() moveDetailId: any;
  @Input() refreshMoveInfo: any;
  @Input() _contentDisable = false;

  // @Input() leadId: any;
  // @Input() leadDetailId: any;
  // @Input() moveId: any;

  @Output() draftBLData = new EventEmitter<any>();
  @Output() version = new EventEmitter<any>();
  @Output() categoryTransportChange = new EventEmitter<{ category: number, transport: number }>();

  @ViewChild('dateModel') dateModel: any;
  @ViewChild('addressModel') addressModel: any;
  @ViewChild('datepicker') datepicker!: NgbDatepicker;
  showSections: boolean = true;
  countries = CountryOptions;

  shipmentSections: boolean = true;
  consignmentSection: boolean = true;

  _leadId = 0;
  _leadDetail = new LeadDetail();
  _moveDetail = new MoveDetail();

  // packing 
  hoveredDatePacking: NgbDateStruct | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  _packingDate: NgbDateStruct | null = null;
  _packingDateTime: Date | null = null;
  _packingTime: string | null = null;

  // loading
  hoveredDateLoading: NgbDateStruct | null = null;
  fromDate1: NgbDate | null = null;
  toDate1: NgbDate | null = null;
  _loadingDate: NgbDateStruct | null = null;
  _loadingTime: string | null = null;
  _loadingDateTime: Date | null = null;

  // storage dates
  storageNeeded: boolean = false;

  hoveredDateStorage: NgbDateStruct | null = null;
  fromDate2: NgbDate | null = null;
  toDate2: NgbDate | null = null;
  // survey dates
  hoveredDateSurvey: NgbDateStruct | null = null;
  surveyDateShow: NgbDate | null = null;

  _surveyDate: NgbDateStruct | null = null;
  _surveyTime: string | null = null;
  _surveyDateTime: Date | null = null;
  _portLabel: string | null = null;

  _storageDate: NgbDateStruct | null = null;
  _storageTime: string | null = null;
  _storageDateTime: Date | null = null;

  // delivery 
  hoveredDateDelivery: NgbDateStruct | null = null;
  deliveryDateShow: NgbDate | null = null;
  _deliveryDate: NgbDateStruct;
  sameDayDelivery: boolean = false;
  _deliveryTime: string | null = null;
  _deliveryDateTime: Date | null = null;

  // scheduled Departure Date 
  hoveredDateScheduledDeparture: NgbDateStruct | null = null;
  scheduledDepartureDateShow: NgbDate | null = null;
  _scheduledDepartureDate: NgbDateStruct;
  _scheduledDepartureTime: string | null = null;
  _scheduledDepartureDateTime: Date | null = null;

  // Departure Date 
  hoveredDateDeparture: NgbDateStruct | null = null;
  departureDateShow: NgbDate | null = null;
  _departureDate: NgbDateStruct;
  _departureTime: string | null = null;
  _departureDateTime: Date | null = null;

  // scheduled Dispatch Date 
  hoveredDateScheduledDispatch: NgbDateStruct | null = null;
  scheduledDispatchDateShow: NgbDate | null = null;
  _scheduledDispatchDate: NgbDateStruct;
  _scheduledDispatchTime: string | null = null;
  _scheduledDispatchDateTime: Date | null = null;

  // Dispatch Date 
  hoveredDateDispatch: NgbDateStruct | null = null;
  dispatchDateShow: NgbDate | null = null;
  _dispatchDate: NgbDateStruct;
  _dispatchTime: string | null = null;
  _dispatchDateTime: Date | null = null;

  // scheduled Arrival Date 
  hoveredDateScheduledArrival: NgbDateStruct | null = null;
  scheduledArrivalDateShow: NgbDate | null = null;
  _scheduledArrivalDate: NgbDateStruct;
  _scheduledArrivalTime: string | null = null;
  _scheduledArrivalDateTime: Date | null = null;

  // Arrival Date 
  hoveredDateArrival: NgbDateStruct | null = null;
  arrivalDateShow: NgbDate | null = null;
  _arrivalDate: NgbDateStruct;
  _arrivalTime: string | null = null;
  _arrivalDateTime: Date | null = null;

  // enums
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  _statusList: any[] = [];
  _firstStatus: any;
  _lastStatus: any;

  _moveStatuses: StatusWithMoveStatus[] = [];
  _isDataLoaded: boolean = false;
  defaultNavActiveId: number = 1;
  originShowAlert: boolean = false;
  destShowAlert: boolean = false;
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];
  originActiveTabIndex: number = 0;
  destActiveTabIndex: number = 0;
  maxAddresses: number = 3;
  originAddressCount: number = 0;
  destinationAddressCount: number = 0;
  originTabErrors: boolean = false;
  destTabErrors: boolean = false;
  eMoveCategoryType = eMoveCategoryType;
  eTransportType = eTransportType;
  _modalInstance!: NgbModalRef;
  _modalInstance1!: NgbModalRef;
  _modalInstance2!: NgbModalRef;
  _addressModalTitle: string = '';
  _address = new Address();

  dateFields: {
    [field: string]: {
      fromDate: NgbDate | null;
      toDate: NgbDate | null;
    };
  } = {
      packing: {
        fromDate: null,
        toDate: null
      },
      loading: {
        fromDate: null,
        toDate: null
      },
      delivery: {
        fromDate: null,
        toDate: null
      },
      storage: {
        fromDate: null,
        toDate: null
      }
    };
  _selectedMoveId: number = 0;
  _moveBoardDTOList: MoveBoardDTO[] = [];
  _dbMoveDetail = new MoveDetail();
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;
  _readPermissionDraftBLAWB = false;

  // for updating status on changing move category and transport type
  _statusVersion = 0;


  constructor(private _route: ActivatedRoute,
    private _movdService: MovdService,
    public generalService: GeneralService,
    private calendar: NgbCalendar,
    private _appComponent: AppComponent,
    private _modalService: NgbModal,
    public enumMappingService: EnumMappingService,
    private _authService: AuthService,
  ) {
    const today = calendar.getToday();

    this._deliveryDate = calendar.getToday();
    this._scheduledDepartureDate = calendar.getToday();
    this._departureDate = calendar.getToday();
    this._scheduledDispatchDate = calendar.getToday();
    this._dispatchDate = calendar.getToday();
    this._scheduledArrivalDate = calendar.getToday();
    this._arrivalDate = calendar.getToday();

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.fromDate1 = calendar.getToday();
    this.toDate1 = calendar.getNext(calendar.getToday(), 'd', 10);

    this.fromDate2 = calendar.getToday();
    this.toDate2 = calendar.getNext(calendar.getToday(), 'd', 10);
    this._userRole = localStorage.getItem('role');

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['moveDetailId'] || changes['refreshMoveInfo']) {
      // console.log("changes in move detail or refresh move info");

      if (changes['moveDetailId']) {
        this._moveDetail.id = changes['moveDetailId'].currentValue;
      }

      if (changes['_contentDisable']) {
        this._contentDisable = changes['_contentDisable'].currentValue;
      }

      if (this._moveDetail.id !== undefined && this._moveDetail.id !== 0) {
        // console.log("calling the funciton");

        this.getMoveDetailById(this._moveDetail.id);
        this.getStatuses(eStatusType.Move, this._moveDetail.categoryType, this._moveDetail.transportType);
        this.getMoveStatuses(this._moveDetail.categoryType, this._moveDetail.transportType);
      }
    }
  }

  ngOnInit() {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Move Details - Info.Read') || this._userRole === 'CompanyAdmin' || this._userRole === 'ThirdParty' || this._userRole === 'Moving Account';
    this._createPermission = permissions.includes('Move Details - Info.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Move Details - Info.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Move Details - Info.Delete') || this._userRole === 'CompanyAdmin';

    this._readPermissionDraftBLAWB = permissions.includes('Move Details - Draft BL/ AWB.Read') || this._userRole === 'CompanyAdmin';

    this.addOriginTab();
    this.addDestTab();
  }

  openAddressModal(modalRef: TemplateRef<any>, addressType: any) {
    if (addressType === 0) {
      this._addressModalTitle = "Add Origin Address";
    } else if (addressType === 1) {
      this._addressModalTitle = "Add Destination Address";
    }

    this._address.addressType = addressType;
    this._modalInstance = this._modalService.open(modalRef, { size: 'md' });
  }

  onChangeCategory() {
    this.categoryTransportChange.emit({ category: this._moveDetail.categoryType, transport: this._moveDetail.transportType });

    this.getStatuses(eStatusType.Move, this._moveDetail.categoryType, this._moveDetail.transportType);
    this.getMoveStatuses(this._moveDetail.categoryType, this._moveDetail.transportType);

    this.displayDraftBLTab(this._moveDetail.categoryType, this._moveDetail.transportType);
  }

  onChangeTransport() {

    this.categoryTransportChange.emit({ category: this._moveDetail.categoryType, transport: this._moveDetail.transportType });
    this.identifyPortLabel();
    this.displayDraftBLTab(this._moveDetail.categoryType, this._moveDetail.transportType);
  }

  displayDraftBLTab(moveCategory: eMoveCategoryType | null, transportType: eTransportType | null) {
    let outputData;
    if (moveCategory && transportType && moveCategory === eMoveCategoryType.DoorToDoorExport || moveCategory === eMoveCategoryType.DoorToPort || moveCategory === eMoveCategoryType.DoorToDoorLocal) {
      if (transportType === eTransportType.Sea || transportType === eTransportType.SeaLCL) {
        outputData = { result: true, label: "CONSIGNMENT INFO" }; // export sea shipment
      } else if (transportType === eTransportType.Air) {
        outputData = { result: true, label: "CONSIGNMENT INFO" }; // export air shipment
      } else if (transportType === eTransportType.Land) {
        outputData = { result: true, label: "CONSIGNMENT INFO" }; // export land shipment
      }
      else {
        outputData = { result: false };
      }
    } else {
      outputData = { result: false };
    }
    this.draftBLData.emit(outputData); // Emit the data
  }
  toggleSections() {
    this.showSections = !this.showSections;
  }

  shipmentSection() {
    this.shipmentSections = !this.shipmentSections;
  }

  toggleConsignmentSection() {
    this.consignmentSection = !this.consignmentSection;
  }

  setOriginActiveTab(index: number) {
    this.originActiveTabIndex = index;
  }

  setDestActiveTab(index: number) {
    this.destActiveTabIndex = index;
  }
  closeOriginAlert() {
    this.originShowAlert = false;
  }

  closeDestAlert() {
    this.destShowAlert = false;
  }
  ngOnDestroy() {
    // console.log("on destroy")
    // localStorage.removeItem('moveDetailId');
    // localStorage.removeItem('moveId');
  }

  removeOriginTab(index: number): void {
    if (this._originAddresses.length > 1) {
      this._originAddresses.splice(index, 1);
      if (this.originActiveTabIndex === index) {
        this.originActiveTabIndex = 0;
      } else if (this.originActiveTabIndex > index) {
        this.originActiveTabIndex--;
      }
    }
  }

  removeDestTab(index: number): void {
    if (this._destinationAddresses.length > 1) {
      this._destinationAddresses.splice(index, 1);
      if (this.destActiveTabIndex === index) {
        this.destActiveTabIndex = 0;
      } else if (this.destActiveTabIndex > index) {
        this.destActiveTabIndex--;
      }
    }
  }
  addOriginTab() {
    if (this._originAddresses.length < this.maxAddresses) {
      const newAddress: Address = { ...this._originAddresses[this.originActiveTabIndex] };
      newAddress.addressType = 0; // Set addressType to 0 for origin address

      newAddress.id = 0;
      // Reset the fields of the new address
      newAddress.streetAddress = '';
      newAddress.city = '';
      newAddress.country = null;
      newAddress.state = '';
      newAddress.zipCode = '';

      this._originAddresses.push(newAddress);
      this.originActiveTabIndex = this._originAddresses.length - 1;
      // console.log("origin address", this.originAddresses);
    } else {
      this.originShowAlert = true;
    }
  }
  addDestTab() {
    if (this._destinationAddresses.length < this.maxAddresses) {
      const newAddress: Address = { ...this._destinationAddresses[this.destActiveTabIndex] };
      newAddress.addressType = 1; // Set addressType to 1 for destination address

      newAddress.id = 0;
      // Reset the fields of the new address
      newAddress.streetAddress = '';
      newAddress.city = '';
      newAddress.country = null;
      newAddress.state = '';
      newAddress.zipCode = '';

      this._destinationAddresses.push(newAddress);
      this.destActiveTabIndex = this._destinationAddresses.length - 1;
    } else {
      this.destShowAlert = true;
    }
  }
  onDateSelect(date: NgbDate, type: string) {
    const dateToSet = this.convertNgbDateToDate(date);

    if (type === 'SurveyDate') {
      this._moveDetail.surveyDate = dateToSet;
    } else if (type === 'DeliveryDate') {
      this._moveDetail.deliveryDate = dateToSet;
    } else if (type === 'ScheduledDepartureDate') {
      this._moveDetail.scheduledDepartureDate = dateToSet;
    } else if (type === 'DepartureDate') {
      this._moveDetail.departureDate = dateToSet;
    } else if (type === 'ScheduledDispatchDate') {
      this._moveDetail.scheduledDispatchDate = dateToSet;
    } else if (type === 'DispatchDate') {
      this._moveDetail.dispatchDate = dateToSet;
    } else if (type === 'ScheduledArrivalDate') {
      this._moveDetail.scheduledArrivalDate = dateToSet;
    } else if (type === 'ArrivalDate') {
      this._moveDetail.arrivalDate = dateToSet;
    }
  }
  onDateSelection(date: any, type: string) {
    const selectedDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    // const selectedDate = new Date(date.year, date.month - 1, date.day);
    // Create a JavaScript Date object
    // Create a JavaScript Date object with time set to midnight - , 0, 0, 0
    // console.log("ngb date", date);
    // console.log("selected date", selectedDate);

    switch (type) {
      case "packing":
        this.hoveredDatePacking = { year: date.year, month: date.month, day: date.day };
        if (!this._moveDetail.packagingDateFrom && !this._moveDetail.packagingDateTo) {
          this._moveDetail.packagingDateFrom = selectedDate;
        } else if (
          this._moveDetail.packagingDateFrom &&
          !this._moveDetail.packagingDateTo &&
          selectedDate > this._moveDetail.packagingDateFrom
        ) {
          this._moveDetail.packagingDateTo = selectedDate;
        } else {
          this._moveDetail.packagingDateFrom = selectedDate;
          this._moveDetail.packagingDateTo = null;
        }
        if (this.sameDayDelivery) {
          this.dateFields['loading'].fromDate = date;
          this.dateFields['delivery'].fromDate = date;

          this._moveDetail.loadingDateFrom = selectedDate;
          this._moveDetail.deliveryDate = selectedDate;

        }
        break;

      case "loading":
        this.hoveredDateLoading = { year: date.year, month: date.month, day: date.day };
        if (!this._moveDetail.loadingDateFrom && !this._moveDetail.loadingDateTo) {
          this._moveDetail.loadingDateFrom = selectedDate;
        } else if (
          this._moveDetail.loadingDateFrom &&
          !this._moveDetail.loadingDateTo &&
          selectedDate > this._moveDetail.loadingDateFrom
        ) {
          this._moveDetail.loadingDateTo = selectedDate;
        } else {
          this._moveDetail.loadingDateFrom = selectedDate;
          this._moveDetail.loadingDateTo = null;
        }
        if (this._modalInstance1 !== undefined) {
          if (this._moveDetail.loadingDateFrom !== null && this._moveDetail.loadingDateTo !== null) {
            this._modalInstance1.close();
          }
        }
        break;

      case "storage":
        this.hoveredDateStorage = { year: date.year, month: date.month, day: date.day };
        if (!this._moveDetail.storageDateFrom && !this._moveDetail.storageDateTo) {
          this._moveDetail.storageDateFrom = selectedDate;
        } else if (
          this._moveDetail.storageDateFrom &&
          !this._moveDetail.storageDateTo &&
          selectedDate > this._moveDetail.storageDateFrom
        ) {
          this._moveDetail.storageDateTo = selectedDate;
        } else {
          this._moveDetail.storageDateFrom = selectedDate;
          this._moveDetail.storageDateTo = null;
        }
        if (this._modalInstance2 !== undefined) {
          if (this._moveDetail.storageDateFrom !== null && this._moveDetail.storageDateTo !== null) {
            this._modalInstance2.close();
          }
        }
        break;

    }
    this.onChange();
  }

  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }

  getStatuses(statusType: eStatusType, category: eMoveCategoryType, transport: eTransportType) {
    this._movdService.GetStatuses(statusType, category, transport).subscribe({
      next: (response: any) => {
        // console.log("status", response);
        if (response && response.length > 0) {
          const filteredStatus = response.filter((item: { isDeleted: boolean; category: number; }) =>
            item.isDeleted === false && item.category === this._moveDetail.categoryType);
          // console.log("filtered status", filteredStatus);

          this._statusList = filteredStatus;
          this._firstStatus = filteredStatus.reduce((minItem: any, currentItem: any) => {
            return currentItem.order < (minItem ? minItem.order : Infinity) ? currentItem : minItem;
          }, null);

          this._lastStatus = filteredStatus.reduce((maxItem: any, currentItem: any) => {
            return currentItem.order > (maxItem ? maxItem.order : 0) ? currentItem : maxItem;
          }, null);

          // console.log("First status:", this._firstStatus);
          // console.log("Last status:", this._lastStatus);
        }
      },
      error: (error: any) => {
        console.error("error getting statuses", error);
      }
    });
  }

  getMoveStatuses(category: number, transportType: number): void {
    const statusType = 0;
    this._movdService.getLeadStatus(this._leadDetail.id, statusType, category, transportType).subscribe({
      next: (data: any) => {
        // console.log("get lead status service called from move info form", data);
        if (data) {
          this._moveStatuses = data.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);
          this._firstStatus = this._moveStatuses.reduce((minItem: any, currentItem: any) => {
            return currentItem.order < (minItem ? minItem.order : Infinity) ? currentItem : minItem;
          }, null);

          this._lastStatus = this._moveStatuses.reduce((maxItem: any, currentItem: any) => {
            return currentItem.order > (maxItem ? maxItem.order : 0) ? currentItem : maxItem;
          }, null);
          // console.log("First status:", this._firstStatus);
          // console.log("Last status:", this._lastStatus);
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  isRange(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);

    if (fromDate && toDate) {
      return date.equals(fromDate) || date.equals(toDate) || this.isInside(date, type) || this.isHovered(date, type) || this.isRangeSelected(date, fromDate, toDate);
    }

    return false;
  }

  isRangeSelected(date: NgbDate, fromDate: NgbDate, toDate: NgbDate) {
    if (!fromDate || !toDate) {
      return false;
    }

    return date.after(fromDate) && date.before(toDate);
  }
  isHovered(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);
    const hoveredDate = this.getHoveredDateByType(type);

    if (!hoveredDate) {
      return false;
    }

    // console.log("is hovered , from , to, type", hoveredDate, hoveredDate, fromDate, toDate, type);
    return fromDate && !toDate && date.after(fromDate) && date.before(toDate) && date.equals(hoveredDate);
  }

  isInside(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);

    // console.log("is inside , from , to, type", fromDate, toDate, type);
    return fromDate && toDate && date.after(fromDate) && date.before(toDate);
  }
  getHoveredDateByType(type: string): NgbDateStruct | null {
    switch (type) {
      case "packing":
        return this.hoveredDatePacking;

      case "loading":
        return this.hoveredDateLoading;

      case "storage":
        return this.hoveredDateStorage;

      default:
        return null;
    }
  }
  getFromDateByType(type: string): NgbDate | null {
    switch (type) {
      case "packing":
        return this.convertDateToNgbDate(this._moveDetail.packagingDateFrom);
      case "loading":
        return this.convertDateToNgbDate(this._moveDetail.loadingDateFrom);
      case "storage":
        return this.convertDateToNgbDate(this._moveDetail.storageDateFrom);
      default:
        return null;
    }
  }

  getToDateByType(type: string): NgbDate | null {
    switch (type) {
      case "packing":
        return this.convertDateToNgbDate(this._moveDetail.packagingDateTo);
      case "loading":
        return this.convertDateToNgbDate(this._moveDetail.loadingDateTo);
      case "storage":
        return this.convertDateToNgbDate(this._moveDetail.storageDateTo);
      default:
        return null;
    }
  }
  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }
  isHighlighted(date: NgbDateStruct, dateField: string) {
    let dateToCompare: NgbDate | null;

    if (dateField === 'survey') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.surveyDate);
    } else if (dateField === 'delivery') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.deliveryDate);
    } else if (dateField === 'scheduledDeparture') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.scheduledDepartureDate);
    } else if (dateField === 'departure') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.departureDate);
    } else if (dateField === 'scheduledDispatch') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.scheduledDispatchDate);
    } else if (dateField === 'dispatch') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.dispatchDate);
    } else if (dateField === 'scheduledArrival') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.scheduledArrivalDate);
    } else if (dateField === 'arrival') {
      dateToCompare = this.convertDateToNgbDate(this._moveDetail.arrivalDate);
    }
    else {
      return false; // Invalid dateField
    }

    if (dateToCompare) {
      return (
        date.year === dateToCompare.year &&
        date.month === dateToCompare.month &&
        date.day === dateToCompare.day
      );
    }

    return false;
  }
  isInsidein(date: NgbDate, type: string) {
    const targetDate = this.getTargetDateByType(type);

    if (targetDate) {
      const targetNgbDate = this.convertDateToNgbDate(targetDate);
      return date.equals(targetNgbDate);
    }

    return false;
  }

  getTargetDateByType(type: string): string | Date | null {
    if (type === 'survey') {
      return this._moveDetail.surveyDate;
    } else if (type === 'delivery') {
      return this._moveDetail.deliveryDate;
    } else if (type === 'scheduledDeparture') {
      return this._moveDetail.scheduledDepartureDate;
    } else if (type === 'departure') {
      return this._moveDetail.departureDate;
    } else if (type === 'scheduledDispatch') {
      return this._moveDetail.scheduledDispatchDate;
    } else if (type === 'dispatch') {
      return this._moveDetail.dispatchDate;
    } else if (type === 'scheduledArrival') {
      return this._moveDetail.scheduledArrivalDate;
    } else if (type === 'arrival') {
      return this._moveDetail.arrivalDate;
    } else {
      return null; // Invalid type
    }
  }
  onSameDayDeliveryChange() {
    if (this.sameDayDelivery) {
      const date = this._moveDetail.packagingDateFrom;
      this._moveDetail.loadingDateFrom = date;
      this._moveDetail.loadingDateTo = null;
      this._moveDetail.deliveryDate = date;
      const time = this._packingTime;
      this._loadingTime = time;
      this._deliveryTime = time;

    }
    else {
      this._moveDetail.loadingDateFrom = null;
      this._moveDetail.deliveryDate = null;
      this._loadingTime = null;
      this._deliveryTime = null;

    }

  }

  onChange() {
    this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.packagingDateFrom, null));
    this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._moveDetail.packagingDateTo));
    this.fromDate1 = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.loadingDateFrom, null));
    this.toDate1 = this.convertDateToNgbDate(this.generalService.formatDate(null, this._moveDetail.loadingDateTo));
    this.fromDate2 = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.storageDateFrom, null));
    this.toDate2 = this.convertDateToNgbDate(this.generalService.formatDate(null, this._moveDetail.storageDateTo));
    this.surveyDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.surveyDate, null));
    this.deliveryDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.deliveryDate, null));
    this.scheduledDepartureDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.scheduledDepartureDate, null));
    this.departureDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.departureDate, null));
    this.scheduledDispatchDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.scheduledDispatchDate, null));
    this.dispatchDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.dispatchDate, null));
    this.scheduledArrivalDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.scheduledArrivalDate, null));
    this.arrivalDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._moveDetail.arrivalDate, null));
  }

  getMoveDetailById(moveDetailId: any) {
    this._movdService.getMoveDetailById(moveDetailId).subscribe({
      next: (data: any) => {
        if (data) {
          // console.log("move detail in move info", data);

          this._moveDetail = data;
          // this.checkContentDisable();

          //  for survey Date  
          if (data.surveyDate) {
            const surveyDateTime = new Date(data.surveyDate);
            this._surveyTime = surveyDateTime.toTimeString().slice(0, 5);
          } else {
            this._surveyTime = null;
            // console.log("one move detail", this._surveyTime);
          }

          // for packagingDate 
          if (data.packagingDateFrom) {
            const packingDateTime = new Date(data.packagingDateFrom);
            this._packingTime = packingDateTime.toTimeString().slice(0, 5);
          } else {
            this._packingTime = null;
          }
          // for loadingDate 

          if (data.loadingDateFrom) {
            const loadingDateTime = new Date(data.loadingDateFrom);
            this._loadingTime = loadingDateTime.toTimeString().slice(0, 5);
          } else {
            this._loadingTime = null;
          }
          // for deliveryDate 
          if (data.deliveryDate) {
            const deliveryDateTime = new Date(data.deliveryDate);
            this._deliveryDate = {
              year: deliveryDateTime.getFullYear(),
              month: deliveryDateTime.getMonth() + 1,
              day: deliveryDateTime.getDate()
            };
            this._deliveryTime = deliveryDateTime.toTimeString().slice(0, 5);
          } else {
            this._deliveryTime = null;
          }

          // for scheduled Departure Date 
          if (data.scheduledDepartureDate) {
            const scheduledDepartureDateTime = new Date(data.scheduledDepartureDate);
            this._scheduledDepartureDate = {
              year: scheduledDepartureDateTime.getFullYear(),
              month: scheduledDepartureDateTime.getMonth() + 1,
              day: scheduledDepartureDateTime.getDate()
            };
            this._scheduledDepartureTime = scheduledDepartureDateTime.toTimeString().slice(0, 5);
          } else {
            this._scheduledDepartureTime = null;
          }

          // for  Departure Date 
          if (data.departureDate) {
            const departureDateTime = new Date(data.departureDate);
            this._departureDate = {
              year: departureDateTime.getFullYear(),
              month: departureDateTime.getMonth() + 1,
              day: departureDateTime.getDate()
            };
            this._departureTime = departureDateTime.toTimeString().slice(0, 5);
          } else {
            this._departureTime = null;
          }

          // for scheduled Dispatch Date 
          if (data.scheduledDispatchDate) {
            const scheduledDispatchDateTime = new Date(data.scheduledDispatchDate);
            this._scheduledDispatchDate = {
              year: scheduledDispatchDateTime.getFullYear(),
              month: scheduledDispatchDateTime.getMonth() + 1,
              day: scheduledDispatchDateTime.getDate()
            };
            this._scheduledDispatchTime = scheduledDispatchDateTime.toTimeString().slice(0, 5);
          } else {
            this._scheduledDispatchTime = null;
          }

          // for Dispatch Date 
          if (data.dispatchDate) {
            const dispatchDateTime = new Date(data.dispatchDate);
            this._dispatchDate = {
              year: dispatchDateTime.getFullYear(),
              month: dispatchDateTime.getMonth() + 1,
              day: dispatchDateTime.getDate()
            };
            this._dispatchTime = dispatchDateTime.toTimeString().slice(0, 5);
          } else {
            this._dispatchTime = null;
          }

          // for scheduled Arrival Date 
          if (data.scheduledArrivalDate) {
            const scheduledArrivalDateTime = new Date(data.scheduledArrivalDate);
            this._scheduledArrivalDate = {
              year: scheduledArrivalDateTime.getFullYear(),
              month: scheduledArrivalDateTime.getMonth() + 1,
              day: scheduledArrivalDateTime.getDate()
            };
            this._scheduledArrivalTime = scheduledArrivalDateTime.toTimeString().slice(0, 5);
          } else {
            this._scheduledArrivalTime = null;
          }

          // for  Arrival Date 
          if (data.arrivalDate) {
            const arrivalDateTime = new Date(data.arrivalDate);
            this._arrivalDate = {
              year: arrivalDateTime.getFullYear(),
              month: arrivalDateTime.getMonth() + 1,
              day: arrivalDateTime.getDate()
            };
            this._arrivalTime = arrivalDateTime.toTimeString().slice(0, 5);
          } else {
            this._arrivalTime = null;
          }

          // for storage date
          if (data.storageDateFrom) {
            const storageDateTime = new Date(data.storageDateFrom);
            this._storageTime = storageDateTime.toTimeString().slice(0, 5);
          } else {
            this._storageTime = null;
          }


          this.onChange();
          if (this._moveDetail && this._moveDetail.addresses !== null) {
            const originAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            } else {
              this._originAddresses = []
              const newAddress: Address = { ...this._originAddresses[this.originActiveTabIndex] };
              newAddress.addressType = 0; // Set addressType to 0 for origin address

              // Reset the fields of the new address
              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._originAddresses.push(newAddress);
              this.originActiveTabIndex = this._originAddresses.length - 1;
              // this.addOriginTab(); // will not work here as we are doing this for diff move
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            } else {
              this._destinationAddresses = [];
              const newAddress: Address = { ...this._destinationAddresses[this.destActiveTabIndex] };
              newAddress.addressType = 1; // Set addressType to 1 for destination address

              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._destinationAddresses.push(newAddress);
              this.destActiveTabIndex = this._destinationAddresses.length - 1;
              // this.addDestTab();
            }
            if (this.generalService.formatDate(this._moveDetail.storageDateFrom, this._moveDetail.storageDateTo) === '----'
              || this.generalService.formatDate(this._moveDetail.storageDateFrom, this._moveDetail.storageDateTo) === null) {
              this.storageNeeded = false;
            }
            else {
              this.storageNeeded = true;
            }
            if (this._moveDetail.loadingDateFrom && this._moveDetail.deliveryDate === this._moveDetail.packagingDateFrom) {
              this.sameDayDelivery = true;
            }
            else {
              this.sameDayDelivery = false;
            }
          }
          this.identifyPortLabel();
        }
      },
      error: (error) => {
        console.error("error getting move detail by Id", error);
      }
    });
  }

  saveMoveDetail() {
    const companyId = Number(localStorage.getItem('companyId'));
    const currentUserId = Number(localStorage.getItem('currentUserId'));
    //this._moveDetail.id = this._selectedMoveId;
    // this._moveDetail.leadId = this._leadId;
    this._moveDetail.createdBy = currentUserId;
    this._moveDetail.ownedBy = companyId;

    //  for survey Date  
    if (this._moveDetail.surveyDate) {
      // Convert the string to a Date object
      const moveSurveyDate = new Date(this._moveDetail.surveyDate);
      const surveyDateTime = new Date(Date.UTC(moveSurveyDate.getFullYear(), moveSurveyDate.getMonth(), moveSurveyDate.getDate()));
      if (this._surveyTime) {
        const [hours, minutes] = this._surveyTime.split(':').map(Number);
        surveyDateTime.setUTCHours(hours);
        surveyDateTime.setUTCMinutes(minutes);
        this._moveDetail.surveyDate = surveyDateTime;
      }

    }

    //  for packaging Date 
    if (this._moveDetail.packagingDateFrom && this._moveDetail.packagingDateTo) {
      // Convert the strings to Date objects
      const packagingDateFrom = new Date(this._moveDetail.packagingDateFrom);
      const packagingDateTo = new Date(this._moveDetail.packagingDateTo);
      // Create the Date objects in UTC
      const packagingDateTimeFrom = new Date(Date.UTC(packagingDateFrom.getFullYear(), packagingDateFrom.getMonth(), packagingDateFrom.getDate()));
      const packagingDateTimeTo = new Date(Date.UTC(packagingDateTo.getFullYear(), packagingDateTo.getMonth(), packagingDateTo.getDate()));
      if (this._packingTime) {
        const [hours, minutes] = this._packingTime.split(':').map(Number);
        packagingDateTimeFrom.setUTCHours(hours);
        packagingDateTimeFrom.setUTCMinutes(minutes);
        packagingDateTimeTo.setUTCHours(hours);
        packagingDateTimeTo.setUTCMinutes(minutes);
        this._moveDetail.packagingDateFrom = packagingDateTimeFrom;
        this._moveDetail.packagingDateTo = packagingDateTimeTo;
      }

    }

    //  for loading Date           
    if (this._moveDetail.loadingDateFrom && this._moveDetail.loadingDateTo) {
      // Convert the strings to Date objects
      const loadingDateFrom = new Date(this._moveDetail.loadingDateFrom);
      const loadingDateTo = new Date(this._moveDetail.loadingDateTo);
      // Create the Date objects in UTC
      const loadingDateTimeFrom = new Date(Date.UTC(loadingDateFrom.getFullYear(), loadingDateFrom.getMonth(), loadingDateFrom.getDate()));
      const loadingDateTimeTo = new Date(Date.UTC(loadingDateTo.getFullYear(), loadingDateTo.getMonth(), loadingDateTo.getDate()));
      if (this._loadingTime) {
        const [hours, minutes] = this._loadingTime.split(':').map(Number);
        loadingDateTimeFrom.setUTCHours(hours);
        loadingDateTimeFrom.setUTCMinutes(minutes);
        loadingDateTimeTo.setUTCHours(hours);
        loadingDateTimeTo.setUTCMinutes(minutes);
        this._moveDetail.loadingDateFrom = loadingDateTimeFrom;
        this._moveDetail.loadingDateTo = loadingDateTimeTo;
      }

    }

    //  for delivery date
    if (this._moveDetail.deliveryDate) {
      // Convert the string to a Date object
      const moveDeliveryDate = new Date(this._moveDetail.deliveryDate);
      const deliveryDateTime = new Date(Date.UTC(moveDeliveryDate.getFullYear(), moveDeliveryDate.getMonth(), moveDeliveryDate.getDate()));
      if (this._deliveryTime) {
        const [hours, minutes] = this._deliveryTime.split(':').map(Number);
        deliveryDateTime.setUTCHours(hours);
        deliveryDateTime.setUTCMinutes(minutes);
        this._moveDetail.deliveryDate = deliveryDateTime;
      }

    }

    //  for scheduledDepartureDate
    if (this._moveDetail.scheduledDepartureDate) {
      // Convert the string to a Date object
      const scheduledDepartureDate = new Date(this._moveDetail.scheduledDepartureDate);
      // Create the Date object in UTC
      const scheduledDepartureDateTime = new Date(Date.UTC(scheduledDepartureDate.getFullYear(), scheduledDepartureDate.getMonth(), scheduledDepartureDate.getDate()));
      if (this._scheduledDepartureTime) {
        const [hours, minutes] = this._scheduledDepartureTime.split(':').map(Number);
        scheduledDepartureDateTime.setUTCHours(hours);
        scheduledDepartureDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.scheduledDepartureDate = scheduledDepartureDateTime;
    }

    //  for DepartureDate
    if (this._moveDetail.departureDate) {
      // Convert the string to a Date object
      const departureDate = new Date(this._moveDetail.departureDate);
      // Create the Date object in UTC
      const departureDateTime = new Date(Date.UTC(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate()));
      if (this._departureTime) {
        const [hours, minutes] = this._departureTime.split(':').map(Number);
        departureDateTime.setUTCHours(hours);
        departureDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.departureDate = departureDateTime;
    }

    //  for scheduledDispatchDate
    if (this._moveDetail.scheduledDispatchDate) {
      // Convert the string to a Date object
      const scheduledDispatchDate = new Date(this._moveDetail.scheduledDispatchDate);
      // Create the Date object in UTC
      const scheduledDispatchDateTime = new Date(Date.UTC(scheduledDispatchDate.getFullYear(), scheduledDispatchDate.getMonth(), scheduledDispatchDate.getDate()));
      if (this._scheduledDispatchTime) {
        const [hours, minutes] = this._scheduledDispatchTime.split(':').map(Number);
        scheduledDispatchDateTime.setUTCHours(hours);
        scheduledDispatchDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.scheduledDispatchDate = scheduledDispatchDateTime;
    }

    //  for DispatchDate
    if (this._moveDetail.dispatchDate) {
      // Convert the string to a Date object
      const dispatchDate = new Date(this._moveDetail.dispatchDate);
      // Create the Date object in UTC
      const dispatchDateTime = new Date(Date.UTC(dispatchDate.getFullYear(), dispatchDate.getMonth(), dispatchDate.getDate()));
      if (this._dispatchTime) {
        const [hours, minutes] = this._dispatchTime.split(':').map(Number);
        dispatchDateTime.setUTCHours(hours);
        dispatchDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.dispatchDate = dispatchDateTime;
    }

    //  for scheduledArrivalDate
    if (this._moveDetail.scheduledArrivalDate) {
      // Convert the string to a Date object
      const scheduledArrivalDate = new Date(this._moveDetail.scheduledArrivalDate);
      // Create the Date object in UTC
      const scheduledArrivalDateTime = new Date(Date.UTC(scheduledArrivalDate.getFullYear(), scheduledArrivalDate.getMonth(), scheduledArrivalDate.getDate()));
      if (this._scheduledArrivalTime) {
        const [hours, minutes] = this._scheduledArrivalTime.split(':').map(Number);
        scheduledArrivalDateTime.setUTCHours(hours);
        scheduledArrivalDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.scheduledArrivalDate = scheduledArrivalDateTime;
    }

    //  for ArrivalDate
    if (this._moveDetail.arrivalDate) {
      // Convert the string to a Date object
      const arrivalDate = new Date(this._moveDetail.arrivalDate);
      // Create the Date object in UTC
      const arrivalDateTime = new Date(Date.UTC(arrivalDate.getFullYear(), arrivalDate.getMonth(), arrivalDate.getDate()));
      if (this._arrivalTime) {
        const [hours, minutes] = this._arrivalTime.split(':').map(Number);
        arrivalDateTime.setUTCHours(hours);
        arrivalDateTime.setUTCMinutes(minutes);
      }
      this._moveDetail.arrivalDate = arrivalDateTime;
    }

    //  for storage Date           
    if (this._moveDetail.storageDateFrom && this._moveDetail.storageDateTo) {
      // Convert the strings to Date objects
      const storageDateFrom = new Date(this._moveDetail.storageDateFrom);
      const storageDateTo = new Date(this._moveDetail.storageDateTo);
      // Create the Date objects in UTC
      const storageDateTimeFrom = new Date(Date.UTC(storageDateFrom.getFullYear(), storageDateFrom.getMonth(), storageDateFrom.getDate()));
      const storageDateTimeTo = new Date(Date.UTC(storageDateTo.getFullYear(), storageDateTo.getMonth(), storageDateTo.getDate()));
      if (this._storageTime) {
        const [hours, minutes] = this._storageTime.split(':').map(Number);
        storageDateTimeFrom.setUTCHours(hours);
        storageDateTimeFrom.setUTCMinutes(minutes);
        storageDateTimeTo.setUTCHours(hours);
        storageDateTimeTo.setUTCMinutes(minutes);
      }
      this._moveDetail.storageDateFrom = storageDateTimeFrom;
      this._moveDetail.storageDateTo = storageDateTimeTo;
    }

    const allAddresses = [...this._originAddresses, ...this._destinationAddresses];
    this._moveDetail.addresses = allAddresses;

    // Check each address at index 0 for the presence of city
    for (const address of this._moveDetail.addresses) {
      if (!address.city) {
        // If city is missing, return or handle the error as needed
        alert("City is missing in an address.");
        return;
      }
      if (!address.country) {
        // If country is missing, return or handle the error as needed
        alert("Country is missing in an address.");
        return;
      }
      if (
        this._moveDetail.categoryType === eMoveCategoryType.DoorToDoorLocal &&
        this._originAddresses[0].country !== this._destinationAddresses[0].country
      ) {
        this._appComponent.showErrorDialog('Error', 'Origin and destination countries are not the same for DoorToDoorLocal move type.');
        return;
      }
      else if (
        this._moveDetail.categoryType !== eMoveCategoryType.DoorToDoorLocal &&
        this._originAddresses[0].country === this._destinationAddresses[0].country
      ) {
        this._appComponent.showErrorDialog('Error', 'Origin and destination countries are same but move type is not  DoorToDoorLocal.');
        return;
      }
    }

    if (this.storageNeeded) {
      // this._moveDetail.storageDateFrom = this.convertNgbDateToDate(this.fromDate2);
      // this._moveDetail.storageDateTo = this.convertNgbDateToDate(this.toDate2);
    } else {
      this._moveDetail.storageDateFrom = null;
      this._moveDetail.storageDateTo = null;
    }

    // to save delivery date
    if (this.sameDayDelivery) {
      if (this.fromDate !== null && this.toDate === null) {
        // this._moveDetail.deliveryDate = this.convertNgbDateToDate(this.fromDate);
        // this._moveDetail.loadingDateFrom = this.convertNgbDateToDate(this.fromDate);
      } else if (this.fromDate !== null && this.toDate !== null) {
        // this._moveDetail.deliveryDate = this.convertNgbDateToDate(this.toDate);
        // this._moveDetail.loadingDateTo = this.convertNgbDateToDate(this.toDate);
      }
    } else {
      // this._moveDetail.deliveryDate = this.convertNgbDateToDate(this._deliveryDate);
      // this._moveDetail.loadingDateFrom = this.convertNgbDateToDate(this.fromDate1);
      // this._moveDetail.loadingDateTo = this.convertNgbDateToDate(this.toDate1);

    }
    // console.log("save move detail ", this._moveDetail);


    // console.log("updating move detail", this._moveDetail);

    this._movdService.saveMoveDetail(this._moveDetail).subscribe({
      next: (response: any) => {
        // console.log("save move detail response", response);
        this.getMoveDetailById(this._moveDetail.id);
        let outputData = { version: 0 }
        this.version.emit(outputData)
        this._appComponent.showSuccessSwal("Move Detail", "saved");
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("Move Detail", "saved");
        console.error("error", error);
      }
    });
  }
  identifyPortLabel() {
    if (this._moveDetail.categoryType && this._moveDetail.transportType && this._moveDetail.categoryType === eMoveCategoryType.DoorToDoorExport || this._moveDetail.categoryType === eMoveCategoryType.DoorToPort) {
      if (this._moveDetail.transportType === eTransportType.Sea || this._moveDetail.transportType === eTransportType.SeaLCL) {
        this._portLabel = "Sea";
      } else if (this._moveDetail.transportType === eTransportType.Air) {
        this._portLabel = "Air";
      }
    }
  }

  checkContentDisable() {
    if (this._moveDetail && (!this._createPermission || !this._updatePermission) && (this._moveDetail.cancelled === true || this._moveDetail.completed === true)) {
      this._contentDisable = true;
      // console.log("content disabled", this._contentDisable);

    } else {
      this._contentDisable = false;
      // console.log("content disabled", this._contentDisable);

    }
  }

}
