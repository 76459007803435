import { eMoveCategoryType, eTransportType } from "./lead-detail.model";

export class StatusWithMoveStatus {
  refId: number;
  statusId: any;
  statusTitle: string;
  moveStatusId: any;
  moveStatusDate: any;
  moveStatusDone: boolean;
  showDatePicker: boolean; // Add this property
  order: number;
  isSaving: boolean;
  isDeleted: boolean;

  constructor() {
    this.refId = 0;
    this.statusId = 0;
    this.statusTitle = '';
    this.moveStatusId = 0;
    this.moveStatusDate = new Date();
    this.moveStatusDone = true;
    this.showDatePicker = false; // Initialize it to false by default
    this.order = 0;
    this.isSaving = false;
    this.isDeleted = false;
  }
}


export class MoveStatus {
  id: number | null;
  refId: number;
  statusId: number | null;
  date: Date | null;
  done: boolean | null;
  category: eMoveCategoryType;
  transportType: eTransportType;
  createdBy: number;
  isDeleted: boolean;

  constructor() {
    this.id = null;
    this.refId = 0;
    this.statusId = null;
    this.date = null;
    this.done = null;
    this.category = eMoveCategoryType.DoorToDoorLocal;
    this.transportType = eTransportType.Sea;
    this.createdBy = 0;
    this.isDeleted = false;
  }
}

