<ng-template #datePickerModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Select a Date</h4>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input class="form-control clickable" name="dp" ngbDatepicker #d="ngbDatepicker"
        placeholder="Select a date" [value]="formatDate1()" (click)="d.toggle()"
        (dateSelect)="onDateSelectStatus($event); d.close()" readonly />
      <button class="input-group-text" type="button" title="date-range" (click)="d.toggle()">
        <i class="feather icon-calendar icon-md text-muted"></i>
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm"
      (click)="saveDateAndStatus(selectedStatus); modal.close('by: save button')"
      [disabled]="!isDateSelected">
      <i class="mdi mdi-content-save"></i> Save
    </button>

    <button type="button" class="btn btn-danger btn-sm" (click)="cancelClick(datePickerModal)">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<div class="container">
  <div class="row">
    <div class="col-md-12 mt-3">
      <h5>STATUS & REQUIREMENTS</h5>

      <ng-container *ngIf="!_isDataLoaded">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="loader-overlay">
          <div id="loader">
            <div class="spinner"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="_isDataLoaded">
        <div class="row" *ngFor="let status of _statusData; let last = last">
          <div class="col-md-2">
            <input type="checkbox" class="form-check-input m-2" [checked]="status.moveStatusDone"
              [disabled]="_leadDetail.markLost || status.isSaving || _createPermission === false || contentDisabled === true"
              (change)="updateStatus(status)" />
            <p class="mt-0 m-3 mb-0 vertical-line" *ngIf="!last"><br /></p>
          </div>
          <div class="col-md-10">
            <label class="mt-1" style="margin-bottom: 0">{{
              status.statusTitle
              }}</label>
            <br />
            <small class="text-muted mt-2">{{ status.moveStatusDate | date : "MMM d, yyyy"}}</small>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="row">
    <div class="col-md-12 mt-3">
      <p>No data added by admin</p>
    </div>
  </div>
</ng-template>
<!-- || status.statusTitle === 'Lead Closed' -->