<ng-template #addPackingMaterialModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">
      <i class="mdi mdi-account-plus"> </i> &nbsp; Add Packing Material
    </h5>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_packingMaterial.name" class="col-form-label">Name</label>
      </div>
      <div class="col-md-9">
        <input type="text" class="form-control" id="_packingMaterial.name" autocomplete="on"
          placeholder="Enter Full Name" [(ngModel)]="_packingMaterial.name" required />
        <div *ngIf="!_packingMaterial.name" class="error-message error" style="color: red;">*
          required</div>
      </div>

    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="selectedMaterialType" class="col-form-label"> Type</label>
      </div>
      <div class="col-md-9">
        <ng-select [items]="_ePackingMaterialTypes" bindLabel="value" bindValue="id" [searchable]="true"
          placeholder="Select Packing Material Type" [(ngModel)]="_packingMaterial.type">
        </ng-select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_packingMaterial.weight" class="col-form-label">Weight</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_packingMaterial.weight" id="_packingMaterial.weight"
          placeholder="Enter Weight" required />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_packingMaterial.volume" class="form-label">Volume</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_packingMaterial.volume" id="_packingMaterial.volume"
          autocomplete="on" placeholder="Enter Volume" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_packingMaterial.quantity" class="form-label">Quantity</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_packingMaterial.quantity" id="_packingMaterial.quantity"
          autocomplete="on" placeholder="Enter Quantity" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <label for="_packingMaterial.cost" class="form-label">Cost</label>
      </div>
      <div class="col-md-9">
        <input type="number" class="form-control" [(ngModel)]="_packingMaterial.cost" id="_packingMaterial.cost"
          autocomplete="on" placeholder="Enter Cost" />
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="savePackingMaterial()">
      <i class="mdi mdi-content-save"> </i> &nbsp; Save
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<ng-container>
  <div class="card rounded">
    <div class="card-header">
      <div class="row">
        <div class="col-md-3 col-sm-auto align-right">
          <input id="table-complete-search" type="text" class="form-control" name="searchTerm" placeholder="Search..."
            [(ngModel)]="searchTerm" (keyup)="performSearch()" />
        </div>
        <div class="col-md-4 col-md-auto"></div>
        <div class="col-md-5 col-md-auto">
          <button class="btn btn-xs btn-primary btn-icon-text float-end" (click)="openAddModal(addPackingMaterialModal)"
            style="margin-left:3px;width: 170px;">
            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
              colors="primary:#000000,secondary:#ebe6ef" style="width:20px;height:20px">
            </lord-icon>
            &nbsp; Add Packing Material
          </button>
        </div>
      </div>
      &nbsp;
      <div class="row" *ngIf="_packingMaterialTableData.length === 0">
        <div class="col-md-12" style="align-self: auto">
          <div class="card">
            <div class="card-body text-center">
              <p>No Packing Material, currently. Please Add a Packing Material.</p>
            </div>
          </div>
        </div>
      </div>
      &nbsp;

      <div class="row" *ngIf="_packingMaterialTableData.length > 0">
        <div class="col-md-12 stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <ngx-datatable [rows]="_packingMaterialTableData" class="bootstrap" [loadingIndicator]="true"
                  [columnMode]="ColumnMode.force" [footerHeight]="40" [limit]="10" [pagination]="true" [pageSize]="10"
                  [rowHeight]="40">
                  <ngx-datatable-column *ngFor="let column of _packingMaterialTableColumns" [name]="column.header">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">

                      <ng-container *ngIf="column.header === 'Name'">
                        <div class="d-flex align-items-center hover" (mouseenter)="onRowMouseEnter(row)"
                          (mouseleave)="onRowMouseLeave(row)">
                          {{ row.name | slice:0:15 }}
                          <button class="btn btn-secondary button-wrapper btn-icon-text
                        btn-small-transparent btn-smaller" *ngIf="row.isHovered" (click)="previewClick(row)">
                            Preview
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="column.header === 'Type'">
                        {{ enumMappingService.mapMaterialType(row.type) || '-' }}
                      </ng-container>
                      <ng-container *ngIf="column.header !== 'Name' && column.header !== 'Type'">
                        {{ value || '-'}}
                      </ng-container>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Action">
                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value">
                      <button type="button" class="btn btn-xs datatable-action-cell" [class.btn-success]="!row.isActive"
                        [class.btn-danger]="row.isActive" (click)="updatePackingMaterialStatus(row, row.isActive)">
                        {{ !row.isActive ? "Activate" : "Deactivate" }}
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-xs btn-danger datatable-action-cell ml-2"
                        (click)="updatePackingMaterialStatus(row, row.isActive, true)">
                        Delete
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>