<ng-template #editModal let-modal>
  <!-- Edit Modal -->
  <!--<div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">-->
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Edit Shipper Info</h5>
      <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>-->
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="customer">Customer</label>
        <input type="text" class="form-control" id="customer" [(ngModel)]="editedLead.customerName">
      </div>
      <div class="form-group">
        <label for="customerId">Account ID</label>
        <input type="text" class="form-control" id="customerId" [(ngModel)]="editedLead.accountId"
          [readonly]="editMode">
      </div>

      <!-- Add more form fields for other properties -->

      <!--<div class="form-group">
        <label for="account">Account</label>
        <input type="text" class="form-control" id="account" [(ngModel)]="editedLead.account">
      </div>-->
      <div class="form-group">
        <label for="leadDetailId">Move ID</label>
        <input type="text" class="form-control" id="leadDetailId"
          [(ngModel)]="editedLead.leadDetailId" [readonly]="editMode">
      </div>

      <div class="form-group">
        <label for="phone">Phone</label>
        <input type="text" class="form-control" id="phone" [(ngModel)]="editedLead.phone">
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" [(ngModel)]="editedLead.email">
      </div>
      <div class="form-group">
        <label for="accounts">Account</label>
        <input type="text" class="form-control" id="accounts" [(ngModel)]="editedLead.accountName">
      </div>
      <div class="form-group">
        <label for="poc">POC</label>
        <input type="text" class="form-control" id="poc" [(ngModel)]="editedLead.pocId">
      </div>
      <div class="form-group">
        <label for="thirdParty">Third Party</label>
        <input type="text" class="form-control" id="thirdParty"
          [(ngModel)]="editedLead.thirdPartyId">
      </div>
      <div class="form-group">
        <label for="pocone">POC</label>
        <input type="text" class="form-control" id="pocone"
          [(ngModel)]="editedLead.thirdPartyPocId">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveChanges()">Save</button>
    </div>
  </div>
  <!--</div>
  </div>-->

</ng-template>

<div class="lead-details-header">

  <div class="col-md-10 d-flex justify-content-between">
    <!-- <h4>LEAD DETAIL</h4>
     <div class="d-flex align-items-end">
      <button class="btn btn-primary me-3 white-text-button">INITIATE MOVE </button>
      <button class="btn btn-primary me-3 white-text-button">MARK LOST</button>
      <button class="btn btn-primary white-text-button">CLOSE</button>
    </div> -->
  </div>
</div>




<div class="container">
  <div class="row">
    <div class="col-md-6">

      <div class="box-left border border-primary p-3 custom-rounded custom-height">
        <h5>SHIPPER INFO</h5>
        <div class="col-md-12 text-end">
          <button class="btn btn-link bordered-button" (click)="openEdit(editModal)">EDIT</button>
        </div>



        <div class="row ">
          <div class="col-md-5">
            <label><strong>CUSTOMER: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.firstName }}</label>
          </div>
        </div>

        <!--<div class="row mt-2">
          <div class="col-md-5">
            <label><strong>CUSTOMER ID: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.customerId }}</label>
          </div>
        </div>-->

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>ACCOUNT ID: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.accountId }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>MOVE ID: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.leadDetailId }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>PHONE: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.phone }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>EMAIL: </strong></label>
          </div>

          <div class="col-md-6">
            <label>{{ lead.email }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>ACCOUNT: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.accountName }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>POC: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.pocId }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>THIRD PARTY: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.thirdPartyId }}</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>POC: </strong></label>
          </div>
          <div class="col-md-6">
            <label>{{ lead.thirdPartyPocId }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="box-left border border-primary p-3 custom-rounded custom-height">
        <h5>CONSIGNEE INFO</h5>
        <div class="row mt-4">
          <div class="col-md-5">
            <label><strong>CUSTOMER: </strong></label>
          </div>
          <div class="col-md-6">
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>ACCOUNT ID: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <!--<div class="row mt-2">
          <div class="col-md-5">
            <label><strong>ACCOUNT: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>-->

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>MOVE ID: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>PHONE: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>EMAIL: </strong></label>
          </div>

          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>ACCOUNT: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>POC: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>THIRD PARTY: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-5">
            <label><strong>POC: </strong></label>
          </div>
          <div class="col-md-6">

          </div>
        </div>

      </div>



    </div>
  </div>


  <div class="row mt-3">
    <div class="col-md-12">
      <div class="box-left border border-primary p-3 custom-rounded">
        <div class="col-md-12 d-flex justify-content-between">
          <h5>NOTIFY PARTY</h5>
          <div class="d-flex align-items-end">
            <!-- <button class="btn btn-primary me-3 white-text-button">INITIATE MOVE</button>
            <button class="btn btn-primary me-3 white-text-button">MARK LOST</button>
            <button class="btn btn-primary white-text-button">CLOSE</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>