export class LeadsSummarizeReportDTO {
  date: Date | null;
  new: number | null;
  inProgress: number | null;
  won: number | null;
  lost: number | null;
  totalLeads: number | null;

  constructor() {
    this.date = null;
    this.new = null;
    this.inProgress = null;
    this.won = null;
    this.lost = null;
    this.totalLeads = null;
  }
}