import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { MovdService } from '../../services/movd.service';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MoveCategoryTypeOptions, MoveTypeOptions, ProcessStatusOptions, TransportTypeOptions, eProcessStatus } from '../../models/lead-detail.model';
import { ContactService } from 'src/services/contact.service';
import { MoveSurvey } from '../../models/move-survery.model';
import { GeneralService } from 'src/services/general.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import { AppComponent } from 'src/app/app.component';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { allSurveyColumns, LeadBoardDTO, LeadBoardResponse } from '../../lead-board/lead-board.model';
import { eContactType } from 'src/app/views/crm/contact/contact.models';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent implements OnInit {

  columnMode: ColumnMode = ColumnMode.force;
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  columnToggleSubject = new Subject<void>();

  loadingIndicator = true;
  _displayedLeadsList: any[] = [];
  tableColumns: any[] = [];
  _allLeads = new LeadBoardDTO();
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  _selectedStatus: string = 'All';
  _allLeadsList: LeadBoardDTO[] = [];
  _companyId: number;
  defaultColumns: string[] = ['Survey ID', 'Lead ID', 'Account', 'Customer', 'Origin', 'Destination'];
  _selectedColumns: { [key: string]: boolean } = {};
  _allColumns: string[] = allSurveyColumns;
  _contacts: any = [];
  _companies: any = [];

  _selectedTab: string = 'All';

  _moveSurvey = new MoveSurvey();
  _userRole: string | null = null;
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _crmContactReadPermission = false;
  _leadInfoReadPermission = false;

  _eProcessStatusOption = ProcessStatusOptions;

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _searchTerm = '';

  constructor(
    private _router: Router,
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _contactService: ContactService,
    public generalService: GeneralService,
    private _authService: AuthService,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService,

  ) {
    this._userRole = localStorage.getItem('role');
    this._companyId = Number(localStorage.getItem('companyId'));

    this.columnToggleSubject.pipe(
      debounceTime(10) // Adjust this value as needed toggleColumnVisibility() will only be called once per 20 milliseconds 
    ).subscribe(() => this.toggleColumnVisibility());
  }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Survey List', url: '/survey-list' },
    ]);

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Survey Form.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Survey Form.Create');
    this._updatePermission = permissions.includes('Survey Form.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Survey Form.Delete');
    this._crmContactReadPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Info.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Survey.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Quotation.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Messages.Read') || this._userRole === 'CompanyAdmin';
    this._leadInfoReadPermission = permissions.includes('Lead Info Form - Summary.Read') || this._userRole === 'CompanyAdmin';

    this._companyId = Number(localStorage.getItem('companyId'));

    // Load selected columns from localStorage or initialize with defaults
    const storedSelectedColumns = localStorage.getItem('selectedColumns');
    if (storedSelectedColumns) {
      this._selectedColumns = JSON.parse(storedSelectedColumns);
    } else {
      for (const column of this.defaultColumns) {
        this._selectedColumns[column] = true;
      }
    }

    // Set up tableColumns based on selected columns
    this.tableColumns = this.defaultColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));

    // Continue with the rest of your code...
    if (this._companyId !== 0) {
      // console.log("com", this._companyId);
      this.getLeadsForBoardById();
    }
    this.getCustomers();
    // console.log('Table Coumn', this.tableColumns);
    // console.log('All Column', this._allColumns);
  }


  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        if (response.contacts && response.contacts.length > 0) {
          this._contacts = response.contacts.filter((item: { contactType: number; }) => item.contactType === eContactType.Contact);
          this._companies = response.contacts.filter((item: { contactType: number; }) => item.contactType === eContactType.Company);
          // console.log("_contacts", this._contacts);
          // console.log("_companies", this._companies);
        }
      },
      error: (error: any) => {
        console.error("error getting contacts", error);
      }
    });
  }

  getMoveTypeLabel(value: any) {
    return this._moveType.find(option => option.id === value)?.value;
  }

  getMoveCategoryLabel(value: any) {
    return this._moveCategory.find(option => option.id === value)?.value;
  }

  getTransportTypeLabel(value: any) {
    return this._transportType.find(option => option.id === value)?.value;
  }

  onClickStatus(label: string) {
    this._selectedStatus = label;
    switch (label) {
      case 'All':
        this._selectedTab = 'All';
        this._displayedLeadsList = this._allLeadsList;
        break;

      case 'New':
        this._selectedTab = 'New';
        let newLeads = this._allLeadsList.filter(lead => (lead.surveyStatus === eProcessStatus.New));
        this._displayedLeadsList = newLeads;
        break;

      case 'InProgress':
        this._selectedTab = 'InProgress';
        let inProgressLeads = this._allLeadsList.filter(lead => (lead.surveyStatus === eProcessStatus.InProgress));
        this._displayedLeadsList = inProgressLeads;
        break;

      case 'Completed':
        this._selectedTab = 'Completed';
        let completedSurveys = this._allLeadsList.filter(lead => (lead.surveyStatus === eProcessStatus.Completed));
        this._displayedLeadsList = completedSurveys;
        break;

      default:
        // Handle the case when the label is not recognized
        break;
    }

  }

  onInputChange(event: any) {
    // console.log("on input change event", event);
    // console.log("on input change event.data", event.data);

    if (event && event.data === null) {
      // console.log("condition checked", event && event.data === null);
      this.getLeadsForBoardById();
    }
  }

  onSearch(): void {
    this.getLeadsForBoardById();
  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.getLeadsForBoardById();
  }

  getLeadsForBoardById() {
    this._movdService.getLeadsForBoard(this._currentPage, this._pageSize, this._searchTerm, true).subscribe({
      next: (data: LeadBoardResponse) => {
        console.log("all leads", data);
        if (data.leads && data.leads.length > 0) {
          this._totalRecords = data.totalRecords;

          var leadBoardDTO = new LeadBoardDTO();

          this._allLeadsList = data.leads;
          this._displayedLeadsList = data.leads;
          // Update tableColumns to include new combined date columns
          this.tableColumns = Object.keys(leadBoardDTO)
            .filter((key) => key !== 'leadId'
              && key !== 'lastName'
              && key !== 'phone'
              && key !== 'email'
              && key !== 'pocId'
              && key !== 'accountId'
              && key !== 'accountName'
              && key !== 'thirdPartyId'
              && key !== 'thirdPartyPocId'
              && key !== 'accountManagerId'
              && key !== 'creationDate'
              && key !== 'status'
            )
            .map((key) => {
              let header: string;
              let prop: string = key;
              switch (key) {
                case 'surveyId':
                  header = 'Survey Id';
                  break;
                case 'leadDetailId':
                  header = 'Lead Id';
                  break;
                case 'account':
                  header = 'Account';
                  break;
                case 'customerName':
                  header = 'Customer';
                  break;
                case 'surveyStatus':
                  header = 'Status';
                  break;
                case 'origin':
                  header = 'Origin';
                  break;
                case 'destination':
                  header = 'Destination';
                  break;
                case 'moveType':
                  header = 'Move';
                  break;
                case 'moveCategory':
                  header = 'Move Category';
                  break;
                case 'moveTransport':
                  header = 'Transport Type';
                  break;
                case 'portOfLoading':
                  header = 'POL';
                  break;
                case 'portOfDischarge':
                  header = 'POD';
                  break;
                case 'placeOfDelivery':
                  header = 'Place Of Delivery';
                  break;
                case 'pocName':
                  header = 'POC';
                  break;
                case 'thirdPartyName':
                  header = 'Third Party';
                  break;
                case 'thirdPartyPocName':
                  header = 'Third Party POC';
                  break;
                case 'packagingDate':
                  header = 'Pack Date';
                  break;
                case 'loadingDate':
                  header = 'Loading Date';
                  break;
                case 'storageDate':
                  header = 'Storage Date';
                  break;
                case 'warehouseStorage':
                  header = 'Warehouse Storage';
                  break;
                case 'deliveryDate':
                  header = 'Delivery Date';
                  break;
                case 'accountManager':
                  header = 'Account Manager';
                  break;
                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }
              return { header, prop };
            });

          this.toggleColumnVisibility();
        }
      },
      error: (error) => {
        console.error("error getting leads for board", error);
      }
    });
  }
  openColumnModal(content: TemplateRef<any>) {
    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }
  toggleColumnVisibility() {
    // console.log('_selectedColumns:', this._selectedColumns);
    this.tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));
    // console.log('tableColumns:', this.tableColumns);
    // localStorage.setItem('selectedColumns', JSON.stringify(this._selectedColumns));
    localStorage.setItem('selectedColumns', JSON.stringify(this._selectedColumns));
    localStorage.setItem('selectedColumnsForThisSection', JSON.stringify(this._selectedColumns));
  }

  async navigateToAddSurvey(leadBoardDTO: LeadBoardDTO) {
    // Navigate to the 'inventory' route
    // const leadDetailId = this._allLeads.leadDetailId;
    // const leadId = this._allLeads.leadId;
    // // localStorage.setItem('leadId', this._allLeads.leadId.toString());
    // localStorage.setItem('moveDetailId', moveDetailId.toString());

    if (leadBoardDTO.surveyStatus === eProcessStatus.New) {
      let text = 'You are about to start the survey process.';
      let confirmButtonText = 'Yes, please start!';

      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        text,
        confirmButtonText
      );

      if (await confirmed === true) {
        this.saveSurveyStatus(leadBoardDTO.leadDetailId);
      }
      else {
        return;
      }
    }
    const leadDetailId = leadBoardDTO.leadDetailId;
    const leadId = leadBoardDTO.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false

    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Survey List', url: '/survey-list' },
      { text: 'Survey Form', url: '' },
    ]);
    this._router.navigate(['/add-survey'], navigationExtras);


  }

  saveSurveyStatus(leadDetailId: number) {
    this._movdService.saveSurveyStatus(leadDetailId, eProcessStatus.InProgress).subscribe({
      next: (response: any) => {
        // console.log("saved survey status resonse", response);

      },
      error: (error) => {
        console.error("error saving survey status", error);
      }
    });
  }

  leadIdClick(data: any) {

    if (this._leadInfoReadPermission === false && this._userRole !== 'CompanyAdmin') {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the lead info form', '');
      return;
    }
    this._allLeads = data;
    const leadDetailId = this._allLeads.leadDetailId;
    const leadId = this._allLeads.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Survey List', url: '/survey-list' },
      { text: 'Lead Info', url: '/lead-detail' },
    ]);

    // Navigate to lead-detail
    this._router.navigate(['/lead-detail'], navigationExtras)
  }
  returnLeadUrl(row: any) {

    const leadDetailId = row.leadDetailId;
    const leadId = row.leadId;
    return { leadDetailId, leadId };
  }

  getMoveSurveys(moveDetailId: number) {
    this._movdService.getMoveSurvey(moveDetailId).subscribe({
      next: (data: any) => {
        if (data) {
          this._moveSurvey = data;
        } else {
          this._moveSurvey = new MoveSurvey();
        }
      },
      error: (error) => {
        console.error("error getting move survey", error);
      }
    });
  }

  redirectToAccount(id: any) {
    if (this._crmContactReadPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the profile', '');
      return;
    }
    // const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Survey List', url: '/survey-list' },
      { text: 'Profile', url: '' },
    ]);
    this._router.navigate(['/profile'], navigationExtras);
  }

  returnSurveyUrl(row: any) {
    const leadDetailId = row.leadDetailId;
    const leadId = row.leadId;
    return { leadDetailId, leadId };
  }

  surveyIdClick(data: any) {
    if (this._readPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit Survey Form', '');
      return;
    }
    // console.log("movePreviewClick", data);
    if (!this._readPermission) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the Survey Form', '');
      return;
    }
    // this.moveInfoClick();
    let leadDetailId = data.leadDetailId;
    let leadId = data.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this._router.navigate(['/add-survey'], navigationExtras);

  }
}
