import { Component, Input, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import { ContactService } from 'src/services/contact.service';
import { CallsModel, EmailsModel, MeetingsModel } from '../../crm/profile/profile.models';
import { Observable } from 'rxjs';
import { MovdService } from '../services/movd.service';
import { Console } from 'console';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent {
  @Input() leadDetailId: any;
  @Input() customerId: any;
  @Input() contentDisabled = false;

  // variables
  defaultNavActiveId: number = 1;

  // _leadDetailId: number = 0;
  _viewname = '';
  _dealId = 0;
  _currentUserId = 0;
  _isdeal: boolean = false;
  _isMove: boolean = true;
  _role: string = "";
  _lastActivityDate: any;
  [key: string]: any;

  // Objects
  _call = new CallsModel();
  _email = new EmailsModel();
  _meeting = new MeetingsModel();;

  // Lists
  _callsList: any[] = [];
  _emailsList: any[] = [];
  _meetingsList: any[] = [];


  // for showing current time
  selectedDate: string | null = null;
  selectedTime: string | null = null;
  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;

  _leadInfoReadPermission = false;
  _crmContactReadPermission = false;
  _userRole: string | null = null;



  constructor(
    private _contactService: ContactService,
    private _movdService: MovdService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _appComponent: AppComponent) {
    const now = new Date();
    this.selectedDate = this.formatDate(now);
    this.selectedTime = this.formatTime(now);
    this._userRole = localStorage.getItem('role');



  }

  ngOnInit(): void {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Lead Info Form - Messages.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Lead Info Form - Messages.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Lead Info Form - Messages.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Lead Info Form - Messages.Delete') || this._userRole === 'CompanyAdmin';

    const currentUserId = localStorage.getItem('currentUserId');
    this._currentUserId = Number(currentUserId);


  }
  // ngOnInit(): void {
  //   this.fetchData('Email')
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leadDetailId']) {
      this.leadDetailId = changes['leadDetailId'].currentValue;
      if (this.leadDetailId && this.leadDetailId !== 0) {
        this.fetchData('Email')
      }
    }
    if (changes['customerId']) {
      this.customerId = changes['customerId'].currentValue;
    }

    if (changes['contentDisabled']) {
      this.contentDisabled = changes['contentDisabled'].currentValue;
      // console.log("disabled", changes['disabled']);
    }
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  private formatTime(date: Date): string {
    let hours = date.getHours();
    const minutes = this.padZero(date.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${this.padZero(hours)}:${minutes}`;
    return `${formattedTime} ${ampm}`;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }

  async fetchCalls(): Promise<void> {
    await this.fetchData('Call');
  }

  async fetchEmails(): Promise<void> {
    await this.fetchData('Email');
  }

  async fetchMeetings(): Promise<void> {
    await this.fetchData('Meeting');
  }

  async fetchData(type: string): Promise<void> {
    // console.log('fetching');
    try {
      let data: any;
      switch (type) {
        case 'Call':
        case 'CallsModel':
          data = await this._movdService.getCustomerCalls(this.leadDetailId, this._isdeal, this._isMove).toPromise();
          this._callsList = data;
          break;
        case 'Email':
        case 'EmailsModel':
          data = await this._movdService.getCustomerEmails(this.leadDetailId, this._isdeal, this._isMove).toPromise();
          this._emailsList = data;
          break;
        case 'Meeting':
        case 'MeetingsModel':
          data = await this._movdService.getMeetings(this.leadDetailId, this._isdeal, this._isMove).toPromise();
          this._meetingsList = data;
          break;
        default:
          break;
      }

      if (data && data.length > 0) {
        const listName = `_${type.toLowerCase()}sList`; // Derive the list name dynamically
        this[listName] = data;

        this[listName].sort((a: { activityDate: Date }, b: { activityDate: Date }) =>
          new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime()
        );
        const mostRecentData = data.length === 1 ? data[0] : data[data.length - 1];

        if (mostRecentData) {
          mostRecentData.timeAgo = this.calculateActivityTime(mostRecentData.activityDate);
        }
      }
    } catch (error) {
      console.error(`Error fetching customer ${type}s:`, error);
    }
  }

  calculateActivityTime(activityDate: string): string {
    const currentTime = new Date(); // Get the current time
    const activityTime = new Date(activityDate); // Convert the activityDate to a Date object
    const timeDiff = currentTime.getTime() - activityTime.getTime(); // Calculate the time difference in milliseconds

    // Convert the time difference to minutes ago, hours ago, or days ago
    if (timeDiff < 60000) { // Less than 1 minute
      return 'Just Now ';
    }
    else if (timeDiff < 3600000) { // Less than 1 hour
      return Math.floor(timeDiff / 60000) + ' minutes ago';
    }
    else if (timeDiff < 86400000) { // Less than 1 day
      const hours = Math.floor(timeDiff / 3600000);
      return hours === 1 ? '1 hour ago' : hours + ' hours ago';
    }
    else { // More than 1 day
      const days = Math.floor(timeDiff / 86400000);
      return days === 1 ? '1 day ago' : days + ' days ago';
    }
  }
  async deleteCall(id: any) {
    await this.confirmAndDelete("Call", id);
  }

  async deleteEmail(id: any) {
    await this.confirmAndDelete("Email", id);
  }

  async deleteMeeting(id: any) {
    await this.confirmAndDelete("Meeting", id);
  }

  async confirmAndDelete(type: string, id: any) {
    const confirmation = confirm(`Are you sure you want to delete this ${type}?`);

    if (!confirmation) {
      return;
    }

    try {
      switch (type) {
        case 'Call':
          await this._contactService.deleteCustomerCall(id).toPromise();
          break;
        case 'Email':
          await this._contactService.deleteCustomerEmail(id).toPromise();
          break;
        case 'Meeting':
          await this._contactService.deleteMeeting(id).toPromise();
          break;
        default:
          break;
      }

    } catch (error: any) {
      if (error.status === 200) {
        this._appComponent.showSuccessSwal("Deleted", type);
        if (type === 'Call') {
          await this.fetchCalls();
        } else if (type === 'Email') {
          await this.fetchEmails();
        } else if (type === 'Meeting') {
          await this.fetchMeetings();
        }
      }
      else {
        this._appComponent.showErrorSwal("Deleting", type);
      }
    }
  }

  // open modals
  openEmailsModel(content: TemplateRef<any>, id = 0) {
    this._email = new EmailsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.openModal(content, "Email", id);
  }

  openCallsModel(content: TemplateRef<any>, id = 0) {
    this._call = new CallsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    this.openModal(content, "Call", id);
  }

  openMeetingsModel(content: TemplateRef<any>, id = 0) {
    this._meeting = new MeetingsModel();
    this.selectedDate = this.formatDate(new Date());
    this.selectedTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    this.openModal(content, "Meeting", id);
  }

  openModal(content: TemplateRef<any>, title: string, id: number) {

    //console.log("id", id);

    if (id === 0) {
      title = "Add " + title;
    } else {
      title = "Update " + title;
    }

    this._modalService.open(content, {}).result.then((result) => {
      // Handle the result if needed
    }).catch((res) => { });
  }

  // save data
  saveData(entity: any, entityType: string) {
    const isAdding = entity.id === 0;

    entity.leadDetailId = this.leadDetailId;

    entity.createdBy = this._currentUserId;
    // console.log("createred by", entity.createdBy)
    const activityDate = `${this.selectedDate}T${this.selectedTime}:00.000Z`;
    entity.activityDate = activityDate;
    entity.customerId = this.customerId;

    let observable: Observable<any> | null = null;;

    if (entityType === 'Email') {
      observable = isAdding ? this._contactService.addCustomerEmail(entity) :
        this._contactService.updateCustomerEmail(entity);
    } else if (entityType === 'Call') {
      observable = isAdding ? this._contactService.addCustomerCall(entity) :
        this._contactService.updateCustomerCall(entity);
    } else if (entityType === 'Meeting') {
      observable = isAdding ? this._contactService.addMeeting(entity) :
        this._contactService.updateMeeting(entity);
    }
    //console.log("observable", observable);
    if (observable) {
      observable.subscribe(
        (response: any) => {
          //console.log("response", response);
          const entityName = entityType.slice(0, -5);

          if (response && response.status !== 200) {
            this._appComponent.showErrorSwal(isAdding ? 'saving' : 'updating', entityType);
          } else {
            this.fetchData(entityType);
            this._modalService.dismissAll('by: calling closeModal()');
            this._appComponent.showSuccessSwal(isAdding ? 'saved' : 'updated', entityType);
          }
        },
        (error: any) => {
          console.error("Error:", error);
          // Handle errors as needed
        }
      );
    }
  }

  saveEmail() {
    if (this._email.details.trim().length === 0) {
      return;
    }
    this.saveData(this._email, 'Email');
  }

  saveCall() {
    if (this._call.details.trim().length === 0) {
      return;
    }

    this._call.date = this.selectedDate; // Adding date to the _call object
    this._call.time = this.selectedTime; // Adding time to the _call object



    this.saveData(this._call, 'Call');
  }

  saveMeeting() {
    if (this._meeting.details.trim().length === 0) {
      return;
    }
    this.saveData(this._meeting, 'Meeting');
  }

  editEmail(dataItem: any) {
    this._email.details = dataItem.details;
    this._email.from = dataItem.from;
    this._email.to = dataItem.to;
    this._email.activityDate = dataItem.activityDate;
    this._email.id = dataItem.id;
  }

  editCall(dataItem: any) {
    this._call.details = dataItem.details;
    this._call.activityDate = dataItem.activityDate;
    this._call.id = dataItem.id;
    this._call.outcome = dataItem.outcome;
    this._call.direction = dataItem.direction;
  }

  editMeeting(dataItem: any) {
    this._meeting.id = dataItem.id;
    this._meeting.title = dataItem.title;
    this._meeting.details = dataItem.details;
    this._meeting.activityDate = dataItem.activityDate;
    this._meeting.organizer = dataItem.organizer;
    this._meeting.location = dataItem.location;
    this._meeting.startDate = dataItem.startDate;
    this._meeting.endDate = dataItem.endDate;
    this._meeting.attendance = dataItem.attendance;
    this._meeting.outcome = dataItem.outcome;

  }

  ngOnDestroy() {

  }
}
