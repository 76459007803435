import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';
import { AuthServicePaths } from '../enums/auth-service-paths';
import { GetCompaniesModel } from '../app/views/admin/super-admin/super-admin.models';
import { tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { Permission, PermissionDTO } from '../app/views/movd/models/permission.model';
import { RolePermission } from '../app/views/movd/models/role-permission.model';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
export interface LoginResponse {
  token: string;
  user: any; // Replace 'any' with the type of your user object
  permissions: any;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  readonly APIUrl = environment.baseUrl;
  private companyId = "0";

  constructor(private http: HttpClient, private _router: Router, private _route: ActivatedRoute) {
    this.companyId = localStorage.getItem("companyId") ?? "0";
  }

  register(user: any): Observable<any> {
    return this.http.post(this.APIUrl + AuthServicePaths.register, user);
  }

  login(user: any): Observable<LoginResponse> {
    // console.log("login user", login);
    return this.http.post<LoginResponse>(this.APIUrl + AuthServicePaths.login, user)
      .pipe(tap(res => {
        localStorage.clear();
        if (res && res.token) {
          localStorage.setItem('access_token', res.token);
          localStorage.setItem('permissions', JSON.stringify(res.permissions));
        }
      }));
  }

  forgotPassword(email: string, isForgotPassword = true): Observable<any> {
    const params = new HttpParams()
      .set('email', email.trim().toString())
      .set('isForgotPassword', isForgotPassword.toString())
    // console.log("forgotPassword email", email);
    return this.http.post(`${this.APIUrl}/Auth/ForgotPassword`, {}, { params: params });
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    const params = new HttpParams()
      .set('access_token', token)
      .set('newPassword', newPassword);
    // console.log("reset password params", params);
    return this.http.post(`${this.APIUrl}/Auth/ResetPassword`, {}, { params: params });
  }

  changePassword(userId: number, currentPass: string, newPass: string): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('currentPass', currentPass)
      .set('newPass', newPass);
    console.log("reset password params", params);
    return this.http.post(`${this.APIUrl}/Auth/ChangePassword`, {}, { params: params });
  }

  checkEmailExists(email: string, isCustomer = false) {
    const params = new HttpParams()
      .set('email', email)
      .set('ownedBy', this.companyId)
      .set('isCustomer', isCustomer)
    return this.http.get<any>(`${this.APIUrl}/Auth/CheckEmailExists`, { params: params });
  }

  getToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  getPermissionsFromLocalStorage(): string[] {
    let permissions = localStorage.getItem('permissions');
    return permissions ? JSON.parse(permissions) : [];
  }

  decodeToken(token: string): any {
    return jwtDecode(token);
  }

  getAllCompanies(): Observable<GetCompaniesModel[]> {
    return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getAllCompanies);
  }

  getUsersOfCompany(): Observable<any[]> {
    const companyId = localStorage.getItem('companyId');
    return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getUsersOfCompany + '/' + companyId);
  }

  updateUserStatus(userId: any, status: boolean, softDelete = false) {
    const url = `${this.APIUrl}/Auth/UpdateUserStatus?id=${userId}&status=${status}&softDelete=${softDelete}`;
    return this.http.post(url, null);
  }

  // Service for RolePermission API
  savePermission(permission: Permission): Observable<any> {
    return this.http.post(`${this.APIUrl}/Auth/SavePermission`, permission);
  }
  // Service for GetAllPermissions API
  getAllPermissions(): Observable<PermissionDTO[]> {
    let ownedBy = Number(localStorage.getItem('companyId'));
    return this.http.get<PermissionDTO[]>(`${this.APIUrl}/Auth/GetAllPermissions?OwnedBy=${ownedBy}`);
  }
  getRoleChangePermission(roleId: number) {
    let userRoleId = roleId;
    return this.http.get<any>(`${this.APIUrl}/Auth/GetRoleChangePermission?roleId=${userRoleId}`);
  }

  // Service for SaveUserPermission API
  saveRolePermission(permission: RolePermission): Observable<any> {
    return this.http.post(`${this.APIUrl}/Auth/SaveRolePermission`, permission);
  }

  // Service for DeletePermission API
  deletePermission(id: number): Observable<any> {
    return this.http.delete(`${this.APIUrl}/Auth/DeletePermission/${id}`);
  }

  async showUnAuthorizedDialog(title: string, text: string, permission: string) {
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showCancelButton: true,
      showConfirmButton: false,
    });

    if (!result.isConfirmed) {
      // console.log("reuslt not confirmed and router object", this._router);
      // console.log("route", this._route);
      // User pressed cancel, navigate back
      const urlTree = this._router.parseUrl(this._router.url);
      const graftedTree = this._router.createUrlTree([], {
        relativeTo: this._route,
        queryParams: urlTree.queryParams,
        fragment: urlTree.fragment ?? undefined,
        queryParamsHandling: 'preserve'
      });
      // console.log("grafted tree", graftedTree);

      //current auto route is not working
      // await this._router.navigateByUrl(graftedTree);

      if (permission === 'read') {
        this._router.navigate(['/dashboard']);
      }
    }

    return result.isConfirmed;
  }

  verifyOTP(userId: number, otp: string) {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('otp', otp);
    return this.http.post(`${this.APIUrl}/Auth/VerifyOTP`, {}, { params: params });
  }

  // savePermission(data: any): Observable<any> {
  //   return this.http.post(this.APIUrl + AuthServicePaths.savePermission, data);
  // }

  // getAllPermissions(ownedId: number) {
  //   const params = { ownedBy: ownedId };
  //   return this.http.get<any[]>(this.APIUrl + AuthServicePaths.getAllPermissions, { params });
  // }

  // saveRolePermission(data: any): Observable<any> {
  //   return this.http.post(this.APIUrl + AuthServicePaths.saveRolePermission, data);
  // }
}


