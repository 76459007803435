<ng-template #tasksModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="tasksModalLabel">
      <div class="TitleIcon">
        <lord-icon class="solid_icons" src="https://cdn.lordicon.com/winbdcbm.json" trigger="hover"
          colors="primary:#5f606c"></lord-icon>
        <span class="TitleText">Task</span>
      </div>
    </h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <input type="hidden" id="_task.id" />
      <div class="col-md-12">
        <input type="text" class="form-control no-border" [(ngModel)]="_task.title" id="_task.title" autocomplete="on"
          value="{{_task.title}}" placeholder="Enter your task" required #taskTitle="ngModel" />
        <div *ngIf="taskTitle.invalid && (taskTitle.dirty)" class="error-message error" style="color: red;">
          <div *ngIf="taskTitle.errors?.['required']"> * required.</div>
        </div>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-6">
        <label for="_task.dueDate" class="form-label no-border">Due Date</label>
        <input type="date" class="form-control" [(ngModel)]="_task.dueDate" id="_task.dueDate" autocomplete="on"
          required />
      </div>
      <div class="col-md-6">
        <label for="_task.assignedto" class="form-label no-border">Assigned To</label>
        <ng-select [items]="_companyUsers" bindLabel="customerName" bindValue="id" [searchable]="true"
          [(ngModel)]="_task.assignedto" placeholder="Select Contact" (change)="onUserSelect($event)" [clearable]="true"
          required>
        </ng-select>
      </div>

    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-4">
        <label for="_task.type" class="input-form-labels">Type</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.type" id="_task.type">
          <ng-option value="To-do" selected>To-do</ng-option>
          <ng-option value="Call">Call</ng-option>
          <ng-option value="Email">Email</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <label for="_task.priority" class="input-form-labels">Priority</label>
        <ng-select class="left-align input-color" [(ngModel)]="_task.priority" id="_task.priority">
          <ng-option value="None" selected>None</ng-option>
          <ng-option value="Low">Low</ng-option>
          <ng-option value="Medium">Medium</ng-option>
          <ng-option value="High">High</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <ng-container *ngIf="_taskUpdate">
          <label for="_task.label" class="input-form-labels">Mark as Done</label>
          <ng-select class="left-align input-color" [(ngModel)]="_task.done" [bindLabel]="_task.done ? 'Done' : 'None'"
            (ngModelChange)="toggleTaskDone(_task)" id="_task.done">
            <ng-option [value]="true">Done</ng-option>
            <ng-option [value]="false">None</ng-option>
          </ng-select>

          <span class="checkmark" [ngClass]="{ 'task-done': _task.done, 'overdue': isOverdue(_task) }"></span>
        </ng-container>
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col-md-12">
        <textarea class="form-control no-border" [(ngModel)]="_task.details" d="_task.details" autocomplete="on"
          value="{{_task.details}}" placeholder="Notes..." required></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="saveTask()">
      <i class="mdi mdi-content-save"> </i> &nbsp;{{_saveTaskTitle}}
    </button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
      <i class="mdi mdi-close"> </i>&nbsp;Close
    </button>
  </div>
</ng-template>

<div class="conatiner">
  <div class="text-center mt-3">
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary btn-xs btn-icon-text universeButton square-button"
        (click)="openTasksModal(tasksModal)">
        <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
          colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
        </lord-icon>
        &nbsp;Add Task &nbsp;
      </button>
    </div>
  </div>
</div>
<ul ngbNav #defaultNav="ngbNav" class="nav-tabs sub-tabs" role="tablist">
  <li [ngbNavItem]="1">
    <a ngbNavLink class="nav-tab sub-tabs">TO-DO</a>

    <ng-template ngbNavContent>
      <div class="todo-task">
        <!-- <div *ngIf="todoTab()" class="text-center">
          <strong>Create a task to keep track of your discussion and notes.</strong>
        </div> -->
        &nbsp;
        <div class="card" *ngIf="todoTab()">
          <div class="card-header">
            <div class="row text-center">
              <p>Create a task to keep track of your discussion and notes.</p>
            </div>
          </div>
        </div>
        <div *ngFor="let dataItem of _tasksList; let i = index">
          <div class="task-card" *ngIf="!dataItem.done">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9 col-sm-9 col-xs-8 ">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="title-icon img-xs rounded-circle">
                          {{
                          dataItem.details.charAt(0)
                          }}
                        </div>
                        <div class="ms-2">

                          <ng-container>
                            <span popoverTitle="Task" class="fw-bold" [ngbPopover]="popoverContent">
                              {{(dataItem.title | slice:0:15) + '...' + (dataItem.assignedToName ? '
                              assigned
                              to ' + dataItem.assignedToName : '')}}
                            </span>
                          </ng-container>

                          <p class="fw-normal" ngbPopover="{{ dataItem.details }}">
                            {{
                            dataItem.details.length > 25 ? (dataItem.details |
                            slice:0:25) + '...' :
                            dataItem.details
                            }}
                          </p>
                          <p class="tx-11 text-muted">
                            {{
                            dataItem.activityDate | date : "MMM d, yyyy 'at' h:mm a"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-4 text-end">

                    <ng-template #popoverContent>
                      <p><strong>Title:</strong> {{ dataItem.title }}</p>
                      <p><strong>Details:</strong> {{ dataItem.details }}</p>
                      <p>
                        <strong>Date:</strong>
                        {{ dataItem.activityDate | date : "MMM d, yyyy" }}
                      </p>
                      <p>
                        <strong>Time:</strong>
                        {{ dataItem.activityDate | date : "h:mm a" }}
                      </p>
                      <p><strong>Priority:</strong> {{ dataItem.priority }}</p>
                      <p><strong>Type:</strong> {{ dataItem.type }}</p>
                    </ng-template>

                    <div ngbDropdown>
                      <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                        ngbDropdownToggle>
                        <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                        <a ngbDropdownItem class="d-flex align-items-center" (click)="openTasksModal(tasksModal, dataItem.id);
                        editTask(dataItem);(false)">
                          <i class="feather icon-edit"></i> &nbsp;
                          <span class="">Edit</span>
                        </a>
                        <a ngbDropdownItem class="d-flex align-items-center" (click)="deleteTask(dataItem.id)">
                          <i class="feather icon-trash"></i> &nbsp;
                          <span class="">Delete</span>
                        </a>
                      </div>
                    </div>

                    <div class="col-md-12 text-end">
                      <span class="overdue-message"
                        [ngClass]="{ 'red-text': isOverdue(dataItem), 'done-text': dataItem.done }">
                        <span *ngIf="dataItem.done; else notDone">Done</span>
                        <ng-template #notDone>
                          <span *ngIf="isOverdue(dataItem)" class="badge bg-danger">Over Due</span>
                        </ng-template>
                        &nbsp;
                        <input type="checkbox" class="form-check-input" [(ngModel)]="dataItem.done"
                          (change)="toggleTaskDone(dataItem)" />
                      </span>
                    </div>

                    <div class="col-md-12 text-end">
                      <label class="due-date tx-11 text-muted">
                        <strong>Due:</strong>
                        {{ dataItem.dueDate | date : "MMM d, yyyy" }}
                      </label>
                    </div>
                  </div>
                </div>
                <ng-template #popoverContent>
                  <p><strong>Details:</strong> {{ dataItem.details }}</p>
                  <p>
                    <strong>Date:</strong>
                    {{ dataItem.activityDate | date : "MMM d, yyyy" }}
                  </p>
                  <p>
                    <strong>Time:</strong>
                    {{ dataItem.activityDate | date : "h:mm a" }}
                  </p>
                  <p><strong>Priority:</strong> {{ dataItem.priority }}</p>
                  <p><strong>Queue:</strong> {{ dataItem.queue }}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink class="nav-tab sub-tabs">DONE</a>
    <ng-template ngbNavContent>
      <div class="todo-task">
        <!-- <div *ngIf="doneTab()" class="text-center mt-6">
          <strong>
            No completed tasks found. Keep track of your completed tasks
            here.
          </strong>
        </div> -->
        &nbsp;
        <div class="card" *ngIf="doneTab()">
          <div class="card-header">
            <div class="row text-center">
              <p>Create a task to keep track of your discussion and notes.</p>
            </div>
          </div>
        </div>
        <div *ngFor="let dataItem of _tasksList; let i = index">
          <div class="task-card" *ngIf="dataItem.done">
            <div class="card rounded">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-9 col-sm-9 col-xs-8 ">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="title-icon img-xs rounded-circle">
                          {{
                          dataItem.details.charAt(0)
                          }}
                        </div>
                        <div class="ms-2">

                          <ng-container>
                            <span popoverTitle="Task" class="fw-bold" [ngbPopover]="popoverContent">
                              {{ dataItem.assignedToName ? dataItem.title + ' assigned to ' +
                              dataItem.assignedToName : dataItem.title }}
                            </span>
                          </ng-container>

                          <p class="fw-normal" ngbPopover="{{ dataItem.details }}">
                            {{
                            dataItem.details.length > 80 ? (dataItem.details |
                            slice:0:80) + '...' :
                            dataItem.details
                            }}
                          </p>
                          <p class="tx-11 text-muted">
                            {{
                            dataItem.activityDate | date : "MMM d, yyyy 'at' h:mm a"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-3 col-xs-4 text-end">
                    <ng-template #popoverContent>
                      <p><strong>Details:</strong> {{ dataItem.details }}</p>
                      <p>
                        <strong>Date:</strong>
                        {{ dataItem.activityDate | date : "MMM d, yyyy" }}
                      </p>
                      <p>
                        <strong>Time:</strong>
                        {{ dataItem.activityDate | date : "h:mm a" }}
                      </p>
                      <p><strong>Priority:</strong> {{ dataItem.priority }}</p>
                      <p><strong>Type:</strong> {{ dataItem.type }}</p>
                    </ng-template>

                    <div ngbDropdown>
                      <button class="btn p-0 no-dropdown-toggle-icon" type="button" id="dropdownMenuButton3"
                        ngbDropdownToggle>
                        <i class="feather icon-more-horizontal icon-lg pb-3px"></i>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownMenuButton2">
                        <a ngbDropdownItem class="d-flex align-items-center"
                          (click)="openTasksModal(tasksModal, dataItem.id);editTask(dataItem);(false)">
                          <i class="feather icon-edit"></i> &nbsp;
                          <span class="">Edit</span>
                        </a>
                        <a ngbDropdownItem class="d-flex align-items-center" (click)="deleteTask(dataItem.id)">
                          <i class="feather icon-trash"></i> &nbsp;
                          <span class="">Delete</span>
                        </a>
                      </div>
                    </div>

                    <div class="col-md-12 text-end">
                      <span class="overdue-message"
                        [ngClass]="{ 'red-text': isOverdue(dataItem), 'done-text': dataItem.done }">
                        <span *ngIf="dataItem.done" class="badge bg-success">Done</span>
                        <span *ngIf="!dataItem.done && isOverdue(dataItem)" class="badge bg-info">Over Due</span>
                        &nbsp;
                        <input type="checkbox" class="form-check-input" [(ngModel)]="dataItem.done"
                          (change)="toggleTaskDone(dataItem)" />
                      </span>
                    </div>

                    <div class="col-md-12 text-end">
                      <label class="due-date tx-11 text-muted">
                        <strong>Due:</strong>
                        {{ dataItem.dueDate | date : "MMM d, yyyy" }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="defaultNav"></div>
<br>