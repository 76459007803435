import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  preserveWhitespaces: true
})
export class DashboardComponent implements OnInit {

  _userRole: string | null;

  _readPermissionSurveyDashboard = false;
  _readPermissionPackingDashboard = false;

  constructor(
    private _authService: AuthService
  ) {
    this._userRole = localStorage.getItem('role');
  }

  ngOnInit() {
    const permissions = this._authService.getPermissionsFromLocalStorage();

    this._readPermissionSurveyDashboard = permissions.includes('Survey Dashboard.Read');
    this._readPermissionPackingDashboard = permissions.includes('Packing Dashboard.Read');

  }

}