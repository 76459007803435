<h5>COMPANY SETTINGS</h5>

<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="container">
      <span class="text"> General </span>
    </a>

    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
              <!-- <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
                [(ngModel)]="_searchStatus" (keyup)="performStatusSearch()" /> -->
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" style="margin-left: 3px; width: 140px"
                (click)="updateCompanySettings()">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
                </lord-icon>
                &nbsp; Update Settings
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-6 column">

                      <input type="hidden" name="Iid" [(ngModel)]="_companySetting.id" />
                      <!-- Company Name -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Company Name</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.companyName"
                            name="companyName" />
                        </div>
                      </div>

                      <!-- Email -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Email</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.email" name="email" />
                        </div>
                      </div>

                      <!-- Contact -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Contact</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.contact"
                            name="contact" />
                        </div>


                      </div>

                      <!-- Street Address -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Street Address</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.streetAddress"
                            name="streetAddress" />
                        </div>
                      </div>


                      <!-- City -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">City</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.city" name="city" />
                        </div>
                      </div>

                      <!-- Country -->
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Country</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.country"
                            name="country" />
                        </div>
                      </div>


                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Branch Name</label>
                          <input type="text" class="form-control" [(ngModel)]="_companySetting.branchName"
                            name="branch" />
                        </div>
                      </div>


                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label for="theme-selector">Theme Color</label>
                          <input class="form-control wd-150" [style.background]="_selectedColor"
                            [(colorPicker)]="_selectedColor" />
                          <p class="text-muted mt-2">Value: {{ _selectedColor }}</p>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Select Logo</label>
                          <div class="file">
                            <input #fileInput type="file" placeholder="Select File" class="form-control"
                              (change)="onLogoFileSelected($event)" accept=".png, .jpg, .jpeg" />
                            <button *ngIf="logoBase64String" type="button" (click)="clearLogoFile()"
                              class="btn btn-link">
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mt-3">
                        <img class="logo-image" *ngIf="_companySetting?.logo" [src]="_companySetting.logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(2)" class="container">
      <span class="text"> Letterhead </span>
    </a>
    <ng-template ngbNavContent>
      <div>
        <h3>Section Under development </h3>
        <p>This section is currently under development. Stay tuned for updates!</p>
      </div>
    </ng-template>

  </li>
  <li [ngbNavItem]="5">
    <a ngbNavLink (click)="onChangeTab(3)" class="container">
      <span class="text"> Measuring Units </span>
    </a>
    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
              <!-- <input id="table-complete-search" type="text" class="form-control" placeholder="Search..."
                [(ngModel)]="_searchStatus" (keyup)="performStatusSearch()" /> -->
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" style="margin-left: 3px; width: 140px"
                (click)="updateCompanySettings()">
                <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                  colors="primary:#000000,secondary:#ebe6ef" style="width: 20px; height: 20px">
                </lord-icon>
                &nbsp; Update Setting
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-6 column">

                      <input type="hidden" name="Iid" [(ngModel)]="_companySetting.id" />

                      <div class="row mt-4">
                        <!-- <label class="fw-bold"> Measurement Units</label> -->
                        <div class="col-md-10">
                          <label> Weight Unit</label>
                          <ng-select id="measurementUnit" [(ngModel)]="_selectedWeightUnit" [items]="_weightUnit"
                            bindLabel="value" bindValue="id" [searchable]="true" placeholder="Please Choose a Unit">
                          </ng-select>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label> Volume Unit</label>
                          <ng-select id="measurementUnit" [(ngModel)]="_selectedVolumeUnit" [items]="_volumeUnit"
                            bindLabel="value" bindValue="id" [searchable]="true" placeholder="Please Choose a Unit">
                          </ng-select>
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label> Dimensions Unit</label>
                          <ng-select id="dimensionUnit" [(ngModel)]="_selectedDimensionUnit" [items]="_dimensionUnit"
                            bindLabel="value" bindValue="id" [searchable]="true"
                            placeholder="Please Choose a Dimension">
                          </ng-select>
                        </div>
                      </div>

                      <div class="row  mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Currency</label>
                          <ng-select id="currency" [(ngModel)]="_selectedCurrency" [items]="_currency" bindLabel="value"
                            bindValue="id" [searchable]="true" placeholder="Please Choose a Currency">
                          </ng-select>
                        </div>
                      </div>





                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </li>
  <li [ngbNavItem]="6">
    <a ngbNavLink (click)="onChangeTab(4)" class="container">
      <span class="text"> Shift Time </span>
    </a>
    <ng-template ngbNavContent>
      <div class="card rounded">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3 col-sm-auto align-right">
            </div>
            <div class="col-md-4 col-md-auto"></div>
            <div class="col-md-5 col-md-auto">
              <button class="btn btn-xs btn-primary btn-icon-text float-end" style="margin-left: 3px; width: 120px"
                (click)="saveShiftTime()">
                <i class="mdi mdi-content-save"> </i>
                &nbsp; Save Setting
              </button>
            </div>
          </div>
          &nbsp;
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-4 column">


                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label> Shift Name</label>
                          <input type="text" class="form-control" placeholder="Enter Shift Name"
                            [(ngModel)]="_shift.shiftName" />
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-10">
                          <label> Shift Start Time</label>
                          <input type="time" class="form-control" placeholder="Set Start Time"
                            [(ngModel)]="_shift.startTime" />
                        </div>
                      </div>
                      <div class="row  mt-4">
                        <div class="col-md-10">
                          <label class="fw-bold">Shift End Time</label>
                          <input type="time" class="form-control" placeholder="Set Start Time"
                            [(ngModel)]="_shift.endTime" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </li>
  <li [ngbNavItem]="7">
    <a ngbNavLink (click)="onChangeTab(5)" class="container">
      <span class="text">Email Configuration </span>
    </a>
    <ng-template ngbNavContent>
      <div>
        <h3>Section Under development </h3>
        <p>This section is currently under development. Stay tuned for updates!</p>
      </div>
    </ng-template>

  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>