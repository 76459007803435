import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { LeadQuotation } from '../../models/lead-quotation.model';
import { MoveCategoryTypeOptions, LeadDetail, MoveTypeOptions, eMoveCategoryType, eTransportType, MoveTransportModal } from '../../models/lead-detail.model';
import { CompanySetting, Currency, WeightUnit, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { MoveSurvey } from '../../models/move-survery.model';
import { SurveyMaterial } from '../../models/survery-material.model';
import { MovdService } from '../../services/movd.service';
import { GeneralService } from 'src/services/general.service';
import { LeadBoardDTO, LeadBoardResponse } from '../../lead-board/lead-board.model';

@Component({
  selector: 'app-lead-survey-preview',
  templateUrl: './lead-survey-preview.component.html',
  styleUrls: ['./lead-survey-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class LeadSurveyPreviewComponent {
  @Input() leadDetailId: any;
  _moveDetail: LeadDetail = new LeadDetail();
  _moveSurvey: MoveSurvey = new MoveSurvey();
  eMoveCategoryType = eMoveCategoryType;
  eTransportType = eTransportType;
  _moveTransport = new MoveTransportModal();
  _leadQuotation = new LeadQuotation();
  // _leadQuotation:any;
  _leadSummary = new LeadBoardDTO();

  _surveyMaterialsList!: SurveyMaterial[];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _filteredAreaItems: SurveyMaterial[] = [];
  _isDataLoaded: boolean = false;

  // Initialize the map
  _surveyPlaceToItemMap: Map<string, string> = new Map();
  _selectedSurveyPlace: string | null = null;
  _totalWeight = 0;
  _currentDate: string | null = null;
  _validTillDate: string | null = null;

  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currency = '';
  _moveCategory = MoveCategoryTypeOptions;
  _moveType = MoveTypeOptions;
  constructor(private _movdService: MovdService,
    private datePipe: DatePipe,
    private _appComponent: AppComponent,
    public generalService: GeneralService) {
    const today = new Date();
    this._currentDate = this.datePipe.transform(today, 'MMMM d, yyyy');

    // Add 10 days to the current date
    const validTillDate = new Date(today);
    validTillDate.setDate(today.getDate() + 10);

    this._validTillDate = this.datePipe.transform(validTillDate, 'MMMM d, yyyy');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['leadDetailId']) {
      this.leadDetailId = changes['leadDetailId'].currentValue;
      this._areaItems = [];
      this._materials = [];
      this._labour = [];
      this._vehicles = [];
      this.getLeadSummary();
      this.getCompanySetting();

      this._movdService.getMoveTransportByLeadDetailId(this.leadDetailId).subscribe({
        next: (response: any) => {
          if (response) {
            this._moveTransport = response;

          } else {
            this._moveTransport = new MoveTransportModal();
          }
        },
        error: (error: any) => {
          console.error("error in getMoveTransportByLeadDetailId", error);
        }
      });

      this._movdService.getLeadQuotationsByLeadDetailId(this.leadDetailId).subscribe({
        next: (response: any) => {
          // console.log("getLeadQuotationsByLeadDetailId", response);
          if (response) {
            this._leadQuotation = response;
          }
          else {
            this.leadDetailId = this.leadDetailId;
          }
        },
        error: (error: any) => {
          console.error("error getLeadQuotationsByMoveDetailId", error);
        }
      });
    }
  }
  getMoveCategoryLabel(value: any) {
    return this._moveCategory.find(option => option.id === value)?.value;
  }
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  getLeadSummary() {
    this._movdService.getLeadSummary(this.leadDetailId).subscribe({
      next: (response: any) => {
        this.getMoveSurveys();
        // this.getMoveDetail(); // not needed now as getting dates from move summary
        if (response && response.length > 0) {
          this._leadSummary = response[0];
          // console.log("move summary", this._moveSummay);
        }
        // console.log("getMoveSummary", response);
      },
      error: (error: any) => {
        console.error("error getting lead summary", error);
      }
    });
  }

  getMoveTypeLabel(value: any) {
    return this._moveType.find(option => option.id === value)?.value;
  }

  getMoveSurveys() {
    this._movdService.getMoveSurvey(this.leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("move survey list", data);
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;

          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                if (material.weight) {
                  this._totalWeight += material.weight;
                }
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              // Handle other types if needed
              default:
                break;
            }
          });
          this._isDataLoaded = true;
        }
        else {
          this._moveSurvey = new MoveSurvey();
        }
        if (this._areaItems.length > 0) {
          this._areaItems.forEach((item) => {
            if (item.surveyPlace !== null && item.item) {
              this._surveyPlaceToItemMap.set(item.surveyPlace, item.item);
            }
          });

          // Get the first key from the map and call filterItemsBySurveyPlace
          const firstSurveyPlace = Array.from(this._surveyPlaceToItemMap.keys())[0];
          //if (firstSurveyPlace) {
          //  this.filterItemsBySurveyPlace(firstSurveyPlace);
          //}
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  calculateTotal(): number {
    let total = 0;
    for (const item of this._areaItems) {
      if (item.quantity && item.weight) {
        total += item.quantity * item.weight;
      }
    }
    return total;
  }
}
