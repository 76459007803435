import { Component, OnInit } from '@angular/core';
import { CompanySetting } from '../../models/companysetting.model';

@Component({
  selector: 'app-template-setting',
  templateUrl: './template-setting.component.html',
  styleUrls: ['./template-setting.component.scss']
})
export class TemplateSettingComponent implements OnInit {

  defaultNavActiveId: number = 1;

  constructor() { }

  ngOnInit(): void {
  }
  onChangeTab(_type: any) {

  }
}
