import { eMoveCategoryType, eMoveType, EnumOption, eTransportType } from "../../models/lead-detail.model";

export class MoveReportsDTO {
  moveReportType: eMoveReportType;
  reportSubType: eReportSubType;
  fromDate: Date | null;
  toDate: Date | null;
  origin: string | null;
  destination: string | null;
  moveType: eMoveType | null;
  moveCategory: eMoveCategoryType | null;
  transportTypes: eTransportType[] | null;
  thirdPartyIds: number[] | null;
  accountIds: number[] | null;
  accountManagerName: string | null;
  ownedBy: number;

  constructor() {
    this.moveReportType = eMoveReportType.TotalLeads;
    this.reportSubType = eReportSubType.SummarizeReport;
    this.fromDate = null;
    this.toDate = null;
    this.origin = null;
    this.destination = null;
    this.moveType = null;
    this.moveCategory = null;
    this.transportTypes = null;
    this.thirdPartyIds = null;
    this.accountIds = null;
    this.accountManagerName = null;
    this.ownedBy = Number(localStorage.getItem("companyId"));
  }
}
export enum eMoveReportType {
  TotalLeads = 0,
  TotalMoves = 1,
  LeadToMove = 2,
  CustomerSatisfaction = 3,
  PackingCustSatisfaction = 4,
  DeliveryCustSatisfaction = 5,
}
export enum eReportSubType {
  SummarizeReport = 0,
  DetailReport = 1,
}
