import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MoveDetail } from '../models/move-detail.model';
import { eAttachmentType, MoveAttachment } from '../models/move-attachments.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import { MovdService } from '../services/movd.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from '../models/address.model';
import { eStatusType, Status } from '../models/status.model';
import { MoveStatus, StatusWithMoveStatus } from '../models/move-status.model';
import { GeneralService } from 'src/services/general.service';
import { eMoveCategoryType, eTransportType } from '../models/lead-detail.model';
import { SurveyMaterial } from '../models/survery-material.model';
import { MoveSurvey } from '../models/move-survery.model';
import { AuthService } from 'src/services/auth.service';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../models/companysetting.model';
import { EnumMappingService } from '../services/movd.enum.service';
import { HttpClient } from '@angular/common/http';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { MovesListResponse } from '../moves-board/moves-board.model';

@Component({
  selector: 'app-client-move-page',
  templateUrl: './client-move-page.component.html',
  styleUrls: ['./client-move-page.component.scss']
})
export class ClientMovePageComponent implements OnInit {

  @ViewChild('cancelMoveModal') cancelMoveModal!: ElementRef;
  @ViewChild('completeMoveModal') completeMoveModal!: ElementRef;

  _isDataLoaded: boolean = true;
  _leadDetailType = "leadDetail";
  _assignmentType = "moveDetail";
  _moveDetail = new MoveDetail();
  _leadDetailId: number = 0;

  defaultNavActiveId: number = 1;
  _currentTabId = 1;
  pdfDocGenerator: any;
  _refreshMoveInfo = false;
  _contentDisable = true;
  _draftTabLabel: string | null = null;
  _selectedLeadId: number = 0;
  _statusVersion: number = 0;

  _clientName: string | null = null;
  _clientsCompany: string | null = null;
  _clientDocType = eAttachmentType.ClientDocuments;
  _moveDocType = eAttachmentType.MoveDocuments;
  _leadId: number = 0;
  _userRole: string | null = null;
  _cancelMoveModalRef: NgbModalRef | undefined;
  _moveBoardDTOList: any[] = [];
  _completeMoveModalRef: NgbModalRef | undefined;
  _allStatusesDone = false;
  _originAddresses: Address[] = [];
  _destinationAddresses: Address[] = [];
  _leadStatuses: StatusWithMoveStatus[] = [];
  _leadInitiatedTitle = 'Lead Initiated';
  _moveCompletedTitle = 'Move Completed';
  _moveCancelledTitle = 'Move Cancelled';
  currentUserId = 0;
  _moveBoardItem: any;
  _totalWeight = 0;
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currencyUnit: string = '';
  _currency: any;
  _originActiveTabIndex: number = 0;
  _moveSurvey = new MoveSurvey();
  _destActiveTabIndex: number = 0;
  _surveyMaterialsList!: SurveyMaterial[];
  _areaItems: SurveyMaterial[] = [];
  _materials: SurveyMaterial[] = [];
  _labour: SurveyMaterial[] = [];
  _vehicles: SurveyMaterial[] = [];
  _containers: SurveyMaterial[] = [];
  _attachments: MoveAttachment[] = [];
  _leadInitiatedStatus = new Status();
  _moveCompletedStatus = new Status();
  _moveCancelledStatus = new Status();
  _statusType = eStatusType.Move;
  _companySetting = new CompanySetting();
  _trackingId: number = 0;
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168

  get statusVersion(): any {
    return this._statusVersion;
  }

  set statusVersion(value: any) {
    this._statusVersion = value;
    // Handle the changes to the statusVersion variable here
  }
  constructor(private _cd: ChangeDetectorRef, private _modalService: NgbModal,
    public generalService: GeneralService,
    private _movdService: MovdService, public enumMappingService: EnumMappingService,
    private _authService: AuthService, private _route: ActivatedRoute,
    private _http: HttpClient,
    private _appComponent: AppComponent, private _router: Router,
  ) {
    this._userRole = localStorage.getItem('role');
    this.currentUserId = Number(localStorage.getItem('currentUserId'));
  }

  ngOnInit(): void {

    this._route.queryParams.subscribe((params) => {
      // console.log('params', params)
      this._trackingId = params['id'];
      if (this._trackingId) {
        //console.log('tracking id', this._trackingId)

        this.getMoveDetailByTrackingId(this._trackingId);
        this.getCompanySetting();
      }
    });
  }
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }
  getMoveDetailByTrackingId(trackingId: any): void {
    this._movdService.getMoveDetailsByTrackingId(trackingId).subscribe({
      next: (data: any) => {
        //console.log("move detail", data);
        this._originAddresses = [];
        this._destinationAddresses = [];
        if (data) {
          this._moveDetail = data;
          this._leadId = this._moveDetail.leadId;
          this.getMoveDetailsByLeadId();
          // this.getLeadStatuses(this._moveDetail.id, eStatusType.Move, this._moveDetail.categoryType, this._moveDetail.transportType);

          this._leadDetailId = data.leadDetailId;

          this._draftTabLabel = this.identifyDraftTabLabel(this._moveDetail.categoryType, this._moveDetail.transportType);
          if (this._moveDetail && this._moveDetail.addresses !== null) {
            const originAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            } else {
              this._originAddresses = []
              const newAddress: Address = { ...this._originAddresses[this._originActiveTabIndex] };
              newAddress.addressType = 0; // Set addressType to 0 for origin address

              // Reset the fields of the new address
              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._originAddresses.push(newAddress);
              this._originActiveTabIndex = this._originAddresses.length - 1;
              // this.addOriginTab(); // will not work here as we are doing this for diff move
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            } else {
              this._destinationAddresses = [];
              const newAddress: Address = { ...this._destinationAddresses[this._destActiveTabIndex] };
              newAddress.addressType = 1; // Set addressType to 1 for destination address

              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._destinationAddresses.push(newAddress);
              this._destActiveTabIndex = this._destinationAddresses.length - 1;
              // this.addDestTab();
            }
          }
        }
        this.getMoveSurveys();
        // this.getMoveDetailsByLeadId();
        // this.checkContentDisable();
      },
      error: (error) => {
        console.error("error getting move detail", error);
      }
    });
  }
  openInitiateMoveModal() {

    if (this._moveDetail.addresses?.length === 0) {
      // console.log("checking address");
      alert("Required fields are missing.");
      return;
    } else if (this._moveDetail.addresses && this._moveDetail.addresses.length > 0) {
      for (const address of this._moveDetail.addresses) {
        if (!address.city || !address.country) {
          // If city is missing, return or handle the error as needed
          alert("City or country is missing in an address.");
          // this._appComponent.showErrorDialog('Error', 'City is missing in an address.');
          return;
        }
      }
    }

    if (this.getActiveMovesCount() === 1) {
      let activeMove = this._moveBoardDTOList.find(move => !move.completed && !move.cancelled);
      if (activeMove) {
        activeMove.selected = true;
      }
      this.completeMove();
    }
    else {
      this._completeMoveModalRef = this._modalService.open(this.completeMoveModal, { centered: true });
      this._moveBoardDTOList.forEach(move => {
        move.selected = move.moveInitiated === true;
      });
      // Check if moveInitiated is true for the leadDetail
      this._moveDetail.selected = this._moveDetail.completed === true;
    }
  }
  identifyDraftTabLabel(moveCategory: eMoveCategoryType | null, transportType: eTransportType | null): string | null {
    if (moveCategory && transportType && moveCategory === eMoveCategoryType.DoorToDoorExport || moveCategory === eMoveCategoryType.DoorToPort || moveCategory === eMoveCategoryType.DoorToDoorLocal) {
      if (transportType === eTransportType.Sea || transportType === eTransportType.SeaLCL) {
        return "CONSIGNMENT INFO"; // export sea shipment
      } else if (transportType === eTransportType.Air) {
        return "CONSIGNMENT INFO"; // export air shipment
      }
      else if (transportType === eTransportType.Land) {
        return "CONSIGNMENT INFO"; // export air shipment
      }
      else {
        return null;
      }
    } else {
      return null;
    }
  }
  getActiveMovesCount(): number {
    return this._moveBoardDTOList.filter(move => !move.completed && !move.cancelled).length;
  }
  cancelMove() {
    this._cancelMoveModalRef = this._modalService.open(this.cancelMoveModal, { centered: true });
  }
  async completeMove() {
    // Get the selected moves
    // console.log("lead board list in complete move", this._leadBoardDTOList);
    const selectedMoves = this._moveBoardDTOList.filter(move => move.selected === true && move.cancelled !== true);

    // console.log("selectedMoves", selectedMoves);
    if (selectedMoves.length === 0) {
      this._appComponent.showErrorDialog('Error', 'No moves are selected for completion.')
      return;
    }

    if (!this._allStatusesDone) {
      this._appComponent.showErrorDialog('Error', 'All Statuses are not done yet.');
      return;
    }

    // If we reach here, none of the selected leads are complete
    // Show a confirmation dialog
    let text = 'You are about to complete the selected moves.';
    let confirmButtonText = 'Yes, complete it!';

    let confirmed = await this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      // Get the IDs of the selected moves
      const leadDetailIds = selectedMoves.map(move => move.moveDetailId);

      // Call the completeMove service with all selected lead IDs
      this._movdService.completeMove(leadDetailIds).subscribe({
        next: (data: any) => {
          this._completeMoveModalRef?.close();
          // Mark all selected leads as initiated
          selectedMoves.forEach(move => {
            move.completed = true;
          });

          this._statusVersion++;
          this.getMoveDetailsByLeadId(); // Update move details

          this._appComponent.showSuccessSwal('Move', 'completed');

          // Close the modal after successful initiation
          this._modalService.dismissAll();
        },
        error: async (error) => {
          this._cd.detectChanges();
          console.error('Error marking move as completed', error);
          // Handle error gracefully, e.g., show an error message to the user
        },
      });
    }
  }
  getMoveDetailsByLeadId() {
    this._movdService.getMoveDetailsByLeadId(this._leadId).subscribe({
      next: (data: MovesListResponse) => {
        // console.log("_moveBoardDTOList move details by lead id", data);
        if (data.movesList.length > 0) {
          this._moveBoardDTOList = data.movesList;
          this.getMoveBoardItem(this._moveDetail.id);
          this.getMoveDetailById(this._moveDetail.id);
        } else {
          this._router.navigate(['/moves-board']);
        }
        this._cd.detectChanges();
      },
      error: (error) => {
        console.error("error move details by lead id", error);
      }
    });
  }
  getMoveDetailById(moveDetailId: any): void {
    this._movdService.getMoveDetailById(moveDetailId).subscribe({
      next: (data: any) => {
        // console.log("move detail", data);
        this._originAddresses = [];
        this._destinationAddresses = [];
        if (data) {
          this._moveDetail = data;

          this.getLeadStatuses(this._moveDetail.id, eStatusType.Move, this._moveDetail.categoryType, this._moveDetail.transportType);

          this._leadDetailId = data.leadDetailId;

          this._draftTabLabel = this.identifyDraftTabLabel(this._moveDetail.categoryType, this._moveDetail.transportType);
          if (this._moveDetail && this._moveDetail.addresses !== null) {
            const originAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._moveDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this._originAddresses = originAdrress;
            } else {
              this._originAddresses = []
              const newAddress: Address = { ...this._originAddresses[this._originActiveTabIndex] };
              newAddress.addressType = 0; // Set addressType to 0 for origin address

              // Reset the fields of the new address
              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._originAddresses.push(newAddress);
              this._originActiveTabIndex = this._originAddresses.length - 1;
              // this.addOriginTab(); // will not work here as we are doing this for diff move
            }

            if (destAdrress.length > 0) {
              this._destinationAddresses = destAdrress;
            } else {
              this._destinationAddresses = [];
              const newAddress: Address = { ...this._destinationAddresses[this._destActiveTabIndex] };
              newAddress.addressType = 1; // Set addressType to 1 for destination address

              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this._destinationAddresses.push(newAddress);
              this._destActiveTabIndex = this._destinationAddresses.length - 1;
              // this.addDestTab();
            }
          }
        }
        this.getMoveSurveys();
        // this.getMoveDetailsByLeadId();
        // this.checkContentDisable();
      },
      error: (error) => {
        console.error("error getting move detail", error);
      }
    });
  }
  getMoveSurveys() {
    this._movdService.getMoveSurvey(this._moveDetail.leadDetailId).subscribe({
      next: (data: any) => {
        this._surveyMaterialsList = [];
        this._areaItems = [];
        this._materials = [];
        this._labour = [];
        this._vehicles = [];
        this._containers = [];
        this._attachments = [];
        // console.log("move survey list", data);
        if (data) {
          this._moveSurvey = data;
          this._surveyMaterialsList = this._moveSurvey.surveyMaterials;

          this._surveyMaterialsList.forEach((material: SurveyMaterial) => {
            switch (material.type) {
              case 'Material':
                this._materials.push(material);
                break;
              case 'Area Item':
                this._areaItems.push(material);
                if (material.weight) {
                  this._totalWeight += material.weight;
                }
                break;
              case 'Labour':
                this._labour.push(material);
                break;
              case 'Vehicle':
                this._vehicles.push(material);
                break;
              case 'Container':
                this._containers.push(material);
                break;
              default:
                break;
            }
          });
          this._isDataLoaded = true;

          if (this._moveSurvey.surveyAttachments && this._moveSurvey.surveyAttachments.length > 0) {
            this._attachments = this._moveSurvey.surveyAttachments;
          }
        }
        else {
          this._moveSurvey = new MoveSurvey();
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  // checkContentDisable() {
  //   if (this._moveDetail && this._moveDetail.cancelled === true || this._moveDetail.completed === true) {
  //     this._contentDisable = true;
  //   } else {
  //     this._contentDisable = false;
  //   }
  // }
  getMoveBoardItem(moveDetailId: number) {
    // console.log("move details by lead id", moveDetailId);
    if (this._moveBoardDTOList && this._moveBoardDTOList.length > 0) {
      const filteredData = this._moveBoardDTOList.filter(((item: { moveDetailId: number; }) => item.moveDetailId === moveDetailId))[0];
      // console.log("filtered data", filteredData);
      if (filteredData) {
        filteredData.surveyDate = filteredData.surveyDate ? this.generalService.formatDate(filteredData.surveyDate, null) : this.generalService.formatDate(filteredData.surveyDate, null);

        filteredData.packagingDate = filteredData.packagingDateFrom && filteredData.packagingDateTo ? this.generalService.formatDate(filteredData.packagingDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.packagingDateTo, null) : this.generalService.formatDate(filteredData.packagingDateFrom, null);
        delete filteredData.packagingDateFrom;
        delete filteredData.packagingDateTo;

        filteredData.loadingDate = filteredData.loadingDateFrom && filteredData.loadingDateTo ? this.generalService.formatDate(filteredData.loadingDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.loadingDateTo, null) : this.generalService.formatDate(filteredData.loadingDateFrom, null);
        delete filteredData.loadingDateFrom;
        delete filteredData.loadingDateTo;

        filteredData.storageDate = filteredData.storageDateFrom && filteredData.storageDateTo ? this.generalService.formatDate(filteredData.storageDateFrom, null) + ' - ' + this.generalService.formatDate(filteredData.storageDateTo, null) : this.generalService.formatDate(filteredData.storageDateFrom, null);
        delete filteredData.storageDateFrom;
        delete filteredData.storageDateTo;
      }

      this._moveBoardItem = filteredData;
      this._clientName = this._moveBoardItem.customerName;
      this._clientsCompany = this._moveBoardItem.accountName;
      this._cd.detectChanges();
    }
  }
  calculateVolume(surveyPlace: string, unit: string, type: string): string | number {
    let totalVolume;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalVolume = items.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    } else {
      totalVolume = this._areaItems.reduce((acc, item) => acc + (item.volume || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalVolume.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'cuft' && this._volumeUnit === 'cuft') {
      // Convert from cuft to cbm
      const convertedVolume = totalVolume * this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cbm`;
    } else if (unit === 'cbm' && this._volumeUnit === 'cbm') {
      // Convert from cbm to cuft
      const convertedVolume = totalVolume / this.cuftToCbmConversionFactor;
      result += ` / ${convertedVolume.toFixed(2)} cuft`;
    }

    return result;
  }

  getItemCount(surveyPlace: string): number {
    const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(surveyPlace: string, unit: string, type: string): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = this._areaItems.filter((item) => item.surveyPlace === surveyPlace && !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    } else {
      totalWeight = this._areaItems.reduce((acc, item) => acc + (item.weight || 0) * item.quantity, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }
  async getLeadStatuses(leadDetailId: number, statusType: eStatusType, category: number, transportType: number): Promise<any> {
    this._movdService.getLeadStatus(leadDetailId, statusType, category, transportType).subscribe({
      next: async (data: any) => {
        // console.log("this.lead statuses", data);
        if (data) {
          this._leadStatuses = data;

          // await this.checkStatusExistsInLeadStatuses('initiated');

          if (this._moveDetail.cancelled === true) {
            await this.checkStatusExistsInLeadStatuses('cancelled');
          } else if (this._moveDetail.completed === true) {
            await this.checkStatusExistsInLeadStatuses('completed');
          }
          // console.log("First status:", this._firstStatus);
          // console.log("Last status:", this._lastStatus);
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
  async checkStatusExistsInLeadStatuses(title: string) {
    let statusTitle = '';

    if (title === 'initiated') {
      statusTitle = this._leadInitiatedTitle;
    }
    else if (title === 'completed') {
      statusTitle = this._moveCompletedTitle;
    }
    else if (title === 'cancelled') {
      statusTitle = this._moveCancelledTitle;
    } else {
      return;
    }
    // status done or not done for a specific lead detail and category

    let moveStatus = this._leadStatuses.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === statusTitle.toLowerCase());
    // console.log(`${statusTitle} Status`, moveStatus);

    if (moveStatus[0]) {
      await this.markStatusAsDone(this._moveDetail.id, title, moveStatus[0]);
    } else {
      // commenting this so it can't create any other status
      // this.checkStatusExistsInStatuses(title);
    }
  }
  async markStatusAsDone(leadDetailId: any, type: string, status: StatusWithMoveStatus | null) {
    // console.log('leadDetailId', leadDetailId);
    let statusToBeSaved: Status = new Status(); // Set a default status
    let moveStatus: MoveStatus = new MoveStatus();
    let statusDoneDate = new Date();

    if (type === 'initiated') {
      statusToBeSaved = this._leadInitiatedStatus;
      statusDoneDate = this._moveDetail.creationDate;
    }

    else if (type === 'won') {
      statusToBeSaved = this._moveCompletedStatus;
    }

    else if (type === 'lost') {
      statusToBeSaved = this._moveCancelledStatus;
    }

    // console.log("before marking status as done && status type: ", statusToBeSaved, type);

    if (statusToBeSaved.id === 0 && !status) {
      return;
    }

    else if (statusToBeSaved.id === 0 && status) {
      // console.log("statusToBeSaved.id === 0 && status");
      moveStatus = {
        id: status.moveStatusId,
        refId: leadDetailId,
        statusId: status.statusId,
        date: statusDoneDate,
        done: true,
        category: this._moveDetail.categoryType,
        transportType: this._moveDetail.transportType,
        createdBy: this.currentUserId,
        isDeleted: false,
      }
    } else {
      // console.log("else");
      moveStatus = {
        id: 0,
        refId: leadDetailId,
        statusId: statusToBeSaved.id,
        date: statusDoneDate,
        done: true,
        category: statusToBeSaved.category, // Use the category of the assigned status
        transportType: statusToBeSaved.transportType, // Use the category of the assigned status
        createdBy: this.currentUserId,
        isDeleted: false,
      }
    }

    if (status && status.moveStatusDone === true) {
      // console.log("status && status.moveStatusDone === true");
      return;
    }

    // console.log("status immediately before service call", moveStatus);
    // Call the MovdService to send the update request to the backend
    this._movdService.saveClientJourneyStatus(moveStatus).subscribe({
      next: (response: any) => {
        // console.log('save move status response', response);
        this._statusVersion++;
        // this.getLeadDetailById(this._leadDetail.id);
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Handle error, e.g., show an error message to the user
      }
    });
  }
  showMoveCancelAndCompleteButton(): boolean {
    return (this._moveDetail.cancelled === false || this._moveDetail.cancelled === null) &&
      (this._moveDetail.completed === false || this._moveDetail.completed === null);
  }
  showMoveById(moveDetailId: number) {
    // console.log("selected lead id", moveDetailId);
    this._selectedLeadId = moveDetailId;
    this._moveDetail.id = this._selectedLeadId;
    // this.getMoveDetailById(this._moveDetail.id);
    // this.getMoveDetailsByLeadId();
    this._cd.detectChanges();
  }
  handleDraftBLData(data: any) {
    // console.log("handle DraftBL Data called and Data:", data);
    if (data && data.result) {
      this._draftTabLabel = data.label;
      this._cd.detectChanges();
    } else if (data && data.result === false) {
      this._draftTabLabel = null;
      this._cd.detectChanges();
    }
  }
  handleCategoryTransportChange(event: { category: number, transport: number }) {
    this._moveDetail.categoryType = event.category;
    this._moveDetail.transportType = event.transport;

    // console.log(`Category = ${this.enumMappingService.mapMoveCategoryType(this._moveDetail.categoryType)} && transportType = ${this.enumMappingService.mapTransportType(this._moveDetail.transportType)}`);
  }
  handleVersionChange(data: any) {
    // console.log("handle version change called and Data:", data);
    if (data) {
      // console.log("if in handle version change function");
      this.showMoveById(this._moveDetail.id);
    }
  }
  createPdf() {
    return new Promise<void>((resolve, reject) => {
      var docDefinition: any = {
        content: [
          this._companySetting.logo && this._companySetting.logo.startsWith('data:image') ? {
            image: this._companySetting.logo,
            width: 110,
            alignment: 'center',
          } : {
            text: 'Company Logo',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10],
          },
          {
            text: 'Move Summary',
            style: 'header',
            alignment: 'center',
            margin: [0, 10, 0, 10] // [left, top, right, bottom]
          },
          {
            text: 'General Info',
            style: 'smallHeadings',
            alignment: 'left',
            margin: [0, 10, 0, 5] // [left, top, right, bottom]
          },

        ],
        styles: {
          header: {
            fontSize: 20,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          columnText: {
            fontSize: 11,
          },
          simpleText: {
            bold: true,
            fontSize: 12,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          smallHeadings: {
            bold: true,
            fontSize: 14,
            color: 'black'
          }
        },
        footer: function (currentPage: any, pageCount: any) {
          return { text: 'Page ' + currentPage.toString() + ' of ' + pageCount, fontSize: 10, alignment: 'right', margin: [0, 5, 40, 0] };
        }
      };

      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );

      var firstTableData = [];

      // Check conditions and add data accordingly


      firstTableData.push(
        [{ text: 'Lead Id', style: 'columnText', alignment: 'left' },
        { text: (this._moveBoardItem.leadDetailId ? this.generalService.formatId(this._moveBoardItem.leadDetailId, 'lead') : '-'), style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Customer Id', style: 'columnText', alignment: 'left' },
        { text: (this._moveBoardItem?.customerId ? this.generalService.formatId(this._moveBoardItem.customerId, 'customer') : '-'), style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Customer Name', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.customerName || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Account Name', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.accountName || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Type', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveType(this._moveBoardItem?.moveType || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Move Category', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapMoveCategoryType(this._moveBoardItem?.moveCategory || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Transport Type', style: 'columnText', alignment: 'left' },
        { text: this.enumMappingService.mapTransportType(this._moveBoardItem?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._moveDetail.categoryType == eMoveCategoryType.DoorToDoorExport || this._moveDetail.categoryType == eMoveCategoryType.DoorToPort && this._moveDetail.transportType == eTransportType.Sea || this._moveDetail.transportType == eTransportType.SeaLCL || this._moveDetail.transportType == eTransportType.Air) {
        firstTableData.push(
          [{ text: 'POL (Port of Loading)', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.portOfLoading || ' - ', style: 'columnText', alignment: 'left' }]
        );
        firstTableData.push(
          [{ text: 'POD (Port of Discharge)', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.portOfDischarge || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Place of Delivery', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.placeOfDelivery || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Survey Date', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.surveyDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Packing Date', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.packagingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Loading Date', style: 'columnText', alignment: 'left' },
        { text: this._moveBoardItem?.loadingDate || ' - ', style: 'columnText', alignment: 'left' }]
      );
      if (this._moveBoardItem.storageDate) {
        firstTableData.push(
          [{ text: 'Storage Date', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.storageDate || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if (this._moveDetail.transportType == eTransportType.Sea || this._moveDetail.transportType == eTransportType.SeaLCL || this._moveDetail.transportType == eTransportType.Air) {
        firstTableData.push(
          [{ text: 'Scheduled Departure Date', style: 'columnText', alignment: 'left' },
          { text: this.generalService.formatDate(this._moveBoardItem?.scheduledDepartureDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
        );

        firstTableData.push(
          [{ text: 'Departure Date', style: 'columnText', alignment: 'left' },
          { text: this.generalService.formatDate(this._moveBoardItem?.departureDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if (this._moveDetail.transportType == eTransportType.Sea || this._moveDetail.transportType == eTransportType.SeaLCL) {
        firstTableData.push(
          [{ text: 'BL No', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.blNo || ' - ', style: 'columnText', alignment: 'left' }]
        );

        firstTableData.push(
          [{ text: 'Vessel Name', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.vesselName || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if (this._moveDetail.transportType == eTransportType.Air) {
        firstTableData.push(
          [{ text: 'AWB No', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.awbNo || ' - ', style: 'columnText', alignment: 'left' }]
        );

        firstTableData.push(
          [{ text: 'Carrier Name', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.carrierName || ' - ', style: 'columnText', alignment: 'left' }]
        );

        firstTableData.push(
          [{ text: 'Flight No', style: 'columnText', alignment: 'left' },
          { text: this._moveBoardItem?.flightNo || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      if (this._moveDetail.transportType == eTransportType.Land) {
        firstTableData.push(
          [{ text: 'Scheduled Dispatch Date', style: 'columnText', alignment: 'left' },
          { text: this.generalService.formatDate(this._moveBoardItem?.scheduledDispatchDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
        );

        firstTableData.push(
          [{ text: 'Dispatch Date', style: 'columnText', alignment: 'left' },
          { text: this.generalService.formatDate(this._moveBoardItem?.dispatchDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
        );
      }
      firstTableData.push(
        [{ text: 'Scheduled Arrival Date', style: 'columnText', alignment: 'left' },
        { text: this.generalService.formatDate(this._moveBoardItem?.scheduledArrivalDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
      );

      firstTableData.push(
        [{ text: 'Arrival Date', style: 'columnText', alignment: 'left' },
        { text: this.generalService.formatDate(this._moveBoardItem?.arrivalDate, null) || ' - ', style: 'columnText', alignment: 'left' }]
      );
      firstTableData.push(
        [{ text: 'Client Notes', style: 'columnText', alignment: 'left' },
        { text: this._moveDetail?.additionalNotes || ' - ', style: 'columnText', alignment: 'left' }]
      );

      if (docDefinition && docDefinition.content && docDefinition.content[3] && docDefinition.content[3].table && docDefinition.content[3].table.body) {
        // Add dynamic data to the second table
        firstTableData.forEach(data => {
          docDefinition.content[3].table.body.push(data);
        });
      }

      //Address Details
      docDefinition.content.push(
        { text: 'Address Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      for (let i = 0; i < Math.max(this._originAddresses.length, this._destinationAddresses.length); i++) {
        // Check if either origin or destination address exists at index i
        if (this._originAddresses[i] || this._destinationAddresses[i]) {
          // Add row for indicating the address index
          if (i !== 0) {
            docDefinition.content.push({
              text: `${i === 0 ? 'First' : i === 1 ? 'Second' : 'Third'} Address`,
              style: 'tableHeader',
              margin: [0, 10, 0, 6], // [left, top, right, bottom]
            });
          }

          // Add row for Origin and Destination headers
          docDefinition.content.push({
            table: {
              widths: ['25%', '25%', '25%', '25%'],
              body: [
                [{ text: 'Origin', style: 'tableHeader' }, '', { text: 'Destination', style: 'tableHeader' }, ''],
              ],
            },
            // Remove borders
          });

          // Add rows for street, city, state, country, and postal code
          const addressRows = [
            ['Street', this._originAddresses[i]?.streetAddress || '-', 'Street', this._destinationAddresses[i]?.streetAddress || '-'],
            ['City', this._originAddresses[i]?.city || '-', 'City', this._destinationAddresses[i]?.city || '-'],
            ['State', this._originAddresses[i]?.state || '-', 'State', this._destinationAddresses[i]?.state || '-'],
            ['Country', this._originAddresses[i]?.country || '-', 'Country', this._destinationAddresses[i]?.country || '-'],
            ['Postal Code', this._originAddresses[i]?.zipCode || '-', 'Postal Code', this._destinationAddresses[i]?.zipCode || '-'],
          ];

          addressRows.forEach(row => {
            docDefinition.content.push({
              table: {
                widths: ['25%', '25%', '25%', '25%'],
                body: [row],
              }, // Remove borders
            });
          });
        }
      }

      docDefinition.content.push(
        { text: 'Shipment Details', style: 'smallHeadings', alignment: 'left', margin: [0, 10, 0, 10] },
      );

      var shipmentData = [
        { text: this.enumMappingService.mapTransportType(this._moveBoardItem?.moveTransport || 0) || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateVolume('', this._volumeUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
        { text: this.calculateWeight('', this._weightUnit, 'total') || ' - ', style: 'columnText', alignment: 'left' },
      ];


      docDefinition.content.push(
        {
          table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            widths: ['33%', '33%', '34%'],
            body: [
              [
                { text: 'Shipment', style: 'tableHeader', alignment: 'left' },
                { text: 'Volume ' + '(' + (this._volumeUnit) + ')', style: 'tableHeader', alignment: 'left' },
                { text: 'Weight ' + '(' + (this._weightUnit) + ')', style: 'tableHeader', alignment: 'left' },
              ],
              shipmentData
            ],
          },
          margin: [0, 10, 0, 10], // [left, top, right, bottom]
        },
      );

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      this.pdfDocGenerator = pdfDocGenerator;
      resolve();
    });
  }

  async downloadPdf() {
    try {
      // Always create a new PDF
      await this.createPdf();
      // Download the newly created PDF
      await this.pdfDocGenerator.download();
    } catch (error) {


      this._appComponent.showAlertMessageDialog('Export PDF', 'An error occurred while creating or downloading the PDF. Please try again.');
    }
  }
  async reInitiateMove() {
    // if (this._leadDetail.markLost === null) {
    //   return;
    // }

    // console.log("before mark lost", this._leadDetail.markLost);
    this._moveDetail.cancelled = !this._moveDetail.cancelled;
    this._cd.detectChanges();
    // console.log('mark lead status');
    // console.log("after mark lost", this._leadDetail.markLost);

    let text: string;
    let confirmButtonText: string;

    text = 'You are about to re-initiate move.';
    confirmButtonText = 'Yes, re-initiate it!';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );
    // console.log("this._moveDetail", this._moveDetail);
    if (await confirmed === true) {
      this._movdService.reInitiateMove(this._moveDetail.id).subscribe({
        next: (data: any) => {
          this._moveDetail = data;
          this.getMoveDetailsByLeadId();
          this._refreshMoveInfo = true;
          this.statusVersion++;
          // console.log('move re-initiated as lost successfully', data);
          this._appComponent.showSuccessSwal("re-initiate", "move");
        },
        error: (error) => {
          this._appComponent.showErrorSwal("re-initiating", "move");
          this._moveDetail.cancelled = !this._moveDetail.cancelled;
          this._cd.detectChanges();
          console.error('Error marking lead as lost', error);
        }
      });
    } else {
      // If the user cancels the operation, revert the markLost flag
      this._moveDetail.cancelled = !this._moveDetail.cancelled;
      // this._saveLeadLost = false;
      this._cd.detectChanges();
    }

  }

  handleAllStatusesDone(allDone: boolean) {
    // console.log("handleAllStatusesDone called ", allDone);
    this._allStatusesDone = allDone;
  }
}
