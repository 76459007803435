<ng-template #movePreviewModal let-modal>
  <div class="modal-header">
    <div class="modal-title">
      <p>
        {{(_movesBoardItem.customer || ' - ') + ' - Move '
        + (_movesBoardItem.moveDetailId || ' - ')}}</p>
    </div>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="box-left border border-dark-grey p-3 custom-rounded">
          <div class="row mb-3">
            <div class="col-6">
              <label class="mb-0">Customer:</label> {{ _movesBoardItem.customer || ' - ' }}
            </div>
            <div class="col-6">
              <label class="mb-0">Move Id: </label> {{ _movesBoardItem.moveDetailId || ' - ' }}
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <label class="mb-0">Email:</label> {{ _movesBoardItem.email || ' - ' }}
            </div>
            <div class="col-6">
              <label class="mb-0">Account:</label> {{ _movesBoardItem.account || ' - ' }}
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="mb-0">Phone Number:</label> {{ _movesBoardItem.phone || ' - ' }}
            </div>
            <div class="col-6"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="_movesBoardItem.origin" class="col-form-label">
            Origin
            <span class="required-input"> * </span>
          </label>
          <input type="text" class="form-control" [(ngModel)]="_movesBoardItem.origin" id="_movesBoardItem.origin"
            autocomplete="on" placeholder="Origin" disabled />
        </div>

        <div class="col-md-6">
          <label for="_movesBoardItem.destination" class="col-form-label">
            Destination
            <span class="required-input"> * </span>
          </label>
          <input type="text" class="form-control" [(ngModel)]="_movesBoardItem.destination"
            id="_movesBoardItem.destination" autocomplete="on" placeholder="Destination" disabled />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <label class="col-form-label"> Move Type </label>
          <input type="text" class="form-control" [(ngModel)]="_movesBoardItem.moveTypeLabel" placeholder="Move Type"
            disabled>
        </div>

        <div class="col-md-6">
          <label class="col-form-label"> Move Category </label>
          <input type="text" class="form-control" [(ngModel)]="_movesBoardItem.moveCategoryLabel"
            placeholder="Move Category" disabled>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label class="col-form-label"> Transport Type </label>
          <input type="text" class="form-control" [(ngModel)]="_movesBoardItem.moveTransportLabel"
            placeholder="Transport Type" disabled>
        </div>

        <div class="col-md-6"></div>
      </div>


      <hr class="flex-grow-1 m-3 border-dark-grey" />
      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Survey Date
          </label>
          <div class="input-group">
            <input class="form-control clickable" name="dp"
              [value]="generalService.formatDate(_movesBoardItem.surveyDate, null)" disabled />
            <button class="input-group-text" type="button" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
          </div>
        </div>
      </div> -->

      <!-- <div class="row mb-3">
        <div class="col-md-6">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Loading Date
          </label>
          <div class="input-group">
            <ng-container *ngIf="_loadingDate; else placeholder">
              <input type="text" class="form-control clickable" placeholder="Select Date Range"
                [value]="generalService.formatDate(_movesBoardItem.loadingDateFrom, _movesBoardItem.loadingDateTo)"
                disabled />
            </ng-container>
            <ng-template #placeholder>
              <div class="form-control">Select Date</div>
            </ng-template>
            <button class="input-group-text" type="button" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label fw-bold m-0 mt-3">
            &nbsp; Delivery Date
          </label>
          <div class="input-group">
            <input class="form-control clickable" name="dp" title="date-range"
              [value]="generalService.formatDate(_movesBoardItem.deliveryDate, null)" disabled />
            <button class="input-group-text" type="button" title="date-range">
              <i class="feather icon-calendar icon-md text-muted"></i>
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <div class="modal-footer">

    <button class="btn btn-sm btn-danger float-end" (click)="modal.close()">
      Close
    </button>
    <button class="btn btn-sm cancel-btn" (click)="moveInfoClick(); modal.close()">
      Move Info Form
    </button>
  </div>
</ng-template>
<ng-template #columnModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">&nbsp;Edit Columns</h5>
  </div>
  <div class="modal-body">
    <table>
      <ng-container *ngIf="_displayedMovesList.length > 0">
        <tr *ngFor="let column of _allColumns">
          <td>
            <label>
              <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedColumns[column]"
                [ngModelOptions]="{ standalone: true }" (ngModelChange)="columnToggleSubject.next()" />
              {{ column }}
            </label>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>

<div class="row">
  <h5 class="dashboard-heading">MOVES DASHBOARD</h5>
</div>

<div class="row mt-3">
  <div class="col-3">
    <input type="text" id="_searchTerm" class="form-control" placeholder="Search..." [(ngModel)]="_searchTerm"
      (keyup.enter)="onSearch()" (input)="onInputChange($event)" />
    <small *ngIf="!_searchTerm" class="form-text text-muted">Type to search.</small>
    <small *ngIf="_searchTerm" class="form-text text-muted">Press Enter to search.</small>
  </div>
  <div class="col-5"></div>

  <div class="col-4">
    <button class="btn btn-xs btn-secondary btn-icon-text float-end" (click)="openColumnModal(columnModal)"
      style="margin-right: 3px; width: 130px">
      <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
        colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd" style="width: 20px; height: 20px">
      </lord-icon>
      &nbsp; Edit Columns
    </button>
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-12">
    <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3 custom-label"
      [class.btn-primary]="_selectedStatus === 'ALL'" [class.text-white]="_selectedStatus === 'ALL'"
      (click)="onClickStatus('ALL')">All</label>

    <!-- <label *ngFor="let status of _statusList" class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3"
      [class.btn-primary]="_selectedStatus === status.title" [class.text-white]="_selectedStatus === status.title"
      (click)="onClickStatus(status.title)">{{ status.title }}</label> -->

    <label *ngFor="let status of _tabLabels"
      class="btn btn-outline-primary btn-label-lg btn-rectangular btn-lg mt-3 custom-label"
      [class.btn-primary]="_selectedStatus === status" [class.text-white]="_selectedStatus === status"
      (click)="onClickStatus(status)">{{ status
      }}</label>

  </div>
</div>
<div class="row" *ngIf="_displayedMovesList.length === 0">
  <div class="col-12">
    <div class="card">
      <div class="card-body text-center">
        <h6>No Moves, currently. Please Add a move.</h6>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="_displayedMovesList.length > 0">
  <div class="col-12">
    <div class="move-board-datatable">
      <ngx-datatable [rows]="_displayedMovesList" class="bootstrap custom-datatable table-bordered"
        [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize" [pagination]="true"
        (page)="onPageChange($event)" [loadingIndicator]="loadingIndicator" [rowHeight]="40" [scrollbarH]="true"
        [count]="_totalRecords" [externalPaging]="true" [pageSize]="_pageSize">

        <ngx-datatable-column *ngFor="let column of tableColumns" [name]="column.header" [prop]="column.prop"
          class="thead" [style]="{ border: '1px solid black' }" [width]="getColumnWidth(column.header)">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <ng-container *ngIf="value !== null">
              <ng-container *ngIf="column.header === 'Move ID'" class="move-id-column">
                <div class="move-id-container hover" (mouseenter)="onRowMouseEnter(row)"
                  (mouseleave)="onRowMouseLeave(row)">
                  <ng-container *ngIf="!_readPermission">
                    <a (click)="movePreviewClick(row, 'moveIdClick')" [ngClass]="'primary-link'">
                      {{ row.formattedMoveDetailId }}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="_readPermission">
                    <a [routerLink]="['/move-detail']" [queryParams]="returnMoveUrl(row)"
                      (click)="movePreviewClick(row, 'moveIdClick')" [ngClass]="'primary-link'">
                      {{ row.formattedMoveDetailId }}
                    </a>
                  </ng-container>

                  <button
                    class="btn btn-secondary button-wrapper btn-icon-text previewClick btn-small-transparent btn-smaller"
                    *ngIf="row.isHovered" [attr.data-id]="row.id" (click)="movePreviewClick(row, 'previewBtnClick')">
                    Preview
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="column.header === 'Survey ID'" class="move-id-column">
                <ng-container *ngIf="!_readPermission">
                  <a *ngIf="row.surveyId; else noLinkSurvey" (click)="movePreviewClick(row, 'surveyIdClick')"
                    class="primary-link">
                    {{ generalService.formatId(row.surveyId, 'survey') }}
                  </a>
                </ng-container>

                <ng-container *ngIf="_readPermission">
                  <a [routerLink]="['/add-survey']" [queryParams]="returnSurveyUrl(row)"
                    *ngIf="row.surveyId; else noLinkSurvey" (click)="movePreviewClick(row, 'surveyIdClick')"
                    [ngClass]="'primary-link'">
                    {{generalService.formatId(row.surveyId, 'survey')}}
                  </a>
                </ng-container>
                <ng-template #noLinkSurvey>-</ng-template>
              </ng-container>
              <ng-container *ngIf="column.header === 'Packing ID'" class="move-id-column">
                <ng-container *ngIf="!_readPermission">
                  <a *ngIf="row.packingId; else noLinkPacking" (click)="movePreviewClick(row, 'packingIdClick')"
                    class="primary-link">
                    {{ generalService.formatId(row.packingId, 'packing') }}
                  </a>
                </ng-container>
                <ng-container *ngIf="_readPermission">
                  <a [routerLink]="['/packing-details']" [queryParams]="returnPackingUrl(row)"
                    *ngIf="row.packingId; else noLinkPacking" (click)="movePreviewClick(row, 'packingIdClick')"
                    class="primary-link">
                    {{ generalService.formatId(row.packingId, 'packing') }}
                  </a>
                </ng-container>

                <ng-template #noLinkPacking>-</ng-template>
              </ng-container>
              <ng-container *ngIf="column.header === 'POC' || column.prop === 'pocName'">
                <a [class.clickable-link]="row.pocName !== null"
                  (click)="row.pocName !== null ? redirectToAccount(row.pocId) : null">
                  {{ row.pocName || '-' }}
                </a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Third Party' || column.prop === 'thirdPartyName'">
                <a [class.clickable-link]="row.thirdPartyName !== null"
                  (click)="row.thirdPartyName !== null ? redirectToAccount(row.thirdPartyId) : null">
                  {{ row.thirdPartyName || '-' }}
                </a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Third Party POC' || column.prop === 'thirdPartyPocName'">
                <a [class.clickable-link]="row.thirdPartyPocName !== null"
                  (click)="row.thirdPartyPocName !== null ? redirectToAccount(row.thirdPartyPocId) : null">
                  {{ row.thirdPartyPocName || '-' }}
                </a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Customer' || column.prop === 'customerName'">
                <a class="clickable-link" (click)="redirectToAccount(row.customerId)">
                  {{row.customer || '-'}}</a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Account'" class="clickable-link">
                <ng-container *ngIf="row.account === 'Individual'; else clickableLink">
                  {{ ' - ' }}
                </ng-container>
                <ng-template #clickableLink>
                  <a class="clickable-link" (click)="redirectToAccount(row.accountId)">
                    {{ row.account }}</a>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="column.header === 'Account Manager' || column.prop === 'accountManager'">
                <a class="clickable-link" (click)="redirectToAccount(row.accountManagerId)">
                  {{row.accountManager || '-'}}</a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Origin' || column.prop === 'origin'">
                {{ row.origin || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Destination' || column.prop === 'destination'">
                {{ row.destination || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Move' || column.prop === 'moveType'">
                {{ row.moveTypeLabel || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Move Category' || column.prop === 'moveCategory'">
                {{ row.moveCategoryLabel || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Transport Type' || column.prop === 'moveTransport'">
                {{ row.moveTransportLabel || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'POL' || column.prop === 'portOfLoading'">
                {{ row.portOfLoading || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'POD' || column.prop === 'portOfDischarge'">
                {{ row.portOfDischarge || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Place Of Delivery' || column.prop === 'placeOfDelivery'">
                {{ row.placeOfDelivery || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Warehouse Storage' || column.prop === 'warehouseStorage'">
                {{ row.warehouseStorage || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Loading Date' || column.prop === 'loadingDate'">
                {{ row.loadingDate || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Survey Date' || column.prop === 'surveyDate'">
                {{ row.formattedSurveyDate || '-' }}
              </ng-container>
              <ng-container *ngIf="column.header === 'Storage Date' || column.prop === 'storageDate'">
                {{ row.storageDate || '-' }}
              </ng-container>
              <ng-container *ngIf="column.header === 'Pack Date' || column.prop === 'packagingDate'">
                {{ row.packagingDate || '-' }}
              </ng-container>
              <ng-container *ngIf="column.header === 'Delivery Date' || column.prop === 'deliveryDate'">
                {{ row.deliveryDate || '-' }}
              </ng-container>

              <ng-container *ngIf="column.header === 'Status'">
                <div class="status-text">{{ value || '-'}}</div>
                <div class="status-indicator">
                  <ng-container *ngFor="let status of _statusList; let i = index">
                    <div class="status-dot" [ngClass]="{
                         'completed' : _completedStatuses.includes(status.title),
                         'highlight-dot' : i < _completedStatuses.length}">
                    </div>
                    <span *ngIf="i < _statusList.length - 1" class="status-line" [ngClass]="{}">
                    </span>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>