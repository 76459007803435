import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { GeneralService } from 'src/services/general.service';
import { MoveAttachment, eAttachmentType } from '../models/move-attachments.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RequiredDocument } from '../models/required-document.model';
import { eMoveCategoryType } from '../models/lead-detail.model';
import { Observable, catchError, forkJoin, map, of, tap } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { PdfService } from 'src/services/pdf.service';
import { title } from 'process';
import { EmailTemplate } from '../models/email-template.model';
import { eEmailType } from '../../auth/auth.models';

@Component({
  selector: 'app-move-documents',
  templateUrl: './move-documents.component.html',
  styleUrls: ['./move-documents.component.scss']
})
export class MoveDocumentsComponent implements OnChanges {
  @Input() moveDetailId: any;
  @Input() leadDetailId: any;
  @Input() leadId: any;
  @Input() moveCategory: any;
  @Input() documentType: any;
  @Input() clientName: any;
  @Input() clientsCompany: any;
  @Input() contentDisabled = false;

  @ViewChild('generateTemplateModal') generateTemplateModal!: ElementRef;
  @ViewChild('addDocumentModal') addDocumentModal!: any;

  _combinedList: any[] = [];
  _moveAttachmentList: MoveAttachment[] = [];
  _requiredDocuments: RequiredDocument[] = [];
  iconVisibility: { [key: number]: boolean } = {};
  _progress = 0;
  _uploadedFiles = 0;
  _remainingFiles = 0;
  _saveDocTitle: string = '';
  _documentModaltitle: string = '';
  // _fileToPreview: { data: string, type: string } | null = null;
  _fileToPreview: any;
  _filePreviewTitle: string | null = null;
  isImageLoading: boolean = false;

  defaultNavActiveId = 1;

  _documentModalRef: NgbModalRef | undefined;

  // objects
  _dueDate: Date | null = null;
  _document = new MoveAttachment();
  eAttachmentType = eAttachmentType;
  eEmailType = eEmailType;
  _documentTitle: string | null = null;
  _titleExists: boolean = false;
  _isAddingAttachment: boolean = false;

  _selectedFile: any;
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;
  _customerId: number | null = null;
  _companyId: number = 0;
  _currentUserId: number | null = null;
  _title = "Generate Email";
  _temp = new EmailTemplate();
  _tempTitle!: string | null;
  _tempSubject!: string | null;
  _tempBody!: string | null;
  tempExists: boolean = false;
  templateType = "Client Docs Template";
  _tempTableData: EmailTemplate[] = [];
  tempTableData: any[] = [];
  _tempDataList: any[] = [];
  _tempTableColumns: any[] = [];
  _searchTempItems: string = '';
  _dictionary: { [key: string]: any } = {};
  pdfData: Blob = new Blob([], { type: 'application/pdf' });
  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    public generalService: GeneralService,
    private _authService: AuthService,
    public _pdfService: PdfService,
    private sanitizer: DomSanitizer

  ) {
    this._userRole = localStorage.getItem('role');
    this._companyId = Number(localStorage.getItem('companyId'));
    this._currentUserId = Number(localStorage.getItem('currentUserId'));
  }

  ngOnInit() {
    // Retrieve permissions from local storage

  }

  async ngOnChanges(changes: SimpleChanges) {
    // console.log("_moveDetail.id and doc type", changes);
    if (changes['moveDetailId']) {
      this.moveDetailId = changes['moveDetailId'].currentValue;
    }

    if (changes['leadId']) {
      this.leadId = changes['leadId'].currentValue;
    }

    if (changes['documentType']) {
      this.documentType = changes['documentType'].currentValue;
    }

    if (changes['moveCategory']) {
      this.moveCategory = changes['moveCategory'].currentValue;
    }
    if (changes['clientName']) {
      this.clientName = changes['clientName'].currentValue;
    }
    if (changes['clientsCompany']) {
      this.clientsCompany = changes['clientsCompany'].currentValue;
    }

    if (changes['contentDisabled']) {
      this.contentDisabled = changes['contentDisabled'].currentValue;
    }

    if (this.moveDetailId && this.documentType && this.moveCategory) {
      // console.log("document type", this.documentType);
      const permissions = this._authService.getPermissionsFromLocalStorage();

      if (this.documentType === eAttachmentType.ClientDocuments) {
        // Set permission variables
        this._readPermission = permissions.includes('Move Details - Client Docs.Read') || this._userRole === 'CompanyAdmin';
        this._createPermission = permissions.includes('Move Details - Client Docs.Create') || this._userRole === 'CompanyAdmin';
        this._updatePermission = permissions.includes('Move Details - Client Docs.Update') || this._userRole === 'CompanyAdmin';
        this._deletePermission = permissions.includes('Move Details - Client Docs.Delete');

      } else if (this.documentType === eAttachmentType.MoveDocuments) {
        // Set permission variables
        this._readPermission = permissions.includes('Move Details - Move Docs.Read') || this._userRole === 'CompanyAdmin';
        this._createPermission = permissions.includes('Move Details - Move Docs.Create');
        this._updatePermission = permissions.includes('Move Details - Move Docs.Update');
        this._deletePermission = permissions.includes('Move Details - Move Docs.Delete');

      }
      await this.getCombinedList();
      this.createDictionary();
    }
  }

  getCombinedList(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      forkJoin([
        this.getMoveAttachments(this.moveDetailId, this.documentType),
        this.getRequiredDocuments()
      ]).subscribe(([attachments, documents]) => {
        this._moveAttachmentList = attachments.map(doc => ({ ...doc, type: 'MoveAttachment' }));
        this._requiredDocuments = documents.filter((doc: any) => !this.isInMoveAttachmentList(doc)).map((doc: any) => ({ ...doc, type: 'RequiredDocument' }));
        this._combinedList = [...this._moveAttachmentList, ...this._requiredDocuments];
        resolve();
        // console.log('combined list', this._requiredDocuments);
      }, error => {
        reject(error);
      });
    });
  }


  getMoveAttachments(moveDetailId: number, documentType: number) {
    return this._movdService.getMoveAttachments(moveDetailId, documentType).pipe(
      tap(data => {
        // console.log("get move attachments", data);
      }),

      catchError(error => {
        console.error("error getting documents", error);
        return of([]); // Return an empty observable in case of error
      })
    );

  }

  getRequiredDocuments(): Observable<any> {
    if (!this.documentType) {
      // console.log("!this.documentType  ")
      return of([]); // Return an empty observable
    }

    return this._movdService.getRequiredDocuments(this.documentType).pipe(
      map(data => {
        if (data) {
          // console.log("get document settings  ");

          return data.filter((item: RequiredDocument) => {
            if (item.type !== this.documentType) {
              return false;
            }

            if (item.all) {
              return true;
            }

            switch (this.moveCategory) {
              case eMoveCategoryType.DoorToDoorLocal:
                return item.doorToDoorLocal;
              case eMoveCategoryType.DoorToPort:
                return item.doorToPort;
              case eMoveCategoryType.PortToDoor:
                return item.portToDoor;
              case eMoveCategoryType.DoorToDoorImport:
                return item.doorToDoorImport;
              case eMoveCategoryType.DoorToDoorExport:
                return item.doorToDoorExport;
              default:
                return false;
            }
          });
        } else {
          // If data is null or undefined, return an empty array
          return [];
        }
      }),
      catchError(error => {
        console.error('Error fetching required document:', error);
        return of([]); // Return an empty observable in case of error
      })
    );
  }

  isInMoveAttachmentList(item: any): boolean {
    return this._moveAttachmentList.some(doc => doc.title === item.title && !item.isDeleted);

  }

  isUploaded(item: any): boolean {
    return this._moveAttachmentList.some(doc => doc.title === item.title && item.isDeleted === false && item.imageData !== null || item.url !== null);
  }

  onCheckboxChange(event: any, item: any): void {
    // This function will be called when the checkbox is checked or unchecked
    // 'item' is the item associated with the checkbox
    // console.log("onCheckboxChange item", item);

    let isDeleted: boolean = false; // Assign a default value

    if (item && item.isDeleted === false) {
      // console.log("item && item.isDeleted === false", item);
      isDeleted = true;
    }

    if (this.isInMoveAttachmentList(item)) {
      // console.log("item already exist in the list", item);

      item.isDeleted = isDeleted;
      this.saveDocuments('single', item);
    }
    else {
      if (item.type === 'MoveAttachment') {
        // console.log('MoveAttachment', item);

        item.isDeleted = isDeleted;
        this.saveDocuments('single', item);

      } else if (item.type === 'RequiredDocument') {
        // console.log('RequiredDocument', item);
        item.id = 0;
        item.imageData = null;
        item.creationDate = new Date();
        this.saveDocuments('single', item);

      }
    }
    this.createDictionary();
    this.iconVisibility[item.id] = event.target.checked;
  }
  // validateDueDate() {

  onFileSelected(event: any, item: any) {
    // console.log("on file selected - item", item);
    const files: FileList = event.target.files;
    this._uploadedFiles = 0; // Reset the uploaded files counter
    this._progress = 0; // Reset the progress
    this._remainingFiles = files.length; // Set the total number of files

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > 5242880) { // 5 MB in bytes
        alert("File is too large! Please select a file that is less than 5 MB.");
        return;
      }
    }

    // Check if files were selected
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const file = files[i];
        reader.onload = (e: any) => {
          this._fileToPreview = e.target.result;
          const existingAttachment = this._moveAttachmentList.find(attachment => attachment.title === item.title);
          if (existingAttachment) {
            existingAttachment.imageData = e.target.result;
            this.saveDocuments('single', existingAttachment);
          } else {
            // If the file does not exist in the list, create a new attachment
            const newAttachment: MoveAttachment = {
              id: 0,
              imageData: e.target.result,
              title: file.name,
              refId: this.moveDetailId,
              type: this.documentType,
              url: null,
              isValid: null,
              creationDate: new Date(),
              isDeleted: false,
              modificationDate: null,
              dueDate: null
            };
            // Push the new attachment object to the _attachments array
            this._moveAttachmentList.push(newAttachment);
          }

          this._uploadedFiles += 1;
          this._remainingFiles -= 1; // Decrease the number of remaining files
          this._progress = (this._uploadedFiles / (this._uploadedFiles + this._remainingFiles)) * 100;
          // console.log("Upload progress: ", this._progress);

        };
        reader.readAsDataURL(files[i]);
      }
    }
  }


  sanitizeDocumentUrl(imageUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }

  sanitizeOfficeUrl(fileData: string): SafeResourceUrl {
    // Create a Blob from the base64 data
    const byteCharacters = atob(fileData.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);

    // Create an object URL from the Blob
    const objectUrl = URL.createObjectURL(blob);

    // Ensure proper URL encoding
    const encodedObjectUrl = encodeURIComponent(objectUrl);

    // Construct the Google Docs Viewer URL
    const viewerUrl = 'https://docs.google.com/viewer?url=' + encodedObjectUrl + '&embedded=true';

    return this.sanitizer.bypassSecurityTrustResourceUrl(viewerUrl);
  }


  resetProgress() {
    this._uploadedFiles = 0; // Reset the uploaded files counter
    this._progress = 0; // Reset the progress
  }

  // this._progress = (this._uploadedFiles / (this._uploadedFiles + this._remainingFiles)) * 100;

  openPreviewModal(modal: any, item: any) {
    this.isImageLoading = true; // Start loading
    this._fileToPreview = item.imageData;
    this._filePreviewTitle = item.title;
    this._modalService.open(modal);
  }

  openEditModal(modal: any, item: any) {
    this.isImageLoading = true;
    this._selectedFile = item;
    this._fileToPreview = item.imageData;
    this._filePreviewTitle = item.title;
    this._modalService.open(modal);

  }

  stopLoading() {
    this.isImageLoading = false;
  }

  downloadFile(item: any) {
    // console.log("item", item);
    // Create a Blob from the base64 data
    if (item.imageData === null) {
      return;
    }
    const mime = item.imageData.split(',')[0].split(':')[1].split(';')[0]; // Extract the MIME type
    const byteCharacters = atob(item.imageData.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mime }); // Use the extracted MIME type
    // Create a link element and set the Blob URL as its href
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    // Set the download attribute of the link to the file name
    link.download = item.title;
    // Append the link to the body and click it to start the download
    document.body.appendChild(link);
    link.click();
    // Clean up by removing the link element from the body
    document.body.removeChild(link);
  }


  async removeDocument(item: any) {
    // Assuming _documentList is the array that contains your documents
    // const index = this._moveAttachmentList.indexOf(item);
    // if (index > -1) {
    //   this._moveAttachmentList.splice(index, 1);
    // }

    let text: string = 'You are about to remove a document.';
    let confirmButtonText: string = 'Yes, remove it!';

    if (!item) {
      return;
    }

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      const existingAttachment = this._moveAttachmentList.find(attachment => attachment.title === item.title);
      if (existingAttachment) {
        existingAttachment.imageData = null;
        this.saveDocuments('single', existingAttachment);
        this._modalService.dismissAll();
      }
    }
  }


  editFile(item: any) {
    // Your file edit code here
  }

  isButtonDisabled(): Boolean {
    if (!this._documentTitle || !this._dueDate) {
      return true;
    } else {
      return false;
    }
  }

  saveDocuments(type: string, document: MoveAttachment | null) {
    // console.log("save documents type and document", type, document);

    let data;
    let singleDoc: MoveAttachment;
    if (this._titleExists) {
      return;
    }

    if (type === 'single' && !document) {
      if (!this._dueDate || !this._documentTitle) {
        return;
      }

      singleDoc = new MoveAttachment();
      singleDoc.refId = this.moveDetailId;
      singleDoc.dueDate = this._dueDate || null;
      singleDoc.title = this._documentTitle || ' ';
      singleDoc.type = this.documentType;
      singleDoc.isValid = true;
      singleDoc.creationDate = new Date();
      singleDoc.isDeleted = false;
      // console.log("save id ", singleDoc.id);
      data = [singleDoc];   // as the api accepts the list 

    } else if (type === 'single' && document) {
      // console.log("save documents type === 'single' && document");
      singleDoc = new MoveAttachment();
      singleDoc.id = document.id;
      // console.log("save id ", singleDoc.id);
      singleDoc.title = this._documentTitle || document.title;
      singleDoc.imageData = document.imageData;
      singleDoc.refId = this.moveDetailId;
      singleDoc.type = this.documentType;
      singleDoc.isValid = true;
      singleDoc.creationDate = document.creationDate;
      singleDoc.modificationDate = new Date();
      singleDoc.isDeleted = document.isDeleted;
      singleDoc.dueDate = this._dueDate || document.dueDate;

      data = [singleDoc];   // as the api accepts the list 

    } else if (type === 'list') {
      data = this._moveAttachmentList;
    }

    if (!data) {
      return;
    }
    // if (!this._dueDate || !this._documentTitle) {


    //   return;
    // }
    // console.log("before saving docs", data);
    this._movdService.saveAttachments(data).subscribe({
      next: (data: any) => {
        this._document = new MoveAttachment();
        this._documentTitle = null;
        // this._fileToPreview = this._selectedFile; 

        this.getCombinedList();
        // this._appComponent.showSuccessSwal('saved', 'document');
        this._documentModalRef?.close();
      },
      error: (error) => {
        console.error("error saving documents", error);
        this._appComponent.showErrorSwal('saving', 'documents');
      }
    });

  }

  openAddDocumentModal() {

    this._isAddingAttachment = true;
    this._documentModaltitle = "Add New Document";
    this._saveDocTitle = "Save";
    this._dueDate = null;
    this._documentTitle = null;
    this._document = new MoveAttachment();
    this._documentModalRef = this._modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false
    });


    if (this._dueDate) {
      this._dueDate = this.formatTaskDate(this._dueDate);
    }
  }

  openEditDocumentModal(item: any) {
    this._isAddingAttachment = false;
    this._documentModaltitle = "Update Documents";
    this._saveDocTitle = "Update";
    this._document = item;
    this._documentTitle = item.title;
    this._dueDate = this.formatTaskDate(item.dueDate);
    this._documentModalRef = this._modalService.open(this.addDocumentModal, {
      backdrop: 'static',
      keyboard: false
    });
    if (this._dueDate) {
      this._dueDate = this.formatTaskDate(this._dueDate);
    }
  }
  deleteDocument(item: MoveAttachment) {
    if (confirm('Are you sure you want to delete this document?')) {
      this._movdService.deleteAttachment(item.id).subscribe({
        next: (response: any) => {
          if (response && response.status !== 200) {
            console.error('Error  deleting document', response);
            this._appComponent.showErrorSwal('deleting', 'document');
          } else {
            // console.log('Document deleted successfully');
            this.getCombinedList();
            this._appComponent.showSuccessSwal('deleted', 'document');
          }
        },
        error: (error) => {
          console.error('Error soft deleting document', error);
          this._appComponent.showErrorSwal('deleting', 'document');
        }
      });
    }
  }



  closeDocumentModal(modal: any) {
    this._document = new MoveAttachment();
    this._documentTitle = null;
    this._dueDate = null;

    modal.close();
  }

  returnUploadedOnDate(item: any) {
    if (item && item.imageData !== null || item.url !== null && item.modificationDate === null) {
      return this.generalService.formatDate(item.creationDate, null);
    } else if (item && item.imageData === null || item.url !== null && item.modificationDate !== null) {
      return this.generalService.formatDate(item.modificationDate, null);
    }
    return ' - ';
  }

  formatTaskDate(dateString: any) {
    if (isNaN(Date.parse(dateString))) {
      return null;
    }
    return dateString.split('T')[0]; // Return only the date part 'yyyy-mm-dd'
  }
  validateTitleExists(): void {
    let input = this._documentTitle;

    if (!input || input.trim().length === 0 || !this._isAddingAttachment) {
      // Invalid or empty title format, reset '_titleExists' and return.
      this._titleExists = false;
      return;
    }

    // Trim spaces and convert to lower case
    input = input.trim().toLowerCase();

    if (this._combinedList && this._combinedList.length > 0) {
      this._titleExists = this._combinedList.some(document => document.title.trim().toLowerCase() === input);
    } else {
      this._titleExists = false; // Reset '_titleExists' if '_moveAttachmentList' is empty
    }
  }
  generateSurveyPdf(type: any) {
    const pdfType = type;
    this._pdfService.generateSurveyPdf(this.leadDetailId, this.leadId, pdfType);
  }
  generatePackingPdf(type: any) {
    const pdfType = type;
    this._pdfService.generatePackingPdf(this.leadId, pdfType);
  }

  openTempModal(): void {

    if (this.generateTemplateModal) {
      this._documentModalRef = this._modalService.open(this.generateTemplateModal, { centered: true, backdrop: 'static', });
    }
  }
  closeModal(type: string, modal: any) {
    switch (type) {
      case 'Temp':
        modal.close();
        break;
      case 'TempItems':
        modal.close();
        break;
      default:
    }
  }

  createDictionary() {
    const documentDictionary: { [key: string]: string[] } = {}; // Document dictionary object with `Documents` key as string array
    const otherDictionary: { [key: string]: any } = {}; // Dictionary for other key-value pairs

    // Iterate through move attachment list to populate document dictionary
    this._moveAttachmentList
      .filter(document => !document.isDeleted)
      .forEach(document => {
        documentDictionary['Documents'] = documentDictionary['Documents'] || []; // Ensure Documents key exists
        documentDictionary['Documents'].push(document.title); // Add document title to the Documents array
      });

    if (this._currentUserId !== undefined || null) {
      otherDictionary['User Id'] = this._currentUserId;
    }
    if (this.moveDetailId !== undefined || null) {
      otherDictionary['Move ID'] = this.generalService.formatId(this.moveDetailId, 'move');
    }
    if (this.clientName !== undefined || null) {
      otherDictionary['Client Name'] = this.clientName;
    }
    if (this.clientsCompany !== undefined || null) {
      otherDictionary['Client Company Name'] = this.clientsCompany;
    }
    else {
      otherDictionary['Client Company Name'] = '-';
    }

    // Merge the two dictionaries
    this._dictionary = { ...documentDictionary, ...otherDictionary };

    // Optionally, log the merged dictionary
    // console.log('Merged Dictionary:', this._dictionary);

    // Return the merged dictionary or use it as needed in your application
    return this._dictionary;
  }

}
