<ng-template #columnModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">&nbsp;Edit Columns</h5>
  </div>
  <div class="modal-body">
    <table>
      <ng-container *ngIf="_allLeadsList.length > 0">
        <tr *ngFor="let column of _allColumns">
          <td>
            <label>
              <input type="checkbox" class="form-check-input" [(ngModel)]="_selectedColumns[column]"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="columnToggleSubject.next()" />
              {{ column }}
            </label>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>

<div class="row">
  <h5 class="dashboard-heading">PACKING LIST</h5>
</div>

<div class="row mt-3">
  <div class="col-md-3">
    <input type="text" id="_searchTerm" class="form-control" placeholder="Search..."
      [(ngModel)]="_searchTerm" (keyup.enter)="onSearch()" (input)="onInputChange($event)" />
    <small *ngIf="!_searchTerm" class="form-text text-muted">Type to search.</small>
    <small *ngIf="_searchTerm" class="form-text text-muted">Press Enter to search.</small>
  </div>
  <div class="col-md-6"></div>
  <div class="col-md-3">
    <button class="btn btn-xs btn-secondary btn-icon-text float-end"
      (click)="openColumnModal(columnModal)" style="margin-left: 3px; width: 130px">
      <lord-icon src="https://cdn.lordicon.com/qtqvorle.json" trigger="hover" delay="2000"
        colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#4cb4fd"
        style="width: 20px; height: 20px">
      </lord-icon>
      &nbsp; Edit Columns
    </button>
  </div>
</div>

<div class="row  mt-1">
  <div class="col-md-12">
    <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
      [class.btn-primary]="_selectedStatus === 'All'" [class.text-white]="_selectedStatus === 'All'"
      (click)="onClickStatus('All')">ALL</label>
    <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
      [class.btn-primary]="_selectedStatus === 'New'" [class.text-white]="_selectedStatus === 'New'"
      (click)="onClickStatus('New')">NEW</label>
    <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
      [class.btn-primary]="_selectedStatus === 'InProgress'"
      [class.text-white]="_selectedStatus === 'InProgress'" (click)="onClickStatus('InProgress')">IN
      PROGRESS</label>
    <label class="btn btn-outline-primary btn-rectangular btn-lg mt-3"
      [class.btn-primary]="_selectedStatus === 'Completed'"
      [class.text-white]="_selectedStatus === 'Completed'"
      (click)="onClickStatus('Completed')">COMPLETED</label>
  </div>
</div>
<div class="row" *ngIf="_displayedLeadsList.length === 0">
  <div class="col-12">
    <div class="card">
      <div class="card-body text-center">
        <h6>No items found in Packing List.</h6>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="_displayedLeadsList.length > 0">
  <div class="col-12">
    <div class="my-datatable">
      <ngx-datatable [rows]="_displayedLeadsList" class="bootstrap custom-datatable"
        [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="_pageSize"
        [pagination]="true" (page)="onPageChange($event)" [loadingIndicator]="loadingIndicator"
        [rowHeight]="40" [scrollbarH]="true" [count]="_totalRecords" [externalPaging]="true"
        [pageSize]="_pageSize">
        <ngx-datatable-column *ngFor="let column of tableColumns" [name]="column.header"
          [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }"
          [width]="getColumnWidth(column.header)">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <ng-container *ngIf="value !== null">

              <ng-container *ngIf="column.header === 'Packing ID'">
                <ng-container *ngIf="!_readPermission">
                  <a (click)="navigateToPackagingDetail(row)" class="move-id">
                    {{ row.formattedPackingId }}
                  </a>
                </ng-container>
                <ng-container *ngIf="_readPermission && row.packingStatus === _eProcessStatus.New">
                  <a (click)="navigateToPackagingDetail(row)" class="move-id">
                    {{ row.formattedPackingId }}
                  </a>
                </ng-container>
                <ng-container *ngIf="_readPermission && row.packingStatus !== _eProcessStatus.New">
                  <a [routerLink]="['/packing-details']" [queryParams]="returnPackingUrl(row)"
                    (click)="navigateToPackagingDetail(row)" class="move-id">
                    {{ row.formattedPackingId }}
                  </a>
                </ng-container>

              </ng-container>

              <ng-container *ngIf="column.header === 'Lead ID'">
                <ng-container *ngIf="!_readPermission">
                  <a (click)="leadIdClick(row)" class="move-id">
                    {{ row.formattedLeadDetailId }}
                  </a>
                </ng-container>
                <ng-container *ngIf="_readPermission">
                  <a [routerLink]="['/lead-detail']" [queryParams]="returnLeadUrl(row)"
                    (click)="leadIdClick(row)" class="move-id">
                    {{ row.formattedLeadDetailId }}
                  </a>
                </ng-container>

              </ng-container>
              <ng-container *ngIf="column.header === 'Move ID'">
                <ng-container *ngIf="!_readPermission">

                  <a (click)="moveInfoClick(row)" class="move-id">
                    {{ row.formattedMoveDetailId }}
                  </a>
                </ng-container>
                <ng-container *ngIf="_readPermission">
                  <a [routerLink]="['/move-detail']" [queryParams]="returnMoveUrl(row)"
                    (click)="moveInfoClick(row)" class="move-id">
                    {{ row.formattedMoveDetailId }}
                  </a>
                </ng-container>

              </ng-container>
              <ng-container *ngIf="column.header === 'POC' || column.prop === 'pocName'">
                <a [class.clickable-link]="row.pocName !== null"
                  (click)="row.pocName !== null ? redirectToAccount(row.pocId) : null">
                  {{ row.pocName || '-' }}
                </a>
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Third Party' || column.prop === 'thirdPartyName'">
                <a [class.clickable-link]="row.thirdPartyName !== null"
                  (click)="row.thirdPartyName !== null ? redirectToAccount(row.thirdPartyId) : null">
                  {{ row.thirdPartyName || '-' }}
                </a>
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Third Party POC' || column.prop === 'thirdPartyPocName'">
                <a [class.clickable-link]="row.thirdPartyPocName !== null"
                  (click)="row.thirdPartyPocName !== null ? redirectToAccount(row.thirdPartyPocId) : null">
                  {{ row.thirdPartyPocName || '-' }}
                </a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Customer' || column.prop === 'customer'">
                <a class="clickable-link" (click)="redirectToAccount(row.customerId)">
                  {{row.customer || '-'}}</a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Account'" class="clickable-link">
                <ng-container *ngIf="row.account === 'Individual'; else clickableLink">
                  <!--{{ row.account }}-->
                  {{' - '}}
                </ng-container>
                <ng-template #clickableLink>
                  <a class="clickable-link" (click)="redirectToAccount(row.accountId)">
                    {{ row.account }}</a>
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Account Manager' || column.prop === 'accountManager'">
                <a class="clickable-link" (click)="redirectToAccount(row.accountManagerId)">
                  {{row.accountManager || '-'}}</a>
              </ng-container>
              <ng-container *ngIf="column.header === 'Origin' || column.prop === 'origin'">
                {{ row.origin || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Destination' || column.prop === 'destination'">
                {{ row.destination || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Move' || column.prop === 'moveType'">
                {{ row.moveTypeLabel || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Move Category' || column.prop === 'moveCategory'">
                {{ row.moveCategoryLabel || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Transport Type' || column.prop === 'moveTransport'">
                {{ row.moveTransportLabel || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'POL' || column.prop === 'portOfLoading'">
                {{ row.portOfLoading || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'POD' || column.prop === 'portOfDischarge'">
                {{ row.portOfDischarge || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Place Of Delivery' || column.prop === 'placeOfDelivery'">
                {{ row.placeOfDelivery || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Warehouse Storage' || column.prop === 'warehouseStorage'">
                {{ row.warehouseStorage || '-'}}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Loading Date' || column.prop === 'loadingDate'">
                {{ row.loadingDate || '-'}}
              </ng-container>
              <ng-container *ngIf="column.header === 'Survey Date' || column.prop === 'surveyDate'">
                {{ row.formattedSurveyDate || '-' }}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Storage Date' || column.prop === 'storageDate'">
                {{ row.storageDate || '-' }}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Pack Date' || column.prop === 'packagingDate'">
                {{ row.packagingDate || '-' }}
              </ng-container>
              <ng-container
                *ngIf="column.header === 'Delivery Date' || column.prop === 'deliveryDate'">
                {{ row.formattedDeliveryDate || '-' }}
              </ng-container>
            </ng-container>
          </ng-template>

        </ngx-datatable-column>
        <ng-container *ngIf="_selectedStatus === 'All'">
          <ngx-datatable-column name="Status">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
              let-value="value">
              {{ generalService.getProcessStatusLabel(row.packingStatus) || ' - ' }}
            </ng-template>
          </ngx-datatable-column>
        </ng-container>

        <ng-container *ngIf="_readPermission">
          <ngx-datatable-column name="Action">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex"
              let-value="value">
              <button type="button" class="btn btn-xs btn-primary datatable-action-cell"
                (click)="navigateToPackagingDetail(row)">
                {{ generalService.getButtonLabel(row.packingStatus) }}
              </button>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>
      </ngx-datatable>
    </div>
  </div>
</div>