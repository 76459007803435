<ng-template #generateTemplateModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="addRoleModalLabel">
      <i class="mdi mdi-email-outline"></i>   {{_title}}
    </h5>
    <button type="button" class="btn-close" label="Close"
      (click)="modal.close()"></button>
  </div>

  <div class="modal-body"><app-generate-email [emailType]="eEmailType.Move" [refId]="moveDetailId"
      [templateType]="templateType" [dictionary]="_dictionary"
      [pdfData]="pdfData" (emailSent)="modal.close()" [shipperEmail]="_shipperEmail" [trackinUrl]="_trackingUrl"></app-generate-email></div>
</ng-template>
<div class="text-center">
  <h4 class="tab-heading">CONSIGNMENT INFO</h4>
  <hr>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container>
      <button class="btn btn-sm btn-primary float-end" (click)="openTempModal()" [disabled]="contentDisabled">
            <i class="mdi mdi-email-outline" ></i>
            &nbsp; Send Email
          </button>
      <button type="button" class="btn btn-sm btn-primary float-end me-2" (click)="downloadPdf();">
        <i class="mdi mdi-file-export"> </i> &nbsp; Export
      </button>
      <button class="btn btn-sm btn-primary float-end me-2" (click)="viewPdf();">
        <i class="mdi mdi-file-eye"> </i> &nbsp; View
      </button>
    </ng-container>
  </div>
</div>


<div class="row">
  <ng-container
    *ngIf="moveCategory && moveTransport && moveCategory === eMoveCategoryType.DoorToDoorExport || moveCategory === eMoveCategoryType.DoorToPort || moveCategory === eMoveCategoryType.DoorToDoorLocal">
    <div class="col-md-6">
      <label class="mt-3">Shipper</label>
      <textarea class="form-control" placeholder="Shipper Info"
        [(ngModel)]="_consignmentDetail.shipperInfo" rows="3"
        [disabled]="contentDisabled"></textarea>
      <label class="mt-3">Consignee</label>
      <textarea class="form-control" placeholder="Consignee Info"
        [(ngModel)]="_consignmentDetail.consigneeInfo" rows="3"
        [disabled]="contentDisabled"></textarea>
      <label class="mt-3">Notify Address</label>
      <textarea class="form-control" placeholder="Notify Address"
        [(ngModel)]="_consignmentDetail.notifyInfo" rows="3"
        [disabled]="contentDisabled"></textarea>
      <div class="row">
        <div class="col-md-6">
          <ng-container
            *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
            <label class="mt-3">Vessel(s)</label>
            <input type="text" class="form-control" placeholder="Vessels"
              [(ngModel)]="_consignmentDetail.vessels" [disabled]="contentDisabled">
          </ng-container>
        </div>
        <div class="col-md-6">
          <ng-container
            *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
            <label class="mt-3">Voyage No</label>
            <input type="text" class="form-control" placeholder="Voyage No"
              [(ngModel)]="_consignmentDetail.voyageNo" [disabled]="contentDisabled">
          </ng-container>
        </div>
      </div>
      <ng-container
        *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL || moveTransport === eMoveTransportType.Air">
        <label class="mt-3">{{_portLabel}} Port of Loading</label>
        <input type="text" class="form-control" placeholder="Port of Loading"
          [(ngModel)]="_moveDetail.portOfLoading" disabled="true" [disabled]="contentDisabled">
        <label class="mt-3">{{_portLabel}} Port of Discharge</label>
        <input type="text" class="form-control" placeholder="Port of Discharge"
          [(ngModel)]="_moveDetail.portOfDischarge" disabled="true" [disabled]="contentDisabled">
      </ng-container>
    </div>
    <div class="col-md-6">
      <ng-container
        *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
        <label class="mt-3">Forwarding Agent</label>
        <textarea class="form-control" placeholder="Forwarding Agent"
          [(ngModel)]="_consignmentDetail.forwardingAgent" rows="3"
          [disabled]="contentDisabled"></textarea>
      </ng-container>
      <label class="mt-3">Place of Receipt</label>
      <textarea class="form-control" placeholder="Place of Receipt"
        [(ngModel)]="_consignmentDetail.placeOfReceipt" rows="3"
        [disabled]="contentDisabled"></textarea>
      <label class="mt-3">Place of Delivery</label>
      <textarea class="form-control" placeholder="Place of Delivery"
        [(ngModel)]="_moveDetail.placeOfDelivery" rows="3" disabled="true"
        [disabled]="contentDisabled"></textarea>
      <ng-container
        *ngIf="moveTransport === eMoveTransportType.Air || moveTransport === eMoveTransportType.Land">
        <label class="mt-3">Issuing Carrier's Info</label>
        <textarea class="form-control" placeholder="Issuing Carrier Agent Details"
          [(ngModel)]="_consignmentDetail.issuingCarrierDetail" rows="3"
          [disabled]="contentDisabled"></textarea>
      </ng-container>
      <ng-container *ngIf="moveTransport === eMoveTransportType.Air">
        <div class="col-md-12">
          <label class="mt-3">Carrier's IATA Code</label>
          <input type="text" class="form-control" placeholder="Carrier's Agent IATA Code"
            [(ngModel)]="_consignmentDetail.carrierIATACode" [disabled]="contentDisabled">
        </div>
      </ng-container>
      <ng-container *ngIf="moveTransport === eMoveTransportType.Air">
        <div class="col-md-12">
          <label class="mt-3">AWB No</label>
          <input type="text" class="form-control" placeholder="AWB No"
            [(ngModel)]="_consignmentDetail.awbNo" [disabled]="contentDisabled">
        </div>
      </ng-container>
      <div class="row">
        <ng-container
          *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
          <div class="col-md-6">
            <label class="mt-3">Carrier's Reference</label>
            <input type="text" class="form-control" placeholder="Carrier's References"
              [(ngModel)]="_consignmentDetail.carrierReference" [disabled]="contentDisabled">
          </div>
          <div class="col-md-6">
            <label class="mt-3">B/L No</label>
            <input type="text" class="form-control" placeholder="B/L No"
              [(ngModel)]="_consignmentDetail.blNo" [disabled]="contentDisabled">
          </div>
        </ng-container>

      </div>
      <ng-container
        *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
        <label class="mt-3">Export References</label>
        <input class="form-control" placeholder="Export References"
          [(ngModel)]="_consignmentDetail.exportReference" rows="3">
        <label class="mt-3">Consignee's Reference</label>
        <input type="text" class="form-control" [(ngModel)]="_consignmentDetail.consigneeReference"
          placeholder="Consignee's Reference" [disabled]="contentDisabled">
      </ng-container>
    </div>
    <ng-container
      *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL">
      <div class="col-md-12">
        <label class="mt-3">Special Instruction (If any)</label>
        <textarea class="form-control" placeholder="Add Special Instruction (If any)"
          [(ngModel)]="_consignmentDetail.specialInstruction" rows="3"
          [disabled]="contentDisabled"></textarea>
      </div>
    </ng-container>
    <div class="col-md-12 mt-3">
      <h4>Consignment Items</h4>
      <table class="custom-table-sm border border-gray mt-3">
        <thead>
          <tr>
            <th width="10%">Marks & No's</th>
            <th width="10%">No of Packages</th>
            <th
              *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL || moveTransport === eMoveTransportType.Land">
              Net Weight ({{_weightUnit}})</th>
            <th>Gross Weight ({{_weightUnit}})</th>
            <th *ngIf="moveTransport === eMoveTransportType.Air">Chargeable Weight ({{_weightUnit}})
            </th>
            <th>Volume ({{_volumeUnit}})</th>
            <th *ngIf="moveTransport === eMoveTransportType.Air">Commodity Item No</th>
            <th *ngIf="moveTransport === eMoveTransportType.Air">Rate / Charge ({{_currency}})</th>
            <th *ngIf="moveTransport === eMoveTransportType.Air">Total ({{_currency}})</th>
            <th width="30%">Description</th>
            <th *ngIf="_consignmentItemList.length !== 0 && !contentDisabled"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="_consignmentItemList.length === 0">
            <td colspan="7" class="text-center">No Items available</td>
          </tr>
          <tr *ngFor="let row of _consignmentItemList; let index = index">
            <ng-container *ngIf="row.isDeleted === false">
              <td><input class="form-control table-form-control" type="text"
                  [(ngModel)]="row.marksAndNo" [disabled]="contentDisabled"></td>
              <td><input class="form-control table-form-control" type="text"
                  [(ngModel)]="row.noOfPackages" [disabled]="contentDisabled"></td>
              <td
                *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL || moveTransport === eMoveTransportType.Land">
                <input class="form-control table-form-control" type="text"
                  [(ngModel)]="row.netWeight" [disabled]="contentDisabled">
              </td>
              <td><input class="form-control table-form-control" type="text"
                  [(ngModel)]="row.grossWeight" [disabled]="contentDisabled"></td>
              <td *ngIf="moveTransport === eMoveTransportType.Air"><input class="form-control"
                  type="text" [(ngModel)]="row.chargeableWeight" [disabled]="contentDisabled"></td>
              <td><input class="form-control table-form-control" type="text"
                  [(ngModel)]="row.volume" [disabled]="contentDisabled"></td>
              <td *ngIf="moveTransport === eMoveTransportType.Air"><input
                  class="form-control table-form-control" type="text"
                  [(ngModel)]="row.commodityItemNo" [disabled]="contentDisabled"></td>
              <td *ngIf="moveTransport === eMoveTransportType.Air"><input
                  class="form-control table-form-control" type="text" [(ngModel)]="row.rateOrCharge"
                  [disabled]="contentDisabled"></td>
              <td *ngIf="moveTransport === eMoveTransportType.Air"><input
                  class="form-control table-form-control" type="text" [(ngModel)]="row.total"
                  [disabled]="contentDisabled"></td>

              <td><textarea class="form-control desc-form-control" type="text"
                  [(ngModel)]="row.description" row="1"></textarea></td>
              <td class="actions-column" *ngIf="!contentDisabled">
                <ng-container>
                  <button class="btn btn-lg feather icon-trash"
                    (click)="deleteConsignmentItem(index,_consignmentItemList)"></button>
                </ng-container>
                <ng-container>
                  <button class="btn btn-lg feather icon-save" (click)="saveConsignmentItem(index)"
                    [disabled]="contentDisabled"></button>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="d-flex justify-content-center mt-2">
      <ng-container *ngIf="contentDisabled === false">
        <button class="plus-button float-end mt-1" (click)="addConsignmentItem()"
          [disabled]="contentDisabled">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef">
          </lord-icon>
        </button>
      </ng-container>
    </div>
    <ng-container
      *ngIf="moveTransport === eMoveTransportType.Sea || moveTransport === eMoveTransportType.SeaLCL || moveTransport === eMoveTransportType.Air">
      <div class="col-md-6">
        <label class="mt-3">Freight Payment Type</label>
        <ng-select [(ngModel)]="_consignmentDetail.freightPayment"
          [placeholder]="_consignmentDetail.freightPayment ? '' : 'Select Freight Payment Type'"
          [disabled]="contentDisabled">
          <ng-option value="Freight Prepaid">Freight Prepaid</ng-option>
          <ng-option value="Freight Collect">Freight Collect</ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <label class="mt-3">Specification of Freight Charges</label>
        <textarea class="form-control" placeholder="Specifications of Freight Charges"
          [(ngModel)]="_consignmentDetail.specificationOfFreightCharges" rows="3"
          [disabled]="contentDisabled"></textarea>
      </div>
    </ng-container>
    <ng-container *ngIf="moveTransport === eMoveTransportType.Land">
      <div class="col-md-6">
        <label class="mt-3">Received On</label>
        <input class="form-control" placeholder="Recevied On"
          [(ngModel)]="_consignmentDetail.receivedOn" rows="3" [disabled]="contentDisabled">
      </div>
      <div class="col-md-6">
        <label class="mt-3">Delivered On</label>
        <input class="form-control" placeholder="Delivered On"
          [(ngModel)]="_consignmentDetail.deliveredOn" rows="3" [disabled]="contentDisabled">
      </div>
    </ng-container>
    <div class="col-md-12 mt-3">
      <ng-container>
        <button class="btn btn-primary btn-sm float-end" (click)="saveConsignment()"
          [disabled]="contentDisabled">
          <i class="mdi mdi-content-save"></i> &nbsp; SAVE
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>