import { eMoveCategoryType, eMoveType, eProcessStatus, eTransportType } from "../../models/lead-detail.model";
import { eAssignmentType } from "../../models/userAssignment.model";


export interface UnassignDispatchLogResponse {
    unassignedDispatchItems: UnassignedDispatchItemDTO[];
    totalRecords: number;
}

export class UnassignedDispatchItemDTO {
    id: number | null;
    itemId: string | null;
    itemType: string | null;
    customerId: number | null;
    customer: string | null;
    phone: string | null;
    email: string | null;
    account: string | null;
    accountId: number | null;
    accountName: string | null;
    pocId: number | null;
    pocName: string | null;
    thirdPartyId: number | null;
    thirdPartyName: string | null;
    thirdPartyPocId: number | null;
    thirdPartyPocName: string | null;
    status: eProcessStatus | null;
    origin: string | null;
    destination: string | null;
    moveType: string | null;
    moveCategory: string | null;
    moveTransport: string | null;
    surveyDate: Date | null;
    creationDate: Date | null;
    packagingDateFrom: Date | null;
    packagingDateTo: Date | null;
    deliveryDate: Date | null;
    accountManagerId: number | null;
    accountManager: string | null;

    constructor() {
        this.id = null;
        this.itemId = null;
        this.itemType = null;
        this.customerId = null;
        this.customer = null;
        this.phone = null;
        this.email = null;
        this.account = null;
        this.accountId = null;
        this.accountName = null;
        this.pocId = null;
        this.pocName = null;
        this.thirdPartyId = null;
        this.thirdPartyName = null;
        this.thirdPartyPocId = null;
        this.thirdPartyPocName = null;
        this.status = null;
        this.origin = null;
        this.destination = null;
        this.moveType = null;
        this.moveCategory = null;
        this.moveTransport = null;
        this.surveyDate = null;
        this.creationDate = null;
        this.packagingDateFrom = null;
        this.packagingDateTo = null;
        this.deliveryDate = null;
        this.accountManagerId = null;
        this.accountManager = null;
    }
}




export const allUnAssignedColumns: string[] = [
    "Item ID",
    "Type",
    "Customer",
    "Phone",
    "Email",
    "Account",
    "POC",
    "Third Party",
    "Third Party POC",
    "Status",
    "Origin",
    "Destination",
    "Move Type",
    "Move Category",
    "Move Transport",
    "Survey Date",
    "Pack Date",
    "Delivery Date",
    "Account Manager"
];
export const columnToProp: { [key: string]: string } = {
    "Item ID": "itemId",
    "Type": "type",
    "Customer": "customer",
    "Phone": "phone",
    "Email": "email",
    "Account": "account",
    "POC": "pocName",
    "Third Party": "thirdPartyName",
    "Third Party POC": "thirdPartyPocName",
    "Status": "status",
    "Origin": "origin",
    "Destination": "destination",
    "Move Type": "moveTypeLabel",
    "Move Category": "moveCategoryLabel",
    "Move Transport": "moveTransportLabel",
    "Survey Date": "surveyDate",
    "Pack Date": "packagingDate",
    "Delivery Date": "deliveryDate",
    "Account Manager": "accountManager"
};

export class AssignedDispatchItemDTO {
    id: number;
    type: eAssignmentType | null;
    userId: number | null;
    customerId: number | null;
    customer: string | null;
    status: eProcessStatus | null;
    email: string | null;
    creationDate: Date | null;
    assignedTimeSlot: string | null;
    itemId: string | null;

    constructor() {
        this.id = 0;
        this.type = null;
        this.userId = null;
        this.customerId = null;
        this.customer = null;
        this.status = null;
        this.email = null;
        this.creationDate = null;
        this.assignedTimeSlot = null;
        this.itemId = null;
    }
}

export class AssignedDispatchItemByIdDTO {
    id: number | null;
    itemId: string | null;
    type: number | null;
    customerId: number | null;
    customer: string | null;
    phone: string | null;
    email: string | null;
    account: string | null;
    accountId: number | null;
    accountName: string | null;
    pocId: number | null;
    pocName: string | null;
    thirdPartyId: number | null;
    thirdPartyName: string | null;
    thirdPartyPocId: number | null;
    thirdPartyPocName: string | null;
    status: eProcessStatus | null;
    origin: string | null;
    destination: string | null;
    moveType: eMoveType | null;
    moveCategory: eMoveCategoryType | null;
    moveTransport: eTransportType | null;
    surveyDate: Date | null;
    creationDate: Date | null;
    packagingDateFrom: Date | null;
    packagingDateTo: Date | null;
    deliveryDate: Date | null;
    accountManagerId: number | null;
    accountManager: string | null;

    constructor() {
        this.id = null;
        this.itemId = null;
        this.type = null;
        this.customerId = null;
        this.customer = null;
        this.phone = null;
        this.email = null;
        this.account = null;
        this.accountId = null;
        this.accountName = null;
        this.pocId = null;
        this.pocName = null;
        this.thirdPartyId = null;
        this.thirdPartyName = null;
        this.thirdPartyPocId = null;
        this.thirdPartyPocName = null;
        this.status = null;
        this.origin = null;
        this.destination = null;
        this.moveType = null;
        this.moveCategory = null;
        this.moveTransport = null;
        this.surveyDate = null;
        this.creationDate = null;
        this.packagingDateFrom = null;
        this.packagingDateTo = null;
        this.deliveryDate = null;
        this.accountManagerId = null;
        this.accountManager = null;
    }
}


export class DispatchBoardStatsDTO {
    type: eAssignmentType;
    new: number;
    inProgress: number;
    completed: number;
    cancelled: number;
    total: number;

    constructor() {
        this.type = 0;
        this.new = 0;
        this.inProgress = 0;
        this.completed = 0;
        this.cancelled = 0;
        this.total = 0;
    }
}
