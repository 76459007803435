<div class="center">
  <h4 class="tab-heading">Summary </h4>
  <hr>
</div>

<ng-template #surveyPrintTemplate>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center">
        <ng-container *ngIf="_companySetting.logo as companyLogo; else defaultLogo">
          <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo"
            width="150">
        </ng-container>
        <ng-template #defaultLogo>
          <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
        </ng-template>
      </div>

      <h1 class="text-center mt-3"> Summary</h1>
      &nbsp;
      <h4> General Info</h4>
      <table class="table table-bordered mt-2" *ngIf="_leadSummary">
        <tbody>
          <tr>
            <td><label>Lead ID</label></td>
            <td>{{ _leadSummary.leadDetailId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer ID</label></td>
            <td>#{{ _leadSummary.customerId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer Name</label></td>
            <td>{{ _leadSummary.customer || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Account Name</label></td>
            <td>{{ _leadSummary.accountName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Move Type</label></td>
            <td>{{ enumMappingService.mapMoveType(_leadSummary.moveType || 0) }}</td>
          </tr>
          <tr>
            <td><label>Move Category</label></td>
            <td>{{ enumMappingService.mapMoveCategoryType(_leadSummary.moveCategory || 0) }}</td>
          </tr>
          <tr>
            <td><label>Transport Type</label></td>
            <td>{{ enumMappingService.mapTransportType(_leadSummary.moveTransport || 0) }}</td>
          </tr>
          <tr>
            <td><label>POL</label></td>
            <td>{{ _leadSummary.portOfLoading || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>POD</label></td>
            <td>{{ _leadSummary.portOfDischarge || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Place Of Delivery</label></td>
            <td>{{ _leadSummary.placeOfDelivery || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Requested Survey Date</label></td>
            <td>{{ _leadSummary.surveyDate || ' - '}}</td>
          </tr>
          <tr>
            <td><label>Requested Packing Date</label></td>
            <td>{{ _leadSummary.packagingDate || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Requested Loading Date</label></td>
            <td>{{ _leadSummary.loadingDate || ' - ' }}</td>
          </tr>
          <tr>
          <tr>
            <td><label>Loading Time</label></td>
            <td>{{ _loadingTime || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Client Notes</label></td>
            <td>{{ _leadSummary.additionalNotes || ' - ' }}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4>Address Details</h4>
      <table class="table table-bordered mt-2" *ngIf="_leadDetail">
        <tbody>
          <ng-container *ngFor="let i of [0, 1, 2]">
            <ng-container *ngIf="_originAddresses[i] || _destinationAddresses[i]">
              <tr *ngIf="i !==0 ">
                <td colspan="4"><strong>{{ i === 0 ? 'First' : i === 1 ? 'Second' : 'Third' }}
                    Address</strong></td>
              </tr>
              <tr>
                <td><label><strong>Origin</strong></label></td>
                <td></td>
                <td><label><strong>Destination</strong></label></td>
                <td></td>
              </tr>
              <tr>
                <td><label>Steet</label></td>
                <td>{{ _originAddresses[i].streetAddress || '-' }}</td>
                <td><label>Steet</label></td>
                <td>{{ _destinationAddresses[i].streetAddress || '-' }}</td>
              </tr>
              <tr>
                <td><label>City</label></td>
                <td>{{ _originAddresses[i].city || '-' }}</td>
                <td><label>City</label></td>
                <td>{{ _destinationAddresses[i].city || '-' }}</td>
              </tr>
              <tr>
                <td><label>State</label></td>
                <td>{{ _originAddresses[i].state || '-' }}</td>
                <td><label>State</label></td>
                <td>{{ _destinationAddresses[i].state || '-' }}</td>
              </tr>
              <tr>
                <td><label>Country</label></td>
                <td>{{ _originAddresses[i].country || '-' }}</td>
                <td><label>Country</label></td>
                <td>{{ _destinationAddresses[i].country || '-' }}</td>
              </tr>
              <tr>
                <td><label>Postal Code</label></td>
                <td>{{ _originAddresses[i].zipCode || '-' }}</td>
                <td><label>Postal Code</label></td>
                <td>{{ _destinationAddresses[i].zipCode || '-' }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <!-- &nbsp;
      <h4> Survey Details</h4>

      <table class="table table-bordered mt-4">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Voulme ({{_volumeUnit}})</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of _areaItems; let i = index">
            <ng-container *ngIf="!item.isDeleted">
              <tr *ngIf="i === 0 || item.surveyPlace !== _areaItems[i - 1].surveyPlace">

                <td colspan="4">
                  <strong>{{ item.surveyPlace || '' }}</strong> &nbsp; (Item count: &nbsp; {{
                  getItemCount(item.surveyPlace || '') }},
                  Volume: {{ calculateVolume(item.surveyPlace || '', _volumeUnit, 'bySurveyPlace') }},
                  Weight: {{ calculateWeight(item.surveyPlace || '', _weightUnit, 'bySurveyPlace') }})
                </td>
              </tr>

              <tr>
                <td>{{ item.item || ' - ' }}</td>
                <td>{{ item.quantity || ' - ' }}</td>
                <td>{{ item.volume ? item.volume.toFixed(2) : ' - ' }}</td>
                <td>{{ item.weight ? item.weight.toFixed(2) : ' - ' }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>

      </table> -->
      &nbsp;
      <h4> Shipment</h4>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Shipment</th>
            <th>Volume ({{_volumeUnit}})</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ enumMappingService.mapTransportType(_leadSummary.moveTransport || 0) }}</td>
            <td>{{calculateVolume('', _volumeUnit, 'total')}}</td>
            <td>{{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</ng-template>

<ng-template #basicModal let-modal>
  <div class="modal-dialog custom-modal-dialog">
    <div class="modal-body">
      <ng-container [ngTemplateOutlet]="surveyPrintTemplate"></ng-container>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-danger btn-sm" (click)="modal.close('by: close button')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
    </div> -->
  </div>
</ng-template>

<ng-template #noData>
  <p>- - - -</p>
</ng-template>
<!-- <div *ngIf="_isDataLoaded"> -->
<div *ngIf="_readPermission === true">
  <!-- <div class="float-end">
    <button class="btn btn-sm btn-primary" (click)="exportSummary()">Export Summary</button>
  </div> -->
  <div class="col-md-12">
    <button type="button" class="btn btn-primary btn-sm float-end " (click)="downloadPdf();">
      Export Summary &nbsp;<i class="mdi mdi-file-export"></i>
    </button>
    <button class="btn btn-primary btn-sm float-end export-button" (click)="viewPdf();">
      View Summary &nbsp;<i class="mdi mdi-file-eye"></i>
    </button>
  </div>
  <br />
  <div #pdfSummary id="pdfSummary" class="container">
    <div class="container mt-5">
      <div class="col-md-12 mt-4">
        <div class="row">
          <!-- First Row -->
          <div class="col-md-3">
            <label class="fw-bold">Lead ID:</label>
            <p class="mt-2 text-muted">
              {{ leadDetailId ? _leadSummary.formattedLeadDetailId : '- - - -' }}
            </p>
          </div>

          <div class="col-md-3">
            <label class="fw-bold">Email:</label>
            <p class="mt-2 text-muted">{{ _leadSummary.email || ' - - - - ' }}</p>
          </div>
          <div class="col-md-3">
            <label class="fw-bold">Customer:</label>
            <p class="mt-2 text-muted">
              <ng-container *ngIf="_leadSummary.customerId; else noData">
                <a (click)="redirectToContact(_leadSummary.customerId)" class="clickable-link">
                  {{ _leadSummary.customer }}
                </a>
              </ng-container>
            </p>
          </div>
          <div class="col-md-3">
            <label class="fw-bold">Phone:</label>
            <p class="mt-2 text-muted">{{ _leadSummary.phone || ' - - - - ' }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <!-- Second Row -->
          <div class="col-md-3">
            <label class="fw-bold">Account:</label>
            <ng-container *ngIf="_leadSummary.accountName; else noData">
              <p class="mt-2 text-muted">
                <a (click)="redirectToAccount(_leadSummary.accountId)" class="clickable-link">
                  {{ _leadSummary.accountName }}
                </a>
              </p>
            </ng-container>
          </div>

          <div class="col-md-3">
            <label class="fw-bold">POC:</label>
            <ng-container *ngIf="_leadSummary.pocName; else noData">
              <p class="mt-2 text-muted">
                <a (click)="redirectToContact(_leadSummary.pocId)" class="clickable-link">
                  {{ _leadSummary.pocName }}
                </a>
              </p>
            </ng-container>
          </div>

          <div class="col-md-3">
            <label class="fw-bold">Third Party:</label>
            <ng-container *ngIf="_leadSummary.thirdPartyName; else noData">
              <p class="mt-2 text-muted">
                <a (click)="redirectToAccount(_leadSummary.thirdPartyId)" class="clickable-link">
                  {{ _leadSummary.thirdPartyName }}
                </a>
              </p>
            </ng-container>
          </div>
          <div class="col-md-3">
            <label class="fw-bold">Third Party POC:</label>
            <ng-container *ngIf="_leadSummary.thirdPartyPocName; else noData">
              <p class="mt-2 text-muted">
                <a (click)="redirectToContact(_leadSummary.thirdPartyPocId)" class="clickable-link">
                  {{ _leadSummary.thirdPartyPocName }}
                </a>
              </p>
            </ng-container>
          </div>
        </div>

      </div>
      <hr *ngIf="!(_originAddresses.length == 0)">

      <div class="col-md-12" *ngFor="let origin of _originAddresses">
        <i class="mdi mdi-map-marker-radius"></i>
        <label class="text-muted small address-text">
          {{ origin.streetAddress ? origin.streetAddress + ', ' : '' }}
          {{ origin.city }}
        </label>
      </div>
      <div class="col-md-12" *ngIf="!(_originAddresses.length == 0)">
        <div class="d-flex">
          <i class="fas fa-ellipsis-v fa-1x mx-1 m-1 move-type"></i>
        </div>
      </div>

      <div class="col-md-12" *ngFor="let destination of _destinationAddresses">
        <i class="mdi mdi-flag-outline"></i>
        <label class="text-muted small address-text">
          {{ destination.streetAddress ? destination.streetAddress + ',': '' }} {{ destination.city
          }}
        </label>
      </div>
      <hr>
      <div class="row mt-2">
        <div class="col-md-3">
          <label class="custom-color">
            <strong>{{ _leadSummary.moveTypeLabel || ' - - - - ' }}</strong>
          </label>
        </div>
        <div class="col-md-3">
          <label class="move-category">
            <strong> {{_leadSummary.moveCategoryLabel || ' - - - - ' }}</strong>
          </label>
        </div>
        <div class="col-md-3">
          <label class="move-category">
            <strong>{{ _leadSummary.moveTransportLabel || ' - - - - ' }}</strong>
          </label>
        </div>
        <ng-container
          *ngIf="_leadDetail.categoryType == eMoveCategoryType.DoorToDoorExport || _leadDetail.categoryType == eMoveCategoryType.DoorToPort  && _leadDetail.transportType == eTransportType.Sea || _leadDetail.transportType == eTransportType.SeaLCL || _leadDetail.transportType == eTransportType.Air">
          <div class="col-md-3">
            <label class="move-category"><strong> To POL:</strong>
              <ng-container *ngIf="_leadSummary.portOfLoading; else dashes">
                {{ _leadSummary.portOfLoading }}
              </ng-container>
              <ng-template #dashes>- - - -</ng-template></label>
          </div>
          <div class="col-md-3 mt-2">
            <label class="fw-bold"><strong> To POD:</strong>
              <ng-container *ngIf="_leadSummary.portOfDischarge; else dashes">
                {{ _leadSummary.portOfDischarge }}
              </ng-container>
              <ng-template #dashes>- - - -</ng-template></label>
          </div>
        </ng-container>
        <div class="col-md-3 mt-2">
          <label class="move-category"><strong> Place of Delivery:</strong>
            <ng-container *ngIf="_leadSummary.placeOfDelivery; else dashes">
              {{ _leadSummary.placeOfDelivery }}
            </ng-container>
            <ng-template #dashes>- - - -</ng-template></label>
        </div>
      </div>

      <div class="row mt-4">
        <hr class="border-dark-grey" />
      </div>
      <div class="row">
        <div class="col-md-3">
          <i class="mdi mdi-scale">&nbsp;&nbsp;&nbsp;</i>
          <label class="fw-bold">LOAD DETAILS</label>
          <p class="mt-2 text-muted"> {{calculateLoadDetails() || ' - - - -'}}</p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">ITEMS</label>
          <p class="mt-2 text-muted"> {{ _areaItems.length < 10 ? '0' + _areaItems.length :
              _areaItems.length }} </p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">Volume ({{_volumeUnit}}) </label>
          <p class="mt-2 text-muted"> {{_totalWeight}} </p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">WEIGHT ({{_weightUnit}}) </label>
          <p class="mt-2 text-muted"> {{_totalWeight}} </p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">PICTURES</label>
          <p class="mt-2 text-muted"> {{_attachments.length}} </p>
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <hr class="border-dark-grey" />
      </div>

      <div class="row">
        <div class="col-md-3">
          <label class="fw-bold">LABOUR</label>
          <p class="mt-2 text-muted">{{ getTotalLabour() }} </p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">VEHICLES</label>
          <p class="mt-2 text-muted"> {{ getTotalVehicles() }}</p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">MOVE MANAGER</label>
          <p class="mt-2 text-muted"> - - -</p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">Insurance</label>
          <p class="mt-2 text-muted">{{ _leadSummary.insuranceRequired === true ? 'Yes' : 'No' }}
          </p>
        </div>
        <div class="col-md-2">
          <label class="fw-bold">Quotation No.</label>
          <p class="mt-2 text-muted"> # {{_leadQuotation.id || 0}} </p>
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <hr class="border-dark-grey" />
      </div>

      <div class="row">
        <div class="col-md-3">
          <label class="fw-bold">Requested Packing Date</label>
          <p class="mt-2 text-muted">
            {{ _leadSummary.packagingDate || ' - - - -'}}
          </p>
        </div>
        <div class="col-md-3">
          <label class="fw-bold">Requested Survey Date</label>
          <p class="mt-2 text-muted">
            {{ _leadSummary.formattedSurveyDate || ' - - - -' }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="fw-bold">Requested Loading Date</label>
          <p class="mt-2 text-muted">
            {{ _leadSummary.loadingDate || ' - - - -' }}
          </p>
        </div>
        <div class="col-md-3">
          <label class="fw-bold">Requested Delivery Date</label>
          <p class="mt-2 text-muted">
            {{ _leadSummary.formattedDeliveryDate || ' - - - -' }}
          </p>
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <hr class="border-dark-grey" />
      </div>

      <div class="row">
        <div class="col-md-3" *ngIf="_leadSummary.storageDateFrom">
          <label class="fw-bold">Requested Storage Date</label>
          <p class="mt-2 text-muted">
            {{ _leadSummary.storageDate || ' - - - -' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>
<div #pdfComponent class="hidden-content">
  <ng-container [ngTemplateOutlet]="surveyPrintTemplate"></ng-container>
  <!-- Other content -->
</div>


<!--<div class="col-md-5">
          <label class="fw-bold">QUOTATION AMOUNT</label>
          <div class="input-group">
            <input type="number" class="form-control mt-2" [(ngModel)]="_moveSurvey.quotationAmount"
                   placeholder="Enter Quotation Amount" />
            <div class="fw-bold mt-2">
              <label class="input-group-text">{{ selectedCurrencySymbol }}</label>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <hr class="border-dark-grey" />
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label fw-bold m-0 mt-3">
              &nbsp; Additional Notes
            </label>
            <textarea class="form-control border border-grey" rows="5" [(ngModel)]="_moveSurvey.externalNotes"
                      placeholder="Write Additional notes for uniques items like arts, etc."></textarea>
          </div>
        </div>-->