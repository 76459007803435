import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { AreaItemsModel, AreaItemsResponse, SurveyPlace, AllSurveyPlacesResponse } from '../../models/movd-comp-admin.model';
import { Status, eStatusType } from '../../models/status.model';
import { eMoveCategoryType } from '../../models/lead-detail.model';
import { CompanySetting, getCurrencySymbol, getDimensionUnitShortLabel, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';

@Component({
  selector: 'app-survey-settings',
  templateUrl: './survey-settings.component.html',
  styleUrls: ['./survey-settings.component.scss']
})
export class SurveySettingsComponent implements OnInit {
  // @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('addAreaModal') addAreaModal: any;
  @ViewChild('addAreaItemsModal') addAreaItemsModal: any;
  @ViewChild('addStatusModal') addStatusModal: any;
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  defaultNavActiveId: number = 1;
  ColumnMode = ColumnMode;


  // for opening pop modals
  _areaModalRef: NgbModalRef | undefined;
  _areaItemsModalRef: NgbModalRef | undefined;



  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _dimensionUnit: string = '';
  _currency: any;

  // objects
  // _companySetting = new CompanySetting();

  // _weightUnit = WeightUnitOptions;
  // _volumeUnit = VolumeUnitOptions;
  // _currency = CurrencyOptions;
  // _selectedWeightUnit!: WeightUnit | null;
  // _selectedVolumeUnit!: VolumeUnit | null;
  // _selectedCurrency: Currency = Currency.USD;
  // _selectedColor = "#7987a1";

  selectedLogoFile: File | null = null;
  logoBase64String: string = '';

  // _area: AreaModel = {
  //   id: 0,
  //   ownedBy: 0,
  //   title: '',
  //   areas: [],
  //   createdBy: 0,
  //   isDeleted: false,
  // }

  _area = new SurveyPlace();
  _areaTitle!: string | null;

  _searchArea: string | null = null;
  _areaTableData: any[] = [];
  _areaDataList: any[] = [];
  _areaTableColumns: any[] = [];
  _surveyPlaces: SurveyPlace[] = [];
  _saveTitle = "Create";
  _title = "Add New Area";

  _areaItems: AreaItemsModel = {
    id: 0,
    ownedBy: 0,
    surveyPlaceId: 0,
    title: '',
    //areaItems: [],
    createdBy: 0,
    isDeleted: false,
    estimatedVolume: 0,
    weight: 0,
    dimension:''

  }

  _searchAreaItems: string = '';
  _areaItemsTableData: any[] = [];
  _areaItemsDataList: any[] = [];
  _areaItemsTableColumns: any[] = [];
  _surveyItems: AreaItemsModel[] = [];

  _status = new Status();
  _searchStatus: string = '';
  _statusTableData: any[] = [];
  _statusTableColumns: any[] = [];
  _statuses: Status[] = [];


  selectedCurrency: string = 'PKR';
  currencyOptions: string[] = ['PKR', 'USD', 'EUR', 'GBP'];
  selectedMeasurementUnit: string = 'KG';
  measurementUnitOptions: string[] = ['KG', 'Foot', 'Inch', 'Centimeter'];
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  _currentPage = 1;
  _pageSize = 10;
  _areaTotalRecords = 0;
  _areaItemsTotalRecords = 0;

  selectedTheme: string = 'light';

  themeOptions = [
    { label: 'Light', value: 'light' },
    { label: 'Dark', value: 'dark' }
  ];

  //for validations
  areaExists: boolean = false;


  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Survey Settings', url: '/survey-setting' },


    ]);

    this.refreshAreaTableData();

    this.getCompanySetting();
    this.refreshAreaItemsTableData();


  }


  onChangeTab(_type: any) {

  }
  onAreaPageChange(event: any): void {
    this._currentPage = event.offset + 1;
    this.refreshAreaTableData();
  }
  onSearchArea(): void {
    this._currentPage = 1;
    this.refreshAreaTableData();
  }
  onSearchAreaItems(): void {
    this._currentPage = 1;
    this.refreshAreaItemsTableData();
  }
  onAreaItemsPageChange(event: any): void {
    this._currentPage = event.offset + 1;
    this.refreshAreaItemsTableData();
  }
  // performAreasSearch() {
  //   // Check if the search term is empty
  //   if (this._searchArea === '') {
  //     // Reset the displayed leads list to the original data
  //     this._areaTableData = this._areaDataList;
  //   } else {
  //     // Filter the original data array based on the search term
  //     const filteredData = this._areaDataList.filter((row: any) => {
  //       // Convert all values of the row to string and check if any value contains the search term
  //       return Object.values(row).some((value: any) => {
  //         if (typeof value === 'string') {
  //           return value.toLowerCase().includes(this._searchArea.toLowerCase());
  //         }
  //         return false; // Skip non-string values
  //       });
  //     });

  //     // Assign the filtered data to the displayed leads array
  //     this._areaTableData = filteredData;
  //   }

  //   // Reset the page to the first page
  //   // if (this.table) {
  //   //   this.table.offset = 0;
  //   // }

  //   // // Recalculate and update the table
  //   // this.table?.recalculate();
  //   // this.table?.bodyComponent.updateOffsetY();

  // }
  // performAreasSearch() {
  //   if (this._searchArea === '') {
  //     this.refreshAreaTableData();
  //   } else {
  //     const filteredData = this._areaTableData.filter((row: any) =>
  //       Object.values(row).some((value: any) =>
  //         String(value).toLowerCase().includes(this._searchArea.toLowerCase())
  //       )
  //     );
  //     this._areaTableData = filteredData;
  //   }

  //   if (this.table) {
  //     this.table.offset = 0;
  //   }

  //   this.table?.recalculate();
  //   this.table?.bodyComponent.updateOffsetY();
  // }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }


  refreshAreaTableData() {

    // const companyId = Number(localStorage.getItem('companyId'));
    this._movdService.getAllSurveyPlaces(this._currentPage, this._pageSize, this._searchArea).subscribe({
      next: (data: AllSurveyPlacesResponse) => {
        if (data.surveyPlaces && data.surveyPlaces.length > 0) {
          this._areaTotalRecords = data.totalRecords
          this._areaTableData = data.surveyPlaces;
          this._areaDataList = data.surveyPlaces;
          this._areaTableColumns = Object.keys(this._areaDataList[0])
            .filter(
              (key) =>
                ![
                  'id',
                  'companyId',
                  'creationDate',
                  'deletionDate',
                  'createdBy',
                  'modificationDate',
                  'modifiedBy',
                  'isDeleted',
                  'isActive',
                  'ownedBy'
                ].includes(key)
            )
            .map((key) => {
              let header: string;
              let isCheckbox: boolean = false;

              switch (key) {
                case 'title':
                  header = 'Area Name';
                  break;
                case 'action':
                  header = 'Action';
                  break;

                default:
                  header = key; // Use the original key if no specific header is defined
                  break;
              }

              return { header, isCheckbox };
            });

        }
        else {
          this._areaTableData = [];
        }
      },
      error: (error: any) => {
        console.error('Error getting survey places', error);
      }
    });
  }

  getAreaAndOpenModal(id: number): void {
    this.areaExists = false;
    const data = this._areaTableData.filter(item => item.id === id)[0];
    if (data) {
      this._area = data;
      this._areaTitle = this._area.title;
      this.openEditArea();
    }
  }

  AreaPreviewClick(id: number) {
    this.getAreaAndOpenModal(Number(id));
  }

  openEditArea() {
    this._saveTitle = "Update";
    this._title = "Update Area Name";
    // this._modalService.open(this.addAreaModal, { size: 'md' }).result.then(
    //   (result) => {
    //   }
    // ).catch((res) => { });
    this._areaModalRef = this._modalService.open(this.addAreaModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._areaModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  openAreaModal(content: any): void {
    this._saveTitle = "Save";
    this._title = "Add New Area";
    // this._area.title = '';
    this._area = new SurveyPlace();
    // this._modalService.open(content, { centered: true });
    this._areaModalRef = this._modalService.open(content, {
      backdrop: 'static',
      keyboard: false,
    });

    this._areaModalRef.result.then(
      (result) => {

      },
      (reason) => {

        if (reason === 'backdrop-click') {

        }
      }
    );
  }

  saveAndCloseArea(modal: any): void {
    if (this.areaExists === true || this._areaTitle === null) {
      // Handle the case when the email already exists in 'tableData'
      // You can show an error message or take appropriate action.
      // For example:
      // this.errorMessage = 'Email already exists.';
      return;
    }

    this._area.title = this._areaTitle;

    const companyId = Number(localStorage.getItem('companyId'));
    this._area.ownedBy = companyId;
    // console.log('Save survey place response', this._area);
    this._movdService.saveSurveyPlace(this._area).subscribe({
      next: (response: any) => {
        // modal.close();
        this._areaTitle = null;
        this._areaModalRef?.close();
        // console.log('Save survey place response', response);
        this._appComponent.showSuccessSwal('created', 'record');
        this.refreshAreaTableData();
        // this.table?.recalculate();
        // this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('creating', 'record');
        console.error('Error', error);
      }
    });

  }

  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.dimensionUnit) {
            this._dimensionUnit = getDimensionUnitShortLabel(response.dimensionUnit)
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        } else {
          this._companySetting = new CompanySetting();
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
    this.refreshAreaItemsTableData();

  }


  async updateAreaStatus(row: any, status: any, softDelete = false) {
    const areaId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this area',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._movdService.updateSurveyPlaceStatus(areaId, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'area');
        this.refreshAreaTableData();
        // this.table?.recalculate();
        // this.table?.bodyComponent.updateOffsetY();
        // console.log('area status updated', response);
      },
      error: (error: any) => {
        console.error('Error updating area status:', error);
        this._appComponent.showErrorSwal('updating', 'area');
      }
    });
  }

  // Area Items Functions
  // performAreaItemsSearch() {
  //   // Check if the search term is empty
  //   if (this._searchAreaItems === '') {
  //     // Reset the displayed leads list to the original data
  //     this._areaItemsTableData = this._areaItemsDataList;
  //   } else {
  //     // Filter the original data array based on the search term
  //     const filteredData = this._areaItemsDataList.filter((row: any) => {
  //       // Convert all values of the row to string and check if any value contains the search term
  //       return Object.values(row).some((value: any) => {
  //         if (typeof value === 'string') {
  //           return value.toLowerCase().includes(this._searchAreaItems.toLowerCase());
  //         }
  //         return false; // Skip non-string values
  //       });
  //     });

  //     // Assign the filtered data to the displayed leads array
  //     this._areaItemsTableData = filteredData;
  //   }

  //   // Reset the page to the first page
  //   // if (this.table) {
  //   //   this.table.offset = 0;
  //   // }

  //   // // Recalculate and update the table
  //   // this.table?.recalculate();
  //   // this.table?.bodyComponent.updateOffsetY();
  // }

  checkHeader(header: string, unit: string) {
    return header === `Est. Volume (${unit})` || header === `Weight (${unit})` || header === `Dimension (${unit})`;
  }
  

  refreshAreaItemsTableData() {
    // const companyId = Number(localStorage.getItem('companyId'));
    this._movdService.getAllSurveyItems(this._currentPage, this._pageSize, this._searchAreaItems).subscribe({
      next: (data: AreaItemsResponse) => {
        if (data && data.surveyItems && data.surveyItems.length > 0) {
          this._areaItemsTotalRecords = data.totalRecords;
          this._areaItemsDataList = data.surveyItems;
          this._areaItemsTableData = this._areaItemsDataList.map(element => {
            if (element.surveyPlaceId) {
              let area = this._areaTableData.find(item => item.id === element.surveyPlaceId);
              element.area = area ? area.title : null;
            } else {
              element.area = null;
            }
            return element;
          });

          const orderedKeys = ['title', 'area', 'estimatedVolume', 'weight', 'dimension'];

          // Assuming orderedKeys is an array of strings
          this._areaItemsTableColumns = orderedKeys.map((key) => {
            let header: string;
            let isCheckbox: boolean = false;

            let prop: string = key; // Initialize prop here to avoid duplicate declarations

            switch (key) {
              case 'title':
                header = 'Item Name';
                break;
              case 'area':
                header = 'Area';
                break;
              case 'estimatedVolume':
                header = `Est. Volume (${this._volumeUnit})`;
                break;
              case 'weight':
                header = `Weight (${this._weightUnit})`;
                break;
              case 'dimension':
                header = `Dimension (${this._dimensionUnit})`;
                break;
              case 'action':
                header = 'Action';
                break;
              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }
            // Return an object with the specified properties
            return { header, prop, isCheckbox };
          });

          

        }
        else {
          this._areaItemsDataList = [];
        }
      },
      error: (error: any) => {
        console.error('Error getting survey items', error);
      }
    });
   
  

  }




  get showVolumeUnit(): boolean {
    return this._areaItemsTableColumns.some(c => c.header === 'Est. Volume');
  }

  get showWeightUnit(): boolean {
    return this._areaItemsTableColumns.some(c => c.header === 'Weight');
  }

  getAreaItemsAndOpenModal(id: number): void {
    const data = this._areaItemsTableData.filter(item => item.id === id)[0];
    if (data) {
      this._areaItems = data;
      this.openEditAreaItems();
    }
  }

  AreaItemsPreviewClick(id: number) {
    this.getAreaItemsAndOpenModal(Number(id));
  }

  openEditAreaItems() {
    this._saveTitle = "Update";
    this._title = "Update Area Item";
    // this._modalService.open(this.addAreaItemsModal, { size: 'md' }).result.then(
    //   (result) => {
    //   }
    // ).catch((res) => { });
    this._areaItemsModalRef = this._modalService.open(this.addAreaItemsModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._areaItemsModalRef.result.then(
      (result) => { },
      (reason) => {

        if (reason === 'backdrop-click') {

        }
      }
    );
  }

  openAreaItemsModal(content: any): void {
    this._saveTitle = "Save";
    this._title = "Add New Area Item"
    // this._areaItems.title = '';
    this.areaExists = false;
    this._areaItems = new AreaItemsModel();
    // this._modalService.open(content, { centered: true });
    this._areaItemsModalRef = this._modalService.open(content, {
      backdrop: 'static',
      keyboard: false,
    });

    this._areaItemsModalRef.result.then(
      (result) => { },
      (reason) => {

        if (reason === 'backdrop-click') {

        }
      }
    );
  }

  saveAndCloseAreaItems(modal: any): void {
    const companyId = Number(localStorage.getItem('companyId'));
    this._areaItems.ownedBy = companyId;
    // console.log('Survey model :', JSON.stringify(this._areaItems));
    this._movdService.saveSurveyItem(this._areaItems).subscribe({
      next: (response: any) => {
        // modal.close();
        this._areaItemsModalRef?.close();
        // console.log('Save survey Items response', response);
        this._appComponent.showSuccessSwal('created', 'record');
        this.refreshAreaItemsTableData();
        // this.table?.recalculate();
        // this.table?.bodyComponent.updateOffsetY();

      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('creating', 'record');
        console.error('Error', error);
      }
    });

  }

  async updateAreaItemsStatus(row: any, status: any, softDelete = false) {
    const areaItemsId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this area item',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted'
      }
    }

    this._movdService.updateSurveyItemStatus(areaItemsId, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'area');
        this.refreshAreaItemsTableData();
        // this.table?.recalculate();
        // this.table?.bodyComponent.updateOffsetY();
        // console.log('area Items status updated', response);
      },
      error: (error: any) => {
        console.error('Error updating area Items status:', error);
        this._appComponent.showErrorSwal('updating', 'area');
      }
    });
  }

  isButtonDisabled(modalType: any): boolean {
    if (modalType === 'area') {
      return this._area.title.trim() === '' || !/^[A-Za-z0-9\s]{1,15}$/.test(this._area.title);
    }
    else if (modalType === 'areaItems') {
      const isTitleValid = this._areaItems.title.trim() !== '' && /^[A-Za-z0-9\s]{1,15}$/.test(this._areaItems.title);
      const isSurveyPlaceIdValid = this._areaItems.surveyPlaceId !== null;

      return !(isTitleValid && isSurveyPlaceIdValid);
    }

    else {
      return this._status.title.trim() === '' || !/^[A-Za-z0-9\s]{1,15}$/.test(this._status.title);

    }
  }




  validateItemExists(): void {
    const input = this._areaTitle;
    if (!input || input.trim().length === 0) {
      // Invalid or empty email format, reset 'emailExists' and return.
      this.areaExists = false;
      return;
    }

    if (this._areaTableData && this._areaTableData.length > 0) {
      this.areaExists = this._areaTableData.some(item => item.title.toLowerCase() === input.toLowerCase());

    } else {
      this.areaExists = false; // Reset 'emailExists' if 'tableData' is empty

    }
  }

  closeModal(type: string, modal: any) {
    switch (type) {
      case 'Area':
        this._area = new SurveyPlace();
        this._areaTitle = null;
        this.areaExists = false;
        this.refreshAreaTableData();
        modal.close();
        break;
      case 'AreaItems':
        this._areaItems = new AreaItemsModel();
        this.refreshAreaItemsTableData();
        modal.close();
        break;

      default:
      // console.log('Unknown type: ' + type);
    }
  }

}
function foreach(arg0: boolean) {
  throw new Error('Function not implemented.');
}

