export class CustomerSatisfactionSummarizeReportDTO {
    date: Date | null;
    dissatisfaction: number | null;
    poor: number | null;
    satisfied: number | null;
    veryGood: number | null;
    excellent: number | null;
    overallRate: number | null;

    constructor() {
        this.date = null;
        this.dissatisfaction = null;
        this.poor = null;
        this.satisfied = null;
        this.veryGood = null;
        this.excellent = null;
        this.overallRate = null;
    }
}
