import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MoveStatus, StatusWithMoveStatus } from '../models/move-status.model';
import { MovdService } from '../services/movd.service';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Status } from '../models/status.model';
import { AppComponent } from 'src/app/app.component';
import { LeadDetail } from '../models/lead-detail.model';
import { MoveDetail } from '../models/move-detail.model';

@Component({
  selector: 'app-client-journey',
  templateUrl: './client-journey.component.html',
  styleUrls: ['./client-journey.component.scss']
})
export class ClientJourneyComponent implements OnChanges {
  @Input() moveDetailId: any;
  @Input() moveCategory: any;
  @Input() moveTransport: any;
  @Input() version: any;
  @Input() contentDisabled = false;

  @ViewChild('datePickerModal') datePickerModal: any;

  currentUserId = 0;

  _isDataLoaded = true;
  _statusDate: NgbDateStruct;
  _statusData: StatusWithMoveStatus[] = [];
  _statusType: number = 2; // currently static for getting client journey statuses
  selectedStatusId: number | null = null; // Added property to store selected status ID
  selectedDate: NgbDateStruct | null = null; // Added property to store selected date
  selectedStatus: StatusWithMoveStatus | null = null;
  _statusToSave: boolean = false;

  _moveDetail = new MoveDetail();
  _toBeUpdateStatus: MoveStatus = new MoveStatus();

  constructor(
    private _movdService: MovdService,
    private calendar: NgbCalendar,
    private _modalService: NgbModal,
    private _appComponent: AppComponent
  ) {
    // this.moveDetailId = 0; // Replace with the appropriate initialization
    // this.moveCategory = 1;
    this.currentUserId = Number(localStorage.getItem('currentUserId'));
    this._statusDate = calendar.getToday();
  }

  // ngOnInit(): void {
  //   this.getLeadSatatus(this._statusType, this.moveCategory);
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['moveDetailId'] || changes['moveCategory'] || changes['version'] || changes['moveTransport'] || changes['contentDisabled']) {
      if (changes['moveDetailId']) {
        this.moveDetailId = changes['moveDetailId'].currentValue;
      }

      if (changes['moveCategory']) {
        this.moveCategory = changes['moveCategory'].currentValue;
      }

      if (changes['moveTransport']) {
        this.moveTransport = changes['moveTransport'].currentValue;
      }

      if (changes['contentDisabled']) {
        this.contentDisabled = changes['contentDisabled'].currentValue;
      }

      // if (changes['version']) {
      //   this.moveDetailId = changes['moveDetailId'].currentValue;
      // }

      // console.log('moveDetailId and moveCategory', this.moveDetailId, this.moveCategory);
      this.getLeadSatatus(this._statusType, this.moveCategory, this.moveTransport);
    }
  }

  formatDate(date: any): string {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }

  // for updating status
  formatDate1(): string {
    return this.selectedDate
      ? `${this.selectedDate.day.toString().padStart(2, '0')}/${this.selectedDate.month.toString().padStart(2, '0')}/${this.selectedDate.year}`
      : '';
  }

  updateStatus(status: StatusWithMoveStatus) {
    this.selectedStatus = status;
    // console.log("update status function", status);
    // Disable checkbox here
    status.isSaving = true;
    if (status.moveStatusDone === true) {
      status.moveStatusDone = false;
      status.moveStatusDate = null;
      // status.showDatePicker = true;
      this._statusToSave = false;
      this.saveDateAndStatus(status);
    } else {
      status.showDatePicker = true;
      this._statusToSave = true;
      this.openDatePickerModal(this.datePickerModal, status);
    }

  }

  saveDateAndStatus(status: StatusWithMoveStatus | null) {
    if (!status) {
      return;
    }

    const moveStatus: MoveStatus = {
      id: status.moveStatusId,
      refId: this.moveDetailId,
      statusId: status.statusId,
      date: status.moveStatusDate,
      done: this._statusToSave,
      category: this.moveCategory,
      transportType: this.moveTransport,
      createdBy: this.currentUserId,
      isDeleted: false
    };

    // console.log('before saving', moveStatus);
    this._movdService.saveClientJourneyStatus(moveStatus).subscribe({
      next: (response: any) => {
        // console.log('update status response', response);
        this.getLeadSatatus(this._statusType, this.moveCategory, this.moveTransport);
        this._appComponent.showSuccessSwal('updated', 'status')
        status.isSaving = false;
        status.moveStatusDone = this._statusToSave;
        status.showDatePicker = false;
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Re-enable checkbox here in case of error
        status.isSaving = false;
      }
    });
  }

  openDatePickerModal(modal: TemplateRef<any>, status: StatusWithMoveStatus) {
    this.selectedStatus = status;  // Assuming selectedStatus is declared in your component
    this._modalService.open(modal);  // Open the modal
  }

  onDateSelectStatus(event: any) {
    if (this.selectedStatus) {  // Assuming selectedStatus is declared in your component
      this.selectedStatus.moveStatusDate = this.convertNgbDateToDate(event);  // Set the selected date to the current status
    }
  }

  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }

  cancelClick(modal: TemplateRef<any>) {
    this._modalService.dismissAll();
    // modal.close('by close button');
    this.getLeadSatatus(this._statusType, this.moveCategory, this.moveTransport);

  }


  getLeadSatatus(statusType: number, category: number, transportType: number): void {
    this._movdService.getLeadStatus(this.moveDetailId, statusType, category, transportType).subscribe({
      next: (data: any) => {
        // console.log("get lead status service called from client journey", data);
        if (data) {
          this._statusData = data.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);
          // this._statusData = data.filter((item: { moveDetailId: number; }) => item.moveDetailId === this.moveDetailId);

          let completedMoveStatusTitle = 'Move Completed';
          let cancelledMoveStatusTitle = 'Move Cancelled';
          let revertedMoveStatusTitle = 'Move Reverted';

          let completedMoveStatus = this._statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === completedMoveStatusTitle.toLowerCase());
          let cancelledMoveStatus = this._statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === cancelledMoveStatusTitle.toLowerCase());
          let revertedMoveStatus = this._statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === revertedMoveStatusTitle.toLowerCase());

          let otherStatuses = this._statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() !== completedMoveStatusTitle.toLowerCase() && item.statusTitle.toLowerCase() !== cancelledMoveStatusTitle.toLowerCase() && item.statusTitle.toLowerCase() !== revertedMoveStatusTitle.toLowerCase());

          if (completedMoveStatus[0] && completedMoveStatus[0].moveStatusDone === true) {
            this._statusData = [...otherStatuses, ...completedMoveStatus];
          } else if (cancelledMoveStatus[0] && cancelledMoveStatus[0].moveStatusDone === true) {
            this._statusData = [...otherStatuses, ...cancelledMoveStatus];
          } else if (revertedMoveStatus[0] && revertedMoveStatus[0].moveStatusDone === true) {
            this._statusData = [...otherStatuses, ...revertedMoveStatus];
          } else {
            this._statusData = [...otherStatuses];
          }
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
