import { Time } from "@angular/common";

export class Shift {
  id: number;
  ownedBy: number;
  shiftName: string | null;
  startTime: string;
  endTime: string;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;
  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.shiftName = 'Morning';
    this.startTime = "09:00";
    this.endTime = "17:00";
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
