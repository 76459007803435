import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import { MoveSurvey } from '../../models/move-survery.model';
import { PackagingInventory } from '../../models/packaging-inventory.model';
import { MovdService } from '../../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { PackagingInventoryItem, ePackingType, ePackingTypeOptions } from '../../models/packaging-inventory-item.model';
import { PackagingWeightCert } from '../../models/packaging-weight-cert.model';
import { ActivatedRoute } from '@angular/router';
import { CompanySetting, getCurrencySymbol, getVolumeUnitShortLabel, getWeightUnitShortLabel } from '../../models/companysetting.model';
import { EnumMappingService } from '../../services/movd.enum.service';
import { PdfService } from 'src/services/pdf.service';
import { MoveTypeOptions, MoveCategoryTypeOptions, TransportTypeOptions } from '../../models/lead-detail.model';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PackagingWeightCertItem } from '../../models/packaging-weight-cert-item.model';
import { AuthService } from 'src/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { PackingListResponse } from '../../survey-manager/packing-list/packing-board.model';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-packing-form',
  templateUrl: './packing-form.component.html',
  styleUrls: ['./packing-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PackingFormComponent implements AfterViewInit, OnChanges {


  @ViewChild('customerSingatureCanvasInvent') customerSingatureCanvasInvent!: ElementRef<HTMLCanvasElement>;
  @ViewChild('surveyMgrSingatureCanvasInvent') surveyMgrSingatureCanvasInvent!: ElementRef<HTMLCanvasElement>;
  customerSignaturePadInvent!: SignaturePad;
  surveyMgrSignaturePadInvent!: SignaturePad;
  // @ViewChild('customerSingatureCanvasWeight', { static: true }) customerSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  // @ViewChild('surveyMgrSingatureCanvasWeight', { static: true }) surveyMgrSingatureCanvasWeight!: ElementRef<HTMLCanvasElement>;
  // customerSignaturePadWeight!: SignaturePad;
  // surveyMgrSignaturePadWeight!: SignaturePad;

  @Input() activeTab: number = 1;
  @Input() packagingDetailId: any;
  @Input() leadId: any;
  @Input() leadDetailId: any;

  _ePackingTypeOptions = ePackingTypeOptions;

  pdfDocGenerator: any;
  itemToDelete: any;
  showTrashButton = false;
  showInventoryList = false;
  showWeightCertsList = false;
  isLoading: boolean = false;

  defaultNavActiveId = 1;
  defaultNavActiveIds = 2;

  _packagingInventory = new PackagingInventory();
  _packagingWeightCert = new PackagingWeightCert();
  _packagingInventoryId: number = 0;
  _packagingWeightCertId: number | null = null;

  modalRef: NgbModalRef | null = null;

  _externalNotesInvent!: string | null;
  _externalNotesWeight!: string | null;

  _inventoryFile: any;
  _inventoryFileName!: string | null;
  _inventoryList: PackagingInventoryItem[] = [];
  // _allInventoryList: PackagingInventoryItem[] = [];
  _assembleDisassembleList: PackagingInventoryItem[] = [];
  _cratedItemList: PackagingInventoryItem[] = [];
  _electronicList: PackagingInventoryItem[] = [];
  _highValueList: PackagingInventoryItem[] = [];
  _filteredInventoryList: PackagingInventoryItem[] = [];

  _displayInventoryList: any[] = [];
  inventoryItem = new PackagingInventoryItem();
  weightCertItem = new PackagingWeightCertItem();
  weightCertsList: any[] = [];

  // export inventory list 
  @ViewChild('pdfContent') pdfContent!: any;
  _companySetting = new CompanySetting();
  _weightUnit: string = '';
  _volumeUnit: string = '';
  _currency: string = '';
  _moveBoardItem: any;
  _moveBoardDTOList: any[] = [];
  generalService: any;
  _cd: any;
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  _packagingDetailDTO: any;
  ePackagingType = ePackingType;
  kgToLbsConversionFactor: number = 2.20462; // 1 kg = 2.20462 lbs
  cuftToCbmConversionFactor: number = 0.0283168; // 1 cuft = 0.0283168 cbm

  _selectedPackingType: ePackingType | number | null = null;

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _movdService: MovdService,
    private _appComponent: AppComponent,
    private _cdr: ChangeDetectorRef,
    public enumMappingService: EnumMappingService,
    private _pdfService: PdfService,
    private _modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private _authService: AuthService,
    private _http: HttpClient,
  ) {
    this._userRole = localStorage.getItem('role');

  }

  ngOnInit(): void {
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Packing Form.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Packing Form.Create') || this._userRole === 'CompanyAdmin';;
    this._updatePermission = permissions.includes('Packing Form.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Packing Form.Delete') || this._userRole === 'CompanyAdmin';;
    // this.addInventoryItem();
    this.getCompanySetting();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packagingDetailId']) {

      // console.log("changes['packagingDetailId'].currentValue", changes['packagingDetailId'].currentValue);
      this.packagingDetailId = changes['packagingDetailId'].currentValue;
      this._packagingInventory.packagingDetailId = this.packagingDetailId
      this._packagingWeightCert.packagingDetailId = this.packagingDetailId;
      this._cdr.detectChanges();
      this.getPackagingDetailsByLeadId(this.leadId);
      this.getPackagingInventoryByPackagingDetailId();
      //if (this.activeTab === 1) {
      //  this.getPackagingDetailsByLeadId(this.leadId);
      //  this.getPackagingInventoryByPackagingDetailId();
      //}

    }

    if (changes['leadId']) {
      this.leadId = changes['leadId'].currentValue;
      this.getPackagingDetailsByLeadId(this.leadId);
      // console.log("changes['leadId'].currentValue", changes['leadId']);
    }

  }

  ngAfterViewInit() {
    this._cdr.detectChanges();

    if (this.activeTab === 1) {
      this.initializeInventorySignaturePads();
    } else if (this.activeTab === 2) {
      // this.initializeWeightSignaturePads();
    }

  }
  async showUnauthorizedDialog() {
    await this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to update the Bingo Bit', '');
    // console.log("go to dashboard. no read permission");
  }
  initializeInventorySignaturePads() {
    // console.log("this.customerSingatureCanvasInvent", this.customerSingatureCanvasInvent);
    this.customerSignaturePadInvent = new SignaturePad(this.customerSingatureCanvasInvent.nativeElement);
    this.surveyMgrSignaturePadInvent = new SignaturePad(this.surveyMgrSingatureCanvasInvent.nativeElement);
  }

  setItemToDelete(item: any) {
    this.itemToDelete = item;
  }

  viewInventoryList() {
    this.showInventoryList = true;
    this.showWeightCertsList = false;
  }

  generateWeightCertificate() {
    this.showInventoryList = false;
    this.showWeightCertsList = true;
  }
  addInventoryItem() {
    let packingType: number | null = null;
    if (this._selectedPackingType) {
      packingType = this._selectedPackingType;
    }
    // console.log('packing type', packingType);
    let itemNo: number;
    let newItem = {
      itemNo: 0,
      description: '',
      weight: null,
      isDeleted: false,
      id: 0,
      packagingInventoryId: 0,
      packagingType: packingType,
      make: null,
      model: null,
      remarks: null,
      value: null,
      dimensions: null,
      cuft: null,
      packingBingoBit: false,
      deliveryBingoBit: false,
      createdBy: null,
      modificationDate: null,
      modifiedBy: null,
      deletionDate: null,
      quantity: 0,
    };
    // this._inventoryList.push(newItem);
    switch (packingType) {
      case ePackingType.AssembleDisassemble:
        itemNo = this._assembleDisassembleList.length + 1;
        newItem.itemNo = itemNo;
        this._assembleDisassembleList.push(newItem);
        break;
      case ePackingType.CratedItem:
        itemNo = this._cratedItemList.length + 1;
        newItem.itemNo = itemNo;
        this._cratedItemList.push(newItem);
        break;
      case ePackingType.Electronic:
        itemNo = this._electronicList.length + 1;
        newItem.itemNo = itemNo;
        this._electronicList.push(newItem);
        break;
      case ePackingType.HighValue:
        itemNo = this._highValueList.length + 1;
        newItem.itemNo = itemNo;
        this._highValueList.push(newItem);
        break;
      default:
        itemNo = this._inventoryList.length + 1;
        newItem.itemNo = itemNo;
        this._inventoryList.push(newItem);
        break;
    }

    this.onChangeTab(packingType);
  }

  deleteInventoryItem(rowIndex: number, itemList: any[]) {
    // console.log('rowIndex', rowIndex, itemList)
    if (itemList[rowIndex]) {
      const deletedItem = itemList.splice(rowIndex, 1)[0]; // Remove and get the deleted item

      if (deletedItem?.id) {
        deletedItem.isDeleted = true;
        this._movdService.savePackagingInventoryItem(deletedItem).subscribe({
          next: (data: any) => {
            this._appComponent.showSuccessSwal('Deleted', 'Item');
            this.getPackagingInventoryItemsByPackagingInventoryId(deletedItem);
          },
          error: (error: any) => {
            this._appComponent.showErrorSwal('Deleting', 'record');
            // console.log("Error", error);
          },
        });
      }
    }

  }

  saveInventoryItem(rowIndex: number, activeTab: number) {
    const currentUserId = localStorage.getItem('currentUserId');
    const packagingInventoryId = this._packagingInventory.id;

    // Choose the correct list based on the active tab
    let currentList: PackagingInventoryItem[] = [];

    switch (activeTab) {
      case 1:
        currentList = this._inventoryList;
        break;
      case 2:
        currentList = this._assembleDisassembleList;
        break;
      case 3:
        currentList = this._cratedItemList;
        break;
      case 4:
        currentList = this._electronicList;
        break;
      case 5:
        currentList = this._highValueList;
        break;
      default:
        break;
    }

    const item: PackagingInventoryItem = currentList[rowIndex];
    item.createdBy = Number(currentUserId);
    item.packagingInventoryId = packagingInventoryId;
    // Set the correct packagingType based on the active tab
    switch (activeTab) {

      case 2:
        item.packagingType = ePackingType.AssembleDisassemble;
        break;
      case 3:
        item.packagingType = ePackingType.CratedItem;
        break;
      case 4:
        item.packagingType = ePackingType.Electronic;
        break;
      case 5:
        item.packagingType = ePackingType.HighValue;
        break;
      default:
        break;
    }

    if (!item.itemNo || !item.packagingType || !item.weight) {
      this._appComponent.showErrorDialog('Error', 'Item details cannot be null / Zero');
      return;
    }

    // console.log('Packaging inventory item before saved:', item);
    this._movdService.savePackagingInventoryItem(item).subscribe({
      next: (data: any) => {
        this._appComponent.showSuccessSwal('Save', 'Item');
        this.getPackagingInventoryItemsByPackagingInventoryId(item);
        // this.createPdf();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('Saving', 'record');
        // console.log("Error", error);
      },
    });
  }


  getPackagingInventoryItemsByPackagingInventoryId(item: any | null) {
    this._inventoryList = [];
    this._assembleDisassembleList = [];
    this._cratedItemList = [];
    this._electronicList = [];
    this._highValueList = [];

    this._packagingInventoryId = this._packagingInventory.id;
    // console.log("_packagingInventoryId", this._packagingInventoryId);
    this._movdService.getPackagingInventoryItemsByPackagingInventoryId(this._packagingInventoryId).subscribe({
      next: (data: any) => {
        let filteredData = data.filter((item: any) => item.isDeleted === false);
        if (filteredData && filteredData.length > 0) {
          this._inventoryList = filteredData;
          // console.log('inveeentory list', this._inventoryList);
          this.filterAndAssignItemsByPackagingType();
          if (item && item.id) {
            // console.log('item', item);
            let mappedList = this._displayInventoryList.map((listItem, index) => {
              if (listItem.id !== item.id) {
                return this._inventoryList[index];
              } else {
                return item;
              }
            });
            // console.log('mapped list', mappedList);
            this._displayInventoryList = mappedList;
          } else {

            this._displayInventoryList = this._inventoryList;
          }

          // if (this.inventoryList.length === 0) {
          //   this.addInventoryItem();
          // }
          // } else {
          //   if (this.inventoryList.length === 0) {
          //     this.addInventoryItem();
          //   }
        }
        //console.log('Packaging inventory items get', data);
        //console.log('filteredData', this._packagingInventory.id);
      },
      error: (error: any) => {
        console.error("error gettting packing inventory items", error);
        if (error && error.status === 404 && this._inventoryList.length === 0) {
          this.addInventoryItem();
        }
      }
    });
  }

  updateBingo(item: PackagingInventoryItem): void {
    if (this._updatePermission == false && this._userRole != 'CompanyAdmin') {
      this.showUnauthorizedDialog();
      return;
    }
    const initialBingoState = item.packingBingoBit;

    item.packingBingoBit = !item.packingBingoBit;

    const currentUserId = localStorage.getItem('currentUserId');
    item.createdBy = Number(currentUserId);

    this._movdService.savePackagingInventoryItem(item).subscribe({
      next: (data: any) => {

        this.getPackagingInventoryItemsByPackagingInventoryId(item);
      },
      error: (error: any) => {
        item.packingBingoBit = initialBingoState;
        this._appComponent.showErrorSwal("Error!", "Error in updating bingo.");
        console.error("error updating bingo", error);
      }
    });
  }

  getPackagingInventoryByPackagingDetailId() {
    // console.log('Packaging inventory get', this.packagingDetailId);
    this._movdService.getPackagingInventoryByPackagingDetailId(this.packagingDetailId).subscribe({
      next: (data: any) => {
        // console.log('Packaging inventory get', data);
        if (data) {
          this._packagingInventory = data;
          this._externalNotesInvent = this._packagingInventory.externalNotes;
          this._packagingInventoryId = this._packagingInventory.id;
          this._inventoryFile = this._packagingInventory.uploadedFile;

          if (this._packagingInventory.customerSignature && this.activeTab !== 3) {
            this.renderCustomerSignature(this._packagingInventory.customerSignature, 'inventory');
          }

          if (this._packagingInventory.managerSignature && this.activeTab !== 3) {
            this.renderManagerSignature(this._packagingInventory.managerSignature, 'inventory');
          }
          this.getPackagingInventoryItemsByPackagingInventoryId(null);
        } else {
          this._packagingInventory = new PackagingInventory();
          this._externalNotesInvent = null;
          this._packagingInventoryId = 0;
          this.initializeInventorySignaturePads();
          this._inventoryList = [];
        }
      },
      error: (error: any) => {
        console.error("error getting packaging inventory", error);
      }
    });
  }

  clearSignature(user: string, type: string) {
    if (user === 'customer' && type === 'inventory') {
      this.customerSignaturePadInvent.clear();
    } else if (user === 'manager' && type === 'inventory') {
      this.surveyMgrSignaturePadInvent.clear();
    }
  }

  saveInventSingature() {
    const custSignatureDataURL = this.customerSignaturePadInvent.toDataURL();
    const surveyMgrSignatureDataURL = this.surveyMgrSignaturePadInvent.toDataURL();

    this._packagingInventory.customerSignature = custSignatureDataURL;
    this._packagingInventory.managerSignature = surveyMgrSignatureDataURL;

    // //console.log("custSignatureDataURL url", custSignatureDataURL);
    // //console.log("surveyMgrSignatureDataURL url", surveyMgrSignatureDataURL);
  }

  savePackagingInventory() {
    //console.log('empty model', this._packagingInventory);
    this._packagingInventory.externalNotes = this._externalNotesInvent;
    this._packagingInventory.uploadedFile = this._inventoryFile;
    this._movdService.savePackagingInventory(this._packagingInventory).subscribe({
      next: (data: any) => {
        // console.log("savePackagingInventory", data);f
        this._appComponent.showSuccessSwal("saved", "record");
        this.isLoading = false;
        this.getPackagingInventoryByPackagingDetailId();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("saving", "record");
        //console.log("error", error);
      }
    });
  }

  renderCustomerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (type === 'inventory') {
        // Check if customerSignaturePadInvent is defined before trying to clear it
        if (this.customerSignaturePadInvent) {
          // Clear the customer signature canvas (optional)
          this.customerSignaturePadInvent.clear();

          // Draw the customer signature
          this.customerSignaturePadInvent.fromDataURL(signatureDataURL);
        } else {
          console.error('customerSignaturePadInvent is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }

  renderManagerSignature(signatureDataURL: string, type: string) {
    const img = new Image();

    img.onload = () => {
      if (type === 'inventory') {
        if (this.surveyMgrSignaturePadInvent) {
          this.surveyMgrSignaturePadInvent.clear();

          this.surveyMgrSignaturePadInvent.fromDataURL(signatureDataURL);
        } else {
          console.error('surveyMgrSignaturePadInvent is not defined');
        }
      }
    };

    img.src = signatureDataURL;
  }


  // export inventory list code 
  getCompanySetting() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._companySetting = new CompanySetting();

    this._movdService.getCompanySettings().subscribe({
      next: (response: CompanySetting) => {
        if (response) {
          this._companySetting = response;
          if (response.weightUnit) {
            this._weightUnit = getWeightUnitShortLabel(response.weightUnit);
          }
          if (response.volumeUnit) {
            this._volumeUnit = getVolumeUnitShortLabel(response.volumeUnit);
          }
          if (response.currency) {
            this._currency = getCurrencySymbol(response.currency);
          }
        }
        // console.log('Company Setting', response);
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }

  // generatePDF() {
  //   //
  //   const contentElement = this.pdfContent.nativeElement;

  //   if (contentElement) {
  //     contentElement.style.visibility = 'visible';
  //     contentElement.offsetHeight;

  //     // Generate the PDF for hidden content packing form 
  //     this._pdfService.ConvertHTMLToPDF(contentElement, 'Inventory List');
  //     // Revert the visibility back to hidden
  //     contentElement.style.visibility = 'hidden';
  //   } else {
  //     console.error('Div not found');
  //   }
  // }


  getPackagingDetailsByLeadId(leadId: number) {
    this._movdService.getPackagingDetailsByLeadId(leadId).subscribe({
      next: (data: PackingListResponse) => {
        // console.log("packaging details by leadId", data);
        if (data && data.packingList.length > 0) {
          const filteredData = data.packingList.filter(((item) => item.packagingDetailId === this.packagingDetailId))[0];
          this._packagingDetailDTO = filteredData;
          // console.log('this._packagingDetailDTO = filteredData;', filteredData);
        }
      },
      error: (error) => {
        console.error("error getting packingdetail", error);
      }
    });
  }

  calculateTotal(itemList: PackagingInventoryItem[]): { totalItems: number, totalWeight: number } {
    let totalItems = 0;
    let totalWeight = 0;

    for (let item of itemList) {
      if (!item.isDeleted && item.itemNo) {
        totalItems++;
        totalWeight += item.weight ?? 0;
      }
    }

    return { totalItems, totalWeight };
  }

  getItemCount(surveyPlace: string, itemList: PackagingInventoryItem[]): number {
    const items = itemList.filter((item) => !item.isDeleted);
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }

  calculateWeight(unit: string, type: string, itemList: PackagingInventoryItem[]): string | number {
    let totalWeight;

    if (type === 'bySurveyPlace') {
      const items = itemList.filter((item) => !item.isDeleted);
      totalWeight = items.reduce((acc, item) => acc + (item.weight || 0) * this.calculateTotal(itemList).totalItems, 0);
    } else {
      totalWeight = itemList.reduce((acc, item) => acc + (item.weight || 0) * this.calculateTotal(itemList).totalItems, 0);
    }

    // Initialize the result string with the calculated value
    let result = `${totalWeight.toFixed(2)} ${unit}`;

    // If conversion is needed between units, apply the conversion factor
    if (unit === 'kg' && this._weightUnit === 'kg') {
      // Convert from kg to lbs
      const convertedWeight = totalWeight * this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} lbs`;
    } else if (unit === 'lbs' && this._weightUnit === 'lbs') {
      // Convert from lbs to kg
      const convertedWeight = totalWeight / this.kgToLbsConversionFactor;
      result += ` / ${convertedWeight.toFixed(2)} kg`;
    }

    return result;
  }

  openInventFileModal(content: TemplateRef<any>): void {
    this.modalRef = this._modalService.open(content, { size: 'lg' });

    this.modalRef.result.then(
      (result) => {
      },
      (reason) => {
      }
    );
  }

  async removeInventFile() {
    let text: string = 'You are about to remove file.';
    let confirmButtonText: string = 'Yes, remove it!';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this._inventoryFile = null;
      this.savePackagingInventory();
      if (this.modalRef) {
        this.modalRef.close();
      }
    }
    else {
      return;
    }
  }

  handleInventFileInput(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this._inventoryFile = e.target.result;
        // console.log('inventory fileee', this._inventoryFile)
        if (file.type === 'application/pdf') {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
        this.savePackagingInventory();
      };
      reader.readAsDataURL(file);

    }
  }

  sanitizeDocumentUrl(imageUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
  onChangeTab(packingType: ePackingType | number | null) {
    // console.log('selected packing Type', packingType);

    if (packingType !== 0 && packingType) {
      switch (packingType) {
        case ePackingType.AssembleDisassemble:
          this._filteredInventoryList = this._assembleDisassembleList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.CratedItem:
          this._filteredInventoryList = this._cratedItemList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.Electronic:
          this._filteredInventoryList = this._electronicList.filter((item) => item.isDeleted === false);
          break;
        case ePackingType.HighValue:
          this._filteredInventoryList = this._highValueList.filter((item) => item.isDeleted === false);
          break;
        default:
          this._filteredInventoryList = [];
          break;
      }

      this._selectedPackingType = packingType;
    } else {
      // Handle the case when no specific packaging type is selected
      this._filteredInventoryList = [];
      this._selectedPackingType = null;
    }

    // console.log('filtered inventory list', this._filteredInventoryList);
  }



  filterAndAssignItemsByPackagingType() {
    // Clear the lists
    // this._inventoryList = [];
    this._assembleDisassembleList = [];
    this._cratedItemList = [];
    this._electronicList = [];
    this._highValueList = [];

    // Filter and assign items to the corresponding lists
    this._inventoryList.forEach(item => {
      switch (item.packagingType) {
        case ePackingType.AssembleDisassemble:
          this._assembleDisassembleList.push(item);
          break;
        case ePackingType.CratedItem:
          this._cratedItemList.push(item);
          break;
        case ePackingType.Electronic:
          this._electronicList.push(item);
          break;
        case ePackingType.HighValue:
          this._highValueList.push(item);
          break;
        default:
          this._inventoryList.push(item);
          break;
      }
    });
  }
  generatePdf(type: any) {
    // console.log('after click');
    const pdfType = type;
    this._pdfService.generatePackingPdf(this.leadDetailId, pdfType);
  }
}
