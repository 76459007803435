import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, NgbDatepicker, NgbDateStruct, NgbModalRef, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Lead } from '../models/lead.model';
import { MoveCategoryTypeOptions, LeadDetail, MoveTypeOptions, TransportTypeOptions, eMoveCategoryType, eMoveType, eTransportType } from '../models/lead-detail.model';
import { MovdService } from '../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { EnumMappingService } from '../services/movd.enum.service';
import { MoveStatus, StatusWithMoveStatus } from '../models/move-status.model';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import Swal from 'sweetalert2';
import { eStatusType, Status } from '../models/status.model';
import { Address, Country, CountryOptions, eAddressType } from '../models/address.model';
import { AuthService } from 'src/services/auth.service';
import { Currency } from '../models/companysetting.model';
import { Console } from 'console';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { filter } from 'rxjs';
import { LeadBoardDTO, LeadBoardResponse } from '../lead-board/lead-board.model';

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {


  @ViewChild('dateModel') dateModel: any;
  @ViewChild('addressModel') addressModel: any;
  @ViewChild('datepicker') datepicker!: NgbDatepicker;
  @ViewChild('markLostModal') markLostModal!: ElementRef;
  @ViewChild('initiateMoveModal') initiateMoveModal!: ElementRef;

  _markLostModalRef: NgbModalRef | undefined;
  _initiateModalRef: NgbModalRef | undefined;

  _statusType = eStatusType.Lead;
  defaultNavActiveId: number = 1;
  eMoveCategoryType = eMoveCategoryType;
  eTransportType = eTransportType;
  currentUserId = 0;
  _saveLeadLost = false;

  countries = CountryOptions;
  // selectedCountry!: { id: Country; value: string };
  isLoading: boolean = false;
  _modalInstance!: NgbModalRef;
  _modalInstance1!: NgbModalRef;
  _modalInstance2!: NgbModalRef;

  // Object 
  _lead: Lead = new Lead();
  _leadDetail = new LeadDetail();
  _leadDetailType = "leadDetail";
  _assignmentType = "leadDetail";
  _leadBoardDTOList: LeadBoardDTO[] = [];
  _statusList: any[] = [];

  _address = new Address();
  _addressType: eAddressType = 0;
  _province: string = '';

  originAddresses: Address[] = [];
  destinationAddresses: Address[] = [];

  originActiveTabIndex: number = 0;
  destActiveTabIndex: number = 0;

  showSections: boolean = true;
  shipmentSections: boolean = true;
  maxAddresses: number = 3;
  originAddressCount: number = 0;
  destinationAddressCount: number = 0;

  originShowAlert: boolean = false;
  destShowAlert: boolean = false;

  //
  originTabErrors: boolean = false;
  destTabErrors: boolean = false;
  // variables
  _addressModalTitle: string = '';
  _leadId: number = 0;
  _portLabel: string | null = null;
  // _leadDetailId: number = 0; // for testing
  _isDataLoaded: boolean = false;
  _selectedLeadId: number = 0;
  customerId: number = 0;

  /*lostReason: string = '';*/
  // enums
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;

  _hoveredDate: NgbDate | null = null;
  _selectedField: string = '';

  // delivery date
  _deliveryDate: NgbDateStruct;
  sameDayDelivery: boolean = false;
  //_deliveryDate: NgbDateStruct | null = null;
  _deliveryTime: string | null = null;
  _deliveryDateTime: Date | null = null;

  _storageDate: NgbDateStruct | null = null;
  _storageTime: string | null = null;
  _storageDateTime: Date | null = null;

  _previousCategoryValue: any;
  _previousTransportTypeValue: any;

  _selectedMoveCategory: eMoveCategoryType = eMoveCategoryType.DoorToDoorLocal;
  _selectedTransportType: eTransportType = eTransportType.Land;

  _allStatusesDone = false;

  _dateRangeModalTitle: string = '';
  dateFields: {
    [field: string]: {
      fromDate: NgbDate | null;
      toDate: NgbDate | null;
    };
  } = {
      packing: {
        fromDate: null,
        toDate: null
      },
      loading: {
        fromDate: null,
        toDate: null
      },
      delivery: {
        fromDate: null,
        toDate: null
      },
      storage: {
        fromDate: null,
        toDate: null
      }
    };

  dateRanges: { [fieldName: string]: { fromDate: NgbDate | null, toDate: NgbDate | null } } = {};

  // packing 
  hoveredDatePacking: NgbDateStruct | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  _packingDate: NgbDateStruct | null = null;
  /* _packingTime: string | null = null;*/
  _packingDateTime: Date | null = null;
  /* _packingTime: Date | null = null;*/
  _packingTime: string | null = null;

  // loading
  hoveredDateLoading: NgbDateStruct | null = null;
  fromDate1: NgbDate | null = null;
  toDate1: NgbDate | null = null;
  _loadingDate: NgbDateStruct | null = null;
  _loadingTime: string | null = null;
  _loadingDateTime: Date | null = null;

  // storage dates
  storageNeeded: boolean = false;

  hoveredDateStorage: NgbDateStruct | null = null;
  fromDate2: NgbDate | null = null;
  toDate2: NgbDate | null = null;
  // survey dates

  //_surveyDate: Date | null= null ;
  //_surveyTime: Date | null = null;
  //_surveyDateTime: Date | null = null;
  _surveyDate: NgbDateStruct | null = null;
  _surveyTime: string | null = null;
  _surveyDateTime: Date | null = null;

  hoveredDateSurvey: NgbDateStruct | null = null;
  surveyDateShow: NgbDate | null = null;
  // delivery 
  hoveredDateDelivery: NgbDateStruct | null = null;
  deliveryDateShow: NgbDate | null = null;
  // portofLoading and Discharge dates
  hoveredDatePortOfLoading: NgbDateStruct | null = null;
  hoveredDatePortOfDischarge: NgbDateStruct | null = null;

  //mark lead lost 
  // _firstStatus: any;
  // _lastStatus: any;
  _statusVersion: number = 0;
  _leadStatuses: StatusWithMoveStatus[] = [];

  _selectAll = false;
  _contentDisable: boolean = false;


  get statusVersion(): any {
    return this._statusVersion;
  }

  set statusVersion(value: any) {
    // console.log("status version called", value);

    this._statusVersion = value;
    // Handle the changes to the statusVersion variable here
    this.getLeadStatuses(this._leadDetail.id, this._statusType, this._leadDetail.categoryType, this._leadDetail.transportType);
  }

  actualLeadId: any;

  // for lead initiated status 
  _leadInitiatedTitle = 'Lead Initiated';
  _leadWonTitle = 'Lead Won';
  _leadLostTitle = 'Lead Lost';
  _leadInitiatedStatus = new Status();
  _leadWonStatus = new Status();
  _leadLostStatus = new Status();


  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _leadStatusAndReqReadPermission = false;
  _moveInitiateLostReadPermission = false;
  _moveInitiateLostCreatePermission = false;
  _moveInitiateLostDeletePermission = false;
  _moveInitiateLostUpdatePermission = false;
  _messagesReadPermission = false;
  _clientName: string | null = null;
  _clientsCompany: string | null = null;
  _userRole: string | null = null;
  previousUrl: string = '';
  previousUrls: string = '';
  // loader for initiate move
  _isLoading: boolean = false;

  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private _movdService: MovdService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    public enumMappingService: EnumMappingService,
    public generalService: GeneralService,
    private _cd: ChangeDetectorRef,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,

  ) {
    // this.initializeDates();
    const today = calendar.getToday();

    this._deliveryDate = calendar.getToday();

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.fromDate1 = calendar.getToday();
    this.toDate1 = calendar.getNext(calendar.getToday(), 'd', 10);

    this.fromDate2 = calendar.getToday();
    this.toDate2 = calendar.getNext(calendar.getToday(), 'd', 10);
    this._userRole = localStorage.getItem('role');

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: any) => {
    //   this.previousUrl = event.url;
    // });
  }

  ngOnInit(): void {
    this.previousUrl = this.breadcrumbService.getPreviousUrl();
    this.previousUrls = this.breadcrumbService.getPreviousOfPreviousUrl();
    // this._isLoading = true;
    this.updateBreadcrumbs();
    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables Lead info form - info
    this._readPermission = permissions.includes('Lead Info Form - Info.Read') || this._userRole === 'CompanyAdmin';
    this._createPermission = permissions.includes('Lead Info Form - Info.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Lead Info Form - Info.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Lead Info Form - Info.Delete') || this._userRole === 'CompanyAdmin';
    this._leadStatusAndReqReadPermission = permissions.includes('Lead Status & Requirement.Read') || this._userRole === 'CompanyAdmin';
    this._moveInitiateLostReadPermission = permissions.includes('Initiate Move & Mark Lost.Read') || this._userRole === 'CompanyAdmin';
    this._moveInitiateLostCreatePermission = permissions.includes('Initiate Move & Mark Lost.Create') || this._userRole === 'CompanyAdmin';
    this._moveInitiateLostUpdatePermission = permissions.includes('Initiate Move & Mark Lost.Update') || this._userRole === 'CompanyAdmin';
    this._moveInitiateLostDeletePermission = permissions.includes('Initiate Move & Mark Lost.Delete') || this._userRole === 'CompanyAdmin';
    this._messagesReadPermission = permissions.includes('Lead Info Form - Messages.Read') || this._userRole === 'CompanyAdmin';

    this._route.queryParams.subscribe(
      params => {
        this._leadDetail.id = Number(params['leadDetailId']);
        this._leadId = Number(params['leadId']);
        this.currentUserId = Number(localStorage.getItem('currentUserId'));
        // console.log("lead and lead id", this._leadId, this._leadDetail.id);
        if (this._leadId && this._leadId !== 0) {
          this.getLeadDetailsByLeadId();
        }
        this.addOriginTab();
        this.addDestTab();
      });
    if (this._leadDetail) {
      this._leadDetail.selected = this._leadDetail.moveInitiated === true;
    }

  }
  private updateBreadcrumbs(): void {
    if (this.previousUrl.includes('/leads-board')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Leads Dashboard', url: '/leads-board' },
        { text: 'Lead Information Form', url: '' },
      ]);
    } else if (this.previousUrl.includes('/survey-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Survey List', url: '/survey-list' },
        { text: 'Lead Information Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/packing-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Packing List', url: '/packing-list' },
        { text: 'Lead Information Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/delivery-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Delivery List', url: '/delivery-list' },
        { text: 'Lead Information Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/move-detail')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Moves Information Form', url: this.breadcrumbService.getPreviousUrl() },
        { text: 'Leads Information Form', url: '' },
      ]);
    }
    else if (this.previousUrls.includes('/survey-list')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Lead Dashboard', url: '/leads-board' },
        { text: 'Leads Information Form', url: '' },
      ]);
    }
    else if (this.previousUrl.includes('/profile')) {
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Lead Dashboard', url: '/leads-board' },
        { text: 'Leads Information Form', url: '' },
      ]);
    }
    // if (this.previousUrl.includes('/profile')) {
    //   // Handle /profile case here
    //   const previousOfPreviousUrl = this.breadcrumbService.getPreviousOfPreviousUrl();
    //   if (previousOfPreviousUrl.includes('/survey-list')) {
    //     this.breadcrumbService.setBreadcrumbs([
    //       { text: 'Dashboard', url: '/dashboard' },
    //       { text: 'Survey List', url: '/survey-list' },
    //       { text: 'Leads Information Forms', url: '' },

    //     ]);
    //   }
    //   else {


    //   }
    // }
  }



  // get lead detail by lead Id
  getLeadDetailsByLeadId(): void {
    this._movdService.getLeadDetailsByLeadId(this._leadId).subscribe({
      next: (data: LeadBoardResponse) => {
        // console.log("lead details by leadId", data);
        if (data && data.leads && data.leads.length > 0) {
          this._leadBoardDTOList = data.leads;
          this._isDataLoaded = true;
          const filteredData = data.leads.filter(((item: { leadDetailId: number; }) => item.leadDetailId === this._leadDetail.id))[0];
          this.customerId = filteredData.customerId ?? 0;
          this._clientName = filteredData.customer;
          this._clientsCompany = filteredData.accountName;
          this._selectedLeadId = this._leadDetail.id;
          this.getLeadDetailById(this._leadDetail.id);
        }
      },
      error: (error) => {
        console.error("error getting lead detail", error);
      }
    });
  }

  getLeadDetailById(leadDetailId: any) {
    this._movdService.getLeadDetailById(leadDetailId).subscribe({
      next: (data: any) => {
        // console.log("one lead detail", data);

        if (data) {
          this._leadDetail = data;
          this._selectedMoveCategory = data.categoryType;
          this._selectedTransportType = data.transportType;

          this._previousCategoryValue = data.categoryType;
          this._previousTransportTypeValue = data.transportType;

          //  for survey Date  

          if (data.surveyDate) {
            const surveyDateTime = new Date(data.surveyDate);
            this._surveyTime = surveyDateTime.toTimeString().slice(0, 5);
          } else {
            this._surveyTime = null;
          }

          //  for packaging Date

          if (data.packagingDateFrom) {
            const packingDateTime = new Date(data.packagingDateFrom);
            this._packingTime = packingDateTime.toTimeString().slice(0, 5);
          } else {
            this._packingTime = null;
          }

          //  for loading Date

          if (data.loadingDateFrom) {
            const loadingDateTime = new Date(data.loadingDateFrom);
            this._loadingTime = loadingDateTime.toTimeString().slice(0, 5);
          } else {
            this._loadingTime = null;
          }

          //  for delivery date

          if (data.deliveryDate) {
            const deliveryDateTime = new Date(data.deliveryDate);
            this._deliveryTime = deliveryDateTime.toTimeString().slice(0, 5);
          } else {
            this._deliveryTime = null;
          }

          //  for storage Date 
          if (data.storageDateFrom) {
            const storageDateTime = new Date(data.storageDateFrom);
            this._storageTime = storageDateTime.toTimeString().slice(0, 5);
          } else {
            this._storageTime = null;
          }


          this.onChange();
          if (this._leadDetail && this._leadDetail.addresses !== null) {
            const originAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 0));
            const destAdrress = this._leadDetail.addresses.filter(((item: { addressType: number; }) => item.addressType === 1));
            // console.log("origin address", originAdrress);
            // console.log("dest address", destAdrress);

            if (originAdrress.length > 0) {
              this.originAddresses = originAdrress;
            } else {
              this.originAddresses = []
              const newAddress: Address = { ...this.originAddresses[this.originActiveTabIndex] };
              newAddress.addressType = 0; // Set addressType to 0 for origin address

              // Reset the fields of the new address
              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this.originAddresses.push(newAddress);
              this.originActiveTabIndex = this.originAddresses.length - 1;
              // this.addOriginTab(); // will not work here as we are doing this for diff move
            }

            if (destAdrress.length > 0) {
              this.destinationAddresses = destAdrress;
            } else {
              this.destinationAddresses = [];
              const newAddress: Address = { ...this.destinationAddresses[this.destActiveTabIndex] };
              newAddress.addressType = 1; // Set addressType to 1 for destination address

              newAddress.streetAddress = '';
              newAddress.city = '';
              newAddress.country = null;
              newAddress.state = '';
              newAddress.zipCode = '';

              this.destinationAddresses.push(newAddress);
              this.destActiveTabIndex = this.destinationAddresses.length - 1;
              // this.addDestTab();
            }
            if (this.generalService.formatDate(this._leadDetail.storageDateFrom, this._leadDetail.storageDateTo) === '----'
              || this.generalService.formatDate(this._leadDetail.storageDateFrom, this._leadDetail.storageDateTo) === null) {
              this.storageNeeded = false;
            }
            else {
              this.storageNeeded = true;
            }

            if (this._leadDetail.loadingDateFrom && this._leadDetail.deliveryDate && this._leadDetail.packagingDateFrom) {
              // Check if the loading date and delivery date are the same day
              const loadingDate = this.generalService.formatDate(this._leadDetail.loadingDateFrom, this._leadDetail.loadingDateTo);
              const deliveryDate = this.generalService.formatDate(this._leadDetail.deliveryDate, null);
              const packagingDate = this.generalService.formatDate(this._leadDetail.packagingDateFrom, null);
              if (packagingDate === loadingDate && packagingDate && deliveryDate) {
                this.sameDayDelivery = true;
              } else {
                this.sameDayDelivery = false;
              }
            } else {
              this.sameDayDelivery = false;
            }

          }
          this.identifyPortLabel();
          this.refreshStatuses();
        }
        this.checkContentDisable();
      },
      error: (error) => {
        console.error("error getting lead detail by Id", error);
      }
    });
  }

  // for lead initiate model
  getActiveLeadsCount(): number {
    return this._leadBoardDTOList.filter(lead => !lead.moveInitiated && !lead.markLost).length;
  }

  // for Addresses 
  closeOriginAlert() {
    this.originShowAlert = false;
  }

  closeDestAlert() {
    this.destShowAlert = false;
  }


  moveIdClick(leadDetail: LeadDetail) {
    const leadDetailId = leadDetail.id;
    const leadId = leadDetail.leadId;
    const queryParams = { leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.router.navigate(['/lead-detail'], navigationExtras);
  }

  addOriginTab() {
    if (this.originAddresses.length < this.maxAddresses) {
      const newAddress: Address = { ...this.originAddresses[this.originActiveTabIndex] };
      newAddress.addressType = 0; // Set addressType to 0 for origin address

      newAddress.id = 0;
      // Reset the fields of the new address
      newAddress.streetAddress = '';
      newAddress.city = '';
      newAddress.country = null;
      newAddress.state = '';
      newAddress.zipCode = '';

      this.originAddresses.push(newAddress);
      this.originActiveTabIndex = this.originAddresses.length - 1;
      // console.log("origin address", this.originAddresses);
    } else {
      this.originShowAlert = true;
    }
  }


  addDestTab() {
    if (this.destinationAddresses.length < this.maxAddresses) {
      const newAddress: Address = { ...this.destinationAddresses[this.destActiveTabIndex] };
      newAddress.addressType = 1; // Set addressType to 1 for destination address

      newAddress.id = 0;
      // Reset the fields of the new address
      newAddress.streetAddress = '';
      newAddress.city = '';
      newAddress.country = null;
      newAddress.state = '';
      newAddress.zipCode = '';

      this.destinationAddresses.push(newAddress);
      this.destActiveTabIndex = this.destinationAddresses.length - 1;
    } else {
      this.destShowAlert = true;
    }
  }


  setOriginActiveTab(index: number) {
    this.originActiveTabIndex = index;
  }

  setDestActiveTab(index: number) {
    this.destActiveTabIndex = index;
  }

  removeOriginTab(index: number): void {
    if (this.originAddresses.length > 1) {
      this.originAddresses.splice(index, 1);
      if (this.originActiveTabIndex === index) {
        this.originActiveTabIndex = 0;
      } else if (this.originActiveTabIndex > index) {
        this.originActiveTabIndex--;
      }
    }
  }

  removeDestTab(index: number): void {
    if (this.destinationAddresses.length > 1) {
      this.destinationAddresses.splice(index, 1);
      if (this.destActiveTabIndex === index) {
        this.destActiveTabIndex = 0;
      } else if (this.destActiveTabIndex > index) {
        this.destActiveTabIndex--;
      }
    }
  }

  onChangeTab(_type: any) {

  }


  onDateSelection(date: any, type: string) {
    const selectedDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    // const selectedDate = new Date(date.year, date.month - 1, date.day);
    // Create a JavaScript Date object
    // Create a JavaScript Date object with time set to midnight - , 0, 0, 0
    // console.log("ngb date", date);
    // console.log("selected date", selectedDate);

    switch (type) {
      case "packing":
        this.hoveredDatePacking = { year: date.year, month: date.month, day: date.day };
        if (!this._leadDetail.packagingDateFrom && !this._leadDetail.packagingDateTo) {
          this._leadDetail.packagingDateFrom = selectedDate;
        } else if (
          this._leadDetail.packagingDateFrom &&
          !this._leadDetail.packagingDateTo &&
          selectedDate > this._leadDetail.packagingDateFrom
        ) {
          this._leadDetail.packagingDateTo = selectedDate;
        } else {
          this._leadDetail.packagingDateFrom = selectedDate;
          this._leadDetail.packagingDateTo = null;
        }
        if (this.sameDayDelivery) {
          this.dateFields['loading'].fromDate = date;
          this.dateFields['delivery'].fromDate = date;

          this._leadDetail.loadingDateFrom = selectedDate;
          this._leadDetail.deliveryDate = selectedDate;

        }
        break;

      case "loading":
        this.hoveredDateLoading = { year: date.year, month: date.month, day: date.day };
        if (!this._leadDetail.loadingDateFrom && !this._leadDetail.loadingDateTo) {
          this._leadDetail.loadingDateFrom = selectedDate;
        } else if (
          this._leadDetail.loadingDateFrom &&
          !this._leadDetail.loadingDateTo &&
          selectedDate > this._leadDetail.loadingDateFrom
        ) {
          this._leadDetail.loadingDateTo = selectedDate;
        } else {
          this._leadDetail.loadingDateFrom = selectedDate;
          this._leadDetail.loadingDateTo = null;
        }
        if (this._modalInstance1 !== undefined) {
          if (this._leadDetail.loadingDateFrom !== null && this._leadDetail.loadingDateTo !== null) {
            this._modalInstance1.close();
          }
        }
        break;

      case "storage":
        this.hoveredDateStorage = { year: date.year, month: date.month, day: date.day };
        if (!this._leadDetail.storageDateFrom && !this._leadDetail.storageDateTo) {
          this._leadDetail.storageDateFrom = selectedDate;
        } else if (
          this._leadDetail.storageDateFrom &&
          !this._leadDetail.storageDateTo &&
          selectedDate > this._leadDetail.storageDateFrom
        ) {
          this._leadDetail.storageDateTo = selectedDate;
        } else {
          this._leadDetail.storageDateFrom = selectedDate;
          this._leadDetail.storageDateTo = null;
        }
        if (this._modalInstance2 !== undefined) {
          if (this._leadDetail.storageDateFrom !== null && this._leadDetail.storageDateTo !== null) {
            this._modalInstance2.close();
          }
        }
        break;

    }
    this.onChange();
  }

  isHovered(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);
    const hoveredDate = this.getHoveredDateByType(type);

    if (!hoveredDate) {
      return false;
    }

    // console.log("is hovered , from , to, type", hoveredDate, hoveredDate, fromDate, toDate, type);
    return fromDate && !toDate && date.after(fromDate) && date.before(toDate) && date.equals(hoveredDate);
  }

  isInside(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);

    // console.log("is inside , from , to, type", fromDate, toDate, type);
    return fromDate && toDate && date.after(fromDate) && date.before(toDate);
  }

  isRange(date: NgbDate, type: string) {
    const fromDate = this.getFromDateByType(type);
    const toDate = this.getToDateByType(type);

    if (fromDate && toDate) {
      return date.equals(fromDate) || date.equals(toDate) || this.isInside(date, type) || this.isHovered(date, type) || this.isRangeSelected(date, fromDate, toDate);
    }

    return false;
  }

  isRangeSelected(date: NgbDate, fromDate: NgbDate, toDate: NgbDate) {
    if (!fromDate || !toDate) {
      return false;
    }

    return date.after(fromDate) && date.before(toDate);
  }

  getHoveredDateByType(type: string): NgbDateStruct | null {
    switch (type) {
      case "packing":
        return this.hoveredDatePacking;

      case "loading":
        return this.hoveredDateLoading;

      case "storage":
        return this.hoveredDateStorage;

      default:
        return null;
    }
  }
  isHighlighted(date: NgbDateStruct, dateField: string) {
    let dateToCompare: NgbDate | null;

    if (dateField === 'survey') {
      dateToCompare = this.convertDateToNgbDate(this._leadDetail.surveyDate);
    } else if (dateField === 'delivery') {
      dateToCompare = this.convertDateToNgbDate(this._leadDetail.deliveryDate);
    } else {
      return false; // Invalid dateField
    }

    if (dateToCompare) {
      return (
        date.year === dateToCompare.year &&
        date.month === dateToCompare.month &&
        date.day === dateToCompare.day
      );
    }

    return false;
  }
  isInsidein(date: NgbDate, type: string) {
    const targetDate = this.getTargetDateByType(type);

    if (targetDate) {
      const targetNgbDate = this.convertDateToNgbDate(targetDate);
      return date.equals(targetNgbDate);
    }

    return false;
  }

  getTargetDateByType(type: string): string | Date | null {
    if (type === 'survey') {
      return this._leadDetail.surveyDate;
    } else if (type === 'delivery') {
      return this._leadDetail.deliveryDate;
    } else {
      return null; // Invalid type
    }
  }
  onChange() {
    this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._leadDetail.packagingDateFrom, null));
    this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._leadDetail.packagingDateTo));
    this.fromDate1 = this.convertDateToNgbDate(this.generalService.formatDate(this._leadDetail.loadingDateFrom, null));
    this.toDate1 = this.convertDateToNgbDate(this.generalService.formatDate(null, this._leadDetail.loadingDateTo));
    this.fromDate2 = this.convertDateToNgbDate(this.generalService.formatDate(this._leadDetail.storageDateFrom, null));
    this.toDate2 = this.convertDateToNgbDate(this.generalService.formatDate(null, this._leadDetail.storageDateTo));
    this.surveyDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._leadDetail.surveyDate, null));
    this.deliveryDateShow = this.convertDateToNgbDate(this.generalService.formatDate(this._leadDetail.deliveryDate, null));
  }

  redirectToAccount(id: any) {
    // const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };

    this.router.navigate(['/profile'], navigationExtras);
  }

  redirectToContact(id: any) {

    // console.log("account id", id);
    if (!id) {
      return;
    }
    const text = 'contact';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.router.navigate(['/profile'], navigationExtras);
  }


  getFromDateByType(type: string): NgbDate | null {
    switch (type) {
      case "packing":
        return this.convertDateToNgbDate(this._leadDetail.packagingDateFrom);
      case "loading":
        return this.convertDateToNgbDate(this._leadDetail.loadingDateFrom);
      case "storage":
        return this.convertDateToNgbDate(this._leadDetail.storageDateFrom);
      default:
        return null;
    }
  }

  getToDateByType(type: string): NgbDate | null {
    switch (type) {
      case "packing":
        return this.convertDateToNgbDate(this._leadDetail.packagingDateTo);
      case "loading":
        return this.convertDateToNgbDate(this._leadDetail.loadingDateTo);
      case "storage":
        return this.convertDateToNgbDate(this._leadDetail.storageDateTo);
      default:
        return null;
    }
  }

  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }

  validateInput(fieldName: string, currentValue: NgbDate | null, input: string): NgbDate | null {
    const range = this.dateRanges[fieldName];
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  fetchLeads() {
    this._movdService.getLeads().subscribe({
      next: (data) => {
        if (data.length > 0) {
          this._lead = data[0];
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  // Inside your component
  openDateModal(fieldName: string, modalRef: TemplateRef<any>) {
    this._selectedField = fieldName; // Set the selected field before opening modal

    const fieldTitleMap: { [fieldName: string]: string } = {
      packing: "Packing Date",
      loading: "Loading Date",
      delivery: "Delivery Date",
      storage: "Storage Date",
      portOfLoading: "Port Of Loading Date",
      portOfDischarge: "Port Of Discharge Date"
    };

    // this.initializeDates();

    if (fieldName) {
      this._dateRangeModalTitle = fieldTitleMap[fieldName] || "";
    }

    // console.log("selected field", fieldName);
    this._modalInstance = this._modalService.open(modalRef, { size: 'md' });
  }

  openDateModal1(fieldName: string, modalRef: TemplateRef<any>) {
    this._selectedField = fieldName; // Set the selected field before opening modal

    this._modalInstance1 = this._modalService.open(modalRef, { size: 'md' });
  }

  openDateModal2(fieldName: string, modalRef: TemplateRef<any>) {
    this._selectedField = fieldName;
    this._modalInstance2 = this._modalService.open(modalRef, { size: 'md' });
  }

  onDateSelect(date: NgbDate, type: string) {
    const dateToSet = this.convertNgbDateToDate(date);

    if (type === 'SurveyDate') {
      this._leadDetail.surveyDate = dateToSet;
    } else if (type === 'DeliveryDate') {
      this._leadDetail.deliveryDate = dateToSet;
    }
    this.onChange();
  }


  openAddressModal(modalRef: TemplateRef<any>, addressType: any) {
    if (addressType === 0) {
      this._addressModalTitle = "Add Origin Address";
    } else if (addressType === 1) {
      this._addressModalTitle = "Add Destination Address";
    }

    this._address.addressType = addressType;
    this._modalInstance = this._modalService.open(modalRef, { size: 'md' });
  }

  closeAddressModel() {
    this._modalInstance.close();
  }

  saveLeadDetail() {

    const companyId = Number(localStorage.getItem('companyId'));
    const currentUserId = Number(localStorage.getItem('currentUserId'));
    this._leadDetail.id = this._selectedLeadId;
    this._leadDetail.leadId = this._leadId;
    this._leadDetail.createdBy = currentUserId;
    this._leadDetail.ownedBy = companyId;

    //  for survey Date  
    if (this._leadDetail.surveyDate) {
      // Convert the string to a Date object
      const leadSurveyDate = new Date(this._leadDetail.surveyDate);
      const surveyDateTime = new Date(Date.UTC(leadSurveyDate.getFullYear(), leadSurveyDate.getMonth(), leadSurveyDate.getDate()));
      if (this._surveyTime) {
        const [hours, minutes] = this._surveyTime.split(':').map(Number);
        surveyDateTime.setUTCHours(hours);
        surveyDateTime.setUTCMinutes(minutes);
        this._leadDetail.surveyDate = surveyDateTime;
      }

    }
    //  for packaging Date 
    if (this._leadDetail.packagingDateFrom && this._leadDetail.packagingDateTo) {
      // Convert the strings to Date objects
      const packagingDateFrom = new Date(this._leadDetail.packagingDateFrom);
      const packagingDateTo = new Date(this._leadDetail.packagingDateTo);
      // Create the Date objects in UTC
      const packagingDateTimeFrom = new Date(Date.UTC(packagingDateFrom.getFullYear(), packagingDateFrom.getMonth(), packagingDateFrom.getDate()));
      const packagingDateTimeTo = new Date(Date.UTC(packagingDateTo.getFullYear(), packagingDateTo.getMonth(), packagingDateTo.getDate()));
      if (this._packingTime) {
        const [hours, minutes] = this._packingTime.split(':').map(Number);
        packagingDateTimeFrom.setUTCHours(hours);
        packagingDateTimeFrom.setUTCMinutes(minutes);
        packagingDateTimeTo.setUTCHours(hours);
        packagingDateTimeTo.setUTCMinutes(minutes);
        this._leadDetail.packagingDateFrom = packagingDateTimeFrom;
        this._leadDetail.packagingDateTo = packagingDateTimeTo;
      }

    }

    //  for loading Date        
    if (this._leadDetail.loadingDateFrom && this._leadDetail.loadingDateTo) {
      // Convert the strings to Date objects
      const loadingDateFrom = new Date(this._leadDetail.loadingDateFrom);
      const loadingDateTo = new Date(this._leadDetail.loadingDateTo);
      // Create the Date objects in UTC
      const loadingDateTimeFrom = new Date(Date.UTC(loadingDateFrom.getFullYear(), loadingDateFrom.getMonth(), loadingDateFrom.getDate()));
      const loadingDateTimeTo = new Date(Date.UTC(loadingDateTo.getFullYear(), loadingDateTo.getMonth(), loadingDateTo.getDate()));
      if (this._loadingTime) {
        const [hours, minutes] = this._loadingTime.split(':').map(Number);
        loadingDateTimeFrom.setUTCHours(hours);
        loadingDateTimeFrom.setUTCMinutes(minutes);
        loadingDateTimeTo.setUTCHours(hours);
        loadingDateTimeTo.setUTCMinutes(minutes);
        this._leadDetail.loadingDateFrom = loadingDateTimeFrom;
        this._leadDetail.loadingDateTo = loadingDateTimeTo;
      }

    }

    //  for delivery date
    if (this._leadDetail.deliveryDate) {
      // Convert the string to a Date object
      const leadDeliveryDate = new Date(this._leadDetail.deliveryDate);
      const deliveryDateTime = new Date(Date.UTC(leadDeliveryDate.getFullYear(), leadDeliveryDate.getMonth(), leadDeliveryDate.getDate()));
      if (this._deliveryTime) {
        const [hours, minutes] = this._deliveryTime.split(':').map(Number);
        deliveryDateTime.setUTCHours(hours);
        deliveryDateTime.setUTCMinutes(minutes);
        this._leadDetail.deliveryDate = deliveryDateTime;
      }

    }
    //  for storage Date 
    if (this._leadDetail.storageDateFrom && this._leadDetail.storageDateTo) {
      // Convert the strings to Date objects
      const storageDateFrom = new Date(this._leadDetail.storageDateFrom);
      const storageDateTo = new Date(this._leadDetail.storageDateTo);
      // Create the Date objects in UTC
      const storageDateTimeFrom = new Date(Date.UTC(storageDateFrom.getFullYear(), storageDateFrom.getMonth(), storageDateFrom.getDate()));
      const storageDateTimeTo = new Date(Date.UTC(storageDateTo.getFullYear(), storageDateTo.getMonth(), storageDateTo.getDate()));
      if (this._storageTime) {
        const [hours, minutes] = this._storageTime.split(':').map(Number);
        storageDateTimeFrom.setUTCHours(hours);
        storageDateTimeFrom.setUTCMinutes(minutes);
        storageDateTimeTo.setUTCHours(hours);
        storageDateTimeTo.setUTCMinutes(minutes);
      }
      this._leadDetail.storageDateFrom = storageDateTimeFrom;
      this._leadDetail.storageDateTo = storageDateTimeTo;
    }

    const allAddresses = [...this.originAddresses, ...this.destinationAddresses];
    this._leadDetail.addresses = allAddresses;

    // Check each address at index 0 for the presence of city
    for (const address of this._leadDetail.addresses) {
      if (!address.city) {
        // If city is missing, return or handle the error as needed
        alert("City is missing in an address.");
        return;
      }
      if (!address.country) {
        alert("Country is missing in an address");
        return;
      }
      if (
        this._leadDetail.categoryType === eMoveCategoryType.DoorToDoorLocal &&
        this.originAddresses[0].country !== this.destinationAddresses[0].country
      ) {
        this._appComponent.showErrorDialog('Error', 'Origin and destination countries are not the same for DoorToDoorLocal move type.');
        return;
      }

      else if (
        this._leadDetail.categoryType !== eMoveCategoryType.DoorToDoorLocal &&
        this.originAddresses[0].country === this.destinationAddresses[0].country
      ) {
        this._appComponent.showErrorDialog('Error', 'Origin and destination countries are same but move type is not  DoorToDoorLocal.');
        return;
      }

    }

    if (this.storageNeeded) {
      // this._moveDetail.storageDateFrom = this.convertNgbDateToDate(this.fromDate2);
      // this._moveDetail.storageDateTo = this.convertNgbDateToDate(this.toDate2);
    } else {
      this._leadDetail.storageDateFrom = null;
      this._leadDetail.storageDateTo = null;
    }

    // to save delivery date
    if (this.sameDayDelivery) {
      if (this.fromDate !== null && this.toDate === null) {
      } else if (this.fromDate !== null && this.toDate !== null) {
        // this._moveDetail.deliveryDate = this.convertNgbDateToDate(this.toDate);
        // this._moveDetail.loadingDateTo = this.convertNgbDateToDate(this.toDate);
      }
    } else {
      // this._moveDetail.deliveryDate = this.convertNgbDateToDate(this._deliveryDate);
      // this._moveDetail.loadingDateFrom = this.convertNgbDateToDate(this.fromDate1);
      // this._moveDetail.loadingDateTo = this.convertNgbDateToDate(this.toDate1);

    }

    //console.log("updating lead detail", this._leadDetail);

    this._movdService.saveLeadDetail(this._leadDetail).subscribe({
      next: (response: any) => {
        //console.log("save lead detail respnse", response);
        this.getLeadDetailsByLeadId();
        this._appComponent.showSuccessSwal("Lead Detail", "saved");
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("Lead Detail", "saved");
        console.error("error", error);
      }
    });
  }
  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }


  ngOnDestroy() {
    // console.log("on destroy")
    // localStorage.removeItem('leadDetailId');
    // localStorage.removeItem('leadId');
  }


  async markLeadLost() {

    let leadClosedExists = false;
    let leadClosedIsDone = false;

    // if (this._leadDetail.markLost === null) {
    //   return;
    // }

    // console.log("before mark lost", this._leadDetail.markLost);
    this._leadDetail.markLost = !this._leadDetail.markLost;
    this._saveLeadLost = true;
    this._cd.detectChanges();
    // console.log('mark lead status');
    // console.log("after mark lost", this._leadDetail.markLost);

    let text: string;
    let confirmButtonText: string;

    if (this._leadDetail.markLost === false) {
      text = 'You are about to unmark the lead as lost.';
      confirmButtonText = 'Yes, unmark it!';
    } else {
      text = 'You are about to mark the lead as lost.';
      confirmButtonText = 'Yes, mark it!';
    }

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );
    // console.log("this._leadDetail.markLost", this._leadDetail.markLost);
    if (await confirmed === true) {
      this._movdService.markLeadLost(this._leadDetail.id, this._leadDetail.markLost).subscribe({
        next: (data: any) => {
          this._markLostModalRef?.close();
          this.saveLeadLostStatus();
          // console.log('Lead marked as lost successfully');
          this._appComponent.showSuccessSwal("marked lost", "lead");
        },
        error: (error) => {
          this._leadDetail.markLost = !this._leadDetail.markLost;
          this._cd.detectChanges();
          console.error('Error marking lead as lost', error);
        }
      });
    } else {
      // If the user cancels the operation, revert the markLost flag
      this._leadDetail.markLost = !this._leadDetail.markLost;
      this._saveLeadLost = false;
      this._cd.detectChanges();
    }

  }

  async markLeadsLost(leadDetails: any) {
    // console.log("lead details", leadDetails);
    const leadDetailsToUpdate: any[] = [];

    let text = 'You are about to mark some leads as lost.';
    let confirmButtonText = 'Yes, mark them!';

    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      for (const leadDetail of leadDetails) {
        let leadClosedExists = false;
        let leadClosedIsDone = false;

        this._cd.detectChanges();
        leadDetailsToUpdate.push(leadDetail);
      }

      if (leadDetailsToUpdate.length > 0) {
        this._movdService.markLeadsLost(leadDetailsToUpdate).subscribe({
          next: (data) => {
            this.getLeadDetailById(this._leadDetail.id);
            this._markLostModalRef?.close();
            this.saveLeadLostStatus();
            this._statusVersion++; // Increment version to refresh <app-moves-status>
            this._appComponent.showSuccessSwal("marked lost", "lead");
          },
          error: (error) => {
            for (const leadDetail of leadDetailsToUpdate) {
              leadDetail.markLost = !leadDetail.markLost;
              this._cd.detectChanges();
            }
            console.error('Error marking leads as lost', error);
          }
        });
      }
    }
  }

  async showMoveInitiatedDialog() {
    const result = await Swal.fire({
      title: 'Move Already Initiated',
      icon: 'error',
      showCancelButton: true,
      cancelButtonText: "Close",
      showConfirmButton: false,
    });

    return result.isConfirmed;
  }

  openInitiateMoveModal() {

    if (this._leadDetail.addresses?.length === 0) {
      // console.log("checking address");
      alert("Required fields are missing.");
      return;
    } else if (this._leadDetail.addresses && this._leadDetail.addresses.length > 0) {
      for (const address of this._leadDetail.addresses) {
        if (!address.city) {
          // If city is missing, return or handle the error as needed
          alert("City is missing in an address.");
          // this._appComponent.showErrorDialog('Error', 'City is missing in an address.');
          return;
        }
      }
    }

    if (this.getActiveLeadsCount() === 1) {
      let activeLead = this._leadBoardDTOList.find(lead => !lead.moveInitiated && !lead.markLost);
      if (activeLead) {
        activeLead.selected = true;
      }
      this.initiateMove();
    }
    else {
      this._initiateModalRef = this._modalService.open(this.initiateMoveModal, { centered: true });
      this._leadBoardDTOList.forEach(lead => {
        lead.selected = lead.moveInitiated === true;
      });
      // Check if moveInitiated is true for the leadDetail
      this._leadDetail.selected = this._leadDetail.moveInitiated === true;
    }
  }

  onSelectAllChange() {
    for (const lead of this._leadBoardDTOList) {
      if (lead.leadId === this._leadId && !lead.moveInitiated) {
        lead.selected = this._selectAll;
      }
    }
  }

  async initiateMove() {

    // Get the selected leads
    // console.log("lead board list in initiate move", this._leadBoardDTOList);
    const selectedLeads = this._leadBoardDTOList.filter(lead => lead.selected === true && lead.moveInitiated !== true);

    // console.log("selectedLeads", selectedLeads);
    if (selectedLeads.length === 0) {
      this._appComponent.showErrorDialog('Error', 'No leads are selected for initiation.')
      return;
    }

    // console.log("All statuses done", this._allStatusesDone);

    if (!this._allStatusesDone) {
      this._appComponent.showErrorDialog('Error', 'All Statuses are not done yet.');
      return;
    }

    // If we reach here, none of the selected leads are initiated
    // Show a confirmation dialog
    let text = 'You are about to initiate the move for the selected leads.';
    let confirmButtonText = 'Yes, initiate it!';

    let confirmed = await this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      this.isLoading = true;
      // Get the IDs of the selected leads
      const leadDetailIds = selectedLeads.map(lead => lead.leadDetailId);

      this._isLoading = true;

      setTimeout(() => {
        // Call the initiateMove service with all selected lead IDs
        this._movdService.initiateMove(leadDetailIds).subscribe({
          next: (data: any) => {
            // console.log("initiate move response", data);
            this._initiateModalRef?.close();
            // Mark all selected leads as initiated
            selectedLeads.forEach(lead => {
              lead.moveInitiated = true;
            });

            this._statusVersion++;
            this.getLeadDetailsByLeadId(); // Update move details

            this._appComponent.showSuccessSwal('Move', 'initiated');

            if (data.moveDetailId && data.leadDetailId) {
              this.handleMoveClick(data.moveDetailId, data.leadDetailId);
            }
            this._isLoading = false;

            // Close the modal after successful initiation
            this._modalService.dismissAll();
          },
          error: async (error) => {
            this._cd.detectChanges();
            this._isLoading = false;

            console.error('Error marking move as initiate', error);
            // Handle error gracefully, e.g., show an error message to the user
          },
        });
      }, 0); // Adjust the delay duration (in milliseconds) as needed

    }
  }

  markLost() {
    this._markLostModalRef = this._modalService.open(this.markLostModal, { centered: true });
  }

  createNewLeadDetail() {
    // console.log("plus btn clicked");
    if (!confirm("Are you sure you want to create a new move?")) {
      return;
    }

    this._leadDetail = new LeadDetail();

    this._leadDetail.leadId = this._leadId;

    // console.log("save lead detail", this._leadDetail);

    this._movdService.saveLeadDetail(this._leadDetail).subscribe({
      next: (response: any) => {
        if (response) {
          this._leadDetail = response;
        }
        // console.log("save lead detail respnse", response);
        this._appComponent.showSuccessSwal("created", "record");
        this.getLeadDetailsByLeadId();
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal("creating", "record");
        console.error("error", error);
      }
    });
  }

  showMoveById(leadDetailId: number) {
    // console.log("selected lead id", leadDetailId);
    this._selectedLeadId = leadDetailId;
    this._leadDetail.id = this._selectedLeadId;
    this.getLeadDetailById(leadDetailId);
  }

  getStatuses(statusType: eStatusType, category: eMoveCategoryType, transport: eTransportType) {
    this._statusList = [];
    this._movdService.GetStatuses(statusType, category, transport).subscribe({
      next: (response: any) => {
        // console.log("get statuses", response);
        if (response) {
          this._statusList = response;
          // console.log("filtered status", filteredStatus);
        }

      },
      error: (error: any) => {
        console.error("error getting status ", error);
      }
    });
  }

  async getLeadStatuses(leadDetailId: number, statusType: eStatusType, category: number, transportType: number): Promise<any> {
    this._movdService.getLeadStatus(leadDetailId, statusType, category, transportType).subscribe({
      next: async (data: any) => {
        // console.log("this.lead statuses", data);
        if (data) {
          this._leadStatuses = data.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);

          await this.checkStatusExistsInLeadStatuses('initiated');

          if (this._leadDetail.markLost === true) {
            await this.checkStatusExistsInLeadStatuses('lost');
          } else if (this._leadDetail.moveInitiated === true) {
            await this.checkStatusExistsInLeadStatuses('won');
          }
          // console.log("First status:", this._firstStatus);
          // console.log("Last status:", this._lastStatus);
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleAllStatusesDone(allDone: boolean) {
    console.log("handleAllStatusesDone called ", allDone);
    this._allStatusesDone = allDone;
  }

  onSameDayDeliveryChange() {
    if (this.sameDayDelivery) {
      const date = this._leadDetail.packagingDateFrom;
      this._leadDetail.loadingDateFrom = date;
      this._leadDetail.loadingDateTo = null;
      this._leadDetail.deliveryDate = date;
      const time = this._packingTime;
      this._loadingTime = time;
      this._deliveryTime = time;

    }
    else {
      this._leadDetail.loadingDateFrom = null;
      this._leadDetail.deliveryDate = null;
      this._loadingTime = null;
      this._deliveryTime = null;
    }

  }

  // on changing move category
  async confirmChange(changeType: string) {
    let text: string = "";
    if (changeType === 'categoryType') {
      text = "Are you sure you want to change the move category";
    } else if (changeType === 'transportType') {
      text = "Are you sure you want to change the transport type";
    }



    let confirmButtonText: string = "Yes!";
    let confirmed = this._appComponent.showConfirmationDialog(
      'Are you sure?',
      text,
      confirmButtonText
    );

    if (await confirmed === true) {
      if (this._leadDetail.addresses?.length === 0) {
        // console.log("checking address");
        alert("Required fields are missing.");
        if (changeType === 'categoryType') {
          // console.log("if (changeType === 'categoryType')");
          this._leadDetail.categoryType = this._previousCategoryValue;
        } else if (changeType === 'transportType') {
          // console.log("if (changeType === 'transportType')");
          this._leadDetail.transportType = this._previousTransportTypeValue;
        }
        return;
      } else {
        if (changeType === 'categoryType') {
          this._previousCategoryValue = this._leadDetail.categoryType;
          this._selectedMoveCategory = this._leadDetail.categoryType;
          this.saveLeadDetail();
          // console.log("else if (changeType === 'categoryType')");
          // this.refreshStatuses();
        } else if (changeType === 'transportType') {
          // console.log("else if (changeType === 'transportType')");
          this._previousTransportTypeValue = this._leadDetail.transportType;
          this._selectedTransportType = this._leadDetail.transportType;
          this.saveLeadDetail();
          // this.refreshStatuses();
        }
      }
    }
    else {
      // Handle the case where the user cancels the change
      if (changeType === 'categoryType') {
        // console.log("if (changeType === 'categoryType')");
        this._leadDetail.categoryType = this._previousCategoryValue;
      } else if (changeType === 'transportType') {
        // console.log("if (changeType === 'transportType')");
        this._leadDetail.transportType = this._previousTransportTypeValue;
      }
    }
  }
  onChangeCategory(event: Event) {
    // console.log("onChangeCategory called", event);
    // console.log(`Category = ${this.enumMappingService.mapMoveCategoryType(this._leadDetail.categoryType)} && transportType = ${this.enumMappingService.mapTransportType(this._leadDetail.transportType)}`);

    this.refreshStatuses();
  }

  onChangeTransport(event: Event) {
    this.identifyPortLabel();
    this.refreshStatuses();
  }

  refreshStatuses() {
    // console.log("refresh statuses called");

    this.getStatuses(this._statusType, this._leadDetail.categoryType, this._leadDetail.transportType);
    this.getLeadStatuses(this._leadDetail.id, this._statusType, this._leadDetail.categoryType, this._leadDetail.transportType);
  }

  checkContentDisable() {
    if (this._leadDetail && this._leadDetail.markLost === true || this._leadDetail.moveInitiated === true) {
      this._contentDisable = true;
    } else {
      this._contentDisable = false;
    }
  }

  showMarkLostAndInitiateButton(): boolean {
    return (this._leadDetail.markLost === false || this._leadDetail.markLost === null) &&
      (this._leadDetail.moveInitiated === false || this._leadDetail.moveInitiated === null);
  }

  checkStatusExistsInStatuses(title: string) {
    let statusList: any[] = [];
    let statusTitle = '';

    if (title === 'initiated') {
      statusTitle = this._leadInitiatedTitle;
    }
    else if (title === 'won') {
      statusTitle = this._leadWonTitle;
    }
    else if (title === 'lost') {
      statusTitle = this._leadLostTitle;
    } else {
      // console.log("Please send the correct Status Title");
      return;
    }
    // Statuses for a specific Category

    let leadStatus = this._statusList.filter((item: Status) => item.isDeleted === false && item.title.toLowerCase() === statusTitle.toLowerCase() && item.category === this._leadDetail.categoryType && item.transportType === this._leadDetail.transportType);

    // console.log(`${statusTitle} Status`, leadStatus);
    // if (leadStatus && leadStatus.length === 0 && statusTitle === this._leadInitiatedTitle) {
    if (leadStatus && leadStatus.length === 0) {
      // console.log('no statuses in status list');
      this.saveNewStatus(title);
    }
  }

  async checkStatusExistsInLeadStatuses(title: string) {
    let statusTitle = '';

    if (title === 'initiated') {
      statusTitle = this._leadInitiatedTitle;
    }
    else if (title === 'won') {
      statusTitle = this._leadWonTitle;
    }
    else if (title === 'lost') {
      statusTitle = this._leadLostTitle;
    } else {
      return;
    }
    // status done or not done for a specific lead detail and category

    let leadStatus = this._leadStatuses.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === statusTitle.toLowerCase());
    // console.log(`${statusTitle} Status`, leadStatus);

    if (leadStatus[0]) {
      await this.markStatusAsDone(this._leadDetail.id, title, leadStatus[0]);
    } else {
      // commenting this so it can't create any other status
      // this.checkStatusExistsInStatuses(title);
    }
  }

  // create a single status as Lead Initiated or Lead Won or Lead Lost in the Status Table
  saveNewStatus(type: string): void {
    let statusTitle = null;
    let order = 0;

    if (type === 'initiated') {
      statusTitle = this._leadInitiatedTitle;
    }
    else if (type === 'won') {
      statusTitle = this._leadWonTitle;
      order = 11;
    }
    else if (type === 'lost') {
      statusTitle = this._leadLostTitle;
      order = 10;
    } else {
      // console.log("Please send the correct Status Title");
      return;
    }

    let currentUserId = Number(localStorage.getItem('currentUserId'));

    let status: Status = {
      id: 0,
      title: statusTitle,
      order: order,
      category: this._leadDetail.categoryType,
      transportType: this._leadDetail.transportType,
      statusType: this._statusType,
      ownedBy: Number(localStorage.getItem('companyId')),
      isDeleted: false,
      isActive: true,
      createdBy: currentUserId,
      required: false
    }
    // console.log('status data ', this._status);

    this._movdService.saveStatus(status).subscribe({
      next: (response: any) => {
        // console.log('Save lead initiate status response', response);
        this._statusVersion++;
        if (response) {
          this._leadInitiatedStatus = response;
        }
        // this.getLeadDetailById(this._leadDetail.id); // i think there is no need to call this function
        // this._appComponent.showSuccessSwal('saved', 'status');
      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('saving', 'status');
        console.error('Error saving status', error);
      }
    });
  }

  // now save move status after creating a lead initiated status for this lead detail .. in the Move Status Table   
  // MoveStatus | StatusWithMoveStatus
  async markStatusAsDone(leadDetailId: any, type: string, status: StatusWithMoveStatus | null) {
    // console.log('leadDetailId', leadDetailId);
    let statusToBeSaved: Status = new Status(); // Set a default status
    let moveStatus: MoveStatus = new MoveStatus();
    let statusDoneDate = new Date();

    if (type === 'initiated') {
      statusToBeSaved = this._leadInitiatedStatus;
      statusDoneDate = this._leadDetail.creationDate;
    }

    else if (type === 'won') {
      statusToBeSaved = this._leadWonStatus;
    }

    else if (type === 'lost') {
      statusToBeSaved = this._leadLostStatus;
    }

    // console.log("before marking status as done && status type: ", statusToBeSaved, type);

    if (statusToBeSaved.id === 0 && !status) {
      return;
    }

    else if (statusToBeSaved.id === 0 && status) {
      // console.log("statusToBeSaved.id === 0 && status");
      moveStatus = {
        id: status.moveStatusId,
        refId: leadDetailId,
        statusId: status.statusId,
        date: statusDoneDate,
        done: true,
        category: this._leadDetail.categoryType,
        transportType: this._leadDetail.transportType,
        createdBy: this.currentUserId,
        isDeleted: false,
      }
    } else {
      // console.log("else");
      moveStatus = {
        id: 0,
        refId: leadDetailId,
        statusId: statusToBeSaved.id,
        date: statusDoneDate,
        done: true,
        category: statusToBeSaved.category, // Use the category of the assigned status
        transportType: statusToBeSaved.transportType, // Use the category of the assigned status
        createdBy: this.currentUserId,
        isDeleted: false,
      }
    }

    if (status && status.moveStatusDone === true) {
      // console.log("status && status.moveStatusDone === true");
      return;
    }

    // console.log("status immediately before service call", moveStatus);
    // Call the MovdService to send the update request to the backend
    this._movdService.saveMoveStatus(moveStatus).subscribe({
      next: (response: any) => {
        // console.log('save move status response', response);
        this._statusVersion++;
        // this.getLeadDetailById(this._leadDetail.id);
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Handle error, e.g., show an error message to the user
      }
    });
  }

  saveLeadLostStatus() {
    if (this._leadStatuses && this._leadStatuses.length > 0) {

      let leadStatus = this._leadStatuses.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === this._leadLostTitle.toLowerCase())[0];
      // console.log("save lead lost status ", leadStatus);

      if (leadStatus) {
        let isDeleted = false;
        let isDone = true;
        let date: Date | null = new Date();

        // console.log("this._leadDetail.markLost", this._leadDetail.markLost);
        if (this._leadDetail.markLost === false && this._saveLeadLost === false) {
          isDone = true;
          date = new Date();
        } else if (this._leadDetail.markLost === false && this._saveLeadLost === true) {
          isDone = false;
          isDeleted = true;
          date = null;
        }

        let moveStatus: MoveStatus = {
          id: leadStatus.moveStatusId,
          refId: this._leadDetail.id,
          statusId: leadStatus.statusId,
          date: date,
          done: isDone,
          category: this._leadDetail.categoryType,
          transportType: this._leadDetail.transportType,
          createdBy: this.currentUserId,
          isDeleted: isDeleted,
        }
        // console.log('before saving move status', moveStatus);

        this._movdService.saveMoveStatus(moveStatus).subscribe({
          next: (response: any) => {
            // console.log('save move status response', response);
            this._statusVersion++; // Increment version to refresh <app-moves-status>
            // this.getLeadDetailById(this._leadDetail.id);
            this.getLeadDetailsByLeadId();
          },
          error: (error: any) => {
            console.error('Error updating status', error);
            // Handle error, e.g., show an error message to the user
          }
        });
      }
    }

  }

  handleMoveClick(moveDetailId: number, leadDetailId: number) {
    const leadId = this._leadBoardDTOList[0].leadId;
    const queryParams = { leadDetailId, moveDetailId, leadId };
    // console.log("lead info query params and data", queryParams, data);
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false,
    };
    this.router.navigate(['/move-detail'], navigationExtras);
  }
  identifyPortLabel() {
    if (this._leadDetail.categoryType && this._leadDetail.transportType && this._leadDetail.categoryType === eMoveCategoryType.DoorToDoorExport || this._leadDetail.categoryType === eMoveCategoryType.DoorToPort) {
      if (this._leadDetail.transportType === eTransportType.Sea || this._leadDetail.transportType === eTransportType.SeaLCL) {
        this._portLabel = "Sea";
      } else if (this._leadDetail.transportType === eTransportType.Air) {
        this._portLabel = "Air";
      }
    }
  }
  toggleSections() {
    this.showSections = !this.showSections;
  }
  shipmentSection() {
    this.shipmentSections = !this.shipmentSections;
  }

}

// code to initate a single lead
// async initiateMove() {
//   let leadInitiateExists = false;

//   if (this._leadDetail.moveInitiated === true) {
//     // Move is already initiated, show a dialog or perform additional logic
//     this.showMoveInitiatedDialog();
//   } else {
//     // Move is not initiated, show a confirmation dialog
//     this._leadDetail.moveInitiated = !this._leadDetail.moveInitiated;
//     this._cd.detectChanges();

//     let text = 'You are about to initiate the move.';
//     let confirmButtonText = 'Yes, initiate it!';

//     let confirmed = await this._appComponent.showConfirmationDialog(
//       'Are you sure?',
//       text,
//       confirmButtonText
//     );

//     if (await confirmed === true) {
//       console.log("before initiating move", [this._leadDetail.id]);
//       this._movdService.initiateMove([this._leadDetail.id]).subscribe({
//         next: (data: any) => {
//           if (this._leadDetail.moveInitiated === true) {
//             this.saveDateAndStatus('last');
//           }

//           this._statusVersion++;
//           this.getLeadDetailsByLeadId(); // Update move details

//           this._appComponent.showSuccessSwal('Move', 'initiated');

//           // Close the modal after successful initiation
//           this._modalService.dismissAll();
//         },
//         error: async (error) => {
//           this._cd.detectChanges();
//           console.log('Error marking move as initiate', error);
//           // Handle error gracefully, e.g., show an error message to the user
//         },
//       });
//     } else {
//       this._leadDetail.moveInitiated = !this._leadDetail.moveInitiated;
//       this._cd.detectChanges();
//     }
//   }

//   this.initiateSelectedLeads();
// }
