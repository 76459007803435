import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AuditLogService } from '../../../services/audit-log.service';
import { AuditLogModel, AuditLogResponse } from './audit-log.models';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { GeneralService } from 'src/services/general.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '../../../services/breadcrumb.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  _logs: AuditLogModel[] = [];
  _logColumns: any[] = [];

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _totalPages = 0;
  _searchTerm = '';

  _log = new AuditLogModel();

  // data table 
  ColumnMode = ColumnMode;

  // viewAuditLogModal
  @ViewChild('viewAuditLogModal') viewAuditLogModal: any;
  _ngbModalRef: NgbModalRef | undefined;

  constructor(
    private _auditLogService: AuditLogService,
    public generalService: GeneralService,
    private _cdr: ChangeDetectorRef,
    private _modalService: NgbModal,
    private breadcrumbService: BreadcrumbService

  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Audit Log', url: '/audit-logs' },


    ]);
    this.getAuditLogs();
  }

  getAuditLogs(): void {
    this._auditLogService.getAuditLogs(this._currentPage, this._pageSize, this._searchTerm)
      .subscribe({
        next: (data: AuditLogResponse) => {
          console.log("get audit logs response", data);
          if (data) {
            this._logs = data.logs;
            this._cdr.detectChanges();
            if (this._logs && this._logs.length > 0) {

              this._logColumns = Object.keys(this._logs[0])
                .filter((key) =>
                  key === 'creationDate' ||
                  key === 'tableName' ||
                  key === 'action' ||
                  key === 'oldData' ||
                  key === 'newData')

                .map((key) => {
                  let header: string;
                  if (key == 'creationDate') {
                    header = 'Date';
                  } else if (key == 'tableName') {
                    header = 'Category';
                  } else if (key == 'action') {
                    header = 'Action';
                  } else if (key == 'oldData') {
                    header = 'Old Data';
                  } else if (key == 'newData') {
                    header = 'New Data';
                  } else {
                    header = key;
                  }
                  return { header };
                });
            }
            this._totalRecords = data.totalRecords;
          }
        },
        error: (error) => {
          // Handle error here. For example, you can call your error service.
          console.error("error getting logs", error);
        }
      });

  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.getAuditLogs();
  }
  onSearch(): void {
    this._currentPage = 1;
    this.getAuditLogs();
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  previewAudit(logRow: AuditLogModel) {
    this._log = logRow;

    this._ngbModalRef = this._modalService.open(this.viewAuditLogModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._ngbModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  closeModal(modal: any) {
    this._log = new AuditLogModel();
  }
}
