export class PackagingWeightCertItem {
  id: number;
  packagingWeightCertId: number;
  itemNo: number | null;
  dimensions: string | null;
  gross: number | null;
  tare: number | null;
  net: number | null;
  volumeWeight: number | null;
  sealNo: number | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean | null;

  constructor() {
    this.id = 0;
    this.packagingWeightCertId = 0;
    this.itemNo = 0;
    this.dimensions = null;
    this.gross = 0;
    this.tare = 0;
    this.net = 0;
    this.volumeWeight = 0;
    this.sealNo = 0;
    this.creationDate = new Date();
    this.createdBy = 0;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
