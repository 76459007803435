import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service'; // Your authentication service

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {
    this.checkTokenExpiry();
  }

  checkTokenExpiry() {
    const expiry = localStorage.getItem('token_expiry');
    if (expiry) {
      const now = new Date().getTime();
      const timeout = +expiry - now;
      if (timeout > 0) {
        setTimeout(() => {
          this.logout();
        }, timeout);
      } else {
        this.logout();
      }
    }
  }

  logout() {
    console.log("logging out");

    localStorage.removeItem('access_token');
    localStorage.removeItem('token_expiry');
    localStorage.removeItem('token_issuedAt');
    this.router.navigate(['login']);
  }



  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    var isLoggedin = Boolean(localStorage.getItem('isLoggedin'));
    console.log("isLoggedin", isLoggedin);

    if (!isLoggedin) {
      // not logged in so redirect to login page with the return url
      console.log("logging out from canActivate child function in auth guard");

      this.router.navigate(['/login']);
      return true;
    }

    // console.log("state: Router State Snapshot", state);
    // console.log("next: Activated Route snapshot ", next);


    let permissions: string[] = this.authService.getPermissionsFromLocalStorage();
    // console.log("permissions on authguard", permissions);

    let role = localStorage.getItem('role');
    // console.log("role ", role);

    // Create conditions for all these module names
    if (state.url.includes('/dashboard') && (role === 'SuperAdmin' || role === 'CompanyAdmin' || role === 'Survey Manager' || role === 'Move/Operations Manager' || role === 'Packing Manager' || role === 'Sales Manager')) {
      console.log("dashboard permission granted");
      return true;
    }

    else if (state.url.includes('/task-board') && (role !== 'ThirdParty' && role !== 'Moving Account')) {
      // console.log("dashboard permission granted");
      return true;
    }

    // super admin and company admin
    if (state.url.includes('/super-admin') && role === 'SuperAdmin') {
      //console.log("super admin  permission");
      return true;
    } else if (state.url.includes('/company-admin') && permissions.some((p: string) => p.startsWith('CRM-Company Admin')) || role === 'CompanyAdmin') {
      //console.log("company admin permission");
      return true;
    }

    // edit profile
    else if (state.url.includes('/edit-profile')) {
      // console.log("edit profile contacts permission");
      return true;
    }

    // sales 
    else if (state.url.includes('/contact') && permissions.some((p: string) => p.startsWith('CRM - Contacts'))) {
      // console.log("crm contacts permission");
      return true;
    } else if (state.url.includes('/profile') && permissions.some((p: string) => p.startsWith('CRM - Contacts') || p.startsWith('CRM - Sales'))) {
      // console.log("crm profile permission");
      return true;
    } else if (state.url.includes('/sales') && permissions.some((p: string) => p.startsWith('CRM - Sales'))) {
      // console.log("crm sales permission");
      return true;
    }

    // leads 
    else if (state.url.includes('/leads-board') && permissions.some((p: string) => p.startsWith('Leads List'))) {
      // console.log("leads board permission");
      return true;
    } else if (state.url.includes('/lead-detail') && permissions.some((p: string) => p.startsWith('Lead Info Form - Info.Read'))) {
      // console.log("leads detail permission");
      return true;
    }

    // survey
    else if (state.url.includes('/survey-list') && permissions.some((p: string) => p.startsWith('Survey List'))) {
      // console.log("survey list permission");
      return true;
    } else if (state.url.includes('/add-survey') && permissions.some((p: string) => p.startsWith('Survey Form'))) {
      // console.log("add-survey permission");
      return true;
    }

    // moves 
    else if (state.url.includes('/moves-board') && (role === 'ThirdParty' || role === 'Moving Account' || permissions.some((p: string) => p.startsWith('Moves List')))) {
      // console.log("moves-board permission");
      return true;
    } else if (state.url.includes('/move-detail') && (role === 'ThirdParty' || role === 'Moving Account' || permissions.some((p: string) => p.startsWith('Move Details - Info')))) {
      console.log("move-detail permission");
      return true;
    }

    // packing
    else if (state.url.includes('/packing-list') && permissions.some((p: string) => p.startsWith('Packing List'))) {
      // console.log("packing-list permission");
      return true;
    } else if (state.url.includes('/packing-details') && permissions.some((p: string) => p.startsWith('Packing Form'))) {
      // console.log("packing-Form permission");
      return true;
    }

    // delivery
    else if (state.url.includes('/delivery-list') && permissions.some((p: string) => p.startsWith('Delivery List'))) {
      // console.log("delivery list permission");
      return true;
    } else if (state.url.includes('/delivery-details') && permissions.some((p: string) => p.startsWith('Delivery Form'))) {
      // console.log("delivery-Form permission");
      return true;
    } else if (state.url.includes('/reports') && role === 'CompanyAdmin') {
      // console.log("reports permission");
      return true;
    } else if (state.url.includes('/client-move')) {
      // console.log("delivery-Form permission");
      return true;
    }

    // Admin Settings
    else if (role === 'CompanyAdmin' && (state.url.includes('/general-setting') || state.url.includes('/labor-setting') || state.url.includes('/inventory-setting') || state.url.includes('/template-setting') || state.url.includes('/documents-setting') || state.url.includes('/status-setting') || state.url.includes('/survey-setting') || state.url.includes('/user-permissions') || state.url.includes('/audit-logs'))) {
      // console.log("Admin Settings permissions");
      return true;
    }
    else if (state.url.includes('/general-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("general-setting permission");
      return true;
    } else if (state.url.includes('/labor-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("labor-setting permission");
      return true;
    } else if (state.url.includes('/inventory-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("inventory-setting permission");
      return true;
    } else if (state.url.includes('/template-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("template-setting permission");
      return true;
    } else if (state.url.includes('/documents-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("documents-setting permission");
      return true;
    } else if (state.url.includes('/status-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("status-setting permission");
      return true;
    } else if (state.url.includes('/survey-setting') && permissions.some((p: string) => p.startsWith('Admin Settings'))) {
      // console.log("survey-setting permission");
      return true;
    } else if (state.url.includes('/audit-logs')) {
      // console.log("audit-setting permission");
      return true;
    } else {
      // commented this for testing
      this.router.navigate(['/edit-profile']);
      return false;
      // return true;
    }

  }



}


// const permissions = decodedToken.permissions.split(','); // Assuming permissions are comma-separated
// console.log("permissions ", permissions);

// Check if the user has the necessary permissions for the operation
//if (next& next.data.permissions.every(permission => permissions.includes(`${next.data.module}.${permission}`))) {
//  return true;
//} else {
//  this.router.navigate(['/unauthorized']); // Redirect to unauthorized page
//  return false;
//}

// module names

//CRM - Contacts
//CRM - Sales
//New Lead
//Leads List
//Lead Info Form - Info
//Lead Info Form - Survey
//Lead Info Form - Quotation
//Lead Info Form - Messages
//Lead Info Form - Summary
//Initiate Move & Mark Lost
//Lead Status & Requirement


//Survey Login
//Survey Dashboard
//Survey List
//Survey Form

//Packing Login
//Packing Dashboard
//Packing List
//Packing Form

//Delivery List
//Delivery Form

//Moves List
//Move Complete & Cancel Move
//Move Internal Status
//Client Journey

//Move Details - Info
//Move Details - Move Summary
//Move Details - Draft BL / AWB
//Move Details - Client Docs
//Move Details - Move Docs
