import { eMoveCategoryType, eTransportType } from "./lead-detail.model";

export class LeadQuotationSettings {
  id: number;
  inclusions: string | null;
  exclusions: string | null;
  insuranceLiabilities: string | null;
  paymentTerms: string | null;
  accessorialRates: string | null;
  categoryType: eMoveCategoryType | null;
  transportType: eTransportType | null;;
  ownedBy: number;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.inclusions = null;
    this.exclusions = null;
    this.insuranceLiabilities = null;
    this.paymentTerms = null;
    this.accessorialRates = null;
    this.ownedBy = 0;
    this.categoryType = eMoveCategoryType.DoorToPort;
    this.transportType = eTransportType.Sea;
    this.creationDate = new Date();
    this.createdBy = Number(localStorage.getItem('customerId')) || 0;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
