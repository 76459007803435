<ng-template #salesFilterContent>
    <div class="row">
        <div class="col-md-12">
            <button type="button" class="btn btn-primary btn-sm float-end mt-2"
                (click)="getSalesReports()">
                <i class="mdi mdi-file-document"></i> Generate Report
            </button>
            <button
                class="btn btn-secondary-outline btn-clear btn-sm btn-border float-end mt-2 me-3 d-block"
                (click)="toggleSalesSections()">
                <i class="mdi mdi-filter-outline"></i>
                Filter Report
                <i
                    [ngClass]="showSalesSections ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
            </button>
            <button
                class="btn btn-secondary-outline btn-clear btn-sm btn-border float-end mt-2 me-3"
                (click)="clearFilters()">
                <i class="mdi mdi-filter-remove-outline"></i>
                Clear Filter
            </button>

        </div>
    </div>

    <div class="row mt-3" [ngClass]="{ 'd-none': !showSalesSections }">
        <div class="col-md-4">
            <input type="text" class="form-control" [(ngModel)]="_saleReports.dealName"
                placeholder="Enter Deal name" required pattern="[A-Za-z\s]{1,25}"
                title="Deal name should only contain letters and spaces, and be between 1-25 characters long."
                maxlength="50" />
        </div>

        <div class="col-md-4">
            <ng-select [items]="_allContacts" bindLabel="customerName" bindValue="id"
                [searchable]="true" [multiple]="true" [(ngModel)]="_saleReports.dealOwnerId"
                placeholder="Select Deal Owner">
            </ng-select>
        </div>

        <div class="col-md-4">
            <input type="text" class="form-control" [(ngModel)]="_saleReports.contactName"
                placeholder="Enter Contact name">
        </div>
    </div>

    <div class="row mt-3" [ngClass]="{ 'd-none': !showSalesSections }">
        <div class="col-md-4">
            <ng-select [items]="_contacts" bindLabel="customerName" bindValue="id"
                [searchable]="true" [multiple]="true" [(ngModel)]="_saleReports.accountId"
                placeholder="Select Account">
            </ng-select>
        </div>

        <div class="col-md-4">
            <input type="text" class="form-control" [(ngModel)]="_saleReports.probability"
                placeholder="Enter Probability" maxlength="15">
        </div>

        <div class="col-md-4">
            <ng-select [items]="_pipesList" bindLabel="pipeName" bindValue="id" [searchable]="true"
                [(ngModel)]="_saleReports.pipeIds" placeholder="Select Deal status">
            </ng-select>
        </div>
    </div>

</ng-template>
<ng-template #leadsSummarizeReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_summarizeReportData" [sorts]="summarizeTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of summarizeTableColumns"
                [name]="column.header" [prop]="column.prop" class="thead"
                [style]="{ border: '1px solid black' }" [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.header === 'Date'">
                        {{ generalService.formatDateTime(row.date) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'New'">
                        {{ row.new || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'InProgress'">
                        {{ row.inProgress || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Won'">
                        {{ row.won || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Lost'">
                        {{ row.lost || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Total Leads'">
                        {{ row.totalLeads || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>
<ng-template #leadsDetailReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_detailReportData" [sorts]="detailTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of detailTableColumns" [name]="column.header"
                [prop]="column.prop" class="thead" [style]="{ border: '1px solid black' }"
                [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.header === 'Date'">
                        {{ generalService.formatDate(row.date, null) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Lead ID'">
                        {{ generalService.formatId(row.leadId, 'lead') || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Account'">
                        {{ row.account || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Customer'">
                        {{ row.customer || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Status'">
                        {{ row.status || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Origin'">
                        {{ row.origin || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Destination'">
                        {{ row.destination || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>
<ng-template #totalSalesReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_totalSalesReportData" [sorts]="totalSalesTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of totalSalesTableColumns"
                [name]="column.header" [prop]="column.prop" class="thead"
                [style]="{ border: '1px solid black' }" [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.prop === 'date'">
                        {{ generalService.formatDate(row.date, null) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'lead'">
                        {{ row.lead || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'prospect'">
                        {{ row.prospect || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'won'">
                        {{ row.won || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'lost'">
                        {{ row.lost || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'totalSales'">
                        {{ row.totalSales || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>
<ng-template #totalSalesDetailReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_totalSalesDetailReportData" [sorts]="totalSalesDetailTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of totalSalesDetailTableColumns"
                [name]="column.header" [prop]="column.prop" class="thead"
                [style]="{ border: '1px solid black' }" [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.prop === 'date'">
                        {{ generalService.formatDate(row.date, null) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'dealName'">
                        {{ row.dealName || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'status'">
                        {{ row.status || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'contactName'">
                        {{ row.contactName || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'dealOwner'">
                        {{ row.dealOwner || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'amount'">
                        {{ row.amount || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'probability'">
                        {{ row.probability || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.prop === 'lastContact'">
                        {{ generalService.formatDateTime(row.lastContact) || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>
<ng-template #leadToSaleSummarizeReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_leadToSaleSummarizedReportData"
            [sorts]="leadToMoveSummarizeTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of leadToMoveSummarizeTableColumns"
                [name]="column.header" [prop]="column.prop" class="thead"
                [style]="{ border: '1px solid black' }" [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.header === 'Date'">
                        {{ generalService.formatDate(row.date, null) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Total Leads'">
                        {{ row.totalLeads || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Total Sales'">
                        {{ row.totalMoves || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Lead To Sale Converstion Rate'">
                        {{ row.leadToMoveRate || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>
<ng-template #leadToSaleDetailReport>
    <div class="my-datatable">
        <ngx-datatable [rows]="_leadToSaleDetailReportData" [sorts]="leadToSaleDetailTableColumns"
            class="bootstrap custom-datatable table-bordered table-responsive"
            [columnMode]="columnMode" [summaryRow]="false" [footerHeight]="40" [limit]="10"
            [pagination]="true" [pageSize]="10" [loadingIndicator]="loadingIndicator"
            [rowHeight]="40" [scrollbarH]="true">

            <ngx-datatable-column *ngFor="let column of leadToSaleDetailTableColumns"
                [name]="column.header" [prop]="column.prop" class="thead"
                [style]="{ border: '1px solid black' }" [sortable]="true">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ng-container *ngIf="column.header === 'Date'">
                        {{ generalService.formatDate(row.date, null) || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Lead ID'">
                        {{ generalService.formatId(row.leadId, 'lead') || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Account'">
                        {{ row.account || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Customer'">
                        {{ row.customer || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Origin'">
                        {{ row.origin || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Destination'">
                        {{ row.destination || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Status'">
                        {{ row.status || '-' }}
                    </ng-container>
                    <ng-container *ngIf="column.header === 'Reason'">
                        {{ row.reason || '-' }}
                    </ng-container>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</ng-template>


<ul ngbNav #moduleTabNav="ngbNav" class="nav-tabs mt-2" [(activeId)]="_moduleActiveTabId">
    <!-- <li [ngbNavItem]="1">
        <a ngbNavLink (click)="onChangeModuleTab(1,eSalesReportType.TotalLeads)"
            class="nav-tab sub-tabs">
            <span class="text">Total Leads</span>
        </a>
        <ng-template ngbNavContent>
            <ul ngbNav #reportTabNav="ngbNav" class="nav-tabs mt-2"
                [(activeId)]="_salesActiveTabId">
                <li [ngbNavItem]="1">
                    <a ngbNavLink (click)="onChangeSalesSubType(1, eReportSubType.SummarizeReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Summarized Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngTemplateOutlet="leadsSummarizeReport"></ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink (click)="onChangeSalesSubType(2, eReportSubType.DetailReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Detail Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngTemplateOutlet="leadsDetailReport"></ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="reportTabNav" class="border border-top-0 p-3"></div>
        </ng-template>

    </li> -->
    <li [ngbNavItem]="1">
        <a ngbNavLink (click)="onChangeModuleTab(2,eSalesReportType.TotalSales)"
            class="nav-tab sub-tabs">
            <span class="text">Total Sales</span>
        </a>
        <ng-template ngbNavContent>
            <ul ngbNav #reportTabNav1="ngbNav" class="nav-tabs mt-2"
                [(activeId)]="_totalsalesActiveTabId">
                <li [ngbNavItem]="1">
                    <a ngbNavLink (click)="onChangeSalesSubType(1, eReportSubType.SummarizeReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Summarized Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <!-- <ng-container *ngTemplateOutlet="totalSalesReport"></ng-container> -->
                        <div class="text-center"> Currently In Development Phase</div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink (click)="onChangeSalesSubType(2, eReportSubType.DetailReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Detail Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngTemplateOutlet="totalSalesDetailReport"></ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="reportTabNav1" class="border border-top-0 p-3"></div>
        </ng-template>
    </li>
    <!-- <li [ngbNavItem]="3">
        <a ngbNavLink (click)="onChangeModuleTab(3,eSalesReportType.LeadToSaleConversion)"
            class="nav-tab sub-tabs">
            <span class="text">Lead-To-Sale Conversion Rate</span>
        </a>
        <ng-template ngbNavContent>
            <ul ngbNav #reportTabNav2="ngbNav" class="nav-tabs mt-2"
                [(activeId)]="_leadtosaleActiveTabId">
                <li [ngbNavItem]="1">
                    <a ngbNavLink (click)="onChangeSalesSubType(1, eReportSubType.SummarizeReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Summarized Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngTemplateOutlet="leadToSaleSummarizeReport"></ng-container>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink (click)="onChangeSalesSubType(2, eReportSubType.DetailReport)"
                        class="nav-tab sub-tabs">
                        <span class="text">Detail Report</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ng-container *ngTemplateOutlet="leadToSaleDetailReport"></ng-container>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="reportTabNav2" class="border border-top-0 p-3"></div>
        </ng-template>

    </li> -->
</ul>


<ng-container *ngTemplateOutlet="salesFilterContent"></ng-container>
<div [ngbNavOutlet]="moduleTabNav" class="border border-top-0 p-3"></div>