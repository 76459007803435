import { MenuItem } from './menu.model';

export function getMenuItems(): MenuItem[] {
  let localStoragePermissions = localStorage.getItem('permissions');
  if (localStoragePermissions) {
    let permissions: string[] = JSON.parse(localStoragePermissions);

    let role = localStorage.getItem('role')?.trim();

    return [
      {
        label: 'Main',
        isTitle: true,
        show: role === 'SuperAdmin' || role === 'CompanyAdmin' || role === 'Survey Manager' || role === 'Move/Operations Manager' || role === 'Packing Manager' || role === 'Sales Manager',
      },
      {
        label: 'Dashboard',
        icon: 'dashboard-icon',
        link: '/dashboard',
        show: role === 'SuperAdmin' || role === 'CompanyAdmin' || role === 'Survey Manager' || role === 'Move/Operations Manager' || role === 'Packing Manager' || role === 'Sales Manager',
      },
      {
        label: 'Dispatch Board',
        icon: 'admin-icon',
        link: '/dispatch-board',
        show: role === 'CompanyAdmin'
      },
      {
        label: 'Tasks',
        icon: 'tasks-icon',
        link: '/task-board',
        show: role !== 'ThirdParty' && role !== 'Moving Account'
      },
      {
        label: 'CRM',
        isTitle: true,
        show: permissions.includes("CRM - Contacts.Read") || permissions.includes("CRM - Sales.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Contacts',
        icon: 'contacts-icon',
        link: '/contact',
        show: permissions.includes("CRM - Contacts.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Sales',
        icon: 'bar-chart-2',
        link: '/sales',
        show: permissions.includes("CRM - Sales.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Super Admin',
        icon: 'admin-icon',
        link: '/super-admin',
        show: role === 'SuperAdmin'
      },
      {
        label: 'Movd',
        isTitle: true,
        show: permissions.includes("Leads List.Read") || permissions.includes("Moves List.Read") || permissions.includes("Survey List.Read") || permissions.includes("Packing List.Read") || permissions.includes("Delivery List.Read") || role === 'CompanyAdmin' || role === 'ThirdParty'
      }, {
        label: 'Leads',
        icon: 'lead',
        link: '/leads-board',
        show: permissions.includes("Leads List.Read") || role === 'CompanyAdmin'
      },
      // {
      //   label: 'Moves Dashboard',
      //   icon: 'survey',
      //   link: '/moves-manager-dashboard',
      //   show: role === 'CompanyAdmin' || permissions.includes("Moves List.Read")
      // },
      {
        label: 'Moves',
        icon: 'survey',
        link: '/moves-board',
        show: role === 'CompanyAdmin' || permissions.includes("Moves List.Read") || role === 'ThirdParty' || role === 'Moving Account'
      },
      // {
      //   label: 'Survey Dashboard',
      //   icon: 'dashboard-icon',
      //   link: '/survey-dashboard',
      //   show: permissions.includes("Survey Form.Read") || role === 'CompanyAdmin'
      // },
      {
        label: 'Survey List',
        icon: 'survey-list-icon',
        link: '/survey-list',
        show: permissions.includes("Survey List.Read") || role === 'CompanyAdmin'
      },
      // {
      //   label: 'Packing Dashboard',
      //   icon: 'packing',
      //   link: '/packing-manager-dashboard',
      //   show: permissions.includes("Packing List.Read") || role === 'CompanyAdmin'
      // },
      {
        label: 'Packing List',
        icon: 'packing',
        link: '/packing-list',
        show: permissions.includes("Packing List.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Delivery List',
        icon: 'delivery-list-icon',
        link: '/delivery-list',
        show: permissions.includes("Delivery List.Read") || role === 'CompanyAdmin'
      },
      {
        label: 'Reports',
        icon: 'bar-chart-2',
        link: '/reports',
        show: role === 'CompanyAdmin'
      },
      {
        label: 'Admin',
        icon: 'admin-icon',
        show: role === 'CompanyAdmin',
        subItems: [
          // {
          //   label: 'Dashboard',
          //   icon: 'admin-icon',
          //   link: '/company-dashboard',
          //   // show: role === 'CompanyAdmin'
          // },
          {
            label: 'Company Settings',
            icon: 'settings',
            link: '/general-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'User Role Access',
            icon: 'admin-icon',
            link: '/company-admin',
            show: permissions.includes("CRM-Company Admin.Read") || role === 'CompanyAdmin'
          },
          {
            label: 'Inventory',
            icon: 'inventory',
            link: '/inventory-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'Template',
            icon: 'template',
            link: '/template-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'Documents',
            icon: 'template',
            link: '/documents-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'Status Settings',
            icon: 'status',
            link: '/status-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'Survey Settings',
            icon: 'survey',
            link: '/survey-setting',
            // show: role === 'CompanyAdmin'
          },
          {
            label: 'Audit Logs',
            icon: 'admin-icon',
            link: '/audit-logs',
            // show: role === 'CompanyAdmin'
          },

          // {
          //   label: 'Reports',
          //   icon: 'bar-chart-2',
          //   link: '/reports',
          //   // show: role === 'CompanyAdmin'
          // },
          // {
          //   label: 'Profile Task',
          //   icon: 'survey',
          //   link: '/profile-task',
          //   // show: role === 'CompanyAdmin'
          // },
          //{
          //  label: 'Role Permissions',
          //  icon: 'admin-icon',
          //  link: '/user-permissions',
          //  // show: role === 'CompanyAdmin'
          //},
        ]
      }
    ];
  }
  else {
    return [];
  }
}




// earlier menu
// export const MENU: MenuItem[] = [
//   {
//     label: 'Main',
//     isTitle: true
//   },
//   {
//     label: 'Dashboard',
//     icon: 'dashboard-icon',
//     link: '/dashboard'
//   },
//   {
//     label: 'CRM',
//     isTitle: true
//   },
//   {
//     label: 'Contacts',
//     icon: 'contacts-icon',
//     link: '/contact//'
//   },
//   {
//     label: 'Sales',
//     icon: 'bar-chart-2',
//     link: '/sales',
//   },
//   {
//     label: 'Super Admin',
//     icon: 'admin-icon',
//     link: '/super-admin'
//   },
//   {
//     label: 'Company Admin',
//     icon: 'admin-icon',
//     link: '/company-admin'
//   }, {
//     label: 'Movd',
//     isTitle: true
//   }, {
//     label: 'Leads',
//     icon: 'lead',
//     link: '/leads-board'
//   },
//   {
//     label: 'Moves',
//     icon: 'survey',
//     link: '/moves-board'
//   },
//   {
//     label: 'Survey Dashboard',
//     icon: 'dashboard-icon',
//     link: '/survey-dashboard'
//   },
//   {
//     label: 'Survey List',
//     icon: 'survey-list-icon',
//     link: '/survey-list'
//   },
//   {
//     label: 'Packing List',
//     icon: 'packing',
//     link: '/packing-list'
//   },
//   {
//     label: 'Delivery List',
//     icon: 'delivery-list-icon',
//     link: '/delivery-list'
//   },
//   {
//     label: 'Admin',
//     icon: 'admin-icon',
//     subItems: [
//       {
//         label: 'General',
//         icon: 'settings',
//         link: '/general-setting'
//       },
//       {
//         label: 'Inventory',
//         icon: 'inventory',
//         link: '/inventory-setting'
//       },
//       {
//         label: 'Template',
//         icon: 'template',
//         link: '/template-setting'
//       },
//       {
//         label: 'Documents',
//         icon: 'template',
//         link: '/documents-setting'
//       },
//       {
//         label: 'Status Settings',
//         icon: 'status',
//         link: '/status-setting'
//       },
//       {
//         label: 'Survey',
//         icon: 'survey',
//         link: '/survey-setting'
//       },
//       {
//         label: 'User Permission',
//         icon: 'admin-icon',
//         link: '/user-permissions'
//       }
//     ]
//   }
// ];
