<h4>LABOR SETTINGS</h4>
<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-4" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="nav-tab">
      <span class="text"> ALL </span>
    </a>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="nav-tab">
      <span class="text"> A </span>
    </a>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeTab(3)" class="nav-tab">
      <span class="text"> B </span>
    </a>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(4)" class="nav-tab">
      <span class="text"> C </span>
    </a>
  </li>
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>