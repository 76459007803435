import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusWithMoveStatus } from '../models/move-status.model';
import { Lead } from '../models/lead.model';
import { LeadQuotation } from '../models/lead-quotation.model';


@Component({
  selector: 'app-shipper-info',
  templateUrl: './shipper-info.component.html',
  styleUrls: ['./shipper-info.component.scss']
})
export class ShipperInfoComponent implements OnInit {
  @Input() lead: Lead = {} as Lead;
  editMode = false; // Add this property
  editedLead: Lead = {} as Lead; // Add this property


  openEdit(content: TemplateRef<any>) {
    this.editedLead = { ...this.lead };

    this.editMode = true;
    this.modalService.open(content); // Open the modal
  }


  saveChanges() {

    this.lead = { ...this.editedLead };
    this.modalService.dismissAll();
    this.editMode = false;
  }

  _quotationdata: any = {
    loaddetails: '20Ft*1, 40Ft*2',
    items: '01',
    volume: '001',
    pictures: '001',
    labours: '4 personnel',
    transport: 'Land/Sea',
    moveManager: '----',
    insurance: '----',
    packingDate: new Date('2023-08-25'),
    loadingDate: new Date('2023-08-30'),

  };



  constructor(public modalService: NgbModal) { }

  ngOnInit() {
    // this.lead = {
    //   firstName: 'Techflo',
    //   accountId: 123456,
    //   account: 'Sample Account',
    //   leadDetailId: 1001,
    //   phoneNo: '123-456-7890',
    //   email: 'techflo@example.com',
    //   accounts: 'Individual',
    //   pocId: 0,
    //   thirdPartyId: 0,
    //   thirdPartyPocId: 0,
    //   id: 1,
    //   lastName: '',
    //   ownedBy: 0
    // };


  }
}
