export class SearchCustomerDTO {
    customerId: number;
    customerName: string | null;
    phone: string | null;
    email: string | null;

    constructor() {
        this.customerId = 0;
        this.customerName = null;
        this.phone = null;
        this.email = null;
    }
}