import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserDTO, UserModel } from '../auth.models';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../app.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;

  loginForm: FormGroup = new FormGroup({});
  submitted = false;
  staticEmail: string = 'admin@techflo.com';
  staticPass: string = 'admin123';
  errorMessage: string | null = null;

  returnUrl: any;

  _user = new UserModel();
  _userDTO = new UserDTO();

  // for otp pop up modal
  _enteredOTP: string | null = null;
  _ngbModalRef: NgbModalRef | undefined;
  @ViewChild('otpModal') otpModal!: any;
  _enteredOTP1: string | null = null;
  _enteredOTP2: string | null = null;;
  _enteredOTP3: string | null = null;;
  _enteredOTP4: string | null = null;;

  _showOtpErrorMessage = false;
  _otpErrorMsg: string | null = null;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _appComponent: AppComponent,
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // this.open();

    localStorage.clear();

    this.loginForm = new FormGroup({
      email: new FormControl(localStorage.getItem('loginEmail') || '', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });

    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe) {
      this.loginForm.patchValue({ rememberMe: true });
      const email = localStorage.getItem('loginEmail');
      if (email) {
        this.loginForm.patchValue({ email });
      }
    }

  }

  ngAfterViewInit(): void {
    // this.open();

  }

  login() {
  }





  onLoggedin(e: Event) {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    e.preventDefault();
    const emailInput = document.getElementById('txtemail') as HTMLInputElement;
    const passInput = document.getElementById('txtpass') as HTMLInputElement;

    if (emailInput && passInput) {
      this._user.email = emailInput.value;
      this._user.password = passInput.value;
    }

    this._authService.login(this._user).subscribe({
      next: (result: any) => {
        if (result) {
          this._userDTO = result.user;
          console.log("login successfull", result);

          // Store user data
          localStorage.setItem('currentUserId', this._userDTO.id.toString());
          localStorage.setItem('name', this._userDTO.username);
          localStorage.setItem('email', this._userDTO.email);
          localStorage.setItem('role', this._userDTO.role);
          localStorage.setItem('roleId', this._userDTO.roleId.toString());
          localStorage.setItem('companyId', this._userDTO.companyId.toString());
          localStorage.setItem('customerId', this._userDTO.customerId.toString());

          localStorage.setItem('isLoggedin', 'true');

          const token = result.token;
          const tokenPayload = JSON.parse(atob(token.split('.')[1]));
          const token_issuedAt = tokenPayload.iat * 1000; // Convert to milliseconds
          const token_expiry = tokenPayload.exp * 1000; // Convert to milliseconds

          // Store token data
          localStorage.setItem('access_token', token);
          localStorage.setItem('token_issuedAt', token_issuedAt.toString());
          localStorage.setItem('token_expiry', token_expiry.toString());

          // console.log("access_token", token.toString());
          // console.log("tokenPayload", tokenPayload);
          // console.log("token_issuedAt", token_issuedAt.toString());
          // console.log("token_expiry", token_expiry.toString());

          // Handle Remember Me functionality
          const rememberMeControl = this.loginForm.get('rememberMe');
          if (rememberMeControl && rememberMeControl.value) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('loginEmail', this._userDTO.email);
          } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('loginEmail');
          }

          this._router.navigate(['/dashboard']);

          if (result && result.otpMessage) {
            this.open();
            this.isLoading = false;
            this._appComponent.showSuccessLogin("OTP Sent Successfully");
          } else {
            this.isLoading = false;
            this._router.navigate(['/dashboard']);
          }
        }
      },
      error: (error: any) => {
        this.isLoading = false; // Set isLoading to false when the request is complete

        if (error.status === 401) {
          this.errorMessage = 'Unauthorized access. Please try again.';
        } else if (error.status === 400) {
          switch (error.error) {
            case 'The user is deleted.':
              this.errorMessage = 'The account has been deleted. Please contact support.';
              break;
            case 'The user is inactive.':
              this.errorMessage = 'The account is inactive. Please contact support.';
              break;
            case 'The user role is inactive.':
              this.errorMessage = 'The account role is inactive. Please contact support.';
              break;
            default:
              this.errorMessage = 'Invalid email or password';
              break;
          }
        } else {
          this.errorMessage = 'Invalid email or password';
        }

        this._appComponent.showFailureLogin(this.errorMessage);
        console.error("error in login", error);
      }
    });
  }


  open() {
    this._showOtpErrorMessage = false;
    this._otpErrorMsg = null;
    this._enteredOTP1 = null;
    this._enteredOTP2 = null;
    this._enteredOTP3 = null;
    this._enteredOTP4 = null;

    this._ngbModalRef = this._modalService.open(this.otpModal, { backdrop: 'static', keyboard: false, centered: true });
  }

  limitInput(event: any) {
    if (event.target.value.length > 0) {
      event.preventDefault();
    }
  }

  verifyOTP() {
    // console.log("verify otp function");

    this.isLoading = true;

    const userId = Number(localStorage.getItem('currentUserId'));
    // const userId = 1027;

    // console.log("otp 1", this._enteredOTP1);
    // console.log("otp 2", this._enteredOTP2);
    // console.log("otp 3", this._enteredOTP3);
    // console.log("otp 4", this._enteredOTP4);
    // console.log("user id", userId);

    if (!this._enteredOTP1 || !this._enteredOTP2 || !this._enteredOTP3 || !this._enteredOTP4 || !userId) {
      return;
    }

    const otp = this._enteredOTP1 + this._enteredOTP2 + this._enteredOTP3 + this._enteredOTP4;

    // console.log("otp", otp);

    this._authService.verifyOTP(userId, otp).subscribe({
      next: response => {
        this.isLoading = false;

        this._modalService.dismissAll('by: calling closeModal()');
        this._appComponent.showSuccessSwal('verified', 'OTP');
        // console.log("saving profile response", response);

        localStorage.setItem('isLoggedin', 'true');

        // const token = result.token;
        // const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        // const token_issuedAt = tokenPayload.iat * 1000; // Convert to milliseconds
        // const token_expiry = tokenPayload.exp * 1000; // Convert to milliseconds
        // // const expiryTime = 30000; // Convert to milliseconds

        // localStorage.setItem('access_token', token);
        // localStorage.setItem('token_issuedAt', token_issuedAt.toString());
        // localStorage.setItem('token_expiry', token_expiry.toString());

        if (Boolean(localStorage.getItem('isLoggedin'))) {
          this._router.navigate(['/dashboard']);
        }
      },
      error: error => {
        this.isLoading = false;
        if (error && error.error == "OTP has expired.") {
          this._otpErrorMsg = "This otp has been expired."
          this._showOtpErrorMessage = true;
        } else {
          this._otpErrorMsg = "OTP verification failed."
          this._showOtpErrorMessage = true;
        }
        this._appComponent.showErrorSwal('otp', 'verification');
        console.error("saving profile error", error);
      }
    });

  }
}

