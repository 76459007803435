import { SalesDTO } from "src/app/views/movd/sales-manager/sales-manager-dashboard/dashboard.models";

export class OptionModel {
  id: number;
  value: string;

  constructor() {
    this.id = 0;
    this.value = '';
  }
}
export class InsertPipe {
  CompanyId: number;
  Name: string;

  constructor() {
    this.CompanyId = 0;
    this.Name = '';
  }
}
export interface DealsResponse {
  deals: SalesDTO[],
  totalRecords: number
}
export class DealsModel {
  id: number;
  name: string;
  contactId: number | null;
  pipeId: number | null;
  dealOwnerId: number | null;
  amount: number;
  probability: number;
  lastContact: Date;
  salesRepId: number;
  createdBy: number;
  modifiedBy: number;
  ownedBy: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.contactId = null;  // required property
    this.pipeId = null;    // required property 
    this.dealOwnerId = null;  // required property
    this.salesRepId = 0;
    this.amount = 0;
    this.probability = 0;
    this.createdBy = 0;
    this.ownedBy = 0;
    this.modifiedBy = 0;
    this.lastContact = new Date();
  }
}

//export class DealDTOModel {
//  Id: number;
//  DealName: string;
//  ContactId: number;
//  PipeId: number;
//  CreatedBy: number;
//  dealOwner: number;
//  Amount: number;
//  Probability: number;
//  LastContact: Date;
//  SalesRepId: number;
//  attributeValues: DealAttributeValue[];

//  constructor() {
//    this.Id = 0;
//    this.DealName = '';
//    this.ContactId = 0;
//    this.PipeId = 0;
//    this.CreatedBy = 0;
//    this.dealOwner = 0;
//    this.SalesRepId = 0;
//    this.Amount = 0;
//    this.Probability = 0;
//    this.LastContact = new Date();
//    this.attributeValues = [];
//  }
//}

//export class DealAttributeValue {
//  PipeAttributeId: number;
//  StringValue: string;
//  NumericValue: number | null;
//  DateValue: Date | null;
//  BooleanValue: boolean | null;

//  constructor() {
//    this.PipeAttributeId = 0;
//    this.StringValue = '';
//    this.NumericValue = null;
//    this.DateValue = null;
//    this.BooleanValue = null;
//  }
//}

export class DealParameterDTO {
  id: number;
  dealId: number;
  name: string;
  contactId: number | null;
  pipeId: number | null;
  createdBy: number | undefined;
  dealOwnerId: number | null;
  amount: number;
  probability: number;
  lastContact: any;
  salesRepId: number | null;

  constructor() {
    this.id = 0;
    this.dealId = 0;
    this.name = '';
    this.contactId = null;
    this.pipeId = null;
    this.createdBy = 0;
    this.dealOwnerId = null;
    this.salesRepId = null;
    this.amount = 0.0;
    this.probability = 0.0;
    this.lastContact = "2023-06-27T04:13:05.947Z";
  }
}

export class AttributeValueDTO {
  PipeAttributeId: number;
  StringValue: string | null;
  NumericValue: number | null;
  DateValue: any;
  BooleanValue: boolean | null;

  constructor() {
    this.PipeAttributeId = 0;
    this.StringValue = null;
    this.NumericValue = null;
    this.DateValue = null;
    this.BooleanValue = null;
  }
}


export class InsertOrUpdateDealDTO {
  dealParameters: DealParameterDTO = new DealParameterDTO;
  attributeValues: [] = [];
}

//attributeValues: AttributeValueDTO = new AttributeValueDTO;

//PipeAttributeId: number;
//StringValue: string;
//NumericValue: number;
//DateValue: any;
//BooleanValue: boolean;
//constructor() {
//  this.PipeAttributeId = 0;
//  this.StringValue = '';
//  this.NumericValue = 0;
//  this.DateValue = "2023-06-27T04:13:05.947Z";
//  this.BooleanValue = false;
//}
