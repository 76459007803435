import { Currency } from "./companysetting.model";

export class LeadQuotation {
  id: number;
  leadDetailId: number;
  commodity!: string | null;
  date!: Date | null;
  validationDate!: Date | null;
  originCharges!: number | null;
  freightCharges!: number | null;
  destinationCharges!: number | null;
  destinationCustomClearance!: string | null;
  inlandHaulage!: string | null;
  delivery!: string | null;
  packAndLoad!: string | null; // Added missing property
  dockTransfer!: string | null; // Added missing property
  exportClearance!: string | null; // Added missing property
  transitTime!: string | null; // Added missing property
  packOut!: string | null; // Added missing property
  airPortTransfer!: string | null; // Added missing property
  importClearance!: string | null; // Added missing property
  returnEmptyUnit!: string | null;
  accessorialRates!: string | null;
  inclusions!: string | null;
  exclusions!: string | null;
  insuranceLiabilities!: string | null;
  termsAndConditions!: string | null;
  others!: string | null;
  creationDate: Date;
  createdBy!: number | null;
  modificationDate!: Date | null;
  modifiedBy!: number | null;
  deletionDate!: Date | null;
  isDeleted: boolean;
  currency: Currency | null;

  constructor() {
    this.id = 0;
    this.leadDetailId = 0;
    this.creationDate = new Date();
    this.isDeleted = false;
    this.currency = null;
  }
}

