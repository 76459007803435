<h5>INVENTORY SETTINGS</h5>
<ul ngbNav #defaultNav="ngbNav" class="nav-tabs mt-3" [(activeId)]="defaultNavActiveId">
  <li [ngbNavItem]="1">
    <a ngbNavLink (click)="onChangeTab(1)" class="nav-tab">
      <span class="text"> LABOUR </span>
    </a>
    <ng-template ngbNavContent>
      <app-labour></app-labour>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink (click)="onChangeTab(2)" class="nav-tab">
      <span class="text"> VEHICLES </span>
    </a>
    <ng-template ngbNavContent>
      <app-vehicles></app-vehicles>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink (click)="onChangeTab(3)" class="nav-tab">
      <span class="text"> PACKING MATERIAL </span>
    </a>
    <ng-template ngbNavContent>
      <app-packing-material></app-packing-material>
    </ng-template>
  </li>
  <!-- <li [ngbNavItem]="4">
    <a ngbNavLink (click)="onChangeTab(4)" class="nav-tab">
      <span class="text"> MISC. </span>
    </a>
  </li> -->
</ul>
<div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>