<div class="row title-and-mutliple-moves-btn">
  <div class="col-md-4">
    <h5>PACKING FORM</h5>
  </div>
  <div class="col-md-8">
    <ng-container *ngFor="let packagingDetail of _packingBoardDTOList">
      <ng-container *ngIf="packagingDetail.leadId === _leadId">
        <button class="muliple-moves-btn float-end mt-1" (click)="showMoveById(packagingDetail)"
          [class.active]="packagingDetail.packagingDetailId === _packingDetail.packagingDetailId">
          {{ packagingDetail.moveTypeLabel }} -
          {{ packagingDetail.moveTransportLabel }}
          <i *ngIf="packagingDetail.moveTransport === 1" class="mdi mdi-airplane-takeoff"></i>
          <i *ngIf="packagingDetail.moveTransport === 2" class="mdi mdi-truck-delivery"></i>
          <i *ngIf="packagingDetail.moveTransport === 3" class="mdi mdi-ferry"></i>
          <i *ngIf="packagingDetail.moveTransport === 4" class="mdi mdi-ferry"></i>
        </button>
        &nbsp;
      </ng-container>
    </ng-container>
    &nbsp;
  </div>
</div>

<div class="row mt-1">
  <div class="col-md-12 order-md-1">
    <div class="box-left border border-dark-grey p-3 custom-rounded">
      <div class="row">
        <app-lead-info-header [type]="_moveDetail" [refId]="_moveDetailId"
          [assignmentType]="_assignmentType"
          [assignmentRefId]="_moveDetailId"></app-lead-info-header>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 order-md-1 mt-3">
        <ul ngbNav #defaultNav="ngbNav" class="nav-tabs" [(activeId)]="defaultNavActiveId">
          <li [ngbNavItem]="1">
            <a ngbNavLink (click)="onChangeTab(4)" class="container">
              <span class="text">INVENTORY LIST</span>
            </a>
            <ng-template ngbNavContent>
              <app-packing-form [packagingDetailId]="_packingDetail.packagingDetailId"
                [activeTab]="1" [leadId]="_leadId" [leadDetailId]="_leadDetailId">
              </app-packing-form>
            </ng-template>

          </li>
          <li [ngbNavItem]="2" *ngIf="_showWeightCertificate">
            <a ngbNavLink (click)="onChangeTab(2)" class="container">
              <span class="text">{{ _weightCertTitle }}</span>
            </a>
            <ng-template ngbNavContent>
              <app-weight-certificate [packagingDetailId]="_packingDetail.packagingDetailId"
                [activeTab]="2" [weightCertificateTitle]="_weightCertTitle"
                [leadId]="_leadId"></app-weight-certificate>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink (click)="onChangeTab(3)" class="container">
              <span class="text">BINGO SHEET</span>
            </a>
            <ng-template ngbNavContent>
              <app-packing-form [packagingDetailId]="_packingDetail.packagingDetailId"
                [weightCertificateTitle]="_weightCertTitle" [leadId]="_leadId"
                [activeTab]="3"></app-packing-form>

            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink (click)="onChangeTab(1)" class="container">
              <span class="text">APPRAISAL FORM</span>
            </a>
            <ng-template ngbNavContent>
              <app-appraisal-form [packagingDetailId]="_packingDetail.packagingDetailId"
                [appraisalType]="_appraisalType"></app-appraisal-form>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-md-3 order-md-2 mt-2">
  <div class="mt-3 border border-dark-grey custom-rounded order-md-2 box-right">
    <app-moves-status [refId]="_packingDetail.id" [moveCategory]="_packingDetail.categoryType"
            [statusType]="_statusType" [version]="_statusVersion"></app-moves-status>
  </div>
</div> -->