<ng-template #imagePreviewModal let-modal>
  <div class="modal-header">
    <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close()">X</button> -->
    <button type="button" class="btn-close" (click)="modal.close('by: close icon')" aria-label="Close"></button>
  </div>
  <div class="modal-body custom-body">
    <ng-container *ngIf="_displayImage; else defaultIcon">
      <img [src]="_imageToDisplay" alt="Image" class="modal-image">
    </ng-container>
    <ng-template #defaultIcon>
      <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
        colors="primary:#121331,secondary:#1ac0a1"></lord-icon>
    </ng-template>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="fileInput.click()">Upload</button>
    <input #fileInput type="file" style="display: none" (change)="handlePictureUpload($event)" accept="image/*" />
    <button type="button" class="btn btn-danger btn-sm" (click)="removeImage()">Remove</button>
    <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close();">Close</button> -->
  </div>
</ng-template>

<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8 mt-1">
    <div class="card rounded">
      <div class="card-body">
        <div class="row mt-2 d-flex">
          <div class="profile-container justify-content-center text-align-center align-items-center"
            (mouseenter)="_showEditButton = true" (mouseleave)="_showEditButton = false">
            <div class="profile-icon img-lg rounded-circle custom-rounded-circle">
              <ng-container *ngIf="_imageToDisplay; else defaultIcon0">
                <img [src]="_imageToDisplay" alt="Image" width="100" height="100">
              </ng-container>
              <ng-template #defaultIcon0>
                <lord-icon src="https://cdn.lordicon.com/eszyyflr.json" trigger="hover" state="hover-nodding"
                  colors="primary:#121331,secondary:#1ac0a1"></lord-icon>
              </ng-template>
              <a (click)="openImageModal(imagePreviewModal, _imageToDisplay)" *ngIf="_showEditButton">
                <i class="feather icon-edit icon-lg edit-button"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <!-- <div class="col-md-9 mt-3 align-items-center"> -->
          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.companyName" class="col-form-label">Name</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control" [(ngModel)]="_userProfile.companyName"
                id="_userProfile.companyName" autocomplete="on" placeholder="Enter Name" required
                pattern="[A-Za-z\s]{1,25}"
                title="Name should only contain letters and spaces, and be between 1-25 characters long."
                maxlength="50" />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.email" class="col-form-label">Email</label>
            </div>
            <div class="col-md-9">
              <input type="email" class="form-control" id="_userProfile.email" [(ngModel)]="_userProfile.email"
                placeholder="Enter Email" [disabled]="true" [readOnly]="true" />
            </div>
          </div>

          <!-- <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.password" class="col-form-label">Password</label>
            </div>
            <div class="col-md-9">
              <div class="input-group">
                <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control" id="_userProfile.password"
                  [(ngModel)]="_userProfile.password" placeholder="Enter Password" />
                <button class="btn btn-outline-secondary" type="button" (click)="togglePasswordVisibility()">
                  <i [ngClass]="isPasswordVisible ? 'far fa-eye-slash' : 'far fa-eye'"></i>
                </button>
              </div>
              <div *ngIf="!passwordMeetsRequirements(_userProfile.password!)" class="text-danger small mt-2">
                *Password must be at least 8 characters long and must contain one special character!
              </div>

            </div>
          </div> -->


          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.zipCode" class="col-form-label">Postal Code</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control" id="_userProfile.zipCode" [(ngModel)]="_userProfile.zipCode"
                placeholder="Enter Postal Code">
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.stAddress" class="col-form-label">Street</label>
            </div>
            <div class="col-md-9">
              <textarea class="form-control" id="_userProfile.streetAddress" [(ngModel)]="_userProfile.streetAddress"
                placeholder="Enter Street Address"></textarea>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.city" class="col-form-label">City</label>
            </div>
            <div class="col-md-9">
              <input type="text" class="form-control" id="_userProfile.city" [(ngModel)]="_userProfile.city"
                placeholder="Enter City">
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-3">
              <label for="_userProfile.country" class="col-form-label">Country</label>
            </div>
            <div class="col-md-9">
              <ng-select [items]="countries" bindLabel="value" bindValue="id" placeholder="Select a country"
                [(ngModel)]="_userProfile.country" [searchable]="true">
              </ng-select>
            </div>
          </div>


          <div class="row mb-2 two-factor-checkbox">
            <div class="col-md-3">
              <label for="_userProfile.twoFactorEnabled" class="col-form-label">Two Factor
                Authentication</label>
            </div>
            <div class="col-md-9">
              <input type="checkbox" class="form-check-input" id="_userProfile.twoFactorEnabled"
                [(ngModel)]="_userProfile.twoFactorEnabled">
            </div>
          </div>

        </div>
      </div>
      <!-- </div> -->
      <div class="card-footer">
        <button type="button" class="btn btn-primary btn-xs mt-2 mr-2 float-end" (click)="editProfile();">
          Update Profile
        </button>

        <button class="btn btn-sm btn-outline-primary btn-xs mt-2"
          (click)="openChangePasswordModal(changePasswordModal)">
          <i class="feather icon-edit icon-sm"></i> Change Password
        </button>
      </div>


    </div>
  </div>

  <!-- <div class="col-md-2 mt-1"></div> -->
</div>

<ng-template #changePasswordModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title text-center">Change Password</h4>
    </div>
    <div class="modal-body">
      <form #passwordForm="ngForm" class="form-horizontal">
        <div class="form-group row mb-3">
          <label for="previousPassword" class="col-sm-4 col-form-label">Current Password</label>
          <div class="col-sm-8">
            <input type="password" class="form-control" id="previousPassword" [(ngModel)]="_currentPassword"
              name="previousPassword" required>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="newPassword" class="col-sm-4 col-form-label">New Password</label>
          <div class="col-sm-8">
            <input type="password" class="form-control" id="newPassword" [(ngModel)]="_newPassword" name="newPassword"
              required>
            <div *ngIf="_newPassword && _currentPassword && _newPassword === _currentPassword"
              class="text-danger small mt-2">
              ✖ New Password cannot be same as Current Password
            </div>
            <div *ngIf="!passwordMeetsRequirements(_newPassword)" class="text-danger small mt-2">
              * Password must be at least 8 characters long and must contain one special character!
            </div>
          </div>
        </div>

        <div class="form-group row mb-3">
          <label for="confirmPassword" class="col-sm-4 col-form-label">Re-enter New Password</label>
          <div class="col-sm-8">
            <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="_confirmPassword"
              name="confirmPassword" required>
            <div *ngIf="passwordForm.form.valid && _newPassword !== _confirmPassword" style="color: red;">
              ✖ Passwords do not match
            </div>
            <div *ngIf="passwordForm.form.valid && _newPassword === _confirmPassword" style="color: green;">
              ✔ Passwords match
            </div>
          </div>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-sm"
        [disabled]="!passwordForm.form.valid || _newPassword !== _confirmPassword || !passwordMeetsRequirements(_newPassword) || _newPassword === _currentPassword"
        (click)="changePassword()">
        <i class="mdi mdi-content-save"> </i> &nbsp;Save
      </button>
      <button type="button" class="btn btn-sm btn-danger" (click)="modal.dismiss('Cross click')">
        <i class="mdi mdi-close"> </i>&nbsp;Close
      </button>
    </div>
  </div>
</ng-template>