import { eAssignmentType } from "./userAssignment.model";

export class InventoryAssignment {
  id: number;
  refId: number;
  inventoryId: number | null;
  // userIds: number[];
  userId: number | null;
  inventoryType: eInventoryType | null;
  assignmentType: eAssignmentType | null;
  assignedDate: Date | null;
  assignedTimeSlot: string | null;
  labourIds: number[] | null;
  vehicleIds: number[] | null;
  packingMaterialIds: number[] | null;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.refId = 0;
    this.inventoryId = null;
    // this.userIds = [];
    this.userId = null;
    this.assignmentType = null;
    this.inventoryType = null;
    this.assignedDate = null;
    this.assignedTimeSlot = null;
    this.labourIds = null;
    this.vehicleIds = null;
    this.packingMaterialIds = null;
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
export enum eInventoryType {
  Labour = 0,
  Vehicle = 1,
  PackingMaterial = 2,
}