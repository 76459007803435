import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { GeneralService } from 'src/services/general.service';
import { Subject, debounceTime } from 'rxjs';
import { MovdService } from '../services/movd.service';
import { MoveCategoryTypeOptions, MoveTypeOptions, TransportTypeOptions, eMoveCategoryType, eTransportType } from '../models/lead-detail.model';
import { AuthService } from 'src/services/auth.service';
import { eStatusType } from '../models/status.model';
import { BreadcrumbService } from 'src/services/breadcrumb.service';
import { allMovesColumns, MoveBoardDTO, MovesListResponse } from './moves-board.model';



@Component({
  selector: 'app-moves-board',
  templateUrl: './moves-board.component.html',
  styleUrls: ['./moves-board.component.scss']
})
export class MovesBoardComponent implements OnInit {
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  @ViewChild('movePreviewModal') movePreviewModal: any;
  columnMode: ColumnMode = ColumnMode.force;
  columnToggleSubject = new Subject<void>();
  loadingIndicator = true;
  _surveyDate: NgbDateStruct | null;
  _deliveryDate: NgbDateStruct | null;
  _loadingDate: NgbDateStruct | null;
  _allColumns: string[] = allMovesColumns;
  defaultColumns: string[] = ['Move ID', 'Survey ID', 'Packing ID', 'Customer', 'Account', 'Status', 'Origin', 'Destination'];
  _selectedColumns: { [key: string]: boolean } = {};
  _companyId: number;

  _firstStatus: string = '';
  _lastStatus: string = '';
  _selectedStatus: string = 'ALL';
  _movesBoardItem = new MoveBoardDTO();
  tableColumns: any[] = [];
  _movesTableData: MoveBoardDTO[] = [];
  _displayedMovesList: MoveBoardDTO[] = [];
  _statusList: any[] = [];
  // _tabLabels = ['SEA', 'AIR'];
  _tabLabels = ['LOCAL', 'IMPORT', 'EXPORT'];
  _completedStatuses: string[] = [
    'Move Initiated'
    //'Customer Information', 'Move Info',
    //'Pre-Move Survey',
    //'Quotation Sent',
    //'Quotation Confirmed'
  ];
  // enums 
  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _crmContactReadPermission = false;
  _surveyFormReadPermission = false;
  _packingFormReadPermission = false;
  _userRole: string | null = null;

  _currentPage = 1;
  _pageSize = 10;
  _totalRecords = 0;
  _searchTerm = '';

  constructor(private _calendar: NgbCalendar,
    private _modalService: NgbModal,
    private _router: Router,
    private _movdService: MovdService,
    public generalService: GeneralService,
    private _authService: AuthService,
    private breadcrumbService: BreadcrumbService,
  ) {

    this._companyId = Number(localStorage.getItem('companyId'));
    this._surveyDate = _calendar.getToday();
    this._deliveryDate = _calendar.getToday();
    this._loadingDate = _calendar.getToday();
    this.columnToggleSubject.pipe(
      debounceTime(10) // Adjust this value as needed toggleColumnVisibility() will only be called once per 300 milliseconds 
    ).subscribe(() => this.toggleColumnVisibility());
    this._userRole = localStorage.getItem('role');

  }

  ngOnInit(): void {

    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Moves ', url: '/moves-board' },

    ]);

    // Retrieve permissions from local storage
    const permissions = this._authService.getPermissionsFromLocalStorage();

    // Set permission variables
    this._readPermission = permissions.includes('Moves List.Read') || this._userRole === 'CompanyAdmin' || this._userRole === 'ThirdParty' || this._userRole === 'Moving Account';
    this._createPermission = permissions.includes('Moves List.Create') || this._userRole === 'CompanyAdmin';
    this._updatePermission = permissions.includes('Moves List.Update') || this._userRole === 'CompanyAdmin';
    this._deletePermission = permissions.includes('Moves List.Delete');
    this._crmContactReadPermission = permissions.includes('CRM - Contacts.Read') || this._userRole === 'CompanyAdmin';
    this._surveyFormReadPermission = permissions.includes('Survey Form.Read') || this._userRole === 'CompanyAdmin' || this._userRole === 'ThirdParty' || this._userRole === 'Moving Account';
    this._packingFormReadPermission = permissions.includes('Packing Form.Read' || this._userRole === 'CompanyAdmin') || this._userRole === 'ThirdParty' || this._userRole === 'Moving Account';

    this._companyId = Number(localStorage.getItem('companyId'));

    // Load selected columns for this section from localStorage or initialize with defaults
    const storedSelectedColumns = localStorage.getItem('selectedColumnsForThisSection');
    if (storedSelectedColumns) {
      this._selectedColumns = JSON.parse(storedSelectedColumns);
    } else {
      for (const column of this.defaultColumns) {
        this._selectedColumns[column] = true;
      }
    }
    if (this._userRole !== 'ThirdParty' && this._userRole !== 'Moving Account') {
      // console.log("com", this._companyId);
      this.getMovesForBoard();
    }
    else if (this._userRole === 'ThirdParty' || this._userRole === 'Moving Account') {
      this.getMovesForBoard();
    }
    this.getStatuses(eStatusType.Move, eMoveCategoryType.DoorToDoorLocal, eTransportType.Air);

    this.defaultColumns.forEach((column) => {
      this._selectedColumns[column] = true;
    });

    this.tableColumns = this.defaultColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));


  }

  onInputChange(event: any) {
    // console.log("on input change event", event);
    // console.log("on input change event.data", event.data);

    if (event && event.data === null) {
      // console.log("condition checked", event && event.data === null);
      this.getMovesForBoard();
    }
  }

  onSearch(): void {
    this.getMovesForBoard();
  }

  onClickStatus(label: string) {
    this._selectedStatus = label;
    switch (label) {
      case 'ALL':
        this._displayedMovesList = this._movesTableData;
        break;

      case 'LOCAL':
        const localMoves = this._movesTableData.filter(moves => moves.moveCategory === eMoveCategoryType.DoorToDoorLocal);
        this._displayedMovesList = localMoves;
        // this.showmovesByStatus(label);
        break;

      case 'IMPORT':
        const importMoves = this._movesTableData.filter(moves => moves.moveCategory === eMoveCategoryType.DoorToDoorImport || moves.moveCategory === eMoveCategoryType.PortToDoor);
        this._displayedMovesList = importMoves;
        // this.showmovesByStatus(label);
        break;

      case 'EXPORT':
        const exportMoves = this._movesTableData.filter(moves => moves.moveCategory === eMoveCategoryType.DoorToDoorExport || moves.moveCategory === eMoveCategoryType.DoorToPort);
        this._displayedMovesList = exportMoves;
        // this.showmovesByStatus(label);
        break;

      default:
        // Handle the case when the label is not recognized
        break;
    }
  }

  onPageChange(event: any) {
    this._currentPage = event.offset + 1;
    this.getMovesForBoard();
  }

  getMovesForBoard() {
    this._movdService.getMovesForBoard(this._currentPage, this._pageSize, this._searchTerm).subscribe({
      next: (data: MovesListResponse) => {
        // console.log("moves for baord", data);

        if (data.movesList && data.movesList.length > 0) {
          this._totalRecords = data.totalRecords;
          this.createMoveTableData(data.movesList);
        }
        // console.log('displayed move list', this._displayedMovesList);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  createMoveTableData(data: any) {
    var moveBoardDTO = new MoveBoardDTO();
    // console.log("moves for board", data);

    this._movesTableData = data;
    this._displayedMovesList = data;
    // Update tableColumns to include new combined date columns
    this.tableColumns = Object.keys(moveBoardDTO)
      .filter((key) => key !== 'moveId'
        && key !== 'lastName'
        && key !== 'phone'
        && key !== 'email'
        && key !== 'pocId'
        && key !== 'accountId'
        && key !== 'accountName'
        && key !== 'thirdPartyId'
        && key !== 'thirdPartyPocId'
        && key !== 'accountManagerId'
        && key !== 'creationDate'
      )
      .map((key) => {
        let header: string;
        let prop: string = key;
        switch (key) {
          case 'moveDetailId':
            header = 'Move ID';
            break;
          case 'surveyId':
            header = 'Survey ID';
            break;
          case 'packingId':
            header = 'Packing ID';
            break;
          case 'account':
            header = 'Account';
            break;
          case 'customer':
            header = 'Customer';
            break;
          case 'status':
            header = 'Status';
            break;
          case 'origin':
            header = 'Origin';
            break;
          case 'destination':
            header = 'Destination';
            break;
          case 'moveType':
            header = 'Move';
            break;
          case 'moveCategory':
            header = 'Move Category';
            break;
          case 'moveTransport':
            header = 'Transport Type';
            break;
          case 'portOfLoading':
            header = 'POL';
            break;
          case 'portOfDischarge':
            header = 'POD';
            break;
          case 'placeOfDelivery':
            header = 'Place Of Delivery';
            break;
          case 'pocName':
            header = 'POC';
            break;
          case 'thirdPartyName':
            header = 'Third Party';
            break;
          case 'thirdPartyPocName':
            header = 'Third Party POC';
            break;
          case 'packagingDate':
            header = 'Pack Date';
            break;
          case 'loadingDate':
            header = 'Loading Date';
            break;
          case 'storageDate':
            header = 'Storage Date';
            break;
          case 'warehouseStorage':
            header = 'Warehouse Storage';
            break;
          case 'deliveryDate':
            header = 'Delivery Date';
            break;
          case 'accountManager':
            header = 'Account Manager';
            break;
          default:
            header = key; // Use the original key if no specific header is defined
            break;
        }
        return { header, prop };
      });

    this.toggleColumnVisibility();
  }

  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  movePreviewClick(data: any, type: string) {
    if (this._readPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to visit the Move detail', '');
      return;
    }
    // console.log("movePreviewClick", data);
    this._movesBoardItem = data;
    // this._moveBoardItem = this._movesList.find((moveBoarditem: any) => moveBoarditem.moveDetailId === data.moveDetailId);
    // console.log("_moveBoardItem", this._moveBoardItem);

    if (type === 'previewBtnClick') {
      const surveryDate = this.convertStringToNgbDateStruct(data.surveyDate);
      this._surveyDate = surveryDate;

      const deliveryDate = this.convertStringToNgbDateStruct(data.deliveryDate);
      this._deliveryDate = deliveryDate;

      // console.log("this._deliveryDate", this._deliveryDate);
      // console.log("preview click", data);
      this._modalService.open(this.movePreviewModal, { size: 'md' })
    } else if (type === 'moveIdClick') {
      this.moveInfoClick();
    }
    else if (type === 'surveyIdClick') {
      if (!this._surveyFormReadPermission) {
        this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the Survey Form', '');
        return;
      }
      // this.moveInfoClick();
      let leadDetailId = data.leadDetailId;
      let leadId = data.leadId;
      const queryParams = { leadDetailId, leadId };
      const navigationExtras: NavigationExtras = {
        queryParams,
        skipLocationChange: false
      };

      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Move Dashboard', url: '/moves-board' },
        { text: 'Survey Form', url: '' },
      ]);
      this._router.navigate(['/add-survey'], navigationExtras);
    }
    else if (type === 'packingIdClick') {
      if (this._packingFormReadPermission) {
        this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the Packing Form', '');
        return;
      }
      // this.moveInfoClick();
      const packagingDetailId = data.packingId
      const leadId = data.leadId
      const moveDetailId = data.moveDetailId
      const queryParams = { packagingDetailId, leadId, moveDetailId };
      const navigationExtras: NavigationExtras = {
        queryParams,
        skipLocationChange: false
      };
      this.breadcrumbService.setBreadcrumbs([
        { text: 'Dashboard', url: '/dashboard' },
        { text: 'Move Dashboard', url: '/moves-board' },
        { text: 'Packing Form', url: '' },
      ]);

      this._router.navigate(['/packing-details'], navigationExtras);
    }

  }
  returnPackingUrl(row: any) {
    const packagingDetailId = row.packingId;
    const leadId = row.leadId;
    const moveDetailId = row.moveDetailId;
    return { packagingDetailId, leadId, moveDetailId };
  }


  returnSurveyUrl(row: any) {
    const leadDetailId = row.leadDetailId;
    const leadId = row.leadId;
    return { leadDetailId, leadId };
  }

  // returnMoveUrl(row: any) {

  //   const moveDetailId = row.moveDetailId;
  //   const leadId = row.leadId;
  //   const leadDetailId = row.leadDetailId;
  //   const queryParams = { moveDetailId, leadDetailId, leadId };
  //   const navigationExtras: NavigationExtras = {
  //     queryParams,
  //     skipLocationChange: false
  //   };
  //   const url = this._router.createUrlTree(['/move-detail'], navigationExtras).toString();
  //   return url;
  // }
  returnMoveUrl(row: any) {
    const moveDetailId = row.moveDetailId;
    const leadId = row.leadId;
    const leadDetailId = row.leadDetailId;
    return { moveDetailId, leadDetailId, leadId };
  }


  moveInfoClick() {
    if (this._readPermission === false && this._userRole !== 'CompanyAdmin') {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the move info form', '');
      return;
    }

    const moveDetailId = this._movesBoardItem.moveDetailId;
    const leadId = this._movesBoardItem.leadId;
    const leadDetailId = this._movesBoardItem.leadDetailId;
    const queryParams = { moveDetailId, leadDetailId, leadId };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Moves Dashboard', url: '/moves-board' },
      { text: 'Moves Information Form', url: '' },
    ]);
    this._router.navigate(['/move-detail'], navigationExtras);
  }


  getStatuses(statusType: eStatusType, category: eMoveCategoryType, transport: eTransportType) {
    this._movdService.GetStatuses(statusType, category, transport).subscribe({
      next: (response: any) => {
        // console.log("status", response);
        if (response && response.length > 0) {
          this._statusList = response.filter((item: { isDeleted: boolean; }) => item.isDeleted === false);

          const firstStatus = response.find((item: any) => item.order === 1);
          this._firstStatus = firstStatus ? firstStatus.title : '';

          const lastStatus = response.reduce((maxItem: any, currentItem: any) => {
            return currentItem.order > (maxItem ? maxItem.order : 0) ? currentItem : maxItem;
          }, null);
          this._lastStatus = lastStatus ? lastStatus.title : '';

          // console.log("first and Last status:", this._firstStatus, this._lastStatus);

          // Check if the statuses in _statusList match the completed statuses
          const allStatusesMatch = this._statusList.every((status: { title: string; }) =>
            this._completedStatuses.includes(status.title)
          );

          if (!allStatusesMatch) {
            // console.log("Statuses retrieved from backend do not match completed statuses.");
          }
        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }
  openColumnModal(content: TemplateRef<any>) {
    this._modalService.open(content, { size: 'md' }).result.then((result) => {

    }).catch((res) => { });
  }
  toggleColumnVisibility() {
    this.tableColumns = this._allColumns
      .filter(column => this._selectedColumns[column])
      .map(column => ({ header: column }));

    localStorage.setItem('selectedColumns', JSON.stringify(this._selectedColumns));
    localStorage.setItem('selectedColumnsForThisSection', JSON.stringify(this._selectedColumns));


  }

  // moveInfoClick() {
  //   if (this._readPermission === false) {
  //     this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the Move Detail', '');
  //     return;
  //   }
  //   const moveDetailId = this._movesBoardItem.moveDetailId;
  //   const leadId = this._movesBoardItem.leadId;
  //   const leadDetailId = this._movesBoardItem.leadDetailId;
  //   const moveId = moveDetailId;
  //   // console.log("move detail", this._movesBoardItem);
  //   // console.log("_moveDetail.id and _leadDetailId", moveDetailId, leadDetailId, leadId);
  //   const queryParams = { moveDetailId, leadDetailId, leadId };

  //   const navigationExtras: NavigationExtras = {
  //     queryParams,
  //     skipLocationChange: false
  //   };
  //   this._router.navigate(['/move-detail'], navigationExtras);

  // }

  convertStringToNgbDateStruct(dateString: string): NgbDateStruct | null {
    if (dateString) {
      const dateParts = dateString.split('T')[0].split('-'); // Split the date string

      if (dateParts.length === 3) {
        const year = +dateParts[0]; // Convert to number
        const month = +dateParts[1]; // Convert to number
        const day = +dateParts[2]; // Convert to number
        // Check if the components are valid numbers
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          return { year, month, day };
        }
      }
    }
    return null;
  }
  redirectToAccount(id: any) {
    if (this._crmContactReadPermission === false) {
      this._authService.showUnAuthorizedDialog('Unauthorized', 'You are not authorized to see the profile', '');
      return;
    }
    // const customerType = this._sharedService.getCustomerType();
    // console.log("customerType", customerType);
    // console.log("account id", id);
    if (!id) {
      return;
    }

    const text = 'company';

    const queryParams = { text, id };
    const navigationExtras: NavigationExtras = {
      queryParams,
      skipLocationChange: false
    };
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Move Dashboard', url: '/leads-board' },
      { text: 'Profile', url: '' },
    ]);
    this._router.navigate(['/profile'], navigationExtras);
  }
  getColumnWidth(header: string): number {
    if (header === 'Move ID' || header === 'Survey ID' || header === 'Packing ID') {
      return 100; // Set the desired width for these columns
    } else {
      return 160; // Default width for other columns
    }
  }

}
