import { ChangeDetectorRef, Component, OnInit, Pipe, TemplateRef, ViewChild } from '@angular/core';
import { MoveCategoryTypeOptions, eMoveCategoryType, eTransportType } from '../../models/lead-detail.model';
import { Status, eStatusType } from '../../models/status.model';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { AppComponent } from 'src/app/app.component';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MovdService } from '../../services/movd.service';
import { AddCompanyModel, PipeAttributeModel, PipeModel, RoleModel } from 'src/app/views/admin/super-admin/super-admin.models';
import { ContactService } from 'src/services/contact.service';
import { SaleService } from 'src/services/sale.service';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';

@Component({
  selector: 'app-status-setting',
  templateUrl: './status-setting.component.html',
  styleUrls: ['./status-setting.component.scss']
})
export class StatusSettingComponent implements OnInit {

  _userEmail!: string | null;
  isRowHovered: boolean = false;
  _currentUserId: number = 0;
  _emailExists: boolean = false;
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //for data table
  @ViewChild('ngx-datatable') table?: DatatableComponent;
  tableData: any[] = []; // Array to hold the table data
  _tableDataList: any[] = []; // Array to hold the table data
  tableColumns: any[] = []; // Array to hold the table column headers
  ColumnMode = ColumnMode;

  //for adding company user
  @ViewChild('addCompanyModal') addCompanyModal: any
  _companyUser = new AddCompanyModel();
  _modalTitle = "Add User";
  _saveTitle = "Create";
  _modalFunction: any;

  _roles: any[] = [];
  _roleId: any;
  _companyId: number = 0;

  searchTerm: string = '';
  selectedRoleId: number | null = null;

  _addBtnTitle = 'Add User';

  closeResult: string = '';
  statusTypeActiveTabId = 1;

  //roles
  @ViewChild('addRoleModal') addRoleModal: any;
  _role = new RoleModel();
  searchRole: string = '';
  roleTableData: any[] = [];
  _roleDataList: any[] = [];
  roleTableColumns: any[] = [];

  //pipes
  @ViewChild('addPipeModal') addPipeModal: any;
  _pipe = new PipeModel();
  searchPipe: string = '';
  pipeTableData: any[] = [];
  _pipeDataList: any[] = [];
  pipeTableColumns: any[] = [];

  //pipe attribute
  @ViewChild('addPipeAttrbModal') addPipeAttrbModal: any;
  _pipeAttrb = new PipeAttributeModel();
  searchPipeAttrb: string = '';
  pipeAttrbTableData: any[] = [];
  _pipeAttrbDataList: any[] = [];
  pipeAttrbTableColumns: any[] = [];
  types: {
    id: number,
    value: string
  }[] = [
      { id: 1, value: "StringValue" },
      { id: 2, value: "NumericValue" },
      { id: 3, value: "DateValue" },
      { id: 4, value: "BooleanValue" }
    ];

  selectedTypeId: any;


  // @ViewChild('ngx-datatable') table?: DatatableComponent;
  // ColumnMode = ColumnMode;
  @ViewChild('addStatusModal') addStatusModal: any;

  _leadStatusType = eStatusType.Lead;
  _moveStatusType = eStatusType.Move;
  _clientJourneyStatusType = eStatusType.ClientJourney;

  // for opening pop modals
  _statusModalRef: NgbModalRef | undefined;

  // defaultNavActiveId: number = 1;

  //for validations
  statusExists: boolean = false;

  // merging status categories in this component
  _statusTitle: string | null = null;
  formSubmitted: boolean = false;
  _status = new Status();
  _searchStatus: string = '';
  _filteredTableData: any[] = [];
  _statusTableColumns: any;
  enumCategoryType = eMoveCategoryType;
  enumTransportType = eTransportType;

  _categoriesActiveTabId = 1;
  _selectedMoveCategoryId = eMoveCategoryType.DoorToPort; //selected by default 
  _selectedTransportTypeId = eTransportType.Sea; //selected by default 

  _statusTableData: any[] = [];

  //@Input() statusType!: eStatusType;

  _eStatusType = eStatusType;
  statusType = eStatusType.Lead;

  constructor(
    private _modalService: NgbModal,
    private _movdService: MovdService,
    private _authService: AuthService,
    private _contactService: ContactService,
    private _saleService: SaleService,
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private _appComponent: AppComponent,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.setBreadcrumbs([
      { text: 'Dashboard', url: '/dashboard' },
      { text: 'Status Settings', url: '/status-setting' },


    ]);

    const role = localStorage.getItem('role');

    if (role == 'CompanyAdmin') {
      const currentUserId = localStorage.getItem('currentUserId');
      this._currentUserId = Number(currentUserId);
      this._companyId = Number(localStorage.getItem('companyId'));

      //console.log("current user id", this._currentUserId);
      this.refreshStatusTableData()
      this.refreshPipeTableData();
    }
    else {
      this._router.navigate(['/dashboard']);
    }


  }

  savePipe() {


    if (this._pipe.required) {
      this._appComponent.showErrorDialog('Error', 'You cannot update this default status!');
      return;
    }
    // console.log("save pipe", this._pipe);
    if (this._pipe.pipeName.trim().length === 0 || !this._pipe.order) {
      return;
    }

    if (this._pipe.id == 0) {
      this._pipe.createdBy = this._currentUserId;
      this._pipe.companyId = this._companyId;

      this._saleService.addPipe(this._pipe).subscribe(
        response => {
          this._pipe = new PipeModel();
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('saved', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('saving', 'status');
        }
      );
    }
    else {
      // console.log("saving status", this._pipe);

      this._saleService.updatePipe(this._pipe).subscribe(
        response => {
          this._modalService.dismissAll('by: calling closeModal()');
          this._appComponent.showSuccessSwal('updated', 'status');
          this.refreshPipeTableData();
          this.table?.recalculate();
          this.table?.bodyComponent.updateOffsetY();
        },
        error => {
          this._appComponent.showErrorSwal('updating', 'status');
        }
      )
    }
  }

  deletePipe(data: any) {
    this._pipe = data;
    this._pipe.isDeleted = true;
    this.savePipe();
  }

  async updatePipeStatus(row: any, status: any, softDelete = false) {
    const pipeId = Number(row.id);
    let action: string;

    if (softDelete) {
      // Handle the delete action
      if (row.required) {
        this._appComponent.showErrorDialog('Error', 'You cannot delete this default status!');
        return;
      }

      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this status',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    } else {
      // Handle the activate/deactivate action
      if (row.required) {
        this._appComponent.showErrorDialog('Error', 'You cannot change this default status!');
        return;
      }

      if (row.isActive) {
        status = false;
        action = 'deactivated';
      } else {
        status = true;
        action = 'activated';
      }
    }

    this._saleService.updatePipeStatus(pipeId, status, softDelete).subscribe(
      (response: any) => {
        this._appComponent.showSuccessSwal(action, 'sales status');
        this.refreshPipeTableData();
        this.table?.recalculate();
        //this.table?.renderRows();
        this.table?.bodyComponent.updateOffsetY();
      },
      (error: any) => {
        // Handle any error that occurred during the update process
        console.error('Error updating attribute status:', error);
        // this._appComponent.showErrorSwal('updating', 'sales lead');
      }
    );
  }

  refreshPipeTableData() {
    this._saleService.getPipes(this._companyId).subscribe(
      (data: any) => {
        var pipeModel = new PipeModel();
        // console.log("company pipes data", data);

        // let filteredData = data.filter((item: any) => item.isDeleted === false);
        // if (filteredData.length > 0) {
        this.pipeTableData = data;
        this._pipeDataList = data;
        this.pipeTableColumns = Object.keys(pipeModel)
          .filter((key) =>
            key === 'pipeName')

          .map((key) => {
            let header: string;
            let isCheckbox: boolean = false;

            if (key == 'pipeName') {
              header = 'Title';
            } else {
              header = key;
            }

            return { header };
          });
        // }
      },
      (error: any) => {
        console.error("error getting lead status data", error);
      });

  }


  pipePreviewClick(id: number) {
    //console.log("pipe id", id);
    this.getPipeAndOpenModal(Number(id));
  }
  getPipeAndOpenModal(id: number): void {
    this._pipe = new PipeModel();
    const data = this.pipeTableData.filter(item => item.id === id)[0];
    this._pipe = data;
    // console.log("_pipe", this._pipe);

    this.openEditPipe();
  }
  openEditPipe() {
    this._modalTitle = "Status";
    this._saveTitle = "Update";

    this._modalService.open(this.addPipeModal, { size: 'md' }).result.then(
      (result) => {
        //this.basicModalCloseResult = "Modal closed" + result

      }).catch((res) => { });
  }



  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onRowMouseEnter(row: any) {
    row.isHovered = true;
  }

  onRowMouseLeave(row: any) {
    row.isHovered = false;
  }

  openAddPipe(template: TemplateRef<any>) {
    this._modalTitle = "Add Status";
    this._saveTitle = "Create";
    this._modalFunction = "add";

    this._pipe = new PipeModel();

    this._modalService.open(template, { size: 'md' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  performPipesSearch() {
    // Check if the search term is empty
    if (this.searchPipe === '') {
      this.pipeTableData = this._pipeDataList;
      return; // Exit the function to avoid further processing
    }

    // Filter the original data array based on the search term
    const filteredData = this._pipeDataList.filter((row: any) => {
      // Convert all values of the row to string and check if any value contains the search term
      return Object.values(row).some((value: any) => {
        return String(value).toLowerCase().includes(this.searchPipe.toLowerCase());
      });
    });

    // Assign the filtered data to the displayedDeals array
    this.pipeTableData = filteredData;

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  onChangeStatusTab(statusType: eStatusType | number): void {
    // console.log("status type", statusType);

    this._categoriesActiveTabId = 1;
    this._selectedMoveCategoryId = this.enumCategoryType.DoorToPort;
    this._selectedTransportTypeId = this.enumTransportType.Sea;
    this._statusTableData = [];
    this._filteredTableData = [];
    this.statusType = statusType;
    this.refreshStatusTableData();
  }

  onChangeCategoriesTab(tabIndex: number, moveCategory: eMoveCategoryType, transportType: eTransportType) {
    // console.log(`category type = ${moveCategory} & transportType = ${transportType}`);

    this._categoriesActiveTabId = tabIndex;
    this._selectedMoveCategoryId = moveCategory;
    this._selectedTransportTypeId = transportType;
    this.refreshStatusTableData();
  }

  refreshStatusTableData() {
    // this._statusTableData = [];
    // this._filteredTableData = [];
    this._movdService.GetStatuses(this.statusType, this._selectedMoveCategoryId, this._selectedTransportTypeId).subscribe({
      next: (data: any) => {
        // console.log("statuse in status categories", data);



        // this._statusTableData = data.filter((row: Status) =>
        //   row.category === this._selectedMoveCategoryId && row.transportType === this._selectedTransportTypeId
        // );
        this._statusTableData = data;

        this._filteredTableData = this._statusTableData.sort((a, b) => a.order - b.order);
        // console.log('filter status data ', this._filteredTableData);
        // console.log('category id ', this._selectedMoveCategoryId);
        // console.log('transport id ', this._selectedTransportTypeId);
        this._statusTableColumns = Object.keys(data[0])
          .filter(
            (key) =>
              [
                'title',
                'action'
              ].includes(key)
          )
          .map((key) => {
            let header: string;
            let isCheckbox: boolean = false;

            switch (key) {
              case 'title':
                header = 'Status Name';
                break;
              case 'action':
                header = 'Action';
                break;

              default:
                header = key; // Use the original key if no specific header is defined
                break;
            }

            return { header, isCheckbox };
          });

      },
      error: (error: any) => {
        if (error.status === 404) {
          // console.log('status for this type and category not found', error);
        }
        else {
          console.error('Error getting status data', error);
        }
      }
    });
  }

  performStatusSearch() {
    // Check if the search term is empty
    if (this._searchStatus === '') {
      // Reset the displayed leads list to the original data
      this._filteredTableData = this._statusTableData;
    } else {
      // Filter the original data array based on the search term
      const filteredData = this._statusTableData.filter((row: any) => {
        // Convert all values of the row to string and check if any value contains the search term
        return Object.values(row).some((value: any) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(this._searchStatus.toLowerCase());
          }
          return false; // Skip non-string values
        });
      });

      // Assign the filtered data to the displayed leads array
      this._filteredTableData = filteredData;
    }

    // Reset the page to the first page
    if (this.table) {
      this.table.offset = 0;
    }

    // Recalculate and update the table
    this.table?.recalculate();
    this.table?.bodyComponent.updateOffsetY();
  }

  getStatusAndOpenModal(id: number): void {
    this.statusExists = false;
    const data = this._statusTableData.filter(item => item.id === id)[0];
    if (data) {
      this._status = data;
      this._statusTitle = data.title;
      this.openEditStatus();
    }
  }

  StatusPreviewClick(id: number) {
    this.getStatusAndOpenModal(Number(id));
  }

  openEditStatus() {
    this._saveTitle = "Update";
    // this._modalService.open(this.addStatusModal, { size: 'md' }).result.then(
    //   (result) => {
    //   }
    // ).catch((res) => { });
    this._statusModalRef = this._modalService.open(this.addStatusModal, {
      backdrop: 'static',
      keyboard: false,
    });

    this._statusModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  openStatusModal(content: any): void {
    this._saveTitle = "Save";
    this.statusExists = false;
    this._status = new Status();
    this._statusTitle = null;
    // this._modalService.open(content, { centered: true });
    this._statusModalRef = this._modalService.open(content, {
      backdrop: 'static',
      keyboard: false,
    });

    this._statusModalRef.result.then(
      (result) => { },
      (reason) => {
        if (reason === 'backdrop-click') {
        }
      }
    );
  }

  saveStatus(modal: any): void {
    this.formSubmitted = true;
    if (this.statusExists === true || this._statusTitle === null) {
      return;
    }

    // console.log('status data ', this._status);

    this._status.title = this._statusTitle;
    this._status.category = this._selectedMoveCategoryId;
    this._status.transportType = this._selectedTransportTypeId;
    this._status.statusType = this.statusType;

    // console.log("status to be saved ", this._status);

    if (this._status.required) {
      this._appComponent.showErrorDialog('Error', 'You cannot delete this default status!');
      return;
    }

    this._movdService.saveStatus(this._status).subscribe({
      next: (response: any) => {
        modal.close();
        // console.log('Save Status response', response);
        this._appComponent.showSuccessSwal('updated', 'status');
        this.refreshStatusTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();

      },
      error: (error: any) => {
        this._appComponent.showErrorSwal('updating', 'status');
        console.error('Error', error);
      }
    });

    this.formSubmitted = false;
  }

  async updateStatus(row: any, status: any, softDelete = false) {
    if (row.required) {
      this._appComponent.showErrorDialog('Error', 'You cannot delete this default status!');
      return;
    }

    const statusId = Number(row.id);
    let action: string;

    if (row.isActive) {
      status = false;
      action = 'deactivated';
    } else {
      status = true;
      action = 'activated';
    }

    if (softDelete) {
      let confirmed = this._appComponent.showConfirmationDialog(
        'Are you sure?',
        'You are about to delete this status',
        'Yes, delete it!',
      );

      if (await confirmed === false) {
        return;
      } else {
        action = 'deleted';
      }
    }

    this._movdService.updateLeadStatus(statusId, status, softDelete).subscribe({
      next: (response: any) => {
        this._appComponent.showSuccessSwal(action, 'status');
        this.refreshStatusTableData();
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
        // console.log('Status updated', response);
      },
      error: (error: any) => {
        console.error('Error updating status:', error);
        this._appComponent.showErrorSwal('updating', 'status');
      }
    });
  }

  isButtonDisabled(): boolean {
    if (this._statusTitle) {
      return this._statusTitle.trim() === '' || !/^[A-Za-z0-9\s]{1,15}$/.test(this._statusTitle);
    }
    else {
      return false;
    }
  }

  closeModal(type: string, modal: any) {
    this._status = new Status();
    this._statusTitle = null;
    this.statusExists = false;
    this.refreshStatusTableData();
    modal.close();
  }

  validateItemExists(): void {
    const input = this._statusTitle;

    if (this._statusTitle === this._status.title) {
      return;
    }

    if (!input || input.trim().length === 0) {
      // Invalid or empty email format, reset 'emailExists' and return.
      this.statusExists = false;
      return;
    }

    if (this._statusTableData && this._statusTableData.length > 0) {
      this.statusExists = this._statusTableData.some(item => item.title.toLowerCase() === input.toLowerCase() && item.category === this._selectedMoveCategoryId && item.transportType === this._selectedTransportTypeId);
    } else {
      this.statusExists = false;

    }
  }

  // drag and drop for changing the order of status
  onDragStart(event: DragEvent, row: any, tableData: any[]) {
    if (row.required) {
      this._appComponent.showErrorDialog('Error', 'You cannot drag this default status!');
      return;
    }

    // console.log('Drag started', row);
    // Store the dragged row data in a dataTransfer object
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      dataTransfer.setData('text/plain', JSON.stringify(row));
    }
  }

  onDragOver(event: DragEvent) {
    // Prevent the default action to allow dropping
    event.preventDefault();
  }

  onDrop(event: DragEvent, tableData: any[], type: string) {
    event.preventDefault();

    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      const row = JSON.parse(dataTransfer.getData('text/plain'));
      const newIndex = this.calculateNewIndex(event);

      // Get the target row
      const targetRow = tableData[newIndex];

      // Check if the target row is required
      // these statuses can not be dragged 
      // if (targetRow && targetRow.required) {
      //   this._appComponent.showErrorDialog('Error', 'You cannot drop onto this default status!');
      //   return;
      // }

      const originalIndex = tableData.findIndex(item => item.id === row.id);

      if (typeof newIndex === 'number') {
        // Store the order of the item that will be replaced
        const replacedItemOrder = tableData[newIndex].order;

        // Swap the order property of the dragged item and the replaced item
        tableData[newIndex].order = tableData[originalIndex].order;

        // Update the order property of the dragged item
        row.order = replacedItemOrder;

        // Update the order property of the replaced item
        tableData[originalIndex].order = row.order;

        const newArray = [...tableData]; // Create a new array
        newArray.splice(originalIndex, 1); // Remove the item from its original position in the new array
        newArray.splice(newIndex, 0, row); // Insert the dragged item at the new position in the new array

        if (type === 'statuses') {
          this._filteredTableData = newArray; // Assign the new array to _filteredTableData
          this.saveReorderedStatuses();
        }
        else if (type === 'pipes') {
          this.pipeTableData = newArray; // Assign the new array to _filteredTableData
          this.saveReorderedPipes();
        }
      }
    }
  }



  calculateNewIndex(event: DragEvent): number {
    const targetElement = event.target as HTMLElement;
    const targetRowElement = targetElement.closest('.datatable-row-wrapper');
    if (targetRowElement) {
      const rowElements = Array.from(document.querySelectorAll('.datatable-row-wrapper'));
      const mouseY = event.clientY;
      const closestRowIndex = rowElements.findIndex(rowEl => {
        const rect = rowEl.getBoundingClientRect();
        return mouseY > rect.top && mouseY < rect.bottom;
      });
      // console.log('Closest row index', closestRowIndex);
      return closestRowIndex >= 0 ? closestRowIndex : rowElements.length;
    }
    return -1;
  }

  saveReorderedStatuses() {

    // Call the MovdService to send the update request to the backend
    this._movdService.saveReorderedStatuses(this._filteredTableData).subscribe({
      next: (response: any) => {
        // console.log('save Reordered Statuses response', response);
        this._appComponent.showSuccessSwal('updated', 'statuses');
        this._statusTableData = response;
        this._filteredTableData = response;
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Handle error, e.g., show an error message to the user
      }
    });
  }

  saveReorderedPipes() {

    // Call the MovdService to send the update request to the backend
    this._saleService.saveReorderedPipes(this.pipeTableData).subscribe({
      next: (response: any) => {
        // console.log('save Reordered Statuses response', response);
        this._appComponent.showSuccessSwal('updated', 'statuses');
        this.pipeTableData = response;
        this.table?.recalculate();
        this.table?.bodyComponent.updateOffsetY();
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Handle error, e.g., show an error message to the user
      }
    });
  }

}
