import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ReportsService } from 'src/services/reports.service';
import { eMoveReportType, eReportSubType, MoveReportsDTO } from '../models/move-reports-dto.model';
import { GeneralService } from 'src/services/general.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MoveCategoryTypeOptions, MoveTypeOptions, TransportTypeOptions } from '../../models/lead-detail.model';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { DealsModel } from 'src/app/views/crm/sales/deals/deals.models';
import { ContactService } from 'src/services/contact.service';
import { CustomerSatisfactionComponent } from './customer-satisfaction/customer-satisfaction.component';
import { LeadsDetailedReportDTO } from '../models/lead-to-move-detail.model';
import { MoveDetailReportDTO } from '../models/move-detail-report.model';
import { LeadToMoveSunmmarizeReportDTO } from '../models/lead-to-move-summarize.model';
import { LeadsDetailReportDTO } from '../models/leads-detail-report.model';
import { LeadsSummarizeReportDTO } from '../models/leads-summarize-report.model';
import { MoveSunmmarizeReportDTO } from '../models/move-summarize-report.model';
import { eContactType } from 'src/app/views/crm/contact/contact.models';


@Component({
  selector: 'app-move-reports',
  templateUrl: './move-reports.component.html',
  styleUrls: ['./move-reports.component.scss']
})
export class MoveReportsComponent implements OnInit {

  defaultNavActiveId = 1;
  _moduleActiveTabId = 1;
  _reportActiveTabId1 = 1;
  _reportActiveTabId2 = 1;
  _reportActiveTabId3 = 1;

  columnMode: ColumnMode = ColumnMode.force;
  loadingIndicator = true;
  hoveredDate: NgbDateStruct | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  _moveType = MoveTypeOptions;
  _moveCategory = MoveCategoryTypeOptions;
  _transportType = TransportTypeOptions;
  // @ViewChild('movereports') movereports: MoveReportsDTO | undefined;
  @ViewChild('customerSatisfaction') customerSatisfaction: CustomerSatisfactionComponent | undefined;
  _moveReports: MoveReportsDTO = new MoveReportsDTO;
  // _customerReports: CustomerSatisfaction = new CustomerSatisfaction;
  eMoveReportType = eMoveReportType;
  eReportSubType = eReportSubType;
  _moveReportType: number = 0;
  _reportSubType: number = 0;
  @Output() reportData = new EventEmitter<MoveReportsDTO>();
  _companies: any = [];
  _contacts: any = [];
  // _lead: any;
  _allCustomers: any = [];
  // _displayedData: any = ['18 April, 24', '6', '4', '5', '2', '17'];
  _selectedAccountIds: number[] | null = null;
  _selectedThirdPartyIds: number[] | null = null;
  showSection: boolean = false;
  showSalesSections: boolean = false;
  //For Total Leads
  _leadSummarizeReportModel = new LeadsSummarizeReportDTO();
  _leadToMoveSummarizeReportModel = new LeadToMoveSunmmarizeReportDTO();
  _leadSummarizeReportData: any[] = [];
  leadSummarizeTableColumns: any[] = [];
  _leadDetailReportData: any[] = [];
  leadDetailTableColumns: any[] = [];
  _leadDetailedReportModel = new LeadsDetailReportDTO();
  //For Total Moves
  _moveSummarizeReportData: any[] = [];
  moveSummarizeTableColumns: any[] = [];
  _moveDetailReportData: any[] = [];
  _moveDetailReportDataModel = new MoveDetailReportDTO();
  moveDetailTableColumns: any[] = [];
  _moveDetailReportModel = new MoveDetailReportDTO();
  //For Lead to Move Converstion Rate
  _leadToMoveDetailReportModel = new LeadsDetailedReportDTO();
  _leadToMoveSummarizedReportData: any[] = [];
  leadToMoveSummarizeTableColumns: any[] = [];
  _leadToMoveDetailReportData: any[] = [];
  leadToMoveDetailTableColumns: any[] = [];


  _dealName!: string | null;
  _allContacts: any[] = [];
  _deal = new DealsModel();
  _companyId: number = 0

  constructor(private _reportsService: ReportsService, public generalService: GeneralService,
    private _contactService: ContactService,
  ) { this._companyId = Number(localStorage.getItem('companyId')); }

  ngOnInit(): void {
    this.getCustomers();
  }
  getReports() {
    this._moveReports.moveReportType = this._moveReportType;
    this._moveReports.reportSubType = this._reportSubType;
    this._moveReports.thirdPartyIds = this._selectedThirdPartyIds;
    this._moveReports.accountIds = this._selectedAccountIds;
    this._moveReports.origin = this._moveReports.origin;
    this._moveReports.destination = this._moveReports.destination;


    // if(customer satisfaction)
    // call function of get reports in customer satisfaction
    // use input output etc whatever you want to use
    // and display data

    // console.log('reports params', this._moveReports);

    if (this._moveReports.moveReportType === eMoveReportType.CustomerSatisfaction) {
      // console.log('reports params', this._moveReports);
      this.customerSatisfaction?.getCustomerSatisfactionReports(this._moveReports);
    }
    else {
      this._reportsService.getLeadReports(this._moveReports).subscribe({
        next: (data: any) => {
          // console.log("reports", data);

          // if (data && data.length > 0) {
          if (this._moveReports.moveReportType === eMoveReportType.TotalLeads) {
            if (this._moveReports.reportSubType === eReportSubType.SummarizeReport) {
              this._leadSummarizeReportData = data;

              this.leadSummarizeTableColumns = Object.keys(this._leadSummarizeReportModel)
                .map((key) => {
                  let header: string;
                  let prop: string = key;
                  switch (key) {
                    case 'date':
                      header = 'Date';
                      break;
                    case 'new':
                      header = 'New';
                      break;
                    case 'inProgress':
                      header = 'InProgress';
                      break;
                    case 'won':
                      header = 'Won';
                      break;
                    case 'lost':
                      header = 'Lost';
                      break;
                    case 'totalLeads':
                      header = 'Total Leads';
                      break;
                    default:
                      header = key; // Use the original key if no specific header is defined
                      break;
                  }
                  return { header, prop };
                });
              // console.log("column data lead summaraise", this.leadSummarizeTableColumns);
            }
            else if (this._moveReports.reportSubType === eReportSubType.DetailReport) {
              this._leadDetailReportData = data;
              this.leadDetailTableColumns = this.getLeadDetailTableColumns();
            }
          }
          if (this._moveReports.moveReportType === eMoveReportType.TotalMoves) {
            if (this._moveReports.reportSubType === eReportSubType.SummarizeReport) {
              //console.log('_moveSummarizeReportData', data);

              var moveSummarizeModel = new MoveSunmmarizeReportDTO();
              this._moveSummarizeReportData = data;
              this.moveSummarizeTableColumns = Object.keys(moveSummarizeModel)
                .map((key) => {
                  let header: string;
                  let prop: string = key;
                  switch (key) {
                    case 'date':
                      header = 'Date';
                      break;
                    case 'completed':
                      header = 'Completed';
                      break;
                    case 'cancelled':
                      header = 'Cancelled';
                      break;
                    case 'reverted':
                      header = 'Reverted';
                      break;
                    case 'local':
                      header = 'Local';
                      break;
                    case 'import':
                      header = 'Import';
                      break;
                    case 'export':
                      header = 'Export';
                      break;
                    case 'totalMoves':
                      header = 'Total Moves';
                      break;
                    default:
                      header = key; // Use the original key if no specific header is defined
                      break;
                  }
                  return { header, prop };
                });
            }
            else if (this._moveReports.reportSubType === eReportSubType.DetailReport) {
              console.log('data', data);
              this._moveDetailReportData = data;
              this.moveDetailTableColumns = Object.keys(this._moveDetailReportDataModel)
                .map((key) => {
                  let header: string;
                  let prop: string = key;
                  switch (key) {
                    case 'date':
                      header = 'Date';
                      break;
                    case 'moveId':
                      header = 'Move ID';
                      break;
                    case 'account':
                      header = 'Account';
                      break;
                    case 'customer':
                      header = 'Customer';
                      break;
                    case 'status':
                      header = 'Status';
                      break;
                    case 'origin':
                      header = 'Origin';
                      break;
                    case 'destination':
                      header = 'Destination';
                      break;
                    default:
                      header = key; // Use the original key if no specific header is defined
                      break;
                  }
                  return { header, prop };
                });
            }
          }
          if (this._moveReports.moveReportType === eMoveReportType.LeadToMove) {
            if (this._moveReports.reportSubType === eReportSubType.SummarizeReport) {
              console.log("reports", data);
              this._leadToMoveSummarizedReportData = data;
              this.leadToMoveSummarizeTableColumns = Object.keys(this._leadToMoveSummarizeReportModel)
                .map((key) => {
                  let header: string;
                  let prop: string = key;
                  switch (key) {
                    case 'date':
                      header = 'Date';
                      break;
                    case 'totalLeads':
                      header = 'Total Leads';
                      break;
                    case 'totalMoves':
                      header = 'Total Moves';
                      break;
                    case 'leadToMoveRate':
                      header = 'Lead To Move Converstion Rate';
                      break;
                    default:
                      header = key; // Use the original key if no specific header is defined
                      break;
                  }
                  return { header, prop };
                });
            }
            else if (this._moveReports.reportSubType === eReportSubType.DetailReport) {
              this._leadToMoveDetailReportData = data;
              // .filter((item: any) => {
              //   const matchesOrigin = this._moveReports.origin ? item.origin === this._moveReports.origin : true;
              //   const matchesDestination = this._moveReports.destination ? item.destination === this._moveReports.destination : true;
              //   return matchesOrigin && matchesDestination;
              // });
              this.leadToMoveDetailTableColumns = this.getLeadToMoveTableColumns();

              // this._leadToMoveDetailReportData = data;

            }
          }
          // }
          // else {
          //   this._leadSummarizeReportData = [];
          //   this._leadDetailReportData = [];
          //   this._moveSummarizeReportData = [];
          //   this._moveDetailReportData = [];
          //   this._leadToMoveSummarizedReportData = [];
          //   this._leadToMoveDetailReportData = [];

          // }
        },
        error: (error) => {
          console.error(error);
        }
      });
    }

  }

  getLeadToMoveTableColumns() {
    const validKeys = [
      'date',
      'leadDetailId',
      'account',
      'customer',
      'status',
      'origin',
      'destination',
      'lostReason'
    ];

    return Object.keys(this._leadToMoveDetailReportModel)
      .filter(key => validKeys.includes(key))
      .map(key => {
        let header: string;
        let prop: string = key;
        switch (key) {
          case 'date':
            header = 'Date';
            break;
          case 'leadDetailId':
            header = 'Lead ID';
            break;
          case 'account':
            header = 'Account';
            break;
          case 'customer':
            header = 'Customer';
            break;
          case 'status':
            header = 'Status';
            break;
          case 'origin':
            header = 'Origin';
            break;
          case 'destination':
            header = 'Destination';
            break;
          case 'lostReason':
            header = 'Lost Reason';
            break;
          default:
            header = key; // Use the original key if no specific header is defined
            break;
        }
        return { header, prop };
      });
  }



  getLeadDetailTableColumns() {
    // console.log(this._leadDetailedReportModel);
    return Object.keys(this._leadDetailedReportModel)
      .filter((key) => {
        return (
          key === 'date' ||
          key === 'leadDetailId' ||
          key === 'account' ||
          key === 'customer' ||
          key === 'status' ||
          key === 'origin' ||
          key === 'destination'
        );
      })
      .map((key) => {
        let header: string;
        let prop: string = key;
        switch (key) {
          case 'date':
            header = 'Date';
            break;
          case 'leadDetailId':
            header = 'Lead ID';
            break;
          case 'account':
            header = 'Account';
            break;
          case 'customer':
            header = 'Customer';
            break;
          case 'status':
            header = 'Status';
            break;
          case 'origin':
            header = 'Origin';
            break;
          case 'destination':
            header = 'Destination';
            break;
          default:
            header = key;
            break;
        }
        return { header, prop };
      });
  }


  getMoveDetailTableColumns() {
    console.log(this._moveDetailReportModel);
    return Object.keys(this._moveDetailReportModel)
      .filter((key) => {
        return (
          key === 'date' ||
          key === 'moveId' ||
          key === 'account' ||
          key === 'customer' ||
          key === 'status' ||
          key === 'origin' ||
          key === 'destination'
        );
      })
      .map((key) => {
        let header: string;
        let prop: string = key;
        switch (key) {
          case 'date':
            header = 'Date';
            break;
          case 'moveId':
            header = 'Move ID';
            break;
          case 'account':
            header = 'Account';
            break;
          case 'customer':
            header = 'Customer';
            break;
          case 'status':
            header = 'Status';
            break;
          case 'origin':
            header = 'Origin';
            break;
          case 'destination':
            header = 'Destination';
            break;
          default:
            header = key; // Use the original key if no specific header is defined
            break;
        }
        return { header, prop };
      });
  }

  receiveReportData(data: MoveReportsDTO) {
    this._moveReports = data; // Assign the emitted data to moveReports
  }
  toggleSection() {
    this.showSection = !this.showSection;
  }
  onChangeTab(type: any) {

  }
  onChangeModuleTab(type: any) {
  }

  onChangeReportType(type: any, moveReportType: eMoveReportType) {
    // console.log("onChangeReportType", moveReportType);

    this._moveReportType = moveReportType;
    this._reportSubType = eReportSubType.SummarizeReport;
  }

  onChangeLeadReportSubType(type: any, reportSubType: eReportSubType) {
    this._reportSubType = reportSubType;
  }

  onChangeMoveReportSubType(type: any, reportSubType: eReportSubType) {
    // console.log("onChangeMoveReportSubType", reportSubType);
    this._reportSubType = reportSubType;
  }

  onChangeLeadToMoveReportSubType(type: any, reportSubType: eReportSubType) {
    this._reportSubType = reportSubType;
  }

  onChangeCustSatisfactionSubType(type: any, reportSubType: eReportSubType) {
    this._reportSubType = reportSubType;
  }

  clearFilter() {

    this._moveReports = new MoveReportsDTO;

  }
  onDateSelect(date: any) {
    const selectedDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    this.hoveredDate = { year: date.year, month: date.month, day: date.day };
    if (!this._moveReports.fromDate && !this._moveReports.toDate) {
      this._moveReports.fromDate = selectedDate;
    } else if (
      this._moveReports.fromDate &&
      !this._moveReports.toDate &&
      selectedDate > this._moveReports.fromDate
    ) {
      this._moveReports.toDate = selectedDate;
    } else {
      this._moveReports.fromDate = selectedDate;
      this._moveReports.toDate = null;
    }
    this.fromDate = this.convertDateToNgbDate(this.generalService.formatDate(this._moveReports.fromDate, null));
    this.toDate = this.convertDateToNgbDate(this.generalService.formatDate(null, this._moveReports.toDate));
    if (this._moveReports.fromDate && this._moveReports.toDate) {
      const filterDateFrom = new Date(this._moveReports.fromDate);
      const filterDateTo = new Date(this._moveReports.toDate);
      this._moveReports.fromDate = filterDateFrom;
      this._moveReports.toDate = filterDateTo;
    }
  }

  convertDateToNgbDate(date: string | Date | null): NgbDate | null {
    if (date) {
      let jsDate = new Date(date);
      return new NgbDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,   // NgbDate month is 1-based
        jsDate.getDate() //+ 1
      );
    } else {
      return null;
    }
  }
  isHovered(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    const hoveredDate = this.hoveredDate;

    if (!hoveredDate) {
      return false;
    }
    return fromDate && !toDate && date.after(fromDate) && date.before(toDate) && date.equals(hoveredDate);
  }

  isInside(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    return fromDate && toDate && date.after(fromDate) && date.before(toDate);
  }
  isRange(date: NgbDate) {
    const fromDate = this.convertDateToNgbDate(this._moveReports.fromDate);
    const toDate = this.convertDateToNgbDate(this._moveReports.toDate);
    if (fromDate && toDate) {
      return date.equals(fromDate) || date.equals(toDate) || this.isInside(date) || this.isHovered(date) || this.isRangeSelected(date, fromDate, toDate);
    }

    return false;
  }
  isRangeSelected(date: NgbDate, fromDate: NgbDate, toDate: NgbDate) {
    if (!fromDate || !toDate) {
      return false;
    }

    return date.after(fromDate) && date.before(toDate);
  }

  onChangeCategory(event: Event) {
  }

  onChangeTransport(event: Event) {
  }

  getCustomers() {
    this._contactService.getContacts().subscribe({
      next: (response: any) => {
        // console.log("getCustomers", response);

        // if (response && response.length > 0) {
        //   this._allCustomers = response;
        //   this._contacts = response.filter((item: { contactType: number; }) => item.contactType === eContactType.Contact);
        //   this._companies = response.filter((item: { contactType: number; }) => item.contactType === eContactType.Company);
        if (response.contacts && response.contacts.length > 0) {
          this._allCustomers = response.contacts;
          // console.log("getCustomers data cc", response);
          this._contacts = response.contacts.filter((item: { contactType: number; companyId: number }) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);
          // console.log("company id", this._companyId);
          this._companies = response.contacts.filter((item: { contactType: number; companyId: number }) => item.contactType === eContactType.Company && item.companyId !== this._companyId);
          // console.log("_companies", this._companies);

        }
      },
      error: (error: any) => {
        console.error("error", error);
      }
    });
  }
  filterContacts(type: string, ids: number[] | null) {

    // console.log("filter contacts", id);
    if (this._contacts && this._contacts.length > 0) {
      if (type === 'contact') {
        this._selectedAccountIds = ids;
      } else if (type === 'thirdParty') {
        this._selectedThirdPartyIds = ids;
      }
    }
  }
  getContacts() {
    this._contactService.getContacts().subscribe(
      (data: any) => {
        // console.log("get contacts response", data);
        if (data && data.length > 0) {
          this._allContacts = data;
          // this._allContacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === 0 && item.companyId === this._companyId);
          this._contacts = data.filter((item: { contactType: number; companyId: number }) => item.contactType === eContactType.Contact && item.companyId !== this._companyId);

        }
        // console.log("this._contacts", this._contacts);
      },
      error => {
        console.error("error getting contacts", error);
      }
    );
  }
}
