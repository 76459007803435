<div class="container mt-5">
  <div class="row">
    <div class="col-md-12">

      <div class="text-center">
        <ng-container *ngIf="_companySetting.logo as companyLogo; else defaultLogo">
          <img [src]="companyLogo || 'assets/images/movd/movd_logo.png'" alt="Company Logo"
            width="150">
        </ng-container>
        <ng-template #defaultLogo>
          <img src="assets/images/movd/movd_logo.png" alt="Company Logo" width="150">
        </ng-template>
      </div>

      <h1 class="text-center mt-3">Survey Summary</h1>
      &nbsp;
      <h4> General Info</h4>
      <table class="table table-bordered mt-2" *ngIf="_leadBoardItem">
        <tbody>
          <tr>
            <td><label>Lead ID</label></td>
            <td>#{{ _leadBoardItem.leadDetailId || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Customer ID</label></td>
            <td>{{ _leadBoardItem.customerId || ' - ' }}</td>
          </tr>
          <tr>
            <td>
              <label>
                Customer Name
              </label>
            </td>
            <td>{{ _leadBoardItem.customer || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Account Name</label></td>
            <td>{{ _leadBoardItem.accountName || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Move Type</label></td>
            <td>{{ enumMappingService.mapMoveType(_leadBoardItem.moveType || 0) }}</td>
          </tr>
          <tr>
            <td><label>Move Category</label></td>
            <td>{{ enumMappingService.mapMoveCategoryType(_leadBoardItem.moveCategory || 0) }}
            </td>
          </tr>
          <tr>
            <td><label>Transport Type</label></td>
            <td>{{ enumMappingService.mapTransportType(_leadBoardItem.moveTransport || 0) }}</td>
          </tr>
          <tr>
            <td><label>POL</label></td>
            <td>{{ _leadBoardItem.portOfLoading || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>POD</label></td>
            <td>{{ _leadBoardItem.portOfDischarge || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Place Of Delivery</label></td>
            <td>{{ _leadBoardItem.placeOfDelivery || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Requested Survey Date</label></td>
            <td>{{ generalService.formatDate(_leadBoardItem.surveyDate, null) || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Requested Packing Date</label></td>
            <td>{{ generalService.formatDate(_leadBoardItem.packagingDate, null) || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Requested Loading Date</label></td>
            <td>{{ generalService.formatDate(_leadBoardItem.loadingDate, null) || ' - ' }}</td>
          </tr>
          <tr>
            <td><label>Client Notes</label></td>
            <td>{{ _moveSurvey.externalNotes || ' - ' }}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4>Address Details</h4>
      <table class="table table-bordered mt-2" *ngIf="_leadDetail">
        <tbody>
          <ng-container *ngFor="let i of [0, 1, 2]">
            <ng-container *ngIf="_originAddresses[i] || _destinationAddresses[i]">
              <tr *ngIf="i !==0 ">
                <td colspan="4"><strong>{{ i === 0 ? 'First' : i === 1 ? 'Second' : 'Third' }}
                    Address</strong></td>
              </tr>
              <tr>
                <td><label><strong>Origin</strong></label></td>
                <td></td>
                <td><label><strong>Destination</strong></label></td>
                <td></td>
              </tr>
              <tr>
                <td><label>Street</label></td>
                <td>{{ _originAddresses[i].streetAddress || '-' }}</td>
                <td><label>Street</label></td>
                <td>{{ _destinationAddresses[i].streetAddress || '-' }}</td>
              </tr>
              <tr>
                <td><label>City</label></td>
                <td>{{ _originAddresses[i].city || '-' }}</td>
                <td><label>City</label></td>
                <td>{{ _destinationAddresses[i].city || '-' }}</td>
              </tr>
              <tr>
                <td><label>State</label></td>
                <td>{{ _originAddresses[i].state || '-' }}</td>
                <td><label>State</label></td>
                <td>{{ _destinationAddresses[i].state || '-' }}</td>
              </tr>
              <tr>
                <td><label>Country</label></td>
                <td>{{ _originAddresses[i].country || '-' }}</td>
                <td><label>Country</label></td>
                <td>{{ _destinationAddresses[i].country || '-' }}</td>
              </tr>
              <tr>
                <td><label>Postal Code</label></td>
                <td>{{ _originAddresses[i].zipCode || '-' }}</td>
                <td><label>Postal Code</label></td>
                <td>{{ _destinationAddresses[i].zipCode || '-' }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <!-- <div class="col-md-12 mt-4">
          <i class="mdi mdi-map-marker-radius"></i>
          <label class="text-muted small address-text">{{ _moveSummay.origin || ' - ' }}</label>
        </div>
        <div class="col-md-12">
          <div class="d-flex">
            <i class="fas fa-ellipsis-v fa-1x mx-1 m-1 move-type"></i>
          </div>
        </div>
        <div class="col-md-12">
          <i class="mdi mdi-flag-outline"></i>
          <label class="text-muted small address-text"> {{ _moveSummay.destination || ' - ' }}
          </label>
        </div> -->
      &nbsp;
      <h4> Survey Details</h4>

      <!-- <p>Area Items</p> -->
      <table class="table table-bordered mt-4">
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Voulme ({{_volumeUnit}})</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of _areaItems; let i = index">
            <ng-container *ngIf="!item.isDeleted">
              <!-- Check if a new surveyPlace starts -->
              <tr *ngIf="i === 0 || item.surveyPlace !== _areaItems[i - 1].surveyPlace">
                <!-- <td colspan="4">{{ item.surveyPlace }} &nbsp; (Item count: &nbsp; 1, Volume:&nbsp; 2, Weight: &nbsp;3)
                  </td> -->
                <td colspan="4">
                  <strong>{{ item.surveyPlace || '' }}</strong> &nbsp; (Item count: &nbsp; {{
                  getItemCount(item.surveyPlace || '')
                  }},
                  Volume: {{ calculateVolume(item.surveyPlace || '', _volumeUnit, 'bySurveyPlace')
                  }},
                  Weight: {{ calculateWeight(item.surveyPlace || '', _weightUnit, 'bySurveyPlace')
                  }})
                </td>
              </tr>

              <!-- Display item data -->
              <tr>
                <td>{{ item.item || ' - ' }}</td>
                <td>{{ item.quantity || ' - ' }}</td>
                <td>{{ item.volume ? item.volume.toFixed(2) : ' - ' }}</td>
                <td>{{ item.weight ? item.weight.toFixed(2) : ' - ' }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <!-- &nbsp;
        <p> Other Survey Items </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Type</th>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="_materials.length > 0">
              <ng-container *ngFor="let material of _materials">
                <ng-container *ngIf="!material.isDeleted">
                  <tr>
                    <td>Materials</td>
                    <td>{{ material.item || ' - ' }}</td>
                    <td>{{ material.quantity || ' - ' }}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="_labour.length > 0">
              <ng-container *ngFor="let labor of _labour">
                <ng-container *ngIf="!labor.isDeleted">
                  <tr>
                    <td>Labor</td>
                    <td>{{ labor.item || ' - ' }}</td>
                    <td>{{ labor.quantity || ' - ' }}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="_vehicles.length > 0">
              <ng-container *ngFor="let vehicle of _vehicles">
                <ng-container *ngIf="!vehicle.isDeleted">
                  <tr>
                    <td>Vehicles</td>
                    <td>{{ vehicle.item || ' - '}}</td>
                    <td>{{ vehicle.quantity || ' - '}}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="_containers.length > 0">
              <ng-container *ngFor="let item of _containers">
                <ng-container *ngIf="!item.isDeleted">
                  <tr>
                    <td>Container</td>
                    <td>{{ item.item || ' - '}}</td>
                    <td>{{ item.quantity || ' - '}}</td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
        </table> -->
      &nbsp;
      <h4> Shipment</h4>
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th>Shipment</th>
            <th>Volume ({{_volumeUnit}})</th>
            <th>Weight ({{_weightUnit}})</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ enumMappingService.mapTransportType(_leadBoardItem.moveTransport || 0) }}</td>
            <td>{{calculateVolume('', _volumeUnit, 'total')}}</td>
            <td>{{calculateWeight('', _weightUnit, 'total')}}</td>
          </tr>
        </tbody>
      </table>

      &nbsp;
      <h4> Singatures </h4>
      <div class="row mt-4">
        <div class="col-md-6">
          <p><strong>Surveyed by:</strong></p>
          <p>Survey Manager</p>
          <div class="sign-containers">
            <img [src]="_moveSurvey.managerSignature" class="img-fluid">
          </div>
        </div>
        <div class="col-md-6">
          <p><strong>Client Name:</strong></p>
          <p>{{ _leadBoardItem.customer || ' - ' }}</p>
          <div class="sign-containers">
            <img [src]="_moveSurvey.customerSignature" class="img-fluid">
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>