<div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
    <div>
        <h4 class="mb-3 mb-md-0">Welcome {{ getNameFromLocalStorage() }}</h4>
    </div>
    <div class="d-flex align-items-center flex-wrap text-nowrap">

    </div>
</div>


<ng-container *ngIf="_isLoading">
    <span id="loader">
        <span class="spinner"></span>
    </span>
</ng-container>

<ng-container *ngIf="!_isLoading">
    <div class="row" *ngIf="isDataLoaded">
        <div class="col-12 col-xl-12 stretch-card">
            <div class="row flex-grow-1">
                <div class="col-md-3 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Total Revenue</h6>

                            </div>
                            <div class="row" *ngIf="_customerStats && _customerStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_customerStats[0].totalCustomers}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_customerStats[0].changeOverPreviousWeek}}</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="customersChartOptions.series"
                                            [chart]="customersChartOptions.chart"
                                            [colors]="customersChartOptions.colors"
                                            [xaxis]="customersChartOptions.xaxis"
                                            [stroke]="customersChartOptions.stroke"
                                            [markers]="customersChartOptions.markers"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Total Cost</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="ordersChartOptions.series"
                                            [chart]="ordersChartOptions.chart" [colors]="ordersChartOptions.colors"
                                            [plotOptions]="ordersChartOptions.plotOptions"
                                            [xaxis]="ordersChartOptions.xaxis"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Monthly Active User</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="ordersChartOptions.series"
                                            [chart]="ordersChartOptions.chart" [colors]="ordersChartOptions.colors"
                                            [plotOptions]="ordersChartOptions.plotOptions"
                                            [xaxis]="ordersChartOptions.xaxis"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Monthly Active User</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">
                                    <div class="mt-md-3 mt-xl-0">
                                        <apx-chart [series]="ordersChartOptions.series"
                                            [chart]="ordersChartOptions.chart" [colors]="ordersChartOptions.colors"
                                            [plotOptions]="ordersChartOptions.plotOptions"
                                            [xaxis]="ordersChartOptions.xaxis"></apx-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div> <!-- row -->



    <div class="row">
        <div class="col-lg-7 col-xl-8 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title mb-0">Monthly Deals</h6>
                        <div ngbDropdown class="mb-2">

                        </div>
                    </div>
                    <p class="text-muted">Deals are activities related to selling or the number of goods or services
                        sold in a
                        given time period.</p>


                    <div class="row mt-1">
                        <div class="col-xl-6 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h6 class="card-title">LEADS</h6>

                                    <apx-chart [series]="donutChartOptionsLeads.series"
                                        [chart]="donutChartOptionsLeads.chart" [colors]="donutChartOptionsLeads.colors"
                                        [stroke]="donutChartOptionsLeads.stroke"
                                        [legend]="donutChartOptionsLeads.legend"
                                        [dataLabels]="donutChartOptionsLeads.dataLabels"
                                        [labels]="donutChartOptionsLeads.labels"></apx-chart>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-body text-center">
                                    <h6 class="card-title">MOVES</h6>

                                    <apx-chart [series]="donutChartOptionsMoves.series"
                                        [chart]="donutChartOptionsMoves.chart" [colors]="donutChartOptionsMoves.colors"
                                        [stroke]="donutChartOptionsMoves.stroke"
                                        [legend]="donutChartOptionsMoves.legend"
                                        [dataLabels]="donutChartOptionsMoves.dataLabels"
                                        [labels]="donutChartOptionsMoves.labels"></apx-chart>

                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
        <div class="col-lg-5 col-xl-4 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">

                        <div ngbDropdown class="mb-2">


                        </div>
                    </div>
                    <!--*ngIf="_tasksList.length === 0"-->
                    <div class="col-xl-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <h6 class="card-title">User By Location</h6>
                                <apx-chart [series]="pieChartOptions1.series" [chart]="pieChartOptions1.chart"
                                    [labels]="pieChartOptions1.labels" [colors]="pieChartOptions1.colors"
                                    [stroke]="pieChartOptions1.stroke" [legend]="pieChartOptions1.legend"
                                    [dataLabels]="pieChartOptions1.dataLabels"></apx-chart>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="_tasksList && _tasksList.length > 0">

                        <ng-container *ngFor="let dataItem of _tasksList">
                            <a href="" (click)="false" class="d-flex align-items-center border-bottom py-3">
                                <div class="me-3">
                                    <div class="title-icon img-xs rounded-circle">{{ dataItem.title.charAt(0) }}</div>
                                    <!--<img src="https://via.placeholder.com/35x35" class="rounded-circle wd-35" alt="user">-->
                                </div>
                                <div class="w-100">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="text-body mb-2">{{ dataItem.title }}</h6>
                                        <p class="tx-11 text-muted">{{dataItem.dueDate | date:'MMM d, yyyy \'at\' h:mm
                                            a' }}</p>
                                        <!--<p class="text-muted tx-12">{{ dataItem.dealCount }} Deals</p>-->
                                    </div>
                                    <!-- <p class="text-muted tx-13">Additional information or message here...</p>-->
                                </div>
                            </a>
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
    </div> <!-- row -->
    <div class="row">
        <div class="col-xl-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="card-title">Total User</h6>

                    <apx-chart [series]="lineChartOptions.series" [chart]="lineChartOptions.chart"
                        [colors]="lineChartOptions.colors" [grid]="lineChartOptions.grid"
                        [xaxis]="lineChartOptions.xaxis" [yaxis]="lineChartOptions.yaxis"
                        [markers]="lineChartOptions.markers" [legend]="lineChartOptions.legend"
                        [stroke]="lineChartOptions.stroke"></apx-chart>

                </div>
            </div>
        </div>
        <div class="col-xl-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="card-title">User Types</h6>
                    <apx-chart [series]="pieChartOptions2.series" [chart]="pieChartOptions2.chart"
                        [labels]="pieChartOptions2.labels" [colors]="pieChartOptions2.colors"
                        [stroke]="pieChartOptions2.stroke" [legend]="pieChartOptions2.legend"
                        [dataLabels]="pieChartOptions2.dataLabels"></apx-chart>
                </div>
            </div>
        </div>

    </div>
    <div class="row" *ngIf="isDataLoaded">
        <div class="col-12 col-xl-12 stretch-card">
            <div class="row flex-grow-1">
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Total Cases</h6>

                            </div>
                            <div class="row" *ngIf="_customerStats && _customerStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_customerStats[0].totalCustomers}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_customerStats[0].changeOverPreviousWeek}}</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Resulation </h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Escalation</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12 col-xl-7">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">SLA Compliant</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Avg. Resolve Time</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-baseline">
                                <h6 class="card-title mb-0">Avg.CSAT</h6>

                            </div>
                            <div class="row" *ngIf="_dealsStats && _dealsStats.length > 0">
                                <div class="col-6 col-md-12 col-xl-5">
                                    <h3 class="mb-2">{{_dealsStats[0].totalDeals}}</h3>
                                    <div class="d-flex align-items-baseline">
                                        <p class="text-success">
                                            <span>{{_dealsStats[0].changeOverPreviousWeekFormatted}}%</span>
                                            <i data-feather="arrow-up" appFeatherIcon class="icon-sm mb-1"></i>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div> <!-- row -->

    <div class="row">
        <!-- Table Col 6 -->
        <div class="card-body text-center col-md-6">
            <h3>Top Issues</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Topic</th>
                        <th scope="col">Volume</th>
                        <th scope="col">Total Cases</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Sample Topic</td>
                        <td>Sample Volume</td>
                        <td>Sample Total Cases</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Box Col 6 -->
        <div class="col-xl-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="card-title">Case Channel</h6>
                    <apx-chart [series]="barChartOptions.series" [chart]="barChartOptions.chart"
                        [colors]="barChartOptions.colors" [grid]="barChartOptions.grid" [xaxis]="barChartOptions.xaxis"
                        [yaxis]="barChartOptions.yaxis" [plotOptions]="barChartOptions.plotOptions"></apx-chart>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body text-center">
                    <h6 class="card-title">Case Tracking</h6>

                    <apx-chart [series]="areaChartOptions.series" [chart]="areaChartOptions.chart"
                        [colors]="areaChartOptions.colors" [stroke]="areaChartOptions.stroke"
                        [dataLabels]="areaChartOptions.dataLabels" [xaxis]="areaChartOptions.xaxis"
                        [yaxis]="areaChartOptions.yaxis" [grid]="areaChartOptions.grid"
                        [tooltip]="areaChartOptions.tooltip" [fill]="areaChartOptions.fill"
                        [legend]="areaChartOptions.legend"></apx-chart>

                </div>
            </div>
        </div>
        <div class="col-xl-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body text-center">
                    <h6>Case Priority</h6>
                    <apx-chart [series]="radialBarChartOptions.series" [chart]="radialBarChartOptions.chart"
                        [colors]="radialBarChartOptions.colors" [grid]="radialBarChartOptions.grid"
                        [plotOptions]="radialBarChartOptions.plotOptions" [labels]="radialBarChartOptions.labels"
                        [legend]="radialBarChartOptions.legend"></apx-chart>
                </div>
            </div>
        </div>

    </div>


</ng-container>