import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-summary-preview',
  templateUrl: './lead-summary-preview.component.html',
  styleUrls: ['./lead-summary-preview.component.scss']
})

export class LeadSummaryPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
