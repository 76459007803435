import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EmailTemplate } from '../models/email-template.model';
import { GeneralService } from 'src/services/general.service';
import { MovdService } from '../services/movd.service';
import { AppComponent } from 'src/app/app.component';
import { eEmailType } from '../../auth/auth.models';

@Component({
  selector: 'app-generate-email',
  templateUrl: './generate-email.component.html',
  styleUrls: ['./generate-email.component.scss']
})
export class GenerateEmailComponent implements OnInit {
  @Input() pdfData: Blob = new Blob([], { type: 'application/pdf' });
  @Input() templateType: any;
  @Input() emailType: eEmailType = eEmailType.Lead;
  @Input() refId: any;
  @Input() shipperEmail: any;
  @Input() trackingUrl: any;
  @Input() dictionary: { [key: string]: string } = {};
  @Output() emailSent = new EventEmitter<boolean>();
  _title = "Generate Email";
  _temp = new EmailTemplate();
  _tempTitle: string = '';
  _tempSubject!: string | null;
  _tempBody!: string | null;
  tempExists: boolean = false;
  _selectedTemplate = "Client Docs Template";
  _templateData = new EmailTemplate;
  tempTableData: any[] = [];
  _tempDataList: any[] = [];
  _tempTableColumns: any[] = [];
  _searchTempItems: string = '';
  _recipients: string = '';
  constructor(public generalService: GeneralService, private _movdService: MovdService, private _appComponent: AppComponent,) { }

  ngOnInit(): void {

    // console.log('blob data', this.pdfData);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['templateType'] || changes['dictionary'] || changes['emailType'] || changes['refId']) {
      if (changes['templateType']) {
        this.templateType = changes['templateType'].currentValue;
      }

      if (changes['dictionary']) {
        this.dictionary = changes['dictionary'].currentValue;
      }
      if (changes['emailType']) {
        this.emailType = changes['emailType'].currentValue;
      }
      if (changes['refId']) {
        this.refId = changes['refId'].currentValue;
      }
      if (changes['trackingUrl']) {
        this.trackingUrl = changes['trackingUrl'].currentValue;
      }
      if (changes['shipperEmail']) {
        this.shipperEmail = changes['shipperEmail'].currentValue;
        if (this.shipperEmail) {
          this._recipients = this.shipperEmail;
        }
      }
      this.refreshTempTableData();
    }
  }
  refreshTempTableData() {
    const ownedBy = Number(localStorage.getItem('companyId'));
    this._movdService.getEmailTemplate(ownedBy, this.templateType, true, this.dictionary).subscribe({
      next: (data: any) => {
        // console.log("EmailTemplates", data);

        this._tempTitle = '';
        this._tempSubject = null;
        this._tempBody = null;
        if (data) {
          this._templateData = data;
          this._tempTitle = this._templateData.title;
          this._tempSubject = this._templateData.subject;
          this._tempBody = this._templateData.body;
        }
        else {
          this._templateData = new EmailTemplate;
        }
      },
      error: (error: any) => {
        console.error('Error getting email templates', error);
      }
    });
  }
  sendEmail() {
    this._templateData = new EmailTemplate;
    this._templateData.title = this._tempTitle;
    this._templateData.subject = this._tempSubject;
    this._templateData.body = this._tempBody;
    this._templateData.isActive = true;
    // console.log('blob pdf', this.pdfData);

    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        console.error('Error: FileReader result is an ArrayBuffer.');
        return;
      }

      let base64String = reader.result?.split(',')[1]; // Extracting base64 string from result
      if (!base64String) {
        base64String = '';
      }

      // Sending email with base64 string
      this._movdService.sendEmail(this._templateData, this._recipients, base64String, this.emailType, this.refId).subscribe({
        next: (response) => {
          // console.log('Settings updated successfully:', response);
          this._appComponent.showSuccessSwal('Email', 'Send');
          this.emailSent.emit(true);
        },
        error: (error) => {
          this._appComponent.showErrorSwal('Email', 'Send');
          console.error('Error updating settings:', error);
        }
      });
    };

    reader.readAsDataURL(this.pdfData);
  }

}
