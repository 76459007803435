import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MoveStatus, StatusWithMoveStatus } from '../models/move-status.model';
import { MovdService } from '../services/movd.service';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Console, log } from 'console';
import { Status, eStatusType } from '../models/status.model';
import { AppComponent } from 'src/app/app.component';
import { LeadDetail } from '../models/lead-detail.model';
import { MoveDetail } from '../models/move-detail.model';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-moves-status',
  templateUrl: './moves-status.component.html',
  styleUrls: ['./moves-status.component.scss']
})
export class MovesStatusComponent implements OnChanges {
  @Input() refId: any;  // as it can be leadDetailId and moveDetailId as well
  @Input() moveCategory: any;
  @Input() moveTransport: any;
  @Input() version: any;
  @Input() statusType: any;
  @Input() contentDisabled = false;
  @Output() allStatusesDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('datePickerModal') datePickerModal: any;

  currentUserId = 0;

  _isDataLoaded = false;
  isDateSelected: boolean = false;
  _statusDate: NgbDateStruct;
  _statusData: StatusWithMoveStatus[] = [];
  selectedStatusId: number | null = null; // Added property to store selected status ID
  selectedDate: NgbDateStruct | null = null; // Added property to store selected date
  selectedStatus: StatusWithMoveStatus | null = null;
  _statusToSave: boolean = false;

  _toBeUpdateStatus: MoveStatus = new MoveStatus();
  _leadDetail = new LeadDetail();
  _moveDetail = new MoveDetail();

  // role base permission
  _createPermission = false;
  _updatePermission = false;
  _readPermission = false;
  _deletePermission = false;
  _userRole: string | null = null;

  constructor(
    private _movdService: MovdService,
    private calendar: NgbCalendar,
    private _modalService: NgbModal,
    private _appComponent: AppComponent,
    private _authService: AuthService
  ) {
    this.currentUserId = Number(localStorage.getItem('currentUserId'));
    this._statusDate = calendar.getToday();
    this._userRole = localStorage.getItem('role');

  }

  // ngOnInit(): void {
  //   this.getLeadSatatus(this._statusType, this._category);
  // }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['refId'] || changes['moveCategory'] || changes['version'] || changes['moveTransport'] || changes['contentDisabled']) {



      if (changes['refId']) {
        this.refId = changes['refId'].currentValue;
      }

      if (changes['moveCategory']) {

        this.moveCategory = changes['moveCategory'].currentValue;
      }

      if (changes['moveTransport']) {
        this.moveTransport = changes['moveTransport'].currentValue;
      }

      if (changes['contentDisabled']) {
        this.contentDisabled = changes['contentDisabled'].currentValue;
      }

      // if (changes['version']) {
      //   this.moveDetailId = changes['moveDetailId'].currentValue;
      // }
      // console.log("status type", this.statusType);
      const permissions = this._authService.getPermissionsFromLocalStorage();

      if (this.statusType === eStatusType.Lead) {
        // console.log('status if', this.statusType);

        // Set permission variables
        this._readPermission = permissions.includes('Lead Status & Requirement.Read') || this._userRole === 'CompanyAdmin';
        this._createPermission = permissions.includes('Lead Status & Requirement.Create') || this._userRole === 'CompanyAdmin';
        this._updatePermission = permissions.includes('Lead Status & Requirement.Update') || this._userRole === 'CompanyAdmin';
        this._deletePermission = permissions.includes('Lead Status & Requirement.Delete') || this._userRole === 'CompanyAdmin';
      }
      else if (this.statusType === eStatusType.Move) {
        // console.log('status if else', this.statusType);
        this._readPermission = permissions.includes('Move Internal Status.Read') || this._userRole === 'CompanyAdmin';
        this._createPermission = permissions.includes('Move Internal Status.Create') || this._userRole === 'CompanyAdmin';
        this._updatePermission = permissions.includes('Move Internal Status.Update') || this._userRole === 'CompanyAdmin';
        this._deletePermission = permissions.includes('Move Internal Status.Delete');
      }
      // console.log("statustype", this.statusType);
      // console.log("moveCategory", this.moveCategory);
      // console.log("moveTransport", this.moveTransport);
      // if (this.statusType && this.moveCategory && this.moveTransport) {
      // console.log('leadDetailId and moveCategory', this.leadDetailId, this.moveCategory);
      this.getLeadSatatus(this.statusType, this.moveCategory, this.moveTransport);
      // }
    }
  }

  formatDate(date: any): string {
    return date
      ? `${date.day.toString().padStart(2, '0')}/${date.month.toString().padStart(2, '0')}/${date.year}`
      : '';
  }

  // for updating status
  formatDate1(): string {
    return this.selectedDate
      ? `${this.selectedDate.day.toString().padStart(2, '0')}/${this.selectedDate.month.toString().padStart(2, '0')}/${this.selectedDate.year}`
      : '';
  }

  updateStatus(status: StatusWithMoveStatus) {
    this.selectedStatus = status;
    // console.log("update status function", status);
    // Disable checkbox here
    // status.isSaving = true;
    if (status.moveStatusDone === true) {
      this.isDateSelected = true;
      status.moveStatusDone = false;
      status.moveStatusDate = null;
      status.isDeleted = true;
      // status.showDatePicker = true;
      this._statusToSave = false;
      this.saveDateAndStatus(status);
    } else {
      status.showDatePicker = true;
      this._statusToSave = true;
      this.openDatePickerModal(this.datePickerModal, status);
    }

  }

  updateSaveButtonState() {
    this.isDateSelected = this._statusData.some(status => status.moveStatusDone);
  }



  saveDateAndStatus(status: StatusWithMoveStatus | null) {
    if (!status || !this.isDateSelected) {
      return;
    }

    const moveStatus: MoveStatus = {
      id: status.moveStatusId,
      refId: this.refId,
      statusId: status.statusId,
      date: status.moveStatusDate,
      done: this._statusToSave,
      category: this.moveCategory,
      transportType: this.moveTransport,
      createdBy: this.currentUserId,
      isDeleted: status.isDeleted
    };

    // console.log('before saving', moveStatus);
    this._movdService.saveMoveStatus(moveStatus).subscribe({
      next: (response: any) => {
        // console.log('update status response', response);
        this.getLeadSatatus(this.statusType, this.moveCategory, this.moveTransport);
        this._appComponent.showSuccessSwal('updated', 'status')
        status.isSaving = false;
        status.moveStatusDone = this._statusToSave;
        status.showDatePicker = false;
      },
      error: (error: any) => {
        console.error('Error updating status', error);
        // Re-enable checkbox here in case of error
        status.isSaving = false;
      }
    });
  }

  openDatePickerModal(modal: TemplateRef<any>, status: StatusWithMoveStatus) {
    this.resetDateSelection(); // Reset the variable
    this.selectedStatus = status;
    this._modalService.open(modal);
  }


  onDateSelectStatus(event: any) {
    if (this.selectedStatus) {
      this.selectedStatus.moveStatusDate = this.convertNgbDateToDate(event);
      this.isDateSelected = true; // Set isDateSelected to true when a date is selected
    }
  }
  resetDateSelection() {
    this.isDateSelected = false;
  }


  convertNgbDateToDate(ngbDate: any): Date | null {
    if (ngbDate !== null) {
      const utcDate = new Date(Date.UTC(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day
      ));
      return utcDate;
    } else {
      return null;
    }
  }

  cancelClick(modal: TemplateRef<any>) {
    this._modalService.dismissAll();
    this.isDateSelected = false; // Set isDateSelected to false when the modal is closed
    this.getLeadSatatus(this.statusType, this.moveCategory, this.moveTransport);
  }

  getLeadSatatus(statusType: number, category: number, transportType: number): void {
    this._statusData = [];
    this._isDataLoaded = false;
    this._movdService.getLeadStatus(this.refId, statusType, category, transportType).subscribe({
      next: (data: any) => {
        // console.log("get lead status service called from move status", data);
        if (data) {
          // this.statusData = data.filter((item: { moveDetailId: number; }) => item.moveDetailId === this.moveDetailId);
          // console.log("status data");

          this.filterStatuses(statusType, data);
          // console.log("lead lost status ", leadLostStatus)
          // console.log("lead won status ", leadWonStatus)
          // console.log("lead other status ", otherStatuses)          

          this.areAllStatusesDone();
        }
        this._isDataLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  areAllStatusesDone(): boolean {
    // console.log("are all statues done", this._statusData);
    let allDone = this._statusData.every(status => status.moveStatusDone);
    this.allStatusesDone.emit(allDone); // Emit the value
    return allDone;
  }

  filterStatuses(statusType: eStatusType, statusList: any) {
    let statusData = statusList.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);

    if (statusType === eStatusType.Lead) {
      let leadWonTitle = 'Lead Won';
      let leadLostTitle = 'Lead Lost';

      let leadWonStatus = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === leadWonTitle.toLowerCase());
      let leadLostStatus = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === leadLostTitle.toLowerCase());

      let otherStatuses = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() !== leadWonTitle.toLowerCase() && item.statusTitle.toLowerCase() !== leadLostTitle.toLowerCase());

      if (leadWonStatus[0] && leadWonStatus[0].moveStatusDone === true) {
        this._statusData = [...otherStatuses, ...leadWonStatus];
      } else if (leadLostStatus[0] && leadLostStatus[0].moveStatusDone === true) {
        this._statusData = [...otherStatuses, ...leadLostStatus];
      } else {
        this._statusData = [...otherStatuses];
      }
    } else if (statusType === eStatusType.Move) {
      let completedMoveStatusTitle = 'Move Completed';
      let cancelledMoveStatusTitle = 'Move Cancelled';
      let revertedMoveStatusTitle = 'Move Reverted';

      let completedMoveStatus = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === completedMoveStatusTitle.toLowerCase());
      let cancelledMoveStatus = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === cancelledMoveStatusTitle.toLowerCase());
      let revertedMoveStatus = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() === revertedMoveStatusTitle.toLowerCase());

      let otherStatuses = statusData.filter((item: StatusWithMoveStatus) => item.statusTitle.toLowerCase() !== completedMoveStatusTitle.toLowerCase() && item.statusTitle.toLowerCase() !== cancelledMoveStatusTitle.toLowerCase() && item.statusTitle.toLowerCase() !== revertedMoveStatusTitle.toLowerCase());

      if (completedMoveStatus[0] && completedMoveStatus[0].moveStatusDone === true) {
        this._statusData = [...otherStatuses, ...completedMoveStatus];
      } else if (cancelledMoveStatus[0] && cancelledMoveStatus[0].moveStatusDone === true) {
        this._statusData = [...otherStatuses, ...cancelledMoveStatus];
      } else if (revertedMoveStatus[0] && revertedMoveStatus[0].moveStatusDone === true) {
        this._statusData = [...otherStatuses, ...revertedMoveStatus];
      } else {
        this._statusData = [...otherStatuses];
      }
    }
  }
}
