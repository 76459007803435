<div class="loader-overlay" *ngIf="_isLoading === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div>

<ng-template #initiateMoveModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Initiate Move</h4>
  </div>
  <div class="modal-body">
    <h6>Please select the Lead(s) you would like to initiate:</h6>

    <div class="row" *ngIf="getActiveLeadsCount() > 1">
      <div class="col-1">
        <input type="checkbox" [(ngModel)]="_selectAll" (change)="onSelectAllChange()"
          class="form-check-input" />
      </div>
      <div class="col">
        Select All
      </div>
    </div>

    <div class="mt-2" *ngFor="let lead of _leadBoardDTOList">
      <div *ngIf="lead.leadId === _leadId && !lead.markLost" class="row">
        <div class="col-1">
          <input type="checkbox" [(ngModel)]="lead.selected" class="form-check-input"
            [checked]="lead.moveInitiated" [disabled]="lead.moveInitiated === true" />
        </div>
        <div class="col">
          {{ lead.formattedLeadDetailId || '-'}} -
          {{ lead.moveTypeLabel || '-'}} -
          {{ lead.moveTransportLabel || '-' }}
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" (click)="initiateMove()">Initiate <i
        class="feather icon-arrow-up-right"></i></button>
    <button type="button" class="btn btn-danger btn-sm" (click)="modal.dismiss('Cancel click')"><i
        class="mdi mdi-close"></i>&nbsp; Cancel</button>
  </div>
</ng-template>
<div class="loader-overlay" *ngIf="isLoading === true">
  <div id="loader">
    <div class="spinner"></div>
  </div>
</div>

<ng-template #markLostModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Mark Lost</h4>
  </div>
  <div class="modal-body">
    <!-- Add your modal content here -->

    <h6>Please select the Lead(s) you would like to mark as Lost:</h6>

    <div *ngFor="let lead of _leadBoardDTOList" class="mt-2">
      <div *ngIf="lead.leadId === _leadId && !lead.moveInitiated" class="row">
        <div class="col-1 mt-2">
          <input type="checkbox" class="form-check-input" [checked]="lead.markLost"
            [(ngModel)]="lead.markLost" />
        </div>
        <div class="col mt-2">
          {{ lead.formattedLeadDetailId || '-'}} -
          {{ lead.moveTypeLabel || '-'}} -
          {{ lead.moveTransportLabel || '-' }}
        </div>
        <div class="mb-3 mt-3">
          <label for="reasonTextarea" class="form-label">Reason (optional):</label>
          <textarea class="form-control" id="reasonTextarea" [(ngModel)]="lead.lostReason"
            placeholder="Enter reason"></textarea>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm"
      (click)="markLeadsLost(_leadBoardDTOList)">Mark Lost <i
        class="feather icon-slash"></i></button>
    <button type="button" class="btn btn-danger  btn-sm" (click)="modal.dismiss('Cancel click')"><i
        class="mdi mdi-close"></i>&nbsp; Cancel</button>
  </div>
</ng-template>

<div *ngIf="_isDataLoaded">
  <div class="row title-and-mutliple-moves-btn">
    <div class="col-md-4">
      <h5>LEAD INFORMATION FORM</h5>
    </div>
    <div class="col-md-8">
      <ng-container *ngIf="_createPermission">
        <button class="plus-button float-end" (click)="createNewLeadDetail()"
          *ngIf="!(_leadBoardDTOList.length >= 5)">
          <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
            colors="primary:#000000,secondary:#ebe6ef" style="width: 60px; height: 60px">
          </lord-icon>
        </button>
      </ng-container>
      &nbsp;
      <ng-container *ngFor="let lead of _leadBoardDTOList">
        <ng-container *ngIf="lead.leadId === _leadId">
          <button class="muliple-moves-btn float-end" (click)="showMoveById(lead.leadDetailId)"
            [class.active]="lead.leadDetailId === _leadDetail.id"
            ngbPopover="{{generalService.formatId(lead.leadDetailId, 'lead')}}" triggers="hover"
            placement="top">

            {{ lead.moveTypeLabel || '-'}} -
            {{ lead.moveTransportLabel || '-' }}
            <i *ngIf="lead.moveTransport === 1" class="mdi mdi-airplane-takeoff"></i>
            <i *ngIf="lead.moveTransport === 2" class="mdi mdi-truck-delivery"></i>
            <i *ngIf="lead.moveTransport === 3" class="mdi mdi-ferry"></i>
            <i *ngIf="lead.moveTransport === 4" class="mdi mdi-ferry"></i>
          </button>
          &nbsp;
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-md-12 order-md-1">
      <div class="box-left border border-dark-grey p-3 custom-rounded">
        <div class="row">
          <app-lead-info-header [type]="_leadDetailType" [assignmentType]="_assignmentType"
            [assignmentRefId]="_leadDetail.id" [refId]="_leadDetail.id"></app-lead-info-header>
        </div>
      </div>

      <!-- Moves Info Form -->
      <div class="row">
        <div class="col-md-9 order-md-1 mt-3">
          <ul ngbNav #defaultNav="ngbNav" class="nav-tabs" [(activeId)]="defaultNavActiveId">
            <li [ngbNavItem]="1">
              <a ngbNavLink (click)="onChangeTab(1)" class="container">
                <span class="text">INFO</span>
              </a>

              <ng-template ngbNavContent>
                <!--<h3>Lead Info</h3>-->
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-4">
                        <ng-select [(ngModel)]="_leadDetail.moveType" [items]="_moveType"
                          bindLabel="value" bindValue="id" [searchable]="true"
                          placeholder="Select Move Type" [disabled]="_contentDisable">
                        </ng-select>
                      </div>
                      <div class="col-md-4">
                        <ng-select [(ngModel)]="_leadDetail.categoryType" [items]="_moveCategory"
                          bindLabel="value" bindValue="id" [searchable]="true"
                          placeholder="Select Move Category" (change)="onChangeCategory($event)"
                          [disabled]="_contentDisable">
                        </ng-select>
                      </div>
                      <div class="col-md-4">
                        <ng-select [(ngModel)]="_leadDetail.transportType" [items]="_transportType"
                          bindLabel="value" bindValue="id" [searchable]="true"
                          (change)="onChangeTransport($event)" placeholder="Select Transport Type"
                          [disabled]="_contentDisable">
                        </ng-select>
                      </div>
                    </div>

                    <!-- Origin & Destination -->
                    <div class="mt-4">
                      <a class="mt-3 d-block" (click)="toggleSections()">
                        <div
                          class="d-flex justify-content-between align-items-center address-hover">
                          <h5 class="mr-2">Addresses</h5>
                          <span class="mr-2 arrow-icon">
                            <i
                              [ngClass]="showSections ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
                          </span>
                        </div>
                        <hr class="mt-1 border-dark-grey">
                      </a>
                    </div>

                    <div class="row mt-4">
                      <div class="col-md-5" [ngClass]="{ 'd-none': !showSections }">
                        <h6 class="text-center custom-text-color">ORIGIN</h6>
                        &nbsp;
                        <hr class="flex-grow-1 m-3 border-dark-grey" />

                        <!-- Origin Form detail  -->
                        <ul class="nav nav-tabs">
                          <li *ngFor="
                              let address of originAddresses;
                              let i = index
                            " class="nav-item">
                            <a class="nav-link" [class.active]="i === originActiveTabIndex"
                              (click)="setOriginActiveTab(i)">
                              Address {{ i + 1 }}
                              <ng-container *ngIf="i !== 0">
                                <button class="btn-close btn-close-tab" aria-label="Close"
                                  (click)="removeOriginTab(i)"
                                  [disabled]="_contentDisable"></button>
                              </ng-container>
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          &nbsp;
                          <div *ngFor="let address of originAddresses; let i = index"
                            class="tab-pane" [class.active]="i === originActiveTabIndex">
                            <label for="originAddress" class="form-label fw-bold m-0">
                              Address
                            </label>
                            <input type="text" id="originAddress" class="form-control "
                              placeholder="Appt#, Street" [(ngModel)]="address.streetAddress"
                              [disabled]="_contentDisable" />

                            <div class="row mt-3">
                              <div class="col-md-6">
                                <label for="originCity" class="form-label fw-bold m-0">
                                  City <span class="required-input"> * </span>
                                </label>
                                <input type="text" id="originCity" class="form-control "
                                  placeholder="City" [(ngModel)]="address.city"
                                  [disabled]="_contentDisable" />

                                <label for="originCountry" class="form-label fw-bold m-0 mt-3">
                                  Country <span class="required-input"> * </span>
                                </label>

                                <ng-select [items]="countries" bindLabel="value" bindValue="id"
                                  placeholder="Select Country" [(ngModel)]="address.country"
                                  [disabled]="_contentDisable"></ng-select>
                              </div>

                              <div class="col-md-6">
                                <label for="originProvince" class="form-label fw-bold m-0">
                                  Province
                                </label>
                                <input type="text" id="originProvince" class="form-control "
                                  placeholder="Province" [(ngModel)]="address.state"
                                  [disabled]="_contentDisable" />

                                <label for="originZip" class="form-label fw-bold m-0 mt-3">
                                  Zip Code
                                </label>
                                <input type="text" id="originZip" class="form-control "
                                  placeholder="Zip Code" [(ngModel)]="address.zipCode"
                                  [disabled]="_contentDisable" />
                              </div>
                            </div>
                          </div>
                        </div>
                        &nbsp;
                        <ngb-alert *ngIf="originShowAlert" type="danger"
                          (click)="closeOriginAlert()">
                          You cannot add more than {{ maxAddresses }} origin
                          addresses.
                        </ngb-alert>
                        <ng-container *ngIf="_createPermission">
                          <button class="btn btn-xs btn-primary btn-icon-text mt-4"
                            (click)="addOriginTab()" [disabled]="_contentDisable">
                            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                              colors="primary:#000000,secondary:#ebe6ef"
                              style="width: 20px; height: 20px">
                            </lord-icon>
                            &nbsp; Add Another Origin
                          </button>
                        </ng-container>
                        &nbsp;
                      </div>

                      <div class="col-md-2 p-0 dot-line" [ngClass]="{ 'd-none': !showSections }">
                        <label class="dots-container">
                          <label class="dot"></label>
                          <label class="dot"></label>
                          <label class="dot"></label>
                          <label class="dot"></label>
                          <label class="dot"></label>
                        </label>
                        <label class="vertical-line text-center"></label>
                      </div>

                      <div class="col-md-5" [ngClass]="{ 'd-none': !showSections }">
                        <h6 class="text-center custom-text-color">
                          DESTINATION
                        </h6>
                        &nbsp;
                        <hr class="flex-grow- m-3 border-dark-grey" />

                        <ul class="nav nav-tabs">
                          <li *ngFor="
                              let address of destinationAddresses;
                              let i = index
                            " class="nav-item">
                            <a class="nav-link" [class.active]="i === destActiveTabIndex"
                              (click)="setDestActiveTab(i)">
                              Address {{ i + 1 }}
                              <ng-container *ngIf="i !== 0">
                                <button class="btn-close btn-close-tab" aria-label="Close"
                                  (click)="removeDestTab(i)" [disabled]="_contentDisable"></button>
                              </ng-container>
                            </a>
                          </li>
                        </ul>

                        <div class="tab-content">
                          &nbsp;
                          <div *ngFor="let address of destinationAddresses; let i = index"
                            class="tab-pane" [class.active]="i === destActiveTabIndex">
                            <label for="destinationAddress" class="form-label fw-bold m-0">
                              Address
                            </label>
                            <input type="text" id="destinationAddress" class="form-control "
                              placeholder="Appt#, Street" [(ngModel)]="address.streetAddress"
                              [disabled]="_contentDisable" />

                            <div class="row mt-3">
                              <div class="col-md-6">
                                <label for="destinationCity" class="form-label fw-bold m-0">
                                  City <span class="required-input"> * </span>
                                </label>
                                <input type="text" id="destinationCity" class="form-control "
                                  placeholder="City" [(ngModel)]="address.city"
                                  [disabled]="_contentDisable" />
                                <div *ngIf="destTabErrors" class="text-danger">
                                  City field is required.
                                </div>
                                <label for="originCountry" class="form-label fw-bold m-0 mt-3">
                                  Country <span class="required-input"> * </span>
                                </label>
                                <ng-select [items]="countries" bindLabel="value" bindValue="id"
                                  placeholder="Select Country" [(ngModel)]="address.country"
                                  [searchable]="true" [disabled]="_contentDisable"></ng-select>
                              </div>
                              <div class="col-md-6">
                                <label for="destinationProvince" class="form-label fw-bold m-0">
                                  Province
                                </label>
                                <input type="text" id="destinationProvince" class="form-control "
                                  placeholder="Province" [(ngModel)]="address.state"
                                  [disabled]="_contentDisable" />

                                <label for="destinationZip" class="form-label fw-bold m-0 mt-3">
                                  Zip Code
                                </label>
                                <input type="text" id="destinationZip" class="form-control "
                                  placeholder="Zip Code" [(ngModel)]="address.zipCode"
                                  [disabled]="_contentDisable" />
                              </div>
                            </div>
                          </div>
                        </div>
                        &nbsp;
                        <ngb-alert *ngIf="destShowAlert" type="danger" (click)="closeDestAlert()">
                          You cannot add more than
                          {{ maxAddresses }} destination addresses.
                        </ngb-alert>
                        <ng-container *ngIf="_createPermission">
                          <button class="btn btn-xs btn-primary btn-icon-text mt-4"
                            (click)="addDestTab()" [disabled]="_contentDisable">
                            <lord-icon src="https://cdn.lordicon.com/xzksbhzh.json" trigger="hover"
                              colors="primary:#000000,secondary:#ebe6ef"
                              style="width: 20px; height: 20px">
                            </lord-icon>
                            &nbsp; Add Another Destination
                          </button>
                        </ng-container>
                        &nbsp;
                      </div>

                      <!-- <hr class="mt-4 border-dark-grey" [ngClass]="{ 'd-none': !showSections }" /> -->
                      <div class="mt-2">
                        <a class="mt-3 d-block" (click)="shipmentSection()">
                          <div
                            class="d-flex justify-content-between align-items-center address-hover">
                            <h5 class="mr-2">Shipment information</h5>
                            <span class="mr-2 arrow-icon">
                              <i
                                [ngClass]="shipmentSections ? 'feather icon-chevron-up' : 'feather icon-chevron-down'"></i>
                            </span>
                          </div>
                          <hr class="mt-1 border-dark-grey">
                        </a>
                      </div>
                      <!-- <h5>SHIPMENT INFORMATION</h5> -->
                      <div class="row mt-2">
                        <ng-container
                          *ngIf="_leadDetail.categoryType == eMoveCategoryType.DoorToDoorExport || _leadDetail.categoryType == eMoveCategoryType.DoorToPort  && _leadDetail.transportType == eTransportType.Sea || _leadDetail.transportType == eTransportType.SeaLCL || _leadDetail.transportType == eTransportType.Air">
                          <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                            <label class="form-label fw-bold m-0 mt-3">
                              {{_portLabel}}Port of Loading
                            </label>
                            <input type="text" class="form-control"
                              [(ngModel)]="_leadDetail.portOfLoading"
                              placeholder="Enter Port of Loading" [disabled]="_contentDisable" />
                          </div>
                          <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                            <label class="form-label fw-bold m-0 mt-3">
                              {{_portLabel}}Port of Discharge
                            </label>
                            <input type="text" class="form-control"
                              [(ngModel)]="_leadDetail.portOfDischarge"
                              placeholder="Enter Port of Discharge" [disabled]="_contentDisable" />
                          </div>
                        </ng-container>
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            Place of Delivery
                          </label>
                          <input type="text" class="form-control"
                            [(ngModel)]="_leadDetail.placeOfDelivery"
                            placeholder="Enter Place of Delivery" [disabled]="_contentDisable" />
                        </div>
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            Insurance Required
                          </label>
                          <div class="form-check">
                            <label class="form-check-label" for="insurance-yes">Yes</label>
                            <input type="radio" class="form-check-input"
                              [(ngModel)]="_leadDetail.insuranceRequired" id="insurance-yes"
                              name="insurance" [value]="true" [disabled]="_contentDisable">
                          </div>
                          <div class="form-check">
                            <label class="form-check-label" for="insurance-no">No</label>
                            <input type="radio" class="form-check-input"
                              [(ngModel)]="_leadDetail.insuranceRequired" id="insurance-no"
                              name="insurance" [value]="false" [disabled]="_contentDisable">
                          </div>
                        </div>
                      </div>

                      <hr class="mt-4 border-dark-grey"
                        [ngClass]="{ 'd-none': !shipmentSections }" />
                      <div class="row"
                        *ngIf="_leadDetail.categoryType !== eMoveCategoryType.PortToDoor">
                        <!-- Survey Date  -->
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            &nbsp; Requested Survey Date
                          </label>
                          <div class="input-group">
                            <div class="position-relative">
                              <input class="form-control clickable" name="dp" ngbDatepicker
                                #d="ngbDatepicker"
                                [value]="generalService.formatDate(_leadDetail.surveyDate, null)"
                                (click)="d.toggle()"
                                (dateSelect)="onDateSelect($event, 'SurveyDate')"
                                outsideDays="hidden" placeholder="Select Date" [dayTemplate]="t"
                                [startDate]="surveyDateShow!" readonly
                                [disabled]="_contentDisable" />
                              <button class="btn btn-small cross-btn" type="button"
                                title="Clear Date" *ngIf="_leadDetail.surveyDate"
                                (click)="_leadDetail.surveyDate = null" [hidden]="_contentDisable">
                                <i class="feather icon-x"></i>
                              </button>
                            </div>
                            <button class="input-group-text" type="button" title="date-range"
                              (click)="d.toggle()" [disabled]="_contentDisable">
                              <i class="feather icon-calendar icon-md text-muted"></i>
                            </button>
                            <input type="time" class="form-control input-color"
                              [(ngModel)]="_surveyTime" id="surveyTime" [disabled]="_contentDisable"
                              placeholder="Select time" required />
                          </div>

                          <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="focused"
                              [class.highlight]="isHighlighted(date, 'survey')">
                              {{ date.day }}
                            </span>
                          </ng-template>
                          <input type="checkbox" class="form-check-input mt-2"
                            title="storage-needed" [(ngModel)]="storageNeeded"
                            [disabled]="_contentDisable" />
                          <label class="m-2 text-muted small">Warehouse storage needed</label>
                        </div>

                        <!-- Packing date  -->
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            &nbsp; Requested Packing Date
                          </label>
                          <div class="input-group range-selection">
                            <div class="position-relative">
                              <input type="text" class="form-control clickable"
                                placeholder="Select Date Range" [value]="
                            generalService.formatDate(
                                _leadDetail.packagingDateFrom,
                                _leadDetail.packagingDateTo
                              )
                            " name="datepicker" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event, 'packing')"
                                [displayMonths]="2" [dayTemplate]="daysTemplate"
                                outsideDays="hidden" (click)="datepicker.toggle()"
                                [startDate]="fromDate1!" tabindex="-1"
                                [disabled]="_contentDisable" />
                              <button class="btn btn-small cross-btn" type="button"
                                title="Clear Date" *ngIf="_leadDetail.packagingDateFrom"
                                (click)="_leadDetail.packagingDateFrom = null"
                                [hidden]="_contentDisable">
                                <i class="feather icon-x"></i>
                              </button>
                            </div>
                            <button class="input-group-text" type="button" title="icon-calendar"
                              (click)="datepicker.toggle()"
                              [disabled]="sameDayDelivery || _contentDisable">
                              <i class="feather icon-calendar icon-md text-muted"></i>
                            </button>

                            <input type="time" class="form-control input-color"
                              [(ngModel)]="_packingTime" id="packingTime"
                              [disabled]="_contentDisable" placeholder="Select time" required />


                            <ng-template #daysTemplate let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused"
                                [class.range]="isRange(date, 'packing')" [class.faded]="
                                isHovered(date, 'packing') ||
                                isInside(date, 'packing')
                              " (mouseenter)="hoveredDatePacking = date"
                                (mouseleave)="hoveredDatePacking = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                          <input type="checkbox" class="form-check-input mt-2" title="packing-date"
                            [(ngModel)]="sameDayDelivery" (change)="onSameDayDeliveryChange()"
                            [disabled]="_contentDisable" readonly />
                          <label class="m-2 text-muted small">Same Day Delivery</label>
                        </div>
                      </div>

                      <!-- Loading Date -->
                      <div class="row">
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            &nbsp; Requested Loading Date
                          </label>
                          <div class="input-group range-selection">
                            <div class="position-relative">
                              <input type="text" class="form-control clickable"
                                placeholder="Select Date Range" [value]="
                            generalService.formatDate(
                                _leadDetail.loadingDateFrom,
                                _leadDetail.loadingDateTo
                              )
                            " name="datepicker" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event, 'loading')"
                                [displayMonths]="2" [dayTemplate]="daysTemplate1"
                                outsideDays="hidden" (click)="datepicker.toggle()"
                                [startDate]="fromDate1!" tabindex="-1" readonly
                                [disabled]="sameDayDelivery || _contentDisable" />
                              <button class="btn btn-small cross-btn" type="button"
                                title="Clear Date" *ngIf="_leadDetail.loadingDateFrom"
                                (click)="_leadDetail.loadingDateFrom = null"
                                [hidden]="_contentDisable">
                                <i class="feather icon-x"></i>
                              </button>
                            </div>
                            <button class="input-group-text" type="button" title="date-range"
                              (click)="datepicker.toggle()"
                              [disabled]="sameDayDelivery || _contentDisable">
                              <i class="feather icon-calendar icon-md text-muted"></i>
                            </button>

                            <input type="time" class="form-control input-color"
                              [(ngModel)]="_loadingTime" id="surveyTime"
                              [disabled]="_contentDisable || sameDayDelivery"
                              placeholder="Select time" required />
                            <ng-template #daysTemplate1 let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused"
                                [class.range]="isRange(date, 'loading')" [class.faded]="
                                isHovered(date, 'loading') ||
                                isInside(date, 'loading')
                              " (mouseenter)="hoveredDateLoading = date"
                                (mouseleave)="hoveredDateLoading = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>

                        <!-- Delivery Date -->
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            &nbsp; Requested Delivery Date
                          </label>
                          <div class="input-group">
                            <div class="position-relative">
                              <input class="form-control clickable" name="dp" ngbDatepicker
                                #dt="ngbDatepicker" title="date-range" [value]="
                              generalService.formatDate(_leadDetail.deliveryDate, null)
                            " (click)="dt.open()" (dateSelect)="
                              onDateSelect($event, 'DeliveryDate'); dt.close()
                            " outsideDays="hidden" placeholder="Select Date" [dayTemplate]="d"
                                [startDate]="deliveryDateShow!"
                                [disabled]="sameDayDelivery || _contentDisable" readonly />
                              <button class="btn btn-small cross-btn" type="button"
                                title="Clear Date" *ngIf="_leadDetail.deliveryDate"
                                (click)="_leadDetail.deliveryDate = null"
                                [hidden]="_contentDisable">
                                <i class="feather icon-x"></i>
                              </button>
                            </div>
                            <button class="input-group-text" type="button" (click)="dt.open()"
                              title="date-range" [disabled]="sameDayDelivery || _contentDisable">
                              <i class="feather icon-calendar icon-md text-muted"></i>
                            </button>
                            <input type="time" class="form-control input-color"
                              [(ngModel)]="_deliveryTime" id="deliveryTime"
                              [disabled]="_contentDisable || sameDayDelivery"
                              placeholder="Select time" required />
                            <ng-template #d let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused"
                                [class.highlight]="isHighlighted(date, 'delivery')">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </div>

                      <!-- Storage Date -->
                      <hr [ngClass]="{ 'd-none': !shipmentSections }" class="mt-3 border-dark-grey"
                        *ngIf="storageNeeded" />
                      <div class="row" *ngIf="storageNeeded">
                        <div class="col-md-6" [ngClass]="{ 'd-none': !shipmentSections }">
                          <label class="form-label fw-bold m-0 mt-3">
                            &nbsp; Requested Storage Date
                          </label>
                          <div class="input-group range-selection">
                            <div class="position-relative">
                              <input type="text" class="form-control clickable"
                                placeholder="Select Date Range" [value]="
                            generalService.formatDate(
                                _leadDetail.storageDateFrom,
                                _leadDetail.storageDateTo
                              )
                            " name="datepicker" ngbDatepicker #datepicker="ngbDatepicker"
                                [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event, 'storage')"
                                [displayMonths]="2" [dayTemplate]="daysTemplate2"
                                outsideDays="hidden" (click)="datepicker.toggle()"
                                [startDate]="fromDate2!" tabindex="-1" readonly
                                [disabled]="_contentDisable" />
                              <button class="btn btn-small cross-btn" type="button"
                                title="Clear Date" *ngIf="_leadDetail.storageDateFrom"
                                (click)="_leadDetail.storageDateFrom = null"
                                [hidden]="_contentDisable">
                                <i class="feather icon-x"></i>
                              </button>
                            </div>
                            <button class="input-group-text" type="button" title="date-range"
                              (click)="datepicker.toggle()" [disabled]="_contentDisable">
                              <i class="feather icon-calendar icon-md text-muted"></i>
                            </button>

                            <input type="time" class="form-control input-color"
                              [(ngModel)]="_storageTime" id="surveyTime"
                              [disabled]="_contentDisable" placeholder="Select time" required />


                            <ng-template #daysTemplate2 let-date let-focused="focused">
                              <span class="custom-day" [class.focused]="focused"
                                [class.range]="isRange(date, 'storage')" [class.faded]="
                                isHovered(date, 'storage') ||
                                isInside(date, 'storage')
                              " (mouseenter)="hoveredDateStorage = date"
                                (mouseleave)="hoveredDateStorage = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label fw-bold m-0 mt-3">
                              &nbsp; Additional Notes
                            </label>
                            <textarea class="form-control " rows="5"
                              [(ngModel)]="_leadDetail.additionalNotes"
                              placeholder="Write Additional notes for uniques items like arts, etc."
                              [disabled]="_contentDisable"></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-end mt-4">
                        <ng-container *ngIf="_createPermission">
                          <button class="btn btn-sm btn-primary submit-info"
                            (click)="saveLeadDetail()" [disabled]="_contentDisable">
                            <i class="feather icon-save"></i> &nbsp;SAVE
                          </button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <ng-container *ngIf="_leadDetail.categoryType !== eMoveCategoryType.PortToDoor">
              <li [ngbNavItem]="2">
                <a ngbNavLink (click)="onChangeTab(2)" class="container">
                  <span class="text">SURVEY</span>
                </a>
                <ng-template ngbNavContent>
                  <app-lead-survey [leadDetailId]="_leadDetail.id" [leadId]="_leadId"
                    [disabled]="_contentDisable"></app-lead-survey>
                </ng-template>
              </li>
            </ng-container>

            <li [ngbNavItem]="3">
              <a ngbNavLink (click)="onChangeTab(3)" class="container">
                <span class="text">QUOTATION</span>
              </a>
              <ng-template ngbNavContent>
                <app-lead-quotation [leadDetailId]="_leadDetail.id" [leadId]="_leadId"
                  [disabled]="_contentDisable" [clientName]="_clientName"
                  [clientsCompany]="_clientsCompany"></app-lead-quotation>
              </ng-template>
            </li>
            <li [ngbNavItem]="4" *ngIf="_messagesReadPermission">
              <a ngbNavLink (click)="onChangeTab(3)" class="container">
                <span class="text">MESSAGES</span>
              </a>
              <ng-template ngbNavContent>
                <app-communications [leadDetailId]="_leadDetail.id" [disabled]="_contentDisable"
                  [customerId]="customerId"></app-communications>
              </ng-template>
            </li>

            <li [ngbNavItem]="5">
              <a ngbNavLink (click)="onChangeTab(3)" class="container">
                <span class="text">SUMMARY</span>
              </a>
              <ng-template ngbNavContent>
                <app-lead-summary [leadDetailId]="_leadDetail.id"
                  [disabled]="_contentDisable"></app-lead-summary>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="defaultNav" class="border border-top-0 p-3"></div>
        </div>
        <div class="col-md-3 order-md-2 mt-2">
          <div *ngIf="showMarkLostAndInitiateButton(); else initiatedOrLost" class="text-end">
            <ng-container *ngIf="_moveInitiateLostDeletePermission">
              <button class="btn mark-lost-btn btn-sm" (click)="markLost()"
                [disabled]="_leadDetail.moveInitiated">
                MARK LOST <i class="feather icon-slash"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="_moveInitiateLostCreatePermission">
              <button class="btn initiate-move-btn btn-sm" (click)="openInitiateMoveModal()">
                INITIATE MOVE <i class="feather icon-arrow-up-right"></i>
              </button>
            </ng-container>

          </div>

          <ng-template #initiatedOrLost>
            <div *ngIf="_leadDetail.moveInitiated" class="text-end">
              <button class="btn initiate-move-btn btn-sm"
                [class.active]="_leadDetail.moveInitiated === true">
                MOVE INITIATED <i class="feather icon-check-circle"></i>
              </button>
            </div>
            <ng-container *ngIf="_moveInitiateLostUpdatePermission">
              <div *ngIf="_leadDetail.markLost" class="text-end">
                <button class="btn btn-danger btn-sm" (click)="markLeadLost()">
                  RE-ACTIVATE <i class="feather icon-refresh-cw"></i>
                </button>
              </div>
            </ng-container>
          </ng-template>

          <div
            class="mt-3 border border-dark-grey custom-rounded order-md-2 box-right move-status-box"
            [class.disabled]="_contentDisable" *ngIf="_leadStatusAndReqReadPermission === true">
            <app-moves-status [refId]="_leadDetail.id" [moveCategory]="_leadDetail.categoryType"
              [moveTransport]="_leadDetail.transportType" [statusType]="_statusType"
              (allStatusesDone)="handleAllStatusesDone($event)" [version]="_statusVersion">
            </app-moves-status>
          </div>

        </div>
      </div>
      <!-- </div> -->

      <!-- </div> -->