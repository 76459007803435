import { Component, NgZone, OnInit, SimpleChanges } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CompanySetting } from '../../../models/companysetting.model';
import { eMoveCategoryType, eTransportType } from '../../../models/lead-detail.model';
import { MovdService } from '../../../services/movd.service';
import { LeadQuotationSettings } from '../../../models/lead-quotation-settings.model';

@Component({
  selector: 'app-quotation-setting',
  templateUrl: './quotation-setting.component.html',
  styleUrls: ['./quotation-setting.component.scss']
})
export class QuotationSettingComponent implements OnInit {
  defaultNavActiveId: number = 1;

  enumCategoryType = eMoveCategoryType;
  enumTransportType = eTransportType;

  _categoryType = eMoveCategoryType.DoorToPort;
  _transportType = eTransportType.Sea;

  _quotationSetting = new LeadQuotationSettings();

  constructor(private _movdService: MovdService, private _appComponent: AppComponent,
    private zone: NgZone) { }

  ngOnInit(): void {
    this.getLeadQuotationSetting(this._categoryType, this._transportType);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['categoryType'] || changes['transportType']) {

  //   }
  // }

  onChangeTab(tabIndex: number, categoryType: eMoveCategoryType | null, transportType: eTransportType | null): void {
    this.defaultNavActiveId = tabIndex;
    // console.log('Category Type:', categoryType);
    // console.log('Transport Type:', transportType);

    if (categoryType && transportType) {
      this._categoryType = categoryType;
      this._transportType = transportType;
      // console.log('Category Type:', this._categoryType);
      // console.log('Transport Type:', this._transportType);
      this.getLeadQuotationSetting(this._categoryType, this._transportType);
    }

    // You can use these values to update your _quotationSetting or perform any other logic
  }


  saveQuotationSettings() {
    const companyId = Number(localStorage.getItem('companyId'));
    this._quotationSetting.ownedBy = companyId;
    this._quotationSetting.categoryType = this._categoryType;
    this._quotationSetting.transportType = this._transportType;
    // console.log('before saving quotation', this._quotationSetting)
    this._movdService.saveLeadQuotationSettings(this._quotationSetting).subscribe({
      next: (response) => {
        this.getLeadQuotationSetting(this._categoryType, this._transportType);
        // console.log('Settings updated successfully:', response);
        this._appComponent.showSuccessSwal('Updated', 'setting');
      },
      error: (error) => {
        console.error('Error updating settings:', error);
      }
    });
  }

  getLeadQuotationSetting(categoryType: eMoveCategoryType | null, transportType: eTransportType | null) {
    this._movdService.getLeadQuotationSettings(categoryType, transportType).subscribe({
      next: (response: LeadQuotationSettings[]) => {
        // console.log('Quotation Settings:', response);
        if (response && response.length > 0) {
          this.zone.run(() => {
            this._quotationSetting = response[0];
          });
        }
        else {
          this._quotationSetting = new LeadQuotationSettings();
        }
      },
      error: (error: any) => {
        console.error('Error getting settings:', error);
        // this._appComponent.showErrorSwal('getting', 'company settings');
      }
    });
  }
}