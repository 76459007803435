import { eMoveCategoryType, eMoveType, eProcessStatus, eTransportType } from "../../models/lead-detail.model";

export class PackagingBoardListDTO {
    leadId: number | null = null;
    packagingDetailId: number | null = null;
    moveDetailId: number | null = null;
    leadDetailId: number | null = null;
    customerId: number | null = null;
    customer: string | null = null;
    phone: string | null = null;
    email: string | null = null;
    moveType: eMoveType | null = null;
    moveCategory: eMoveCategoryType | null = null;
    moveTransport: eTransportType | null = null;
    surveyDate: Date | null = null;
    loadingDateFrom: Date | null = null;
    loadingDateTo: Date | null = null;
    storageDateFrom: Date | null = null;
    storageDateTo: Date | null = null;
    deliveryDate: Date | null = null;
    account: string | null = null;
    accountId: number | null = null;
    accountName: string | null = null;
    pocId: number | null = null;
    pocName: string | null = null;
    thirdPartyId: number | null = null;
    thirdPartyName: string | null = null;
    thirdPartyPocId: number | null = null;
    thirdPartyPocName: string | null = null;
    origin: string | null = null;
    destination: string | null = null;
    packagingDateFrom: Date | null = null;
    packagingDateTo: Date | null = null;
    internalNotes: string | null = null;
    accountManagerId: number | null = null;
    accountManager: string | null = null;
    insuranceRequired: boolean | null = null;
    creationDate: Date | null = null;
    packingStatus: eProcessStatus | null = null;
    deliveryStatus: eProcessStatus | null = null;
    totalCount: number = 0;

    // Not Mapped Properties for datatable formatting
    warehouseStorage: string | null = null;
    formattedMoveDetailId: string | null = null;
    formattedLeadDetailId: string | null = null;
    formattedPackingId: string | null = null;
    packagingDate: string | null = null;
    loadingDate: string | null = null;
    storageDate: string | null = null;
    moveTypeLabel: string | null = null;
    moveCategoryLabel: string | null = null;
    moveTransportLabel: string | null = null;
    formattedDeliveryDate: string | null = null;
    formattedSurveyDate: string | null = null;

    constructor() { }
}

export interface PackingListResponse {
    packingList: PackagingBoardListDTO[];
    totalRecords: number;
}

export const allPackingColumns: string[] = [
    "Move Id",
    "Account",
    "Customer",
    "Status",
    "Origin",
    "Destination",
    "Move",
    "POL",
    "POD",
    "Place Of Delivery",
    "POC",
    "Third Party",
    "Third Party POC",
    "Move Category",
    "Transport Type",
    "Warehouse Storage",
    "Survey Date",
    "Pack Date",
    "Loading Date",
    "Storage Date",
    "Delivery Date",
    "Account Manager"
];
