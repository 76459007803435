import { Address } from "./address.model";

export class Vehicle {
  id: number;
  ownedBy: number;
  name: string;
  type: eVehicleType | null;
  modelNo: string;
  registrationNo: string;
  capacity: string;
  creationDate: Date;
  createdBy: number | null;
  modificationDate: Date | null;
  modifiedBy: number | null;
  deletionDate: Date | null;
  isDeleted: boolean;

  constructor() {
    this.id = 0;
    this.ownedBy = 0;
    this.name = '';
    this.type = null;
    this.modelNo = '';
    this.registrationNo = '';
    this.capacity = '';
    this.creationDate = new Date();
    this.createdBy = null;
    this.modificationDate = null;
    this.modifiedBy = null;
    this.deletionDate = null;
    this.isDeleted = false;
  }
}
export enum eVehicleType {
  Van = 0,
  Truck = 1,
}
export interface EnumOption {
  id: number;
  value: string;
}

export const VehicleTypeOptions: EnumOption[] = [
  { id: eVehicleType.Van, value: 'Van' },
  { id: eVehicleType.Truck, value: 'Truck' },
];